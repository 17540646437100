// * Angular
import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
// * Translate
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
// * Apex Charts
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexResponsive,
  ApexXAxis,
  ApexLegend,
  ApexFill} from 'ng-apexcharts';

@Component({
  selector: 'recolected-refund',
  templateUrl: './recolected-refund.component.html',
  styleUrls: ['./recolected-refund.component.scss']
})
export class RecolectedRefundComponent implements OnInit {
	@ViewChild('chart') chart: ChartComponent;
	public dataChartExpiredOrders: Partial<ChartOptions>;
	tooltipTracking: string;
	@Input() dataBaseRecolected: number[] = [];
	@Input() dataBaseRefunded: number[] = [];
	@Input() dataBaseDays: string[] = [];
	@Input() dataCompRecolected: number[] = [];
	@Input() dataCompRefunded: number[] = [];
	@Input() dataCompDays: string[] = [];
	@Input() addComp: boolean;
	@Input() periodBase: string = '';
	@Input() periodComp: string = '';
	@Input() changeStyles: string = 'dates-default';
	recolect = [];
	refund = [];
	days = [];
	constructor(private translate: TranslateService) {
	}
	ngOnInit() {
		this.translate.use(localStorage.getItem('language').toLowerCase());
		this.tooltipTracking = this.translate.instant('tooltip.ordersTrackingExpired');
		this.loadDataChart();
		this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
			this.translate.use(event.lang);
			this.tooltipTracking = this.translate.instant('tooltip.ordersTrackingExpired');
		})
	}
	ngOnChanges(changes: SimpleChanges) {

		this.getData();
		this.loadDataChart();
		if(changes.periodComp !== undefined){
			this.getData();
			this.loadDataChart();
		}
		if(changes.addComp !== undefined){
			this.getData();
			this.loadDataChart();
		}
	}
	getData() {
		this.days = [];
		this.refund = [];
		this.recolect = [];
		this.dataBaseRecolected.forEach((res) => {
			this.recolect.push(res);
		});
		this.dataBaseRefunded.forEach((res) => {
			this.refund.push(res);
		});
		this.dataBaseDays.forEach((res) => {
			this.days.push(res.substring(0, 3));
		});
		if (this.addComp === true) {
			this.refund.push(0);
			this.recolect.push(0);
			this.days.push(' ');
			if (this.dataCompRecolected.length > 0 && this.dataCompRefunded.length > 0 && this.dataCompDays.length > 0) {
				this.dataCompRecolected.forEach((res) => {
					this.recolect.push(res);
				});
				this.dataCompRefunded.forEach((res) => {
					this.refund.push(res);
				});
				this.dataCompDays.forEach((res) => {
					this.days.push(res.substring(0, 3));
				});
			}
		}

	}
	private loadDataChart() {
		this.dataChartExpiredOrders = {
			series: [
				{
					name: this.translate.instant('overview.orderRecolected'),
					data: this.recolect,
				},
				// {
				// 	name: this.translate.instant('overview.orderRefunded'),
				// 	data: this.refund,
				// },
			],
			chart: {
				type: 'bar',
				height: 300,
				stacked: true,
				toolbar: {
					show: true,
					tools:{
            			download:`
							<div class="icon_chevron">
								<p class="caption_c2 font_medium color_primary">
									${this.translate.instant('buttons.export')}
								</p>
								<img src="../../../../../../assets/icons/icon-chevron-down.svg">

							</div>
						`,

						pan:false,
						reset:false,
						zoom:false,
						zoomin:false,
						zoomout:false,
						selection:true
					},
				},
				zoom: {
					enabled: true,
				},
			},
			responsive: [
				{
					breakpoint: 480,
					options: {
						legend: {
							position: 'bottom',
							offsetX: -10,
							offsetY: 0,
						},
					},
				},
			],
			plotOptions: {
				bar: {
					horizontal: false,
				},
			},
			xaxis: {
				type: 'category',
				categories: this.days,
			},
			legend: {
				show: false,
				position: 'bottom',
				offsetY: 0,
				markers: {
					fillColors: ['#0082CB', '#18345D'], // Colores personalizados para los marcadores de la leyenda
				},
			},
			dataLabels: {
				enabled: true,
			},
			fill: {
				opacity: 1,
				colors: ['#0082CB', '#18345D'],
			},
		};
	}
}

export type ChartOptions = {
	series: ApexAxisChartSeries;
	chart: ApexChart;
	dataLabels: ApexDataLabels;
	plotOptions: ApexPlotOptions;
	responsive: ApexResponsive[];
	xaxis: ApexXAxis;
	legend: ApexLegend;
	fill: ApexFill;
};
