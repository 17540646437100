// * Angular
import { Component, Input, SimpleChanges, ViewChild } from '@angular/core';
// * Services
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
// * Apex Charts
import {
	ApexAxisChartSeries,
	ApexChart,
	ChartComponent,
	ApexDataLabels,
	ApexXAxis,
	ApexPlotOptions,
	ApexResponsive,
	ApexFill,
	ApexLegend,
	ApexYAxis,
	ApexStroke,
} from 'ng-apexcharts';
import { Data } from 'src/app/core/models';

export interface DataBase {
	name:string;
	data:number;
}
@Component({
  selector: 'rating-average',
  templateUrl: './rating-average.component.html',
  styleUrls: ['./rating-average.component.scss']
})
export class RatingAverageComponent  {
	@ViewChild('chart') chart: ChartComponent;
	@Input() dataBase = [];
	@Input() qualification = 0.0;
	@Input() quantityQualification = 0;
	@Input() rating = 0;
	@Input() simplified = false;
	interpolationData:DataBase[] = [];
	public chartOptions: Partial<ChartOptions>;

	stars=[];
	data =[];
	tooltipRating: string;
	constructor(private translate: TranslateService) {

	}

	ngOnInit() {
		this.translate.setDefaultLang(localStorage.getItem('language').toLowerCase());
		this.tooltipRating = this.translate.instant('tooltip.qualification');
		this.loadDataChart();
		this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
			this.translate.use(event.lang);
			this.tooltipRating = this.translate.instant('tooltip.qualification');
		})


	}



	ngOnChanges(changes: SimpleChanges) {
		this.data =[];
		this.stars = [];
		this.dataBase.map((item:any)=>{
			this.data.push(item.data);
			this.stars.push(item.name);
		})
		this.loadDataChart();
		this.interpolationData = this.dataBase;
	}

	loadDataChart() {
		this.chartOptions = {
			series: [
				{
					data: this.data
				}
			],
			chart: {
				type: 'bar',
				height: 220,
				foreColor:  '#A6A6A6',
				toolbar: {
					show: this.simplified ? false: true,
					tools:{
						download:`
						<div class="icon_chevron">
							<p class="caption_c2 font_medium color_primary">
								${this.translate.instant('buttons.export')}
							</p>
							<img src="../../../../../../assets/icons/icon-chevron-down.svg">

						</div>
						`,
						pan:false,
						reset:false,
						zoom:false,
						zoomin:false,
						zoomout:false,
						selection:true,
					},
				},
			},

			plotOptions: {
				bar: {
					horizontal: true,
					barHeight: '30',
					colors : {
						ranges:[
							{
								from: -30,
								to: -1,
								color: 'var(--color-container)',
							},
							{
								from: 0,
								to: 20,
								color: '#8AC6E7',
							},
							{
								from: 20,
								to: 40,
								color: '#54ABDC',
							},
							{
								from: 40,
								to: 60,
								color: '#0082CB',
							},
							{
								from: 60,
								to: 80,
								color: '#016299',
							},
							{
								from: 80,
								to: 100,
								color: '#003755'
							}
						],
					},
				}
			},
			responsive: [
				{
					breakpoint: 620,
					options:{
						chart: {
							height: 0
						},
						series: this.dataBase.map((item) => ({
							name: item.name,
							data: item.data ? item.data : [0],
						}))
					}
				},
			],
			xaxis: {
				labels: {
					show: false,
				},
				axisBorder: {
					show: true,
				},
				axisTicks: {
					show: false,
				},
				position: 'outside',
				categories :['','','','',''],
				type: 'category'
			},
			yaxis:{
				labels:{
					show:false,
				},
				axisBorder: {
					show: false,
				},
				axisTicks: {
					show: false,
				},
			},
			dataLabels: {
				enabled: false,
				offsetX: 300,
				style: {
					colors: ['#a6a6a6'],
					fontFamily: 'Inter',
					fontSize: '10px',
				},
				// ! HIDDEN VALUE -------------------------->
				// formatter: function (val: any, opts) {
				// 	let total = opts.w.globals.seriesTotals.reduce(
				// 		(a, b) => a + b,
				// 		0
				// 	);
				// 	let percentage = ((val / total) * 100).toFixed(0);
				// 	return `${val}(${percentage}%)`;
				// },
				// ! HIDDEN VALUE -------------------------->
				textAnchor: 'middle',
			},
			fill: {
				opacity: 100,
				colors: ['#8AC6E7','#54ABDC','#0082CB', '#016299', '#003755'],
			},
			legend: {
				show: false,
			},
			stroke: {
				show: true,
				width: 2,
				colors: ['var(--color-container)'],
			},
		};
	}
}

export type ChartOptions = {
	series: ApexAxisChartSeries;
	chart: ApexChart;
	dataLabels: ApexDataLabels;
	plotOptions: ApexPlotOptions;
	xaxis: ApexXAxis;
	yaxis: ApexYAxis;
	responsive: ApexResponsive[];
	legend: ApexLegend;
	fill: ApexFill;
	stroke: ApexStroke;
};
