import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GoogleMapsService } from 'src/app/core/services/google-maps/google-maps.service';
import { StoresService } from 'src/app/core/services/stores.service';

@Component({
	selector: 'app-dialog-edit-store',
	templateUrl: './dialog-edit-store.component.html',
	styleUrls: ['./dialog-edit-store.component.scss']
})
export class DialogEditStoreComponent implements OnInit, AfterViewInit {
	storeForm: FormGroup;
	cities = [];
	map: google.maps.Map;
	marker: google.maps.Marker;
	@ViewChild('mapContainer') mapContainer: ElementRef;
	center: google.maps.LatLngLiteral ={ lat: 25.74682811, lng: -100.351334 }; // Ciudad de México
	zoom = 12; // Nivel de zoom inicial
	states = [
		{ id: 1, name: 'Aguascalientes', abbreviation: 'AG' },
		{ id: 2, name: 'Baja California', abbreviation: 'BC' },
		{ id: 3, name: 'Baja California Sur', abbreviation: 'BS' },
		{ id: 4, name: 'Campeche', abbreviation: 'CM' },
		{ id: 5, name: 'Chiapas', abbreviation: 'CS' },
		{ id: 6, name: 'Chihuahua', abbreviation: 'CH' },
		{ id: 7, name: 'Ciudad de México (DF)', abbreviation: 'DF' },
		{ id: 8, name: 'Coahuila', abbreviation: 'CO' },
		{ id: 9, name: 'Colima', abbreviation: 'CL' },
		{ id: 10, name: 'Durango', abbreviation: 'DG' },
		{ id: 11, name: 'Estado de México', abbreviation: 'MX' },
		{ id: 12, name: 'Guanajuato', abbreviation: 'GT' },
		{ id: 13, name: 'Guerrero', abbreviation: 'GR' },
		{ id: 14, name: 'Hidalgo', abbreviation: 'HG' },
		{ id: 15, name: 'Jalisco', abbreviation: 'JA' },
		{ id: 16, name: 'Michoacán', abbreviation: 'MI' },
		{ id: 17, name: 'Morelos', abbreviation: 'MO' },
		{ id: 18, name: 'Nayarit', abbreviation: 'NA' },
		{ id: 19, name: 'Nuevo León', abbreviation: 'NL' },
		{ id: 20, name: 'Oaxaca', abbreviation: 'OA' },
		{ id: 21, name: 'Puebla', abbreviation: 'PU' },
		{ id: 22, name: 'Querétaro', abbreviation: 'QT' },
		{ id: 23, name: 'Quintana Roo', abbreviation: 'QR' },
		{ id: 24, name: 'San Luis Potosí', abbreviation: 'SL' },
		{ id: 25, name: 'Sinaloa', abbreviation: 'SI' },
		{ id: 26, name: 'Sonora', abbreviation: 'SO' },
		{ id: 27, name: 'Tabasco', abbreviation: 'TB' },
		{ id: 28, name: 'Tamaulipas', abbreviation: 'TM' },
		{ id: 29, name: 'Tlaxcala', abbreviation: 'TL' },
		{ id: 30, name: 'Veracruz', abbreviation: 'VE' },
		{ id: 31, name: 'Yucatán', abbreviation: 'YU' },
		{ id: 32, name: 'Zacatecas', abbreviation: 'ZA' },
	];
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private dialogRef: MatDialogRef<DialogEditStoreComponent>,
		private storeService: StoresService,
		private formBuilder: FormBuilder,
		private cdr: ChangeDetectorRef, // Inyección del ChangeDetectorRef,
		private maps: GoogleMapsService,

	) {
		console.log(this.data);
		this.storeForm = this.formBuilder.group({
			id_store: [data.store.id_store, Validators.required],
			id_status: [data.store.id_status, Validators.required],
			id_city: [data.store.id_city, Validators.required],
			id_state:[data.store.id_state, Validators.required],
			store_name: [data.store.store_name, [Validators.required, Validators.minLength(3), Validators.maxLength(100)]],
			store_alias: [data.store.store_alias, [Validators.required, Validators.minLength(3), Validators.maxLength(50)]],
			address: [data.store.address, [Validators.required, Validators.minLength(5), Validators.maxLength(255)]],
			latitude: [data.store.latitude, Validators.required],
			longitude: [data.store.longitude, Validators.required],
			codigo_referencia: [data.store.codigo_referencia, [Validators.required, Validators.pattern(/^[A-Z0-9]{6}$/)]],
			opening_time: [data.store.opening_time, Validators.required],
			closing_time: [data.store.closing_time, Validators.required]
		});
		this.onStateSelected(data.store.id_state);
		this.getAllCities(data.store.id_state);
		this.onCitySelected(data.store.id_city);
	}
	close(){
		this.dialogRef.close();
	}
	getErrorMessage(controlName: string): string {
		const control = this.storeForm.get(controlName);
		if (control?.hasError('required')) {
			return 'Este campo es obligatorio';
		}
		if (control?.hasError('minlength')) {
			return `El mínimo permitido es ${control.errors?.minlength.requiredLength} caracteres.`;
		}
		if (control?.hasError('maxlength')) {
			return `El máximo permitido es ${control.errors?.maxlength.requiredLength} caracteres.`;
		}
		if (control?.hasError('pattern')) {
			return 'El formato no es válido';
		}
		return '';
	}
	async getAllCities(stateId: number) {
		try {
			const response = await this.storeService.AllCities(stateId).then((res:any)=>{
				res.cities.map((element:any)=>{
					this.cities.push({
						id:element.id_city,
						name:element.city
					});
				});
			});
			console.log(this.cities);

		} catch (error) {
			console.error('Error al obtener ciudades:', error);
		}
	}
	onStateSelected(selectedState: any) {
		// Set the selected state in the form
		this.storeForm.get('id_state')?.setValue(selectedState.id);

		// Fetch the cities corresponding to the selected state
		this.getAllCities(selectedState.id);
	}
	onCitySelected(city: any) {
		if (this.storeForm.get('id_city').value !== city.id) {
			this.storeForm.get('id_city').setValue(city.id);
		}
	}
	ngOnInit() {
		this.storeForm.get('id_state').setValue(this.data.store.id_city);
		console.log(this.storeForm.value);
		// Initialize the form and set up a listener for city changes
		this.storeForm.get('id_city')?.valueChanges.subscribe((value) => {
			console.log('City changed:', value);
		});
		this.cdr.detectChanges(); // Forzar la actualización de la vista después de recibir los datos
		this.loadMap();
	}

	ngAfterViewInit() {
		this.maps.loadGoogleMaps().then((googleMaps) => {
			this.initMap(googleMaps);
			this.placeInitialMarker(googleMaps);  // Llamada para colocar el marcador inicial
		}).catch((error) => {
			console.error('Error al cargar Google Maps:', error);
		});
	}
	placeInitialMarker(googleMaps: any) {
		// Colocar el marcador en las coordenadas iniciales de la tienda
		const initialLocation = new googleMaps.LatLng(this.center.lat, this.center.lng);
		this.placeMarker(initialLocation, googleMaps);  // Llama a placeMarker para colocar el marcador
	}
	loadMap() {
		this.maps.loadGoogleMaps().then(googleMaps => {
			this.center.lat = parseFloat(this.data.store.latitude);
			this.center.lng = parseFloat(this.data.store.longitude);
			this.initMap(googleMaps);
		}).catch(error => {
			console.error('Error al cargar Google Maps:', error);
		});
	}
	initMap(googleMaps: any) {
		if (!this.center.lat || !this.center.lng || isNaN(this.center.lat) || isNaN(this.center.lng)) {
			console.error('No se puede inicializar el mapa sin coordenadas válidas.');
			return;
		}

		// Configuración del mapa
		const mapOptions = {
			center: this.center,
			zoom: this.zoom,
			streetViewControl: true,
		};

		this.map = new googleMaps.Map(this.mapContainer.nativeElement, mapOptions);

		// Evento click para mover el marcador
		googleMaps.event.addListener(this.map, 'click', (event: any) => {
			this.placeMarker(event.latLng, googleMaps);
		});
	}
	placeMarker(location: google.maps.LatLng, googleMaps: any) {
		if (this.marker) {
			this.marker.setMap(null); // Elimina el marcador previo
		}

		// Crear nuevo marcador
		this.marker = new googleMaps.Marker({
			position: location,
			map: this.map,
			title: 'Nueva ubicación',
		});

		// Crear nueva InfoWindow
		const infoWindow = new googleMaps.InfoWindow({
			content: `<div>
						<h4>Nueva ubicación</h4>
						<p>Latitud: ${location.lat()}</p>
						<p>Longitud: ${location.lng()}</p>
					</div>`,
		});

		// Mostrar InfoWindow al hacer clic en el nuevo marcador
		this.marker.addListener('click', () => {
			infoWindow.open(this.map, this.marker);
		});

		const geocoder = new google.maps.Geocoder();
		geocoder.geocode({ location: location }, (results, status) => {
			if (status === google.maps.GeocoderStatus.OK && results[0]) {
			const address = results[0].formatted_address;
			this.storeForm.get('address').setValue(address);
			}
			this.cdr.detectChanges();
		});
		// Actualizar las coordenadas en el store
		this.storeForm.get('latitude').setValue(Number(location.lat().toFixed(8)));
		this.storeForm.get('longitude').setValue(Number(location.lng().toFixed(8)));
		// this.store.latitude = location.lat();
		// this.store.longitude = location.lng();
		// console.log('Nueva posición del marcador:', this.store.latitude, this.store.longitude);
	}
	saveStore(){

		Object.values(this.storeForm.controls).forEach(control => {
			control.markAsTouched(); // Marca el control como tocado
		});
		console.log('storesInformation ->', this.storeForm.value);
		if(this.storeForm.valid){
			console.log('storesInformation ->', this.storeForm.value);
			// Wrap the object in an array to match the expected structure
			let obj = {
				store_data: [this.storeForm.value],
			};

			this.storeService.updateStore(this.storeForm.value,this.data.store.id_store).then((res: any) => {
				console.log('Response from addStore:', res);
				if(res.status===200){
					this.dialogRef.close({
						storeCreated:true,
						store:this.storeForm.value
					});
				}
			}).catch((error: any) => {
				console.error('Error saving store:', error);
			});
		}
	}
}
