import { HttpClient } from '@angular/common/http';
import {
	Component,
	Input,
	OnChanges,
	OnInit,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ColDef, GridOptions } from 'ag-grid-community';
import { EstatusOxxoToOxxoComponent } from '../estatus-oxxo-to-oxxo/estatus-oxxo-to-oxxo.component';
import { StarRatingComponent } from 'src/app/shared/components/star-rating/star-rating.component';
import { DialogOxxoToOxxoDetailComponent } from '../../dialog-oxxo-to-oxxo-detail/dialog-oxxo-to-oxxo-detail.component';
import { AppClientService } from 'src/app/core/http/app-client/app-client.service';
import {
	QueryGeneralPackage,
	ReturnResultQueryGeneralPackage,
} from 'src/app/core/models/app-client/app-client.interface';
import * as XLSX from 'xlsx';
import { DateTextComponent } from '../../date-text/date-text.component';
import { FilterService } from 'src/app/core/http/FilterDataServiceOxxoToOxxo.service';
import { AgGridAngular } from 'ag-grid-angular';
import { start } from 'repl';
import { DataService } from 'src/app/core/services/data.service';
import { DateTextTablesComponent } from 'src/app/shared/components/date-text-tables/date-text-tables.component';
@Component({
	selector: 'app-oxxo-to-oxxo-general',
	templateUrl: './oxxo-to-oxxo-general.component.html',
	styleUrls: ['./oxxo-to-oxxo-general.component.scss'],
})
export class OxxoToOxxoGeneralComponent implements OnInit, OnChanges {
	@Input() rowData;
	@Input() filteredData;
	@ViewChild('agGrid') agGrid: AgGridAngular;
	rangeDates: Date[];
	InitialDate = new Date();
	FinalDate = new Date();
	gridOptions: GridOptions;
	filterText: string = '';
	mode: string;
	filters: boolean = false;
	overlayLoadingTemplate = `
	<div>
		<div class="spinner-grow text-dark" role="status">
			<span class="sr-only">Loading...</span>
		</div>
		<div class="spinner-grow text-dark" role="status">
			<span class="sr-only">Loading...</span>
		</div>
		<div class="spinner-grow text-dark" role="status">
			<span class="sr-only">Loading...</span>
		</div>
	</div>
	`;
	buttonExport: boolean = true;

	private gridApi: AgGridAngular;
	public columnDefsGeneralOTO: ColDef[];
	arrayFilterEstatus;
	constructor(
		private translate: TranslateService,
		private http: HttpClient,
		private matDialog: MatDialog,
		private appClient: AppClientService,
		private filterService: FilterService,
		public dataService: DataService
	) {}
	ngOnInit() {
		this.mode = localStorage.getItem('mode');
		this.translate.use(localStorage.getItem('language').toLowerCase());
		this.getData();
		this.updateTableGeneral();
		this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
			this.translate
				.get([
					'oxxoToOxxo.tables.tracking',
					'oxxoToOxxo.tables.submitter',
					'oxxoToOxxo.tables.origin',
					'oxxoToOxxo.tables.destiny',
					'oxxoToOxxo.tables.coverage',
					'oxxoToOxxo.tables.status',
					'oxxoToOxxo.tables.scheduleDate',
					'oxxoToOxxo.tables.deliveryDate',
					'oxxoToOxxo.tables.rate',
				])
				.subscribe((t: any) => {
					this.columnDefsGeneralOTO = [
						{
							headerName: t['oxxoToOxxo.tables.tracking'],
							field: 'data_package_service.tracking_number',
							headerClass: 'borderHeaderOTOGeneral',
							cellClass: 'OTOGeneralClassLink',
							autoHeight: true,
							sortable: true,
							onCellClicked: ($event) =>
								this.openModalOxxoToOxxoDetail($event),
						},
						{
							headerName: t['oxxoToOxxo.tables.submitter'],
							valueGetter: (params) => {
								const firstName =
									params.data.data_consumer.consumer_source
										.first_name;
								const lockerName =
									params.data.data_consumer.consumer_source
										.surname;
								return `${firstName} ${lockerName}`;
							},
							headerClass: 'borderHeaderOTOGeneral',
							cellClass: 'OTOGeneralClass',
							sortable: true,
							autoHeight: true,
						},
						{
							headerName: t['oxxoToOxxo.tables.origin'],
							field: 'data_locker.locker_source.locker_name',
							headerClass: 'borderHeaderOTOGeneral',
							cellClass: 'OTOGeneralClass',
							sortable: true,
							autoHeight: true,
						},
						{
							headerName: t['oxxoToOxxo.tables.destiny'],
							field: 'data_locker.locker_destination.locker_name',
							headerClass: 'borderHeaderOTOGeneral',
							cellClass: 'OTOGeneralClass',
							sortable: true,
							autoHeight: true,
						},
						{
							headerName: t['oxxoToOxxo.tables.status'],
							field: 'data_package_service.id_status',
							colId: 'data_package_service_id_status',
							cellRenderer: EstatusOxxoToOxxoComponent,
							headerClass: 'borderHeaderOTOGeneral',
							cellClass: 'OTOGeneralClass',
							sortable: true,
							autoHeight: true,
						},
						{
							headerName: t['oxxoToOxxo.tables.scheduleDate'],
							field: 'data_package_service.registration_date_schedule',
							headerClass: 'borderHeaderOTOGeneral',
							cellClass: 'OTOGeneralClass',
							sortable: true,
							sort: 'desc',
							autoHeight: true,
							cellRenderer: DateTextTablesComponent,
						},
						{
							headerName: t['oxxoToOxxo.tables.deliveryDate'],
							field: 'data_package_service.pickup_date',
							headerClass: 'borderHeaderOTOGeneral',
							cellClass: 'OTOGeneralClass',
							sortable: true,
							autoHeight: true,
							cellRenderer: DateTextTablesComponent,
						},
						{
							headerName: t['oxxoToOxxo.tables.rate'],
							field: 'data_package_service.rate',
							cellRenderer: StarRatingComponent,
							headerClass: 'borderHeaderOTOGeneral',
							cellClass: 'OTOGeneralClass',
							sortable: true,
							autoHeight: true,
						},
					];
				});
		});
	}
	ngOnChanges(changes: SimpleChanges): void {
		// if (this.rowData.length === 0) {
		// 	this.buttonExport = false;
		// } else {
		// 	this.buttonExport = true;
		// }
	}
	getData() {
		// let object={
		// 	language: localStorage.getItem('language').toLowerCase()
		// }
		// this.appClient.getQueryGeneralPackage(object).then((res:QueryGeneralPackage)=>{
		// 	this.rowData = res.return_result.filter(item => {
		// 		const allowedStatuses = [39, 45, 46, 35, 47, 57, 49, 50, 54, 56, 55, 51, 57, 59, 60, 57, 61, 62, 50, 63, 64, 58];
		// 		return allowedStatuses.includes(item.data_package_service.id_status);
		// 	});

		// });
	}

	onGridReady(params: any) {
		this.gridApi = params;
	}
	updateTableGeneral() {
		this.gridOptions = {
			columnDefs: [
				{
					headerName: this.translate.instant(
						'oxxoToOxxo.tables.tracking'
					),
					field: 'data_package_service.tracking_number',
					headerClass: 'borderHeaderOTOGeneral',
					cellClass: 'OTOGeneralClassLink',
					autoHeight: true,
					sortable: true,
					onCellClicked: ($event) =>
						this.openModalOxxoToOxxoDetail($event),
				},
				{
					headerName: this.translate.instant(
						'oxxoToOxxo.tables.submitter'
					),
					valueGetter: (params) => {
						const firstName =
							params.data.data_consumer.consumer_source
								.first_name;
						const lockerName =
							params.data.data_consumer.consumer_source.surname;
						return `${firstName} ${lockerName}`;
					},
					headerClass: 'borderHeaderOTOGeneral',
					cellClass: 'OTOGeneralClass',
					sortable: true,
					autoHeight: true,
				},
				{
					headerName: this.translate.instant(
						'oxxoToOxxo.tables.origin'
					),
					field: 'data_locker.locker_source.locker_name',
					headerClass: 'borderHeaderOTOGeneral',
					cellClass: 'OTOGeneralClass',
					sortable: true,
					autoHeight: true,
				},
				{
					headerName: this.translate.instant(
						'oxxoToOxxo.tables.destiny'
					),
					field: 'data_locker.locker_destination.locker_name',
					headerClass: 'borderHeaderOTOGeneral',
					cellClass: 'OTOGeneralClass',
					sortable: true,
					autoHeight: true,
				},
				{
					headerName: this.translate.instant(
						'oxxoToOxxo.tables.status'
					),
					field: 'data_package_service.id_status',
					colId: 'data_package_service_id_status',
					cellRenderer: EstatusOxxoToOxxoComponent,
					headerClass: 'borderHeaderOTOGeneral',
					cellClass: 'OTOGeneralClass',
					sortable: true,
					autoHeight: true,
				},
				{
					headerName: this.translate.instant(
						'oxxoToOxxo.tables.scheduleDate'
					),
					field: 'data_package_service.registration_date_schedule',
					headerClass: 'borderHeaderOTOGeneral',
					cellClass: 'OTOGeneralClass',
					sortable: true,
					sort: 'desc',
					autoHeight: true,
					cellRenderer: DateTextTablesComponent,
				},
				{
					headerName: this.translate.instant(
						'oxxoToOxxo.tables.deliveryDate'
					),
					field: 'data_package_service.pickup_date',
					headerClass: 'borderHeaderOTOGeneral',
					cellClass: 'OTOGeneralClass',
					sortable: true,
					autoHeight: true,
					cellRenderer: DateTextTablesComponent,
				},
				{
					headerName: this.translate.instant(
						'oxxoToOxxo.tables.rate'
					),
					field: 'data_package_service.rate',
					cellRenderer: StarRatingComponent,
					headerClass: 'borderHeaderOTOGeneral',
					cellClass: 'OTOGeneralClass',
					sortable: true,
					autoHeight: true,
				},
			],
			rowClass: 'rowOTOGeneralClass',
			animateRows: true,
			defaultColDef: {
				flex: 1,
			},
			pagination: true,
			paginationPageSize: 10,
			paginationAutoPageSize: true,
			headerHeight: 59,
			rowHeight: 59,
			overlayLoadingTemplate: this.overlayLoadingTemplate,
			overlayNoRowsTemplate: `<div>
				<div>
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="bi bi-info-circle icon-no-info" viewBox="0 0 16 16">
						<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
						<path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
					</svg>
				</div>
				<div class="labelNoRegistros">
					Aun no hay registros, <br>
					intentalo mas tarde
				</div>
			</div>`,
		};
	}
	onFilterTextChange(event) {
		this.filterText = event.target.value;
		event.api.setQuickFilter(this.filterText);
		let api = event.api;
		let temporal = [];
		event.api.forEachNodeAfterFilter((node) => {
			temporal.push(node.data);
		});
		this.filteredData = temporal;
	}
	openModalOxxoToOxxoDetail(event) {
		const dialogRef = this.matDialog.open(DialogOxxoToOxxoDetailComponent, {
			width: '1306px',
			height: '95%',
			data: {
				rowData: event.data,
				from: 'general',
			},
		});
	}
	onExport() {
		const fileName = 'OxxoToOxxo-General.xlsx';
		const excelParams = {
			fileName: fileName,
			sheetName: 'OxxoToOxxo-General',
			columnWidths: [30, 20, 20, 20],
		};
		if (this.filteredData.length > 0) {
			const Data = this.filteredData || [];
			const excelData = Data.map((row) => {
				let obj = {
					tracking: row.data_package_service.order_number,
					remitente: row.data_consumer.consumer_source.first_name,
					origen: row.data_locker.locker_source.locker_name,
					destiny: row.data_locker.locker_destination.locker_name,
					scheduledDate:
						row.data_package_service.registration_date_schedule,
					deliverDate:
						row.data_package_service.registration_date_in_locker,
					rate: row.data_package_service.rate,
				};
				return obj;
			});
			const wb = XLSX.utils.book_new();
			const ws = XLSX.utils.json_to_sheet(excelData);
			XLSX.utils.book_append_sheet(wb, ws, excelParams.sheetName);
			XLSX.writeFile(wb, fileName);
		} else if (this.rowData.length > 0 && this.filteredData.length === 0) {
			const Data = this.rowData || [];
			const excelData = Data.map((row) => {
				let obj = {
					tracking: row.data_package_service.order_number,
					remitente: row.data_consumer.consumer_source.first_name,
					origen: row.data_locker.locker_source.locker_name,
					destiny: row.data_locker.locker_destination.locker_name,
					scheduledDate:
						row.data_package_service.registration_date_schedule,
					deliverDate:
						row.data_package_service.registration_date_in_locker,
					rate: row.data_package_service.rate,
				};
				return obj;
			});
			const wb = XLSX.utils.book_new();
			const ws = XLSX.utils.json_to_sheet(excelData);
			XLSX.utils.book_append_sheet(wb, ws, excelParams.sheetName);
			XLSX.writeFile(wb, fileName);
		}
	}
	showFilters() {
		this.filters = !this.filters;
	}
}
