// ANGULAR

import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { AdminClientService } from '../../../core/http/admin-client.service';
import { ColDef, GridOptions } from 'ag-grid-community';
import { AgGridAngular } from 'ag-grid-angular';
import { EstatusTableOrderAdminComponent } from 'src/app/modules/admin-client/components/estatus-table-order-admin/estatus-table-order-admin.component';
import * as XLSX from 'xlsx';
import { TooltipComponent } from './tooltip';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Component, Input, OnInit, Output, SimpleChanges, ViewChild, EventEmitter, OnChanges } from '@angular/core';
interface GridConfig {
	columnDefs: ColDef[];
	rowData: any[];
}
@Component({
	selector: 'app-table',
	templateUrl: './table.component.html',
	styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit, OnChanges {
	@Input() calendar: string = 'none';
	@Input() filterFunnel: string = 'none';
	@Input() btnExport: string = 'none';
	@Input() tableClass: string = 'container-table';
	@Input() tableHeader: any[] = [];
	@Input() tableData: any[] = [];
	@Input() rowData: any[];
	@Input() gridOptions: GridOptions;
	@Output() emiter_select = new EventEmitter<string>();
	@Output() emiter_select_comparment = new EventEmitter<string>();
	@Input() data: string;
	@Input() header;
	@Input() register_package: boolean = false;
	@Input() list_compartment;
	@Input() columns: string;
	@Input() hover;
	@Input() link = 'true';
	@Input() selectedData: any;
	@Output() rowDataSizeChange = new EventEmitter<number>();
	@Output() rowDataEmiter = new EventEmitter();
	delivery = [];
	unclaimed = [];
	coords;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	tempData = [];
	dataSource;
	displayedColumns: string[];
	local;
	locks: [];
	lockers: [];
	grid;
	count_door = 1;
	status = [];
	currentURL: any = '';
	compartment = [];
	public page: number = 0;
	ready_select: boolean = false;
	filterText: string = '';
	dataTemporal = [];
	public filteredData: any[] = [];
	idLocker: string;
	completedDataCompleted = [];
	frameworkComponents: any;
	public columnDefs: ColDef[];
	arrayMarker: any = [];
	id_user_type = Number(localStorage.getItem('id_user_type'));
	overlayLoadingTemplate =`
	<div>
		<div class="spinner-grow text-dark" role="status">
			<span class="sr-only">Loading...</span>
		</div>
		<div class="spinner-grow text-dark" role="status">
			<span class="sr-only">Loading...</span>
		</div>
		<div class="spinner-grow text-dark" role="status">
			<span class="sr-only">Loading...</span>
		</div>
	</div>
	`;
	//AG GRID ANGULAR
	@ViewChild('agGrid') agGrid: AgGridAngular;
	constructor(private router: Router, private dialog: MatDialog,private adminService: AdminClientService,private translate: TranslateService) {
	}
	verLocker(params: any) {
		let grid = [];
		params.data.MODULE.forEach((e, i) => {
			grid.push({
				name_locker_module: e.name_locker_module,
				template_module: e.template_module,
			});
		});
		localStorage.setItem('grid', JSON.stringify(grid));
		localStorage.setItem('id_locker', params.data.id);
		localStorage.setItem('lat', params.data.lat);
		localStorage.setItem('lng', params.data.lng);
		localStorage.setItem('address', params.data.address);
		localStorage.setItem('locker_name', params.data.lockerID);
		localStorage.setItem('img_screen', params.data.imgScreen);
		if (this.router !== null && this.router !== undefined) {
			if(this.id_user_type === 9 ){
				this.router.navigateByUrl(`superadmin/locker/${params.data.lockerID}`, {
					skipLocationChange: true,
				})
			}
		}
		return params.value;
	}
	ngOnInit() {
		if(this.selectedData !== null){
			this.filterText = this.selectedData;
		}
		this.currentURL = document.location.href.split('/');
		this.currentURL = this.currentURL[this.currentURL.length - 1];
		this.local = JSON.parse(localStorage.getItem('data'));
		this.updateTable();
		let idUserType = parseInt(localStorage.getItem('user_type'));
		this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
			localStorage.setItem('id_locker', this.idLocker);
			this.translate.use(event.lang);
			this.translate.get([
					'table-locker.lockerID',
					'table-locker.totalDeliveries',
					'table-locker.totalMonth',
					'table-locker.doors',
					'table-locker.available',
					'table-locker.serial',
					'table-locker.status',
			]).subscribe((t: any) => {
				this.columnDefs = [
					{
						headerName: t['table-locker.lockerID'],
						field: 'lockerID',
						onCellClicked: (params) => {
							if(idUserType <= 9 ){
								this.verLocker(params)
							}else if(idUserType !== 9 ){
								return null;
							}
						},
						cellClass:'tableClassLink',
						headerClass:'borderHeaderTableLockerID',
						cellRenderer: this.centeredCellRenderer
					},
					{
						field: 'lockername',
						headerName: 'Locker name',
						hide: true,
					},
					{
						headerName: this.translate.instant('table-locker.location'),
						field: 'address',
						width:350,
						autoHeight:true,
						sortable: true,
						cellRenderer: (params) => {
							const maxLength = 50; // Número máximo de caracteres antes de mostrar los puntos suspensivos
							const value = params.value;
							if (value.length > maxLength) {
								return value.slice(0, maxLength) + '...';
							}
							return value;
						},
						headerClass:'borderHeaderTable',
						cellClass:'tableClass'
					},
					{
						headerName: t['table-locker.totalDeliveries'],
						field: 'totalDeliveries',
						autoHeight:true,
						cellRenderer: this.centeredCellRenderer,
						// cellStyle: (params) => ({
						// 	'color': params.data.totalDeliveries.includes('-') ? 'var(--color-danger) ' : 'var(--color-success)',
						// 	'text-align': 'center',
						// }),
						headerClass:'borderHeaderTable',
						cellClass:'tableClass'
					},
					{
						headerName: t['table-locker.doors'],
						field: 'doors',
						autoHeight:true,
						sortable: true,
						cellRenderer: this.centeredCellRenderer,
						headerClass:'borderHeaderTable',
						cellClass:'tableClass'
					},
					{
						headerName: t['table-locker.available'],
						field: 'available',
						autoHeight:true,
						sortable: true,
						cellRenderer: this.centeredCellRenderer,
						headerClass:'borderHeaderTable',
						cellClass:'tableClass'
					},
					{
						headerName: t['table-locker.status'],
						field: 'status',
						autoHeight:true,
						sortable: true,
						cellRenderer: EstatusTableOrderAdminComponent,
						headerClass:'borderHeaderTable',
						cellClass:'tableClass'
					}
				];
			});
		});

	}
	ngOnChanges(changes: SimpleChanges) {
		this.rowData = [];
		this.getDataOfTable(this.tableData);
		if(this.selectedData !== null){
			let temporal = [];
			this.filterText = this.selectedData;
			this.agGrid.api.setQuickFilter(this.filterText);
			this.filteredData = temporal;
		}
	}
	updateTable() {
		let data = JSON.parse(localStorage.getItem('data'));
		let idUserType = data.result.id_user_type;
		this.gridOptions = {
			columnDefs: [
				{
					headerName: this.translate.instant('table-locker.lockerID'),
					field: 'lockerID',
					sortable: true,
					sort: 'asc',
					tooltipField: 'lockerID',
					onCellClicked: (params) => {
						if(idUserType <= 9 ){
							this.verLocker(params)
						}else if(idUserType > 9 ){
							return null;
						}
					},
					cellRenderer: this.centeredCellRenderer,
					cellClass:'tableClassLink',
					headerClass:'borderHeaderTableLockerID'
				},
				{
					field: 'lockername',
					headerName: 'Locker name',
					hide: true,
				},
				{
					headerName: this.translate.instant('table-locker.location'),
					field: 'address',
					autoHeight:true,
					sortable: true,
					cellRenderer: (params) => {
						const maxLength = 50; // Número máximo de caracteres antes de mostrar los puntos suspensivos
						const value = params.value;
						if (value.length > maxLength) {
							return value.slice(0, maxLength) + '...';
						}
						return value;
					},
					cellClass:'tableClass',
					headerClass:'borderHeaderTable',
					tooltipField: 'address'
				},
				{
					headerName: this.translate.instant('table-locker.totalDeliveries'),
					field: 'totalDeliveries',
					autoHeight:true,
					sortable: true,
					cellRenderer: this.centeredCellRenderer,
					// cellStyle: (params) => ({
					// 	'color': params.data.totalDeliveries.includes('-') ? 'var(--color-danger) ' : 'var(--color-success)',
					// 	'text-align': 'center',
					// }),
					headerClass:'borderHeaderTable',
					cellClass:'tableClass'
				},
				{
					headerName: this.translate.instant('table-locker.doors'),
					field: 'doors',
					autoHeight:true,
					sortable: true,
					cellRenderer: this.centeredCellRenderer,
					cellClass:'tableClass',
					headerClass:'borderHeaderTable',
				},
				{
					headerName: this.translate.instant('table-locker.available'),
					field: 'available',
					autoHeight:true,
					sortable: true,
					cellRenderer: this.centeredCellRenderer,
					cellClass:'tableClass',
					headerClass:'borderHeaderTable',
				},
				{
					headerName: this.translate.instant('table-locker.status'),
					field: 'status',
					autoHeight:true,
					sortable: true,
					cellRenderer: EstatusTableOrderAdminComponent,
					cellClass:'tableClass',
					headerClass:'borderHeaderTable',
				}
			],
			rowClass:'rowTableClass',
			animateRows: true,
			pagination: true,
			paginationPageSize: 10,
			paginationAutoPageSize: true,
			headerHeight:62,
			rowHeight:62,
			alwaysShowVerticalScroll:false,
			alwaysShowHorizontalScroll:false,
			overlayNoRowsTemplate:
			`<div>
				<div>
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="bi bi-info-circle icon-no-info" viewBox="0 0 16 16">
						<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
						<path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
					</svg>
				</div>
				<div class="labelNoRegistros">
					Aun no hay registros, <br>
					intentalo mas tarde
				</div>
			</div>`,
		} as MyGridOptions;
	}
	selectedSize(event: any) {
		this.emiter_select_comparment.emit(event);
	}
	onGridReady(params: any) {
		const { api } = params;
		api.sizeColumnsToFit();
	}
	resultado:string;
	getDataOfTable(data) {
		this.rowData = [];
		this.completedDataCompleted =[];
		data.forEach((item:any)=>{
			let direccion = item.locker_address;
			let partes = direccion.split(',');
			if (partes.length >= 3) {
				this.resultado = partes.slice(0, 2).join(',\n');
			}
			this.completedDataCompleted.push({
				lockerID			: item.company_route && item.company_route !== null && item.company_route !== '' ? item.company_route : item.locker_name,
				lockername			: item.locker_name,
				imgScreen			: item.img_screen,
				totalDeliveries		: item.PACKAGE[0].DELIVERY[0].package_month_actuality_delivery,
				total				: item.PACKAGE[0].BY_COLLECT[0].result_delivery_to_collect,
				doors				: item.door_total,
				available			: item.compartments_available,
				status				: item.name_status,
				serial				: item.serial_locker,
				status_access_locker: item.ping_nombre_status == 'INACTIVE' ? 'DOWN' : 'UP',
				MODULE				: item.MODULE,
				id					: item.id_locker,
				lat					: item.latitud,
				lng					: item.longitud,
				address				: item.locker_address,
				ip_app_locker		: item.ip_ap_locker,
				ip_pc_locker		: item.ip_pc_locker
			});
			this.rowData = this.completedDataCompleted;
			this.rowDataSizeChange.emit(this.rowData.length);
		})
	}
	refreshGrid() {
		const api = this.agGrid.api;
		api.refreshCells();
	}
	async seeLocker(locker, i) {
		let grid = [];
		locker.MODULE.forEach((e, i) => {
			grid.push({
				name_locker_module: e.name_locker_module,
				template_module: e.template_module,
			});
		});
		localStorage.setItem('grid', JSON.stringify(grid));
		localStorage.setItem('id_locker', locker.id);
		localStorage.setItem('lat', locker.lat);
		localStorage.setItem('lng', locker.lng);
		localStorage.setItem('address', locker.address);
		localStorage.setItem('status_access_locker',locker.status_access_locker);
		localStorage.setItem('locker_name', locker.lockerID);
		this.router.navigateByUrl(`l-retail/locker/${locker.id}`, {
			skipLocationChange: true,
		})
	}
	ngOnDestroy() {
		if (this.currentURL != 'order') this.dialog.closeAll();
	}
	radioChange(event: any, locker: any) {
		this.emiter_select.emit(locker);
	}
	centeredCellRenderer(params) {
		const cellValue = params.value;
		const cellDiv = document.createElement('div');
		cellDiv.style.display = 'flex';
		cellDiv.style.alignItems = 'center';
		cellDiv.style.justifyContent = 'center';
		cellDiv.style.height = '100%';
		cellDiv.innerText = cellValue;
		return cellDiv;
	}
	onFilterTextChange(event) {
		this.filterText = event.target.value;
		event.api.setQuickFilter(this.filterText);
		let api = event.api;
		let temporal = [];
		event.api.forEachNodeAfterFilter((node) => {
			temporal.push(node.data);
		});
		this.filteredData = temporal;
	}
	onExport() {
		const fileName = 'LockerSuperAdmin.xlsx';
		const excelParams = {
			fileName: fileName,
			sheetName: 'LockerSuperAdmin',
			columnWidths: [30, 20, 20, 20], // Ancho de las columnas en caracteres
		};
		if(this.selectedData !== undefined){
			this.agGrid.api.setQuickFilter(this.selectedData);
			let api = this.agGrid.api;
			let temporal = [];
			this.agGrid.api.forEachNodeAfterFilter((node) => {
				temporal.push(node.data);
			});
			this.filteredData = temporal;
			const Data = this.filteredData;
			const excelData = Data.map((row) => {
				// Crear un nuevo objeto clonando 'row' sin incluir el campo 'lockername'
				const { lockername,imgScreen,serial,MODULE,id,  ...rowDataWithoutLockername } = row;
				return rowDataWithoutLockername;
			});
			const wb = XLSX.utils.book_new();
			const ws = XLSX.utils.json_to_sheet(excelData);
			XLSX.utils.book_append_sheet(wb, ws, excelParams.sheetName);
			XLSX.writeFile(wb, fileName);
		}
		else{
			if (this.filteredData.length > 0) {
				const Data = this.filteredData || [];
				// Mapear los objetos de Data sin el campo 'lockername'
				const excelData = Data.map((row) => {
					// Crear un nuevo objeto clonando 'row' sin incluir el campo 'lockername'
					const { lockername,imgScreen,serial,MODULE,id,  ...rowDataWithoutLockername } = row;
					return rowDataWithoutLockername;
				});
				const wb = XLSX.utils.book_new();
				const ws = XLSX.utils.json_to_sheet(excelData);
				XLSX.utils.book_append_sheet(wb, ws, excelParams.sheetName);
				XLSX.writeFile(wb, fileName);
			}
			else if (this.rowData.length > 0 && this.filteredData.length === 0) {
				const Data = this.rowData || [];

				// Mapear los objetos de Data sin el campo 'lockername'
				const excelData = Data.map((row) => {
				// Crear un nuevo objeto clonando 'row' sin incluir el campo 'lockername'
				const { lockername,imgScreen,serial,MODULE,id,  ...rowDataWithoutLockername } = row;
				return rowDataWithoutLockername;
				});

				const wb = XLSX.utils.book_new();
				const ws = XLSX.utils.json_to_sheet(excelData);
				XLSX.utils.book_append_sheet(wb, ws, excelParams.sheetName);
				// Descargar el archivo de Excel
				XLSX.writeFile(wb, fileName);
			}
		}
	}
	getTooltipContent(params) {
		// Aquí puedes personalizar el contenido del tooltip según tus necesidades
		return params.value;
	}
}

interface MyGridOptions extends GridOptions {
	pagination:boolean;
}
