<!-- ? CONTAINER MAIN -->
<div class="cont_main">
	<!-- ? HEADER -->
	<div class="header headlines_h2 font_semibold color_primary">
		<!-- TITLE -->
		<p>{{'overview.cardsOverview.headquarters' | translate}}</p>

		 <!-- ? TOOLTIP CONTAINER -->
		 <div class="tooltip_cont_">
			<img src="../../../../../../assets/icons/icon-info.svg" class="icon_tooltip">

			<!-- TOOLTIP -->
			<div class="tooltip_">
				<p class="caption_c2 color_primary_400 font_medium">
					{{ 'tooltip.sedes' | translate }}
				</p>
				<!-- PIKE -->
				<div class="pike_2"></div>
			</div>
			<!-- PIKE -->
			<div class="pike"></div>

		</div>

	</div>

	<!-- ? CHART  -->
	<div class="chart" id="chartDonut">
		<apx-chart style="width:99%"
			[series]="chartOptions.series"
			[chart]="chartOptions.chart"
			[plotOptions]="chartOptions.plotOptions"
			[labels]="chartOptions.labels"
			[legend]="chartOptions.legend"
			[colors]="chartOptions.colors"
			[responsive]="chartOptions.responsive"
			[stroke]="chartOptions.stroke">
		</apx-chart>
		<div class="dates-default">
		<div class="grid-header">
			<span class="span-base">{{periodBase}}</span>
		</div>
		<div class="grid-header">
			<span class="span-comparacion">{{periodComp}}</span>
		</div>
		</div>
	</div>


	<!-- ? FOOTER -->
	<div class="footer_">
		<!-- ? DATES -->
		<div class="dates_ caption_c2 font_medium color_primary_300">
			<!-- BASE DATE -->
			<span class="span-date" *ngIf="periodBase.length === 2">
				{{periodBase[0] | date:'dd' }} - {{periodBase[1] | date:'dd MMMM yyyy' }}
			</span>
			<!-- COMPARE DATE -->
			<span class="span-date" *ngIf="periodComp.length === 2">
				{{periodComp[0] | date:'dd' }} - {{periodComp[1] | date:'dd MMMM yyyy' }}
			</span>
		</div>

		<div class="leyend_cont caption_c3 color_primary font_medium">
            <div *ngFor="item of dataLegends; let i = index;" class="block_">
                <div class="color_ circle_{{i+1}}"></div>
                <p> {{dataLegends[i]}} </p>
            </div>
		</div>

	</div>
</div>
