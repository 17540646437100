<div class="dropdown-container" [ngClass]="{'disabled': true}">
	<div class="title" *ngIf="showTitleContainer">
		{{ title | translate }}
	</div>
	<!-- Input de búsqueda -->
	<input
		(click)="!disabled && toggleDropdown()"
		type="text"
		class="dropdown"
		[disabled]="true"
		[formControl]="searchControl"
		placeholder="Buscar..."
		(click)="$event.stopPropagation()"
		[value]="getSelectedText()"
	/>
	<!-- Mensaje de error -->
	<div class="error-message">
		<div *ngIf="showError">
			{{ errorMessage }}
		</div>
	</div>
	<!-- Contenedor de las opciones -->
	<div class="container" role="listbox" [class.open]="isDropdownOpen" *ngIf="isDropdownOpen">
		<div
			class="item"
			*ngFor="let item of filteredItems$ | async; let i = index; trackBy: trackByFn"
			role="option"
			[attr.aria-selected]="i === selectedIndex"
			[ngClass]="{ 'item-select': i === selectedIndex }"
			(click)="!disabled && selectItem(item, i)"
		>
			<div class="text">{{ displayKey ? item[displayKey] : item }}</div>
		</div>
	</div>
</div>
