import { Component, Input, ViewChild } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ApexAxisChartSeries, ApexChart, ChartComponent, ApexDataLabels, ApexXAxis, ApexPlotOptions, ApexStroke, ApexFill, ApexResponsive,ApexLegend} from 'ng-apexcharts';

@Component({
  selector: 'use-lockers-compartment',
  templateUrl: './use-lockers-compartment.component.html',
  styleUrls: ['./use-lockers-compartment.component.scss']
})
export class UseLockersCompartmentComponent {
	@ViewChild('chart') chart: ChartComponent;
	public dataChartUseLocker: Partial<ChartOptions>;
	public total = 1690;
	tooltipUseLocker: string;
	@Input() dataBase: number[] = [];
	@Input() dataComp: number[] = [];
	@Input() dataSize: string[] = [];
	@Input() addComp: boolean;
	size = [];
	data = [];
	constructor(private translate: TranslateService) {
	}
	ngOnInit() {
		this.loadDataChart();
		this.translate.setDefaultLang(localStorage.getItem('language').toLowerCase());
		this.tooltipUseLocker = this.translate.instant('tooltip.useLockerBySize');
		this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
			this.translate.use(event.lang);
			this.tooltipUseLocker = this.translate.instant('tooltip.useLockerBySize');
		})
	}

	ngOnChanges() {

		this.size = [];
		this.data = [];
		this.dataSize.forEach((res) => {
			this.size.push(res.substring(0, 3));
		});
		this.dataBase.forEach((res) => {
			this.data.push(res);
		});
		this.loadDataChart();
		if (this.addComp === true) {
			this.dataChartUseLocker.series.push({
				name: this.translate.instant('overview.comparisonPeriod'),
				data: this.dataComp,
			});
		}
	}

	private loadDataChart() {
		this.dataChartUseLocker = {
			series: [
				{
					name: this.translate.instant('overview.basePeriod'),
					data: this.data,
				},
			],
			chart: {
				height:410,
				type: 'bar',
				toolbar: {
					show: true,
					tools:{
						download:`
							<div class="icon_chevron">
								<p class="caption_c2 font_medium color_primary">
									${this.translate.instant('buttons.export')}
								</p>
								<img src="../../../../../../assets/icons/icon-chevron-down.svg">

							</div>
						`,
						pan:false,
						reset:false,
						zoom:false,
						zoomin:false,
						zoomout:false,
						selection:true
					}
				},
				zoom: {
					enabled: true,
				},
			},
			plotOptions: {
				bar: {
					horizontal: true,
					dataLabels: {
						position: 'top',
					},
				},
			},
			xaxis: {
				type: 'category',
				categories: this.size,
			},
			legend: {
				show: false,
				position: 'bottom',
				offsetY: 0,
				markers: {
					fillColors: ['#18345D', '#0082CB'], // Colores personalizados para los marcadores de la leyenda
				},
			},
			dataLabels: {
				enabled: true,
				offsetX: 0,
			},
			stroke: {
				show: false,
				width: 1,
				colors: ['#fdfdfd'],
			},
			fill: {
				opacity: 1,
				colors: ['#18345D', '#0082CB'],
			},
		};
	}
}

export type ChartOptions = {
	series: ApexAxisChartSeries;
	chart: ApexChart;
	dataLabels: ApexDataLabels;
	plotOptions: ApexPlotOptions;
	xaxis: ApexXAxis;
	stroke: ApexStroke;
	fill: ApexFill;
	responsive: ApexResponsive[];
	legend: ApexLegend;
};
