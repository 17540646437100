import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ColDef, GridOptions } from 'ag-grid-community';
import { TableUserService } from 'src/app/core/http/user.service';
import { UsersCarriersInterface } from 'src/app/core/models/users.interfaces';
import * as XLSX from 'xlsx';
import { ToggleComponent } from 'src/app/shared/components/toggle/toggle.component';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { EstatusTableOrderAdminComponent } from '../../estatus-table-order-admin/estatus-table-order-admin.component';
import { DateTextComponent } from '../../date-text/date-text.component';
import { UserDetailModalComponent } from '../../user-detail-modal/user-detail-modal.component';
import { ConfirmChangeStatusUserComponent } from '../../confirm-change-status-user/confirm-change-status-user.component';
import { DialogAuditUserComponent } from '../../dialog-audit-user/dialog-audit-user.component';
@Component({
  selector: 'app-table-user-users-carrier',
  templateUrl: './table-user-users-carrier.component.html',
  styleUrls: ['./table-user-users-carrier.component.scss']
})
export class TableUserUsersCarrierComponent implements OnInit {
	valueInput;
	public columnDefs: ColDef[];
	filterText: string = '';
	carrierID:number;
	gridOptions: GridOptions;
	public filteredData: any[] = [];
	users: UsersCarriersInterface[];
	constructor(private translate: TranslateService,private userService: TableUserService,private dialog: MatDialog) {}
	ngOnInit() {
		this.translate.use(localStorage.getItem('language').toLowerCase());
		this.carrierID = parseInt(localStorage.getItem('courier_id'));
		this.updateDataUser();
		this.loadData();
		this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
			this.translate.get([
				'table-user.fullName',
				'table-user.email',
				'table-user.employeeId',
				'table-user.userType',
				'table-user.activeDays',
				'table-user.expiredDate',
				'table-user.statusUser',
				'table-user.accessQR',
				'table-user.registerDate'
			]).subscribe((t: any) => {
				this.columnDefs = [
					{
						headerName: t['table-user.fullName'],
						field: 'full_name',
						sortable: true,
						autoHeight: true,
						cellClass:'userClassLink',
						cellRenderer: this.centeredCellRenderer,
						headerClass:'borderHeaderUser',
						onCellClicked: ($event) => this.openModalUserDetail($event)
					},
					{
						headerName: t['table-user.employeeId'],
						field: 'id_company_employee',
						sortable: true,
						autoHeight: true,
						cellClass:'userClass',
						headerClass:'borderHeaderUser',
						cellRenderer: this.centeredCellRenderer,
					},
					{
						headerName: t['table-user.email'],
						field: 'email',
						sortable: true,
						autoHeight: true,
						cellClass:'userClass',
						headerClass:'borderHeaderUser',
						cellRenderer: this.centeredCellRenderer,
					},
					{
						headerName: t['table-user.activeDays'],
						field: 'active_days',
						sortable: true,
						autoHeight: true,
						cellClass:'userClass',
						headerClass:'borderHeaderUser',
						cellRenderer: this.centeredCellRenderer,
					},
					{
						headerName: t['table-user.statusUser'],
						field: 'status',
						sortable: true,
						autoHeight: true,
						cellClass:'userClass',
						headerClass:'borderHeaderUser',
						cellRenderer: EstatusTableOrderAdminComponent
					},
					{
						headerName: t['table-user.registerDate'],
						field: 'registration_date',
						sortable: true,
						autoHeight: true,
						cellClass:'userClass',
						headerClass:'borderHeaderUser',
						cellRenderer: DateTextComponent
					},
					{
						headerName: t['table-user.expiredDate'],
						field: 'date_expire_password',
						sortable: true,
						autoHeight: true,
						cellClass:'userClass',
						headerClass:'borderHeaderUser',
						cellRenderer: DateTextComponent
					},
					{
						headerName: t['table-user.accessQR'],
						field: 'enable_token',
						sortable: true,
						autoHeight: true,
						cellClass:'userClass',
						headerClass:'borderHeaderUser',
						cellRenderer: ToggleComponent,
						onCellClicked: ($event) => this.openConfirmChangeStatus($event),
					}
				];
			});
			this.updateDataUser();
		});
	}
	updateDataUser(): void {
		this.translate.use(localStorage.getItem('language').toLowerCase());
		this.gridOptions = {
			columnDefs: [
				{
					headerName: this.translate.instant('table-user.fullName'),
					field: 'full_name',
					sortable: true,
					autoHeight: true,
					cellClass:'userClassLink',
					headerClass:'borderHeaderUser',
					cellRenderer: this.centeredCellRenderer,
					onCellClicked: ($event) => this.openModalUserDetail($event)
				},
				{
					headerName: this.translate.instant('table-user.employeeId'),
					field: 'id_company_employee',
					sortable: true,
					autoHeight: true,
					cellClass:'userClass',
					headerClass:'borderHeaderUser',
					cellRenderer: this.centeredCellRenderer,
				},
				{
					headerName: this.translate.instant('table-user.email'),
					field: 'email',
					sortable: true,
					autoHeight: true,
					cellClass:'userClass',
					headerClass:'borderHeaderUser',
					cellRenderer: this.centeredCellRenderer,
				},
				{
					headerName: this.translate.instant('table-user.activeDays'),
					field: 'active_days',
					sortable: true,
					autoHeight: true,
					cellClass:'userClass',
					headerClass:'borderHeaderUser',
					cellRenderer: this.centeredCellRenderer,
				},
				{
					headerName: this.translate.instant('table-user.statusUser'),
					field: 'status',
					sortable: true,
					autoHeight: true,
					cellClass:'userClass',
					headerClass:'borderHeaderUser',
					cellRenderer: EstatusTableOrderAdminComponent
				},
				{
					headerName: this.translate.instant('table-user.registerDate'),
					field: 'registration_date',
					sortable: true,
					autoHeight: true,
					cellClass:'userClass',
					headerClass:'borderHeaderUser',
					cellRenderer: DateTextComponent
				},
				{
					headerName: this.translate.instant('table-user.expiredDate'),
					field: 'date_expire_password',
					sortable: true,
					autoHeight: true,
					cellClass:'userClass',
					headerClass:'borderHeaderUser',
					cellRenderer: DateTextComponent
				},
				{
					headerName: this.translate.instant('table-user.accessQR'),
					field: 'id_status',
					sortable: true,
					autoHeight: true,
					cellClass:'userClass',
					headerClass:'borderHeaderUser',
					cellRenderer: ToggleComponent,
					onCellClicked: ($event) => this.openConfirmChangeStatus($event),
				}

			],
			animateRows: true,
			pagination: true,
			paginationPageSize: 10,
			headerHeight:59,
			rowHeight:59,
			paginationAutoPageSize: true,
			enableCellChangeFlash: true,
			rowClass:'rowUserClass',
			overlayNoRowsTemplate:'<span style="padding: 10px; border: 1px solid #ccc;">No hay registros</span>',
		}
	}

	onGridReady(params: any) {
		const { api } = params;
		api.sizeColumnsToFit();
	}
	loadData() {
		this.users = [];
		let data = {
			language: localStorage.getItem('language').toLowerCase(),
			is_module_courier : true,
			id_courier: this.carrierID
		};
		this.userService.getCarriers(data).subscribe((res: any) => {
			if (res.result_return) {
				res.result_return.map((item: UsersCarriersInterface) => {
					let hourRegistrationDate = item.registration_date.substring(0,2);
					let minutesRegistrationDate = item.registration_date.substring(3,5);
					let secondsRegistrationDate = item.registration_date.substring(6,8);
					let monthRegistrationDate = parseInt(item.registration_date.substring(9,11));
					let dayRegistrationDate = item.registration_date.substring(12,14);
					let yearRegistrationDate = item.registration_date.substring(15,19);
					let registrationDateString = yearRegistrationDate +'-'+monthRegistrationDate+'-'+dayRegistrationDate+' '+hourRegistrationDate+':'+minutesRegistrationDate+':'+secondsRegistrationDate;

					let hourExpireDate = item.date_expire_password.substring(0,2);
					let minutesExpireDate = item.date_expire_password.substring(3,5);
					let secondsExpireDate = item.date_expire_password.substring(6,8);
					let monthExpireDate = parseInt(item.date_expire_password.substring(9,11));
					let dayExpireDate = item.date_expire_password.substring(12,14);
					let yearExpireDate = item.date_expire_password.substring(15,19);
					let expireDateString = yearExpireDate +'-'+monthExpireDate+'-'+dayExpireDate+' '+hourExpireDate+':'+minutesExpireDate+':'+secondsExpireDate;
					this.users.push({
						id_company_employee: item.id_company_employee,
						id_status: item.id_status,
						status: item.status,
						full_name: item.full_name,
						courier_name: item.courier_name,
						email: item.email,
						company_employee_id: item.company_employee_id,
						date_expire_password: expireDateString,
						registration_date: registrationDateString,
						date_update: item.date_update,
						id_type_employee: item.id_type_employee,
						enable_token: item.enable_token,
						active_days: item.active_days,
						name_type_employee: item.name_type_employee,
						url_token: item.url_token,
						audit_package: item.audit_package,
						audit_employee: item.audit_employee,
					});
				});
				this.users = [...this.users];
			}
		});
	}

	centeredCellRenderer(params) {
		const cellValue = params.value;
		const cellDiv = document.createElement('div');
		cellDiv.style.display = 'flex';
		cellDiv.style.alignItems = 'center';
		cellDiv.style.justifyContent = 'center';
		cellDiv.style.height = '100%';
		cellDiv.innerText = cellValue;
		return cellDiv;
	}

	onFilterTextChange(event) {
		this.filterText = event.target.value;
		event.api.setQuickFilter(this.filterText);
		let api = event.api;
		let temporal = [];
		event.api.forEachNodeAfterFilter((node) => {
			temporal.push(node.data);
		});
		this.filteredData = temporal;
	}

	onExport() {
		const fileName = 'Carriers-Users.xlsx';
		// Configuración para exportar en formato Excel
		const excelParams = {
			fileName: fileName,
			sheetName: 'Carriers-Users',
			columnWidths: [30, 20, 20, 20], // Ancho de las columnas en caracteres
		};
		// Obtener los datos de la tabla
		if (this.filteredData.length > 0) {
			const Data = this.filteredData || [];
			const excelData = Data.map((row) => {
				const { id_status,audit_package,audit_employee,url_token,id_type_employee,id_company_employee,company_employee_id,	...rowDataWithoutFields} = row;
				return rowDataWithoutFields;
			});
			// Crear el libro de Excel y agregar la hoja con los datos
			const wb = XLSX.utils.book_new();
			const ws = XLSX.utils.json_to_sheet(excelData);
			XLSX.utils.book_append_sheet(wb, ws, excelParams.sheetName);
			// Descargar el archivo de Excel
			XLSX.writeFile(wb, fileName);
		} else if (this.users.length > 0 && this.filteredData.length === 0) {
			const Data = this.users || [];
			const excelData = Data.map((row) => {
				const { id_status,audit_package,audit_employee,url_token,id_type_employee,id_company_employee,company_employee_id,	...rowDataWithoutFields} = row;
				return rowDataWithoutFields;
			});
			// Crear el libro de Excel y agregar la hoja con los datos
			const wb = XLSX.utils.book_new();
			const ws = XLSX.utils.json_to_sheet(excelData);
			XLSX.utils.book_append_sheet(wb, ws, excelParams.sheetName);
			// Descargar el archivo de Excel
			XLSX.writeFile(wb, fileName);
		}
	}

	openModalUserDetail(event) {
		const dialogRef = this.dialog.open(UserDetailModalComponent, {
			width: '1456px',
			height: '1030px',
			data: event.data,
		});

		dialogRef.afterClosed().subscribe((result) => {
		});
	}
	openConfirmChangeStatus(event) {
		const dialogRef = this.dialog.open(ConfirmChangeStatusUserComponent, {
			width: '500px',
			height: '200px',
			data: event,
		});

		dialogRef.afterClosed().subscribe((result) => {
			this.loadData();
		});
	}
	openModalUserAudit(event) {
		const dialogRef = this.dialog.open(DialogAuditUserComponent, {
			width: '959px',
			height: '796px',
			data: event.data,
		});

		dialogRef.afterClosed().subscribe((result) => {
		});
	}
}
