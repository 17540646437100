// * Angular
import { Component, Input, OnInit } from '@angular/core';
import { CardData, KpiSummary } from 'src/app/core/models';
// * Services
import { DataService } from 'src/app/core/services/data.service';

@Component({
  selector: 'kpi-orders',
  templateUrl: './kpi-orders.component.html',
  styleUrls: ['./kpi-orders.component.scss']
})
export class KpiOrdersComponent {
	@Input() dataCardSm: CardData;
	@Input() dataCardSummary: KpiSummary[] = [];

	constructor( public dataService : DataService ) {}
}
