// data-sharing.service.ts
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DataSharingService {
  private updateTableSubject = new Subject<void>();

  updateTable$ = this.updateTableSubject.asObservable();

  triggerUpdateTable() {
    this.updateTableSubject.next();
  }
}
