<div class="container-iterations">
    <div class="container-rest-api">
		<p class="headlines_h1 font_medium color_primary">{{'navbar.integrations' | translate | titlecase }}</p>
        <div class="rest-api-block-1">
            <div>
				<div class="row" style="display: flex; border:1px solid var(--color-primary-100); border-radius: 12px;">
					<div class="col-12 col-md-12 col-lg-2 col-xl-2" style="height:100%;">
						<div class="headerName">
							{{'integrations.table.name' | translate | titlecase}}
						</div>
						<div class="labelName"  style="border-bottom: 1px solid var(--color-primary-100);">
							API_VIVIPOST
						</div>
					</div>
					<div class="col-12 col-md-12 col-lg-2 col-xl-2" style="height:100%;  ">
						<div class="headerDateCreation">
							{{'integrations.table.date' | translate | titlecase}}
						</div>
						<div class="labelDateCreation" style="border-bottom: 1px solid var(--color-primary-100);">
							{{this.dateCreationApi}}
						</div>
					</div>
					<div class="col-12 col-md-12 col-lg-2 col-xl-2" style="height:100%;">
						<div class="headerDateCreation">
							{{'integrations.table.apiStatus' | translate | titlecase}}
						</div>
						<div class="labelButton" style="border-bottom: 1px solid var(--color-primary-100);">
							<app-button [activo]="false" btnType="primaryMedium" btnNombre="Suscrito" *ngIf="this.statusApi === 1">
							</app-button>
						</div>
					</div>
					<div class="col-12 col-md-12 col-lg-3 col-xl-3" style="height:100%;">
						<div class="headerName">
							API
						</div>
						<div class="labelApi" style="border-bottom: 1px solid var(--color-primary-100);">
							<div *ngIf="this.showApiText === true">
								{{this.apiText.slice(0, 10)}}...
							</div>
							<div *ngIf="this.showApiText === false">
								*************
							</div>
							<div class="icon-copy" (click)="copiarApi()"></div>
							<div class="icon-view" (click)="showApi()" *ngIf="showApiText === false"> </div>
							<div class="icon-hide" (click)="showApi()" *ngIf="showApiText === true"> </div>
							<div class="icon-info" (mouseover)="showTooltipApi()" (mouseleave)="hideTooltipApi()">
								<tooltip tooltip="integrations.tooltip.apiToolTip" showTooltip="true" *ngIf="this.showTooltipApiValue === true">
								</tooltip>
							</div>
						</div>
					</div>
					<div class="col-12 col-md-12 col-lg-3 col-xl-3" style="height:100%; ">
						<div class="headerName" >
						</div>
						<div class="labelName" style="border-bottom: 1px solid var(--color-primary-100);">
							{{'integrations.passToProduction' | translate}}
						</div>
					</div>
				</div>
            </div>
        </div>
        <div class="rest-api-block-2">
            <span> {{'integrations.rest-api-span-2' | translate }} </span>
            <ul class="list-1">
                <li>{{'integrations.li-1' | translate}}</li>
                <li>{{'integrations.li-2' | translate}}</li>
                <li>{{'integrations.li-3' | translate}}</li>
                <li>{{'integrations.li-4' | translate}}</li>
                <li>{{'integrations.li-5' | translate}}</li>
                <li>{{'integrations.li-6' | translate}}</li>
                <li>{{'integrations.li-7' | translate}}</li>
                <li>{{'integrations.li-8' | translate}}</li>
            </ul>
        </div>
        <div class="rest-api-block-3">
            <span> {{'integrations.block-3-span' | translate }}  </span>
            <div class="link-documentation">
                <a style="cursor: pointer;" (click)="openLink()">
					{{'integrations.block-3-link' | translate }}
				</a>
                <div class="icon-arrow-34"></div>
                <div class="icon-copy" (click)="copiarUrl()"></div>
            </div>
        </div>
    </div>
    <div class="about-webhooks">
        <div class="block-webhooks">
            <span class="block-webhooks-title"> {{"integrations.webhooks.title" | translate }} </span>
            <div>
                <span class="block-webhooks-description"> {{"integrations.webhooks.span-2" | translate }} </span>
                <div class="span-list">
                    <span> {{"integrations.webhooks.span-3" | translate }} </span>
                    <ul class="list-2">
                        <li>{{'integrations.webhooks.li-1' | translate }}</li>
                        <li>{{'integrations.webhooks.li-2' | translate }}</li>
                        <li>{{'integrations.webhooks.li-3' | translate }}</li>
                        <li>{{'integrations.webhooks.li-4' | translate }}</li>
                        <li>{{'integrations.webhooks.li-5' | translate }}</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="block-webhooks">
            <span class="block-webhooks-title"> {{'integrations.address.title' | translate }} </span>
            <span class="block-webhooks-description"> {{'integrations.address.txt' | translate }} </span>
        </div>
        <div class="block-webhooks">
            <span class="block-webhooks-title"> {{'integrations.type.title' | translate }} </span>
            <span class="block-webhooks-description"> {{'integrations.type.txt' | translate }} </span>
        </div>
        <div class="block-webhooks">
            <span class="block-webhooks-title"> {{'integrations.events.title' | translate }} </span>
            <span class="block-webhooks-description"> {{'integrations.events.txt' | translate }} </span>
        </div>
        <div class="block-webhooks">
            <span class="block-webhooks-title"> {{'integrations.events-active.title' | translate }} </span>
            <span class="block-webhooks-description"> {{'integrations.events-active.txt' | translate }} </span>
        </div>
        <div class="block-webhooks">
            <span class="block-webhooks-title"> {{'integrations.events-webhook.title' | translate }} </span>
            <span class="block-webhooks-description"> {{ 'integrations.events-webhook.txt1' | translate }} <span class="linkTxt">{{ linkTxt | translate }}</span> {{ 'integrations.events-webhook.txt2' | translate }}</span>
        </div>
    </div>
    <div class="container-endpoint">
        <div class="grid-table">
            <div class="table-row">
                <div class="th">{{'integrations.th.item' | translate }}</div>
                <div class="th">{{'integrations.th.event' | translate }}</div>
                <div class="th">{{'integrations.th.endpoint' | translate }}</div>
                <div class="th">{{'integrations.th.description' | translate }}</div>
            </div>
            <hr>
            <div class="table-row">
                <div class="td"> {{'integrations.td.item.1' | translate }}</div>
                <div class="td"> {{'integrations.td.event.1' | translate }}</div>
                <div class="td"> {{'integrations.td.endpoint.1' | translate }}</div>
                <div class="td"> {{'integrations.td.description.1' | translate }}</div>
            </div>
            <hr>

            <div class="table-row">
                <div class="td"> {{'integrations.td.item.2' | translate }}</div>
                <div class="td"> {{'integrations.td.event.2' | translate }}</div>
                <div class="td"> {{'integrations.td.endpoint.2' | translate }}</div>
                <div class="td"> {{'integrations.td.description.2' | translate }}</div>
            </div>
            <hr>

            <div class="table-row">
                <div class="td"> {{'integrations.td.item.3' | translate }}</div>
                <div class="td"> {{'integrations.td.event.3' | translate }}</div>
                <div class="td"> {{'integrations.td.endpoint.3' | translate }}</div>
                <div class="td"> {{'integrations.td.description.3' | translate }}</div>
            </div>
            <hr>

            <div class="table-row">
                <div class="td"> {{'integrations.td.item.4' | translate }}</div>
                <div class="td"> {{'integrations.td.event.4' | translate }}</div>
                <div class="td"> {{'integrations.td.endpoint.4' | translate }}</div>
                <div class="td"> {{'integrations.td.description.4' | translate }}</div>
            </div>
            <hr>

            <div class="table-row">
                <div class="td"> {{'integrations.td.item.5' | translate }}</div>
                <div class="td"> {{'integrations.td.event.5' | translate }}</div>
                <div class="td"> {{'integrations.td.endpoint.5' | translate }}</div>
                <div class="td"> {{'integrations.td.description.5' | translate }}</div>
            </div>
        </div>
        <div class="block-webhooks">
            <span class="block-webhooks-title"> {{'integrations.properties.title' | translate }} </span>
            <span class="block-webhooks-description"> {{'integrations.properties.txt' | translate }} </span>
        </div>

    </div>
    <div class="body-block-4">
        <div class="block-body">
            <span class="body-btn"> {{'integrations.body.btn' | translate }} </span>
            <span class="txt-body"> {{'integrations.body.txt' | translate }} </span>
        </div>
        <div class="block-request">
            <div class="row-1">
                <span class="body-btn"> {{'integrations.request.btn' | translate }} </span>
                <span class="txt-body"> {{'integrations.request.txt' | translate }} </span>
                <div class="json1">
                    <span> {{'integrations.request.example' | translate }} </span>
                    <pre> {{ jsonString1 }} </pre>
                </div>
            </div>
        </div>
        <div class="block-request">
            <div class="row-2">
                <span class="body-btn"> {{'integrations.response.btn' | translate }} </span>
                <span class="txt-body"> {{'integrations.response.txt1' | translate }} </span>
                <div class="json2">
                    <div class="list-body">
                        <span class="txt-body"> {{'integrations.response.txt2' | translate }} </span>
                        <ul>
                            <li>{{'integrations.response.error' | translate }}</li>
                            <li>{{'integrations.response.code' | translate }}</li>
                            <li>{{'integrations.response.error_message' | translate }}</li>
                            <li>{{'integrations.response.example' | translate }}</li>
                        </ul>
                    </div>
                    <div class="list-body2" >
                        <span> {{'integrations.response.example' | translate }} </span>
                        <pre> {{ jsonString2 }} </pre>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="example-delivery">
        <span class="block-webhooks-title"> {{'integrations.example-delivery' | translate }} </span>
        <div class="json-example-delivery">
            <pre> {{ jsonString3 }} </pre>
        </div>
    </div>
</div>
