<form class="container-main">
    <div class="container-header">
        <span class="header">
            {{ 'side-bar-panel-control.pref' | translate  }}
        </span>
    </div>
    <div class="container-mode">
        <div class="container-light" (click)="changeMode('light')">
            <div class="img-light"></div>
            <div class="btn-light-mode">
                <div class="icon-light"></div>
                <span>
                    {{ 'side-bar-panel-control.preferences-page.light-mode' | translate  }}
                </span>
            </div>
        </div>
        <div class="container-dark" (click)="changeMode('dark')">
            <div class="img-dark"></div>
            <div class="btn-dark-mode">
                <div class="icon-dark"></div>
                <span>
                    {{ 'side-bar-panel-control.preferences-page.dark-mode' | translate  }}
                </span>
            </div>
        </div>
    </div>
    <div class="container-dropdown">
        <span>
            {{ 'side-bar-panel-control.preferences-page.language' | translate  }}
        </span>
        <div class="dropdown-language-container">
            <div class="selected">
                <div class="btn-es">
                    <div class="icon-{{spanSelect}}"></div>
                    <span> {{ spanSelect | translate }} </span>
                </div>
            </div>
            <div class="option" (click)="selectLang( spanSelect )">
                <div class="btn-en">
                    <div class="icon-{{option}}"></div>
                    <span>{{ option | translate }}</span>
                </div>
            </div>
            <div class="bg-selection"></div>
            <div class="chevron-down"></div>
        </div>
    </div>
	<div class="btn-container" style="display: flex; justify-content: center;">
		<app-button-icon (click)="saveChanges()" [activo]="false" btnNombre="side-bar-panel-control.account-page.btn" btnType="primaryMedium" icon="icon-save">
		</app-button-icon>
	</div>
</form>
