<div class="filtersBox" *ngIf="this.filters === true">
	<div class="row">
		<div class="col-12 col-md-12 col-lg-12 col-xl-12"  style="display: flex; justify-content: space-between;">
			<span class="filtersBoxTitle">
				Filters
			</span>
			<div class="icon-close" (click)="closeModal()">
			</div>
		</div>
		<div class="col-12 col-md-12 col-lg-12 col-xl-12">
			<div class="boxValues">
				<div class="row">
					<div class="col-12 col-md-12 col-lg-12 col-xl-12">
						<div class="selectStatus" (click)="openStatusDiv()">
							<div class="icon-right" *ngIf="openStatus === false">
							</div>
							<div class="icon-down" *ngIf="openStatus === true">
							</div>
							<span class="textDropDown">
								Status
							</span>
						</div>
							<div class="clasificationStatus" *ngIf="openStatus === true" (click)="openStatusInLocker()">
									<div class="icon-down" *ngIf="openInLocker === false">
									</div>
									<div class="icon-up" *ngIf="openInLocker === true">
									</div>
									<span class="textDropDown">
										En locker
									</span>
							</div>
								<div class="statusInLocker" *ngIf="openInLocker === true">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="en_locker_destino"
										[(ngModel)]="checkboxEnLockerDestino"
										name="enLockerDestino"
										(change)="validarCasillasSeleccionadas()"/>
									<span class="textDropDown">
										En locker destino
									</span>
								</div>
								<div class="statusInLocker" *ngIf="openInLocker === true">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="en_locker_origen"
										[(ngModel)]="checkboxEnLockerOrigen"
										name="enLockerOrigen"
										(change)="validarCasillasSeleccionadas()"/>
									<span class="textDropDown">
										En locker origen
									</span>
								</div>
							<div class="clasificationStatus" *ngIf="openStatus === true" (click)="openStatusInProcess()">
									<div class="icon-down" *ngIf="openInProcess === false">
									</div>
									<div class="icon-up" *ngIf="openInProcess === true">
									</div>
									<span class="textDropDown">
										En proceso
									</span>
							</div>
								<div class="statusInLocker"  *ngIf="openInProcess === true">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="first_checkbox"
										[(ngModel)]="checkboxEnTransitoCEDISOrigen"
										(change)="validarCasillasSeleccionadas()"
										/>
									<span class="textDropDown">
										En transito CEDIS origen
									</span>
								</div>
								<div class="statusInLocker"  *ngIf="openInProcess === true">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="first_checkbox"
										[(ngModel)]="checkboxCEDISOrigen"
										(change)="validarCasillasSeleccionadas()" />
									<span class="textDropDown">
										CEDIS origen
									</span>
								</div>
								<div class="statusInLocker"  *ngIf="openInProcess === true">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="first_checkbox"
										[(ngModel)]="checkboxEnRevision"
										(change)="validarCasillasSeleccionadas()"/>
									<span class="textDropDown">
										En revision
									</span>
								</div>
								<div class="statusInLocker"  *ngIf="openInProcess === true">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="first_checkbox"
										[(ngModel)]="checkboxDetenido"
										(change)="validarCasillasSeleccionadas()"/>
									<span class="textDropDown">
										Detenido
									</span>
								</div>
								<div class="statusInLocker"  *ngIf="openInProcess === true">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="first_checkbox"
										[(ngModel)]="checkboxRevisado"
										(change)="validarCasillasSeleccionadas()"/>
									<span class="textDropDown">
										Revisado
									</span>
								</div>
								<div class="statusInLocker"  *ngIf="openInProcess === true">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="first_checkbox"
										[(ngModel)]="checkboxEnTransitoCEDISDestino"
										(change)="validarCasillasSeleccionadas()"/>
									<span class="textDropDown">
										En transito CEDIS destino
									</span>
								</div>
								<div class="statusInLocker"  *ngIf="openInProcess === true">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="first_checkbox"
										[(ngModel)]="checkboxCEDISDestino"
										(change)="validarCasillasSeleccionadas()"/>
									<span class="textDropDown">
										CEDIS destino
									</span>
								</div>
							<div class="clasificationStatus" *ngIf="openStatus === true" (click)="openStatusExpired()">
								<div class="icon-down" *ngIf="openExpired === false">
								</div>
								<div class="icon-up" *ngIf="openExpired === true">
								</div>
								<span class="textDropDown">
									Expiradas
								</span>
							</div>
								<div class="statusInLocker"  *ngIf="openExpired === true">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="first_checkbox"
										[(ngModel)]="checkboxExpirado"
										(change)="validarCasillasSeleccionadas()"/>
									<span class="textDropDown">
										Expirado
									</span>
								</div>
							<div class="clasificationStatus" *ngIf="openStatus === true" (click)="openStatusScheduled()">
								<div class="icon-down" *ngIf="openScheduled === false">
								</div>
								<div class="icon-up" *ngIf="openScheduled === true">
								</div>
								<span class="textDropDown">
									Agendadas
								</span>
							</div>
								<div class="statusInLocker"  *ngIf="openScheduled === true">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="first_checkbox"
										[(ngModel)]="checkboxPagoPendiente"
										(change)="validarCasillasSeleccionadas()"/>
									<span class="textDropDown">
										Pago pendiente
									</span>
								</div>
								<div class="statusInLocker"  *ngIf="openScheduled === true">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="first_checkbox"
										[(ngModel)]="checkboxPagoExpirado"
										(change)="validarCasillasSeleccionadas()"/>
									<span class="textDropDown">
										Pago expirado
									</span>
								</div>
								<div class="statusInLocker"  *ngIf="openScheduled === true">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="first_checkbox"
										[(ngModel)]="checkboxNoSeEntrego"
										(change)="validarCasillasSeleccionadas()"/>
									<span class="textDropDown">
										No se entrego
									</span>
								</div>
								<div class="statusInLocker"  *ngIf="openScheduled === true">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="first_checkbox"
										[(ngModel)]="checkboxAgendado"
										(change)="validarCasillasSeleccionadas()"/>
									<span class="textDropDown">
										Agendado
									</span>
								</div>
							<div class="clasificationStatus" *ngIf="openStatus === true" (click)="openStatusReturns()">
								<div class="icon-down" *ngIf="openReturns === false">
								</div>
								<div class="icon-up" *ngIf="openReturns === true">
								</div>
								<span class="textDropDown">
									Devoluciones
								</span>
							</div>
								<div class="statusInLocker"  *ngIf="openReturns === true">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="first_checkbox"
										[(ngModel)]="checkboxRecolectadoPorCarrier"
										(change)="validarCasillasSeleccionadas()"/>
									<span class="textDropDown">
										Recolectado por carrier
									</span>
								</div>
								<div class="statusInLocker"  *ngIf="openReturns === true">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="first_checkbox"
										[(ngModel)]="checkboxCEDISDestino"
										(change)="validarCasillasSeleccionadas()"/>
									<span class="textDropDown">
										En cedis Destino
									</span>
								</div>
								<div class="statusInLocker"  *ngIf="openReturns === true">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="first_checkbox"
										[(ngModel)]="checkboxRecolectadoDestinatario"
										(change)="validarCasillasSeleccionadas()"/>
									<span class="textDropDown">
										Recolectado destinatario
									</span>
								</div>
								<div class="statusInLocker"  *ngIf="openReturns === true">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="first_checkbox"
										[(ngModel)]="checkboxProcesoDevolucion"
										(change)="validarCasillasSeleccionadas()"/>
									<span class="textDropDown">
										Proceso de devolucion
									</span>
								</div>
								<div class="statusInLocker"  *ngIf="openReturns === true">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="first_checkbox"
										[(ngModel)]="checkboxCEDISOrigen"
										(change)="validarCasillasSeleccionadas()"/>
									<span class="textDropDown">
										En Cedis origen
									</span>
								</div>
								<div class="statusInLocker"  *ngIf="openReturns === true">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="first_checkbox"
										[(ngModel)]="checkboxRecRemitente"
										(change)="validarCasillasSeleccionadas()"/>
									<span class="textDropDown">
										Rec. remitente
									</span>
								</div>
								<div class="statusInLocker"  *ngIf="openReturns === true">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="first_checkbox"
										[(ngModel)]="checkboxProcesoLegal"
										(change)="validarCasillasSeleccionadas()"/>
									<span class="textDropDown">
										Proceso legal
									</span>
								</div>


						<div class="selectStatus" (click)="openRateDiv()">
							<div class="icon-right" *ngIf="openRate === false">
							</div>
							<div class="icon-down" *ngIf="openRate === true">
							</div>
							<span class="textDropDown">
								Calificacion
							</span>
						</div>
							<div class="rates"  *ngIf="openRate === true">
								<input
									type="checkbox"
									class="checkboxInDestinyLocker"
									id="cbox1"
									value="first_checkbox"
									[(ngModel)]="checkboxOne"
									(change)="validarStars()"/>
								<span class="textDropDown">
									1
								</span>
							</div>
							<div class="rates"  *ngIf="openRate === true">
								<input
									type="checkbox"
									class="checkboxInDestinyLocker"
									id="cbox1"
									value="first_checkbox"
									[(ngModel)]="checkboxTwo"
									(change)="validarStars()"/>
								<span class="textDropDown">
									2
								</span>
							</div>
							<div class="rates"  *ngIf="openRate === true">
								<input
									type="checkbox"
									class="checkboxInDestinyLocker"
									id="cbox1"
									value="first_checkbox"
									[(ngModel)]="checkboxThree"
									(change)="validarStars()"/>
								<span class="textDropDown">
									3
								</span>
							</div>
							<div class="rates"  *ngIf="openRate === true">
								<input
									type="checkbox"
									class="checkboxInDestinyLocker"
									id="cbox1"
									value="first_checkbox"
									[(ngModel)]="checkboxFour"
									(change)="validarStars()"/>
								<span class="textDropDown">
									4
								</span>
							</div>
							<div class="rates"  *ngIf="openRate === true">
								<input
									type="checkbox"
									class="checkboxInDestinyLocker"
									id="cbox1"
									value="first_checkbox"
									[(ngModel)]="checkboxFive"
									(change)="validarStars()"/>
								<span class="textDropDown">
									5
								</span>
							</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

