// ANGULAR
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
// COMPONENTS
import {
	CellClickedEvent,
	ColDef,
	GridOptions,
	GridReadyEvent,
} from 'ag-grid-community';
import { ConfirmChangeStatusUserComponent } from '../confirm-change-status-user/confirm-change-status-user.component';
import { DateTextComponent } from '../date-text/date-text.component';
import { DialogAuditUserComponent } from '../dialog-audit-user/dialog-audit-user.component';
import { TranslateService } from '@ngx-translate/core';
import { ToggleComponent } from 'src/app/shared/components/toggle/toggle.component';
import { UserDetailModalComponent } from '../user-detail-modal/user-detail-modal.component';
import * as XLSX from 'xlsx';
// SERVICE
import { AdminClientService } from '../../../../core/http/admin-client.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-client-user-admin',
	templateUrl: './client-user-admin.component.html',
	styleUrls: ['./client-user-admin.component.scss'],
})
export class ClientUserAdminComponent implements OnInit {
	@Input() btnExport: string = 'btnExport';
	data;
	data_admin;
	users: any;
	users_admin;
	columns;
	columns_admin;
	current_date = new Date();
	unixTimeZeroCurrent = Date.parse(String(this.current_date));
	unixTimeZeroRegister;
	idUser;
	searchValue;
	rangeDates: Date[];
	InitialDate = new Date();
	FinalDate = new Date();
	filterText: string = '';
	gridOptions: GridOptions;
	rowData: any[];
	dataTemporal = [];
	public columnDefs: ColDef[];
	public defaultColDef: ColDef = {
		flex: 1,
		minWidth: 70,
		resizable: true,
		sortable: true,
		filter: true,
	};
	public filteredData: any[] = [];
	constructor(
		private adminService: AdminClientService,
		private route: ActivatedRoute,
		private dialog: MatDialog,
		private translate: TranslateService
	) {
		this.gridOptions = {
			columnDefs: [
				{
					headerName: this.translate.instant('table-user.fullName'),
					field: 'fullName',
					width: 240,
					cellClass: 'first-column',
					sortable: true,
					onCellClicked: ($event) => this.openModalUserDetail($event),
					cellRenderer: this.centeredCellRenderer,
				},
				{
					headerName: this.translate.instant('table-user.email'),
					field: 'email',
					width: 270,
					sortable: true,
					cellStyle: {
						'text-align': 'center',
					},
					cellRenderer: this.centeredCellRenderer,
				},
				{
					headerName: this.translate.instant('table-user.employeeId'),
					field: 'employeId',
					sortable: true,
					cellStyle: {
						'text-align': 'center',
					},
					width: 120,
					cellRenderer: this.centeredCellRenderer,
				},
				{
					headerName: this.translate.instant('table-user.userType'),
					field: 'userTypeName',
					sortable: true,
					width: 200,
					cellStyle: {
						'text-align': 'center',
					},
					cellRenderer: this.centeredCellRenderer,
				},
				{
					headerName: this.translate.instant('table-user.activeDays'),
					field: 'nombre_status_employee',
					sortable: true,
					cellStyle: {
						'text-align': 'center',
					},
					width: 120,
					cellRenderer: this.centeredCellRenderer,
				},
				{
					headerName: this.translate.instant(
						'table-user.expiredDate'
					),
					field: 'expires',
					sortable: true,
					cellStyle: {
						'text-align': 'center',
					},
					width: 154,
					cellRenderer: DateTextComponent,
				},
				{
					headerName: this.translate.instant('table-user.accessQR'),
					field: 'accessqr',
					sortable: true,
					cellStyle: {
						'text-align': 'center',
					},
					width: 130,
					cellRenderer: ToggleComponent,
				},
				{
					headerName: this.translate.instant('table-user.statusUser'),
					field: 'status',
					sortable: true,
					cellStyle: {
						'text-align': 'center',
					},
					width: 100,
					onCellClicked: ($event) =>
						this.openConfirmChangeStatus($event),
					// cellRendererFramework: ToggleComponent,
				},
				{
					headerName: this.translate.instant('table-user.actions'),

					sortable: false,
					cellStyle: {
						'text-align': 'center',
					},
					// cellClass:'far fa-edit',
					width: 100,
					onCellClicked: ($event) => this.openModalUserAudit($event),
					cellRenderer: function (params) {
						return '<i class="fas fa-edit fa-lg"></i>';
					},
				},
			],
			animateRows: true,
			pagination: true,
			paginationPageSize: 10,
			paginationAutoPageSize: true,
		}
	}
	ngOnInit() {
		this.data = JSON.stringify(this.users);
		this.getUsers()
		let endDate;
		let startDate;
		this.rangeDates = [startDate, endDate];
		this.InitialDate = this.rangeDates[0];
		this.FinalDate = this.rangeDates[1];
	}
	openModalUserDetail(event) {
		const dialogRef = this.dialog.open(UserDetailModalComponent, {
			width: '759px',
			height: '796px',
			data: event.data,
		});

		dialogRef.afterClosed().subscribe((result) => {
		});
	}
	openConfirmChangeStatus(event) {
		const dialogRef = this.dialog.open(ConfirmChangeStatusUserComponent, {
			width: '500px',
			height: '200px',
			data: event,
		});

		dialogRef.afterClosed().subscribe((result) => {
			const newData = this.rowData.slice();
			newData[result].nombre_status_employee = 'INACTIVE';
			this.rowData = newData;
		});
	}
	openModalUserAudit(event) {
		const dialogRef = this.dialog.open(DialogAuditUserComponent, {
			width: '959px',
			height: '796px',
			data: event.data,
		});

		dialogRef.afterClosed().subscribe((result) => {
		});
	}
	addEventRangeDates() {
		this.InitialDate = this.rangeDates[0];
		if (this.rangeDates[1] !== null) {
			this.FinalDate = this.rangeDates[1];
		}
	}
	async getUsers() {
		let activeDaysVerf;
		let dateRegistre;
		let activeDays;
		let data = {
			language: localStorage.getItem('language').toLowerCase(),
			id_company: parseInt(localStorage.getItem('id_company')),
		};
		let res: any = await this.adminService.getUser(data);

		this.users = [];
		let date_expire_password: any;
		if (!res.mensaje_return.ERROR)
			res.result_return.forEach((e) => {
				dateRegistre = new Date(e.date_register);
				date_expire_password = new Date();
				date_expire_password.setDate(dateRegistre.getDate() + 90);

				this.unixTimeZeroRegister = Date.parse(String(dateRegistre));
				activeDaysVerf =
					this.unixTimeZeroCurrent - this.unixTimeZeroRegister;
				activeDays = Math.round(activeDaysVerf / (1000 * 60 * 60 * 24));

				this.users.push({
					fullName: e.first_name,
					email: e.email,
					employeId: e.company_employee_id,
					id_company_employee: e.id_company_employee,
					activeDays: activeDays,
					expires: getExpires(e),
					actions: ['far fa-edit', 'x', 'far fa-file-archive-o'],
					id: e.id_user,
					id_status: e.id_status,
					status: e.status_user,
					nombre_status_employee: e.nombre_status_employee,
					slide_status: getSlideStatus(e),
					enabledToken: Number(e.enable_token),
					url_token: e.url_token,
					userType: { id: e.id_user_type, name: e.user_type },
					userTypeName: e.user_type,
					userTypeId: e.id_user_type,
					checked: false,
					id_user_type: e.id_user_type,
					result_audit_employee: e.result_audit_employee,
					result_package: e.result_package,
				});
				this.rowData = this.users;

				function getExpires(e: any) {
					return e.id_user_type == 11 || e.id_user_type == 12
						? e.date_expire_password
						: date_expire_password;
				}
				function getSlideStatus(e: any) {
					if (e.nombre_status_employee == 'PASSWORD EXPIRADO') {
						return null;
					} else if (e.nombre_status_employee == 'ACTIVE') {
						return true;
					} else if (e.status_employee != 'N/A') {
						return false;
					} else if (e.status_user == 'ACTIVE') {
						return true;
					} else {
						return false;
					}
				}

				this.rowData = this.users;
			});

		this.data = JSON.stringify(this.users);
	}
	onGridReady(params: GridReadyEvent) {}
	onCellClicked(e: CellClickedEvent): void {}
	centeredCellRenderer(params) {
		const cellValue = params.value;
		const cellDiv = document.createElement('div');
		cellDiv.style.display = 'flex';
		cellDiv.style.alignItems = 'center';
		cellDiv.style.justifyContent = 'center';
		cellDiv.style.height = '100%';
		cellDiv.innerText = cellValue;
		return cellDiv;
	}
	onFilterTextChange(event) {
		this.filterText = event.target.value;
		event.api.setQuickFilter(this.filterText);
		let api = event.api;
		let temporal = [];
		event.api.forEachNodeAfterFilter((node) => {
			temporal.push(node.data);
		});
		this.filteredData = temporal;
	}
	onExport() {
		const fileName = 'Usuarios.xlsx';
		// Configuración para exportar en formato Excel
		const excelParams = {
			fileName: fileName,
			sheetName: 'Usuarios',
			columnWidths: [30, 20, 20, 20], // Ancho de las columnas en caracteres
		};
		// Obtener los datos de la tabla
		if (this.filteredData.length > 0) {
			const Data = this.filteredData || [];
			const excelData = Data.map((row) => {
				return row;
			});
			// Crear el libro de Excel y agregar la hoja con los datos
			const wb = XLSX.utils.book_new();
			const ws = XLSX.utils.json_to_sheet(excelData);
			XLSX.utils.book_append_sheet(wb, ws, excelParams.sheetName);
			//   // Descargar el archivo de Excel
			XLSX.writeFile(wb, fileName);
		} else if (this.rowData.length > 0 && this.filteredData.length === 0) {
			const Data = this.rowData || [];
			const excelData = Data.map((row) => {
				return row;
			});
			//   // Crear el libro de Excel y agregar la hoja con los datos
			const wb = XLSX.utils.book_new();
			const ws = XLSX.utils.json_to_sheet(excelData);
			XLSX.utils.book_append_sheet(wb, ws, excelParams.sheetName);
			//   // Descargar el archivo de Excel
			XLSX.writeFile(wb, fileName);
		}
	}
}

