import { Component, Inject, ViewChild, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, GridOptions } from 'ag-grid-community';
import { EstatusOrdersWarehouseComponent } from '../../../tables-orders/estatus-orders-warehouse/estatus-orders-warehouse.component';
import { WarehouseService } from 'src/app/core/http/warehouse/warehouse.service';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-dialog-create-route',
	templateUrl: './dialog-create-route.component.html',
	styleUrls: ['./dialog-create-route.component.scss']
})
export class DialogToCreateRouteComponent {
	rowData :any[];
	columnDefs: ColDef[];
	gridOptions: GridOptions;
	routeCreated: boolean = false;
	showLoading:boolean = false;
	@ViewChild('agGrid') agGrid: AgGridAngular;
	public ts = inject(TranslateService);
	public warehouseService = inject(WarehouseService)

	// public alertService = inject(AlertService);
	constructor(@Inject(MAT_DIALOG_DATA) private data: any =[],private dialogRef: MatDialogRef<DialogToCreateRouteComponent>, private toastr: ToastrService, private translate: TranslateService){
		this.rowData = data;
		this.gridOptions={
			pagination: true,
			paginationPageSize: 5,
			headerHeight:49,
			rowHeight:49,
			defaultColDef:{
				resizable: false,
				sortable: true,
				flex:1
			}
		}
	}
	ngOnInit(){
		this.updateColDef(localStorage.getItem('language'));
		this.ts.onLangChange.subscribe(lang => {
			this.updateColDef(lang.lang)
		})
	}
	updateColDef(lang){
		const translations = require(`../../../../../../../assets/languaje/${lang}.json`);
		this.columnDefs = [
			{
				headerValueGetter: () => translations.createRoute.dialog.table.id_order,
				field: 'shipping_number',
				resizable: false,
				suppressMovable: true,
				headerClass:'header-text-table',
				cellClass:'text-all-cells',
				minWidth: 120,
				maxWidth: 120
			},
			{
				headerValueGetter: () => translations.createRoute.dialog.table.tracking,
				field: 'tracking_number',
				resizable: false,
				suppressMovable: true,
				headerClass:'header-text-table',
				cellClass:'text-all-cells',
				minWidth: 130,
				maxWidth: 130
			},
			{
				headerValueGetter: () => translations.createRoute.dialog.table.status,
				field: 'status_name',
				resizable: false,
				suppressMovable: true,
				headerClass:'header-text-table',
				cellClass:'text-all-cells',
				minWidth: 150,
				maxWidth: 150,
				cellRenderer		: EstatusOrdersWarehouseComponent
			},
			{
				headerValueGetter: () => translations.createRoute.dialog.table.zones,
				field: 'zones',
				resizable: false,
				suppressMovable: true,
				headerClass:'header-text-table',
				cellClass:'text-all-cells',
				minWidth: 70,
				maxWidth: 70
			},
			{
				headerValueGetter: () => translations.createRoute.dialog.table.originLocker,
				field: 'origin_locker_name',
				resizable: false,
				suppressMovable: true,
				headerClass:'header-text-table',
				cellClass:'text-all-cells',
				minWidth: 200,
				maxWidth: 200
			},
			{
				headerValueGetter: () => translations.createRoute.dialog.table.destinationLocker,
				field: 'destination_locker_name',
				resizable: false,
				suppressMovable: true,
				headerClass:'header-text-table',
				cellClass:'text-all-cells',
				minWidth: 200,
				maxWidth: 200
			}
		]
	}
	onGridReady(params: any) {
		this.agGrid.gridOptions={
			rowHeight:48,
			animateRows:true,
			pagination:true,
			paginationPageSize:10
		}
		this.agGrid.api.sizeColumnsToFit();
	}
	closeDialog(){
		this.dialogRef.close({ routeCreated: false, data:[], rowData:this.rowData });
	}
	createRoute(){
		this.showLoading = true;
		let arrayAssigned:any[] 	= [];
		let arrayExpired:any[] 		= [];
		let objAssigned				= null;
		let objExpired  			= null;
		let dataToSendBack			= null;
		this.routeCreated = true;
		this.rowData.forEach((res)=>{
			if(res.id_status === 47){
				arrayAssigned.push(res.id_package);
				objAssigned = {
					language: localStorage.getItem('language'),
					package_assign: arrayAssigned,
					is_source:true
				}
			}
			else if(res.id_status === 59 || res.id_status === 50){
				arrayExpired.push(res.id_package);
				objExpired = {
					language: localStorage.getItem('language'),
					package_assign: arrayExpired,
					is_source:false
				}
			}
		});
		if(objAssigned !== null ){
			dataToSendBack = {
				assigned: arrayAssigned,
				expiredAssigned: arrayExpired,
			};
			this.warehouseService.assignLockerPackage(objAssigned).then((res:any)=>{
				if(res.body.mensaje_return.CODE === 200){
					this.showLoading =false;
					if(res.body.package_unassigned.length > 0){
						this.dialogRef.close({
							routeCreated: false,
							data: [],
							rowData: this.rowData
						});
					}
					if(res.body.package_assigned.length > 0){
						this.dialogRef.close({
							routeCreated: true,
							data: res.body.package_assigned,
							rowData: this.rowData
						});
					}
				}
			})
		}
		if(objExpired !== null){
			dataToSendBack = {
				assigned: arrayAssigned,
				expiredAssigned: arrayExpired,
			};
			this.warehouseService.assignLockerPackage(objExpired).then((res:any)=>{
				if(res.body.mensaje_return.CODE === 200){
					if(res.body.package_unassigned.length > 0){
						this.dialogRef.close({
							routeCreated: false,
							data: arrayExpired,
						});
					}
					if(res.body.package_assigned.length > 0){
						this.dialogRef.close({
							routeCreated: true,
							data: arrayExpired
						});
					}
				}
			}).catch(err=>{
				console.log(err);
			})
		}
		// this.alertService.showAlert(true);
	}
	closeModal() {
		let dataToSend=null;
		this.dialogRef.close(dataToSend);
	}
	closeModalWithData(dataToSend: any) {
		this.dialogRef.close(dataToSend);
	}
}
