import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AdminClientService } from 'src/app/core/http/admin-client.service';
import { ConfirmChangeStatusUserComponent } from 'src/app/modules/admin-client/components/confirm-change-status-user/confirm-change-status-user.component';


@Component({
  selector: 'app-confirm-change-status-user-vivipost',
  templateUrl: './confirm-change-status-user-vivipost.component.html',
  styleUrls: ['./confirm-change-status-user-vivipost.component.scss']
})
export class ConfirmChangeStatusUserVivipostComponent{
	event: any;

	constructor(
		private dialogRef: MatDialogRef<ConfirmChangeStatusUserVivipostComponent>,
		private http: HttpClient,
		private toastr: ToastrService,
		@Inject(MAT_DIALOG_DATA) private data: any,
		private adminService: AdminClientService,
		private translate: TranslateService
	) {
		this.event = data;
	}

	closeModal() {
		this.dialogRef.close({changes:false});
	}

	async toggleStatus() {
		if(this.data.value === 1){
			let body = {
				language: localStorage.getItem('language').toLowerCase(),
				id_company: parseInt(localStorage.getItem('id_company')),
				data_employee: [
					{
						id_status: 0,
						email: this.event.data.email,
						id_type_employee: this.event.data.id_type_employee
					},
				],
			};
			this.adminService.udpateEmployee(body).then((res:any)=>{
				this.toastr.success(
					'Se deshabilitó el usuario correctamente',
					'Se ha actualizado',
					{
						toastClass: 'toast-extendida',
						timeOut: 5000,
						positionClass: 'toast-bottom-center',
					}
				);
				this.dialogRef.close(this.event.rowIndex);
			}).catch(err=>{
				this.toastr.success(err.mensaje_return.ERROR_MENSAGGE, 'Oh, hubo un error', {
					toastClass: 'toast-erronea',
					timeOut: 5000,
					positionClass: 'toast-bottom-center',
				});
			})
		}
		else if(this.data.value === 0){
			let body = {
				language: localStorage.getItem('language').toLowerCase(),
				id_company: parseInt(localStorage.getItem('id_company')),
				data_employee: [
					{
						id_status: 1,
						email: this.event.data.email,
						id_type_employee: this.event.data.id_type_employee
					},
				],
			};
			this.adminService.udpateEmployee(body).then((res:any)=>{
				this.toastr.success(
					'Se deshabilitó el usuario correctamente',
					'Se ha actualizado',
					{
						toastClass: 'toast-extendida',
						timeOut: 5000,
						positionClass: 'toast-bottom-center',
					}
				);
				this.dialogRef.close(this.event.rowIndex);
			}).catch(err=>{
				this.toastr.success(err.mensaje_return.ERROR_MENSAGGE, 'Oh, hubo un error', {
					toastClass: 'toast-erronea',
					timeOut: 5000,
					positionClass: 'toast-bottom-center',
				});
			})
		}
	}
}
