<!-- ? CONTAINER MAIN -->
<div class="cont_main">
	<!-- ? HEADER -->
	<div class="header headlines_h2 font_semibold color_primary">
		<!-- TITLE -->
		<p>{{'overview.cardsOverview.orderHistoryLog' | translate}}</p>

		 <!-- ? TOOLTIP CONTAINER -->
		 <div class="tooltip_cont_">
			<img src="../../../../../../assets/icons/icon-info.svg" class="icon_tooltip">
			   
			<!-- TOOLTIP -->
			<div class="tooltip_">
				<p class="caption_c2 color_primary_400 font_medium">
					{{ 'tooltip.historical' | translate }}
				</p>
				<!-- PIKE -->
				<div class="pike_2"></div>
			</div>
			<!-- PIKE -->
			<div class="pike"></div>

		</div>

	</div>

	<!-- ? CHART  -->
	<div class="chart" id="chart">
		<apx-chart style="width:99%"
		[series]="chartOptions.series"
		[chart]="chartOptions.chart"
		[xaxis]="chartOptions.xaxis"
		[stroke]="chartOptions.stroke"
		[tooltip]="chartOptions.tooltip"
		[dataLabels]="chartOptions.dataLabels"
		[legend]="chartOptions.legend"
		[markers]="chartOptions.markers"
		[grid]="chartOptions.grid"
		[yaxis]="chartOptions.yaxis"
		[title]="chartOptions.title"
		[responsive]="chartOptions.responsive">
		</apx-chart>
	</div>


	<!-- ? FOOTER -->
	<div class="footer_">
		<!-- ? LEGEND -->
		<div class="leyend_cont caption_c3 color_primary font_medium">
			<!-- BLOCK 1 -->
			<div>
				<!-- SQUARE -->
				<div class="color_ color_1"></div>
				<!-- TXT -->
				<p>{{'overview.orderDelivered' | translate}}</p>
			</div>
			<!-- BLOCK 2 -->
			<div>
				<!-- SQUARE -->
				<div class="color_ color_2"></div>
				<!-- TXT -->
				<p>{{'overview.orderRecolected' | translate}}</p>
			</div>
			<!-- BLOCK 3 -->
			<div>
				<!-- SQUARE -->
				<div class="color_ color_3"></div>
				<!-- TXT -->
				<p>{{'overview.orderExpired' | translate}}</p>
			</div>

		</div>

	</div>


</div>