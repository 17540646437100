<div class="cont_main">
	<app-tables style="width: 100%;"
		[filterText]="selectedMarkerData"
		[gridOptions]="this.gridOptions"
		[showExportButton]="true"
		[showFilterButton]="false"
		[showCalendar]="true"
		[rowData]="this.rowData"
		[colDef]="this.columnDefs"
		nameFileExport="Lockers"
		(datesEmitter)="handleReceivedDates($event)"
		[overlayLoadingTemplate]="overlayLoadingTemplate"
		[overlayNoRowsTemplate]="overlayNoRowsTemplate">
	</app-tables>
</div>
