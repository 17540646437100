<div class="dialog-create-store-container">
	<div class="header-container">
		<div class="label">
			{{ 'storesPage.dialog.dialogEditStore.title' | translate }}
		</div>
		<app-close-button (click)="close()"></app-close-button>
	</div>
	<div class="body-container">
		<div class="left-side">
			<div class="title">
				<div class="label">
					{{ 'storesPage.dialog.dialogCreateStore.info' | translate }}
				</div>
			</div>
			<form [formGroup]="storeForm" class="form-container">
				<app-input-dashboard
					style="width: 100%;"
					[showEraseIcon]="false"
					[inputValue]="storeForm.get('store_name').value"
					(inputValueChange)="storeForm.get('store_name').setValue($event)"
					[header]="'storesPage.dialog.dialogEditStore.fields.storeName'"
					[showError]="storeForm.get('store_name').invalid && storeForm.get('store_name').touched"
					[error]="getErrorMessage('store_name')"
					[haveMaxLength]="true"
					[maxLength]="100"
					autocomplete="off">
				</app-input-dashboard>
				<app-input-dashboard
					style="width: 100%;"
					[showEraseIcon]="false"
					[inputValue]="storeForm.get('store_alias').value"
					(inputValueChange)="storeForm.get('store_alias').setValue($event)"
					[header]="'storesPage.dialog.dialogEditStore.fields.storeAlias'"
					[showError]="storeForm.get('store_alias').invalid && storeForm.get('store_alias').touched"
					[error]="getErrorMessage('store_alias')"
					[haveMaxLength]="true"
					[maxLength]="50"
					autocomplete="off">
				</app-input-dashboard>
				<div style="display: flex; justify-content: space-between; gap:12px">
					<app-dropdown
						style="width: 100%;"
						title="storesPage.dialog.dialogEditStore.fields.state"
						[items]="states"
						[showTitleContainer]="true"
						[disabled]="false"
						[displayKey]="'name'"
						[valueKey]="'id'"
						[valueItem]="this.data.store.id_state"
						placeholder="Seleccione un estado"
						[showError]="storeForm.get('id_state').invalid && storeForm.get('id_state').touched"
						(itemSelected)="onStateSelected($event)">
					</app-dropdown>
					<app-dropdown
						style="width: 100%;"
						title="storesPage.dialog.dialogEditStore.fields.city"
						[items]="cities"
						[showTitleContainer]="true"
						[displayKey]="'name'"
						[valueKey]="'id'"
						[valueItem]="this.data.store.id_city"
						placeholder="Seleccione una ciudad"
						[showError]="storeForm.get('id_city').invalid && storeForm.get('id_city').touched"
						(itemSelected)="onCitySelected($event)">
					</app-dropdown>
				</div>
				<app-input-dashboard
					style="width: 100%;"
					[showEraseIcon]="false"
					[inputValue]="storeForm.get('address').value"
					(inputValueChange)="storeForm.get('address').setValue($event)"
					[header]="'storesPage.dialog.dialogEditStore.fields.address'"
					[showError]="storeForm.get('address').invalid && storeForm.get('address').touched"
					[error]="getErrorMessage('address')"
					[haveMaxLength]="true"
					[maxLength]="255"
					autocomplete="off">
				</app-input-dashboard>
				<div style="display: flex; justify-content: space-between; gap:12px">
					<app-input-dashboard
						style="width: 100%;"
						[showEraseIcon]="false"
						[inputValue]="storeForm.get('latitude').value"
						(inputValueChange)="storeForm.get('latitude').setValue($event)"
						[header]="'storesPage.dialog.dialogEditStore.fields.latitud'"
						[showError]="storeForm.get('latitude').invalid && storeForm.get('latitude').touched"
						[error]="getErrorMessage('latitude')"
						[haveMaxLength]="true"
						[maxLength]="100"
						[inputDisabled]="true"
						autocomplete="off">
					</app-input-dashboard>

					<app-input-dashboard
						style="width: 100%;"
						[showEraseIcon]="false"
						[inputValue]="storeForm.get('longitude').value"
						(inputValueChange)="storeForm.get('longitude').setValue($event)"
						[header]="'storesPage.dialog.dialogEditStore.fields.longitud'"
						[showError]="storeForm.get('longitude').invalid && storeForm.get('longitude').touched"
						[error]="getErrorMessage('longitude')"
						[haveMaxLength]="true"
						[maxLength]="100"
						[inputDisabled]="true"
						autocomplete="off">
					</app-input-dashboard>
				</div>



				<app-input-dashboard
					style="width: 100%;"
					[showEraseIcon]="false"
					[inputValue]="storeForm.get('codigo_referencia').value"
					(inputValueChange)="storeForm.get('codigo_referencia').setValue($event)"
					[header]="'storesPage.dialog.dialogEditStore.fields.codeReference'"
					[showError]="storeForm.get('codigo_referencia').invalid && storeForm.get('codigo_referencia').touched"
					[error]="getErrorMessage('codigo_referencia')"
					[haveMaxLength]="true"
					[maxLength]="6"
					autocomplete="off">
				</app-input-dashboard>
				<div style="display: flex; justify-content: space-between; gap:12px">
					<app-input-dashboard
						style="width: 100%;"
						[showEraseIcon]="false"
						[inputValue]="storeForm.get('opening_time').value"
						(inputValueChange)="storeForm.get('opening_time').setValue($event)"
						[header]="'storesPage.dialog.dialogEditStore.fields.storeOpening'"
						[showError]="storeForm.get('opening_time').invalid && storeForm.get('opening_time').touched"
						[error]="getErrorMessage('opening_time')"
						autocomplete="off">
					</app-input-dashboard>
					<app-input-dashboard
						style="width: 100%;"
						[showEraseIcon]="false"
						[inputValue]="storeForm.get('closing_time').value"
						(inputValueChange)="storeForm.get('closing_time').setValue($event)"
						[header]="'storesPage.dialog.dialogEditStore.fields.storeClosing'"
						[showError]="storeForm.get('closing_time').invalid && storeForm.get('closing_time').touched"
						[error]="getErrorMessage('closing_time')"
						autocomplete="off">
					</app-input-dashboard>
				</div>




			</form>
			<div class="bottoms">
				<app-button-dashboard style="width: 100%;"
					buttonClass="btn-md-secondary"
					(click)="close()"
					label="storesPage.dialog.dialogEditStore.buttons.cancel">
				</app-button-dashboard>
				<app-button-dashboard style="width: 100%;"
					buttonClass="btn-md-primary"
					(click)="saveStore()"
					label="storesPage.dialog.dialogEditStore.buttons.accept">
				</app-button-dashboard>
			</div>
		</div>
		<div class="right-side">
			<div class="title">
				<div class="label">
					{{ 'storesPage.dialog.dialogEditStore.location' | translate }}
				</div>
			</div>
			<div class="map-container">
				<div id="map" class="google_map" #mapContainer></div>
			</div>
		</div>
	</div>
</div>
