import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EncryptDataService } from 'src/app/core/security/encypt-data.service';
import { GoogleMapsService } from 'src/app/core/services/google-maps/google-maps.service';
import { environment } from 'src/environments/environment';
import { DataCompany } from '../../../../../core/models/services/dataLocalStorageinterfaces';
import { Observable, of } from 'rxjs';
import { StoresService } from 'src/app/core/services/stores.service';
import { TimePickerComponent } from 'src/app/shared/components/dashboard-components/time-picker/time-picker.component';
import { TranslateService } from '@ngx-translate/core';
@Component({
	selector: 'app-dialog-create-store',
	templateUrl: './dialog-create-store.component.html',
	styleUrls: ['./dialog-create-store.component.scss']
})
export class DialogCreateStoreComponent implements OnInit,AfterViewInit {
	map: google.maps.Map;
	marker: google.maps.Marker;
	@ViewChild('mapContainer') mapContainer: ElementRef;
	@ViewChild(TimePickerComponent) timePickerComponent: TimePickerComponent;
	center: google.maps.LatLngLiteral = { lat: 25.74682811, lng: -100.351334 }; // Ciudad de México
	zoom = 12; // Nivel de zoom inicial
	storesInformation: FormGroup;
	dataLocal = JSON.parse(this.securityLocalStorage.decryptData(localStorage.getItem('data')));
	cities = [];
	isCityDisabled: boolean = true;
	stateSelected;
	markers: google.maps.Marker[] = []; // Lista de marcadores actuales
	states = [
		{ id: 1, name: 'Aguascalientes', abbreviation: 'AG' },
		{ id: 2, name: 'Baja California', abbreviation: 'BC' },
		{ id: 3, name: 'Baja California Sur', abbreviation: 'BS' },
		{ id: 4, name: 'Campeche', abbreviation: 'CM' },
		{ id: 5, name: 'Chiapas', abbreviation: 'CS' },
		{ id: 6, name: 'Chihuahua', abbreviation: 'CH' },
		{ id: 7, name: 'Ciudad de México (DF)', abbreviation: 'DF' },
		{ id: 8, name: 'Coahuila', abbreviation: 'CO' },
		{ id: 9, name: 'Colima', abbreviation: 'CL' },
		{ id: 10, name: 'Durango', abbreviation: 'DG' },
		{ id: 11, name: 'Estado de México', abbreviation: 'MX' },
		{ id: 12, name: 'Guanajuato', abbreviation: 'GT' },
		{ id: 13, name: 'Guerrero', abbreviation: 'GR' },
		{ id: 14, name: 'Hidalgo', abbreviation: 'HG' },
		{ id: 15, name: 'Jalisco', abbreviation: 'JA' },
		{ id: 16, name: 'Michoacán', abbreviation: 'MI' },
		{ id: 17, name: 'Morelos', abbreviation: 'MO' },
		{ id: 18, name: 'Nayarit', abbreviation: 'NA' },
		{ id: 19, name: 'Nuevo León', abbreviation: 'NL' },
		{ id: 20, name: 'Oaxaca', abbreviation: 'OA' },
		{ id: 21, name: 'Puebla', abbreviation: 'PU' },
		{ id: 22, name: 'Querétaro', abbreviation: 'QT' },
		{ id: 23, name: 'Quintana Roo', abbreviation: 'QR' },
		{ id: 24, name: 'San Luis Potosí', abbreviation: 'SL' },
		{ id: 25, name: 'Sinaloa', abbreviation: 'SI' },
		{ id: 26, name: 'Sonora', abbreviation: 'SO' },
		{ id: 27, name: 'Tabasco', abbreviation: 'TB' },
		{ id: 28, name: 'Tamaulipas', abbreviation: 'TM' },
		{ id: 29, name: 'Tlaxcala', abbreviation: 'TL' },
		{ id: 30, name: 'Veracruz', abbreviation: 'VE' },
		{ id: 31, name: 'Yucatán', abbreviation: 'YU' },
		{ id: 32, name: 'Zacatecas', abbreviation: 'ZA' },
	];
	constructor(
		private translate: TranslateService,
		private dialogRef: MatDialogRef<DialogCreateStoreComponent>,
		private maps: GoogleMapsService,
		private formBuilder: FormBuilder,
		private cdr: ChangeDetectorRef,
		private securityLocalStorage	: EncryptDataService,
		private storeService: StoresService,
		@Inject(MAT_DIALOG_DATA) private data: any
	) {
		this.storesInformation = this.formBuilder.group({
			id_status: [1, Validators.required],
			id_state: [null, Validators.required],
			id_city: [null, Validators.required], // Debe seleccionarse una ciudad
			id_company: [null, Validators.required], // Asociado con la compañía
			store_name: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(100)]], // Entre 3 y 100 caracteres
			store_alias: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(50)]], // Entre 3 y 50 caracteres
			address: [null, [Validators.required, Validators.minLength(5), Validators.maxLength(255)]], // Entre 5 y 255 caracteres
			latitude: [null, [Validators.required, Validators.pattern(/^(-?\d+(\.\d+)?),?\s*(-?\d+(\.\d+)?)$/)]], // Validación de formato de coordenadas
			longitude: [null, [Validators.required, Validators.pattern(/^(-?\d+(\.\d+)?),?\s*(-?\d+(\.\d+)?)$/)]], // Validación de formato de coordenadas
			photo_name: "imagen_fail.jpg",
			// photo_name: [null, [Validators.required]],
			codigo_referencia: [null, [Validators.required, Validators.pattern(/^[A-Z0-9]{6}$/)]], // Solo 6 caracteres alfanuméricos en mayúsculas
			opening_time: [null, [Validators.required]],
			closing_time: [null, [Validators.required]]

		});

	}

	ngOnInit() {
		this.storesInformation.get('id_company').setValue(this.dataLocal.data_company.id_company);
	}

	ngAfterViewInit() {
		this.maps.loadGoogleMaps()
		.then((googleMaps) => {
			this.initMap(googleMaps);
		})
		.catch((error) => {
			console.error('Error al cargar Google Maps:', error);
		});
	}

	async getAllCities(){
		this.storeService.AllCities(this.stateSelected.id).then((res:any)=>{
			console.log(res);
			res.cities.map((element:any)=>{
				this.cities.push({
					id:element.id_city,
					name:element.city
				});
			});
		})
	}

	initMap(googleMaps: any) {
		const mapOptions = {
			center: this.center,
			zoom: this.zoom,
			streetViewControl: true,
			mapTypeControl: false,
            mapTypeControlOptions: {
                mapTypeIds: [googleMaps.MapTypeId.ROADMAP, 'mapId'],
            },
		};

		const style = [
			{
				featureType: 'road',
				elementType: 'labels.text',
				stylers: [{ visibility: 'on' }] // Mostrar nombres de calles
			},
			{
				featureType: 'poi',
				elementType: 'labels',
				stylers: [{ visibility: 'on' }] // Ocultar puntos de interés
			},
			{
				featureType: 'landscape',
				stylers: [{ visibility: 'off' }] // Ocultar los puntos de interés (incluidos los volcanes)
			},
			{
				featureType: 'transit.station.bus',
				stylers: [{ visibility: 'off' }] // Ocultar paradas de autobús
			},
			{
				featureType: 'road',
				elementType: 'labels.icon',
				stylers: [{ visibility: 'off' }] // Ocultar números de carreteras
			},
			{
				featureType: 'transit.station',
				elementType: 'labels.icon',
				stylers: [{ visibility: 'off' }] // Ocultar estaciones del metro
			},
			{
				featureType: 'transit.line',
				elementType: 'geometry',
				stylers: [{ visibility: 'off' }] // Ocultar vías del metro
			},
		];

		this.map = new googleMaps.Map(this.mapContainer.nativeElement, mapOptions);
		var mapType = new googleMaps.StyledMapType(style, { name: 'Grayscale' });
		this.map.mapTypes.set('mapId', mapType);
		this.map.setMapTypeId('mapId');

		// Inicia la búsqueda de lugares
		this.searchOxxoStores(this.center, googleMaps);
		 // Detectar cuando el mapa deja de moverse (idle)
		this.map.addListener('idle', () => {
			const newCenter = this.map.getCenter(); // Obtiene el nuevo centro del mapa

		});
		googleMaps.event.addListener(this.map, 'click', (event: any) => {
			const newCenter = this.map.getCenter(); // Obtiene el nuevo centro del mapa
			this.placeMarker(event.latLng);
			this.searchOxxoStores({ lat: newCenter.lat(), lng: newCenter.lng() }, googleMaps);
		});
	}
	searchOxxoStores(center: google.maps.LatLngLiteral, googleMaps: any) {
		const service = new googleMaps.places.PlacesService(this.map);

		const request = {
			location: center,
			radius: 5000, // Radio de 5 km
			keyword: 'OXXO', // Buscar tiendas OXXO
		};

		// Limpia marcadores existentes antes de agregar nuevos
		if (this.markers) {
			this.markers.forEach(marker => marker.setMap(null));
		}
		this.markers = []; // Reinicia la lista de marcadores

		service.nearbySearch(request, (results, status) => {
			if (status === googleMaps.places.PlacesServiceStatus.OK) {
				results.forEach(place => {
					this.addCustomMarker(place, googleMaps);
				});
			} else {
				console.error('Error al buscar lugares:', status);
			}
		});
	}
	addCustomMarker(place: any, googleMaps: any) {
		const customIcon = {
			url:'../../../../../../assets/images/pinStoreMap.svg' , // Ruta del pin personalizado
			scaledSize: new googleMaps.Size(40, 40), // Tamaño del ícono
		};

		const marker = new googleMaps.Marker({
			position: place.geometry.location,
			map: this.map,
			title: place.name,
			icon: customIcon, // Usa el marcador personalizado
			clickable: false, // Deshabilita la interacción
		});
		this.markers.push(marker); // Agrega el marcador a la lista para futuras referencias
	}
	addPlaceMarker(place: any, googleMaps: any) {
		const customIcon = {
			url:'../../../../../../assets/images/pinStoreSelected.svg' , // Ruta del pin personalizado
			scaledSize: new googleMaps.Size(40, 40), // Tamaño del ícono
		};
		const marker = new googleMaps.Marker({
			position: place.geometry.location,
			map: this.map,
			title: place.name,
			icon: customIcon,
		});

		const infoWindow = new googleMaps.InfoWindow({
			content: `<h3>${place.name}</h3><p>${place.vicinity}</p>`,
		});

		marker.addListener('click', () => {
			infoWindow.open(this.map, marker);
		});
	}
	placeMarker(location: google.maps.LatLng) {
		if (this.marker) {
			this.marker.setMap(null);
		}
		this.marker = new google.maps.Marker({
			position: location,
			map: this.map,
		});

		const geocoder = new google.maps.Geocoder();
		geocoder.geocode({ location: location }, (results, status) => {
			if (status === google.maps.GeocoderStatus.OK && results[0]) {
			const address = results[0].formatted_address;
			this.storesInformation.get('address').setValue(address);
			}
			this.cdr.detectChanges();
		});

		this.storesInformation.get('latitude').setValue(Number(location.lat().toFixed(8)));
		this.storesInformation.get('longitude').setValue(Number(location.lng().toFixed(8)));
		this.cdr.detectChanges();
	}

	getStreetViewImageForPlace(placeId: string): string {
		const apiKey = environment.key_google_maps;
		return `https://maps.googleapis.com/maps/api/streetview?size=600x400&location=place_id:${placeId}&key=${apiKey}`;
	}

	saveStore(){
		Object.values(this.storesInformation.controls).forEach(control => {
			control.markAsTouched(); // Marca el control como tocado
		});
		const isValidTime = this.validateTime();
		if (!isValidTime) {
			this.timePickerComponent.showError = true;
			this.timePickerComponent.errorMessage = 'El horario de apertura es obligatorio';
			return; // Evitar continuar si el tiempo no es válido
		}

		if(this.storesInformation.valid){
			console.log('storesInformation ->', this.storesInformation.value);
			// Wrap the object in an array to match the expected structure
			let obj = {
				store_data: [this.storesInformation.value],
			};

			this.storeService.addStore(obj).then((res: any) => {
				console.log('Response from addStore:', res);
				if(res.status===200){
					this.dialogRef.close({
						storeCreated:true,
						store:this.storesInformation.value
					});
				}
			}).catch((error: any) => {
				console.error('Error saving store:', error);
			});
		}
	}
	private validateTime(): boolean {
		// Aquí va la validación de hora y minuto
		// Ejemplo:
		if (this.timePickerComponent.selectedHour < 1 || this.timePickerComponent.selectedHour > 12) {
			return false;
		}
		if (this.timePickerComponent.selectedMinute < 0 || this.timePickerComponent.selectedMinute > 59) {
			return false;
		}
		return true;
	}
	closeModal() {
		this.dialogRef.close({
			storeCreated:false
		});
	}
	onStateSelected(state: any) {
		if (state) {
			this.isCityDisabled = false;  // Enable the city dropdown once a state is selected
			this.storesInformation.get('id_state').setValue(state.id);
			console.log(state);
			this.stateSelected = state;
			this.getAllCities();
		} else {
			this.isCityDisabled = true;   // Keep city dropdown disabled if no state is selected
		}
	}
	onCitySelected(city: any) {
		this.storesInformation.get('id_city').setValue(city.id);
	}
	openingTimeSelected(event){
		console.log('open Time',event)
		this.storesInformation.get('opening_time').setValue(event);
		this.storesInformation.setValidators(openingClosingTimeValidator);
	}
	closingTimeSelected(event){
		console.log('close Time',event);
		this.storesInformation.get('closing_time').setValue(event);
		this.storesInformation.setValidators(openingClosingTimeValidator);
	}
	getErrorMessage(controlName: string): string {
		const control = this.storesInformation.get(controlName);
		if (control?.hasError('required')) {
			return 'Este campo es obligatorio';
		}
		if (control?.hasError('minlength')) {
			return `El mínimo permitido es ${control.errors?.minlength.requiredLength} caracteres.`;
		}
		if (control?.hasError('maxlength')) {
			return `El máximo permitido es ${control.errors?.maxlength.requiredLength} caracteres.`;
		}
		if (control?.hasError('pattern')) {
			return 'El formato no es válido';
		}
		return '';
	}
}

export function openingClosingTimeValidator(group: AbstractControl): ValidationErrors | null {
	const openingTime = group.get('opening_time')?.value;
	const closingTime = group.get('closing_time')?.value;
	if (openingTime && closingTime && openingTime >= closingTime) {
	  return { invalidTimeRange: true }; // Error si la apertura es después del cierre
	}
	return null;
}
