//Angular
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
//Modules
import { HighchartsChartModule } from 'highcharts-angular';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgApexchartsModule } from 'ng-apexcharts';
import { SharedModule } from '../../../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
//Components
import { AccountSuperadminComponent } from './account-profile/account-superadmin/account-superadmin.component';
import { ActivityTableAdminComponent } from './activity-table-admin/activity-table-admin.component';
import { AddNewCarrierComponent } from './add-new-carrier/add-new-carrier.component';
import { AddNewUserComponent } from './add-new-user/add-new-user.component';
import { AgGridModule } from 'ag-grid-angular';
import { AuditTableAdminComponent } from './audit-table-admin/audit-table-admin.component';
import { AverageCollectionComponent } from './average-collection/average-collection.component';
import { AveragePickupComponent } from './average-pickup/average-pickup.component';
import { CalendarModule } from 'primeng/calendar';
import { CalificationsAverageComponent } from './califications-average/califications-average.component';
import { CamTableAdminComponent } from './cam-table-admin/cam-table-admin.component';
import { CardsAdminComponent } from './cards-admin/cards-admin.component';
import { CardsSummaryComponent } from './cards-summary/cards-summary.component';
import { CarrierMetricGraphicComponent } from './carrier-metric-graphic/carrier-metric-graphic.component';
import { ChartBarAdminComponent } from './chart-bar-admin/chart-bar-admin.component';
import { ChartClientAdminComponent } from './chart-client-admin/chart-client-admin.component';
import { ChartDonutSummaryComponent } from './chart-donut-summary/chart-donut-summary.component';
import { ChartsGrid1Module } from './grid1/charts-grid1/charts-grid1.module';
import { ChartsGrid2Module } from './grid2/charts-grid2/charts-grid2.module';
import { ChartsOverviewModule } from './charts/charts-overview.module';
import { ClientAdminComponent } from './client-admin/client-admin.component';
import { ClientProfileAdminComponent } from './client-profile-admin/client-profile-admin.component';
import { ClientsMetricsAdminComponent } from './clients-metrics-admin/clients-metrics-admin.component';
import { ClientTableAdminComponent } from './client-table-admin/client-table-admin.component';
import { ClientTableComponent } from './client-table/client-table.component';
import { ClientUserAdminComponent } from './client-user-admin/client-user-admin.component';
import { CompartmentSizeComponent } from './compartment-size/compartment-size.component';
import { CompletedTableAdminComponent } from './completed-table-admin/completed-table-admin.component';
import { ConfirmChangeStatusUserComponent } from './confirm-change-status-user/confirm-change-status-user.component';
import { CreateUserAdminComponent } from './create-user-admin/create-user-admin.component';
import { DateTextComponent } from './date-text/date-text.component';
import { DateTextExpiredComponent } from './date-text-expired/date-text-expired.component';
import { DialogAuditUserComponent } from './dialog-audit-user/dialog-audit-user.component';
import { DialogCarrierDetailComponent } from './dialog-carrier-detail/dialog-carrier-detail.component';
import { DialogChangeStatusCarrierComponent } from './dialog-change-status-carrier/dialog-change-status-carrier.component';
import { DialogDeliverPersonallyComponent } from './dialog-deliver-personally/dialog-deliver-personally.component';
import { DialogEraseOrderComponent } from './dialog-erase-order/dialog-erase-order.component';
import { DialogEstatusOxxoToOxxoComponent } from './tables-oxxo-to-oxxo/dialog-estatus-oxxo-to-oxxo/dialog-estatus-oxxo-to-oxxo.component';
import { DialogImageLockerComponent } from './dialog-image-locker/dialog-image-locker.component';
import { DialogModule } from 'primeng/dialog';
import { DialogOrderDetailComponent } from './dialog-order-detail/dialog-order-detail.component';
import { DialogOxxoToOxxoDetailComponent } from './dialog-oxxo-to-oxxo-detail/dialog-oxxo-to-oxxo-detail.component';
import { DialogQrTockenComponent } from './dialog-qr-tocken/dialog-qr-tocken.component';
import { DialogRecordatoriosComponent } from './dialog-recordatorios/dialog-recordatorios.component';
import { DoorStatusAdminComponent } from './door-status-admin/door-status-admin.component';
import { DropdownModule } from 'primeng/dropdown';
import { EraseOrderComponent } from './erase-order/erase-order.component';
import { EstatusOrderComponent } from './estatus-order/estatus-order.component';
import { EstatusOxxoToOxxoComponent } from './tables-oxxo-to-oxxo/estatus-oxxo-to-oxxo/estatus-oxxo-to-oxxo.component';
import { EstatusTableCarrierOrderComponent } from './estatus-table-carrier-order/estatus-table-carrier-order.component';
import { EstatusTableOrderAdminComponent } from './estatus-table-order-admin/estatus-table-order-admin.component';
import { ExpiredTableAdminComponent } from './expired-table-admin/expired-table-admin.component';
import { FilterCarriersComponent } from './filter-carriers/filter-carriers.component';
import { FilterCarriersOrdersComponent } from './filter-carriers-orders/filter-carriers-orders.component';
import { FilterClientsComponent } from './filter-clients/filter-clients.component';
import { FilterOrdersComponent } from './filter-orders/filter-orders.component';
import { FilterOxxoToOxxoComponent } from './tables-oxxo-to-oxxo/filter-oxxo-to-oxxo/filter-oxxo-to-oxxo.component';
import { FooterAdminComponent } from './footer-admin/footer-admin.component';
import { GeneralTableAdminComponent } from './general-table-admin/general-table-admin.component';
import { Grid1Component } from './grid1/grid1.component';
import { Grid2Component } from './grid2/grid2.component';
import { GridLockerComponent } from './grid-locker/grid-locker.component';
import { HistoricalCardsAdminComponent } from './historical-cards-admin/historical-cards-admin.component';
import { HistoricalPackagesComponent } from './historical-packages/historical-packages.component';
import { KpiLockerComponent } from './kpi-locker/kpi-locker.component';
import { LocationAdminComponent } from './location-admin/location-admin.component';
import { LockerGridAdminComponent } from './locker-grid-admin/locker-grid-admin.component';
import { LockerMapComponent } from './locker-map/locker-map.component';
import { LockerOperationComponent } from './overview-components/locker-operation/locker-operation.component';
import { LockersClientGridAdminComponent } from './lockers-client-grid-admin/lockers-client-grid-admin.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MaterialModule } from '../../../shared/libraries/angular-material/material.module';
import { MatNativeDateModule } from '@angular/material/core';
import { MonthlyAdminComponent } from './monthly-admin/monthly-admin.component';
import { MonthPickerComponent } from './month-picker/month-picker.component';
import { OrderAdminComponent } from './order-admin/order-admin.component';
import { OrderClientAdminComponent } from './order-client-admin/order-client-admin.component';
import { CardsOverviewComponent } from './overview-components/cards-overview/cards-overview.component';
import { FrecuencyUseComponent } from './overview-components/frecuency-use/frecuency-use.component';
import { HeadquartersComponent } from './overview-components/headquarters/headquarters.component';
import { HistoricalOrdersComponent } from './overview-components/historical-orders/historical-orders.component';
import { OrderMetricsAdminComponent } from './order-metrics-admin/order-metrics-admin.component';
import { OrdersTableAdminComponent } from './orders-table-admin/orders-table-admin.component';
import { OxxoToOxxoExpiredComponent } from './tables-oxxo-to-oxxo/oxxo-to-oxxo-expired/oxxo-to-oxxo-expired.component';
import { OxxoToOxxoGeneralComponent } from './tables-oxxo-to-oxxo/oxxo-to-oxxo-general/oxxo-to-oxxo-general.component';
import { OxxoToOxxoInLockerComponent } from './tables-oxxo-to-oxxo/oxxo-to-oxxo-in-locker/oxxo-to-oxxo-in-locker.component';
import { OxxoToOxxoReturnsComponent } from './tables-oxxo-to-oxxo/oxxo-to-oxxo-returns/oxxo-to-oxxo-returns.component';
import { OxxoToOxxoScheduledComponent } from './tables-oxxo-to-oxxo/oxxo-to-oxxo-scheduled/oxxo-to-oxxo-scheduled.component';
import { OxxoToOxxoWarehouseComponent } from './tables-oxxo-to-oxxo/oxxo-to-oxxo-warehouse/oxxo-to-oxxo-warehouse.component';
import { PackagesCollectedReturnedComponent } from './packages-collected-returned/packages-collected-returned.component';
import { PackagesDeliveredAdminComponent } from './packages-delivered-admin/packages-delivered-admin.component';
import { PackagesDeliverySizesComponent } from './packages-delivery-sizes/packages-delivery-sizes.component';
import { PackagesReturnAdminComponent } from './packages-return-admin/packages-return-admin.component';
import { PasswordSuperadminComponent } from './account-profile/password-superadmin/password-superadmin.component';
import { PendingTableAdminComponent } from './pending-table-admin/pending-table-admin.component';
import { PieChartAdminComponent } from './pie-chart-admin/pie-chart-admin.component';
import { PreferencesSuperadminComponent } from './account-profile/preferences-superadmin/preferences-superadmin.component';
import { RatingAverageComponent } from './overview-components/rating-average/rating-average.component';
import { RecolectedRefundComponent } from './overview-components/recolected-refund/recolected-refund.component';
import { RefundTableAdminComponent } from './refund-table-admin/refund-table-admin.component';
import { RegisterAdminComponent } from './register-admin/register-admin.component';
import { ReturnClientsComponent } from './return-clients/return-clients.component';
import { ReturnLotesComponent } from './return-lotes/return-lotes.component';
import { ReturnOrdersComponent } from './return-orders/return-orders.component';
import { ScheduleTableAdminComponent } from './schedule-table-admin/schedule-table-admin.component';
import { SelectComponent } from './select/select.component';
import { SelectLockerComponent } from './select-locker/select-locker.component';
import { StarRatingAdminComponent } from './star-rating-admin/star-rating-admin.component';
import { SummaryTableAdminComponent } from './summary-table-admin/summary-table-admin.component';
import { TableCarrierAdminComponent } from './table-carrier-admin/table-carrier-admin.component';
import { TableCarrierOrderComponent } from './table-carrier-order/table-carrier-order.component';
import { TableCarrierUserComponent } from './table-carrier-user/table-carrier-user.component';
import { TableExpiredAdminComponent } from './table-expired-admin/table-expired-admin.component';
import { TableExpiredCarrierComponent } from './tables-carriers/table-expired-carrier/table-expired-carrier.component';
import { TableFilterByDayComponent } from './table-filter-by-day/table-filter-by-day.component';
import { TableFilterByYearComponent } from './table-filter-by-year/table-filter-by-year.component';
import { TableGeneralAdminComponent } from './table-general-admin/table-general-admin.component';
import { TableGeneralCarrierComponent } from './tables-carriers/table-general-carrier/table-general-carrier.component';
import { TableLocalAdminsComponent } from './table-local-admins/table-local-admins.component';
import { TableOrderssAdminComponent } from './table-orderss-admin/table-orderss-admin.component';
import { TablePAdminComponent } from './table-p-admin/table-p-admin.component';
import { TableScheduledCarrierComponent } from './tables-carriers/table-scheduled-carrier/table-scheduled-carrier.component';
import { TableSuperAdminComponent } from './table-super-admin/table-super-admin.component';
import { TableSupervisorComponent } from './table-supervisor/table-supervisor.component';
import { TableUserCarrierAdminCarrierComponent } from './tables-carriers/table-user-carrier-admin-carrier/table-user-carrier-admin-carrier.component';
import { TableUserComponent } from './table-user/table-user.component';
import { TableUserLocalAdminCarrierComponent } from './tables-carriers/table-user-local-admin-carrier/table-user-local-admin-carrier.component';
import { TableUserSuperAdminCarrierComponent } from './tables-carriers/table-user-super-admin-carrier/table-user-super-admin-carrier.component';
import { TableUserSupervisorCarrierComponent } from './tables-carriers/table-user-supervisor-carrier/table-user-supervisor-carrier.component';
import { TableUserUsersCarrierComponent } from './tables-carriers/table-user-users-carrier/table-user-users-carrier.component';
import { TestModule } from 'src/app/shared/test/test.module';
import { UsageStateComponent } from './usage-state/usage-state.component';
import { UseLockersCompartmentComponent } from './overview-components/use-lockers-compartment/use-lockers-compartment.component';
import { UserDetailModalComponent } from './user-detail-modal/user-detail-modal.component';
import { VersionAdminComponent } from './version-admin/version-admin.component';
import { KpiLockersComponent } from './lockers-components/kpi-lockers/kpi-lockers.component';
import { TableLockersComponent } from './lockers-components/table-lockers/table-lockers.component';
import { EstatusActivityPackageComponent } from './estatus-activity-package/estatus-activity-package.component';
import { OrderMetricsComponent } from './orders-components/order-metrics/order-metrics.component';
import { KpiOrdersComponent } from './orders-components/kpi-orders/kpi-orders.component';
import { GeneralTableComponent } from './orders-components/general-table/general-table.component';
import { ScheduledTableComponent } from './orders-components/scheduled-table/scheduled-table.component';
import { ExpiredTableComponent } from './orders-components/expired-table/expired-table.component';
import { CardCarriersComponent } from './carriers-components/card-carriers/card-carriers.component';
import { OxxoToOxxoGeneralTableComponent } from './oxxo-to-oxxo-components/oxxo-to-oxxo-general-table/oxxo-to-oxxo-general-table.component';
import { OxxoToOxxoLockersTableComponent } from './oxxo-to-oxxo-components/oxxo-to-oxxo-lockers-table/oxxo-to-oxxo-lockers-table.component';
import { OxxoToOxxoProcessTableComponent } from './oxxo-to-oxxo-components/oxxo-to-oxxo-process-table/oxxo-to-oxxo-process-table.component';
import { OxxoToOxxoExpiredTableComponent } from './oxxo-to-oxxo-components/oxxo-to-oxxo-expired-table/oxxo-to-oxxo-expired-table.component';
import { OxxoToOxxoScheduleTableComponent } from './oxxo-to-oxxo-components/oxxo-to-oxxo-schedule-table/oxxo-to-oxxo-schedule-table.component';
import { OxxoToOxxoReturnsTableComponent } from './oxxo-to-oxxo-components/oxxo-to-oxxo-returns-table/oxxo-to-oxxo-returns-table.component';
import { ClientsMetricsComponent } from './clients-components/clients-metrics/clients-metrics.component';
import { KpiClientsComponent } from './clients-components/kpi-clients/kpi-clients.component';
import { ClientsTableComponent } from './clients-components/clients-table/clients-table.component';
import { CardUsersComponent } from './users-components/card-users/card-users.component';
import { UsersTableComponent } from './users-components/users-table/users-table.component';
import { SupervisorsTableComponent } from './users-components/supervisors-table/supervisors-table.component';
import { LocalAdminTableComponent } from './users-components/local-admin-table/local-admin-table.component';
import { SuperAdminTableComponent } from './users-components/super-admin-table/super-admin-table.component';
import { CarrierAdminTableComponent } from './users-components/carrier-admin-table/carrier-admin-table.component';
import { ScheduledTableLockerComponent } from './scheduled-table-locker/scheduled-table-locker.component';
import { DialogOrderDetailScheduledComponent } from './dialog-order-detail-scheduled/dialog-order-detail-scheduled.component';
import { LinkCarrierDetailComponent } from './carriers-components/link-carrier-detail/link-carrier-detail.component';
import { LinkUserDetailComponent } from './users-components/link-user-detail/link-user-detail.component';
import { FilterAdminAllComponent } from './filters/filter-admin-all/filter-admin-all.component';
import { FilterAdminProcessComponent } from './filters/filter-admin-process/filter-admin-process.component';
import { FilterAdminScheduledComponent } from './filters/filter-admin-scheduled/filter-admin-scheduled.component';
import { FilterAdminInLockerComponent } from './filters/filter-admin-in-locker/filter-admin-in-locker.component';
import { FilterAdminExpiredComponent } from './filters/filter-admin-expired/filter-admin-expired.component';
import { DialogCreateStoreComponent } from './dialogs/dialog-create-store/dialog-create-store.component';
import { DialogStoreDetailComponent } from './dialogs/dialog-store-detail/dialog-store-detail.component';
import { LinkStoreDetailComponent } from './link-store-detail/link-store-detail.component';
import { DialogEditStoreComponent } from './dialogs/dialog-edit-store/dialog-edit-store.component';
import { EstatusStorePackageComponent } from './estatus-store-package/estatus-store-package.component';


@NgModule({
	declarations: [
		AccountSuperadminComponent,
		ActivityTableAdminComponent,
		AddNewCarrierComponent,
		AddNewUserComponent,
		AuditTableAdminComponent,
		AverageCollectionComponent,
		AveragePickupComponent,
		CalificationsAverageComponent,
		CamTableAdminComponent,
		CardsAdminComponent,
		CardsOverviewComponent,
		CardsSummaryComponent,
		CarrierMetricGraphicComponent,
		ChartBarAdminComponent,
		ChartClientAdminComponent,
		ChartDonutSummaryComponent,
		ClientAdminComponent,
		ClientProfileAdminComponent,
		ClientsMetricsAdminComponent,
		ClientTableAdminComponent,
		ClientTableComponent,
		ClientUserAdminComponent,
		CompartmentSizeComponent,
		CompletedTableAdminComponent,
		ConfirmChangeStatusUserComponent,
		CreateUserAdminComponent,
		DateTextComponent,
		DateTextExpiredComponent,
		DialogAuditUserComponent,
		DialogCarrierDetailComponent,
		DialogCreateStoreComponent,
		DialogChangeStatusCarrierComponent,
		DialogDeliverPersonallyComponent,
		DialogEraseOrderComponent,
		DialogEstatusOxxoToOxxoComponent,
		DialogImageLockerComponent,
		DialogOrderDetailComponent,
		DialogOxxoToOxxoDetailComponent,
		DialogQrTockenComponent,
		DialogRecordatoriosComponent,
		DoorStatusAdminComponent,
		EraseOrderComponent,
		EraseOrderComponent,
		EstatusOrderComponent,
		EstatusOxxoToOxxoComponent,
		EstatusTableCarrierOrderComponent,
		EstatusTableOrderAdminComponent,
		ExpiredTableAdminComponent,
		FilterCarriersComponent,
		FilterCarriersOrdersComponent,
		FilterClientsComponent,
		FilterOrdersComponent,
		FilterOxxoToOxxoComponent,
		FooterAdminComponent,
		FrecuencyUseComponent,
		GeneralTableAdminComponent,
		Grid1Component,
		Grid2Component,
		GridLockerComponent,
		HeadquartersComponent,
		HistoricalCardsAdminComponent,
		HistoricalOrdersComponent,
		HistoricalPackagesComponent,
		KpiLockerComponent,
		LocationAdminComponent,
		LockerGridAdminComponent,
		LockerMapComponent,
		LockerOperationComponent,
		LockersClientGridAdminComponent,
		MonthlyAdminComponent,
		MonthPickerComponent,
		OrderAdminComponent,
		OrderClientAdminComponent,
		OrderMetricsAdminComponent,
		OrdersTableAdminComponent,
		OxxoToOxxoExpiredComponent,
		OxxoToOxxoGeneralComponent,
		OxxoToOxxoInLockerComponent,
		OxxoToOxxoReturnsComponent,
		OxxoToOxxoScheduledComponent,
		OxxoToOxxoWarehouseComponent,
		PackagesCollectedReturnedComponent,
		PackagesDeliveredAdminComponent,
		PackagesDeliverySizesComponent,
		PackagesReturnAdminComponent,
		PasswordSuperadminComponent,
		PendingTableAdminComponent,
		PieChartAdminComponent,
		PreferencesSuperadminComponent,
		RatingAverageComponent,
		RecolectedRefundComponent,
		RefundTableAdminComponent,
		RegisterAdminComponent,
		ReturnClientsComponent,
		ReturnLotesComponent,
		ReturnOrdersComponent,
		ScheduleTableAdminComponent,
		SelectComponent,
		SelectLockerComponent,
		StarRatingAdminComponent,
		SummaryTableAdminComponent,
		TableCarrierAdminComponent,
		TableCarrierOrderComponent,
		TableCarrierUserComponent,
		TableExpiredAdminComponent,
		TableExpiredCarrierComponent,
		TableFilterByDayComponent,
		TableFilterByYearComponent,
		TableGeneralAdminComponent,
		TableGeneralCarrierComponent,
		TableLocalAdminsComponent,
		TableOrderssAdminComponent,
		TablePAdminComponent,
		TableScheduledCarrierComponent,
		TableSuperAdminComponent,
		TableSupervisorComponent,
		TableUserCarrierAdminCarrierComponent,
		TableUserComponent,
		TableUserLocalAdminCarrierComponent,
		TableUserSuperAdminCarrierComponent,
		TableUserSupervisorCarrierComponent,
		TableUserUsersCarrierComponent,
		UsageStateComponent,
		UseLockersCompartmentComponent,
		UserDetailModalComponent,
		VersionAdminComponent,
		KpiLockersComponent,
		TableLockersComponent,
		EstatusActivityPackageComponent,
		OrderMetricsComponent,
		KpiOrdersComponent,
		GeneralTableComponent,
		ScheduledTableComponent,
		ExpiredTableComponent,
		CardCarriersComponent,
		OxxoToOxxoGeneralTableComponent,
		OxxoToOxxoLockersTableComponent,
		OxxoToOxxoProcessTableComponent,
		OxxoToOxxoExpiredTableComponent,
		OxxoToOxxoScheduleTableComponent,
		OxxoToOxxoReturnsTableComponent,
		ClientsMetricsComponent,
		KpiClientsComponent,
		ClientsTableComponent,
		CardUsersComponent,
		UsersTableComponent,
		SupervisorsTableComponent,
		LocalAdminTableComponent,
		SuperAdminTableComponent,
		CarrierAdminTableComponent,
		ScheduledTableLockerComponent,
		DialogOrderDetailScheduledComponent,
		LinkCarrierDetailComponent,
		LinkUserDetailComponent,
		FilterAdminAllComponent,
		FilterAdminProcessComponent,
		FilterAdminScheduledComponent,
		FilterAdminInLockerComponent,
		FilterAdminExpiredComponent,
		DialogStoreDetailComponent,
		DialogEditStoreComponent,
		LinkStoreDetailComponent,
		EstatusStorePackageComponent
],
exports: [
		UsersTableComponent,
		SupervisorsTableComponent,
		LocalAdminTableComponent,
		SuperAdminTableComponent,
		CarrierAdminTableComponent,
		CardUsersComponent,
		ClientsMetricsComponent,
		KpiClientsComponent,
		ClientsTableComponent,
		OxxoToOxxoGeneralTableComponent,
		OxxoToOxxoLockersTableComponent,
		OxxoToOxxoProcessTableComponent,
		OxxoToOxxoExpiredTableComponent,
		OxxoToOxxoScheduleTableComponent,
		OxxoToOxxoReturnsTableComponent,
		CardCarriersComponent,
		AccountSuperadminComponent,
		ActivityTableAdminComponent,
		AddNewCarrierComponent,
		AddNewUserComponent,
		AuditTableAdminComponent,
		AverageCollectionComponent,
		AveragePickupComponent,
		CalificationsAverageComponent,
		CamTableAdminComponent,
		CardsAdminComponent,
		CardsOverviewComponent,
		CardsSummaryComponent,
		CarrierMetricGraphicComponent,
		ChartBarAdminComponent,
		ChartClientAdminComponent,
		ChartDonutSummaryComponent,
		ClientAdminComponent,
		ClientProfileAdminComponent,
		ClientsMetricsAdminComponent,
		ClientTableAdminComponent,
		ClientTableComponent,
		ClientUserAdminComponent,
		CompartmentSizeComponent,
		CompletedTableAdminComponent,
		ConfirmChangeStatusUserComponent,
		CreateUserAdminComponent,
		DateTextComponent,
		DateTextExpiredComponent,
		DialogAuditUserComponent,
		DialogCarrierDetailComponent,
		DialogChangeStatusCarrierComponent,
		DialogCreateStoreComponent,
		DialogDeliverPersonallyComponent,
		DialogEraseOrderComponent,
		DialogEstatusOxxoToOxxoComponent,
		DialogImageLockerComponent,
		DialogOrderDetailComponent,
		DialogOxxoToOxxoDetailComponent,
		DialogQrTockenComponent,
		DialogRecordatoriosComponent,
		DoorStatusAdminComponent,
		EraseOrderComponent,
		EraseOrderComponent,
		EstatusOrderComponent,
		EstatusOxxoToOxxoComponent,
		EstatusTableCarrierOrderComponent,
		EstatusTableOrderAdminComponent,
		ExpiredTableAdminComponent,
		FilterCarriersComponent,
		FilterCarriersOrdersComponent,
		FilterClientsComponent,
		FilterOrdersComponent,
		FilterOxxoToOxxoComponent,
		FooterAdminComponent,
		FrecuencyUseComponent,
		GeneralTableAdminComponent,
		Grid1Component,
		Grid2Component,
		GridLockerComponent,
		HeadquartersComponent,
		HistoricalCardsAdminComponent,
		HistoricalOrdersComponent,
		HistoricalPackagesComponent,
		KpiLockerComponent,
		LocationAdminComponent,
		LockerGridAdminComponent,
		LockerMapComponent,
		LockerOperationComponent,
		LockersClientGridAdminComponent,
		MonthlyAdminComponent,
		MonthPickerComponent,
		OrderAdminComponent,
		OrderClientAdminComponent,
		OrderMetricsAdminComponent,
		OrdersTableAdminComponent,
		OxxoToOxxoExpiredComponent,
		OxxoToOxxoGeneralComponent,
		OxxoToOxxoInLockerComponent,
		OxxoToOxxoReturnsComponent,
		OxxoToOxxoScheduledComponent,
		OxxoToOxxoWarehouseComponent,
		PackagesCollectedReturnedComponent,
		PackagesDeliveredAdminComponent,
		PackagesDeliverySizesComponent,
		PackagesReturnAdminComponent,
		PasswordSuperadminComponent,
		PendingTableAdminComponent,
		PieChartAdminComponent,
		PreferencesSuperadminComponent,
		RatingAverageComponent,
		RecolectedRefundComponent,
		RefundTableAdminComponent,
		RegisterAdminComponent,
		ReturnClientsComponent,
		ReturnLotesComponent,
		ReturnOrdersComponent,
		ScheduleTableAdminComponent,
		SelectComponent,
		SelectLockerComponent,
		StarRatingAdminComponent,
		SummaryTableAdminComponent,
		TableCarrierAdminComponent,
		TableCarrierOrderComponent,
		TableCarrierUserComponent,
		TableExpiredAdminComponent,
		TableExpiredCarrierComponent,
		TableFilterByDayComponent,
		TableFilterByYearComponent,
		TableGeneralAdminComponent,
		TableGeneralCarrierComponent,
		TableLocalAdminsComponent,
		TableOrderssAdminComponent,
		TablePAdminComponent,
		TableScheduledCarrierComponent,
		TableSuperAdminComponent,
		TableSupervisorComponent,
		TableUserCarrierAdminCarrierComponent,
		TableUserComponent,
		TableUserLocalAdminCarrierComponent,
		TableUserSuperAdminCarrierComponent,
		TableUserSupervisorCarrierComponent,
		TableUserUsersCarrierComponent,
		UsageStateComponent,
		UseLockersCompartmentComponent,
		UserDetailModalComponent,
		VersionAdminComponent,
		KpiLockersComponent,
		TableLockersComponent,
		EstatusActivityPackageComponent,
		OrderMetricsComponent,
		KpiOrdersComponent,
		GeneralTableComponent,
		ScheduledTableComponent,
		ExpiredTableComponent,
		ScheduledTableLockerComponent,
		DialogOrderDetailScheduledComponent,
		FilterAdminAllComponent,
		FilterAdminProcessComponent,
		FilterAdminScheduledComponent,
		FilterAdminInLockerComponent,
		FilterAdminExpiredComponent,
		DialogStoreDetailComponent,
		DialogEditStoreComponent,
		LinkStoreDetailComponent,
		EstatusStorePackageComponent
	],
	imports: [
		CommonModule,
		MaterialModule,
		NgApexchartsModule,
		SharedModule,
		HighchartsChartModule,
		TranslateModule,
		RouterModule,
		NgxPaginationModule,
		MatDatepickerModule,
		MatNativeDateModule,
		CalendarModule,
		AgGridModule,
		DropdownModule,
		DialogModule,
		ChartsOverviewModule,
		ChartsGrid1Module,
		ChartsGrid2Module,
		TestModule,
	]
})
export class ComponentsAdminClientModule {}
