
import { Platform } from '@angular/cdk/platform';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import * as XLSX from 'xlsx';
import { AdminClientService } from '../../../../core/http/admin-client.service';
import { ColDef, GridOptions } from 'ag-grid-community';
import { DateTextComponent } from '../date-text/date-text.component';
import { StarRatingComponent } from 'src/app/shared/components/star-rating/star-rating.component';
import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FilterService } from 'src/app/core/http/FilterDataServiceOxxoToOxxo.service';
import { AgGridAngular } from 'ag-grid-angular';

@Component({
	selector: 'app-client-table',
	templateUrl: './client-table.component.html',
	styleUrls: ['./client-table.component.scss'],
})
export class ClientTableComponent implements OnInit{
	@Input() btnExport: string = 'none';
	@Input() calendar: string = 'none';
	@Input() filterFunnel: string = 'none';
	@Input() user;
	@Input() client;
	@Input() rowData;
	@Input() search;
	@Input() columns: string;
	@ViewChild('agGrid') agGrid: AgGridAngular;
	filterText: string = '';
	gridOptions: GridOptions;
	dataTemporal = [];
	filters:boolean=false;
	mode:string;
	public filteredData: any[] = [];
	private gridApi: AgGridAngular;
	public columnDefs: ColDef[];
	constructor( private platform: Platform, private router: Router, private adminService: AdminClientService, private dialog: MatDialog, private translate: TranslateService,private filterService	: FilterService) {
		translate.use(localStorage.getItem('language').toLowerCase());
	}
	ngOnDestroy() {
		this.dialog.closeAll();
	}
	ngOnChanges(changes: SimpleChanges): void {
	}
	ngOnInit() {
		this.mode = localStorage.getItem('mode');
		this.translate.use(localStorage.getItem('language').toLowerCase());
		this.updateDataClient();
		this.translate.onLangChange.subscribe((event: LangChangeEvent)=>{
			this.translate.use(event.lang);
			this.updateDataClient();
			this.translate.get([
				'table-client.name',
				'table-client.email',
				'table-client.phone',
				'table-client.number_visits',
				'table-client.registration_date'
			]).subscribe((t:any)=>{
				this.columnDefs = [
					{
						headerName: t['table-client.email'],
						field: 'email',
						sortable: true,
						autoHeight: true,
						cellClass:'ClientsClass',
						headerClass:'borderHeaderClients',
						cellRenderer: (params) => {
							const maxLength = 50; // Número máximo de caracteres antes de mostrar los puntos suspensivos
							const value = params.value;
							if (value.length > maxLength) {
								return value.slice(0, maxLength) + '...';
							}
							return value;
						},
					},
					{
						headerName: t['table-client.phone'] ,
						field: 'main_tlf',
						sortable: true,
						autoHeight: true,
						cellClass:'ClientsClass',
						headerClass:'borderHeaderClients',
						cellRenderer: (params) => {
							const phoneNumber = params.value;
							const formattedPhone = `${phoneNumber.slice(0,3)} (${phoneNumber.slice(3, 6)})-${phoneNumber.slice(6,9)}-${phoneNumber.slice(9, 13)}`;
							return `<div>${formattedPhone}</div>`;
						},
					},
					{
						headerName: t['table-client.number_visits'],
						field: 'count_return',
						sortable: true,
						autoHeight: true,
						cellClass:'ClientsClass',
						headerClass:'borderHeaderClients',
					},
					{
						headerName:   t['table-client.registration_date'],
						field: 'registration_date',
						sortable: true,
						autoHeight: true,
						cellClass:'ClientsClass',
						headerClass:'borderHeaderClients',
						cellRenderer: DateTextComponent,
					}
				]
			} )
		});
	}
	onGridReady(params) {
		this.gridApi = params;
	}
	onFilterTextChange(event) {
		this.filterText = event.target.value;
		event.api.setQuickFilter(this.filterText);
		let api = event.api;
		let temporal = [];
		event.api.forEachNodeAfterFilter((node) => {
			temporal.push(node.data);
		});
		this.filteredData = temporal;
	}
	centeredCellRenderer(params) {
		const cellValue = params.value;
		const cellDiv = document.createElement('div');
		cellDiv.style.display = 'flex';
		cellDiv.style.alignItems = 'center';
		cellDiv.style.justifyContent = 'center';
		cellDiv.style.height = '100%';
		cellDiv.innerText = cellValue;
		return cellDiv;
	}
	queryConsumer() {




	}
	onExport() {
		const fileName = 'Clients.xlsx';
		// Configuración para exportar en formato Excel
		const excelParams = {
			fileName: fileName,
			sheetName: 'Clients',
			columnWidths: [30, 20, 20, 20], // Ancho de las columnas en caracteres
		};
		// Obtener los datos de la tabla
		if (this.filteredData.length > 0) {
			const Data = this.filteredData || [];
			const excelData = Data.map((row) => {
				const { id_company,id_status,id_courier, ...rowDataWithoutFields} = row;
				return rowDataWithoutFields;
			});
			// Crear el libro de Excel y agregar la hoja con los datos
			const wb = XLSX.utils.book_new();
			const ws = XLSX.utils.json_to_sheet(excelData);
			XLSX.utils.book_append_sheet(wb, ws, excelParams.sheetName);
			//   // Descargar el archivo de Excel
			XLSX.writeFile(wb, fileName);
		} else if (this.rowData.length > 0 && this.filteredData.length === 0) {
			const Data = this.rowData || [];
			const excelData = Data.map((row) => {
				const { id_company,id_status,id_courier, ...rowDataWithoutFields} = row;
				return rowDataWithoutFields;
			});
			//   // Crear el libro de Excel y agregar la hoja con los datos
			const wb = XLSX.utils.book_new();
			const ws = XLSX.utils.json_to_sheet(excelData);
			XLSX.utils.book_append_sheet(wb, ws, excelParams.sheetName);
			//   // Descargar el archivo de Excel
			XLSX.writeFile(wb, fileName);
		}
	}
	updateDataClient():void{
		this.gridOptions = {
			columnDefs: [
				{
					headerName: this.translate.instant('table-client.email'),
					field: 'email',
					sortable: true,
					autoHeight: true,
					cellClass:'ClientsClass',
					headerClass:'borderHeaderClients',
					cellRenderer: (params) => {
						const maxLength = 120; // Número máximo de caracteres antes de mostrar los puntos suspensivos
						const value = params.value;
						if (value.length > maxLength) {
							return value.slice(0, maxLength) + '...';
						}
						return value;
					},
				},
				{
					headerName: this.translate.instant('table-client.phone'),
					field: 'main_tlf',
					sortable: true,
					autoHeight: true,
					cellClass:'ClientsClass',
					headerClass:'borderHeaderClients',
					cellRenderer: (params) => {
						const phoneNumber = params.value;
						const formattedPhone = `${phoneNumber.slice(0,3)} (${phoneNumber.slice(3, 6)})-${phoneNumber.slice(6,9)}-${phoneNumber.slice(9, 13)}`;
						return `<div>${formattedPhone}</div>`;
					},
				},
				{
					headerName: this.translate.instant('table-client.number_visits'),
					field: 'count_return',
					sortable: true,
					autoHeight: true,
					cellClass:'ClientsClass',
					headerClass:'borderHeaderClients',
				},
				{
					headerName: this.translate.instant(
						'table-client.registration_date'
					),
					field: 'registration_date',
					sortable: true,
					autoHeight: true,
					cellClass:'ClientsClass',
					headerClass:'borderHeaderClients',
					cellRenderer: DateTextComponent,
				}
			],
			animateRows: true,
			defaultColDef: {
				flex: 1,
			},
			rowClass:'rowClientsClass',
			pagination: true,
			paginationPageSize: 10,
			paginationAutoPageSize: true,
			rowHeight:59,
			headerHeight:59,
			overlayNoRowsTemplate:
			`<div>
				<div>
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="bi bi-info-circle icon-no-info" viewBox="0 0 16 16">
						<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
						<path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
					</svg>
				</div>
				<div class="labelNoRegistros">
					Aun no hay registros, <br>
					intentalo mas tarde
				</div>
			</div>`,
		}
	}
	showFilters(){
		this.filters = !this.filters
	}
}

