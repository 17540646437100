<form class="container-main" autocomplete="off" [formGroup]="formPassword">
    <div class="container-header">
        <span class="header">
            Si quieres cambiar tu contraseña, ingresa la actual para empezar el proceso.
        </span>
    </div>
    <div class="hr"></div>

    <div class="container-form">
        <div>
            <span class="span-txt"> {{'side-bar-panel-control.password-page.current' | translate }} </span>
            <input type="password" formControlName="password" (change)="onLoadForm()">
        </div>
        <div>
            <span class="span-txt"> {{'side-bar-panel-control.password-page.new' | translate }} </span>
            <input type="password" formControlName="new_password" (change)="onLoadForm()">
			<span class="span-txt"> </span>
			<span class="span-txt-1"> Tu nueva contraseña debe tener mas de 8 caracteres. </span>
        </div>
        <div>
            <span class="span-txt"> {{'side-bar-panel-control.password-page.confirm' | translate }} </span>
            <input type="password" formControlName="confirm_password" (change)="onLoadForm()">
        </div>
		<div class="btn-container" style="display: flex; justify-content: center;">
			<app-button-icon *ngIf="this.formValid === true" (click)="updatePassword()" [activo]="false" btnNombre="side-bar-panel-control.account-page.btn" btnType="primaryMedium" icon="icon-save">
			</app-button-icon>
			<app-button-icon *ngIf="this.formValid === false" [activo]="true" btnNombre="side-bar-panel-control.account-page.btn" btnType="primaryMedium" icon="icon-save">
			</app-button-icon>
		</div>
    </div>
</form>
