import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild} from '@angular/core';
import { Subscription } from 'rxjs';
import { GoogleMapsService } from '../../../core/services/google-maps/google-maps.service';
import { SharedService } from '../../../core/services/table_to_map.service';

@Component({
	selector: 'app-google-maps',
	templateUrl: './google-maps.component.html',
	styleUrls: ['./google-maps.component.scss'],
})
export class GoogleMapsComponent implements OnInit, AfterViewInit {
	@ViewChild('google_map', { static: true }) mapElementRef: ElementRef;
	@Input() latitud;
	@Input() longitud;
	@Input() zoom;
	@Input() coords: any;
	@Output() eventAddress = new EventEmitter<string>();
	googleMaps: any;
	map: any;
	clickEventsubscription: Subscription;
	markers: any;
	combinedMarker: any;
	geocoder: any;
	lstCombinedMarker: google.maps.Marker[] = [];
	selectedMarker: google.maps.Marker;
	lockerId;
	// count: number = 0;
	constructor(private maps: GoogleMapsService,private renderer: Renderer2,private sharedService: SharedService) {
		if(localStorage.getItem('lat') !== null && localStorage.getItem('lng') !== null){
			this.clickEventsubscription = this.sharedService.getClickEvent().subscribe((data) => {
				this.latitud = Number(localStorage.getItem('lat'));
				this.longitud = Number(localStorage.getItem('lng'));
				this.zoom = data.zoom;
				this.ngAfterViewInit();
			});
			this.loadMap()
		}
		else{
			this.clickEventsubscription = this.sharedService.getClickEvent().subscribe((data) => {
				this.latitud = Number(data.lat);
				this.longitud = Number(data.lng);
				this.zoom = data.zoom;
				this.ngAfterViewInit();
			});
			this.loadMap()
		}
	}
	ngOnInit(): void {
	}
	ngAfterViewInit() {
	}
	clickChange() {
		this.ngAfterViewInit();
	}
	async loadMap() {
		try {
			let googleMaps: any = await this.maps.loadGoogleMaps();
			this.googleMaps = googleMaps;
			const mapEl = this.mapElementRef.nativeElement;
			// const location = new googleMaps.LatLng(this.latitud, this.longitud);
			const location = new googleMaps.LatLng('19.05755908', '-98.2232274');
			this.map = new googleMaps.Map(mapEl, {
				center: location,
				zoom: 12,
				scaleControl: false,
				streetViewControl: false,
				zoomControl: false,
				overviewMapControl: false,
				mapTypeControl: false,
				mapTypeControlOptions: {
					mapTypeIds: [googleMaps.MapTypeId.ROADMAP, 'mapId'],
				},
			});
			const style = [
				{
					featureType: 'road',
					elementType: 'labels.text',
					stylers: [{ visibility: 'on' }] // Mostrar nombres de calles
				},
				{
					featureType: 'poi',
					elementType: 'labels',
					stylers: [{ visibility: 'off' }] // Ocultar puntos de interés
				},
				{
					featureType: 'landscape',
					stylers: [{ visibility: 'off' }] // Ocultar los puntos de interés (incluidos los volcanes)
				},
				{
					featureType: 'transit.station.bus',
					stylers: [{ visibility: 'off' }] // Ocultar paradas de autobús
				},
				{
					featureType: 'road',
					elementType: 'labels.icon',
					stylers: [{ visibility: 'off' }] // Ocultar números de carreteras
				},
				{
					featureType: 'transit.station',
					elementType: 'labels.icon',
					stylers: [{ visibility: 'off' }] // Ocultar estaciones del metro
				},
				{
					featureType: 'transit.line',
					elementType: 'geometry',
					stylers: [{ visibility: 'off' }] // Ocultar vías del metro
				},
			];
			var mapType = new googleMaps.StyledMapType(style, { name: 'Grayscale' });
			this.map.mapTypes.set('mapId', mapType);
			this.map.setMapTypeId('mapId');

			this.renderer.addClass(mapEl, 'visible');
			this.createMarkers();
			this.fusionMarkers();
			this.handlerClickMarkers();
			this.map.addListener('zoom_changed', () => {
				this.fusionMarkers();
			});
		} catch (e) {
			console.log(e);
		}
	}
	createMarkers() {
		this.markers = this.coords.map((marker, index) => {
			this.lockerId = marker.lockerID ? marker.lockerID : null;
			const markerOptions = {
				position: marker,
				map: this.map,
				draggable: false,
				// label: {
				// 	color: '#ffffff',
				// 	text: '•',
				// 	fontWeight: 'bold',
				// 	fontSize: '40px',
				// },
				zIndex: 1000,
				icon: '../../../../assets/images/pinStoreMap.svg', // Reemplaza 'URL_DE_LA_IMAGEN' con la URL de la imagen que deseas usar
			};

			const googleMarker = new google.maps.Marker(markerOptions);
			googleMarker.set('lockerId', this.lockerId);
			return googleMarker;
		});
	}

	fusionMarkers() {
		const groupedMarkers = {};
		const radius = 100; // Radio en kilómetros para agrupar los marcadores

		// Agrupar los marcadores por proximidad geográfica
		this.markers.forEach((marker) => {
			const lat1 = marker.getPosition().lat();
			const lng1 = marker.getPosition().lng();

			let closestCity = null;
			let closestDist = null;
			for (const city in groupedMarkers) {
				const lat2 = groupedMarkers[city][0].position.lat();
				const lng2 = groupedMarkers[city][0].position.lng();
				const dist = this.haversine(lat1, lng1, lat2, lng2); // Calcular la distancia entre los dos puntos

				if (closestDist === null || dist < closestDist) {
					closestCity = city;
					closestDist = dist;
				}
			}

			if (closestDist !== null && closestDist <= radius) {
				groupedMarkers[closestCity].push(marker);
			} else {
				const newCity = `City ${
					Object.keys(groupedMarkers).length + 1
				}`;
				groupedMarkers[newCity] = [marker];
				marker.city = newCity;
			}
		});

		if (this.map.getZoom() < 11) {
			// Iterar sobre los grupos de marcadores y crear marcadores combinados para cada grupo
			// this.lstCombinedMarker=[];
			for (const city in groupedMarkers) {
				const markers = groupedMarkers[city];
				const latLng = new this.googleMaps.LatLng(
					markers.reduce((a, b) => a + b.getPosition().lat(), 0) /
						markers.length,
					markers.reduce((a, b) => a + b.getPosition().lng(), 0) /
						markers.length
				);

				let combinedMarker = new google.maps.Marker({
					position: latLng,
					map: this.map,
					// label: {
					// 	color: '#ffffff',
					// 	text: markers.length.toString(),
					// 	fontWeight: 'bold',
					// },
					icon: '../../../../assets/images/pinStoreSelected.svg', // Reemplaza 'URL_DE_LA_IMAGEN' con la URL de la imagen que deseas usar

				});

				this.lstCombinedMarker.push(combinedMarker);

				markers.forEach((marker) => {
					marker.setMap(null);
				});
			}
		} else {
			this.markers.forEach((marker) => marker.setMap(this.map));
			// Eliminar los marcadores combinados de ciudad
			// for (const city in groupedMarkers) {
			//   const markers = groupedMarkers[city];
			//   markers.forEach((marker) => {
			//     marker.setMap(this.map);
			//   });
			//   if (this.combinedMarker) {
			//     this.combinedMarker.setPosition(null);
			//   }
			// }
			this.lstCombinedMarker.forEach((marker) => {
				marker.setMap(null);
			});
		}
	}

	// Función para calcular la distancia entre dos puntos geográficos utilizando la fórmula Haversine
	haversine(lat1, lng1, lat2, lng2, unit = 'km') {
		const R = unit === 'km' ? 6371 : 3956;
		const dLat = this.toRad(lat2 - lat1);
		const dLng = this.toRad(lng2 - lng1);
		const a =
			Math.sin(dLat / 2) * Math.sin(dLat / 2) +
			Math.cos(this.toRad(lat1)) *
				Math.cos(this.toRad(lat2)) *
				Math.sin(dLng / 2) *
				Math.sin(dLng / 2);
		const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		const d = R * c;
		return d;
	}

	// Función para convertir grados a radianes
	toRad(degrees) {
		return (degrees * Math.PI) / 180;
	}

	getAddressMap() {
		this.geocoder.geocode(
			{ location: { lat: this.latitud, lng: this.longitud } },
			(results, status) => {
				if (status === 'OK') {
					if (results[0]) {
						this.eventAddress.emit(results[0].formatted_address);
					}
				} else {
					this.eventAddress.emit('loading...');
				}
			}
		);
	}

	handlerClickMarkers() {
		for (let marker of this.markers) {
			marker.addListener('click', (event) => {
				this.latitud = event.latLng.lat();
				this.longitud = event.latLng.lng();
				const previousSelectedMarker = this.selectedMarker;
				// Si el marcador actual es igual al marcador previamente seleccionado, deselecciónalo
				if (previousSelectedMarker === marker) {
					// Restablecer el marcador a su estado original
					marker.setZIndex();
					marker.setIcon('../../../../assets/images/pinStoreMap.svg'); // Reemplaza con la URL de la imagen original
					// Limpiar la referencia al marcador seleccionado
					this.selectedMarker = null;
					this.lockerId = 0;
					this.eventAddress.emit(this.lockerId);
					// No emitir nada en el evento eventAddress
				} else {
					// Restablecer todos los marcadores a su estado original
					for (let m2 of this.markers) {
						m2.setZIndex();
						m2.setIcon('../../../../assets/images/pinStoreMap.svg'); // Reemplaza con la URL de la imagen original
					}
					// Establecer la imagen diferente para el marcador seleccionado
					marker.setZIndex(1000);
					marker.setIcon('../../../../assets/images/pinStoreSelected.svg'); // Reemplaza con la URL de la nueva imagen
					// Almacenar referencia al marcador seleccionado
					this.selectedMarker = marker;
					// Recuperar el valor de lockerId utilizando el método 'get'
					const lockerId = marker.get('lockerId');

					this.eventAddress.emit(lockerId);
				}
			});
		}
	}
}
