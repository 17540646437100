<!-- ? CONTAINER MAIN -->
<div class="cont_main">
	<!-- ? HEADER -->
	<div class="header headlines_h2 font_semibold color_primary">
		<!-- TITLE -->
		<p> {{'orders.orderMetrics' | translate}} </p>

		 <!-- ? TOOLTIP CONTAINER -->
		 <div class="tooltip_cont_">
			<img src="../../../../../../assets/icons/icon-info.svg" class="icon_tooltip">
			   
			<!-- TOOLTIP -->
			<div class="tooltip_">
				<p class="caption_c2 color_primary_400 font_medium">
					{{ 'tooltip.useLockersByOperation' | translate }}
				</p>
				<!-- PIKE -->
				<div class="pike_2"></div>
			</div>
			<!-- PIKE -->
			<div class="pike"></div>

		</div>

	</div>
	
	<!-- ? CHART  -->
	<div id="chart" class="chart">
        <apx-chart 
            [series]="chartOptions.series"
            [chart]="chartOptions.chart"
            [xaxis]="chartOptions.xaxis"
            [stroke]="chartOptions.stroke"
            [tooltip]="chartOptions.tooltip"
            [dataLabels]="chartOptions.dataLabels"
            [legend]="chartOptions.legend"
            [markers]="chartOptions.markers"
            [grid]="chartOptions.grid"
            [yaxis]="chartOptions.yaxis"
            [title]="chartOptions.title">
        </apx-chart>
		
	</div>
	
</div>