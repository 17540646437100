<button type="button" [disabled]="this.activo" [ngClass]="{
	'primary-sm' : this.btnType === 'primarySmall',
	'primary-md' : this.btnType === 'primaryMedium',
	'primary-xl' : this.btnType === 'primaryBig',
	'secondary-sm' : this.btnType === 'secondarySmall',
	'secondary-md' : this.btnType === 'secondaryMedium',
	'secondary-xl' : this.btnType === 'secondaryBig',
	'tertiary-sm' : this.btnType === 'tertiarySmall',
	'tertiary-md' : this.btnType === 'tertiaryMedium',
	'tertiary-xl' : this.btnType === 'tertiaryBig',
	'primary-danger-sm' : this.btnType === 'primaryDangerSmall',
	'primary-danger-md' : this.btnType === 'primaryDangerMedium',
	'primary-danger-xl' : this.btnType === 'primaryDangerBig',
	'secondary-danger-sm' : this.btnType === 'secondaryDangerSmall',
	'secondary-danger-md' : this.btnType === 'secondaryDangerMedium',
	'secondary-danger-xl' : this.btnType === 'secondaryDangerBig'
}">
	<div [ngClass]="{
			'label-sm' : this.btnType === 'primarySmall' || this.btnType === 'secondarySmall' || this.btnType === 'tertiarySmall' || this.btnType === 'primaryDangerSmall'  || this.btnType === 'secondaryDangerSmall',
			'label-md' : this.btnType === 'primaryMedium'|| this.btnType === 'secondaryMedium'|| this.btnType === 'tertiaryMedium'|| this.btnType === 'primaryDangerMedium' || this.btnType === 'secondaryDangerMedium',
			'label-xl' : this.btnType === 'primaryBig'   || this.btnType === 'secondaryBig'   || this.btnType === 'tertiaryBig'   || this.btnType === 'primaryDangerBig'    || this.btnType === 'secondaryDangerBig'
		}">
		<div class="mobile">
			{{btnNombre | translate}}
		</div>

		<img src="../../../../assets/icons/{{this.icon}}.svg" [width]="18" [height]="18" alt="">
	</div>
</button>


