<div class="page-size-selector">
	<div class="result-per-page">
		<div class="text">
			Result per page
		</div>
		<div class="select-container" (click)="toggleOptions()">
			<div class="number">
				{{pageSize}}
			</div>
			<div class="icon-arrow">
			</div>

			<!-- <select class="select-page" id="pageSize" (change)="onPageSizeChange($event.target.value)">
				<option *ngFor="let option of pageSizeOptions" [value]="option">{{option}}</option>
			</select> -->
		</div>
	</div>
	<div class="count-results">
		{{firstRow}}-{{lastRow}} of {{rowCount}} items
	</div>
	<!-- <label for="pageSize">Items per page:</label>-->
</div>

<div class="options" *ngIf="showOptions === true">
	<div class="number" *ngFor="let option of pageSizeOptions" [ngClass]="{ 'option-selected': pageSize === option }" (click)="selectOption(option)">
		{{ option }}
	</div>
</div>
