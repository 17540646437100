import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ApexAxisChartSeries, ApexChart, ChartComponent, ApexDataLabels, ApexPlotOptions, ApexResponsive, ApexXAxis, ApexLegend, ApexFill} from 'ng-apexcharts';
@Component({
	selector: 'app-recolected-refund-orders',
	templateUrl: './recolected-refund-orders.component.html',
	styleUrls: ['./recolected-refund-orders.component.scss'],
})
export class RecolectedRefundOrdersComponent implements OnInit {
	@ViewChild('chart') chart: ChartComponent;
	public dataChartExpiredOrders: Partial<ChartOptions>;
	tooltipTracking: string;
	@Input() dataBaseRecolected: number[] = [];
	@Input() dataBaseRefunded: number[] = [];
	@Input() dataBaseDays: string[] = [];
	@Input() dataCompRecolected: number[] = [];
	@Input() dataCompRefunded: number[] = [];
	@Input() dataCompDays: string[] = [];
	@Input() addComp: boolean;
	@Input() periodBase: string = '';
	@Input() periodComp: string = '';
	@Input() changeStyles: string = 'dates-default';
	recolect = [];
	refund = [];
	days = [];
	constructor(private translate: TranslateService) {
	}
	ngOnInit() {
		this.translate.use(localStorage.getItem('language').toLowerCase());
		this.tooltipTracking = this.translate.instant('tooltip.ordersTrackingExpired');
		this.getData();
		this.loadDataChart();
		this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
			this.translate.use(event.lang);
			this.tooltipTracking = this.translate.instant('tooltip.ordersTrackingExpired');
		})
	}
	ngOnChanges() {
		this.getData();
		this.loadDataChart();
	}
	getData() {
		this.recolect = [];
		this.refund = [];
		this.days = [];
		this.dataBaseRecolected.forEach((res) => {
			this.recolect.push(res);
		});
		this.dataBaseRefunded.forEach((res) => {
			this.refund.push(res);
		});
		this.dataBaseDays.forEach((res) => {
			this.days.push(res.substring(0, 3));
		});
		if (this.addComp === true) {
			this.refund.push(0);
			this.recolect.push(0);
			this.days.push(' ');
			if (
				this.dataCompRecolected.length > 0 &&
				this.dataCompRefunded.length > 0 &&
				this.dataCompDays.length > 0
			) {
				this.dataCompRecolected.forEach((res) => {
					this.recolect.push(res);
				});
				this.dataCompRefunded.forEach((res) => {
					this.refund.push(res);
				});
				this.dataCompDays.forEach((res) => {
					this.days.push(res.substring(0, 3));
				});
			}
		}
	}
	private loadDataChart() {
		this.dataChartExpiredOrders = {
			series: [
				{
					name: this.translate.instant('overview.orderRecolected'),
					data: this.recolect,
				},
				{
					name: this.translate.instant('overview.orderRefunded'),
					data: this.refund,
				},
			],
			chart: {
				type: 'bar',
				height: 310,
				stacked: true,
				toolbar: {
					show: true,
					tools:{
						download:`
							<div class="downloadClassRecolectRefundOrders">
								<label class="textExport">
									${this.translate.instant('buttons.export')}
								</label>
								<div class="icon">
								</div>
							</div>
						`,
						pan:false,
						reset:false,
						zoom:false,
						zoomin:false,
						zoomout:false,
						selection:true
					},
				},
				zoom: {
					enabled: true,
				},
			},
			responsive: [
				{
					breakpoint: 480,
					options: {
						legend: {
							position: 'bottom',
							offsetX: -10,
							offsetY: 0,
						},
					},
				},
			],
			plotOptions: {
				bar: {
					horizontal: false,
				},
			},
			xaxis: {
				type: 'category',
				categories: this.days,
			},
			legend: {
				show: false,
				position: 'bottom',
				offsetY: 10,
				markers: {
					fillColors: ['#18345D', '#0082CB'], // Colores personalizados para los marcadores de la leyenda
				},
			},
			fill: {
				opacity: 1,
				colors: ['#18345D', '#0082CB'],
			},
		};
	}
}

export type ChartOptions = {
	series: ApexAxisChartSeries;
	chart: ApexChart;
	dataLabels: ApexDataLabels;
	plotOptions: ApexPlotOptions;
	responsive: ApexResponsive[];
	xaxis: ApexXAxis;
	legend: ApexLegend;
	fill: ApexFill;
};
