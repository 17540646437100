import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Platform } from '@angular/cdk/platform';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ClientSign } from '../dialogs-admin/client-dialog';
import { ConfirmDelete } from '../dialogs-admin/confirm-delete';
import * as XLSX from 'xlsx';
import { SuccesDialogComponent } from '../dialogs-admin/succes-dialog/succes-dialog.component';
import { AdminClientService } from '../../../../core/http/admin-client.service';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-client-table-admin',
	templateUrl: './client-table-admin.component.html',
	styleUrls: ['./client-table-admin.component.scss'],
})
export class ClientTableAdminComponent implements OnInit {
	@Output() updateEvent = new EventEmitter<string>();
	@Input() user;
	@Input() client;
	@Input() data;
	@Input() search;
	@Input() columns: string;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	validExts = new Array('xlsx', 'xls', 'csv');
	validExtsFile = new Array(
		'-',
		'client_name',
		'id_cliente',
		'e-mail_bees',
		'teléfono_bees',
		'telefonocts',
		'telefono_con_contactación_l3m',
		'tel_contacto_ma'
	);

	dataSource: MatTableDataSource<[]>;
	displayedColumns: string[];
	mobile = false;
	list;
	lang;
	company;
	disabled_create_qr: string = 'generateQRDisabled';
	disabled: string = 'disabled';
	date_currient = new Date();
	name_file =
		'data_' +
		this.date_currient.getDate() +
		'_' +
		(this.date_currient.getMonth() + 1) +
		'_' +
		this.date_currient.getFullYear();
	disabled_dowloade: string = '';
	disabled_dowloade_button: boolean = true;
	exceltoJson;
	dataClientFile: any = [];
	dataClienteSend: any = [];
	res;

	constructor(
		private platform: Platform,
		private router: Router,
		private adminService: AdminClientService,
		private dialog: MatDialog,
		private translate: TranslateService,
		private toastr: ToastrService
	) {}

	ngOnDestroy() {
		this.dialog.closeAll();
	}

	ngOnInit() {
		this.lang = localStorage.getItem('language').toLowerCase();
		this.company = localStorage.getItem('id_company');
		if (this.platform.ANDROID || this.platform.IOS) {
			this.mobile = true;
		} else this.mobile = false;
	}

	toggle(event: MatSlideToggleChange, element: any) {
		this.editUser(event.checked, element);
	}

	async toggleStatus(event: MatSlideToggleChange, element: any) {
		let body = {
			language: this.lang,
			id_company: this.company,
			data_employee: [
				{
					id_status: event.checked ? 1 : 2,
					email: element.email,
					id_type_employee: element.userType.id,
				},
			],
		};

		let res: any = await this.adminService.udpateEmployee(body);
	}

	ngOnChanges(): void {
		if (this.data) {
			this.list = JSON.parse(this.data);
			this.displayedColumns = this.columns.split(',');
			this.dataSource = new MatTableDataSource(JSON.parse(this.data));
			this.dataSource.paginator = this.paginator;

			if (this.dataSource.filteredData.length == 0)
				this.disabled_dowloade = '-disabled';
			else this.disabled_dowloade_button = false;
		}

		if (this.search) {
			this.searchID(this.search);
		}
	}

	updateListCheck(list) {
		this.dataSource = new MatTableDataSource(list);
		this.dataSource.paginator = this.paginator;
	}

	searchID(value) {
		if (this.dataSource) {
			const filterValue = value;
			this.dataSource.filter = filterValue.trim().toLowerCase();
		}
	}

	applyFilter(event: Event) {
		if (this.dataSource) {
			const filterValue = (event.target as HTMLInputElement).value;
			this.dataSource.filter = filterValue.trim().toLowerCase();
		}
	}

	edit(element: any) {
		//is.router.navigate([`l-retail/user/edit`])
		localStorage.setItem('update_user', JSON.stringify(element));
		this.router.navigate([`l-retail/user/edit`])
	}

	create() {
		this.router.navigateByUrl('/l-retail/user/create')
	}

	register() {
		this.router.navigateByUrl('/l-retail/client/register')
	}

	createQR() {
		let data_employee: any = [];
		let body: any;

		this.list.forEach((data: any) => {
			if (data.checked) {
				data_employee.push({
					id_company_employee: data.id_company_employee,
				});
			}
		});
		body = {
			language: localStorage.getItem('language').toLowerCase(),
			data_employee: data_employee,
		};

		this.dialog
			.open(ClientSign, {
				width: window.innerHeight < window.innerWidth ? '45%' : '70%',
				height: window.innerHeight < window.innerWidth ? '25%' : '15%',
				data: { type: 'configCreateQr', body },
			})
			.afterClosed()
			.subscribe(() => {
				this.router
					.navigateByUrl('/RefreshComponent', {
						skipLocationChange: true,
					})
					.then(() => {
						this.router
							.navigate(['/l-retail/user']);
					});
			});
	}

	async deleteUser(e): Promise<any> {
		const message: string = await this.translate
			.get('user.confirmDelete')
			.toPromise();
		const dialogRef = this.dialog.open(ConfirmDelete, {
			data: {
				message: message,
			},
			height: '265px',
			width: '550px',
		});
		await dialogRef.afterClosed().toPromise();
		if (localStorage.getItem('deleted')) {
			const params = {
				language: localStorage.getItem('language').toLowerCase(),
				id_user_delete: Number(e.id),
			};
			this.res = await this.adminService.deleteUserConsumer(params);
			if (this.res.mensaje_return.ERROR === false) {
				this.list = this.list.filter((l: any) => l.id != e.id);
				this.dataSource = new MatTableDataSource(this.list);
				localStorage.setItem('code', this.res.mensaje_return.CODE);
				localStorage.setItem('deleted', '1');
			} else {
				localStorage.setItem('deleted', '0');
			}
		}
		localStorage.removeItem('deleted');
		return this.res;
	}

	validarButtonQr() {
		let validate_button = false;
		this.list.forEach((element: any) => {
			if (element.checked === true) {
				validate_button = true;
			}
		});

		if (validate_button) {
			this.disabled_create_qr = 'generateQR';
			this.disabled = 'false';
		} else {
			this.disabled_create_qr = 'generateQRDisabled';
			this.disabled = 'disabled';
		}
	}

	async editUser(enable_token, user) {
		let body = {
			language: this.lang,
			id_company: this.company,
			data_employee: [
				{
					enable_token: enable_token,
					email: user.email,
					id_type_employee: user.userType.id,
				},
			],
		};

		let res: any = await this.adminService.udpateEmployee(body);
		if (res.mensaje_return.ERROR === false) {
			this.toastr.success(
				'Se ha actualizado el empleado',
				'Accion Exitosa',
				{
					toastClass:'toast-exitosa',
					timeOut:5000,
					positionClass: 'toast-bottom-center'
				}
			)
		} else {
			this.toastr.error(
				'Hubo un error al actualizar el empleado',
				'Error al actualizar el empleado',
				{
					toastClass:'toast-error-toggle',
					timeOut:5000,
					positionClass: 'toast-bottom-center'
				}
			)
		}
	}

	setAll(checked: boolean) {
		this.list.forEach((element: any) => {
			if (element.url_token == 'N/A') element.checked = checked;
		});
		this.validarButtonQr();
		this.updateListCheck(this.list);
	}

	selectItem(select: boolean, element: any) {
		this.list.forEach((data: any) => {
			if (data.email == element.email) data.checked = select;
		});

		this.validarButtonQr();
		this.updateListCheck(this.list);
	}

	modalQr(user: any) {
		this.dialog.open(ClientSign, {
			// width: window.innerHeight<window.innerWidth?'55%':'80%',
			// height: window.innerHeight<window.innerWidth?'65%':'40%',
			data: {
				image: user.url_token,
				type: 'qrAccess',
				id_company_employee: user.id_company_employee,
			},
		});
	}

	logUser(user: any) {
		this.translate
			.get(['audit.audit_user', 'buttons.cancel'])
			.subscribe((t: any) => {
				this.dialog.open(ClientSign, {
					width: '90%',
					height: '85%',
					data: {
						type: 'audit_user',
						title: t['audit.audit_user'],
						text_button: t['buttons.cancel'],
						result_audit_employee: user.result_audit_employee,
						result_package: user.result_package,
						fullName: user.fullName.toUpperCase(),
					},
				});
			});
	}

	get_header_row(sheet) {
		let headers = [];
		let range = XLSX.utils.decode_range(sheet['!ref']);
		for (let C = range.s.c; C <= range.e.c; ++C) {
			let cell = sheet[XLSX.utils.encode_cell({ c: C, r: range.s.r })]?.v;
			let hdr = cell ? XLSX.utils.format_cell(cell) : 'UNKNOWN ' + C;
			headers.push(hdr);
		}

		return headers;
	}

	async saveClient() {
		let data_send = {
			language: localStorage.getItem('language').toLowerCase(),
			data_consumer: this.dataClienteSend,
		};
		let res: any = await this.adminService.registerClient(data_send);
		if (res.mensaje_return.ERROR === false) {
			this.dialog.closeAll();
			this.dialog.open(SuccesDialogComponent, {
				width: '40%',
				data: { status: true },
			});
			this.updateEvent.emit('true');
		} else {
			this.dialog.closeAll();
			this.dialog.open(SuccesDialogComponent, {
				width: '40%',
				data: { status: false, codigo: res.mensaje_return.CODE },
			});
			this.updateEvent.emit('false');
		}
	}

	onFileChange(event: any) {
		this.resetData();
		const file = event.target.files[0];
		const fileExt = file.name.split('.').pop();
		if (this.isValidExtension(fileExt)) {
			this.convertExcelToJson(file);
		} else {
			this.showErrorDialog('general.err_type_file');
		}
		event.target.value = '';
	}
	resetData() {
		this.exceltoJson = {};
		this.dataClientFile = [];
		this.dataClienteSend = [];
	}
	isValidExtension(fileExt: string) {
		return this.validExts.includes(fileExt);
	}
	convertExcelToJson(file: File) {
		const reader: FileReader = new FileReader();
		reader.onload = (e: any) => {
			const binarystr: string = e.target.result;
			const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });
			const wsname: string = wb.SheetNames[0];
			const ws: XLSX.WorkSheet = wb.Sheets[wsname];
			this.dataClientFile = XLSX.utils.sheet_to_json(ws);
			this.exceltoJson['filename'] = file.name;
			this.exceltoJson['sheet1'] = this.dataClientFile;
			const headers: string[] = this.getHeaderRow(ws);
			const headerJsonFormat = headers.map((h: string) =>
				this.formatHeader(h)
			);
			if (this.isValidHeader(headerJsonFormat)) {
				this.processData(headerJsonFormat);
			} else {
				this.showErrorDialog('general.error_file_fields');
			}
		};
		reader.readAsBinaryString(file);
	}
	getHeaderRow(ws: XLSX.WorkSheet) {
		const range = XLSX.utils.decode_range(ws['!ref']);
		const headers: string[] = [];
		for (let C = range.s.c; C <= range.e.c; ++C) {
			const cellAddress = { c: C, r: range.s.r };
			const headerCell = XLSX.utils.encode_cell(cellAddress);
			headers.push(ws[headerCell].v);
		}
		return headers;
	}
	formatHeader(header: string) {
		return header
			.toLowerCase()
			.trim()
			.replace(/[\n|\r]/g, '_')
			.replace(/ /g, '_');
	}
	isValidHeader(headers: string[]) {
		const countValidateHeader = headers.filter((h: string) =>
			this.validExtsFile.includes(h)
		).length;
		return countValidateHeader === this.validExtsFile.length - 1;
	}
	processData(headerJsonFormat: string[]) {
		this.dataClientFile.forEach((client: any) => {
			const dataClient = this.formatDataClient(client);
			const numberPhone = this.getNumberPhone(dataClient);
			this.dataClienteSend.push({
				client_name: dataClient.client_name
					.replaceAll('_', ' ')
					.toUpperCase(),
				email: this.getEmail(dataClient, numberPhone),
				main_tlf: '+' + numberPhone,
				id_client: dataClient.id_cliente,
				alternative_tlf: this.getAlternativeTlf(dataClient),
			});
		});
		this.showConfirmationDialog('confirm_client.title');
	}
	formatDataClient(client: any) {
		return JSON.parse(
			JSON.stringify(client)
				.replaceAll(/[\n|\r]/g, '_')
				.toLowerCase()
				.replaceAll(' ', '_')
				.replaceAll('e-', '')
				.replaceAll('\\r\\n', '')
				.replaceAll('\\n', '')
				.replaceAll('\\r', '')
		);
	}
	getNumberPhone(dataClient: any) {
		let number_phone = '';
		if (dataClient.teléfono_bees) {
			if (
				dataClient.teléfono_bees != 0 ||
				dataClient.teléfono_bees != ''
			) {
				number_phone = this.formatPhoneNumber(dataClient.teléfono_bees);
			} else if (
				dataClient.telefonocts != 0 ||
				dataClient.telefonocts != ''
			) {
				number_phone = this.formatPhoneNumber(dataClient.telefonocts);
			} else if (
				dataClient.telefono_con_contactación_l3m != 0 ||
				dataClient.telefono_con_contactación_l3m != ''
			) {
				number_phone = this.formatPhoneNumber(
					dataClient.telefono_con_contactación_l3m
				);
			}
		} else {
			if (dataClient.telefonocts != 0 || dataClient.telefonocts != '') {
				number_phone = this.formatPhoneNumber(dataClient.telefonocts);
			} else if (
				dataClient.telefono_con_contactación_l3m != 0 ||
				dataClient.telefono_con_contactación_l3m != ''
			) {
				number_phone = this.formatPhoneNumber(
					dataClient.telefono_con_contactación_l3m
				);
			}
		}
		return number_phone;
	}
	formatPhoneNumber(phoneNumber: string) {
		return String(phoneNumber).startsWith('52')
			? phoneNumber
			: '52' + phoneNumber;
	}
	getEmail(dataClient: any, numberPhone: string) {
		return dataClient.mail_bees != 0 || dataClient.mail_bees != ''
			? dataClient.mail_bees
			: numberPhone + '@vivipost.com';
	}
	getAlternativeTlf(dataClient: any) {
		return `${this.formatAlternativeTlf(
			dataClient.telefonocts
		)},${this.formatAlternativeTlf(
			dataClient.telefono_con_contactación_l3m
		)},${this.formatAlternativeTlf(dataClient.tel_contacto_ma)}`;
	}
	formatAlternativeTlf(phoneNumber: string) {
		return phoneNumber == '0' || phoneNumber == '' ? null : phoneNumber;
	}
	showConfirmationDialog(titleKey: string) {
		this.translate.get([titleKey]).subscribe((t: any) => {
			let dialogRef = this.dialog.open(ClientSign, {
				width: '90%',
				height: '85%',
				data: {
					type: 'confirm_clients',
					title: t[titleKey],
					data_clients: JSON.stringify(this.dataClienteSend),
				},
			});
			dialogRef.componentInstance.saveEvent.subscribe((save: string) => {
				if (save == 'true') {
					this.showLoadingDialog('general.loading');
					this.saveClient()
				} else if (save == 'false') {
					this.dataClienteSend = [];
					this.updateEvent.emit('false');
				}
			});
		});
	}
	showErrorDialog(errorKey: string) {
		this.translate.get([errorKey, 'general.error']).subscribe((t: any) => {
			this.dialog.open(ClientSign, {
				width: '40%',
				data: {
					title: t['general.error'],
					message: t[errorKey],
					type: 'message',
				},
			});
		});
	}
	showLoadingDialog(loadingKey: string) {
		this.translate.get([loadingKey]).subscribe((t: any) => {
			this.dialog.open(ClientSign, {
				width: '40%',
				height: '20%',
				data: {
					type: 'loading',
					title: t[loadingKey],
				},
			});
		});
	}
}
