<div class="detail-oxxo-to-oxxo" *ngIf="this.from === 'general'">
	<div class="row">
		<div class="col-sm-10 col-md-10 col-lg-10 col-xl-10">
			<label class="header-user-detail">
				{{'orderDetail.title-orderDetail'|translate}}
			</label>
		</div>
		<div class="col-sm-2 col-md-2 col-lg-2 col-xl-2" (click)="closeModal()">
			<div class="closeIcon">
				<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="bi bi-x-lg icon" viewBox="0 0 16 16">
					<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
				</svg>
			</div>
		</div>
		<div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
			<hr class="line">
		</div>
	</div>
	<div class="row">
		<div class="col-6">
			<div class="row" style="display: flex; row-gap: 12px;">
				<div class="col-6">
					<div [ngClass]="{'remitente-information-active': viewRemitenteInformation ===true, 'remitente-information-desactive': viewRemitenteInformation ===false}" (click)="ViewRemitenteInformation()">
						<label class="active">
							Remitente
						</label>
					</div>
				</div>
				<div class="col-6">
					<div [ngClass]="{'destinatario-information-active': viewDestinatarioInformation ===true, 'destinatario-information-desactive': viewDestinatarioInformation ===false}" (click)="ViewDestinatarioInformation()">
						<label class="active">
							Destinatario
						</label>
					</div>
				</div>
				<div class="col-12">
					<div class="remitente-information-table" *ngIf="viewRemitenteInformation === true && viewCedisInformation === false && viewDestinatarioInformation === false">
						<div class="row" style="justify-content: center;">
							<div class="col-11">
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.remitente.trackingNumber' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">
											<!-- {{this.orderDetail..tracking_number}} -->
											{{this.orderDetail.data_package_service.tracking_number}}
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.remitente.originNumber' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">
											{{this.orderDetail.data_package_service.order_number}}
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.remitente.originLocker' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">
											{{this.orderDetail.data_locker.locker_source.locker_name}}
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.remitente.email' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">
											{{this.orderDetail.data_consumer.consumer_source.email}}
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.remitente.cellphone' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">
											{{this.orderDetail.data_consumer.consumer_source.main_tlf | phoneFormat}}
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.remitente.door' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">
											{{this.orderDetail.data_package_service.door.door_number}}, {{this.orderDetail.data_package_service.door.compartment_name}} ({{this.orderDetail.data_package_service.door.compartment_size}})
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.remitente.deliveryCategory' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">
											{{this.orderDetail.data_package_service.detail_package}}
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.remitente.valueProximate' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">
											{{this.orderDetail.data_package_service.aproximate_value}}
										</label>
									</div>
								</div>
								<!-- <div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.remitente.planCoverage' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">
											{{this.orderDetail.data_package_service.insurance}}
										</label>
									</div>
								</div> -->
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.remitente.scheduledDate' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">
											{{this.orderDetail.data_package_service.registration_date_schedule | dateFormatWithoutHour}}
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.remitente.maxRegisterDate' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">
											{{this.orderDetail.data_package_service.registration_date_in_locker | dateFormatWithoutHour}}
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.remitente.orderStatus' | translate}}
										</label>
									</div>
									<div class="table-item">
										<div class="text-response">
											<app-dialog-estatus-oxxo-to-oxxo [estatusID]="this.orderDetail.data_package_service.id_status">

											</app-dialog-estatus-oxxo-to-oxxo>
										</div>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.remitente.qualificationClient' | translate}}
										</label>
									</div>
									<div class="table-item">
										<div class="text-response">
											<app-star-rating starCount="5" [rating]="this.orderDetail.data_package_service.rate">

											</app-star-rating>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="cedis-information-table" *ngIf="viewRemitenteInformation === false && viewCedisInformation === true && viewDestinatarioInformation === false">
						<div class="row">
							<div class="col-12">
								<div class="table-contain">

								</div>
							</div>
						</div>
					</div>
					<div class="destinatario-information-table" *ngIf="viewRemitenteInformation === false && viewCedisInformation === false && viewDestinatarioInformation === true">
						<div class="row" style="justify-content: center;">
							<div class="col-11">
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.destinatario.trackingNumber' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">
											{{this.orderDetail.data_package_service.tracking_number}}
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.destinatario.destinyNumber' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">
											{{this.orderDetail.data_package_service.order_number}}
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.destinatario.destinyLocker' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">
											{{this.orderDetail.data_locker.locker_destination.locker_name}}
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.destinatario.email' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response" *ngIf="this.orderDetail.data_consumer.consumer_destination !== undefined">
											{{this.orderDetail.data_consumer.consumer_source.email}}
										</label>
										<label for="" class="text-response" *ngIf="this.orderDetail.data_consumer.consumer_destination === undefined">
											---
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.destinatario.cellphone' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response" *ngIf="this.orderDetail.data_consumer.consumer_destination !== undefined">
											{{this.orderDetail.data_consumer.consumer_destination.main_tlf | phoneFormat }}
										</label>
										<label for="" class="text-response" *ngIf="this.orderDetail.data_consumer.consumer_destination === undefined">
											---
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.destinatario.door' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">

										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.destinatario.deliverDateDestiny' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">
											{{this.orderDetail.data_package_service.pickup_date | dateFormatWithoutHour}}
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.destinatario.expirationDateDestiny' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">
											---
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.destinatario.orderStatus' | translate}}
										</label>
									</div>
									<div class="table-item">
										<div class="text-response">
											<app-dialog-estatus-oxxo-to-oxxo [estatusID]="this.orderDetail.data_package_service.id_status">

											</app-dialog-estatus-oxxo-to-oxxo>
										</div>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item-without-line">
										<label class="text">
											{{'oxxoToOxxo.dialog.destinatario.qualificationClient' | translate}}
										</label>
									</div>
									<div class="table-item-without-line">
										<div class="text-response">
											<app-star-rating starCount="5" [rating]="this.orderDetail.data_package_service.rate">

											</app-star-rating>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-6">
			<div class="row" >
				<div class="container-qr" *ngIf="viewRemitenteInformation === true && viewCedisInformation === false && viewDestinatarioInformation === false">
					<div class="box-qr">
						<div class="box-title">
							<label class="title">
								QR de entrega en origen
							</label>
							<app-button-icon [activo]="true" btnNombre="Descargar" btnType="primaryMedium" icon="icon-qr">

							</app-button-icon>
						</div>
						<div class="box-image-qr">
							<div class="border-box-image-qr">
								<div class="row" style="display: flex;
								justify-content: center;
								align-items: center;
								text-align: center;
								row-gap: 8px;">
									<div class="col-12">
										<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M9 12V9M9 6H9.0075M16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9Z" stroke="#4B4B4B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
										</svg>
									</div>
									<div class="col-12">
										<label class="label-text">
											Una vez el paquete este en ruta de entrega <br>
											el QR de recoleccion se generara
										</label>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="container-qr" *ngIf="viewRemitenteInformation === false && viewCedisInformation === true && viewDestinatarioInformation === false">
					<div class="box-qr">
						<div class="box-title">
							<label class="title">
								QR de entrega en origen
							</label>
							<app-button-icon [activo]="true" btnNombre="Descargar" btnType="primaryMedium" icon="icon-qr">

							</app-button-icon>
						</div>
						<div class="box-image-qr">
							<div class="border-box-image-qr">
								<div class="row" style="display: flex;
								justify-content: center;
								align-items: center;
								text-align: center;
								row-gap: 8px;">
									<div class="col-12">
										<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M9 12V9M9 6H9.0075M16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9Z" stroke="#4B4B4B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
										</svg>
									</div>
									<div class="col-12">
										<label class="label-text">
											Una vez el paquete este en ruta de entrega <br>
											el QR de recoleccion se generara
										</label>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="container-qr" *ngIf="viewRemitenteInformation === false && viewCedisInformation === false && viewDestinatarioInformation === true">
					<div class="box-qr">
						<div class="box-title">
							<label class="title">
								QR de entrega en origen
							</label>
							<app-button-icon [activo]="true" btnNombre="Descargar" btnType="primaryMedium" icon="icon-qr">

							</app-button-icon>
						</div>
						<div class="box-image-qr">
							<div class="border-box-image-qr">
								<div class="row" style="display: flex;
								justify-content: center;
								align-items: center;
								text-align: center;
								row-gap: 8px;">
									<div class="col-12">
										<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M9 12V9M9 6H9.0075M16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9Z" stroke="#4B4B4B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
										</svg>
									</div>
									<div class="col-12">
										<label class="label-text">
											Una vez el paquete este en ruta de entrega <br>
											el QR de recoleccion se generara
										</label>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="detail-oxxo-to-oxxo" *ngIf="this.from === 'Locker'">
	<div class="row">
		<div class="col-sm-10 col-md-10 col-lg-10 col-xl-10">
			<label class="header-user-detail">
				{{'orderDetail.title-orderDetail'|translate}}
			</label>
		</div>
		<div class="col-sm-2 col-md-2 col-lg-2 col-xl-2" (click)="closeModal()">
			<div class="closeIcon">
				<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="bi bi-x-lg icon" viewBox="0 0 16 16">
					<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
				</svg>
			</div>
		</div>
		<div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
			<hr class="line">
		</div>
	</div>
	<div class="row">
		<div class="col-6">
			<div class="row" style="display: flex; row-gap: 12px;">
				<div class="col-6">
					<div [ngClass]="{'remitente-information-active': viewRemitenteInformation ===true, 'remitente-information-desactive': viewRemitenteInformation ===false}" (click)="ViewRemitenteInformation()">
						<label class="active">
							Remitente
						</label>
					</div>
				</div>
				<div class="col-6">
					<div [ngClass]="{'destinatario-information-active': viewDestinatarioInformation ===true, 'destinatario-information-desactive': viewDestinatarioInformation ===false}" (click)="ViewDestinatarioInformation()">
						<label class="active">
							Destinatario
						</label>
					</div>
				</div>
				<div class="col-12">
					<div class="remitente-information-table" *ngIf="viewRemitenteInformation === true && viewCedisInformation === false && viewDestinatarioInformation === false">
						<div class="row" style="justify-content: center;">
							<div class="col-11">
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.remitente.trackingNumber' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">
											<!-- {{this.orderDetail..tracking_number}} -->
											{{this.orderDetail.data_package_service.tracking_number}}
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.remitente.originNumber' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">
											{{this.orderDetail.data_package_service.order_number}}
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.remitente.originLocker' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">
											{{this.orderDetail.data_locker.locker_source.locker_name}}
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.remitente.email' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">
											{{this.orderDetail.data_consumer.consumer_source.email}}
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.remitente.cellphone' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">
											{{this.orderDetail.data_consumer.consumer_source.main_tlf | phoneFormat}}
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.remitente.door' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">
											{{this.orderDetail.data_package_service.door.door_number}}, {{this.orderDetail.data_package_service.door.compartment_name}} ({{this.orderDetail.data_package_service.door.compartment_size}})
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.remitente.deliveryCategory' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">
											{{this.orderDetail.data_package_service.detail_package}}
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.remitente.valueProximate' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">
											{{this.orderDetail.data_package_service.aproximate_value}}
										</label>
									</div>
								</div>
								<!-- <div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.remitente.planCoverage' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">
											{{this.orderDetail.data_package_service.insurance}}
										</label>
									</div>
								</div> -->
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.remitente.scheduledDate' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">
											{{this.orderDetail.data_package_service.registration_date_schedule | dateFormatWithoutHour}}
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.remitente.maxRegisterDate' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">
											{{this.orderDetail.data_package_service.registration_date_in_locker | dateFormatWithoutHour}}
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.remitente.orderStatus' | translate}}
										</label>
									</div>
									<div class="table-item">
										<div class="text-response">
											<app-dialog-estatus-oxxo-to-oxxo [estatusID]="this.orderDetail.data_package_service.id_status">

											</app-dialog-estatus-oxxo-to-oxxo>
										</div>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item-without-line">
										<label class="text">
											{{'oxxoToOxxo.dialog.remitente.qualificationClient' | translate}}
										</label>
									</div>
									<div class="table-item-without-line">
										<div class="text-response">
											<app-star-rating starCount="5" [rating]="this.orderDetail.data_package_service.rate">

											</app-star-rating>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="cedis-information-table" *ngIf="viewRemitenteInformation === false && viewCedisInformation === true && viewDestinatarioInformation === false">
						<div class="row">
							<div class="col-12">
								<div class="table-contain">

								</div>
							</div>
						</div>
					</div>
					<div class="destinatario-information-table" *ngIf="viewRemitenteInformation === false && viewCedisInformation === false && viewDestinatarioInformation === true">
						<div class="row" style="justify-content: center;">
							<div class="col-11">
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.destinatario.trackingNumber' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">
											{{this.orderDetail.data_package_service.tracking_number}}
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.destinatario.destinyNumber' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">
											{{this.orderDetail.data_package_service.order_number}}
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.destinatario.destinyLocker' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">
											{{this.orderDetail.data_locker.locker_destination.locker_name}}
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.destinatario.email' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response" *ngIf="this.orderDetail.data_consumer.consumer_destination !== undefined">
											{{this.orderDetail.data_consumer.consumer_source.email}}
										</label>
										<label for="" class="text-response" *ngIf="this.orderDetail.data_consumer.consumer_destination === undefined">
											---
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.destinatario.cellphone' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response" *ngIf="this.orderDetail.data_consumer.consumer_destination !== undefined">
											{{this.orderDetail.data_consumer.consumer_destination.main_tlf | phoneFormat }}
										</label>
										<label for="" class="text-response" *ngIf="this.orderDetail.data_consumer.consumer_destination === undefined">
											---
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.destinatario.door' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">

										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.destinatario.deliverDateDestiny' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">
											{{this.orderDetail.data_package_service.pickup_date | dateFormatWithoutHour}}
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.destinatario.expirationDateDestiny' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">
											---
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.destinatario.orderStatus' | translate}}
										</label>
									</div>
									<div class="table-item">
										<div class="text-response">
											<app-dialog-estatus-oxxo-to-oxxo [estatusID]="this.orderDetail.data_package_service.id_status">

											</app-dialog-estatus-oxxo-to-oxxo>
										</div>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item-without-line">
										<label class="text">
											{{'oxxoToOxxo.dialog.destinatario.qualificationClient' | translate}}
										</label>
									</div>
									<div class="table-item-without-line">
										<div class="text-response">
											<app-star-rating starCount="5" [rating]="this.orderDetail.data_package_service.rate">

											</app-star-rating>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-6">
			<div class="row" >
				<div class="container-qr" *ngIf="viewRemitenteInformation === true && viewCedisInformation === false && viewDestinatarioInformation === false">
					<div class="box-qr">
						<div class="box-title">
							<label class="title">
								QR de entrega en origen
							</label>
							<app-button-icon [activo]="true" btnNombre="Descargar" btnType="primaryMedium" icon="icon-qr">

							</app-button-icon>
						</div>
						<div class="box-image-qr">
							<div class="border-box-image-qr">
								<div class="row" style="display: flex;
								justify-content: center;
								align-items: center;
								text-align: center;
								row-gap: 8px;">
									<div class="col-12">
										<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M9 12V9M9 6H9.0075M16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9Z" stroke="#4B4B4B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
										</svg>
									</div>
									<div class="col-12">
										<label class="label-text">
											Una vez el paquete este en ruta de entrega <br>
											el QR de recoleccion se generara
										</label>
									</div>
								</div>



							</div>
						</div>
					</div>
				</div>
				<div class="container-qr" *ngIf="viewRemitenteInformation === false && viewCedisInformation === true && viewDestinatarioInformation === false">
					<div class="box-qr">
						<div class="box-title">
							<label class="title">
								QR de entrega en CEDIS
							</label>
							<app-button-icon [activo]="true" btnNombre="Descargar" btnType="primaryMedium" icon="icon-qr">

							</app-button-icon>
						</div>
						<div class="box-image-qr">
							<div class="border-box-image-qr-with-image">
								<div class="row" style="display: flex;
														justify-content: center;
								align-items: center;
								text-align: center;
								row-gap: 8px;">
									<div class="col-12">
										<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M9 12V9M9 6H9.0075M16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9Z" stroke="#4B4B4B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
										</svg>
									</div>
									<div class="col-12">
										<label class="label-text">
											Una vez el paquete este en ruta de entrega <br>
											el QR de recoleccion se generara
										</label>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="container-qr" *ngIf="viewRemitenteInformation === false && viewCedisInformation === false && viewDestinatarioInformation === true">
					<div class="box-qr">
						<div class="box-title">
							<label class="title">
								QR de entrega en destino
							</label>
							<app-button-icon [activo]="false" btnNombre="Descargar" btnType="primaryMedium" icon="icon-qr">

							</app-button-icon>
						</div>
						<div class="box-image-qr">
							<div class="border-box-image-qr-with-image">
								<div class="row" style="display: flex;
								justify-content: center;
								align-items: center;
								text-align: center;
								row-gap: 8px;">
									<div class="col-12">
										<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M9 12V9M9 6H9.0075M16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9Z" stroke="#4B4B4B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
										</svg>
									</div>
									<div class="col-12">
										<label class="label-text">
											Una vez el paquete este en ruta de entrega <br>
											el QR de recoleccion se generara
										</label>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="detail-oxxo-to-oxxo" *ngIf="this.from === 'scheduled'">
	<div class="row">
		<div class="col-sm-10 col-md-10 col-lg-10 col-xl-10">
			<label class="header-user-detail">
				{{'orderDetail.title-orderDetail'|translate}}
			</label>
		</div>
		<div class="col-sm-2 col-md-2 col-lg-2 col-xl-2" (click)="closeModal()">
			<div class="closeIcon">
				<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="bi bi-x-lg icon" viewBox="0 0 16 16">
					<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
				</svg>
			</div>
		</div>
		<div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
			<hr class="line">
		</div>
	</div>
	<div class="row">
		<div class="col-6">
			<div class="row" style="display: flex; row-gap: 12px;">
				<div class="col-3">
					<div [ngClass]="{'remitente-information-active': viewRemitenteInformation ===true, 'remitente-information-desactive': viewRemitenteInformation ===false}" (click)="ViewRemitenteInformation()">
						<label class="active">
							Remitente
						</label>
					</div>
				</div>
				<div class="col-3">
					<div [ngClass]="{'destinatario-information-active': viewDestinatarioInformation ===true, 'destinatario-information-desactive': viewDestinatarioInformation ===false}" (click)="ViewDestinatarioInformation()">
						<label class="active">
							Destinatario
						</label>
					</div>
				</div>
				<div class="col-12">
					<div class="remitente-information-table" *ngIf="viewRemitenteInformation === true && viewCedisInformation === false && viewDestinatarioInformation === false">
						<div class="row" style="justify-content: center;">
							<div class="col-11">
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.remitente.originNumber' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">
											{{this.scheduledDetail.data_package_service.number_orden}}
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.remitente.originLocker' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">
											{{this.scheduledDetail.data_locker.locker_source.locker_name}}
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.remitente.email' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">
											{{this.scheduledDetail.data_consumer.source_consumer.email_consumer}}
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.remitente.cellphone' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">
											{{this.scheduledDetail.data_consumer.source_consumer.main_tlf | phoneFormat}}
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.remitente.door' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.remitente.deliveryCategory' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response" *ngFor="let item of this.scheduledDetail.data_package_service.detail_package">
											{{item.description}}
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.remitente.valueProximate' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">
											${{this.scheduledDetail.data_package_service.approximate_value}}
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.remitente.scheduledDate' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">
											{{this.scheduledDetail.registration_date | dateFormatWithoutHour}}
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.remitente.maxRegisterDate' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">
											{{this.scheduledDetail.registration_date | dateFormatWithoutHour}}
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.remitente.orderStatus' | translate}}
										</label>
									</div>
									<div class="table-item">
										<div class="text-response">
											<app-dialog-estatus-oxxo-to-oxxo [estatusID]="this.scheduledDetail.id_status">
											</app-dialog-estatus-oxxo-to-oxxo>
										</div>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item-without-line">
										<label class="text">
											{{'oxxoToOxxo.dialog.remitente.qualificationClient' | translate}}
										</label>
									</div>
									<div class="table-item-without-line">
										<div class="text-response">
											<app-star-rating starCount="5" [rating]="0">
											</app-star-rating>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="cedis-information-table" *ngIf="viewRemitenteInformation === false && viewCedisInformation === true && viewDestinatarioInformation === false">
						<div class="row">
							<div class="col-12">
								<div class="table-contain">

								</div>
							</div>
						</div>
					</div>
					<div class="destinatario-information-table" *ngIf="viewRemitenteInformation === false && viewCedisInformation === false && viewDestinatarioInformation === true">
						<div class="row" style="justify-content: center;">
							<div class="col-11">
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.destinatario.destinyNumber' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">
											{{this.scheduledDetail.data_package_service.number_orden}}
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.destinatario.destinyLocker' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">
											{{this.scheduledDetail.data_locker.locker_destination.locker_name}}
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.destinatario.email' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">
											{{this.scheduledDetail.data_consumer.destination_consumer.email_consumer}}
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.destinatario.cellphone' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">
											{{this.scheduledDetail.data_consumer.destination_consumer.main_tlf | phoneFormat }}
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.destinatario.door' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">

										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.destinatario.deliverDateDestiny' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">
											---
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.destinatario.expirationDateDestiny' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">
											---
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.destinatario.orderStatus' | translate}}
										</label>
									</div>
									<div class="table-item">
										<div class="text-response">
											<app-dialog-estatus-oxxo-to-oxxo [estatusID]="this.scheduledDetail.id_status">

											</app-dialog-estatus-oxxo-to-oxxo>
										</div>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.destinatario.qualificationClient' | translate}}
										</label>
									</div>
									<div class="table-item">
										<div class="text-response">
											<app-star-rating starCount="5" [rating]="4">

											</app-star-rating>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-6">
			<div class="row" >
				<div class="container-qr" *ngIf="viewRemitenteInformation === true && viewCedisInformation === false && viewDestinatarioInformation === false">
					<div class="box-qr">
						<div class="box-title">
							<label class="title">
								QR de entrega en origen
							</label>
							<app-button-icon [activo]="true" btnNombre="Descargar" btnType="primaryMedium" icon="icon-qr">

							</app-button-icon>
						</div>
						<div class="box-image-qr">
							<div class="border-box-image-qr">
								<div class="row" style="display: flex;
								justify-content: center;
								align-items: center;
								text-align: center;
								row-gap: 8px;">
									<div class="col-12">
										<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M9 12V9M9 6H9.0075M16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9Z" stroke="#4B4B4B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
										</svg>
									</div>
									<div class="col-12">
										<label class="label-text">
											Una vez el paquete este en ruta de entrega <br>
											el QR de recoleccion se generara
										</label>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="container-qr" *ngIf="viewRemitenteInformation === false && viewCedisInformation === true && viewDestinatarioInformation === false">
					<div class="box-qr">
						<div class="box-title">
							<label class="title">
								QR de entrega en origen
							</label>
							<app-button-icon [activo]="false" btnNombre="Descargar" btnType="primaryMedium" icon="icon-qr">
							</app-button-icon>
						</div>
						<div class="box-image-qr">
							<div class="border-box-image-qr">
								<div class="row" style="display: flex;
								justify-content: center;
								align-items: center;
								text-align: center;
								row-gap: 8px;">
									<div class="col-12">
										<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M9 12V9M9 6H9.0075M16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9Z" stroke="#4B4B4B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
										</svg>
									</div>
									<div class="col-12">
										<label class="label-text">
											Una vez el paquete este en ruta de entrega <br>
											el QR de recoleccion se generara
										</label>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="container-qr" *ngIf="viewRemitenteInformation === false && viewCedisInformation === false && viewDestinatarioInformation === true">
					<div class="box-qr">
						<div class="box-title">
							<label class="title">
								QR de entrega en origen
							</label>
							<app-button-icon [activo]="true" btnNombre="Descargar" btnType="primaryMedium" icon="icon-qr">

							</app-button-icon>
						</div>
						<div class="box-image-qr">
							<div class="border-box-image-qr">
								<div class="row" style="display: flex;
								justify-content: center;
								align-items: center;
								text-align: center;
								row-gap: 8px;">
									<div class="col-12">
										<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M9 12V9M9 6H9.0075M16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9Z" stroke="#4B4B4B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
										</svg>
									</div>
									<div class="col-12">
										<label class="label-text">
											Una vez el paquete este en ruta de entrega <br>
											el QR de recoleccion se generara
										</label>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="detail-oxxo-to-oxxo" *ngIf="this.from === 'ScheduledLocker'">
	<div class="row">
		<div class="col-sm-10 col-md-10 col-lg-10 col-xl-10">
			<label class="header-user-detail">
				{{'orderDetail.title-orderDetail'|translate}}
			</label>
		</div>
		<div class="col-sm-2 col-md-2 col-lg-2 col-xl-2" (click)="closeModal()">
			<div class="closeIcon">
				<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="bi bi-x-lg icon" viewBox="0 0 16 16">
					<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
				</svg>
			</div>
		</div>
		<div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
			<hr class="line">
		</div>
	</div>
	<div class="row">
		<div class="col-6">
			<div class="row" style="display: flex; row-gap: 12px;">
				<div class="col-3">
					<div [ngClass]="{'remitente-information-active': viewRemitenteInformation ===true, 'remitente-information-desactive': viewRemitenteInformation ===false}" (click)="ViewRemitenteInformation()">
						<label class="active">
							Remitente
						</label>
					</div>
				</div>
				<div class="col-3">
					<div [ngClass]="{'destinatario-information-active': viewDestinatarioInformation ===true, 'destinatario-information-desactive': viewDestinatarioInformation ===false}" (click)="ViewDestinatarioInformation()">
						<label class="active">
							Destinatario
						</label>
					</div>
				</div>
				<div class="col-12">
					<div class="remitente-information-table" *ngIf="viewRemitenteInformation === true && viewCedisInformation === false && viewDestinatarioInformation === false">
						<div class="row" style="justify-content: center;">
							<div class="col-11">
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.remitente.originNumber' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">
											{{this.scheduledDetail.code_service}}
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.remitente.originLocker' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">
											{{this.scheduledDetail.data_locker.locker_source.locker_name}}
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.remitente.email' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">
											{{this.scheduledDetail.data_consumer.source_consumer.email_consumer}}
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.remitente.cellphone' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">
											{{this.scheduledDetail.data_consumer.source_consumer.main_tlf | phoneFormat}}
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.remitente.door' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.remitente.deliveryCategory' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response" *ngFor="let item of this.scheduledDetail.data_package_service.detail_package">
											{{item.description}}
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.remitente.valueProximate' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">
											${{this.scheduledDetail.data_package_service.approximate_value}}
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.remitente.scheduledDate' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">
											{{this.scheduledDetail.registration_date | dateFormatWithoutHour}}
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.remitente.maxRegisterDate' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">
											{{this.scheduledDetail.registration_date | dateFormatWithoutHour}}
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.remitente.orderStatus' | translate}}
										</label>
									</div>
									<div class="table-item">
										<div class="text-response">
											<app-dialog-estatus-oxxo-to-oxxo [estatusID]="this.scheduledDetail.id_status">
											</app-dialog-estatus-oxxo-to-oxxo>
										</div>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item-without-line">
										<label class="text">
											{{'oxxoToOxxo.dialog.remitente.qualificationClient' | translate}}
										</label>
									</div>
									<div class="table-item-without-line">
										<div class="text-response">
											<app-star-rating starCount="5" [rating]="0">
											</app-star-rating>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="cedis-information-table" *ngIf="viewRemitenteInformation === false && viewCedisInformation === true && viewDestinatarioInformation === false">
						<div class="row">
							<div class="col-12">
								<div class="table-contain">

								</div>
							</div>
						</div>
					</div>
					<div class="destinatario-information-table" *ngIf="viewRemitenteInformation === false && viewCedisInformation === false && viewDestinatarioInformation === true">
						<div class="row" style="justify-content: center;">
							<div class="col-11">
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.destinatario.destinyNumber' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">
											{{this.scheduledDetail.data_package_service.number_orden}}
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.destinatario.destinyLocker' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">
											{{this.scheduledDetail.data_locker.locker_destination.locker_name}}
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.destinatario.email' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">
											{{this.scheduledDetail.data_consumer.destination_consumer.email_consumer}}
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.destinatario.cellphone' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">
											{{this.scheduledDetail.data_consumer.destination_consumer.main_tlf | phoneFormat }}
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.destinatario.door' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">

										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.destinatario.deliverDateDestiny' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">
											---
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.destinatario.expirationDateDestiny' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">
											---
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.destinatario.orderStatus' | translate}}
										</label>
									</div>
									<div class="table-item">
										<div class="text-response">
											<app-dialog-estatus-oxxo-to-oxxo [estatusID]="this.scheduledDetail.id_status">

											</app-dialog-estatus-oxxo-to-oxxo>
										</div>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item-without-line">
										<label class="text">
											{{'oxxoToOxxo.dialog.destinatario.qualificationClient' | translate}}
										</label>
									</div>
									<div class="table-item-without-line">
										<div class="text-response">
											<app-star-rating starCount="5" [rating]="4">

											</app-star-rating>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-6">
			<div class="row" >
				<div class="container-qr" *ngIf="viewRemitenteInformation === true && viewCedisInformation === false && viewDestinatarioInformation === false">
					<div class="box-qr">
						<div class="box-title">
							<label class="title">
								QR de entrega en origen
							</label>
							<app-button-icon [activo]="true" btnNombre="Descargar" btnType="primaryMedium" icon="icon-qr">

							</app-button-icon>
						</div>
						<div class="box-image-qr">
							<div class="border-box-image-qr">
								<div class="row" style="display: flex;
								justify-content: center;
								align-items: center;
								text-align: center;
								row-gap: 8px;">
									<div class="col-12">
										<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M9 12V9M9 6H9.0075M16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9Z" stroke="#4B4B4B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
										</svg>
									</div>
									<div class="col-12">
										<label class="label-text">
											Una vez el paquete este en ruta de entrega <br>
											el QR de recoleccion se generara
										</label>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="container-qr" *ngIf="viewRemitenteInformation === false && viewCedisInformation === true && viewDestinatarioInformation === false">
					<div class="box-qr">
						<div class="box-title">
							<label class="title">
								QR de entrega en origen
							</label>
							<app-button-icon [activo]="true" btnNombre="Descargar" btnType="primaryMedium" icon="icon-qr">
							</app-button-icon>
						</div>
						<div class="box-image-qr">
							<div class="border-box-image-qr">
								<div class="row" style="display: flex;
								justify-content: center;
								align-items: center;
								text-align: center;
								row-gap: 8px;">
									<div class="col-12">
										<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M9 12V9M9 6H9.0075M16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9Z" stroke="#4B4B4B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
										</svg>
									</div>
									<div class="col-12">
										<label class="label-text">
											Una vez el paquete este en ruta de entrega <br>
											el QR de recoleccion se generara
										</label>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="container-qr" *ngIf="viewRemitenteInformation === false && viewCedisInformation === false && viewDestinatarioInformation === true">
					<div class="box-qr">
						<div class="box-title">
							<label class="title">
								QR de entrega en origen
							</label>
							<app-button-icon [activo]="false" btnNombre="Descargar" btnType="primaryMedium" icon="icon-qr">

							</app-button-icon>
						</div>
						<div class="box-image-qr">
							<div class="border-box-image-qr">
								<div class="row" style="display: flex;
								justify-content: center;
								align-items: center;
								text-align: center;
								row-gap: 8px;">
									<div class="col-12">
										<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M9 12V9M9 6H9.0075M16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9Z" stroke="#4B4B4B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
										</svg>
									</div>
									<div class="col-12">
										<label class="label-text">
											Una vez el paquete este en ruta de entrega <br>
											el QR de recoleccion se generara
										</label>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="detail-oxxo-to-oxxo" *ngIf="this.from === 'warehouse'">
	<div class="row">
		<div class="col-sm-10 col-md-10 col-lg-10 col-xl-10">
			<label class="header-user-detail">
				{{'orderDetail.title-orderDetail'|translate}}
			</label>
		</div>
		<div class="col-sm-2 col-md-2 col-lg-2 col-xl-2" (click)="closeModal()">
			<div class="closeIcon">
				<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="bi bi-x-lg icon" viewBox="0 0 16 16">
					<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
				</svg>
			</div>
		</div>
		<div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
			<hr class="line">
		</div>
	</div>
	<div class="row">
		<div class="col-6">
			<div class="row" style="display: flex; row-gap: 12px;">
				<div class="col-6">
					<div [ngClass]="{'remitente-information-active': viewRemitenteInformation ===true, 'remitente-information-desactive': viewRemitenteInformation ===false}" (click)="ViewRemitenteInformation()">
						<label class="active">
							Remitente
						</label>
					</div>
				</div>
				<div class="col-6">
					<div [ngClass]="{'destinatario-information-active': viewDestinatarioInformation ===true, 'destinatario-information-desactive': viewDestinatarioInformation ===false}" (click)="ViewDestinatarioInformation()">
						<label class="active">
							Destinatario
						</label>
					</div>
				</div>
				<div class="col-12">
					<div class="remitente-information-table" *ngIf="viewRemitenteInformation === true && viewCedisInformation === false && viewDestinatarioInformation === false">
						<div class="row" style="justify-content: center;">
							<div class="col-11">
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.remitente.trackingNumber' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">
											{{this.orderDetailInTransint.data_package_service.tracking_number}}
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.remitente.originNumber' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">
											{{this.orderDetailInTransint.data_package_service.order_number}}
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.remitente.originLocker' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">
											{{this.orderDetailInTransint.data_locker.locker_source.locker_name}}
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.remitente.email' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">

											{{this.orderDetailInTransint.data_consumer.consumer_source.email}}
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.remitente.cellphone' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">
											{{this.orderDetailInTransint.data_consumer.consumer_source.main_tlf | phoneFormat}}
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.remitente.door' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">

										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.remitente.deliveryCategory' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">
											{{this.orderDetailInTransint.data_package_service.detail_package}}
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.remitente.valueProximate' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">

										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.remitente.scheduledDate' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">
											{{this.orderDetailInTransint.data_package_service.registration_date_schedule | dateFormatWithoutHour}}
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.remitente.maxRegisterDate' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">
											{{this.orderDetailInTransint.data_package_service.registration_date_in_locker | dateFormatWithoutHour}}
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.remitente.orderStatus' | translate}}
										</label>
									</div>
									<div class="table-item">
										<div class="text-response">
											<app-dialog-estatus-oxxo-to-oxxo [estatusID]="this.orderDetailInTransint.data_package_service.id_status">

											</app-dialog-estatus-oxxo-to-oxxo>
										</div>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item-without-line">
										<label class="text">
											{{'oxxoToOxxo.dialog.remitente.qualificationClient' | translate}}
										</label>
									</div>
									<div class="table-item-without-line">
										<div class="text-response">
											<app-star-rating starCount="5" [rating]="this.orderDetailInTransint.data_package_service.rate">

											</app-star-rating>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="cedis-information-table" *ngIf="viewRemitenteInformation === false && viewCedisInformation === true && viewDestinatarioInformation === false">
						<div class="row">
							<div class="col-12">
								<div class="table-contain">

								</div>
							</div>
						</div>
					</div>
					<div class="destinatario-information-table" *ngIf="viewRemitenteInformation === false && viewCedisInformation === false && viewDestinatarioInformation === true">
						<div class="row" style="justify-content: center;">
							<div class="col-11">
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.destinatario.trackingNumber' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">
											{{this.orderDetailInTransint.data_package_service.tracking_number}}
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.destinatario.destinyNumber' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">
											{{this.orderDetailInTransint.data_package_service.order_number}}
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.destinatario.destinyLocker' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">
											{{this.orderDetailInTransint.data_locker.locker_destination.locker_name}}
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.destinatario.email' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response" *ngIf="this.orderDetailInTransint.data_consumer.consumer_destination !== undefined">
											{{this.orderDetailInTransint.data_consumer.consumer_source.email}}
										</label>
										<label for="" class="text-response" *ngIf="this.orderDetailInTransint.data_consumer.consumer_destination === undefined">
											---
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.destinatario.cellphone' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response" *ngIf="this.orderDetailInTransint.data_consumer.consumer_destination !== undefined">
											{{this.orderDetailInTransint.data_consumer.consumer_destination.main_tlf | phoneFormat }}
										</label>
										<label for="" class="text-response" *ngIf="this.orderDetailInTransint.data_consumer.consumer_destination === undefined">
											---
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.destinatario.door' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">

										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.destinatario.deliverDateDestiny' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">
											---
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.destinatario.expirationDateDestiny' | translate}}
										</label>
									</div>
									<div class="table-item">
										<label for="" class="text-response">
											---
										</label>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.destinatario.orderStatus' | translate}}
										</label>
									</div>
									<div class="table-item">
										<div class="text-response">
											<app-dialog-estatus-oxxo-to-oxxo [estatusID]="this.orderDetailInTransint.data_package_service.id_status">

											</app-dialog-estatus-oxxo-to-oxxo>
										</div>
									</div>
								</div>
								<div class="table-contain">
									<div class="table-item">
										<label class="text">
											{{'oxxoToOxxo.dialog.destinatario.qualificationClient' | translate}}
										</label>
									</div>
									<div class="table-item">
										<div class="text-response">
											<app-star-rating starCount="5" [rating]="this.orderDetailInTransint.data_package_service.rate">

											</app-star-rating>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-6">
			<div class="row" >
				<div class="container-qr" *ngIf="viewRemitenteInformation === true && viewCedisInformation === false && viewDestinatarioInformation === false">
					<div class="box-qr">
						<div class="box-title">
							<label class="title">
								QR de entrega en origen
							</label>
							<app-button-icon [activo]="true" btnNombre="Descargar" btnType="primaryMedium" icon="icon-qr">

							</app-button-icon>
						</div>
						<div class="box-image-qr">
							<div class="border-box-image-qr">
								<div class="row" style="display: flex;
								justify-content: center;
								align-items: center;
								text-align: center;
								row-gap: 8px;">
									<div class="col-12">
										<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M9 12V9M9 6H9.0075M16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9Z" stroke="#4B4B4B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
										</svg>
									</div>
									<div class="col-12">
										<label class="label-text">
											Una vez el paquete este en ruta de entrega <br>
											el QR de recoleccion se generara
										</label>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="container-qr" *ngIf="viewRemitenteInformation === false && viewCedisInformation === true && viewDestinatarioInformation === false">
					<div class="box-qr">
						<div class="box-title">
							<label class="title">
								QR de entrega en origen
							</label>
							<app-button-icon [activo]="true" btnNombre="Descargar" btnType="primaryMedium" icon="icon-qr">

							</app-button-icon>
						</div>
						<div class="box-image-qr">
							<div class="border-box-image-qr">
								<div class="row" style="display: flex;
								justify-content: center;
								align-items: center;
								text-align: center;
								row-gap: 8px;">
									<div class="col-12">
										<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M9 12V9M9 6H9.0075M16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9Z" stroke="#4B4B4B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
										</svg>
									</div>
									<div class="col-12">
										<label class="label-text">
											Una vez el paquete este en ruta de entrega <br>
											el QR de recoleccion se generara
										</label>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="container-qr" *ngIf="viewRemitenteInformation === false && viewCedisInformation === false && viewDestinatarioInformation === true">
					<div class="box-qr">
						<div class="box-title">
							<label class="title">
								QR de entrega en origen
							</label>
							<app-button-icon [activo]="false" btnNombre="Descargar" btnType="primaryMedium" icon="icon-qr">

							</app-button-icon>
						</div>
						<div class="box-image-qr">
							<div class="border-box-image-qr">
								<div class="row" style="display: flex;
								justify-content: center;
								align-items: center;
								text-align: center;
								row-gap: 8px;">
									<div class="col-12">
										<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M9 12V9M9 6H9.0075M16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9Z" stroke="#4B4B4B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
										</svg>
									</div>
									<div class="col-12">
										<label class="label-text">
											Una vez el paquete este en ruta de entrega <br>
											el QR de recoleccion se generara
										</label>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
