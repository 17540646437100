// ANGULAR
import {
	AgGridEvent,
	CellClickedEvent,
	ColDef,
	GridOptions,
	GridReadyEvent,
} from 'ag-grid-community';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { AdminClientService } from 'src/app/core/http/admin-client.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import * as XLSX from 'xlsx';
import { EstatusTableOrderAdminComponent } from '../estatus-table-order-admin/estatus-table-order-admin.component';
import { DateTextComponent } from '../date-text/date-text.component';
import { Component, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { EstatusActivityPackageComponent } from '../estatus-activity-package/estatus-activity-package.component';
import { DataService } from 'src/app/core/services/data.service';
import { AgGridAngular } from 'ag-grid-angular';
import { EstatusTableOrderVivipostComponent } from 'src/app/modules/vivipost/components/estatus/estatus-table-order-vivipost/estatus-table-order-vivipost.component';
import { StatusTopicAuditComponent } from 'src/app/shared/components/dashboard-components/status-topic-audit/status-topic-audit.component';
import { DateTextTablesComponent } from 'src/app/shared/components/date-text-tables/date-text-tables.component';
import { DateTextWarehouseComponent } from 'src/app/modules/warehouse/components/date-text-warehouse/date-text-warehouse.component';
interface GridConfig {
	columnDefs: ColDef[];
	rowData: any[];
}

@Component({
	selector: 'app-activity-table-admin',
	templateUrl: './activity-table-admin.component.html',
	styleUrls: ['./activity-table-admin.component.scss'],
})
export class ActivityTableAdminComponent implements OnInit {
	@ViewChild('agGrid') agGrid: AgGridAngular;
	filterText: string = '';
	gridOptions: GridOptions;
	selectedMarkerData: any;
	public filteredData: any[] = [];
	rangeDates: Date[];
	InitialDate = new Date();
	FinalDate = new Date();
	rowData: any[];
	rowDataGeneralAdmin;
	today = new Date();
	year = this.today.getFullYear();
	month = this.today.getMonth();
	day = this.today.getDate();
	lastMonth: Date;
	date_start;
	date_end;
	data;
	dataTemp = [];
	columnDefs: ColDef[];
	public columnDefsActivity: ColDef[];
	isLoading = false;
	public overlayNoRowsTemplate = `
		<div class="no-rows-content">
			<div class="img-no-content">
				<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
					<path d="M34.1667 26.6667H13.3333M13.3333 26.6667V5.83333M13.3333 26.6667L5.83333 34.1667M5.83333 13.3333H26.6667M26.6667 13.3333V34.1667M26.6667 13.3333L34.1667 5.83333M35 25.5621V6.33333C35 5.86662 35 5.63327 34.9092 5.45501C34.8293 5.29821 34.7018 5.17072 34.545 5.09083C34.3667 5 34.1334 5 33.6667 5H14.4379C14.0303 5 13.8264 5 13.6346 5.04605C13.4646 5.08688 13.302 5.15422 13.1529 5.2456C12.9847 5.34867 12.8405 5.4928 12.5523 5.78105L5.78105 12.5523C5.4928 12.8405 5.34867 12.9847 5.2456 13.1529C5.15422 13.302 5.08688 13.4646 5.04605 13.6346C5 13.8264 5 14.0303 5 14.4379V33.6667C5 34.1334 5 34.3667 5.09083 34.545C5.17072 34.7018 5.29821 34.8293 5.45501 34.9092C5.63327 35 5.86662 35 6.33333 35H25.5621C25.9697 35 26.1736 35 26.3654 34.9539C26.5354 34.9131 26.698 34.8458 26.8471 34.7544C27.0153 34.6513 27.1595 34.5072 27.4477 34.219L34.2189 27.4477C34.5072 27.1595 34.6513 27.0153 34.7544 26.8471C34.8458 26.698 34.9131 26.5354 34.9539 26.3654C35 26.1736 35 25.9697 35 25.5621Z" stroke="#98A2B3" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
				</svg>
			</div>
			<div class="txt-no-content">
				<div class="title-no-content">
					No hay nada por aquí...
				</div>
				<div class="subtitle-no-content">
					En esta tabla encontrarás los lockers
				</div>
			</div>
		</div>
	`;
	public overlayLoadingTemplate =  `
		<div class="loader-container">
			<div class="loader-template">
			</div>
			<div class="text-loader">
				<div class="title">
					Cargando contenido...
				</div>
			</div>
		</div>
	`
	constructor(
		private adminService: AdminClientService,
		private translate: TranslateService,
		private datePipe: DatePipe,
		public dataService: DataService,
		private toast: ToastrService
	) {}
	ngOnInit() {
		this.gridOptions = {
			pagination: true,
			paginationPageSize: 10,
			headerHeight:49,
			rowHeight:49,
			// domLayout:'autoHeight',
			enableCellTextSelection: true,
			defaultColDef:{
				resizable: false,
				sortable: true,
				flex:1
			}
		}
		let today = new Date();
		let month = today.getMonth();
		let year = today.getFullYear();
		let day = today.getDate();
		let lastMonth;
		let endDate;
		let startDate;
		if (month < 0) {
			month = 11;
			year -= 1;
		}
		lastMonth = new Date(year, month - 1, day);
		endDate = new Date(year, month, day);
		startDate = new Date(year, lastMonth.getMonth(), day);
		this.rangeDates = [startDate, endDate];
		this.updateColDef(localStorage.getItem('language'));
		this.getTableCompleted();
		this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
			this.translate.use(event.lang);

		});
	}
	ngOnChanges(changes: SimpleChanges) {
		this.rowDataGeneralAdmin = this.rowData;
	}
	updateColDef(lang:string){
		const translations = require(`../../../../../assets/languaje/${lang}.json`);
		this.columnDefs = this.columnDefs = [
			{
				headerValueGetter: () => translations.tableActivity.idEvent,
				field: 'id_audit_locker',
				autoHeight:false,
				sortable: true,
				resizable: false,
				suppressMovable: true,
				headerClass:'header-text-table',
				cellClass:'text-all-cells',
				minWidth: 120,
				maxWidth:120,
			},
			{
				headerValueGetter: () => translations.tableActivity.topicNameEvent,
				field: 'topic_name',
				autoHeight:false,
				sortable: true,
				resizable: false,
				suppressMovable: true,
				headerClass:'header-text-table',
				cellClass:'text-all-cells',
				minWidth: 350,
				maxWidth:350,
				cellRenderer: StatusTopicAuditComponent
			},
			{
				headerValueGetter: () => translations.tableActivity.registrationDateEvent,
				field: 'registration_date',
				autoHeight:false,
				sortable: true,
				resizable: false,
				suppressMovable: true,
				headerClass:'header-text-table',
				cellClass:'text-all-cells',
				minWidth:200,
				maxWidth:250,
				cellRenderer: DateTextWarehouseComponent
			},
			{
				headerValueGetter: () => translations.tableActivity.description,
				field: 'description',
				autoHeight:false,
				sortable: true,
				resizable: false,
				suppressMovable: true,
				headerClass:'header-text-table',
				cellClass:'text-cell-activity',
				minWidth: 700,
				maxWidth:700,
			},
		]
	}
	async getTableCompleted(date_start?: number, date_end?: number) {
		this.data = {
			id_locker: localStorage.getItem('id_locker'),
			language: localStorage.getItem('language'),
			date_start: this.InitialDate.getTime(),
			date_end: this.FinalDate.getTime(),
			limit: 30,
		};
		this.isLoading = true;
		await this.adminService.auditLocker(this.data).then((res:any)=>{
			if(res.mensaje_return.CODE === 200){
				this.rowData = res.return_result;
			}
			else{
				this.rowData = [];
			}
		});
	}
	handleReceivedDates(dates: Date[]): void {
		if (dates[0] !== null) {
			this.date_start = dates[0].getTime();
		}
		if (dates[1] !== null) {
			this.date_end = dates[1].getTime();
			this.getTableCompleted(this.date_start, this.date_end);
		}
	}
}

interface InterfaceEmployeeRefund {
	name: string;
	id: string;
}
