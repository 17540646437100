<div class="sidebar_cont">
    <div class="section_">
        <div class="block_">
            <div class="logo_txt_cont">
                <div class="logo_cont">
                    <img [src]="'../../../../assets/logos/vivipost/'+this.mode+'/logo-vivipost-sidebar.svg'" alt="logo">
                </div>
                <div class="txt_cont opacity_">
                    <p class="font_medium caption_c1 color-txt-primary"> {{companyName}} </p>
                    <p class="font_regular caption_c3 color_primary_300"> {{companyType | titlecase}} </p>
                </div>
            </div>
            <div class="chevron_container">
                <div class="icon_chevron"></div>
                <input width="20px" height="20px" (click)="closeAllDrops()" type="checkbox" class="main_checkbox">
            </div>
        </div>
        <div class="hr_cont">
            <div class="hr_"></div>
        </div>
        <div class="block_menu">
            <ul class="ul_">
                <li *ngFor="let nav of menuBlock1">
                    <a  class="link_menu"
                        [routerLink]="nav.route"
                        routerLinkActive="active_link">
                        <div class="icon_txt">
                            <a  class="icon_link_cont"
                                routerLinkActive="active_link_icon">
                                <img [src]="'../../../../assets/icons/sidebar_icons/icon-sidebar-'+nav.icon+'.svg'" class="icon_link" alt="menu">
                            </a>
                            <p class="font_regular caption_c1 color-txt-primary opacity_"> {{nav.txt | translate }} </p>
                        </div>
                    </a>
                </li>
            </ul>
        </div>
    </div>
    <div class="section_">
        <div class="hr_cont">
            <div class="hr_"></div>
        </div>
        <div class="block_menu">
            <ul>
                <li *ngFor="let nav of menuBlock2" >
                    <a  class="link_menu" *ngIf="this.idUserType !== 11 && this.idUserType !== 12  && this.idUserType !== 6"
                        [routerLink]="nav.route"
                        routerLinkActive="active_link">
                        <div class="icon_txt">
                            <a  class="icon_link_cont"
                                routerLinkActive="active_link_icon">
                                <img [src]="'../../../../assets/icons/sidebar_icons/icon-sidebar-'+nav.icon+'.svg'" class="icon_link" alt="menu">
                            </a>
                            <p class="caption_c1 color-txt-primary opacity_"> {{nav.txt | translate }} </p>
                        </div>
                    </a>
                </li>
                <li class="li_lang">
                    <div class="link_menu" (click)="toggleLanguageDropdown()">
                        <div class="icon_txt">
                            <a  class="icon_link_cont"
                                routerLinkActive="active_link_icon">
                                <img src="../../../../assets/icons/sidebar_icons/icon-sidebar-lang.svg" class="icon_link" alt="lenguage">
                            </a>
                            <p class="caption_c1 color-txt-primary opacity_"> {{'navbar.lang' | translate }} </p>
                        </div>
                        <div class="icon_chevron_menu"></div>
                        <input  type="checkbox" (click)="checkLang()" [(ngModel)]="this.langCheck" class="checkbox_menu">
                    </div>
                    <div class="dropdown_content" *ngIf="langCheck">
                        <ul class="dropdown_list">
                            <li [ngClass]="{ 'lang_selected' : getLang() == 'es' }">
                                <p  class="caption_c1 color-txt-primary font_regular"
                                    (click)="changeLanguage('es')">
                                    {{ 'navbar.es' | translate}}
                                </p>
                            </li>
                            <li [ngClass]="{ 'lang_selected' : getLang() == 'en' }">
                                <p  class="caption_c1 color-txt-primary font_regular"
                                    (click)="changeLanguage('en')">
                                    {{ 'navbar.en' | translate}}
                                </p>
                            </li>
                        </ul>
                    </div>
                </li>
                <li class="li_mode">
                    <div class="link_menu" (click)="toggleAppearanceDropdown()">
                        <div class="icon_txt">
                            <a  class="icon_link_cont"
                                routerLinkActive="active_link_icon">
                                <img src="../../../../assets/icons/sidebar_icons/icon-sidebar-appearance.svg" class="icon_link" alt="appearance">
                            </a>
                            <p class="caption_c1 color-txt-primary opacity_"> {{'navbar.appearance' | translate }} </p>
                        </div>
                        <div class="icon_chevron_menu">
                        </div>
                        <input  type="checkbox" (click)="checkMode()" [(ngModel)]="this.modeCheck" class="checkbox_menu">
                    </div>
                    <div class="dropdown_content">
                        <ul class="dropdown_list">
                            <li [ngClass]="{ 'lang_selected' : this.mode == 'dark' }">
                                <p  class="caption_c1 color-txt-primary font_regular"
                                    (click)="changeMode('dark')">
                                    {{ 'navbar.dark' | translate}}
                                </p>
                            </li>
                            <li [ngClass]="{ 'lang_selected' : this.mode == 'light' }">
                                <p  class="caption_c1 color-txt-primary font_regular"
                                    (click)="changeMode('light')">
                                    {{ 'navbar.light' | translate}}
                                </p>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
        <div class="hr_cont">
            <div class="hr_"></div>
        </div>
        <div class="block_menu">
            <ul class="ul_">
                <li *ngFor="let nav of menuBlock3">
                    <!-- {{this.idUserType !== 6 || nav.txt!=='navbar.integrations' | json}} -->
                    <a  class="link_menu" *ngIf="this.idUserType !== 6 || nav.txt!=='navbar.integrations'"
                        [routerLink]="nav.route"
                        routerLinkActive="active_link">
                        <div class="icon_txt">
                            <a  class="icon_link_cont"
                                routerLinkActive="active_link_icon">
                                <img [src]="'../../../../assets/icons/sidebar_icons/icon-sidebar-'+nav.icon+'.svg'" class="icon_link">
                            </a>
                            <p class="caption_c1 color-txt-primary opacity_"> {{nav.txt | translate }} </p>
                        </div>
                    </a>
                </li>
            </ul>
        </div>
        <div class="hr_cont">
            <div class="hr_"></div>
        </div>
        <div class="block_user">
            <div class="profile_cont" (click)="goToAccount()">
                <div class="profile_">
                    <p class="color_primary_400 font_medium caption_c2">{{profile}} </p>
                </div>
                <div class="profile_txt">
                    <p  class="opacity_ font_medium caption_c1 color-txt-primary">{{userName}}</p>
                    <p  class="opacity_ font_regular caption_c3 color_primary_300">{{'navbar.myaccount' | translate}}</p>
                </div>
            </div>
            <div class="logout_cont">
                <img (click)="logout()" src="../../../../assets/icons/sidebar_icons/icon-sidebar-logout.svg">
            </div>
        </div>
        <p class="opacity_ text_align_center caption_c3 font_regular color_primary_300">Powered by <strong class="caption_c3 font_medium color-txt-primary">Vivipost</strong> v2.0.0</p>
    </div>
</div>
