// * Angular
import { Component, Input } from '@angular/core';
// * Models
import { CardData } from 'src/app/core/models';
import { DataService } from 'src/app/core/services/data.service';

@Component({
  selector: 'cards-overview',
  templateUrl: './cards-overview.component.html',
  styleUrls: ['./cards-overview.component.scss']
})
export class CardsOverviewComponent {
 // * Cards Data
 @Input() dataCard: CardData[] = [];
 @Input() footer: boolean;
 // * Arrows
 getArrow(percent:number):string {
   if(percent < 0) {
     return 'danger'
   } else if(percent >= 0) {
     return 'success'
   }
   return '';
 }

constructor( public dataService : DataService ) { }

 // * LocalStorage set IDS
 private storeCardsIds: string | null = null;

 // * Get Type of data
 getType(data:any):boolean {
   if(isNaN(data)) {
     return true
   } else {
     return false
   }
 }

}
