import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ConfirmChangeStatusUserVivipostComponent } from 'src/app/modules/vivipost/components/dialogs/confirm-change-status-user-vivipost/confirm-change-status-user-vivipost.component';

@Component({
	selector: 'app-toggle-dashboard',
	templateUrl: './toggle-dashboard.component.html',
	styleUrls: ['./toggle-dashboard.component.scss']
})
export class ToggleDashboardComponent implements OnInit, ICellRendererAngularComp {
	@Output() toggleChange = new EventEmitter<boolean>();
	data: any;
	constructor(private matdialog : MatDialog) { }
	agInit(params: ICellRendererParams): void {
		console.log(params);
		this.data = params.data;
		if (this.data.enable_token === 1) {
			this.toggleChange.emit(true);
		}
	}
	refresh(params: ICellRendererParams) {
		return false;
	}
	ngOnInit(): void {
	}
    toggleChanged(event: any) {
        this.toggleChange.emit(event.target.checked);
		this.openConfirmChangeStatus();
    }
	openConfirmChangeStatus() {
		const dialogRef = this.matdialog.open(ConfirmChangeStatusUserVivipostComponent, {
			width: '361px',
			height: '231px',
			data: this.data,
			disableClose:true
		});

		dialogRef.afterClosed().subscribe((result) => {
			if(result.changes === false){
			}
			// if (this.users !== undefined) {
			// 	// const newData = this.users.slice();
			// 	// newData[result].status = 'INACTIVE';
			// 	// this.users = newData;
			// }
		});
	}
}
