<form class="containerFormCreatePassword" [formGroup]="formularioNewPassword">
	<app-input-dashboard class="inputPassword"
		[inputValue]="formularioNewPassword.get('actualPassword').value"
		(inputValueChange)="formularioNewPassword.get('actualPassword').setValue($event)"
		[showIcon]="false"
		[showPasswordIcon]="true"
		[header]="'accountSettingsPage.perfil.password.form.actual-password' | translate"
		[showError]="errorMessage ? true : false"
		[inputType]="showPasswordNewPassword ? 'text' : 'password'"
		[error]="errorMessage">
	</app-input-dashboard>
	<app-input-dashboard  class="inputPassword"
		[inputValue]="formularioNewPassword.get('newPassword').value"
		(inputValueChange)="formularioNewPassword.get('newPassword').setValue($event)"
		[showIcon]="false"
		[showPasswordIcon]="true"
		[header]="'accountSettingsPage.perfil.password.form.new-password' | translate"
		[showError]="errorMessage ? true : false"
		[inputType]="showPasswordNewPassword ? 'text' : 'password'"
		[error]="errorMessage">
	</app-input-dashboard>
	<div class="boxValidations">
		<span class="span-boxValidationsTitle">
			{{ 'accountSettingsPage.perfil.password.form.boxValidation.title' | translate}}
		</span>
		<div class="validation1">
			<div class="circle">
				<svg *ngIf="hasUpperCase === true" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
					<g clip-path="url(#clip0_2456_12277)">
					<path d="M4.99967 8.00016L6.99967 10.0002L10.9997 6.00016M14.6663 8.00016C14.6663 11.6821 11.6816 14.6668 7.99967 14.6668C4.31778 14.6668 1.33301 11.6821 1.33301 8.00016C1.33301 4.31826 4.31778 1.3335 7.99967 1.3335C11.6816 1.3335 14.6663 4.31826 14.6663 8.00016Z" stroke="#079455" stroke-linecap="round" stroke-linejoin="round"/>
					</g>
					<defs>
					<clipPath id="clip0_2456_12277">
					<rect width="16" height="16" fill="white"/>
					</clipPath>
					</defs>
				</svg>
				<svg *ngIf="hasUpperCase === null" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
					<g clip-path="url(#clip0_2456_12207)">
					<path d="M7.99967 14.6668C11.6816 14.6668 14.6663 11.6821 14.6663 8.00016C14.6663 4.31826 11.6816 1.3335 7.99967 1.3335C4.31778 1.3335 1.33301 4.31826 1.33301 8.00016C1.33301 11.6821 4.31778 14.6668 7.99967 14.6668Z" stroke="#D0D5DD" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="3 3"/>
					</g>
					<defs>
					<clipPath id="clip0_2456_12207">
					<rect width="16" height="16" fill="white"/>
					</clipPath>
					</defs>
				</svg>
				<svg *ngIf="hasUpperCase === false" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
					<g clip-path="url(#clip0_1109_31143)">
					<path d="M7.99967 14.6667C11.6816 14.6667 14.6663 11.6819 14.6663 8.00001C14.6663 4.31811 11.6816 1.33334 7.99967 1.33334C4.31778 1.33334 1.33301 4.31811 1.33301 8.00001C1.33301 11.6819 4.31778 14.6667 7.99967 14.6667Z" stroke="#FDA29B" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="3 3"/>
					</g>
					<defs>
					<clipPath id="clip0_1109_31143">
					<rect width="16" height="16" fill="white"/>
					</clipPath>
					</defs>
				</svg>
			</div>
			<div class="text" *ngIf="hasUpperCase === null || hasUpperCase === true">
				{{ 'accountSettingsPage.perfil.password.form.boxValidation.validation1' | translate}}
			</div>
			<div class="text-error" *ngIf="hasUpperCase === false">
				{{ 'accountSettingsPage.perfil.password.form.boxValidation.validation1' | translate }}
			</div>
		</div>
		<div class="validation2">
			<div class="circle">
				<svg *ngIf="hasNumber === true" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
					<g clip-path="url(#clip0_2456_12277)">
					<path d="M4.99967 8.00016L6.99967 10.0002L10.9997 6.00016M14.6663 8.00016C14.6663 11.6821 11.6816 14.6668 7.99967 14.6668C4.31778 14.6668 1.33301 11.6821 1.33301 8.00016C1.33301 4.31826 4.31778 1.3335 7.99967 1.3335C11.6816 1.3335 14.6663 4.31826 14.6663 8.00016Z" stroke="#079455" stroke-linecap="round" stroke-linejoin="round"/>
					</g>
					<defs>
					<clipPath id="clip0_2456_12277">
					<rect width="16" height="16" fill="white"/>
					</clipPath>
					</defs>
				</svg>
				<svg *ngIf="hasNumber === null" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
					<g clip-path="url(#clip0_2456_12207)">
					<path d="M7.99967 14.6668C11.6816 14.6668 14.6663 11.6821 14.6663 8.00016C14.6663 4.31826 11.6816 1.3335 7.99967 1.3335C4.31778 1.3335 1.33301 4.31826 1.33301 8.00016C1.33301 11.6821 4.31778 14.6668 7.99967 14.6668Z" stroke="#D0D5DD" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="3 3"/>
					</g>
					<defs>
					<clipPath id="clip0_2456_12207">
					<rect width="16" height="16" fill="white"/>
					</clipPath>
					</defs>
				</svg>
				<svg *ngIf="hasNumber === false" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
					<g clip-path="url(#clip0_1109_31143)">
					<path d="M7.99967 14.6667C11.6816 14.6667 14.6663 11.6819 14.6663 8.00001C14.6663 4.31811 11.6816 1.33334 7.99967 1.33334C4.31778 1.33334 1.33301 4.31811 1.33301 8.00001C1.33301 11.6819 4.31778 14.6667 7.99967 14.6667Z" stroke="#FDA29B" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="3 3"/>
					</g>
					<defs>
					<clipPath id="clip0_1109_31143">
					<rect width="16" height="16" fill="white"/>
					</clipPath>
					</defs>
				</svg>
			</div>
			<div class="text" *ngIf="hasNumber === null || hasNumber === true">
				{{ 'accountSettingsPage.perfil.password.form.boxValidation.validation2' | translate}}
			</div>
			<div class="text-error" *ngIf="hasNumber === false">
				{{ 'accountSettingsPage.perfil.password.form.boxValidation.validation2' | translate}}
			</div>
		</div>
		<div class="validation3">
			<div class="circle">
				<svg *ngIf="hasSpecialCharacter === true" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
					<g clip-path="url(#clip0_2456_12277)">
					<path d="M4.99967 8.00016L6.99967 10.0002L10.9997 6.00016M14.6663 8.00016C14.6663 11.6821 11.6816 14.6668 7.99967 14.6668C4.31778 14.6668 1.33301 11.6821 1.33301 8.00016C1.33301 4.31826 4.31778 1.3335 7.99967 1.3335C11.6816 1.3335 14.6663 4.31826 14.6663 8.00016Z" stroke="#079455" stroke-linecap="round" stroke-linejoin="round"/>
					</g>
					<defs>
					<clipPath id="clip0_2456_12277">
					<rect width="16" height="16" fill="white"/>
					</clipPath>
					</defs>
				</svg>
				<svg *ngIf="hasSpecialCharacter === null" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
					<g clip-path="url(#clip0_2456_12207)">
					<path d="M7.99967 14.6668C11.6816 14.6668 14.6663 11.6821 14.6663 8.00016C14.6663 4.31826 11.6816 1.3335 7.99967 1.3335C4.31778 1.3335 1.33301 4.31826 1.33301 8.00016C1.33301 11.6821 4.31778 14.6668 7.99967 14.6668Z" stroke="#D0D5DD" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="3 3"/>
					</g>
					<defs>
					<clipPath id="clip0_2456_12207">
					<rect width="16" height="16" fill="white"/>
					</clipPath>
					</defs>
				</svg>
				<svg *ngIf="hasSpecialCharacter === false" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
					<g clip-path="url(#clip0_1109_31143)">
					<path d="M7.99967 14.6667C11.6816 14.6667 14.6663 11.6819 14.6663 8.00001C14.6663 4.31811 11.6816 1.33334 7.99967 1.33334C4.31778 1.33334 1.33301 4.31811 1.33301 8.00001C1.33301 11.6819 4.31778 14.6667 7.99967 14.6667Z" stroke="#FDA29B" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="3 3"/>
					</g>
					<defs>
					<clipPath id="clip0_1109_31143">
					<rect width="16" height="16" fill="white"/>
					</clipPath>
					</defs>
				</svg>
			</div>
			<div class="text" *ngIf="hasSpecialCharacter === null || hasSpecialCharacter === true">
				{{ 'accountSettingsPage.perfil.password.form.boxValidation.validation3' | translate}}
			</div>
			<div class="text-error" *ngIf="hasSpecialCharacter === false">
				{{ 'accountSettingsPage.perfil.password.form.boxValidation.validation3' | translate }}
			</div>
		</div>
		<div class="validation4">
			<div class="circle">
				<svg *ngIf="hasMinLength === true" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
					<g clip-path="url(#clip0_2456_12277)">
					<path d="M4.99967 8.00016L6.99967 10.0002L10.9997 6.00016M14.6663 8.00016C14.6663 11.6821 11.6816 14.6668 7.99967 14.6668C4.31778 14.6668 1.33301 11.6821 1.33301 8.00016C1.33301 4.31826 4.31778 1.3335 7.99967 1.3335C11.6816 1.3335 14.6663 4.31826 14.6663 8.00016Z" stroke="#079455" stroke-linecap="round" stroke-linejoin="round"/>
					</g>
					<defs>
					<clipPath id="clip0_2456_12277">
					<rect width="16" height="16" fill="white"/>
					</clipPath>
					</defs>
				</svg>
				<svg *ngIf="hasMinLength === null" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
					<g clip-path="url(#clip0_2456_12207)">
					<path d="M7.99967 14.6668C11.6816 14.6668 14.6663 11.6821 14.6663 8.00016C14.6663 4.31826 11.6816 1.3335 7.99967 1.3335C4.31778 1.3335 1.33301 4.31826 1.33301 8.00016C1.33301 11.6821 4.31778 14.6668 7.99967 14.6668Z" stroke="#D0D5DD" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="3 3"/>
					</g>
					<defs>
					<clipPath id="clip0_2456_12207">
					<rect width="16" height="16" fill="white"/>
					</clipPath>
					</defs>
				</svg>
				<svg *ngIf="hasMinLength === false" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
					<g clip-path="url(#clip0_1109_31143)">
					<path d="M7.99967 14.6667C11.6816 14.6667 14.6663 11.6819 14.6663 8.00001C14.6663 4.31811 11.6816 1.33334 7.99967 1.33334C4.31778 1.33334 1.33301 4.31811 1.33301 8.00001C1.33301 11.6819 4.31778 14.6667 7.99967 14.6667Z" stroke="#FDA29B" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="3 3"/>
					</g>
					<defs>
					<clipPath id="clip0_1109_31143">
					<rect width="16" height="16" fill="white"/>
					</clipPath>
					</defs>
				</svg>
			</div>
			<div class="text" *ngIf="hasMinLength === null || hasMinLength === true">
				{{ 'accountSettingsPage.perfil.password.form.boxValidation.validation4' | translate }}
			</div>
			<div class="text-error" *ngIf="hasMinLength === false">
				{{ 'accountSettingsPage.perfil.password.form.boxValidation.validation4' | translate }}
			</div>
		</div>
	</div>
	<app-input-dashboard class="inputPassword"
		[inputValue]="formularioNewPassword.get('confirmPassword').value"
		(inputValueChange)="formularioNewPassword.get('confirmPassword').setValue($event)"
		[showIcon]="false"
		[showPasswordIcon]="true"
		[header]="'accountSettingsPage.perfil.password.form.confirmPassword' | translate"
		[showError]="errorMessage ? true : false"
		[inputType]="showPasswordConfirm ? 'text' : 'password'"
		[error]="hasErrorInSamePassword ? 'Las contraseñas no coinciden, inténtalo de nuevo.' : errorMessage">
	</app-input-dashboard>

	<div style="width: 100%;justify-content: end; display: flex;">
		<app-button-dashboard (click)="updatePassword()" buttonClass="btn-md-primary" label="accountSettingsPage.perfil.password.form.btnUpdatePassword">
		</app-button-dashboard>
	</div>

</form>

<app-alert
	[showAlert]="this.cpSuccess"
	alertType="medium"
	position="bottom-right"
	imgType="success"
	textAlert="Se ha cambiado la contraseña"
	subtitleAlert="Ahora podras ingresar a la cuenta con la nueva contraseña">
</app-alert>
