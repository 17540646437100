import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Data, ReturnMenu } from 'src/app/core/models';
import { EncryptDataService } from 'src/app/core/security/encypt-data.service';
import { DataService } from 'src/app/core/services/data.service';
import { UserService } from 'src/app/core/services/user.service';
import { UserAuthService } from 'src/app/modules/auth/services/user-auth.service';
@Component({
  selector: 'sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnChanges {
	mode: string = localStorage.getItem('mode') || 'light'; // default'light'
	modeSelected: string;
	supportLanguages = ['es', 'en'];
	username: string;
	role: string;
	sideMenu: ReturnMenu[];
	sideMenu2:ReturnMenu[]
	language: string;
	page;
	data;
	dropdownTranslateOpen = false;
	dropdownAppearanceOpen = false;
	translateCheckOpen = false;
	mostrarTexto = true;
	companyName:string;
	idUserType:number;
	userType:string;
	openSideBar:boolean = false;
	sidebarTransition: boolean = false;
	openTranslateSideBar:boolean = false;
	openAparienciaSideBar:boolean = false;
	systemVersion:string;
	local;
	constructor(
		private userService: UserAuthService,
		private dataService :DataService,
		public translate: TranslateService,
		private securityLocalStorage: EncryptDataService,
		private router      :Router) {
			this.local = JSON.parse(this.securityLocalStorage.decryptData(localStorage.getItem('data')))
			// const data = localStorage.getItem('data');
			// const userData:Data = JSON.parse(data);
			this.username = this.local.data_company.first_name;
			this.language = localStorage.getItem('language').toLowerCase();
			this.mode = localStorage.getItem('mode');
			this.sideMenu2 = [
				{
				txt:'navbar.version',
				route:'version',
				icon:'version'
				},
				{
				txt:'navbar.integrations',
				route:'integrations',
				icon:'integrations'
				},
			]
			this.translate.onLangChange.subscribe((event:LangChangeEvent)=>{
				this.translate.use(event.lang);
				this.sideMenu2 = [
					{
					txt:'navbar.version',
					route:'version',
					icon:'version'
					},
					{
					txt:'navbar.integrations',
					route:'integrations',
					icon:'integrations'
					},
				]
			})
	}
	ngOnInit(): void {
		this.data = JSON.parse(localStorage.getItem('data'));
		this.companyName = this.data.data_company.company_name;

		this.idUserType = parseInt(localStorage.getItem('user_type'))
		this.translate.onLangChange.subscribe((event: LangChangeEvent)=> {
			this.language = event.lang
		})
		this.role = this.dataService.role;
		this.sideMenu = this.data.data_menu.return_menu;
		this.userType = this.data.result.user_type;
		this.mode = localStorage.getItem('mode');
		this.language = localStorage.getItem('language').toLowerCase();
		this.page = this.router.url.split("/")[2];
		this.userService.getSystemVersion().then((res:any)=>{
			this.systemVersion = res.version;
			if(res.mensaje_return.ERROS_MENSAGGE ==='SUCCESS'){
				this.systemVersion = res.version;
			}
		});
	}
	ngOnChanges(changes: SimpleChanges): void {
		this.language = localStorage.getItem('language');
		this.page = this.router.url.split("/")[2];
	}
	langSelected:string;
	selectLang(lang: string) {
		this.translate.use(lang);
		this.language = lang
		localStorage.setItem('language',lang);
		if (lang === 'en') {
			this.langSelected = 'en';
			localStorage.setItem('language',this.langSelected);
		} else {
			this.langSelected = 'es';
			localStorage.setItem('language',this.langSelected);
		}
	}
	changeMode(mode: string): void {
		this.mode = mode;
		document.body.classList.toggle('dark-mode', this.mode === 'dark');
		localStorage.setItem('mode', mode);
		this.modeSelected = this.dataService.mode;
	}
	// logout() {
	// 	this.userService.logout();
	// }
	selectPage(){
		this.page = this.router.url;
		localStorage.removeItem('searchOrderNotification');
		localStorage.removeItem('courier_id');
		localStorage.setItem('id_locker',null)
	}
	toggleDropdown(dropdown: string) {
		if (dropdown === 'translate') {
			this.dropdownTranslateOpen = !this.dropdownTranslateOpen;
			this.dropdownAppearanceOpen = false; // Cerrar el otro dropdown si está abierto
		} else if (dropdown === 'appearance') {
			this.dropdownAppearanceOpen = !this.dropdownAppearanceOpen;
			this.dropdownTranslateOpen = false; // Cerrar el otro dropdown si está abierto
		}
	}
	toggleCheckbox(checkbox: string) {
		if (checkbox === 'appearance') {
			this.translateCheckOpen = !this.translateCheckOpen;
		}
	}
	changePage(event){
		this.page = event;
		if(this.idUserType === 6){
			this.router.navigateByUrl('warehouse/'+event);
		}
		else if(this.idUserType === 7){
			this.router.navigateByUrl('vivipost/'+event);
		}
		else if(this.idUserType === 8){
			this.router.navigateByUrl('superadmin/'+event);
		}
		else if(this.idUserType === 9){
			this.router.navigateByUrl('superadmin/'+event);
		}
		else if(this.idUserType === 10){
			this.router.navigateByUrl('carriers/'+event);
		}
	}
	hideSideBar(){
		this.openSideBar = !this.openSideBar;
		this.sidebarTransition = !this.sidebarTransition;
		this.mostrarTexto = false;
		setTimeout( ()=>{
			this.mostrarTexto = true;
		},500)
	}
	openTranslate(){
		this.openTranslateSideBar = !this.openTranslateSideBar;
		this.openAparienciaSideBar = false;
	}
	openApariencia(){
		this.openAparienciaSideBar = !this.openAparienciaSideBar;
		this.openTranslateSideBar = false;
	}
	changeLanguage(event){
		localStorage.setItem('language',event)
		this.translate.use(event);
	}
}

