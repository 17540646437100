<div>
	<div class="Status_{{this.statusID}}">
		<!-- {{statusName}} -->
		<!-- {{data | json}} -->
		{{'oxxoToOxxo.status.'+this.statusID|translate}}
	</div>
	<!-- <div class="Status_expired" *ngIf="this.statusCodePackage === 32">
		{{statusName}}
	</div> -->
</div>
