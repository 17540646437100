import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
	selector: 'app-status-topic-audit',
	templateUrl: './status-topic-audit.component.html',
	styleUrls: ['./status-topic-audit.component.scss']
})
export class StatusTopicAuditComponent implements OnInit,ICellRendererAngularComp {
	statusTopicAudit:number;
	constructor() { }
	ngOnInit(): void {
	}
	agInit(params: ICellRendererParams): void {
		this.statusTopicAudit = params.data.id_topic_audit;
	}
	refresh(params: ICellRendererParams) {
		return false;
	}
}
