// * Angular
import { Component, Input } from '@angular/core';
// * Models
import { CardData } from 'src/app/core/models';
import { DataService } from 'src/app/core/services/data.service';

@Component({
  selector: 'card-users',
  templateUrl: './card-users.component.html',
  styleUrls: ['./card-users.component.scss']
})
export class CardUsersComponent  {
  // * Cards Data
  @Input() dataCard: CardData[] = [];
  // * Arrows
  getArrow(percent:number):string {
    if(percent < 0) {
      return 'danger'
    } else if(percent >= 0) {
      return 'success'
    }
    return '';
  }
 
 constructor( public dataService : DataService ) { }
 
  // * LocalStorage set IDS
  private storeCardsIds: string | null = null;
 
  // * Get Type of data
  getType(data:any):boolean {
    if(isNaN(data)) {
      return true
    } else {
      return false
    }
  }
 
 }
 