<!-- ? CONTAINER MAIN -->
<div class="cont_main">
    <!-- ? BLOCK 1 | INPUT | CALENDAR | FILTER | EXPORT  -->
    <div class="block_header">
        <!-- ? INPUT CONTAINER-->
        <div class="input_main_cont">
            <input
                [(ngModel)]="filterText"
                type="text"
                class="input_main"
                (keyup)="onFilterTextChange($event)"
                placeholder="{{'search.users' | translate}}">
            <!-- ICON SEARCH -->
            <img class="icon_sm" src="../../../../../../assets/icons/icon-search.svg">

        </div>

        <!-- ? EXPORT -->
        <div class="calendar_filter_export">

            <!-- ? BTN EXPORT -->
			<button-export (click)="onExport()"></button-export>


        </div>

    </div>

    <!-- ? BLOCK 2 | TABLE -->
    <div class="block_table">
        <ag-grid-angular #agGrid
            class="ag-theme-alpine"
            [gridOptions]="this.gridOptions"
            [columnDefs]="this.columnDefs"
            [rowData]="this.localadmin"
            [quickFilterText]="this.filterText"
            (gridReady)="onGridReady($event)">
        </ag-grid-angular>
    </div>

</div>
