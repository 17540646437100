import { Component} from '@angular/core';
// import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-estatus-oxxo-to-oxxo',
  templateUrl: './estatus-oxxo-to-oxxo.component.html',
  styleUrls: ['./estatus-oxxo-to-oxxo.component.scss']
})
export class EstatusOxxoToOxxoComponent {
	statusID:number;
	// statusCodePackage:number;
	statusName:string="";
	data:any;
	constructor() { }
	agInit(params: ICellRendererParams): void {
		this.statusID = params.data.data_package_service.id_status || params.data.package_status_id ;
		this.statusName=params.data.data_package_service.nombre_status;
		this.data=params.data.data_package_service;
		// this.statusCodePackage = params.data.id_status_code_package;
		// if(this.statusCodePackage===32){
		// }
	}
	// refresh(params: ICellRendererParams) {
	// 	return false;
	// }
	// ngOnInit(): void {
	// }
}
