import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-estatus-order',
	templateUrl: './estatus-order.component.html',
	styleUrls: ['./estatus-order.component.scss'],
})
export class EstatusOrderComponent implements OnInit {
	@Input('orderData') orderData;
	estatus;
	status_package: number;
	id_status_code_package: number;
	extend_package: number;
	dataSendUpdate;
	parametros;
	statusID;
	statusCodePackage:number;

	activity_topic;
	public display: boolean = false;

	ngOnInit(): void {
		this.statusID = this.orderData.status_package || this.orderData.id_status_service ;
		this.statusCodePackage = this.orderData.id_status_code_package;

		this.id_status_code_package = this.orderData.id_status_code_package;
		this.status_package = this.orderData.status_package;
		this.estatus = this.orderData.status;
		this.extend_package = this.orderData.extended_package;
	}
	constructor() {}
}
