import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-estatus-store-package',
  templateUrl: './estatus-store-package.component.html',
  styleUrls: ['./estatus-store-package.component.scss']
})
export class EstatusStorePackageComponent implements OnInit, ICellRendererAngularComp {
	id_status_package;
	constructor() { }
	ngOnInit() {
	}
	agInit(params: ICellRendererParams): void {
		this.id_status_package = params.data.package_status_id;
	}
	refresh(params: ICellRendererParams) {
		return false;
	}
}
