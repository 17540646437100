
<div class="resumen-to-create-route-container">
	<div class="header-container">
		<div class="title-box">
			<div class="title">
				{{ 'createRoute.dialog.title' | translate }}
			</div>
			<app-close-button (click)="closeDialog()"/>
		</div>
		<div class="subtitle">
			{{ 'createRoute.dialog.subtitle-1' | translate }}
			<strong>
				{{this.rowData.length}} {{ 'createRoute.dialog.subtitle-2' | translate }}
			</strong>
			{{ 'createRoute.dialog.subtitle-3' | translate }}
		</div>
	</div>
	<ag-grid-angular
		style="width: 100%; height: 482px;"
		#agGrid
		class="ag-theme-alpine"
		[gridOptions]="this.gridOptions"
		[rowData]="this.rowData"
		[columnDefs]="this.columnDefs"
		(gridReady)="onGridReady($event)"
		paginationAutoPageSize="true"
		paginationPageSize="10"
		[animateRows]="true">
	</ag-grid-angular>
</div>
<div class="footer-container">
	<div class="button-container">
		<app-button-dashboard
			(click)="closeDialog()"
			[disabled]="false"
			buttonClass="btn-md-secondary"
			label="createRoute.dialog.buttons.cancel" >
		</app-button-dashboard>
		<app-button-dashboard
			[showLoading]="this.showLoading"
			(click)="createRoute()"
			[disabled]="false"
			buttonClass="btn-md-primary"
			label="createRoute.dialog.buttons.create_route" >
		</app-button-dashboard>
	</div>
</div>

