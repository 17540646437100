import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { CellClickedEvent, ColDef, GridOptions } from 'ag-grid-community';
import { EstatusTableOrderAdminComponent } from '../estatus-table-order-admin/estatus-table-order-admin.component';
import * as XLSX from 'xlsx';
import { DateTextComponent } from '../date-text/date-text.component';
import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-return-lotes',
	templateUrl: './return-lotes.component.html',
	styleUrls: ['./return-lotes.component.scss'],
})
export class ReturnLotesComponent implements OnInit {
	rangeDates: Date[];
	InitialDate = new Date();
	FinalDate = new Date();
	public defaultColDef: ColDef = {
		flex: 1,
		minWidth: 70,
		resizable: false,
		sortable: true,
		filter: true,
	};
	public columnDefs: ColDef[];

	tabOrders = true;
	tabLotes = false;
	tabClientes = false;

	filterText: string = '';
	gridOptions: GridOptions;
	rowData: any[];
	dataTemporal = [];
	public filteredData: any[] = [];

	constructor(private translate: TranslateService, private http: HttpClient) {
		this.gridOptions = {
			columnDefs: [
				{
					headerName: this.translate.instant(
						'table-returns-lotes.loteID'
					),
					field: 'loteID',
					cellStyle: {
						'font-family': 'Inter',
						'text-align': 'center',
					},
					cellRenderer: this.centeredCellRenderer,
				},
				{
					headerName: this.translate.instant(
						'table-returns-lotes.origen'
					),
					field: 'origen',
					cellStyle: {
						'font-family': 'Inter',
						'text-align': 'center',
					},
					cellRenderer: this.centeredCellRenderer,
				},
				{
					headerName: this.translate.instant(
						'table-returns-lotes.orderNumber'
					),
					field: 'orderNumber',
					cellStyle: {
						'font-family': 'Inter',
						'text-align': 'center',
					},
					cellRenderer: this.centeredCellRenderer,
				},
				{
					headerName: this.translate.instant(
						'table-returns-lotes.weigthLote'
					),
					field: 'weigthLote',
					cellStyle: {
						'font-family': 'Inter',
						'text-align': 'center',
					},
					cellRenderer: this.centeredCellRenderer,
				},
				{
					headerName: this.translate.instant(
						'table-returns-lotes.priceToSend'
					),
					field: 'priceToSend',
					cellStyle: {
						'font-family': 'Inter',
						'text-align': 'center',
					},
					cellRenderer: this.centeredCellRenderer,
				},
				{
					headerName: this.translate.instant(
						'table-returns-lotes.status'
					),
					field: 'status',
					cellStyle: {
						'font-family': 'Inter',
						'text-align': 'center',
						'justify-content': 'center',
						'align-items': 'center',
						display: 'flex',
					},
					cellRenderer: EstatusTableOrderAdminComponent,
				},
				{
					headerName: this.translate.instant(
						'table-returns-lotes.registerDate'
					),
					field: 'registerDate',
					cellStyle: {
						'font-family': 'Inter',
						'text-align': 'center',
					},
					cellRenderer: DateTextComponent,
				},
			],
			animateRows: true,
			defaultColDef: {
				flex: 1,
			},
			pagination: true,
			paginationPageSize: 10,
		}
	}

	ngOnInit() {
		this.http
			.get<any[]>('../../../../../assets/dummy/return-lote-info.json')
			.subscribe((data) => {
				this.rowData = data;
			});
	}

	addEventRangeDates() {
		this.InitialDate = this.rangeDates[0];
		if (this.rangeDates[1] !== null) {
			this.FinalDate = this.rangeDates[1];
		}
	}

	onCellClicked(e: CellClickedEvent): void {}

	centeredCellRenderer(params) {
		const cellValue = params.value;
		const cellDiv = document.createElement('div');
		cellDiv.style.display = 'flex';
		cellDiv.style.alignItems = 'center';
		cellDiv.style.justifyContent = 'center';
		cellDiv.style.height = '100%';
		cellDiv.innerText = cellValue;
		return cellDiv;
	}

	onFilterTextChange(event) {
		this.filterText = event.target.value;
		event.api.setQuickFilter(this.filterText);
		let api = event.api;
		let temporal = [];
		event.api.forEachNodeAfterFilter((node) => {
			temporal.push(node.data);
		});
		this.filteredData = temporal;
	}

	onExport() {
		const fileName = 'ReturnLotes.xlsx';
		// Configuración para exportar en formato Excel
		const excelParams = {
			fileName: fileName,
			sheetName: 'ReturnLotes',
			columnWidths: [30, 20, 20, 20], // Ancho de las columnas en caracteres
		};
		// Obtener los datos de la tabla
		if (this.filteredData.length > 0) {
			const Data = this.filteredData || [];
			const excelData = Data.map((row) => {
				return row;
			});
			// Crear el libro de Excel y agregar la hoja con los datos
			const wb = XLSX.utils.book_new();
			const ws = XLSX.utils.json_to_sheet(excelData);
			XLSX.utils.book_append_sheet(wb, ws, excelParams.sheetName);
			//   // Descargar el archivo de Excel
			XLSX.writeFile(wb, fileName);
		} else if (this.rowData.length > 0 && this.filteredData.length === 0) {
			const Data = this.rowData || [];
			const excelData = Data.map((row) => {
				return row;
			});
			//   // Crear el libro de Excel y agregar la hoja con los datos
			const wb = XLSX.utils.book_new();
			const ws = XLSX.utils.json_to_sheet(excelData);
			XLSX.utils.book_append_sheet(wb, ws, excelParams.sheetName);
			//   // Descargar el archivo de Excel
			XLSX.writeFile(wb, fileName);
		}
	}
}

