<div class="wrap-confirm">
    <h5 class="title-confirm">¿Desea la entrega personalmente?</h5>

    <div class="wrap-date-time">
        <!-- <mat-form-field appearance="fill">
          <mat-label>Inherited calendar color</mat-label>
          <input matInput [matDatepicker]="picker1" (dateChange)="changeDateNotify($event)">
          <mat-hint>MM/DD/YYYY</mat-hint>
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>
        <input type="time" id="startTime" (change)="changeTimeNotify($event)" /> -->
        <!-- <ng-container *ngFor="let column of displayedColumns; let i = index" matColumnDef="{{column}}"> -->

        <input id="dateTimePersonally" type="datetime-local" name="partydate" (change)="changeDateNotify($event)"/>
      </div>

    <div class="wrap-btns">
        <button class="btn-secondary-gbl" (click)="closeModal()">Cancelar</button>
        <button class="btn-primary-gbl" (click)="savePersonalDelivery()">Aceptar</button>
    </div>
</div>