// * Angular
import {
	Component,
	Input,
	OnChanges,
	OnInit,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
// * Services
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ClientService } from 'src/app/core/http/client.service';
// * Libraries
import {
	ChartComponent,
	ApexAxisChartSeries,
	ApexResponsive,
	ApexChart,
	ApexXAxis,
	ApexDataLabels,
	ApexStroke,
	ApexMarkers,
	ApexYAxis,
	ApexGrid,
	ApexTitleSubtitle,
	ApexLegend,
	ApexFill,
	ApexTooltip,
} from 'ng-apexcharts';
// * Interfaces
import { clientsAdminResponse } from 'src/app/core/models/clients/clients.interface';

@Component({
	selector: 'clients-metrics',
	templateUrl: './clients-metrics.component.html',
	styleUrls: ['./clients-metrics.component.scss'],
})
export class ClientsMetricsComponent implements OnInit, OnChanges {
	@ViewChild('chart') chart: ChartComponent;
	@Input() metricData: [] = [];
	@Input() dataDeliver: number[] = [];
	@Input() dataRecolected: number[] = [];
	@Input() dataExpired: number[] = [];
	public chartOptions: Partial<ChartOptions>;

	arrayDeliver = [];
	arrayRecolected = [];

	constructor(
		private translate: TranslateService,
		private clientService: ClientService
	) {
		translate.use(localStorage.getItem('language'));
		this.loadDataChart();
	}

	ngOnInit(): void {
		this.translate.use(localStorage.getItem('language'));
		this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
			this.translate.use(event.lang);
			this.loadDataChart();
		});
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.loadDataChart();
	}
	private loadDataChart() {
		this.chartOptions = {
			series: [
				{
					name: this.translate.instant(
						'clients.metricsClients.uniqueClients'
					),
					data: this.dataDeliver,
				},
				{
					name: this.translate.instant(
						'clients.metricsClients.recurringClients'
					),
					data: this.dataRecolected,
				},
			],
			chart: {
				stacked: true,
				type: 'line',
				height: 375,
				width: '100%',
				toolbar: {
					show: true,
					tools: {
						download: `
							<div class="icon_chevron">
								<p class="caption_c2 font_medium color_primary">
									${this.translate.instant('buttons.export')}
								</p>
								<img src="../../../../../../assets/icons/icon-chevron-down.svg">

							</div>
						`,
						pan: false,
						reset: false,
						zoom: false,
						zoomin: false,
						zoomout: false,
						selection: false,
					},
				},
				selection: {
					enabled: true,
				},
				dropShadow: {
					enabled: false,
					enabledOnSeries: undefined,
					top: 0,
					left: 0,
					blur: 3,
					color: '#000',
					opacity: 0.35,
				},
			},
			dataLabels: {
				enabled: false,
			},
			stroke: {
				width: 2,
				colors: ['#0082cb', '#18345d'],
				curve: 'straight',
				dashArray: [0, 0, 0],
				show: true,
			},
			markers: {
				size: 0,
				hover: {
					sizeOffset: 6,
				},
				colors: ['#0082cb', '#18345d'],
			},
			yaxis: {
				axisBorder: {
					color: '#efefef',
					show: true,
				},
				labels: {
					style: {
						colors: '#a6a6a6',
						fontSize: '12px',
						fontFamily: 'var(--primary-font)',
						fontWeight: 500,
					},
				},
			},
			xaxis: {
				labels: {
					trim: false,
					style: {
						colors: '#a6a6a6',
						fontSize: '12px',
						fontFamily: 'var(--primary-font)',
						fontWeight: 500,
					},
				},
				categories: [
					this.translate.instant(
						'clients.metricsClients.month.January'
					),
					this.translate.instant(
						'clients.metricsClients.month.February'
					),
					this.translate.instant(
						'clients.metricsClients.month.March'
					),
					this.translate.instant(
						'clients.metricsClients.month.April'
					),
					this.translate.instant('clients.metricsClients.month.May'),
					this.translate.instant('clients.metricsClients.month.June'),
					this.translate.instant('clients.metricsClients.month.July'),
					this.translate.instant(
						'clients.metricsClients.month.August'
					),
					this.translate.instant(
						'clients.metricsClients.month.September'
					),
					this.translate.instant(
						'clients.metricsClients.month.October'
					),
					this.translate.instant(
						'clients.metricsClients.month.November'
					),
					this.translate.instant(
						'clients.metricsClients.month.December'
					),
				],
			},
			tooltip: {
				y: [
					{
						title: {
							formatter: function (val) {
								return val + ' (mins)';
							},
						},
					},
				],
				marker: {
					fillColors: ['#0082cb', '#18345d'],
				},
			},
			grid: {
				borderColor: '#e5e5e5',
				show: true,
			},
			fill: {
				opacity: 1,
				colors: ['#0082cb', '#18345d'],
			},
			legend: {
				position: 'bottom',
				fontFamily: 'Inter',
				offsetY: 0,
				labels: {
					colors: '#212121',
				},
				markers: {
					radius: 2,
					fillColors: ['#0082cb', '#18345d'], // Colores personalizados para los marcadores de la leyenda
				},
			},
		};
	}
}

export type ChartOptions = {
	series: ApexAxisChartSeries;
	chart: ApexChart;
	xaxis: ApexXAxis;
	stroke: ApexStroke;
	dataLabels: ApexDataLabels;
	markers: ApexMarkers;
	yaxis: ApexYAxis;
	grid: ApexGrid;
	legend: ApexLegend;
	title: ApexTitleSubtitle;
	fill: ApexFill;
	tooltip: ApexTooltip;
	responsive: ApexResponsive;
};
