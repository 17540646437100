<label class="toggle">
    <input type="checkbox" [checked]="data.id_status === 1" (change)="toggleChanged()">
    <span class="slider"></span>
</label>

<!-- <app-alert
	[showAlert]="this.disabledStore"
	alertType="large"
	position="bottom-right"
	imgType="success"
	[textAlert]="'Se ha deshabilitado la tienda'"
	subtitleAlert="los cambios se visualizaran en la tabla">
</app-alert>

<app-alert
	[showAlert]="this.abledStore"
	alertType="large"
	position="bottom-right"
	imgType="success"
	[textAlert]="'Se ha habilitado la tienda'"
	subtitleAlert="los cambios se visualizaran en la tabla">
</app-alert> -->
