import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { UpdateStoreStatusService } from 'src/app/core/http/update-store-status.service';
import { StoresService } from 'src/app/core/services/stores.service';

@Component({
	selector: 'app-toggle-store',
	templateUrl: './toggle-store.component.html',
	styleUrls: ['./toggle-store.component.scss']
})
export class ToggleStoreComponent implements OnInit, ICellRendererAngularComp  {
	data: any;
	disabledStore:boolean=false;
	abledStore:boolean=false;
	@Output() toggleChange = new EventEmitter<boolean>();
	constructor(private storeService: StoresService,private alert: UpdateStoreStatusService) { }
	agInit(params: ICellRendererParams): void {;
		this.data = params.data;
	}
	refresh(params: ICellRendererParams) {
		return false;
	}
	ngOnInit() {
	}
	toggleChanged() {
		console.log(this.data);
		if(this.data.id_status ===1){
			let obj={
				"id_status": 2,
			}
			this.storeService.updateStore(obj,this.data.id_store).then((res:any)=>{
				console.log(res.body.mensaje_return.CODE);
				console.log(res.body);
				if(res.body.mensaje_return.CODE === 200){
					this.disabledStore = true;
					this.alert.updateStatusDisabledStore({
						disabledStore:true
					});
				}
				else{
					console.log(res.body);
					this.disabledStore = false;
					this.alert.updateStatusDisabledStore({
						disabledStore:false
					});
				}
			});
		}
		else{
			let obj={
				"id_status": 1,
			}
			this.storeService.updateStore(obj,this.data.id_store).then((res:any)=>{
				console.log(res.body);
				if(res.body.mensaje_return.CODE === 200){
					this.abledStore = true;
					this.alert.updateStatusabledStore({
						abledStore:true
					});

				}
				else{
					console.log(res.body);
					this.alert.updateStatusDisabledStore({
						abledStore:false
					});
				}
			});
		}

        // this.toggleChange.emit(event.target.checked);
		// this.openConfirmChangeStatus();
    }

}
