<form class="container-main" [formGroup]="this.formPersonal">
    <div class="container-header">
        <div class="form-account">
			<div class="groupBox">
				<div class="text">
					{{'side-bar-panel-control.account-page.name' | translate }}
				</div>
				<input type="text" formControlName="fname"  class="inputFormField" placeholder="{{fname}}" value="{{fname}}">
			</div>
			<div class="groupBox">
				<div class="text">
					{{'side-bar-panel-control.account-page.email' | translate }}
				</div>
				<input type="text" formControlName="email" class="inputFormField" placeholder="{{email}}" value="{{email}}">
			</div>
        </div>
		<div class="row" style="margin:48px 0px;">
			<div class="col-12">
				<hr style="margin:0px 0px">
			</div>
		</div>
		<div class="form-information-company">
			<div class="row">
				<div class="col-12">
					<div class="groupBox">
						<div class="text">
							{{'side-bar-panel-control.account-page.company' | translate }}
						</div>
						<input type="text" formControlName="company" disabled class="inputFormField" placeholder="{{company}} ">
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-12">
					<div class="groupBox">
						<div class="text">
							{{'side-bar-panel-control.account-page.type' | translate }}
						</div>
						<input type="text" formControlName="user_type" disabled class="inputFormField" placeholder="{{userType}} ">
					</div>
				</div>
			</div>
			<div class="row" style="gap: 48px;">
				<div class="col-12">
					<div class="groupBox">
						<div class="text">
							{{'side-bar-panel-control.account-page.ID' | translate }}
						</div>
						<input type="text" formControlName="id_user" disabled class="inputFormField" placeholder="{{id_user}} ">
					</div>
				</div>
				<div class="col-6">
					<div class="boxButtons">
						<app-button-icon (click)="saveInformation()" btnNombre="side-bar-panel-control.account-page.btn" btnType="primaryMedium" icon="icon-save">
						</app-button-icon>
					</div>
				</div>
			</div>
        </div>
    </div>
</form>

