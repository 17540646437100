import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/app/core/services/data.service';
import { Data } from '@angular/router';
import { AdminClientService } from 'src/app/core/http/admin-client.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-preferences-superadmin',
  templateUrl: './preferences-superadmin.component.html',
  styleUrls: ['./preferences-superadmin.component.scss']
})
export class PreferencesSuperadminComponent implements OnInit {
	mode: string = localStorage.getItem('mode') || 'light'; // default'light'
	supportLanguages = ['es', 'en'];
	spanSelect:any = localStorage.getItem('language')
	option:any = '';
	modeSelected: string;
	optionSelected:string;
	formPreferences:FormGroup;
	fname:string;
	sname:string;
	email:string;
	company:string;
	userType:string;
	id_user:number;
	lang:string;
	address:string;
	id_company:string;
	dark_mode:string;
	constructor (
		public dataService: DataService,
		private translate: TranslateService ,
		private formBuilder: FormBuilder,
		private adminService: AdminClientService,
		private toastr: ToastrService
	) {}
	get darkModeValue() {
		return this.formPreferences.get('dark_mode').value;
	}
	get languageValue() {
		return this.formPreferences.get('id_language').value;
	}
	ngOnInit(): void {
		const data 			= localStorage.getItem('data');
		const userData:Data = JSON.parse(data);
		this.lang 			= userData.result.acronym_language;
		this.email 			= userData.data_company.email;
		this.company 		= userData.data_company.company_name;
		this.id_user 		= userData.data_company.id_user;
		this.id_company 	= userData.data_company.id_company;
		this.fname 			= userData.data_company.first_name;
		this.sname 			= userData.data_company.surname;
		this.userType 		= userData.data_company.user_type;
		this.address 		= userData.data_company.address;
		this.dark_mode		= userData.result.dark_mode;
		this.formPreferences = this.formBuilder.group({
			dark_mode		: [this.dark_mode,	[Validators.required]],
			id_language		: [,	[Validators.required]]
		});
		this.supportLanguages;

		if(localStorage.getItem('language') === 'es') {
			this.option = 'en'
		} else {
			this.option = 'es'
		}
		const lang = localStorage.getItem('language');
		if(lang ==='es'){
			this.formPreferences.get('id_language').setValue(1)
		}
		else if(lang === 'en'){
			this.formPreferences.get('id_language').setValue(2)
		}
		if (lang) {
			// If a value exists in localStorage, set the corresponding language
			this.translate.use(lang);
			} else {
			// If there is no value in localStorage, set the default language to 'es'
			this.translate.use('es');
			localStorage.setItem('language', 'es');
		}
	}

	selectLang(lang: string) {
		if(lang ==='es'){
			this.formPreferences.get('id_language').setValue(1)
		}
		else if(lang === 'en'){
			this.formPreferences.get('id_language').setValue(2)
		}
		lang = localStorage.getItem('language');
		const newLang = lang === 'es' ? 'en' : 'es';
		this.translate.use(newLang)
		localStorage.setItem( 'lang', newLang )
		this.spanSelect = newLang;
		this.option = lang;
	}
	changeMode(mode: string): void {
		this.mode = mode;

		if(this.mode === 'light'){
			this.formPreferences.get('dark_mode').setValue(0)
		}
		else if(this.mode === 'dark'){
			this.formPreferences.get('dark_mode').setValue(1)
		}
		document.body.classList.toggle('dark-mode', this.mode === 'dark');
		this.modeSelected = this.dataService.mode;
	}
	saveChanges() {
		localStorage.setItem('mode', this.mode);
		localStorage.setItem( 'lang', this.spanSelect );
		let data = {
			id_language	: this.languageValue,
			first_name	: this.fname,
			surname		: this.sname,
			email		: this.email,
			company		: this.company,
			id_user		: this.id_user,
			userType	: this.userType,
			dark_mode	: this.darkModeValue
		}
		this.adminService.update_update_data_user(data).subscribe((res) => {
			if(res.mensaje_return.CODE === 200){
				this.toastr.success(
					res.mensaje_return.ERROR_MENSAGGE,
					this.translate.instant('message.errorGenerateToken'),
					{
					toastClass: 'toast-extendida',
					timeOut: 5000,
					positionClass: 'toast-bottom-center',
					}
				);
			}
		})
	}
}
