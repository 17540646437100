// * Angular
import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Platform } from '@angular/cdk/platform';
import { Router } from '@angular/router';
// * Services
import { FilterService } from 'src/app/core/http/FilterDataServiceOxxoToOxxo.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import * as XLSX from 'xlsx';
// * Libraries
import { AgGridAngular } from 'ag-grid-angular';
import { AgGridEvent, ColDef, GridOptions } from 'ag-grid-community';
// * Components
import { StarRatingComponent } from 'src/app/shared/components/star-rating/star-rating.component';
import { DateTextComponent } from '../../date-text/date-text.component';
import { AdminClientService } from 'src/app/core/http/admin-client.service';
import { DataService } from 'src/app/core/services/data.service';


@Component({
  selector: 'clients-table',
  templateUrl: './clients-table.component.html',
  styleUrls: ['./clients-table.component.scss']
})
export class ClientsTableComponent implements OnInit{
	@Input() btnExport: string = 'none';
	@Input() calendar: string = 'none';
	@Input() filterFunnel: string = 'none';
	@Input() user;
	@Input() client;
	@Input() rowData;
	@Input() search;
	@Input() columns: string;
	@ViewChild('agGrid') agGrid: AgGridAngular;
	filterText: string = '';
	gridOptions: GridOptions;
	dataTemporal = [];
	filters:boolean=false;
	mode:string;
	buttonExport:boolean =true;

	public filteredData: any[] = [];
	private gridApi: AgGridAngular;
	public columnDefs: ColDef[];

	constructor(
    private platform      : Platform,
    private router        : Router,
    private adminService  : AdminClientService,
    private dialog        : MatDialog,
    private translate     : TranslateService,
    private filterService	: FilterService,
	public dataService : DataService) {
		translate.use(localStorage.getItem('language').toLowerCase());
	}
	ngOnDestroy() {
		this.dialog.closeAll();
	}
	ngOnChanges(changes: SimpleChanges): void {
		if (this.rowData) {
			if(this.rowData.length === 0){
				this.buttonExport =false;
			}
			else{
				this.buttonExport =  true;
			}
		}
		this.filteredData = this.rowData;
	}
	ngOnInit() {
		this.mode = localStorage.getItem('mode');
		this.translate.use(localStorage.getItem('language').toLowerCase());
		this.updateDataClient();
		this.translate.onLangChange.subscribe((event: LangChangeEvent)=>{
			this.filterText="";
			this.filteredData=[];
			this.agGrid.api.updateGridOptions({ quickFilterText: "" });

			this.translate.use(event.lang);
			this.updateDataClient();
			this.translate.get([
				'table-client.name',
				'table-client.email',
				'table-client.phone',
				'table-client.number_visits',
				'table-client.registration_date'
			]).subscribe((t:any)=>{
				this.columnDefs = [
					{
						headerName: this.translate.instant('table-client.name'),
						field: 'name',
						minWidth: 200,
						sortable: true,
						autoHeight: true,
						cellClass:'ClientsClass',
						headerClass:'borderHeaderClients',
						suppressMovable: true,
					},
					{
						headerName: this.translate.instant('table-client.email'),
						field: 'email',
						minWidth: 300,
						sortable: true,
						autoHeight: true,
						cellClass:'ClientsClass',
						headerClass:'borderHeaderClients',
						suppressMovable: true,
					},
					{
						headerName: this.translate.instant('table-client.phone'),
						field: 'tlf',
						minWidth: 200,
						sortable: true,
						autoHeight: true,
						cellClass:'ClientsClass',
						headerClass:'borderHeaderClients',
						suppressMovable: true,
						cellRenderer: (params) => {
							// const phoneNumber = params.value;
							// const formattedPhone = `${phoneNumber.slice(0,3)} (${phoneNumber.slice(3, 6)})-${phoneNumber.slice(6,9)}-${phoneNumber.slice(9, 13)}`;
							// const formattedPhone = `### (###)-###-${phoneNumber.slice(9, 13)}`;
							return `<div>${params.value}</div>`;
						},
					},
					{
						headerName: this.translate.instant('table-client.number_visits'),
						field: 'paid_packages',
						minWidth: 150,
						sortable: true,
						autoHeight: true,
						cellClass:'ClientsClass',
						headerClass:'borderHeaderClients',
						suppressMovable: true,
					},
					{
						headerName: this.translate.instant('table-client.registration_date'),
						field: 'format_registration_date',
						sortable: true,
						cellClass:'ClientsClass',
						headerClass:'borderHeaderClients',
						cellRenderer: DateTextComponent,
						autoHeight: true,
						minWidth: 180,
						comparator:this.dateComparator.bind(this, 'registration_date'),
						suppressMovable: true,
					}
				]
			} )
		});
	}
	onGridReady(params) {
		this.gridApi = params;
	}

	onFilterTextChange(event) {
		let textSearch=this.filterText.toLowerCase();
		this.agGrid.api.updateGridOptions({ quickFilterText: textSearch.toLowerCase() });
		let temporal = [];
		this.agGrid.api.forEachNodeAfterFilterAndSort((node) => {
			temporal.push(node.data);
		});
		this.filteredData = temporal;
	}

	centeredCellRenderer(params) {
		const cellValue = params.value;
		const cellDiv = document.createElement('div');
		cellDiv.style.display = 'flex';
		cellDiv.style.alignItems = 'center';
		cellDiv.style.justifyContent = 'center';
		cellDiv.style.height = '100%';
		cellDiv.innerText = cellValue;
		return cellDiv;
	}
	queryConsumer() {




	}
	onExport() {
		if (this.filteredData.length > 0){
			const fileName = 'Clients.xlsx';
			// Configuración para exportar en formato Excel
			const excelParams = {
				fileName: fileName,
				sheetName: 'Clients',
				columnWidths: [30, 30, 20, 20, 20], // Ancho de las columnas en caracteres
			};
			// Obtener los datos de la tabla
			let Data = [];
			if (this.filteredData.length > 0) Data = this.filteredData || [];
			else if (this.rowData.length > 0 && this.filteredData.length === 0) Data = this.rowData || [];
			
			const excelData = Data.map((row) => {
				// const { id_company,id_status,id_courier,registration_date, ...rowDataWithoutFields} = row;
				// return rowDataWithoutFields;

				return {
					name: row.name,
					email: row.email,
					tlf: row.tlf,
					paid_packages: row.paid_packages,
					registration_date: row.format_registration_date,
				};
			});
			const wb = XLSX.utils.book_new();
			const ws = XLSX.utils.json_to_sheet(excelData);
			XLSX.utils.book_append_sheet(wb, ws, excelParams.sheetName);
			XLSX.writeFile(wb, fileName);
		}
	}

	onSortChanged(event:any){
		const sortedData: any[] = [];
		this.gridApi.api.forEachNodeAfterFilterAndSort(node => {
			sortedData.push(node.data);
		});
		this.filteredData=sortedData
	}

	updateDataClient():void{
		this.gridOptions = {
			enableCellTextSelection: true,
			defaultColDef: {
				resizable: false,
			},
			columnDefs: [
				{
					headerName: this.translate.instant('table-client.name'),
					field: 'name',
					minWidth: 200,
					sortable: true,
					autoHeight: true,
					cellClass:'ClientsClass',
					headerClass:'borderHeaderClients',
					suppressMovable: true,
				},
				{
					headerName: this.translate.instant('table-client.email'),
					field: 'email',
					minWidth: 300,
					sortable: true,
					autoHeight: true,
					cellClass:'ClientsClass',
					headerClass:'borderHeaderClients',
					suppressMovable: true,
				},
				{
					headerName: this.translate.instant('table-client.phone'),
					field: 'tlf',
					minWidth: 200,
					sortable: true,
					autoHeight: true,
					cellClass:'ClientsClass',
					headerClass:'borderHeaderClients',
					suppressMovable: true,
					cellRenderer: (params) => {
						// const phoneNumber = params.value;
						// const formattedPhone = `${phoneNumber.slice(0,3)} (${phoneNumber.slice(3, 6)})-${phoneNumber.slice(6,9)}-${phoneNumber.slice(9, 13)}`;
						// const formattedPhone = `### (###)-###-${phoneNumber.slice(9, 13)}`;
						return `<div>${params.value}</div>`;
					},
				},
				{
					headerName: this.translate.instant('table-client.number_visits'),
					field: 'paid_packages',
					minWidth: 150,
					sortable: true,
					autoHeight: true,
					cellClass:'ClientsClass',
					headerClass:'borderHeaderClients',
					suppressMovable: true,
				},
				{
					headerName: this.translate.instant('table-client.registration_date'),
					field: 'format_registration_date',
					sortable: true,
					cellClass:'ClientsClass',
					headerClass:'borderHeaderClients',
					cellRenderer: DateTextComponent,
					autoHeight: true,
					minWidth: 180,
					comparator:this.dateComparator.bind(this, 'registration_date'),
					suppressMovable: true,
				}
			],
			autoSizeStrategy: {
				type: 'fitGridWidth',
			},
			animateRows: true,
			rowClass:'rowClientsClass',
			pagination: true,
			paginationPageSize: 10,
			paginationAutoPageSize: true,
			rowHeight:59,
			headerHeight:59,
			overlayNoRowsTemplate:
			`<div>
				<div>
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="bi bi-info-circle icon-no-info" viewBox="0 0 16 16">
						<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
						<path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
					</svg>
				</div>
				<div class="labelNoRegistros">
					Aun no hay registros, <br>
					intentalo mas tarde
				</div>
			</div>`,
		}
	}

	onDisplayedColumnsChanged(event: AgGridEvent) {
		const grid = document.getElementById('ag-grid');
		if (grid) {
			const gridWidth = grid.offsetWidth;
			
			const widthVisibleColumns = event.api.getColumns()
				.filter(c => c.isVisible())
				.map(c => c.getActualWidth())
				.reduce((a, b) => a + b);
	
			if (gridWidth > widthVisibleColumns) {
				event.api.sizeColumnsToFit();
			} else {
				event.api.autoSizeAllColumns();
			}
		}
	}

	dateComparator(param?: string,valueA?: any, valueB?: any, nodeA?: any, nodeB?: any, isInverted?: boolean): number {
		const date1: Date = new Date(nodeA.data[param]);
		const date2: Date = new Date(nodeB.data[param]);
		// const idPackage1: number = nodeA.data.id_package;
		// const idPackage2: number = nodeB.data.id_package;

		if (date1 === date2) {
			// return idPackage2 - idPackage1;
			return 0;
		}
		
		return date1 < date2 ? -1 : 1;
	}


	showFilters(){
		this.filters = !this.filters
	}
}

