<!-- ? CONTAINER MAIN -->
<div class="cont_main">
	<!-- ? HEADER -->
	<div class="header headlines_h2 font_semibold color_primary">
		<!-- TITLE -->
		<p>{{'overview.cardsOverview.ordersRecoletedRefund' | translate}}</p>

		 <!-- ? TOOLTIP CONTAINER -->
		 <div class="tooltip_cont_">
			<img src="../../../../../../assets/icons/icon-info.svg" class="icon_tooltip">

			<!-- TOOLTIP -->
			<div class="tooltip_">
				<p class="caption_c2 color_primary_400 font_medium">
					{{ 'tooltip.ordersTrackingExpired' | translate }}
				</p>
				<!-- PIKE -->
				<div class="pike_2"></div>
			</div>
			<!-- PIKE -->
			<div class="pike"></div>

		</div>

	</div>

	<!-- ? CHART  -->
	<div id="chart" class="chart">
        <apx-chart
            [series]="dataChartExpiredOrders.series"
            [chart]="dataChartExpiredOrders.chart"
            [dataLabels]="dataChartExpiredOrders.dataLabels"
            [plotOptions]="dataChartExpiredOrders.plotOptions"
            [responsive]="dataChartExpiredOrders.responsive"
            [xaxis]="dataChartExpiredOrders.xaxis"
            [legend]="dataChartExpiredOrders.legend"
            [fill]="dataChartExpiredOrders.fill">
        </apx-chart>
	</div>


	<!-- ? FOOTER -->
	<div class="footer_">
		<!-- ? DATES -->
		<div class="dates_ caption_c2 font_medium color_primary_300">
			<!-- BASE DATE -->
			<span class="span-date" *ngIf="periodBase.length === 2">
				{{periodBase[0] | date:'dd' }} - {{periodBase[1] | date:'dd MMMM yyyy' }}
			</span>
			<!-- COMPARE DATE -->
			<span class="span-date" *ngIf="periodComp.length === 2">
				{{periodComp[0] | date:'dd' }} - {{periodComp[1] | date:'dd MMMM yyyy' }}
			</span>
		</div>

		<!-- ? LEGEND -->
		<div class="leyend_cont caption_c3 color_primary font_medium">
			<!-- BLOCK 1 -->
			<div>
				<!-- SQUARE -->
				<div class="color_ color_1"></div>
				<!-- TXT -->
                <p>{{'overview.orderRecolected' | translate}}</p>

			</div>
			<!-- BLOCK 2 -->
			<!-- <div>
				<div class="color_ color_2"></div>
				<p>{{'overview.orderRefunded' | translate}}</p>
			</div> -->

		</div>

	</div>


</div>
