import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ModuleCarrierService {
	public baseUrl: string = environment.url_server_backend;
	private token: string;
	private options: any;
	public temp: any;
	public data;

	constructor(private http: HttpClient) { }

	setToken(token: string) {
		this.token = token;
	}

	getToken() {
		return this.token ? this.token : localStorage.getItem('token');
	}

	async getCarrierData(params:any):Promise<any>{
		let options = {
			headers: new HttpHeaders({
				'Content-Type':"application/json",
				token: this.getToken()
			}),
			params: params
		}
		let url = this.baseUrl + '/server/company/query-carrier';
		return this.http.get(url,options).toPromise();
	}

	async getCarrierDataOrders(params:any):Promise<any>{
		let options = {
			headers: new HttpHeaders({
				'Content-Type':"application/json",
				token: this.getToken()
			}),
			params:params
		}
		let url = this.baseUrl + '/server/company/query-carrier-order';
		return this.http.get(url,options).toPromise();
	}

	async getCarrierDataUsers(params:any):Promise<any>{
		let options = {
			headers: new HttpHeaders({
				'Content-Type':"application/json",
				token: this.getToken()
			}),
			params:params
		}
		let url = this.baseUrl + '/server/company/query-carrier-user';
		return this.http.get(url,options).toPromise();
	}

	async disableCarrier(params:any):Promise<any>{
		const headers = new HttpHeaders({
			'Content-Type':'application/json',
			token:this.getToken()
		})
		let url = this.baseUrl + '/server/company/change-status-api-key';
		const body = JSON.stringify(params);
		const request = new HttpRequest('POST',url,body,{headers});
		const response = await this.http.request(request).toPromise();
		return response;
	}

	async addNewCarrierAdmin(params:any):Promise<any>{
		const headers = new HttpHeaders({
			'Content-Type':'application/json',
			token:this.getToken()
		});
		let url = this.baseUrl + '/server/company/register-carrier';
		const body = JSON.stringify(params);
		const request = new HttpRequest('POST',url,body,{headers});
		const response = await this.http.request(request).toPromise();
		return response;
	}
}
