import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { AgGridEvent, ColDef, GridOptions } from 'ag-grid-community';
import { TableUserService } from 'src/app/core/http/user.service';
import { SuperAdminResult, UsersCarriersInterface, SuperAdminInterface } from 'src/app/core/models/users.interfaces';
import * as XLSX from 'xlsx';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DialogAuditUserComponent } from '../../dialog-audit-user/dialog-audit-user.component';
import { ConfirmChangeStatusUserComponent } from '../../confirm-change-status-user/confirm-change-status-user.component';
import { UserDetailModalComponent } from '../../user-detail-modal/user-detail-modal.component';
import { DateTextComponent } from '../../date-text/date-text.component';
import { DataService } from 'src/app/core/services/data.service';
import { AgGridAngular } from 'ag-grid-angular';

@Component({
  selector: 'super-admin-table',
  templateUrl: './super-admin-table.component.html',
  styleUrls: ['./super-admin-table.component.scss']
})
export class SuperAdminTableComponent implements OnInit {
	@ViewChild('agGrid') agGrid: AgGridAngular;
	valueInput;
	// public columnDefs: ColDef[];
	filterText: string = '';
	gridOptions: GridOptions;
	public filteredData: any[] = [];
	users: UsersCarriersInterface[];
	superAdmin: SuperAdminInterface[];
	buttonExport:boolean =true;
	public columnDefsTranslate: ColDef[];

	public columnDefs: ColDef[] = [
		{
			headerName:this.translate.instant('table-superadmin.fullName'),
			field: 'first_name',
			minWidth: 250,
			cellClass:'superAdminClassLink',
			cellRenderer: this.centeredCellRenderer,
			suppressMovable: true,
			// onCellClicked: ($event) => this.openModalUserDetail($event)
		},
		{
			headerName: this.translate.instant('table-superadmin.email'),
			field: 'email',
			minWidth: 200,
			cellClass:'superAdminClass',
			cellRenderer: this.centeredCellRenderer,
			suppressMovable: true,
		},
		{
			headerName: this.translate.instant('table-superadmin.activeDays'),
			field: 'active_days',
			minWidth: 120,
			maxWidth: 120,
			cellClass:'superAdminClass',
			cellRenderer: this.centeredCellRenderer,
			suppressMovable: true,
		},
		{
			headerName: this.translate.instant('table-superadmin.registerDate'),
			field: 'format_registration_date',
			minWidth: 200,
			cellClass:'superAdminClass',
			cellRenderer: DateTextComponent,
			suppressMovable: true,
			comparator:this.dateComparator.bind(this, 'registration_date'),
		}
	];

	constructor(
		private translate: TranslateService,
		private userService: TableUserService,
		private dialog: MatDialog,
		public dataService : DataService
	) { }

	ngOnInit(): void {
		this.translate.use(localStorage.getItem('language').toLowerCase());
		this.updateDataSuperAdmin();
		this.loadData();
		this.translate.onLangChange.subscribe((event: LangChangeEvent)=> {
			this.filterText="";
			this.filteredData=[];
			this.agGrid.api.updateGridOptions({ quickFilterText: "" });
			this.loadData();

			this.translate
			.get([
				'table-superadmin.fullName',
				'table-superadmin.email',
				'table-superadmin.activeDays',
				'table-superadmin.registerDate'
			])
			.subscribe((t: any) => {
				this.columnDefsTranslate = this.columnDefs.map((obj: any,) => {
					switch (obj.field) {
						case 'first_name':
							obj.headerName = t['table-superadmin.fullName'];
							obj.sort = null;
							break;
						case 'email':
							obj.headerName = t['table-superadmin.email'];
							obj.sort = null;
							break;
						case 'active_days':
							obj.headerName = t['table-superadmin.activeDays'];
							obj.sort = null;
							break;
						case 'format_registration_date':
							obj.headerName = t['table-superadmin.registerDate'];
							obj.sort = null;
							break;
					}
					return obj
				});
			});
		})
	}

	onFilterTextChange(event) {
		let textSearch=this.filterText.toLowerCase();
		this.agGrid.api.updateGridOptions({ quickFilterText: textSearch.toLowerCase() });
		let temporal = [];
		this.agGrid.api.forEachNodeAfterFilterAndSort((node) => {
			temporal.push(node.data);
		});
		this.filteredData = temporal;
	}

	updateDataSuperAdmin(){
		this.translate.use(localStorage.getItem('language').toLowerCase());
		this.gridOptions = {
			enableCellTextSelection: true,
			defaultColDef: {
				resizable: false,
				sortable: true,
				autoHeight: true,
				headerClass: 'borderHeaderGeneralSuperAdmin',
			},
			columnDefs: this.columnDefs,
			autoSizeStrategy: {
				type: 'fitGridWidth',
			},
			animateRows: true,
			pagination: true,
			paginationPageSize: 10,
			paginationAutoPageSize: true,
			enableCellChangeFlash: true,
			rowClass:'rowSuperAdminClass',
			overlayNoRowsTemplate:
			`<div>
				<div>
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="bi bi-info-circle icon-no-info" viewBox="0 0 16 16">
						<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
						<path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
					</svg>
				</div>
				<div class="labelNoRegistros">
					Aun no hay registros, <br>
					intentalo mas tarde
				</div>
			</div>`,
		}
	}

	onDisplayedColumnsChanged(event: AgGridEvent) {
		const grid = document.getElementById('ag-grid');
		if (grid) {
			const gridWidth = grid.offsetWidth;
			
			const widthVisibleColumns = event.api.getColumns()
				.filter(c => c.isVisible())
				.map(c => c.getActualWidth())
				.reduce((a, b) => a + b);
	
			if (gridWidth > widthVisibleColumns) {
				event.api.autoSizeAllColumns();
			} else {
				event.api.sizeColumnsToFit();
			}
		}
	}

	loadData(){
		this.superAdmin=[];
		let data ={
			language: localStorage.getItem('language').toLowerCase()
		}
		this.userService.getSuperAdminAsincrono(data).then( (res:SuperAdminResult)=>{

			if(res.result_return){
				res.result_return.forEach((item:any)=>{
					// let hourRegistrationDate = item.registration_date.substring(0,2);
					// let minutesRegistrationDate = item.registration_date.substring(3,5);
					// let secondsRegistrationDate = item.registration_date.substring(6,8);
					// let monthRegistrationDate = parseInt(item.registration_date.substring(9,11));
					// let dayRegistrationDate = item.registration_date.substring(12,14);
					// let yearRegistrationDate = item.registration_date.substring(15,19);
					// let registrationDateString = yearRegistrationDate +'-'+monthRegistrationDate+'-'+dayRegistrationDate+' '+hourRegistrationDate+':'+minutesRegistrationDate+':'+secondsRegistrationDate;
					this.superAdmin.push({
						acronym_language:item.acronym_language,
						active_days: item.active_days,
						alias_username: item.alias_username,
						audit_package: item.audit_package,
						code_master: item.code_master,
						courier_name: item.courier_name,
						email: item.email,
						enable_token: item.enable_token,
						first_name: item.first_name + ' ' + item.surname,
						id_language: item.id_language,
						id_status: item.id_status,
						id_user: item.id_user,
						language_name: item.language_name,
						name_status: item.name_status,
						registration_date: item.registration_date,
						format_registration_date: item.format_registration_date,
						audit_employee: item.result_audit_emploche,
						surname: item.surname,
						url_token: item.url_token
					})
				})
				this.superAdmin = [...this.superAdmin];
				this.filteredData=[...this.superAdmin];
				// if(this.superAdmin.length === 0){
				// 	this.buttonExport =false;
				// }
				// else{
				// 	this.buttonExport =  true;
				// }
			}
		})
	}

	dateComparator(param?: string,valueA?: any, valueB?: any, nodeA?: any, nodeB?: any, isInverted?: boolean): number {
		const date1: Date = new Date(nodeA.data[param]);
		const date2: Date = new Date(nodeB.data[param]);
		// const idPackage1: number = nodeA.data.id_package;
		// const idPackage2: number = nodeB.data.id_package;

		if (date1 === date2) {
			// return idPackage2 - idPackage1;
			return 0;
		}
		
		return date1 < date2 ? -1 : 1;
	}

	onSortChanged(event:any){
		const sortedData: any[] = [];
		this.agGrid.api.forEachNodeAfterFilterAndSort(node => {
			sortedData.push(node.data);
		});
		this.filteredData=sortedData
	}

	onExport() {
		if (this.filteredData.length > 0){
			const fileName = 'SuperAdmin-Users.xlsx';
			const excelParams = {
				fileName: fileName,
				sheetName: 'SuperAdmin-Users',
				columnWidths: [30, 20, 20, 20],
			};
			let Data = [];
			if (this.filteredData.length > 0) Data = this.filteredData || [];
			else if (this.superAdmin.length > 0 && this.filteredData.length === 0) Data = this.superAdmin || [];
	
			const excelData = Data.map((row) => {
				return {
					fullName:row.first_name,
					email:row.email,
					activeDays:row.active_days,
					registerDate:row.registration_date,
				}
			});
			const wb = XLSX.utils.book_new();
			const ws = XLSX.utils.json_to_sheet(excelData);
			XLSX.utils.book_append_sheet(wb, ws, excelParams.sheetName);
			XLSX.writeFile(wb, fileName);
			// if (this.filteredData.length > 0) {
			// 	const Data = this.filteredData || [];
			// 	const excelData = Data.map((row) => {
			// 		// const { id_status,id_language,id_user,audit_package,audit_employee,code_master,url_token,	...rowDataWithoutFields} = row;
			// 		// return rowDataWithoutFields;
			// 		return {
			// 			fullName:row.first_name,
			// 			email:row.email,
			// 			activeDays:row.active_days,
			// 			registerDate:row.registration_date,
			// 		}
			// 	});
			// 	const wb = XLSX.utils.book_new();
			// 	const ws = XLSX.utils.json_to_sheet(excelData);
			// 	XLSX.utils.book_append_sheet(wb, ws, excelParams.sheetName);
			// 	XLSX.writeFile(wb, fileName);
			// } else if (this.superAdmin.length > 0 && this.filteredData.length === 0) {
			// 	const Data = this.superAdmin || [];
			// 	const excelData = Data.map((row) => {
			// 		// const { id_status,id_language,id_user,audit_package,audit_employee,code_master,url_token,	...rowDataWithoutFields} = row;
			// 		// return rowDataWithoutFields;
			// 		return {
			// 			fullName:row.first_name,
			// 			email:row.email,
			// 			activeDays:row.active_days,
			// 			registerDate:row.registration_date,
			// 		}
			// 	});
			// 	const wb = XLSX.utils.book_new();
			// 	const ws = XLSX.utils.json_to_sheet(excelData);
			// 	XLSX.utils.book_append_sheet(wb, ws, excelParams.sheetName);
			// 	XLSX.writeFile(wb, fileName);
			// }
		}
	}
	centeredCellRenderer(params) {
		const cellValue = params.value;
		const cellDiv = document.createElement('div');
		cellDiv.style.display = 'flex';
		cellDiv.style.alignItems = 'center';
		cellDiv.style.justifyContent = 'center';
		cellDiv.style.height = '100%';
		cellDiv.innerText = cellValue;
		return cellDiv;
	}


	openModalUserDetail(event) {
		const dialogRef = this.dialog.open(UserDetailModalComponent, {
			width: '1456px',
			height: '95%',
			data: event.data,
		});

		dialogRef.afterClosed().subscribe((result) => {
		});
	}
	openConfirmChangeStatus(event) {
		const dialogRef = this.dialog.open(ConfirmChangeStatusUserComponent, {
			width: '500px',
			height: '200px',
			data: event,
		});

		dialogRef.afterClosed().subscribe((result) => {
			const newData = this.users.slice();
			newData[result].status = 'INACTIVE';
			this.users = newData;
		});
	}
	openModalUserAudit(event) {
		const dialogRef = this.dialog.open(DialogAuditUserComponent, {
			width: '959px',
			height: '796px',
			data: event.data,
		});

		dialogRef.afterClosed().subscribe((result) => {
		});
	}
}
