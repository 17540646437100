<div class="row">
	<div class="col-12">
        <label class="header-user-detail">
            Auditoria
        </label>
    </div>
</div>

<div class="row" style="border:1px solid #e5e5e5; border-radius:20px">
    <div class="col-6">
        <div [ngClass]="{'user-information-active': viewUserInformation ===true, 'user-information-desactive': viewUserInformation ===false}" (click)="ViewUserInformation()">
            <label class="active">
                Auditoria de paquete
            </label>
        </div>
    </div>
    <div class="col-6">
        <div [ngClass]="{'qr-information-disabled': viewQrInformation ===false, 'qr-information-active': viewQrInformation ===true}" (click)="ViewQrInformation()">
            <label class="desactive">
                Auditoria de usuario
            </label>
        </div>
    </div>

    <div class="col-12" style="margin-top:24px">
        <div class="user-information-table" *ngIf="viewUserInformation === true && viewQrInformation === false">
            <div class="row">
                <div class="col-12 user-table-contain">
                    <ag-grid-angular
						class="ag-theme-alpine"
						[gridOptions]="gridOptionsPackage"
						[rowData]="rowDataPackage"
						style="width: 100%; height: 665px;">
                    </ag-grid-angular>
                </div>
            </div>

        </div>
        <div *ngIf="viewUserInformation === false && viewQrInformation === true">
            <div class="row">
                <div class="col-12 user-table-contain">
                    <ag-grid-angular
						class="ag-theme-alpine"
						[gridOptions]="gridOptionsEmployee"
						[rowData]="rowDataEmployee"
						style="width: 100%; height: 665px;">
                    </ag-grid-angular>
                </div>
            </div>
        </div>
    </div>
</div>
