import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
	selector: 'app-item-notification',
	templateUrl: './item-notification.component.html',
	styleUrls: ['./item-notification.component.scss']
})
export class ItemNotificationComponent implements OnInit {
	@Input() order;
	@Input() user;
	@Input() all;
	constructor() { }
	ngOnInit(): void {
	}
	ngOnChanges(changes: SimpleChanges): void {
		//Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
		//Add '${implements OnChanges}' to the class.
	}
}
