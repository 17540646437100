import { Component, EventEmitter, Input, Output, inject, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-input-dashboard',
	templateUrl: './input-dashboard.component.html',
	styleUrls: ['./input-dashboard.component.scss']
})
export class InputDashboardComponent implements OnInit, OnChanges {
	@Input() inputValue: string = ''; // Recibe el valor del input desde el componente padre
	@Output() inputValueChange: EventEmitter<string> = new EventEmitter<string>();
	@Input() showIcon:boolean = false;
	@Input() showPasswordIcon:boolean = false;
	@Input() icon:string;
	@Input() header:string;
	@Input() showError:boolean;
	@Input() showEraseIcon:boolean;
	@Input() showTextInside:boolean=false;
	@Input() textInside:string;
	@Input() inputType:string;
	@Input() error:string;
	@Input() showFooter:boolean=false;
	@Input() footerText:string='';
	@Input() required:boolean =false;
	@Input() haveMaxLength:boolean =false;
	@Input() maxLength:number;
	@Input() inputDisabled:boolean = false;
	public ts = inject( TranslateService );
	public randomSuffix: string = Math.random().toString(36).substring(2, 15);
	ngOnInit(): void {

	}
	ngOnChanges(changes: SimpleChanges) {
    }
	onInputChange(event: any) {
        this.inputValue = event.target.value;
        this.inputValueChange.emit(this.inputValue);
    }
	eraseInput(){
        this.inputValue = ''; // Borra el valor del input
		this.inputValueChange.emit(this.inputValue);
	}
	changeInputType(){
		if(this.inputType === 'password'){
			this.inputType = "text"
		}
		else if(this.inputType ==='text'){
			this.inputType = "password"
		}
	}
	showInfo(){

	}
}
