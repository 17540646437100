<div class="cont_main">
	<div class="row" style="row-gap: 12px;">
		<div class="col-12 col-md-4 col-lg-4 col-xl-4">
			<div class="input-container">
				<input
					type="text"
					class="input-gbl"
					(keyup)="onFilterTextChange($event)"
					placeholder="{{'dashboard.search_user' | translate}}">
				<button
					type="submit"
					class="btn-search">
					<div class="icon-search">
					</div>
				</button>
			</div>
		</div>
		<div class="col-12"  *ngIf="isMobileView() === true">
			<div class="input_cont_calendar">
				<p-calendar
					styleClass="input_custome"
					[showIcon]="true"
					[(ngModel)]="rangeDates"
					(ngModelChange)="addEventRangeDates()"
					selectionMode="range"
					[readonlyInput]="true"
					inputId="range">
				</p-calendar>

			</div>
		</div>
		<div class="col-12 col-md-12 col-lg-8 col-xl-8">
			<div class="calendar_filter_export">
				<div class="input_cont_calendar" *ngIf="isMobileView() === false">
					<p-calendar
						styleClass="input_custome"
						[showIcon]="true"
						[(ngModel)]="rangeDates"
						(ngModelChange)="addEventRangeDates()"
						selectionMode="range"
						[readonlyInput]="true"
						inputId="range">
					</p-calendar>
				</div>
				<app-button-icon *ngIf="this.mode ==='light'"
					[activo]="false"
					btnNombre="buttons.export"
					btnType="primaryMedium"
					icon="icon-file-download-disabled"
					(click)="onExport()">
				</app-button-icon>
				<app-button-icon *ngIf="this.mode ==='dark'"
					[activo]="false"
					btnNombre="buttons.export"
					btnType="primaryMedium"
					icon="icon-file-download-disabled"
					(click)="onExport()">
				</app-button-icon>
				<!-- <button-export (click)="onExport()"></button-export> -->
			</div>

		</div>

		<div class="col-12 col-md-12 col-lg-12 col-xl-12">
			<ag-grid-angular
				#agGrid
				style="width: 100%; height: 750px; border: none; margin-top:24px"
				class="ag-theme-alpine"
				[gridOptions]="this.gridOptions"
				[rowData]="this.rowDataExpired"
				[columnDefs]="this.columnDefsExpired"
				[quickFilterText]="this.filterText"
				(gridReady)="onGridReady($event)">
			</ag-grid-angular>
		</div>
	</div>
</div>
<div *ngIf="display" class="extendCard">
    <div class="row">
        <div class="col-12">
            <p-calendar
				#calendar
				[inline]="true"
				[minDate]="minDate"
				[maxDate]="maxDate">
			</p-calendar>
        </div>
        <div class="col-6" style="text-align: start;">
			<app-button
				btnNombre="general.cancel"
				btnType="secondaryMedium"
				(click)="cerrarExtender()">
			</app-button>
        </div>
        <div class="col-6" style="text-align: end;">
			<app-button
				*ngIf="calendar.value === undefined"
				[activo]="true"
				btnNombre="table.extend"
				btnType="primaryMedium"
				(click)="extenderFechas(calendar)">
			</app-button>
			<app-button
				*ngIf="calendar.value !== undefined"
				[activo]="false"
				btnNombre="table.extend"
				btnType="primaryMedium"
				(click)="extenderFechas(calendar)">
			</app-button>
        </div>
    </div>
</div>
