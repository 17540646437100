import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-date-format',
  templateUrl: './date-format.component.html',
  styleUrls: ['./date-format.component.scss']
})
export class DateFormatComponent implements OnInit, ICellRendererAngularComp {
	fecha: string;
	isBadDate:boolean = false;

	constructor() {}
	agInit(params: ICellRendererParams<any, any>): void {
		if(params.value !== null && params.value !== '' && params.value !== 'undefined' && params.value !== undefined) {
			let date = new Date(params.value);
			this.fecha = date.toString();
			this.isBadDate = false;
		}
		else{
			this.fecha=null;
		}
	}
	refresh(params: ICellRendererParams<any, any>): boolean {
		return false;
	}

	ngOnInit(): void {}

}
