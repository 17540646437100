<div class="item-container" *ngIf="order !== undefined">
	<!-- {{order | json}} -->
	<div class="icon-container">
		<div class="circle" *ngIf="order.read===false">
		</div>
		<div class="icon-orders">
		</div>
	</div>
	<div class="text-container">
		{{'notifications.items.packet' | translate }}
		<strong> {{order.tracking_number}} </strong>
		{{'notifications.items.statusTopic.'+ order.id_topic_audit | translate }}
		<strong> {{order.locker_name}} </strong>
		<div class="time-container">
			{{'notifications.items.ago' | translate }}
			{{order.time_elapsed}}
			{{'notifications.items.minutes' | translate }}
		</div>
	</div>

</div>
<div class="item-container" *ngIf="user !== undefined">
	<div class="icon-container">
		<div class="circle" *ngIf="user.read===false">
		</div>
		<div class="icon-user" *ngIf="user !== undefined">
		</div>
	</div>
	<div class="text-container">
		{{'notifications.items.user' | translate }}
		<strong> {{user.full_name}} </strong>
		{{'notifications.items.statusTopic.' +user.id_topic_audit | translate }}
		<strong> {{user.locker_name}} </strong>
		<div class="time-container">
			{{'notifications.items.ago' | translate }}
			{{user.time_elapsed}}
			{{'notifications.items.minutes'| translate }}
		</div>
	</div>
</div>
<div class="item-container" *ngIf="all !== undefined">
	<div class="icon-container">
		<div class="circle" *ngIf="all.read===false">
		</div>
		<div class="icon-orders" *ngIf="all.isOrder === true">
		</div>
		<div class="icon-user" *ngIf="all.isUser === true">
		</div>
	</div>

	<div class="text-container" *ngIf="all.isOrder === true">
		{{'notifications.items.packet' | translate }}
		<strong> {{all.tracking_number}} </strong>
		{{'notifications.items.statusTopic.'+ all.id_topic_audit | translate }}
		<strong> {{all.locker_name}} </strong>
		<div class="time-container">
			{{'notifications.items.ago' | translate }}
			{{all.time_elapsed}}
			{{'notifications.items.minutes' | translate }}
		</div>
	</div>

	<div class="text-container" *ngIf="all.isUser === true">
		{{'notifications.items.user' | translate }}
		<strong> {{all.full_name}} </strong>
		{{'notifications.items.statusTopic.' +all.id_topic_audit | translate }}
		<strong> {{all.locker_name}} </strong>
		<div class="time-container">
			{{'notifications.items.ago' | translate }}
			{{all.time_elapsed}}
			{{'notifications.items.minutes'| translate }}
		</div>
	</div>

	<!-- <div class="text-container" *ngIf="all.id_topic_audit ===4">
		El usuario <strong> {{all.full_name}} </strong>
		ha iniciado su sesion en el <strong> {{all.locker_name}} </strong>
		<div class="time-container">
			Hace {{all.time_elapsed}} minutos
		</div>
	</div>
	<div class="text-container" *ngIf="all.id_topic_audit ===5">
		El usuario <strong> {{all.full_name}} </strong>
		ha cerrado su sesion en el <strong> {{all.locker_name}} </strong>
		<div class="time-container">
			Hace {{all.time_elapsed}} minutos
		</div>
	</div>
	<div class="text-container" *ngIf="all.id_topic_audit ===6">
		El usuario <strong> {{all.full_name}} </strong>
		ha obtenido un error en su sesion en el <strong> {{all.locker_name}} </strong>
		<div class="time-container">
			Hace {{all.time_elapsed}} minutos
		</div>
	</div>
	<div class="text-container" *ngIf="all.id_topic_audit ===7">
		El usuario <strong> {{all.full_name}} </strong> ha intentado loguearse en el <strong> {{all.locker_name}} </strong>
		<div class="time-container">
			Hace {{all.time_elapsed}} minutos
		</div>
	</div>
	<div class="text-container" *ngIf="all.id_topic_audit ===8">
		El usuario <strong> {{all.full_name}} </strong> ha retirado un paquete en el <strong> {{all.locker_name}} </strong>
		<div class="time-container">
			Hace {{all.time_elapsed}} minutos
		</div>
	</div>
	<div class="text-container" *ngIf="all.id_topic_audit ===9">
		El usuario <strong> {{all.full_name}} </strong> ha entregado un paquete en el <strong> {{all.locker_name}} </strong>
		<div class="time-container">
			Hace {{all.time_elapsed}} minutos
		</div>
	</div>
	<div class="text-container" *ngIf="all.id_topic_audit ===10">
		El usuario <strong> {{all.full_name}} </strong> ha usado el codigo master en el <strong> {{all.locker_name}} </strong>
		<div class="time-container">
			Hace {{all.time_elapsed}} minutos
		</div>
	</div>
	<div class="text-container" *ngIf="all.id_topic_audit === 11">
		El usuario <strong> {{all.full_name}} </strong> ha retirado un paquete expirado en el <strong> {{all.locker_name}} </strong>
		<div class="time-container">
			Hace {{all.time_elapsed}} minutos
		</div>
	</div>
	<div class="text-container" *ngIf="all.id_topic_audit === 25">
		El paquete <strong> {{all.tracking_number}} </strong> ha sido ingresado al <strong> {{all.locker_name}} </strong>
		<div class="time-container">
			Hace {{all.time_elapsed}} minutos
		</div>
	</div>
	<div class="text-container" *ngIf="all.id_topic_audit === 26">
		El paquete <strong> {{all.tracking_number}} </strong> ha sido asignado al <strong> {{all.locker_name}} </strong>
		<div class="time-container">
			Hace {{all.time_elapsed}} minutos
		</div>
	</div>
	<div class="text-container" *ngIf="all.id_topic_audit === 27">
		El paquete <strong> {{all.tracking_number}} </strong> esta en transito CEDIS origen
		<div class="time-container">
			Hace {{all.time_elapsed}} minutos
		</div>
	</div>
	<div class="text-container" *ngIf="all.id_topic_audit === 28">
		El paquete <strong> {{all.tracking_number}} </strong> esta ubicado en CEDIS origen
		<div class="time-container">
			Hace {{all.time_elapsed}} minutos
		</div>
	</div>
	<div class="text-container" *ngIf="all.id_topic_audit === 29">
		El paquete <strong> {{all.tracking_number}} </strong> esta en transito a CEDIS destino
		<div class="time-container">
			Hace {{all.time_elapsed}} minutos
		</div>
	</div>
	<div class="text-container" *ngIf="all.id_topic_audit === 30">
		El paquete <strong> {{all.tracking_number}} </strong> esta ubicado en CEDIS destino
		<div class="time-container">
			Hace {{all.time_elapsed}} minutos
		</div>
	</div>
	<div class="text-container" *ngIf="all.id_topic_audit === 31">
		El paquete <strong> {{all.tracking_number}} </strong> esta transito a <strong> {{all.locker_name}} </strong>
		<div class="time-container">
			Hace {{all.time_elapsed}} minutos
		</div>
	</div>
	<div class="text-container" *ngIf="all.id_topic_audit === 32">
		El paquete <strong> {{all.tracking_number}} </strong> esta en revisión dentro de CEDIS
		<div class="time-container">
			Hace {{all.time_elapsed}} minutos
		</div>
	</div>
	<div class="text-container" *ngIf="all.id_topic_audit === 33">
		El paquete <strong> {{all.tracking_number}} </strong> ha sido revisado dentro de CEDIS
		<div class="time-container">
			Hace {{all.time_elapsed}} minutos
		</div>
	</div>
	<div class="text-container" *ngIf="all.id_topic_audit === 34">
		El paquete <strong> {{all.tracking_number}} </strong> ha sido confiscado en CEDIS
		<div class="time-container">
			Hace {{all.time_elapsed}} minutos
		</div>
	</div>
	<div class="text-container" *ngIf="all.id_topic_audit === 35">
		El paquete <strong> {{all.tracking_number}} </strong> esta en <strong> {{all.locker_name}} </strong>
		<div class="time-container">
			Hace {{all.time_elapsed}} minutos
		</div>
	</div>
	<div class="text-container" *ngIf="all.id_topic_audit === 36">
		El paquete <strong> {{all.tracking_number}} </strong> ha sido entregado en CEDIS
		<div class="time-container">
			Hace {{all.time_elapsed}} minutos
		</div>
	</div>
	<div class="text-container" *ngIf="all.id_topic_audit === 37">
		El paquete <strong> {{all.tracking_number}} </strong> ha sido expirado en <strong> {{all.locker_name}} </strong>
		<div class="time-container">
			Hace {{all.time_elapsed}} minutos
		</div>
	</div>
	<div class="text-container" *ngIf="all.id_topic_audit === 38">
		El paquete <strong> {{all.tracking_number}} </strong> ha sido recogido de <strong> {{all.locker_name}} </strong>
		<div class="time-container">
			Hace {{all.time_elapsed}} minutos
		</div>
	</div>
	<div class="text-container" *ngIf="all.id_topic_audit === 39">
		El paquete <strong> {{all.tracking_number}} </strong> ha sido recogido por cliente
		<div class="time-container">
			Hace {{all.time_elapsed}} minutos
		</div>
	</div>
	<div class="text-container" *ngIf="all.id_topic_audit === 40">
		El paquete <strong> {{all.tracking_number}} </strong> esta en proceso de devolver a origen
		<div class="time-container">
			Hace {{all.time_elapsed}} minutos
		</div>
	</div>
	<div class="text-container" *ngIf="all.id_topic_audit === 41">
		El paquete <strong> {{all.tracking_number}} </strong> ha sido devuelto
		<div class="time-container">
			Hace {{all.time_elapsed}} minutos
		</div>
	</div>
	<div class="text-container" *ngIf="all.id_topic_audit === 42">
		El paquete <strong> {{all.tracking_number}} </strong> ha entrado a proceso legal
		<div class="time-container">
			Hace {{all.time_elapsed}} minutos
		</div>
	</div> -->
</div>
