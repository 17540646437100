<!--? CONTAINER -->
<div class="container-grid-locker">
	<!-- LOCKER GRID -->
	<div fxLayout="column" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="center center">
		<!--  -->
		<div fxLayout="row" fxLayoutGap="0rem" fxLayoutAlign="center center" class="container-locker" [style.height.px]="alturaHijo">
			<!--  -->
			<div class="module_lockers" *ngFor="let lockers_array of module">
				<!--  -->
				<div *ngFor="let locker of lockers_array.locker.template_module" fxFlex="{{ (locker[0].class == 'LOCKER_BLOCK')  ?  '0.6rem' : '5.5rem'}}" fxFlex.sm="{{ (locker[0].class == 'LOCKER_BLOCK') ?  '0.6rem' : '5.2rem'}}" fxFlex.xs="{{ (locker[0].class == 'LOCKER_BLOCK') ?  '0.6rem' : '5.2rem'}}">
					<!--  -->
					<mat-grid-list class="boxes" cols="1" rowHeight="20.6992px"	gutterSize="4px">
						<!--  -->
						<mat-grid-tile 	*ngFor="let d of locker"
										[colspan]="d.cols"
										[rowspan]="d.rows"
										class="{{d.class}} {{ (d.class === 'LOCKER_CERRADO' && select_compartment) ? 'select':'' }}"
										[matTooltipClass]="safari?'safari-tip':'grid-tip'"
										matTooltipPosition="above"
										[matTooltip]="itemToolInfo(d)"
										(click)="onSelectCompartment($event, d)">

						<!-- <mat-grid-tile *ngFor="let d of locker" [colspan]="d.cols" [rowspan]="d.rows" class="{{d.class}} {{ (d.class === 'LOCKER_CERRADO' && select_compartment) ? 'select':'' }}" [matTooltipClass]="safari?'safari-tip':'grid-tip'" matTooltipPosition="above" [matTooltip]="itemToolInfo(d)" (click)="(d.class=='LOCKER_CERRADO' && select_compartment) ? onSelectCompartment($event, d) : ''"> -->
							<!--  -->
							<div *ngIf="d.class != 'SCREEN' && d.class != 'LOCKER_BLOCK'" class="number_locker">
								{{d.text}}
							</div>
							<div *ngIf="d.rows == 5 && d.class == 'SCREEN'">
								<div class="screen-styles" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="5rem">
									<div class="scree-container">
										<img [src]="imgScreen" width="100%" height="58px" (click)="viewScreenShot(img)" alt="imagen locker">
									</div>
									<div style="height: 2px; width: 2px; position: absolute; border: solid 1px var(--color-locker-stroke); background: var(--color-locker-stroke);top: 35%; left: 20%;"></div>
									<div style="height: 4px; width: 4px; position: absolute; border: solid 1px var(--color-locker-stroke); background: var(--color-locker-stroke);top: 45%; left: 16%;"></div>
									<div style="height: 8px; width: 11px; position: absolute; border: solid 1px var(--color-locker-stroke); background: var(--color-locker-stroke);top: 55%; left: 12%;"></div>
								</div>
							</div>
						</mat-grid-tile>
					</mat-grid-list>
				</div>
			</div>
		</div>
	</div>

	<!-- LEYENDS -->
	<div class="grid-leyends">

		<!--? AVAILABLE --------------->
		<span class="txt-status">
			<div class="circle-available"></div>
			{{ 'lockers.available_door' | translate }} ({{tt_available}})
		</span>

		<!--? OCCUPIED --------------->
		<span class="txt-status">
			<div class="circle-occupied"></div>
			{{ 'lockers.busy_door' | translate }} ({{tt_occupied}})
		</span>

		<!--? DEFECTIVE --------------->
		<span class="txt-status">
			<div class="circle-defective"></div>
			{{ 'lockers.fail_door' | translate }} ({{tt_damaged}})
		</span>

		<!--? OPEN --------------->
		<span class="txt-status">
			<div class="circle-open"></div>
			{{ 'lockers.door_open' | translate }} ({{tt_open}})
		</span>

		<!--? EXPIRED --------------->
		<span class="txt-status">
			<div class="circle-expired"></div>
			{{ 'lockers.expired' | translate }} ({{tt_expired}})
		</span>

		<!--? SCHEDULE --------------->
		<span class="txt-status">
			<div class="circle-schedule"></div>
			{{ 'lockers.tt_schedule' | translate }} ({{tt_schedule}})
		</span>
	</div>
</div>
