import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
	selector: 'app-file-data-estatus',
	templateUrl: './file-data-estatus.component.html',
	styleUrls: ['./file-data-estatus.component.scss']
})
export class FileDataEstatusComponent implements OnInit, ICellRendererAngularComp {
	data: any;
	constructor() { }
	agInit(params: ICellRendererParams): void {
		this.data = params.data;
		console.log(this.data);
	}
	refresh(params: ICellRendererParams) {
		return false;
	}
	ngOnInit(): void {
	}

}
