import { Component, OnInit } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { DialogStoreDetailComponent } from '../dialogs/dialog-store-detail/dialog-store-detail.component';
import { MatDialog } from '@angular/material/dialog';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Router } from '@angular/router';

@Component({
  selector: 'app-link-store-detail',
  templateUrl: './link-store-detail.component.html',
  styleUrls: ['./link-store-detail.component.scss']
})
export class LinkStoreDetailComponent implements OnInit,ICellRendererAngularComp {
	data;
	value;

	constructor(
		private dialog: MatDialog,
		private router : Router
	) { }

	ngOnInit() {
	}
	agInit(params: ICellRendererParams): void {
		this.data = params.data
		this.value = this.data.store_name;
	}
	refresh(params: ICellRendererParams) {
		return false;
	}
	linkToStoreDetail() {
		this.router.navigateByUrl('/superadmin/store/'+this.data.id_store)
	}
}
