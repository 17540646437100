<!-- ? CONTAINER MAIN -->
<div class="cont_main">
	<div	[ngClass]="{
			'fadeAnimationIn'	: dataService.overviewChecked,
			'fadeAnimationOut'	: !dataService.overviewChecked }">

		<!-- ? TABLE CONTAINER -->
		<div class="table_cont">
			<!-- ? BLOCK 1 | INPUT & BTN EXPORT -->
			<div class="block_1">
				<!-- ? INPUT CONTAINER-->
				<div class="input_main_cont">
					<input
						[(ngModel)]="filterText"
						type="text"
						class="input_main"
						(keyup)="onFilterTextChange($event)"
						placeholder="{{'dashboard.search_user' | translate}}">
					<!-- ICON SEARCH -->
					<img class="icon_sm" src="../../../../../../assets/icons/icon-search.svg">
				</div>
				<!-- ? BTN EXPORT -->
				<button-export (click)="onExport()" [isDisabled]="rowData.length>0?false:true"></button-export>
			</div>
			<!-- ? BLOCK 2 | TABLE -->
			<ag-grid-angular
				#agGrid
				class="ag-theme-alpine"
				[gridOptions]="this.gridOptions"
				[rowData]="this.rowData"
				[columnDefs]="this.columnDefs"
				[quickFilterText]="this.filterText"
				[animateRows]="true"
				(gridReady)="onGridReady($event)"
				(displayedColumnsChanged)="onDisplayedColumnsChanged($event)"
				(sortChanged)="onSortChanged($event)">
			</ag-grid-angular>
		</div>

	</div>

</div>
