import { Component,Input,OnChanges,OnInit,SimpleChanges,ViewChild} from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
// ApexCharts
import { ApexAxisChartSeries,ApexChart,ChartComponent, ApexDataLabels, ApexPlotOptions, ApexResponsive, ApexXAxis, ApexLegend, ApexFill, ApexStroke } from 'ng-apexcharts';
@Component({
	selector: 'locker-operation',
	templateUrl: './locker-operation.component.html',
	styleUrls: ['./locker-operation.component.scss']
})
export class LockerOperationComponent implements OnInit, OnChanges {
	@ViewChild('chart') chart: ChartComponent;
	public dataChartUseLockerOperation: Partial<ChartOptions>;
	// DATES
	@Input() periodBase: string;
	@Input() periodComp: string;
	@Input() dataBaseRecolect: number[] = [];
	@Input() dataBaseDelivery: number[] = [];
	@Input() dataCompRecolect: number[] = [];
	@Input() dataCompDelivery: number[] = [];
	@Input() dataBase: number[] = [];
	@Input() dataComp?: number[] = [];
	@Input() dataDays: string[] = [];
	@Input() dataDaysComp: string[] = [];
	@Input() addComp: boolean;
	base = [];
	comp = [];
	days = [];
	delivery = [];
	recolect = [];

	recolect2 = [30, 40, 45, 50, 49, 60, 70, 91, 125];
	days2 = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];
	chartOptions2: Partial<ChartOptions> = {
		series: [{
		  name: 'Series 1',
		  data: this.recolect2
		}],
		chart: {
		  type: 'bar',
		  height: 300,
		},
		dataLabels: {
			enabled: true,
		},
		plotOptions: {
		  bar: {
			horizontal: false
		  }
		},
		xaxis: {
		  categories: this.days2
		},
		legend: {
			show: true,
		  position: 'bottom',
		  horizontalAlign: 'center',
		  containerMargin: {
			top: 30
		  }
		}
	  };


	constructor(private translate: TranslateService) {
		translate.setDefaultLang(
			localStorage.getItem('language').toLowerCase()
		);

		
	}
	ngOnInit() {
		this.getData();
		this.loadDataChart();
		this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
			this.translate.use(event.lang);
			this.getData();
			this.loadDataChart();
		})


		// const chart = new ApexCharts(document.querySelector('#chart'), this.chartOptions);
		// chart.render();
	}
	ngOnChanges(changes: SimpleChanges) {
		this.getData();
		this.loadDataChart();
		if(changes.periodComp !== undefined){
			this.getData();
			this.loadDataChart();
		}
		if(changes.addComp !== undefined){
			this.getData();
			this.loadDataChart();
		}
	}
	getData(){
		this.days = [];
		this.delivery = [];
		this.recolect = [];
		this.dataBaseDelivery.forEach((res) => {
			this.delivery.push(res);
		});
		this.dataBaseRecolect.forEach((res) => {
			this.recolect.push(res);
		});
		this.dataDays.forEach((res) => {
			this.days.push(res);
		});
		if(this.addComp === true){
			this.delivery.push(0);
			this.recolect.push(0);
			this.days.push(' ');
			if (this.dataCompRecolect.length > 0 &&	this.dataCompDelivery.length > 0 &&	this.dataDaysComp.length > 0) {
				this.dataCompRecolect.forEach((res) => {
					this.recolect.push(res);
				});
				this.dataCompDelivery.forEach((res) => {
					this.delivery.push(res);
				});
				this.dataDaysComp.forEach((res) => {
					this.days.push(res);
				});
			}
		}
	}
	private loadDataChart() {
		this.dataChartUseLockerOperation = {
			series: [
				// {
				// 	name: this.translate.instant('overview.orderDelivered'),
				// 	data: this.delivery,
				// },
				{
					name: this.translate.instant('overview.orderDelivered'),
					data: this.recolect,
				},
			],
			chart: {
				type: 'bar',
				height:300,
				stacked: true,
				toolbar: {
					show: true,
					tools:{
						download:`
							<div class="icon_chevron">
								<p class="caption_c2 font_medium color_primary">
									${this.translate.instant('buttons.export')}
								</p>
								<img src="../../../../../../assets/icons/icon-chevron-down.svg">

							</div>
						`,
						pan:false,
						reset:false,
						zoom:false,
						zoomin:false,
						zoomout:false,
						selection:true
					},
				},
				zoom: {
					enabled: true,
				},
			},
			plotOptions: {
				bar: {
					horizontal: false,
				},
			},
			xaxis: {
				labels: {
					show: true,
				},
				type: 'category',
				categories: this.addComp ? this.days.map((day) => this.translate.instant('day.'+day)) : this.days.map((day) => this.translate.instant('day.'+day)),
			},
			legend: {
				// show: true,
				// position: 'bottom',
				// offsetY: 0,
				// markers: {
				// 	fillColors: ['#18345D', '#0082CB'],
				// },
				    position: "bottom",
					horizontalAlign: "right",
					floating: true,
					offsetY: -25,
					offsetX: -5
			},
			dataLabels: {
				enabled: true,
			},
			fill: {
				opacity: 1,
				colors: ['#18345D', '#0082CB'],
			},
		};
	}

	openToolbarMenu() {
		this.chart.chart.toolbar.show = true;
	}
}

export type ChartOptions = {
	series: ApexAxisChartSeries;
	chart: ApexChart;
	dataLabels: ApexDataLabels;
	plotOptions: ApexPlotOptions;
	responsive: ApexResponsive[];
	xaxis: ApexXAxis;
	legend: ApexLegend;
	fill: ApexFill;
	stroke: ApexStroke;
};
