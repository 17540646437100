// ANGULAR
import { Component,EventEmitter,Input,OnInit,Output} from '@angular/core';
import {  Router } from '@angular/router';
import { EncryptDataService } from 'src/app/core/security/encypt-data.service';
// import { NotificationsService } from '../../services/notifications.service';
@Component({
	selector: 'notifications',
	templateUrl: './notifications.component.html',
	styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
	userType:number;
	@Input() orders: any;
	@Input() users: any;
	@Input() all;
	@Input() statusOrder: string;
	@Input() sizeOrders: number;
	@Input() sizeUser: number;
	@Input() sizeAll: number;
	@Output() clean: EventEmitter<void> = new EventEmitter<void>();
	@Output() cleanItem: EventEmitter<void> = new EventEmitter<void>();
	@Output() orderSelected: EventEmitter<string> = new EventEmitter<string>();
	// Set page lenght
	public pageOrdersLenght: number = 6;
	public pageUsersLenght: number = 6;
	public pageAllLenght: number = 6;
	// Set pagination
	public pageOrders: number = 0;
	public pageUsers: number = 0;
	public pageAll: number = 0;
	// See all
	public markAllread: boolean = true;
	markNotificationsRead:boolean=false;
	dontShowMoreOrders=false;
	dontShowMoreUsers=false;
	dontShowMoreAll=false;
	selectedContent					: any;
	selectedTable					: string ="Ordenes";

	dataLocal:any;
	content_table		= [
		{
			"name":"Ordenes",
			"text":"notifications.switchContent.orders",
			"quantity":0,
			"showquantity":true,
			"selected":true
		},
		{
			"name":"Usuarios",
			"text":"notifications.switchContent.users",
			"quantity":0,
			"showquantity":true,
			"selected":false
		},
		{
			"name":"Todas",
			"text":"notifications.switchContent.all",
			"quantity":0,
			"showquantity":true,
			"selected":false
		}
	];
	handleContentSelection(selectedItem: any) {
        this.selectedContent = selectedItem;
		this.selectedTable = selectedItem.name;
    }


	markRead() {
		this.clean.emit();
		this.content_table[0].quantity = 0;
		this.content_table[1].quantity = 0;
		this.content_table[2].quantity = 0;
		this.all.forEach(obj => {
			obj.read=true;
		})
		let allNotifications=localStorage.getItem("lstNotifications") ? JSON.parse(localStorage.getItem("lstNotifications")):[]
		allNotifications.forEach((item) => {
			item.read=true;
		});
		localStorage.setItem("lstNotifications",JSON.stringify(allNotifications));
	}
	markReadItem(item:any) {
		if(item.read===false){
			item.read=true;

			let allNotifications=localStorage.getItem("lstNotifications") ? JSON.parse(localStorage.getItem("lstNotifications")):[]
			allNotifications.forEach((element) => {
				if (element.isOrder) {
					if(element.id_service === item.id_service && element.id_topic_audit===item.id_topic_audit  && element.read === false){
						element.read=true;
					}
				}
				else {
					if(element.id_audit_company_employee === item.id_audit_company_employee && element.read === false){
						element.read=true;
					}
				}
			});
			localStorage.setItem("lstNotifications",JSON.stringify(allNotifications));

			if(item.isOrder){
				this.content_table[0].quantity--;
			}
			else{
				this.content_table[1].quantity--;
			}
			this.content_table[2].quantity--;
			this.cleanItem.emit();
		}
	}

	loadMoreOrders() {
		// Verifica si hay más órdenes disponibles antes de cargar más
		if (this.pageOrdersLenght < this.sizeOrders) {
			this.pageOrdersLenght += 5;
			this.dontShowMoreOrders = true;
		} else {
			this.dontShowMoreOrders = true;
		}
	}
	loadMoreUsers() {
		if (this.pageUsersLenght < this.sizeUser) {
			this.pageUsersLenght += 5;
			this.dontShowMoreUsers = true;
		}
		else{
			this.dontShowMoreUsers = true;
		}

	}
	loadMoreAll() {
		this.pageAllLenght += 5;
		if(this.pageAllLenght > 18){
			this.dontShowMoreAll = true
		}
		else{
			this.dontShowMoreAll = false
		}
	}
	// NOTIFICATIONS
	order: string = '625-894';
	locker: string = 'OXXOMTY61565';
	time: number = 5;
	tabOrderSeen: boolean = true;
	tabUserSeen: boolean = true;
	tabAllSeen: boolean = true;
	orderSeen() {
		this.tabOrderSeen = false;
	}
	userSeen() {
		this.tabUserSeen = false;
	}
	allSeen() {
		this.tabAllSeen = false;
	}

	constructor(
		private router: Router,
		private securityLocalStorage: EncryptDataService,
		// public notificationsService: NotificationsService
	) {}

	ngOnInit(){
		this.userType = parseInt(localStorage.getItem('user_type'));
		const dataLocal = JSON.parse(this.securityLocalStorage.decryptData(localStorage.getItem('data')));
		this.userType=Number(dataLocal.result.id_user_type);

		this.content_table[0].quantity = this.sizeOrders;
		this.content_table[1].quantity = this.sizeUser;
		this.content_table[2].quantity = this.sizeOrders + this.sizeUser;
	}
	ngOnChanges() {

	}
	openOrder(item) {
		if(this.userType === 7){
			this.router.navigateByUrl('/warehouse/orders/' + item.tracking_number);
			if (this.router.url !== '/warehouse/orders/' + item.tracking_number) {
				this.sizeOrders--;
				localStorage.setItem('searchOrderNotification', item.tracking_number);
				this.router.navigateByUrl('/warehouse/orders/' + item.tracking_number)
			}
		}
		else if(this.userType === 9){
			this.router.navigateByUrl('/l-retail/orders/' + item.tracking_number);
			if (this.router.url !== '/l-retail/orders/' + item.tracking_number) {
				this.sizeOrders--;
				localStorage.setItem('searchOrderNotification', item.tracking_number);
				this.router.navigateByUrl('/l-retail/orders/' + item.tracking_number)
			}
		}

	}
	openUser(item){
	}
	navigate() {
		this.router.navigateByUrl('/');
	}
	goToNotifications(){
		if(this.userType === 6) {
			this.router.navigateByUrl('/warehouse/notifications')
		}
		else if(this.userType === 7) {
			this.router.navigateByUrl('/vivipost/notifications')
		}
		if(this.userType === 9){
			this.router.navigateByUrl('/superadmin/notifications')
		}

	}
}
