import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { AgGridAngular } from 'ag-grid-angular';
import { CellClickedEvent, ColDef, GridOptions } from 'ag-grid-community';
import { utils } from 'xlsx';
import { AdminClientService } from 'src/app/core/http/admin-client.service';
import { MatDatepicker } from '@angular/material/datepicker';
import { Calendar } from 'primeng/calendar';
import * as XLSX from 'xlsx';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DateTextTablesComponent } from 'src/app/shared/components/date-text-tables/date-text-tables.component';
import { FilterService } from 'src/app/core/http/FilterDataServiceOxxoToOxxo.service';
import { AdminGuard } from 'src/app/core/guards/admin.guard';
import { EstatusTableOrderAdminComponent } from '../../estatus-table-order-admin/estatus-table-order-admin.component';
import { DialogOrderDetailComponent } from '../../dialog-order-detail/dialog-order-detail.component';
import { DataService } from 'src/app/core/services/data.service';
import { EstatusOxxoToOxxoComponent } from '../../tables-oxxo-to-oxxo/estatus-oxxo-to-oxxo/estatus-oxxo-to-oxxo.component';
import { ActivatedRoute } from '@angular/router';
import { DialogOxxoToOxxoDetailComponent } from '../../dialog-oxxo-to-oxxo-detail/dialog-oxxo-to-oxxo-detail.component';
import { EncryptDataService } from 'src/app/core/security/encypt-data.service';

@Component({
  selector: 'expired-table',
  templateUrl: './expired-table.component.html',
  styleUrls: ['./expired-table.component.scss']
})
export class ExpiredTableComponent implements OnInit  {
	public columnDefsExpired: ColDef[];
	public defaultColDefExpired: ColDef = {
		sortable: true,
		filter: true,
	};
	private gridApi;
	public rowDataExpired = [];
	@ViewChild(AgGridAngular) agGrid!: AgGridAngular;
	@ViewChild('datepicker') datepicker: MatDatepicker<Date>;
	@ViewChild(EstatusTableOrderAdminComponent)
	estatusOrderAdminComponent: EstatusTableOrderAdminComponent;
	@ViewChild('calendar') calendar: Calendar;
	supportLanguages = ['es', 'en'];
	InitialDate = new Date();
	FinalDate = new Date();
	res;
	completedDataCompleted = [];
	countCompleted = 0;
	display = false;
	minDate: Date;
	maxDate: Date;
	orderSelectToExtendDate: any;
	filterText: string = '';
	gridOptions: GridOptions;
	dataTemporal = [];
	idLocker: string;
	filters:boolean=false;
	mode:string;
	url:string;
	idUserType;
	rowDataFilter;
	@Input() rowData;
	@Input() filteredData;
	@Input() rangeDates: Date[];
	@Output() rangeDatesChange = new EventEmitter<Date[]>();
	buttonExport:boolean=true;

	constructor(
		private translate: TranslateService,
		private adminService: AdminClientService,
		private toastr: ToastrService,
		public dataService : DataService,
		private filterService	: FilterService,
		private elementRef		: ElementRef,
		private route: ActivatedRoute,
		private securityLocalStorage: EncryptDataService,
		private matdialog: MatDialog)
		{
	}
	obtenerIdUserType(){
		let dataLocal = JSON.parse(this.securityLocalStorage.decryptData(localStorage.getItem('data')))
		return parseInt('id_user_type ->',dataLocal.result.id_user_type);
	}
	determineCellClass(params: any) {
		const id_user_type = this.obtenerIdUserType();
		return (id_user_type !== 10 && id_user_type !== 11) ? 'expiredClassLink' : 'expiredClass';
	}
	onLockerIDCellClicked(params: any) {
		const id_user_type = this.obtenerIdUserType();
		if(id_user_type !== 10 && id_user_type !== 11){
			this.openModalUserDetail(params);
		}
		else {
		  // Otra lógica para otros tipos de usuarios, si es necesario
			return null;
		}
	}
	renderEmailCell(params: any) {
		const maxLength = 100;
		const value = params.value;
		if (value.length > maxLength) {
			return value.slice(0, maxLength) + '...';
		}
		return value;
	}
	ngOnInit() {
		this.rowDataExpired =[];
		if(this.filteredData === undefined){
			this.filteredData = [];
		}
		let dataLocal = JSON.parse(this.securityLocalStorage.decryptData(localStorage.getItem('data')))
		this.idUserType = dataLocal.result.id_user_type
		this.mode = localStorage.getItem('mode');
		this.translate.use(localStorage.getItem('language').toLowerCase());
		this.url = this.route.snapshot.url.join('/');
		this.updateTable();
		// this.addEventRangeDates();
		this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
			this.translate.get([
					'table-general.order',
					'table-expired.tracking',
					'table-expired.lockerID',
					'table-expired.email',
					'table-expired.employeeName',
					'table-expired.door',
					'table-expired.status',
					'table-expired.inLocker',
					'table-expired.expiredDate',
					'table-expired.retiredLocker'
			]).subscribe((t: any) => {
				const id_user_type = this.obtenerIdUserType();
				this.columnDefsExpired = [
					{
						headerName: t['table-expired.tracking'],
						field: 'tracking',
						width:150,
						sortable: true,
						autoHeight: true,
						headerClass:'borderHeaderExpired',
						cellClass:this.determineCellClass.bind(this),
						onCellClicked: this.onLockerIDCellClicked.bind(this),
						suppressMovable	: true,
					},
					{
						headerName: t['table-expired.model'],
						field: 'model',
						width: 150,
						sortable: true,
						autoHeight: true,
						cellClass: 'expiredClass',
						hide: this.url === 'orders' ? true : false,
						headerClass:'borderHeaderGeneral',
						suppressMovable	: true,
					},
					{
						headerName: t['table-expired.email'],
						field: 'email',
						width: this.url === 'orders' ? 525 : 375,
						sortable: true,
						autoHeight: true,
						cellClass:'expiredClass',
						headerClass:'borderHeaderExpired',
						cellRenderer:  this.renderEmailCell.bind(this),
						suppressMovable	: true,
					},
					{
						headerName: t['table-expired.door'],
						field: 'doorNumber',
						width:150,
						sortable: true,
						autoHeight: true,
						headerClass:'borderHeaderExpired',
						cellClass:'expiredClass',
						suppressMovable	: true,
					},
					{
						headerName: t['table-expired.status'],
						field: 'status',
						width:200,
						sortable: true,
						autoHeight: true,
						cellRenderer: EstatusTableOrderAdminComponent,
						headerClass:'borderHeaderExpired',
						cellClass:'expiredClass',
						onCellClicked: ($event) => this.openCalendar($event),
						suppressMovable	: true,
					},
					{
						headerName: t['table-expired.inLocker'],
						field: 'inLocker',
						width: 200,
						sortable: true,
						autoHeight: true,
						sort: 'asc',
						cellRenderer: DateTextTablesComponent,
						headerClass:'borderHeaderExpired',
						cellClass:'expiredClass',
						suppressMovable	: true,
					},
					{
						headerName: t['table-expired.expiredDate'],
						field: 'expiration_date',
						width: 200,
						sortable: true,
						autoHeight: true,
						cellRenderer: DateTextTablesComponent,
						headerClass:'borderHeaderExpired',
						cellClass:'expiredClass',
						suppressMovable	: true,
					},
					{
						headerName: t['table-expired.retiredLocker'],
						field: 'package_pickup_date',
						width: 132,
						sortable: true,
						autoHeight: true,
						cellRenderer: DateTextTablesComponent,
						headerClass:'borderHeaderExpired',
						cellClass:'expiredClass',
						suppressMovable	: true,
					},
					{
						headerName: t['table-expired.expiredDays'],
						field: 'expiration_days',
						width: 150,
						sortable: true,
						autoHeight: true,
						headerClass:'borderHeaderExpired',
						cellClass:'expiredClass',
						suppressMovable	: true,
					}
				];
			});
		});
		this.minDate = new Date();
		this.minDate.setDate(this.minDate.getDate() + 1); // día mínimo es mañana
		this.maxDate = new Date();
		this.maxDate.setDate(this.maxDate.getDate() + 2);
		// this.rowDataExpired = this.rowData.filter((item)=>{
		// 	return (
		// 		item.id_status_code_package === 32  &&
		// 		item.status_package === 14 || item.status === 59
		// 	);
		// })
		if(this.rowDataExpired.length === 0){
			this.buttonExport =false;
		}
		else{
			this.buttonExport =  true;
		}


		let today = new Date();
		this.FinalDate = new Date("2023-06-31");
		this.FinalDate = new Date(today.getFullYear() + '-' + (today.getMonth()+1) +'-'+(today.getDate()));
		this.InitialDate = new Date(this.FinalDate);
		if((today.getDate()) === 31){
			this.InitialDate.setDate(0);
		}
		else{
			this.InitialDate.setMonth(this.InitialDate.getMonth() -1);
		}
		this.rangeDates = [this.InitialDate, this.FinalDate];
		this.idLocker = localStorage.getItem('id_locker');

	}
	ngOnChanges(): void {
		this.rowDataExpired = this.rowData.filter((item)=>{
			return (
				(item.id_status_code_package === 32  &&
				item.status === 14) ||
				item.status === 59
			);
		});
		if(this.rowDataExpired.length === 0){
			this.buttonExport =false;
		}
		else{
			this.buttonExport =  true;
		}
	}
	updateTable() {
		const id_user_type = this.obtenerIdUserType();
		this.gridOptions = {
			columnDefs: [
				{
					headerName: this.translate.instant('table-expired.tracking'),
					field: 'tracking',
					width:150,
					sortable: true,
					autoHeight: true,
					headerClass:'borderHeaderExpired',
					cellClass:this.determineCellClass.bind(this),
					onCellClicked: this.onLockerIDCellClicked.bind(this),
					suppressMovable	: true,
				},
				{
					headerName: this.translate.instant('table-expired.model'),
					field: 'model',
					width: 150,
					sortable: true,
					autoHeight: true,
					cellClass: 'expiredClass',
					hide: this.url === 'orders' ? true : false,
					headerClass:'borderHeaderGeneral',
					suppressMovable	: true,
				},
				{
					headerName: this.translate.instant('table-expired.email'),
					field: 'email',
					width: this.url === 'orders' ? 525 : 375,
					sortable: true,
					autoHeight: true,
					cellClass:'expiredClass',
					headerClass:'borderHeaderExpired',
					cellRenderer:  this.renderEmailCell.bind(this),
					suppressMovable	: true,
				},
				{
					headerName: this.translate.instant('table-expired.door'),
					field: 'doorNumber',
					width:150,
					sortable: true,
					autoHeight: true,
					headerClass:'borderHeaderExpired',
					cellClass:'expiredClass',
					suppressMovable	: true,
				},
				{
					headerName: this.translate.instant('table-expired.status'),
					field: 'status',
					width:200,
					sortable: true,
					autoHeight: true,
					cellRenderer: EstatusTableOrderAdminComponent,
					headerClass:'borderHeaderExpired',
					cellClass:'expiredClass',
					onCellClicked: ($event) => this.openCalendar($event),
					suppressMovable	: true,
				},
				{
					headerName: this.translate.instant('table-expired.inLocker'),
					field: 'inLocker',
					width: 200,
					sortable: true,
					autoHeight: true,
					sort: 'asc',
					cellRenderer: DateTextTablesComponent,
					headerClass:'borderHeaderExpired',
					cellClass:'expiredClass',
					suppressMovable	: true,
				},
				{
					headerName: this.translate.instant('table-expired.expiredDate'),
					field: 'expiration_date',
					width: 200,
					sortable: true,
					autoHeight: true,
					cellRenderer: DateTextTablesComponent,
					headerClass:'borderHeaderExpired',
					cellClass:'expiredClass',
					suppressMovable	: true,
				},
				{
					headerName: this.translate.instant('table-expired.retiredLocker'),
					field: 'package_pickup_date',
					width: 132,
					sortable: true,
					autoHeight: true,
					cellRenderer: DateTextTablesComponent,
					headerClass:'borderHeaderExpired',
					cellClass:'expiredClass',
					suppressMovable	: true,
				},
				{
					headerName: this.translate.instant('table-expired.expiredDays'),
					field: 'expiration_days',
					width: 150,
					sortable: true,
					autoHeight: true,
					headerClass:'borderHeaderExpired',
					cellClass:'expiredClass',
					suppressMovable	: true,
				}
			],
			animateRows: true,
			pagination: true,
			paginationPageSize: 10,//this.selectedCity,
			paginationAutoPageSize: true,
			headerHeight:59,
			rowHeight:59,
			rowClass:'rowGeneralClass',
			scrollbarWidth:0,
			alwaysShowVerticalScroll:false,
			alwaysShowHorizontalScroll:false,
			overlayNoRowsTemplate:
			`<div>
				<div>
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="bi bi-info-circle icon-no-info" viewBox="0 0 16 16">
						<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
						<path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
					</svg>
				</div>
				<div class="labelNoRegistros">
					Aun no hay registros, <br>
					intentalo mas tarde
				</div>
			</div>`
		}
	}
	onGridReady(params: any) {
		this.gridApi = params;
	}
	onCellClicked(e: CellClickedEvent): void {
		if(AdminGuard){
			if (e.colDef.field === 'status') {
				this.display = true;
				this.orderSelectToExtendDate = e.data;
			}
		}
		else{
			this.display =false;
		}

	}
	addEventRangeDates() {
		this.rangeDatesChange.emit(this.rangeDates);
	}
	showDate: Date;
	showDateFinal: Date;
	fechasByMonth: string;

	DownloadExcel() {
		let headerExcel = [
			'package',
			'tracking',
			'lockerID',
			'email',
			'employeeID',
			'employeeName',
			'door',
			'doorSize',
			'status',
			'inLockerDate',
			'inLockerTime',
			'registrationDateExpired',
			'registrationTimeExpired',
		];
		let dataExcel = [];
		this.completedDataCompleted.forEach((e: any) => {
			let info = {
				package: e.order,
				tracking: e.tracking,
				lockerID: e.lockerID,
				email: e.email,
				employeeID: e.employeId,
				employeeName: e.nameEmploye,
				door: e.doors,
				doorSize: e.compartment,
				status: e.status,
				inLockerDate: e.inLocker.slice(0, 10),
				inLockerTime: e.inLocker.slice(11, 19),
				registrationDateExpired: e.registrationDateExpired.slice(0, 10),
				registrationTimeExpired: e.registrationDateExpired.slice(11,19),
			};
			dataExcel.push(info);
		});
		const worksheet = utils.json_to_sheet(dataExcel, {
			header: headerExcel,
		});
		const workbook = utils.book_new();
		utils.book_append_sheet(workbook, worksheet, 'Data');
	}

	centeredCellRenderer(params) {
		const cellValue = params.value;
		const cellDiv = document.createElement('div');
		cellDiv.style.display = 'flex';
		cellDiv.style.alignItems = 'center';
		cellDiv.style.justifyContent = 'center';
		cellDiv.style.height = '100%';
		cellDiv.innerText = cellValue;
		return cellDiv;
	}

	openModalUserDetail(event) {
		let model = event.data.model
		if(model === "Carrier"){
			const dialogRef = this.matdialog.open(DialogOrderDetailComponent, {
				width: '1304px',
				height: 'auto',
				data: event.data,
			});
			dialogRef.afterClosed().subscribe((result) => {});
		}
		else if (model === "OXXO TO OXXO"){
			const dialogRef = this.matdialog.open(DialogOxxoToOxxoDetailComponent, {
				width: '1304px',
				height: 'auto',
				data: event.data,

			});
			dialogRef.afterClosed().subscribe((result) => {});
		}

	}

	async extenderFechas(calendar) {
		let dateSelect = new Date(calendar.value);
		let month =	dateSelect.getMonth().toString().length == 1 ? '0' + (dateSelect.getMonth() + 1) : dateSelect.getMonth() + 1;
		let day = dateSelect.getDate().toString().length == 1 ? '0' + dateSelect.getDate() : dateSelect.getDate();
		let d = dateSelect.getFullYear() + '-' + month + '-' + day;
		let dataSendUpdate = {
			language: localStorage.getItem('language').toString().toLowerCase(),
			id_company: parseInt(localStorage.getItem('id_company')),
			id_package_code: this.orderSelectToExtendDate.id_package_code,
			date_update: d,
		};
		let res: any = await this.adminService.updateExpirationDate(dataSendUpdate);
		if (res.mensaje_return) {
			this.display = false;
			this.toastr.success('la entrega santifactoriamente','Se ha extendindo',
				{
					toastClass: 'toast-extendida',
					timeOut: 5000,
					positionClass: 'toast-bottom-center',
				}
			);
		}
	}
	cerrarExtender() {
		this.display = false;
		this.orderSelectToExtendDate = [];
	}
	onFilterTextChange(event) {
		this.filterText = event.target.value;
		event.api.setQuickFilter(this.filterText);
		let api = event.api;
		let temporal = [];
		event.api.forEachNodeAfterFilter((node) => {
			temporal.push(node.data);
		});
		this.filteredData = temporal;
	}
	onExport() {
		const dayInitial = this.InitialDate.getDate() < 10 ? '0' + this.InitialDate.getDate() : this.InitialDate.getDate();
		const monthInitial = this.InitialDate.getMonth() + 1 < 10 ? '0' + (this.InitialDate.getMonth() + 1)	: this.InitialDate.getMonth() + 1;
		const yearInitial = this.InitialDate.getFullYear();
		const dayFinal = this.FinalDate.getDate() < 10 ? '0' + this.FinalDate.getDate() : this.FinalDate.getDate();
		const monthFinal = this.FinalDate.getMonth() + 1 < 10 ? '0' + (this.FinalDate.getMonth() + 1) : this.FinalDate.getMonth() + 1;
		const yearFinal = this.FinalDate.getFullYear();
		const fileName = 'Expired'+dayInitial+monthInitial+yearInitial+'_'+dayFinal+monthFinal+yearFinal+'.xlsx';
		// Configuración para exportar en formato Excel
		const excelParams = {
			fileName: fileName,
			sheetName: 'Expired',
			columnWidths: [30, 20, 20, 20], // Ancho de las columnas en caracteres
		};
		// Obtener los datos de la tabla
		if (this.filteredData.length > 0) {
			const Data = this.filteredData || [];
			const excelData = Data.map((row) => {
				const { lockerID, id_package,id_package_code,id_status_code_package,status_package,qr_order,resultNotification,IMAGE,  ...rowDataWithoutFields} = row;
				return rowDataWithoutFields;
			});
			// Crear el libro de Excel y agregar la hoja con los datos
			const wb = XLSX.utils.book_new();
			const ws = XLSX.utils.json_to_sheet(excelData);
			XLSX.utils.book_append_sheet(wb, ws, excelParams.sheetName);
			//   // Descargar el archivo de Excel
			XLSX.writeFile(wb, fileName);
		}
		else if (this.rowData.length > 0 && this.filteredData.length === 0) {
			const Data = this.rowData || [];
			const excelData = Data.map((row) => {
				const { lockerID, id_package,id_package_code,id_status_code_package,status_package,qr_order,resultNotification,IMAGE,  ...rowDataWithoutFields} = row;
				return rowDataWithoutFields;
			});
			//   // Crear el libro de Excel y agregar la hoja con los datos
			const wb = XLSX.utils.book_new();
			const ws = XLSX.utils.json_to_sheet(excelData);
			XLSX.utils.book_append_sheet(wb, ws, excelParams.sheetName);
			//   // Descargar el archivo de Excel
			XLSX.writeFile(wb, fileName);
		}
	}
	openCalendar(e: CellClickedEvent) {
		if (e.data.status_package === 14 &&	e.data.id_status_code_package === 32) {
			this.display = true;
			this.orderSelectToExtendDate = e.data;
		}
		else {
			this.display = false;
		}
	}
	showFilters(){
		this.filters = !this.filters;
		if (this.filters) {
			this.registerClickOutsideListener();
		}
		else {
			this.closeFilters();
		}
	}
	private registerClickOutsideListener() {
		document.addEventListener('click', this.clickOutsideHandler.bind(this));
	}
	private clickOutsideHandler(event: Event) {
		if (!this.elementRef.nativeElement.contains(event.target)) {
			this.closeFilters();
		}
	}

	private closeFilters() {
		this.filters = false;
		document.removeEventListener('click', this.clickOutsideHandler.bind(this));
	}
	isMobileView(): boolean {
		const width = this.elementRef.nativeElement.offsetWidth; // Obtiene el ancho del elemento actual
		return width <= 600; // Puedes ajustar este valor según tus necesidades
	}
}
