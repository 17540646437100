<div class="calendar-container">
    <p-calendar #calendar
        selectionMode="range"
        [readonlyInput]="true"
        [showIcon]="true"
        [(ngModel)]="selectedDates"
        (onSelect)="onSelect($event)"
		(onFocus)="showCalendar()"
        [inputStyle]="{'text-align': 'center'}"
        dateFormat="M dd, yy">
		<ng-template pTemplate="footer" >
			<div class="footer-buttons">
				<app-button-dashboard  (click)="clearSelection()" label="buttons.lastMonth" buttonClass="btn-md-cuaternary">
				</app-button-dashboard>
				<div class="buttons">
					<app-button-dashboard
						[disabled]="selectedDates.length === 0"
						label="buttons.cancel"
						buttonClass="btn-md-secondary"
						(click)="cancelSelection()">
					</app-button-dashboard>
					<app-button-dashboard
						[disabled]="selectedDates.length === 0"
						label="buttons.acept"
						buttonClass="btn-md-primary"
						(click)="acceptSelection()">
					</app-button-dashboard>
				</div>
			</div>
		</ng-template>
    </p-calendar>
</div>
