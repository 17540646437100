<div class="container-fluid">
	<div class="row">
		<div class="col-12 noPaddings">
			<div class="headerClass">
				{{'dialogChangeStatusCarrier.headerTitle' | translate}}
			</div>
		</div>
		<div class="col-12 noPaddings">
			<div class="subHeaderClass">
				{{'dialogChangeStatusCarrier.subHeaderTitle' | translate}}
				<ul>
					<li>
						{{'dialogChangeStatusCarrier.list.point1' | translate}}
					</li>
					<li>
						{{'dialogChangeStatusCarrier.list.point2' | translate}}
					</li>
					<li>
						{{'dialogChangeStatusCarrier.list.point3' | translate}}
					</li>
					<li>
						{{'dialogChangeStatusCarrier.list.point4' | translate}}
					</li>
				</ul>
				{{'dialogChangeStatusCarrier.paragraphSubHeader' | translate}}
			</div>
		</div>
		<div class="col-12 noPaddings">
			<div class="passwordBox">
				<form [formGroup]="validateUserForm">
					<label class="inputLabel">
						{{'dialogChangeStatusCarrier.inputTitle' | translate}}
					</label>
					<input
						formControlName="pass"
						type="password"
						class="inputText"
						(ngModelChange)="modelChangedStatusCarrier($event)">
				</form>
			</div>
		</div>
		<div class="col-12 noPaddings">
			<div class="row">
				<div class="col-4">
					<button class="cancelClass" (click)="closeModal()">
						{{'dialogChangeStatusCarrier.buttonCancel' | translate}}
					</button>
				</div>
				<div class="col-8 desactiveClass">
					<div *ngIf="this.validationUserForm === false">
						<app-button
						[activo]="true"
						btnNombre="dialogChangeStatusCarrier.buttonDesactivate"
						btnType="primaryDangerMedium">
						</app-button>
					</div>
					<div *ngIf="this.validationUserForm === true">
						<app-button
							(click)="desactivarCarrier()"
							[activo]="!this.validationUserForm"
							btnNombre="dialogChangeStatusCarrier.buttonDesactivate"
							btnType="primaryDangerMedium">
						</app-button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
