/**
 * Componente FileUploaderComponent
 * Este componente permite a los usuarios cargar archivos Excel, validar su contenido y mostrar los datos en un modal.
 * Características:
 * - Carga y validación de archivos Excel con formato específico.
 * - Validación de tamaño y tipo de archivo.
 * - Validación de encabezados requeridos en el archivo.
 * - Validación de contenido de las celdas (nombre, apellido, email, teléfono).
 * - Visualización de datos en un modal con posibilidad de aceptar o rechazar la carga.
 */

import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import * as XLSX from 'xlsx';
import { FileDataModalComponent } from '../../file-data-modal/file-data-modal.component';
import { UpdateStoreStatusService } from 'src/app/core/http/update-store-status.service';

@Component({
	selector: 'app-file-uploader',
	templateUrl: './file-uploader.component.html',
	styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent implements OnInit {
	showPopUp: boolean = false;
	showAlert: boolean = false;
	fileSizeIsOver: boolean = false;
	invalidFileType: boolean = false;
	invalidHeaders: boolean = false;
	file: File | null = null;
	progress: number = 0;
	@Input() requiredHeaders: string[] = [
		'store_name', 'store_alias','state', 'city', 'address',
		'latitude', 'longitude', 'codigo_referencia',
		'opening_time', 'closing_time'
	];
	fileData: any[] = [];

	constructor(private dialog: MatDialog,private alert: UpdateStoreStatusService) { }

	ngOnInit(): void {
	}

//#region User Interaction
	showPopUpToUpload() {
		this.showPopUp = !this.showPopUp;
	}

	downloadTemplate(): void {
		const link = document.createElement('a');
		link.href = '../../../../../assets/plantillas/PlantillaStore.xlsx';
		link.download = 'PlantillaStore.xlsx';
		link.click();
	}

	onFileSelected(event: any): void {
		this.resetErrors();
		let file: File = event.target.files[0];
		let validFileTypes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'];

		if (file && file.size <= 10 * 1024 * 1024 && validFileTypes.includes(file.type)) {
		this.file = file;
		this.readFileHeaders(file);
		} else if (!validFileTypes.includes(file.type)) {
		this.invalidFileType = true;
		} else {
		this.fileSizeIsOver = true;
		}
	}
// #endregion

//#region File Processing
	readFileHeaders(file: File): void {
		const reader = new FileReader();
		reader.onload = (e: any) => {
			const data = new Uint8Array(e.target.result);
			const workbook = XLSX.read(data, { type: 'array' });
			const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
			const headers = XLSX.utils.sheet_to_json<any[]>(firstSheet, { header: 1 })[0];
			if (this.validateHeaders(headers)) {
				const emailTracker = {};
				this.fileData = XLSX.utils.sheet_to_json(firstSheet).map((row: any) => {
				const validationErrors = [];
				const isAccepted = validationErrors.length === 0;
				return { ...row};
				});
				this.simulateFileUpload();
			}
			else {
				this.invalidHeaders = true;
			}
		};
		reader.readAsArrayBuffer(file);
	}
	simulateFileUpload(): void {
		this.progress = 0;
		let interval = setInterval(() => {
		this.progress += 10;
		if (this.progress >= 100) {
			clearInterval(interval);
			this.openModal(this.fileData);
			this.showPopUp = false;
		}
		}, 100);
	}
// #endregion

// #region Modal Handling
	openModal(data: any[]): void {
		const dialogRef = this.dialog.open(FileDataModalComponent, {
			width: '65%',
			height: 'auto',
			data: data
		});

		dialogRef.afterClosed().subscribe((result) => {

			console.log(result)
			if (result.storeCreated === true) {
				this.alert.updateStatusabledStore({
					storesCreated:true
				});
			}
			else if (result.isAdded === false) {
				this.alert.updateStatusabledStore({
					storesCreated:true
				});
				this.file = null;
				this.fileData = [];
				this.progress = 0;
				this.showPopUp = false;
			} else {
				this.showAlert = true;
				this.showPopUp = false;
				setTimeout(() => {
				this.showAlert = false;
				}, 5000);
			}
		});
	}
// #endregion

// #region Utilities
	resetErrors(): void {
		this.fileSizeIsOver = false;
		this.invalidFileType = false;
		this.invalidHeaders = false;
	}

	clearFile(): void {
		this.file = null;
		this.progress = 0;
	}

	validateHeaders(headers: any[]): boolean {
		return this.requiredHeaders.every(header => headers.includes(header));
	}

	validateEmail(email: string): boolean {
		const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailPattern.test(email);
	}

	validatePhoneNumber(phone: string): boolean {
		const phonePattern = /^(\+52)?[0-9]{10}$/;
		return phonePattern.test(phone);
	}

	validateName(name: string): boolean {
		const namePattern = /^[A-Za-z\sáéíóúÁÉÍÓÚñÑ]+$/;
		return namePattern.test(name) && name.trim().length > 0;
	}
// #endregion


//#region handles
	handleKeydown(event: KeyboardEvent, action: string) {
		if (event.key === 'Enter' || event.key === ' ') {
			switch(action) {
			case 'downloadTemplate':
				this.downloadTemplate();
				break;
			case 'showPopUpToUpload':
				this.showPopUpToUpload();
				break;
			  // Add more cases here as needed for other actions
			default:
				console.warn(`No action defined for ${action}`);
			}
		}
	}
//#endregion

}
