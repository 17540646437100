import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { TranslateService } from 'src/app/core/http/translate.service';


@Component({
  selector: 'app-content-switcher',
  templateUrl: './content-switcher.component.html',
  styleUrls: ['./content-switcher.component.scss']
})
export class ContentSwitcherComponent implements OnInit {
	@Input() content = [];
	@Input() showNumber:boolean = true;
	@Output() contentSelected = new EventEmitter<any>(); // Evento de salida para notificar la selección de contenido
	selectedContent: any;
	public ts = inject(TranslateService);

	ngOnInit() {
	}
	selectContent(item: any) {
        // Iterate through content array and set selected property accordingly
        this.content.forEach(contentItem => {
            contentItem.selected = contentItem === item;
        });
		if (this.selectedContent !== item) {
            this.selectedContent = item;
            // Emitir el evento de selección solo si es un contenido diferente
            this.contentSelected.emit(this.selectedContent);
        }
    }

}
