import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-estatus-orders-warehouse',
  templateUrl: './estatus-orders-warehouse.component.html',
  styleUrls: ['./estatus-orders-warehouse.component.scss']
})
export class EstatusOrdersWarehouseComponent implements OnInit,ICellRendererAngularComp {
	statusID:number;
	statusCodePackage:number;
	constructor() { }
	agInit(params: ICellRendererParams): void {
		this.statusID = params.data.id_status || params.value;
		this.statusCodePackage = params.data.id_status_code_package;
	}
	refresh(params: ICellRendererParams) {
		return false;
	}
	ngOnInit(): void {
	}
}
