<div class="containerCarrierDetail">

	<div class="header">
		<label class="header-user-detail">
			Detalles del carrier
		</label>
		<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="bi bi-x-lg icon" viewBox="0 0 16 16" (click)="closeModal()">
			<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
		</svg>

	</div>
	<hr style="margin-top:12px; margin-bottom:12px">

	<div class="row">
		<div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">


		</div>
		<div class="col-12 col-md-12 col-lg-12 col-xl-12">
			<div class="detailInformation">
				<div class="informationGeneralBox">
					<div class="informationGeneralTitle">
						<label class="title">
							Informacion General
						</label>
					</div>
					<div class="informationGeneralTable" style="margin-top:10px">
						<div class="row informationGeneralItemTable">
							<div class="col-6 col-md-6 col-lg-6 col-xl-6">
								<div class="informationGeneralItemTableName">
									<label class="text">
										Nombre
									</label>
								</div>
							</div>
							<div class="col-6 col-md-6 col-lg-6 col-xl-6">
								<div class="informationGeneralItemTableValue">
									<label class="text">
										{{this.carrier.courier_name}}
									</label>
								</div>
							</div>
						</div>
						<div class="row informationGeneralItemTable">
							<div class="col-6 col-md-6 col-lg-6 col-xl-6">
								<div class="informationGeneralItemTableName">
									<label class="text">
										RFC
									</label>
								</div>
							</div>
							<div class="col-6 col-md-6 col-lg-6 col-xl-6">
								<div class="informationGeneralItemTableValue">
									<label class="text">
										{{this.carrier.trfc}}
									</label>
								</div>
							</div>
						</div>
						<div class="row informationGeneralItemTable">
							<div class="col-6 col-md-6 col-lg-6 col-xl-6">
								<div class="informationGeneralItemTableName">
									<label class="text">
										Dirección
									</label>
								</div>
							</div>
							<div class="col-6 col-md-6 col-lg-6 col-xl-6">
								<div class="informationGeneralItemTableValue">
									<label class="text">
										{{this.carrier.address}}
									</label>
								</div>
							</div>
						</div>
						<div class="row informationGeneralItemTable">
							<div class="col-6 col-md-6 col-lg-6 col-xl-6">
								<div class="informationGeneralItemTableName">
									<label class="text">
										Telefono
									</label>
								</div>
							</div>
							<div class="col-6 col-md-6 col-lg-6 col-xl-6">
								<div class="informationGeneralItemTableValue">
									<label class="text">
										{{this.carrier.phone_number}}
									</label>
								</div>
							</div>
						</div>
						<div class="row informationGeneralItemTable">
							<div class="col-6 col-md-6 col-lg-6 col-xl-6">
								<div class="informationGeneralItemTableName">
									<label class="text">
										API Key
									</label>
								</div>
							</div>
							<div class="col-6 col-md-6 col-lg-6 col-xl-6">
								<div class="informationGeneralItemTableValue">
									<label class="text" *ngIf="this.carrier.api_enabled === 1">
										<mat-slide-toggle (click)="deshabilitarCarrier()" checked="true" [disableRipple]="true">
										</mat-slide-toggle>
									</label>
									<label class="text" *ngIf="this.carrier.api_enabled === 0">
										<mat-slide-toggle (click)="deshabilitarCarrier()" checked="false" [disableRipple]="true">
										</mat-slide-toggle>
									</label>
								</div>
							</div>
						</div>
						<div class="row informationGeneralItemTable">
							<div class="col-6 col-md-6 col-lg-6 col-xl-6">
								<div class="informationGeneralItemTableName">
									<label class="text">
										Dias de Expirado
									</label>
								</div>
							</div>
							<div class="col-6 col-md-6 col-lg-6 col-xl-6">
								<div class="informationGeneralItemTableValue">
									<label class="text">
										{{this.carrier.password_expires_time}}
									</label>
								</div>
							</div>
						</div>
						<div class="row informationGeneralItemTable">
							<div class="col-6 col-md-6 col-lg-6 col-xl-6">
								<div class="informationGeneralItemTableName">
									<label class="text">
										Dias credenciales
									</label>
								</div>
							</div>
							<div class="col-6 col-md-6 col-lg-6 col-xl-6">
								<div class="informationGeneralItemTableValue">
									<label class="text">
										{{this.carrier.days_expire_package}}
									</label>
								</div>
							</div>
						</div>
						<div class="row informationGeneralItemTable">
							<div class="col-6 col-md-6 col-lg-6 col-xl-6">
								<div class="informationGeneralItemTableName">
									<label class="text">
										Hora Expira paquete
									</label>
								</div>
							</div>
							<div class="col-6 col-md-6 col-lg-6 col-xl-6">
								<div class="informationGeneralItemTableValue">
									<label class="text">
										{{this.carrier.package_expiration_time}}
									</label>
								</div>
							</div>
						</div>
						<div class="row informationGeneralItemTable">
							<div class="col-6 col-md-6 col-lg-6 col-xl-6">
								<div class="informationGeneralItemTableName">
									<label class="text">
										Promedio de calificacion
									</label>
								</div>
							</div>
							<div class="col-6 col-md-6 col-lg-6 col-xl-6">
								<div class="informationGeneralItemTableValue">
									<label class="text">
										{{this.carrier.average_start}}
									</label>
								</div>
							</div>
						</div>
						<div class="row informationGeneralItemTable">
							<div class="col-6 col-md-6 col-lg-6 col-xl-6">
								<div class="informationGeneralItemTableName">
									<label class="text">
										Entregas totales
									</label>
								</div>
							</div>
							<div class="col-6 col-md-6 col-lg-6 col-xl-6">
								<div class="informationGeneralItemTableValue">
									<label class="text">
										{{this.carrier.delivery_total}}
									</label>
								</div>
							</div>
						</div>
						<div class="row informationGeneralItemTable">
							<div class="col-6 col-md-6 col-lg-6 col-xl-6" >
								<div class="informationGeneralItemTableName">
									<label class="text">
										Total paquetes expirados
									</label>
								</div>
							</div>
							<div class="col-6 col-md-6 col-lg-6 col-xl-6">
								<div class="informationGeneralItemTableValue">
									<label class="text">
										---
									</label>
								</div>
							</div>
						</div>
						<div class="row informationGeneralItemTable">
							<div class="col-6 col-md-6 col-lg-6 col-xl-6">
								<div class="informationGeneralItemTableName">
									<label class="text">
										Fecha de registro
									</label>
								</div>
							</div>
							<div class="col-6 col-md-6 col-lg-6 col-xl-6">
								<div class="informationGeneralItemTableValue">
									<label class="text">
										{{HourRegister}}<br>
										{{DateRegister}}
									</label>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="boxTables">
					<div class="row" style="gap: 12px;">
						<div class="col-12">
							<div class="OrdenesRecientesBox">
								<div class="OrdenesRecientesTitle">
									<label class="title">
										Ordenes Recientes
									</label>
									<app-button [btnNombre]="this.btnOrdenes" btnType="primaryMedium" (click)="goToOrdersCarriers()">
									</app-button>
								</div>
								<div class="row" style="height: 100%" >
									<div class="col-12 col-md-12 col-lg-12 col-xl-12" style="text-align: end;margin-top:12px">
										<div class="OrdenesRecientesTable">
											<app-table-carrier-order [CarrierID]="this.carrier.id_courier">
											</app-table-carrier-order>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-12">
							<div class="ActividadUsuariosBox">
								<div class="ActividadUsuariosTitle">
									<div class="row" style="height: 100%;">
										<div class="col-12 col-md-12 col-lg-12 col-xl-12" style="display:flex;justify-content: space-between;">
											<div class="title">
												Actividad de usuarios reciente
											</div>

											<app-button [btnNombre]="this.btnUsuarios" btnType="primaryMedium" (click)="goToUsersCarriers()">
											</app-button>
										</div>
									</div>
								</div>
								<div class="col-12 col-md-12 col-lg-12 col-xl-12" style=" text-align: end;">
									<div class="ActividadUsuariosTable">
										<app-table-carrier-user [CarrierID]="this.carrier.id_courier">
										</app-table-carrier-user>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
