<div class="filtersBox" *ngIf="this.filters === true">
	<div class="row">
		<div class="col-12 col-md-12 col-lg-12 col-xl-12" style="display: flex; justify-content: space-between;">
			<span class="filtersBoxTitle">
				{{'ordersPage.filters.title' | translate}}
			</span>
			<div class="icon-close" (click)="closeModal()">
			</div>
		</div>
		<div class="col-12 col-md-12 col-lg-12 col-xl-12">
			<div class="boxValues">
				<div class="row">
					<div class="col-12 col-md-12 col-lg-12 col-xl-12">


								<div class="statusInLocker">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="en_locker_destino"
										[(ngModel)]="checkboxExpired"
										name="enLockerDestino"
										(change)="validarCasillasSeleccionadas()"/>
									<span class="textDropDown">
										{{'ordersPage.filters.all.expired.expired' | translate}}
									</span>
								</div>

								<div class="statusInLocker">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="en_locker_destino"
										[(ngModel)]="checkboxPayExpired"
										name="enLockerDestino"
										(change)="validarCasillasSeleccionadas()"/>
									<span class="textDropDown">
										{{'ordersPage.filters.all.expired.expiredPays' | translate}}
									</span>
								</div>




					</div>
				</div>
			</div>
		</div>
	</div>
</div>
