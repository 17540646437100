<div class="timepicker-container">
	<div class="title">
		{{label | translate}}
	</div>
	<div class="body-container" (click)="openContainerTime()">
		{{this.selectedTime}}
	</div>
	<div class="error-message">
		<div *ngIf="showError" class="error-message">
			<div>{{ errorMessage }}</div>
		</div>
	</div>
</div>
<div class="box-timepicker" *ngIf="showBoxContainer ===true">
	<div class="time-picker">
		<div class="timer">
			<div class="hour-container">
				<div class="number">
					<input  type="number" [(ngModel)]="selectedHour" (change)="onTimeChange()" [min]="1" [max]="24" placeholder="HH">
				</div>
				<div class="title">
					{{'timePicker.hour' | translate}}
				</div>
			</div>
			<div class="dots">
				:
			</div>
			<div class="minutes-container">
				<div class="number">
					<input  type="number" [(ngModel)]="selectedMinute" (change)="onTimeChange()" [min]="0" [max]="59" placeholder="MM">
				</div>
				<div class="title">
					{{'timePicker.minute' | translate}}
				</div>
			</div>
		</div>
		<!-- <div class="time-slot">
			<div  class="slot"	[class.selected]="selectedPeriod === 'AM'" (click)="setPeriod('AM')">
				<div class="text">
					AM
				</div>
			</div>
			<div class="slot" [class.selected]="selectedPeriod === 'PM'" (click)="setPeriod('PM')">
				<div class="text">
					PM
				</div>
			</div>
		</div> -->
	</div>
	<div class="buttons-container">
		<app-button-dashboard style="width: 100%;" (click)="clearTime()"
			buttonClass="btn-md-tertiary"
			label="timePicker.buttons.erase">
		</app-button-dashboard>
		<div class="buttons-principals">
			<app-button-dashboard style="width: 100%;" (click)="cancel()"
				buttonClass="btn-md-secondary"
				label="timePicker.buttons.cancel">
			</app-button-dashboard>
			<app-button-dashboard style="width: 100%;" (click)="accept()"
				buttonClass="btn-md-primary"
				label="timePicker.buttons.accept">
			</app-button-dashboard>
		</div>
	</div>
</div>
