import { Component, Input, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
@Component({
	selector: 'app-tooltip-table',
	templateUrl: './tooltip-table.component.html',
	styleUrls: ['./tooltip-table.component.scss']
})
export class TooltipTableComponent  implements ICellRendererAngularComp, OnInit{
	data;
	tooltip: string = 'sm-center';
	// @Input() tooltipContent: string;
	constructor() { }
	agInit(params: ICellRendererParams): void {
		this.data = params.value;
	
	}
	refresh(params: ICellRendererParams): boolean {
		return false
	}

	ngOnInit(): void {
	}
}
