import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/core/http/auth/auth.service';
import * as shajs from 'sha.js';
import { HttpResponse } from '@angular/common/http';
import { ModuleCarrierService } from 'src/app/core/http/carriers/module_carrier/module-carrier.service';
import { ToastrService } from 'ngx-toastr';
import { EncryptDataService } from 'src/app/core/security/encypt-data.service';

@Component({
	selector: 'app-dialog-change-status-carrier',
	templateUrl: './dialog-change-status-carrier.component.html',
	styleUrls: ['./dialog-change-status-carrier.component.scss'],
})
export class DialogChangeStatusCarrierComponent implements OnInit {
	user: string;
	validateUserForm: FormGroup;
	validationUserForm: boolean = false;
	isValidated: boolean = false;
	data;
	carrierID: number;

	constructor(
		@Inject(MAT_DIALOG_DATA) private dataTable,
		private dialogRef: MatDialogRef<DialogChangeStatusCarrierComponent>,
		private fb: FormBuilder,
		private translate: TranslateService,
		private authService: AuthService,
		private moduleCarrierService: ModuleCarrierService,
		private toastr: ToastrService,
		private securityLocalStorage: EncryptDataService
	) {
		this.data = JSON.parse(
			this.securityLocalStorage.decryptData(localStorage.getItem('data'))
		);
		this.user = this.data.result.email;
		this.validateUserForm = this.fb.group({
			user: [this.user],
			pass: ['', [Validators.required]],
		});
	}

	ngOnInit(): void {
		if (this.dataTable.isFromDialog === true) {
			this.carrierID = this.dataTable.data.data.id_courier;
		} else {
			this.carrierID = this.dataTable.data.data.id_courier;
		}
		this.translate.use(localStorage.getItem('language').toLowerCase());
		this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
			this.translate.use(event.lang);
		});
	}
	closeModal() {
		this.dialogRef.close();
	}
	modelChangedStatusCarrier($event) {
		if (this.validateUserForm.get('pass').status === 'VALID') {
			this.validationUserForm = true;
		} else {
			this.validationUserForm = false;
		}
	}
	desactivarCarrier() {
		let passValue = shajs('sha512')
			.update(this.validateUserForm.get('pass').value)
			.digest('hex');
		let objectValidateUser = {
			language: localStorage.getItem('language').toLowerCase(),
			user: this.validateUserForm.get('user').value,
			pass: passValue,
			mobile: false,
			tecnico: false,
			is_only_validate: true,
		};
		this.authService
			.validateUserPassword(objectValidateUser)
			.then((res: HttpResponse<any>) => {
				if (res.body.mensaje_return.CODE === 200) {
					this.isValidated = true;
				} else if (res.body.mensaje_return.CODE !== 200) {
					this.isValidated = false;
				}
				if (this.isValidated === true) {
					let objectToDisable = {
						language: localStorage.getItem('language'),
						id_courier_update: this.carrierID,
					};
					this.moduleCarrierService
						.disableCarrier(objectToDisable)
						.then((res: any) => {
							this.toastr.success(
								'Se ha cambiado el estatus del carrier',
								'Accion Exitosa',
								{
									toastClass: 'toast-exitosa',
									timeOut: 5000,
									positionClass: 'toast-bottom-center',
								}
							);
							this.dialogRef.close();
						});
				} else if (this.isValidated === false) {
					this.toastr.error(
						'La contraseña no es la misma',
						'Error de Contraseña',
						{
							toastClass: 'toast-error-toggle',
							timeOut: 5000,
							positionClass: 'toast-bottom-center',
						}
					);
				}
			});
	}
}
