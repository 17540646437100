import { AfterContentChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AgGridAngular } from 'ag-grid-angular';
import { AgGridEvent, ColDef, GridOptions } from 'ag-grid-community';
import { TranslateService } from 'src/app/core/http/translate.service';
import { ZoneSelectionService } from 'src/app/core/http/zone-selection.service';
import { DialogCreateStoreComponent } from 'src/app/modules/admin-client/components/dialogs/dialog-create-store/dialog-create-store.component';
import { DialogToCreateRouteComponent } from 'src/app/modules/warehouse/components/create-route-components/dialogs/dialog-create-route/dialog-create-route.component';

@Component({
	selector: 'app-tables',
	templateUrl: './tables.component.html',
	styleUrls: ['./tables.component.scss']
})
export class TablesComponent implements OnInit, OnChanges,AfterContentChecked {
	@Input() rowData:any[] =[];
	@Input() colDef: ColDef[] = [];
	@ViewChild('agGrid') agGrid: AgGridAngular;

	@Input() gridOptions: GridOptions = {
		pagination: true,
		paginationPageSize: 10,
		headerHeight:49,
		rowHeight:49,
		domLayout:'autoHeight',
		defaultColDef:{
			autoHeight:false,
			resizable: false,
			sortable: true,
			flex:1
		}
	};
	@Input() overlayNoRowsTemplate;
	@Input() overlayLoadingTemplate;
	@Input() nameFileExport;
	@Input() showInputSearch: boolean =true;
	@Input() showExportButton:boolean = false;
	@Input() showStartReviewButton:boolean = false;
	@Input() showFilterButton:boolean = false;
	@Input() showRoutesButton:boolean = false;
	@Input() showCalendar:boolean = false;
	@Input() showCreateRoute:boolean =false;
	@Input() showCreateStore:boolean= false;
	@Input() title:string;
	@Input() TimelineItems: any[];
	@Input() filterText: string = '';
	@Input() disabledButton:boolean;
	@Output() datesEmitter: EventEmitter<Date[]> = new EventEmitter<Date[]>();
	@Output() filterEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() storeCreatedEmmiter: EventEmitter<boolean> = new EventEmitter<boolean>();
	showCompleteReview:boolean=false;
	disabledStartReviewButton:boolean =true;
	showFilter:boolean = false;
	filteredData = [];
	receivedDates: Date[] = [];
	showAlert:boolean = false;
	showAlertEmpty:boolean = false;
	showAlertCreatedStore:boolean = false;
	selectCheckbox=[];
	storeCreated:any[]=[];
	showCreateFullStore:boolean=false;
	public ts = inject(TranslateService);
	public matDialog = inject(MatDialog);
	private router = inject(Router);
	public zoneService= inject(ZoneSelectionService);
	private cdr = inject(ChangeDetectorRef);
	firstRow:number;
	lastRow:number;
	rowCount:number;
	selectedPacket:number;
	disabledRowData;
	showButtonDisabled:boolean = false;
	currentPage:number;
	totalPages:number;
	// get firstRow(): number {
	// 	return this.agGrid ? this.agGrid.api.getFirstDisplayedRow() + 1 : 0;
	// }

	// get lastRow(): number {
	// 	return this.agGrid ? this.agGrid.api.getLastDisplayedRow() + 1 : 0;
	// }

	// get rowCount(): number {
	// 	return this.agGrid ? this.agGrid.api.getModel().getRowCount() : 0;
	// }
	ngAfterContentChecked(): void {
		if (this.agGrid && this.agGrid.api) {
			this.firstRow = this.agGrid.api.getFirstDisplayedRow() + 1;
			this.lastRow = this.agGrid.api.getLastDisplayedRow() + 1;
			this.rowCount = this.agGrid.api.getModel().getRowCount();
		} else {
			this.firstRow = 0;
			this.lastRow = 0;
			this.rowCount = 0;
		}
	}
	ngOnInit(){
		if (this.TimelineItems && this.TimelineItems.length >= 4) {
			if(this.TimelineItems[2].status === 1){
				this.disabledStartReviewButton = false;
			}
			if(this.TimelineItems[3].status === 1){
				this.showStartReviewButton = false;
				this.showCompleteReview = true;
				this.disabledStartReviewButton = false;
			}
        }
		this.ts.lang$.subscribe(lang => {
			const translations =require(`../../../../../assets/languaje/${lang}.json`)
		});
		const currentDate = new Date(); // Obtén la fecha actual
		const lastMonthDate = new Date(); // Obtén la fecha del mes anterior
		lastMonthDate.setMonth(lastMonthDate.getMonth() - 1);
		this.receivedDates = [currentDate, lastMonthDate];
		this.datesEmitter.emit(this.receivedDates);
	}
	ngOnChanges(changes: SimpleChanges): void {
		if(this.rowData.length > 0){
			this.disabledButton = false;
		}
		else{
			this.disabledButton = true;
		}
	}
	onGridReady(params: any) {
		const gridApi = params.api;
		// Initial call to set stats
		this.updateGridStats(gridApi);
		this.agGrid.gridOptions={
			animateRows:true,
			onSelectionChanged: this.getSelectedRowsData.bind(this),
			defaultColDef:{
				flex:1,
				minWidth:150,
				maxWidth:450
			}
		}

	}
	updateGridStats(gridApi: any = this.agGrid?.api): void {

		if (gridApi) {
			this.firstRow = gridApi.getFirstDisplayedRow() + 1;
			this.lastRow = gridApi.getLastDisplayedRow() + 1;
			this.rowCount = gridApi.getModel().getRowCount();
			this.currentPage = gridApi.paginationGetCurrentPage() + 1;
			this.totalPages = gridApi.paginationGetTotalPages();
		}

		this.cdr.detectChanges();
	}
	onRowUpdate(): void {
		const firstRowIndex = this.agGrid.api.getFirstDisplayedRow();
		if (firstRowIndex >= 0) {
			const lastNode = this.agGrid.api.getDisplayedRowAtIndex(firstRowIndex);
			const allColumns = 	this.agGrid.api.getColumns();
			const columnIds = allColumns.map(column => column.getColId());
			if (lastNode && columnIds.length > 0) {
				this.agGrid.api.flashCells({
					rowNodes: [lastNode],
					columns: columnIds,
					flashDelay: 1000
				});
			}
		}
	}
	onFilterTextChange(event) {
		this.filterText = event;
		// event.api.setQuickFilter(this.filterText);
		let api = event.api;
		let temporal = [];
		// event.api.forEachNodeAfterFilter((node) => {
		// 	temporal.push(node.data);
		// });
		this.filteredData = temporal;
	}
	receiveDates(dates: Date[]): void {
		this.filterEmitter.emit(false);
		// Formatear la fecha inicial a 'YYYY-MM-DD 00:00:00'
		if (dates.length > 0) {
			const startDate = new Date(dates[0]);
			startDate.setHours(0, 0, 0, 0); // Establecer horas, minutos, segundos y milisegundos a 0

			// Ajustar la zona horaria a GMT-5 (por ejemplo, México)
			const startDateLocal = new Date(startDate.toLocaleString("en-US", { timeZone: "America/Mexico_City" }));

			// Formatear la fecha final a 'YYYY-MM-DD 23:59:59'
			let endDate;
			if (dates.length > 1) {
				endDate = new Date(dates[1]);
			} else {
				// Si no hay fecha final, utilizar la fecha inicial para crear un rango de un día
				endDate = new Date(startDate);
				endDate.setDate(endDate.getDate() + 1); // Agregar un día para obtener la fecha final
			}
			endDate.setHours(23, 59, 59, 999); // Establecer horas, minutos, segundos y milisegundos al final del día

			// Ajustar la zona horaria a GMT-5 (por ejemplo, México)
			const endDateLocal = new Date(endDate.toLocaleString("en-US", { timeZone: "America/Mexico_City" }));

			// Emitir las fechas formateadas como objetos Date
			this.datesEmitter.emit([startDateLocal, endDateLocal]);
		}
	}
	exportDataToExcel(){
		let params;
		const fileName = `${this.nameFileExport}.xls`;
		params = {
			fileName: fileName,
			processCellCallback:  this.formatNumber.bind(this),
			processHeaderCallback: this.formatHeaders.bind(this), // Agrega esta línea
		};
		this.agGrid.api.exportDataAsCsv(params);
	}

	formatNumber(params) {
		const colId = params.column.getColId();
		if (colId === 'tracking_number') {
			return `‎${params.value.toString()}`;
		}
		else if(colId === '0' || colId === 'locker_name_1'){
			return ''
		}
		else if (colId === 'register_date') {
			const date = new Date(params.value);
			return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}  ${date.getHours()}:${date.getMinutes()}`;
		}
		else if (colId === 'registration_date') {
			const date = new Date(params.value);
			return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}  ${date.getHours()}:${date.getMinutes()}`;
		}
		else if (colId === 'update_date') {
			const date = new Date(params.value);
			return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}  ${date.getHours()}:${date.getMinutes()}`;
		}
		else if (colId === 'create_date') {
			const date = new Date(params.value);
			return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}  ${date.getHours()}:${date.getMinutes()}`;
		}
		else if (colId === 'update_date') {
			const date = new Date(params.value);
			return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}  ${date.getHours()}:${date.getMinutes()}`;
		}
		else {
			return params.value;
		}
	}
	unshowFilter(){
		this.filterEmitter.emit(false);
	}

	formatHeaders(params) {
		const translations = 	require(`../../../../../assets/languaje/${localStorage.getItem('language')}.json`);
		const colId = params.column.getColId();
		const colHeader = params.column.colDef.headerValueGetter();
		if (colId === 'tracking_number') {
			return 'Tracking Number';
		}
		else if(colHeader ==='Imprimir' || colHeader === ''){
			return ''
		}
		else{
			return params.column.colDef.headerValueGetter();
		}
	}

	convertNumberToText(params) {
		if (typeof params.value === 'number') {
			return params.value.toString();
		} else {
			return params.value;
		}
	}
	convertStringToNumber(params) {
		const stringValue = params.value.toString().trim();
		if ((Number(stringValue))) {
			return parseFloat(stringValue);
		} else {
			return params.value;
		}
	}

	createRoute(){
		this.router.navigate(['dashboard/create-route']);
	}
	// Método para obtener los datos de los checkbox seleccionados
	getSelectedRowsData() {
		const selectedNodes = this.agGrid.api.getSelectedNodes(); // Obtener nodos seleccionados
		this.selectCheckbox = selectedNodes;
		if(selectedNodes.length === 0){
			this.showAlertEmpty = true;
			setTimeout(() => {
				this.showAlertEmpty = false;
			}, 10000)
		}
		else{
			const selectedData = selectedNodes.map(node => node.data); // Obtener datos de los nodos seleccionados
			let hasDifferentZones = false;
			selectedData.forEach(data => {
				if (data.zone !== selectedData[0].zone) { // Comparar con la zona del primer elemento seleccionado
					hasDifferentZones = true;
				}
			});
			this.zoneService.setDifferentZonesStatus(hasDifferentZones);
			if(selectedData.length > 0){
				this.matDialog.open(DialogToCreateRouteComponent,{
					width:'950px',
					height:'auto',
					data: selectedData,
					disableClose: true,
				}).afterClosed().toPromise().then(res => {
						if (res.routeCreated && res && res.data) {
							this.rowData = this.rowData.filter(item => {
								return !res.data.some(dataItem => dataItem === item.id_package);
							});
							this.showAlert = true;
							setTimeout(() => {
								this.showAlert = false;
							}, 10000)
						}
				});
			}
		}
	}
	anyCheckboxSelected(): boolean {
		return this.agGrid && this.agGrid.api && this.agGrid.api.getSelectedNodes().length > 0;
	}
	clearSelection(): void {
		this.agGrid.api.deselectAll();
	}
	onPageSizeChanged(size: number) {
		this.gridOptions.paginationPageSize = size;
		// Actualiza la paginación en la tabla
		if (this.agGrid.api) {
			// Set pagination page size using setGridOption
			this.agGrid.api.setGridOption('paginationPageSize', size);
		}
	}
	onPageChanged(page: number) {
		// Navega a la página especificada
		if (this.agGrid.api) {
			this.agGrid.api.paginationGoToPage(page - 1);
		}
	}
	openFilters(){
		this.showCalendar === false
		this.showFilter = !this.showFilter;
		this.filterEmitter.emit(this.showFilter);

	}
	openCreateStore(){
		console.log('HOLA');
		this.matDialog.open(DialogCreateStoreComponent,{
			width:'1080px',
			height:'auto',
			disableClose: false,
		}).afterClosed().toPromise().then(res => {
			if (res && res.storeCreated) {
				this.showAlertCreatedStore = true;

				// Notifica que una tienda fue creada.
				this.storeCreatedEmmiter.emit(true);

				// Guarda los datos de la tienda creada.
				this.storeCreated = res.store;

				// Opcional: Actualizar la tabla si es necesario
				if (res.store) {
					this.rowData = [...this.rowData, ...res.store]; // Agrega la nueva tienda(s) a la tabla
					this.updateGridStats();
				}
			} else {
				console.warn('El diálogo se cerró sin crear una tienda o hubo un error en la respuesta.');
			}
		});
	}
	handleKeyUp(event: KeyboardEvent) {
		// Handle the key up event here
	}
}
