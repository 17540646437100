<div class="container-main">
	<div class="grid-header">
		<div class="span-txt">
			<span class="txt-title">
				{{'overview.cardsOverview.ordersRecoletedRefund' | translate}}
			</span>
			<div class="icon-info">
				<app-tooltip-center-sm
					class="tooltip-span"
					[tooltip]="tooltipTracking">
				</app-tooltip-center-sm>
			</div>
		</div>
	</div>
	<div class="chart" id="chart">
		<apx-chart style="width:99%"
			[series]="dataChartExpiredOrders.series"
			[chart]="dataChartExpiredOrders.chart"
			[dataLabels]="dataChartExpiredOrders.dataLabels"
			[plotOptions]="dataChartExpiredOrders.plotOptions"
			[responsive]="dataChartExpiredOrders.responsive"
			[xaxis]="dataChartExpiredOrders.xaxis"
			[legend]="dataChartExpiredOrders.legend"
			[fill]="dataChartExpiredOrders.fill">
		</apx-chart>
		<div class="dates-change">
			<div class="span-date" *ngIf="periodBase.length === 2">
				{{periodBase[0] | date:'MMMM dd, yyyy' }} - {{periodBase[1] | date:'MMMM dd, yyyy' }}
			</div>
			<div class="span-date" *ngIf="periodComp.length === 2">
				{{periodComp[0] | date:'MMMM dd, yyyy'}} - {{periodComp[1] | date:'MMMM dd, yyyy'}}
			</div>
		</div>
	</div>
	<div class="container-legend">
		<div class="align-text">
			<span>
				<div class="circle-1">
				</div>
				{{'overview.orderDelivered' | translate}}
			</span>
			<span>
				<div class="circle-2">
				</div>
				{{'overview.orderRecolected' | translate}}
			</span>
		</div>
	</div>
</div>


