// * Angular
import { Component } from '@angular/core';
import { Router } from '@angular/router';
// * Interfaces
import { DataLocalStorage, NavMenu } from '../sidebar-menu/sidebar.interfaces';
// * Services
import { DataService } from 'src/app/core/services/data.service';
import { TranslateService } from '@ngx-translate/core';
import { EncryptDataService } from 'src/app/core/security/encypt-data.service';

@Component({
  selector: 'header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss']
})
export class HeaderMenuComponent {
    // ? STORAGE DATA ------------------>
  // * Sidebar menu
  menuBlock1:NavMenu[];
  // * Company name
  companyName:string;
  // * Company name
  companyType:string;
  // * Username
  userName:string;
  userType:number;
  // * Profile
  profile:string;
  // * Checked Lang & Mode
  langCheck:boolean = false;
  modeCheck:boolean = false;
  hamburgerCheck:boolean = false;
  // * Change mode
	mode: string = localStorage.getItem('mode') || 'light';
  // * Selected
	modeSelected: string;
	idUserType:number;

  constructor(
    private router      : Router,
    public translate    : TranslateService,
	private securityLocalStorage: EncryptDataService,
		private dataService : DataService ) {

    // ? GET DATA
    const data:DataLocalStorage = JSON.parse(this.securityLocalStorage.decryptData(localStorage.getItem('data')))
	this.idUserType = data.result.id_user_type;
	this.userType = data.data_company.id_user_type;
    // * Menu Data
    this.menuBlock1 = data.data_menu.return_menu;
    // * Company Name
    this.companyName = data.data_company.company_name;
    // * Company Type
    this.companyType = data.data_company.company_type;
    // * Username
    this.userName = data.data_company.alias_username;
    // * Get Profile
    const word = data.data_company.first_name.split(" ");
    // Get first 2 words
    this.profile = "";
    for (const w of word) {
      if (w.length > 0) {
        this.profile += w[0];
      }
    }
  }
  // ? Side menu block 2
  menuBlock2:NavMenu[] = [
    {
      txt:'navbar.webhook',
      icon:'webhook',
      route:'config-webhook',
    },
  ]
  // ? Side menu block 3
  menuBlock3:NavMenu[] = [
    {
      txt:'navbar.integrations',
      icon:'integrations',
      route:'integrations'
    },
    {
      txt:'navbar.version',
      icon:'version',
      route:'version'
    }
  ]

  // ? Change Mode
  changeMode(mode: string): void {
		this.mode = mode;
    // * Get mode from localStorage
		document.body.classList.toggle('dark-mode', this.mode === 'dark');
    // * Set Mode
		localStorage.setItem('mode', mode);

		this.modeSelected = this.dataService.mode;
	}

  getLang():string {
    let lang = localStorage.getItem('language');
    return lang;
  }


  // ? Change Language
  checkLang() {
    this.modeCheck = false;
  }
  checkMode() {
    this.langCheck = false;
  }

  // ? Close header
  closeHeader() {
    this.hamburgerCheck = !this.hamburgerCheck;
  }
  // ? Close dropdowns menu
  closeAllDrops() {
    this.langCheck = false;
    this.modeCheck = false;
  }
  changeLanguage(event){
		localStorage.setItem('language',event)
		this.translate.use(event);
	}
  // ? Logout

  logout() {
		localStorage.clear();
		this.router.navigateByUrl('/');
	}

	goToUp(){
		window.scroll({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	}
	goToAccount(){
		if(this.idUserType === 6){
			this.router.navigateByUrl('warehouse/account-settings')
		}
		else if(this.idUserType === 7){
			this.router.navigateByUrl('vivipost/account-settings')
		}
		else if(this.idUserType === 9){
			this.router.navigateByUrl('superadmin/account-settings')
		}
		else if(this.idUserType === 10){
			this.router.navigateByUrl('carriers/account-settings')
		}
	}

}
