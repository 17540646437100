// * Angular
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// * Interfaces
import { DataLocalStorage, NavMenu } from './sidebar.interfaces';
// * Services
import { DataService } from 'src/app/core/services/data.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { EncryptDataService } from 'src/app/core/security/encypt-data.service';
import { UserAuthService } from 'src/app/modules/auth/services/user-auth.service';
import { ReturnMenu } from 'src/app/core/models';

@Component({
  selector: 'sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss']
})
export class SidebarMenuComponent implements OnInit {
	// ? STORAGE DATA ------------------>
	// * Sidebar menu
	menuBlock1:NavMenu[];
	// * Company name
	companyName:string;
	// * Company name
	companyType:string;
	// * Username
	userName:string;
	// * Profile
	profile:string;
	// * Checked Lang & Mode
	langCheck:boolean = false;
	modeCheck:boolean = false;
	// * Change mode
	mode: string = localStorage.getItem('mode') || 'light';
	// * Selected
	modeSelected: string;
	idUserType:number;

	data;
	language: string;
	role: string;
	sideMenu: ReturnMenu[];
	userType:string;
	page;
	systemVersion:string;

	constructor(
		private router      : Router,
		public translate    : TranslateService,
		private securityLocalStorage: EncryptDataService,
		private dataService : DataService,
		private userService: UserAuthService ) {
		// ? GET DATA
		const data:DataLocalStorage = JSON.parse(this.securityLocalStorage.decryptData(localStorage.getItem('data')));
		this.idUserType = data.result.id_user_type;
		// * Menu Data
		this.menuBlock1 = data.data_menu.return_menu;
		// * Company Name
		this.companyName = data.data_company.company_name;
		// * Company Type
		this.companyType = data.data_company.user_type
		;
		// * Username
		this.userName = data.data_company.alias_username;
		// * Get Profile
		const word = data.data_company.first_name.split(" ");
		// Get first 2 words
		this.profile = "";
		for (const w of word) {
		if (w.length > 0) {
			this.profile += w[0];
		}
		}

		if (this.idUserType === 11 || this.idUserType === 12) {
			// Remove the first element from menuBlock3
			this.menuBlock3.splice(0, 1);
		}
	}
	ngOnInit(): void {
		this.data = JSON.parse(this.securityLocalStorage.decryptData(localStorage.getItem('data')))
		this.companyName = this.data.data_company.company_name;

		this.idUserType = this.data.result.id_user_type
		this.translate.onLangChange.subscribe((event: LangChangeEvent)=> {
			this.language = event.lang
		})
		this.role = this.dataService.role;
		this.sideMenu = this.data.data_menu.return_menu;
		this.userType = this.data.result.user_type;
		this.mode = localStorage.getItem('mode');
		this.language = localStorage.getItem('language').toLowerCase();
		this.page = this.router.url.split("/")[2];
		this.userService.getSystemVersion().then((res:any)=>{
			this.systemVersion = res.version;
			if(res.mensaje_return.ERROS_MENSAGGE ==='SUCCESS'){
				this.systemVersion = res.version;
			}
		});
	}
	// ? Side menu block 2
	menuBlock2:NavMenu[] = [
		{
		txt:'navbar.webhook',
		icon:'webhook',
		route:'config-webhook',
		},
	]
	// ? Side menu block 3
	menuBlock3:NavMenu[] = [
		{
		txt:'navbar.integrations',
		icon:'integrations',
		route:'integrations'
		},
		{
		txt:'navbar.version',
		icon:'version',
		route:'version'
		}
	];

	// ? Change Mode
	changeMode(mode: string): void {
			this.mode = mode;
		// * Get mode from localStorage
			document.body.classList.toggle('dark-mode', this.mode === 'dark');
		// * Set Mode
			localStorage.setItem('mode', mode);

			this.modeSelected = this.dataService.mode;
		}
	getLang():string {
		let lang = localStorage.getItem('language');
		return lang;
	}
	// ? Change Language
	checkLang() {
		this.modeCheck = false;
	}
	checkMode() {
		this.langCheck = false;
	}
	// ? Close dropdowns menu
	closeAllDrops() {
		this.langCheck = false;
		this.dataService.overviewChecked = !this.dataService.overviewChecked;
		this.modeCheck = false;
	}
	changeLanguage(event){
		localStorage.setItem('language',event)
		this.translate.use(event);
	}
	// ? Logout
	logout() {
		let obj = {
			language:'es',
			id_user: this.data.result.id_user,
			id_company: this.data.data_company.id_company
		}
		this.userService.logout(obj).then((res:any)=>{
			localStorage.removeItem('token');

			localStorage.removeItem('user_type');
			localStorage.removeItem('id_user');
			localStorage.removeItem('id_company');
			localStorage.removeItem('courier_id');
			localStorage.setItem('language','es');
			this.router.navigateByUrl('/auth/new-login');
		}).catch((error:any)=>{
			console.log(error);
		})
	}
	goToAccount(){
		if(this.idUserType === 6){
			this.router.navigateByUrl('warehouse/account-settings')
		}
		else if(this.idUserType === 7){
			this.router.navigateByUrl('vivipost/account-settings')
		}
		else if(this.idUserType === 9){
			this.router.navigateByUrl('superadmin/account-settings')
		}
		else if(this.idUserType === 10){
			this.router.navigateByUrl('carriers/account-settings')
		}
	}
	toggleLanguageDropdown(){
		this.langCheck = !this.langCheck;
		this.dataService.overviewChecked = false;
		this.modeCheck = false;
	}
	toggleAppearanceDropdown(){
		this.langCheck = false;
		this.dataService.overviewChecked = false;
		this.modeCheck = !this.modeCheck;
	}
}
