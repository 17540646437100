import { Component, Input, SimpleChanges, ViewChild } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

import {
	ApexAxisChartSeries,
	ApexChart,
	ChartComponent,
	ApexDataLabels,
	ApexXAxis,
	ApexPlotOptions,
	ApexResponsive,
	ApexFill,
	ApexLegend,
	ApexYAxis,
	ApexStroke,
} from 'ng-apexcharts';

@Component({
	selector: 'app-rating-average',
	templateUrl: './rating-average.component.html',
	styleUrls: ['./rating-average.component.scss'],
})
export class RatingAverageComponent {
	@ViewChild('chart') chart: ChartComponent;
	@Input() dataBase = [];
	@Input() qualification = 0.0;
	@Input() quantityQualification = 0;
	@Input() rating = 0;
	public chartOptions: Partial<ChartOptions>;

	stars=[];
	data =[];
	tooltipRating: string;
	constructor(private translate: TranslateService) {

	}

	ngOnInit() {
		this.translate.setDefaultLang(localStorage.getItem('language').toLowerCase());
		this.tooltipRating = this.translate.instant('tooltip.qualification');
		this.loadDataChart();
		this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
			this.translate.use(event.lang);
			this.tooltipRating = this.translate.instant('tooltip.qualification');
		})
	}

	ngOnChanges(changes: SimpleChanges) {
		this.data =[];
		this.stars = [];
		this.dataBase.map((item:any)=>{
			this.data.push(item.data);
			this.stars.push(item.name);
		})
		this.loadDataChart();
	}

	loadDataChart() {
		this.chartOptions = {
			series: [
				{
					data: this.data
				}
			],
			chart: {
				type: 'bar',
				height: 210,
				foreColor:  '#003755',
				toolbar: {
					show: true,
						tools:{
							download:`
								<div class="downloadClassRatingAverage">
									<label class="textExport">
										${this.translate.instant('buttons.export')}
									</label>
									<div class="icon">
									</div>
								</div>
							`,
							pan:false,
							reset:false,
							zoom:false,
							zoomin:false,
							zoomout:false,
							selection:true,

					},
				},
			},

			plotOptions: {
				bar: {
					horizontal: true,
					barHeight: '100',
					colors : {
						ranges:[
							{
								from: -30,
								to: -1,
								color: 'var(--color-container)',
							},
							{
								from: 0,
								to: 20,
								color: '#8AC6E7',
							},
							{
								from: 20,
								to: 40,
								color: '#54ABDC',
							},
							{
								from: 40,
								to: 60,
								color: '#0082CB',
							},
							{
								from: 60,
								to: 80,
								color: '#016299',
							},
							{
								from: 80,
								to: 100,
								color: '#003755'
							}
						],
					},
				}
			},
			responsive: [
				{
					breakpoint: 620,
					options:{
						chart: {
							height: 0
						},
						series: this.dataBase.map((item) => ({
							name: item.name,
							data: item.data ? item.data : [0],
						}))
					}
				},
			],
			xaxis: {
				labels: {
					show: false,
				},
				axisBorder: {
					show: true,
				},
				axisTicks: {
					show: false,
				},
				position: 'outside',
				categories :['1','2','3','4','5'],
				type: 'category'
			},
			yaxis:{
				labels:{
					show:true,
				},
				axisBorder: {
					show: false,
				},
				axisTicks: {
					show: false,
				},
			},
			dataLabels: {
				enabled: true,
				offsetX: 300,
				style: {
					colors: ['#a6a6a6'],
					fontFamily: 'Inter',
					fontSize: '10px',
				},
				formatter: function (val: any, opts) {
					let total = opts.w.globals.seriesTotals.reduce(
						(a, b) => a + b,
						0
					);
					let percentage = ((val / total) * 100).toFixed(0);
					return `${val}(${percentage}%)`;
				},
				textAnchor: 'middle',
			},
			fill: {
				opacity: 100,
				colors: ['#8AC6E7','#54ABDC','#0082CB', '#016299', '#003755'],
			},
			legend: {
				show: false,
			},
			stroke: {
				show: true,
				width: 2,
				colors: ['var(--color-container)'],
			},
		};
	}
}

export type ChartOptions = {
	series: ApexAxisChartSeries;
	chart: ApexChart;
	dataLabels: ApexDataLabels;
	plotOptions: ApexPlotOptions;
	xaxis: ApexXAxis;
	yaxis: ApexYAxis;
	responsive: ApexResponsive[];
	legend: ApexLegend;
	fill: ApexFill;
	stroke: ApexStroke;
};
