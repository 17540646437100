import { Component, EventEmitter, OnInit, Output, inject } from '@angular/core';
import { DataLocalStorage, ReturnProfile } from '../../sidebar-menu/sidebar.interfaces';
import { EncryptDataService } from '../../../../core/security/encypt-data.service';
import { NavigationEnd, Router } from '@angular/router';
import { UserAuthService } from 'src/app/modules/auth/services/user-auth.service';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/app/core/services/data.service';
import { NotificationMenu, UsersMenu } from 'src/app/core/models';
import { LogsService } from 'src/app/core/http/logs.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-sidebar-dashboard',
  templateUrl: './sidebar-dashboard.component.html',
  styleUrls: ['./sidebar-dashboard.component.scss']
})
export class SidebarDashboardComponent implements OnInit {
	public securityLocalStorage = inject(EncryptDataService);
	public router      			= inject(Router);
	public userAuthService		= inject(UserAuthService);
	public userService			= inject(UserService);
	public translate  			= inject(TranslateService);
	public dataService 			= inject(DataService)
	public logsService			= inject(LogsService);
	data_menu;
	data_profile:ReturnProfile[];
	selectedPage: string | null = null;
	data:DataLocalStorage = JSON.parse(this.securityLocalStorage.decryptData(localStorage.getItem('data')));
	openLanguage:boolean = false;
	openAppareance:boolean = false;
	showSettings:boolean = false;
	showSettingsClosed:boolean =false;
	showPanelMenuMobile:boolean =false;
	language:string;
	mode: string = localStorage.getItem('mode') || 'light';
	modeSelected: string;
	sideBarClosed:boolean=false;
	profile:string;
	notificationSeen = true;
	showNotification = false;
	sizeOrders: number;
	sizeUsers: number;
	sizeAll: number=0;
	notificationOrders: NotificationMenu[] = [];
	notificationUser: UsersMenu[] = [];
	all:any = [];
	errorNotificacionesOrdenes: boolean = false;
	errorNotificacionesUsers: boolean = false;
	private intervalId: ReturnType<typeof setInterval> | undefined;
	lstNotifications: any[] = [];
	numIteracion = 0;
	systemVersion:string;
	@Output() sideBarClosedChange = new EventEmitter<boolean>();
	ngOnInit(){
		this.userAuthService.getSystemVersion().then((res:any)=>{
			this.systemVersion = res.version;
			if(res.mensaje_return.ERROS_MENSAGGE ==='SUCCESS'){
				this.systemVersion = res.version;
			}
		});
		this.language = localStorage.getItem('language');
		this.mode = localStorage.getItem('mode') || 'light';
		const currentRoute = this.router.url;
		const parts = currentRoute.split('/');
		this.selectedPage =  parts[2]
		this.data_menu = this.data.data_menu.return_menu;
		console.log('dataMenu',this.data_menu);
		this.data_profile =  this.data.data_menu.return_profiles;
		this.data_profile[0].icon = this.data_profile[0].icon = 'puzzle-piece-01';
		this.data_profile[0].txt = this.data_profile[0].txt = 'integrations';
		if(this.data.result.id_user_type === 9){
			this.data_profile.push({
				txt:'webhook',
				icon:'tool-01',
				route:'config-webhook'
			})
		}
		this.profile = "";
		const word = this.data.data_company.alias_username.split(" ");
		for (const w of word) {
			if (w.length > 0) {
				this.profile += w[0];
			}
		}
		let obj = {
			language: localStorage.getItem('language')
		}
		this.userService.getListTypeUser(obj).then(res => {
			if (res.mensaje_return.CODE !== 200) {
				this.router.events.subscribe(event => {
					if (event instanceof NavigationEnd) {
						if (event.url === '/auth/new-login') {
							this.clearInterval();
						}
					}
				});
			}
			else if (res.mensaje_return.CODE === 200) {
				this.getNotifications();
				this.intervalId = setInterval(() => {
					this.all = [];
					if (this.errorNotificacionesOrdenes === true || this.errorNotificacionesUsers === true) {
						this.all = [];
					}
					else {
						this.all = [];
						this.numIteracion++
						this.getNotifications();
					}
				}, 60000);
				this.router.events.subscribe(event => {
					if (event instanceof NavigationEnd) {

						if (event.url === '/auth/new-login') {
							this.clearInterval();
						}
					}
				});
			}
		})
	}
	goToPage(item){
		this.showSettings = false;
		this.showPanelMenuMobile = false;
		this.selectedPage = item.route;
		if(item.route !== ''){
			if(this.data.result.id_user_type === 6){
				this.router.navigateByUrl('/warehouse/'+item.route)
			}
			else if(this.data.result.id_user_type === 7){
				this.router.navigateByUrl('/vivipost/'+item.route)
			}
			else if(this.data.result.id_user_type === 9){
				this.router.navigateByUrl('/superadmin/'+item.route)
			}
			else if(this.data.result.id_user_type === 10){
				this.router.navigateByUrl('/carriers/'+item.route)
			}
			else if(this.data.result.id_user_type === 11){
				this.router.navigateByUrl('/local-admin/'+item.route)
			}
			else if(this.data.result.id_user_type === 12){
				this.router.navigateByUrl('/supervisor/'+item.route)
			}
		}
	}
	goToIntegrations(){
	}
	showPanelNotifications(){
		window.scroll({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
		this.showPanelMenuMobile = false;
		this.showNotification = !this.showNotification;
	}
	showMenuMobile(){
		this.showPanelMenuMobile = true;
		this.showNotification = false;
	}
	closePanelMobile(){
		this.showSettings = false;
		this.showPanelMenuMobile = false;
	}
	private clearInterval() {
		if (this.intervalId !== undefined) {
			clearInterval(this.intervalId);
			this.intervalId = undefined;
		}
	}
	async getNotifications() {
		try {
			let obj = {
				language: localStorage.getItem('language'),
				limit: 10
			};
			this.sizeOrders = 0;
			this.sizeUsers = 0;
			this.sizeAll=0
			let lstNewNotification: any[] = [];
			let allNotifications = localStorage.getItem("lstNotifications") ? JSON.parse(localStorage.getItem("lstNotifications")) : []

			const [dataOrders, dataUsers]: any = await Promise.all([
				this.logsService.getNotificationsWarehouse(obj),
				this.logsService.getUsersWarehouse(obj)
			]);

			//Ordes======================================================================
			this.notificationOrders = [];
			dataOrders.return_logs.forEach((item: NotificationMenu) => {
				this.notificationOrders.push({
					id_topic_audit: item.id_topic_audit,
					locker_name: item.locker_name,
					order_number: item.order_number,
					registration_date: item.registration_date,
					time_elapsed: item.time_elapsed,
					topic_name: item.topic_name,
					tracking_number: item.tracking_number,
					id_service:item.id_service
				});
				this.all.push({
					id_topic_audit: item.id_topic_audit,
					locker_name: item.locker_name,
					order_number: item.order_number,
					registration_date: item.registration_date,
					time_elapsed: item.time_elapsed,
					topic_name: item.topic_name,
					tracking_number: item.tracking_number,
					id_service:item.id_service,
					isOrder: true,
					isUser: false,
					read:false
				});
			});
			//Users======================================================================
			this.notificationUser = [];
			dataUsers.return_logs.forEach((item: UsersMenu) => {
				this.notificationUser.push({
					company_employee_id: item.company_employee_id,
					description_audit: item.description_audit,
					full_name: item.full_name,
					id_topic_audit: item.id_topic_audit,
					locker_name: item.locker_name,
					registration_date: item.registration_date,
					time_elapsed: item.time_elapsed,
					topic_name: item.topic_name
				});
				this.all.push({
					id_audit_company_employee:item.id_audit_company_employee,
					company_employee_id: item.company_employee_id,
					description_audit: item.description_audit,
					full_name: item.full_name,
					id_topic_audit: item.id_topic_audit,
					locker_name: item.locker_name,
					registration_date: item.registration_date,
					time_elapsed: item.time_elapsed,
					topic_name: item.topic_name,
					isOrder: false,
					isUser: true,
					read: false
				})
			});

			this.all.forEach(objeto2 => {
				const objeto1 = allNotifications.find(o => {
					if (o.isOrder) {
						return (o.id_service === objeto2.id_service && o.id_topic_audit===objeto2.id_topic_audit  && objeto2.read === false)
					}
					else {
						return (o.id_audit_company_employee === objeto2.id_audit_company_employee && objeto2.read === false)
					}
				});
				if (objeto1) {
				} else {
					allNotifications.push(objeto2);
				}
			});

			this.lstNotifications = this.sortNotifications(allNotifications);
			this.cleanLst(allNotifications);
			allNotifications.forEach(objeto2 => {
				if (objeto2.isOrder && objeto2.read === false) {
					this.sizeOrders++;
					this.sizeAll++;
				}
				else if (objeto2.isUser && objeto2.read === false) {
					this.sizeUsers++;
					this.sizeAll++;
				}
			})


		} catch (error) {
		}
	}
	sortNotifications(list: any[]): any[] {
		list.forEach(obj => {
			obj.registration_date = new Date(obj.registration_date);
		});

		list.sort((a, b) => {
			return b.registration_date.getTime() - a.registration_date.getTime()
		});

		return list;
	}
	cleanLst(allNotifications:any){
		const idsEnThisAll = this.all.map(objeto => {
			if (objeto.isOrder) {
				return `${objeto.id_service}-${objeto.id_topic_audit}`;
			} else {
				return objeto.id_audit_company_employee;
			}
		});

		const objetosNoEncontrados = allNotifications.filter(objeto1 => {
			if (objeto1.isOrder) {
				return !idsEnThisAll.includes(`${objeto1.id_service}-${objeto1.id_topic_audit}`);
			} else {
				return !idsEnThisAll.includes(objeto1.id_audit_company_employee);
			}
		});

		objetosNoEncontrados.forEach(objetoNoEncontrado => {
			const index = allNotifications.indexOf(objetoNoEncontrado);
			if (index !== -1) {
				allNotifications.splice(index, 1);
			}
		});

		localStorage.setItem("lstNotifications", JSON.stringify(allNotifications));
	}
	cleanNotifications(){
		this.sizeOrders=0;
		this.sizeUsers=0;
		this.sizeAll=0;
	}
	cleanItemNotifications(){
		this.sizeAll--;
	}
	openItem(item){
		if(item.txt === 'Idioma'){
			this.openLanguage = true;
			this.openAppareance = false;
		}
		if(item.txt === 'Apariencia'){
			this.openLanguage = false;
			this.openAppareance = true;
		}
	}
	openLanguageItem(){
		this.sideBarClosed = false;
		this.openLanguage = !this.openLanguage;
		this.openAppareance = false;
	}
	openAppareanceItem(){
		this.sideBarClosed = false;
		this.openLanguage = false;
		this.openAppareance = !this.openAppareance;
	}
	showSettingsContainer(){
		this.showSettings = !this.showSettings;
		this.showSettingsClosed = false;
	}
	showSettingsContainerClosed(){
		this.showSettings = false;
		this.showSettingsClosed = !this.showSettingsClosed;
	}
	goToAccount(){
		this.showSettings = false;
		this.showPanelMenuMobile = false;

		if(this.data.result.id_user_type === 6){
			this.router.navigateByUrl('/warehouse/account-settings')
		}
		else if(this.data.result.id_user_type === 7){
			this.router.navigateByUrl('/vivipost/account-settings')
		}
		else if(this.data.result.id_user_type === 9){
			this.router.navigateByUrl('/superadmin/account-settings')
		}
		else if(this.data.result.id_user_type === 10){
			this.router.navigateByUrl('/carriers/account-settings')
		}
		else if(this.data.result.id_user_type === 11){
			this.router.navigateByUrl('/local-admin/account-settings')
		}
		else if(this.data.result.id_user_type === 12){
			this.router.navigateByUrl('/supervisor/account-settings')
		}
	}
	goToVersion(){
		this.showSettings = false;
		this.showPanelMenuMobile = false;
		// this.router.navigateByUrl('/warehouse/version');

		if(this.data.result.id_user_type === 6){
			this.router.navigateByUrl('/warehouse/version')
		}
		else if(this.data.result.id_user_type === 7){
			this.router.navigateByUrl('/vivipost/version')
		}
		else if(this.data.result.id_user_type === 9){
			this.router.navigateByUrl('/superadmin/version')
		}
		else if(this.data.result.id_user_type === 10){
			this.router.navigateByUrl('/carriers/version')
		}
		else if(this.data.result.id_user_type === 11){
			this.router.navigateByUrl('/local-admin/version')
		}
		else if(this.data.result.id_user_type === 12){
			this.router.navigateByUrl('/supervisor/version')
		}
	}
	logout() {
		let obj = {
			language:'es',
			id_user: this.data.result.id_user,
			id_company: this.data.data_company.id_company
		}
		this.userAuthService.logout(obj).then((res:any)=>{
			localStorage.removeItem('token');
			localStorage.removeItem('data');
			localStorage.removeItem('user_type');
			localStorage.removeItem('id_user');
			localStorage.removeItem('id_company');
			localStorage.removeItem('courier_id');
			localStorage.setItem('language','es');
			this.router.navigateByUrl('/auth/new-login');
		}).catch((error:any)=>{
			console.log(error);
		})
	}
	translateToEs(){
		localStorage.setItem('language','es')
		this.translate.use('es');
		this.language = 'es';
	}
	translateToEn(){
		localStorage.setItem('language','en')
		this.translate.use('en');
		this.language = 'en';
	}
	changeModeLight(mode: string): void {
		this.mode = 'light';
		this.changeMode('light');
	}
	changeModeDark(mode: string): void {
		this.mode = 'dark';
		this.changeMode('dark');
	}

	changeMode(mode: string): void {
		// Remove previous mode classes if present
		document.body.classList.remove('root', 'dark-mode');

		// Set the mode and apply the appropriate class
		if (mode === 'light') {
			document.body.classList.add('root');
		} else if (mode === 'dark') {
			document.body.classList.add('dark-mode');
		}

		// Update the local mode variable and store it in localStorage
		this.mode = mode;
		localStorage.setItem('mode', mode);
		this.modeSelected = this.dataService.mode;
	}
	closeSidebar(){
		this.showSettings = false;
		this.showSettingsClosed = false;
		this.sideBarClosed = true;
		this.sideBarClosedChange.emit(true);
	}
	openSidebar(){
		this.showSettings = false;
		this.showSettingsClosed = false;
		this.sideBarClosed = false;
		this.sideBarClosedChange.emit(false);
	}
	iconName(icon){
		console.log(icon);
	}
}
