<div class="cont_main">
    <div class="row">
		<div class="col-12 col-md-12 col-lg-12 col-xl-4">
			<div class="input-container">
				<input
					type="text"
					class="input-gbl"
					(keyup)="onFilterTextChange($event)"
					placeholder="{{'dashboard.search_user' | translate}}">
				<button
					type="submit"
					class="btn-search">
					<div class="icon-search">
					</div>
				</button>
			</div>
		</div>
		<div class="col-12 col-md-12 col-lg-12 col-xl-8 alignEnd">
			<div class="calendar_filter_export">
				<app-button-icon *ngIf="this.mode ==='light'"
					[activo]="false"
					btnNombre="buttons.export"
					btnType="primaryMedium"
					icon="icon-file-download-disabled"
					(click)="onExport()">
				</app-button-icon>
				<app-button-icon *ngIf="this.mode ==='dark'"
					[activo]="false"
					btnNombre="buttons.export"
					btnType="primaryMedium"
					icon="icon-file-download-disabled"
					(click)="onExport()">
				</app-button-icon>
				<!-- <button-export (click)="onExport()"></button-export> -->
			</div>

		</div>

		<div class="col-12 col-md-12 col-lg-12 col-xl-12">
			<ag-grid-angular
				style="width: 100%; height: 748px; border: none; margin-top:24px"
				class="ag-theme-alpine"
				[columnDefs]="this.columnDefs"
				[gridOptions]="gridOptions"
				[rowData]="rowData"
				(orderDeleted)="onOrderDeleted($event)"
				(gridReady)="onGridReady($event)">
			</ag-grid-angular>
		</div>
	</div>

	<!-- ? TABLE -->
	<!-- <div class="block_table">
		<ag-grid-angular
			class="ag-theme-alpine"
			[columnDefs]="this.columnDefs"
			[gridOptions]="gridOptions"
			[rowData]="rowData"
			(orderDeleted)="onOrderDeleted($event)"
			(gridReady)="onGridReady($event)">
		</ag-grid-angular>

	</div> -->

</div>
