<div class="cont_main">
    <div class="block_header">
        <div class="input_main_cont">
            <input
                [(ngModel)]="filterText"
                type="text"
                class="input_main"
                (keyup)="onFilterTextChange($event)"
                placeholder="{{'search.oxxoToOxxo' | translate}}">
            <img class="icon_sm" src="../../../../../../assets/icons/icon-search.svg">
        </div>
        <div class="calendar_filter_export">
            <div class="input_cont_calendar">
                <p-calendar
                    styleClass="input_custome"
                    [showIcon]="true"
                    [(ngModel)]="rangeDates"
                    (ngModelChange)="addEventRangeDates()"
                    selectionMode="range"
                    [readonlyInput]="true"
                    inputId="rangeExpired"
                    [placeholder]="formatDatePlaceholder(rangeDatesPlaceholder[0],rangeDatesPlaceholder[1])">
                </p-calendar>
            </div>
			<button-export (click)="onExport()" [isDisabled]="filteredData && filteredData.length>0?false:true"></button-export>
        </div>
    </div>
    <div class="block_table">
        <ag-grid-angular
            #agGrid
            class="ag-theme-alpine"
            [gridOptions]="gridOptions"
            [rowData]="rowData"
            suppressScrollOnNewData = true
            [columnDefs]="this.columnDefsGeneralOTO"
            (gridReady)="onGridReady($event)"
            (displayedColumnsChanged)="onDisplayedColumnsChanged($event)"
            (sortChanged)="onSortChanged($event)">
        </ag-grid-angular>
    </div>
</div>
