<div *ngIf="showAlert === true">
	<div *ngIf="alertType === 'small'">
			<div class="alert-sm-container-right-bottom" *ngIf="position ==='bottom-right'">
				<div class="alert-sm-box">
					<div class="alert-sm-img">
						<img [src]="urlSuccess" *ngIf="imgType === 'success'" alt="Success">
						<img [src]="urlError" *ngIf="imgType === 'error'" alt="error">
						<img [src]="urlInfo" *ngIf="imgType === 'info'" alt="info">
						<img [src]="urlZap" *ngIf="imgType === 'zap'" alt="Zap">
						<div class="text">
							{{textAlert  | translate}}
						</div>
					</div>
					<div class="action" [routerLink]="primaryActionUrl">
						{{primaryActionTxt  | translate}}
					</div>
					<div class="counter" *ngIf="activateInterval && counterValue > 0" [style.width.%]="counterValue">
					</div>
					<app-close-button (click)="closeAlert()">
					</app-close-button>
				</div>
			</div>

			<div class="alert-sm-container-center-bottom" *ngIf="position ==='bottom-center'">
				<div class="alert-sm-box">
					<div class="alert-sm-img">
						<img [src]="urlSuccess" *ngIf="imgType === 'success'" alt="Success">
						<img [src]="urlError" *ngIf="imgType === 'error'" alt="error">
						<img [src]="urlInfo" *ngIf="imgType === 'info'" alt="info">
						<img [src]="urlZap" *ngIf="imgType === 'zap'" alt="Zap">
						<div class="text">
							{{textAlert  | translate}}
						</div>
					</div>
					<div class="action" [routerLink]="primaryActionUrl">
						{{primaryActionTxt  | translate}}
					</div>
					<div class="counter" *ngIf="activateInterval && counterValue > 0" [style.width.%]="counterValue">
					</div>
					<app-close-button (click)="closeAlert()">
					</app-close-button>
				</div>
			</div>

			<div class="alert-sm-container-left-bottom" *ngIf="position ==='bottom-left'">
				<div class="alert-sm-box">
					<div class="alert-sm-img">
						<img [src]="urlSuccess" *ngIf="imgType === 'success'" alt="Success">
						<img [src]="urlError" *ngIf="imgType === 'error'" alt="error">
						<img [src]="urlInfo" *ngIf="imgType === 'info'" alt="info">
						<img [src]="urlZap" *ngIf="imgType === 'zap'" alt="Zap">
						<div class="text">
							{{textAlert  | translate}}
						</div>
					</div>
					<div class="action" [routerLink]="primaryActionUrl">
						{{primaryActionTxt  | translate}}
					</div>
					<div class="counter" *ngIf="activateInterval && counterValue > 0" [style.width.%]="counterValue">
					</div>
					<app-close-button (click)="closeAlert()">
					</app-close-button>
				</div>
			</div>

			<div class="alert-sm-container-right-center" *ngIf="position ==='right-center'">
				<div class="alert-sm-box">
					<div class="alert-sm-img">
						<img [src]="urlSuccess" *ngIf="imgType === 'success'" alt="Success">
						<img [src]="urlError" *ngIf="imgType === 'error'" alt="error">
						<img [src]="urlInfo" *ngIf="imgType === 'info'" alt="info">
						<img [src]="urlZap" *ngIf="imgType === 'zap'" alt="Zap">
						<div class="text">
							{{textAlert  | translate}}
						</div>
					</div>
					<div class="action" [routerLink]="primaryActionUrl">
						{{primaryActionTxt  | translate}}
					</div>
					<div class="counter" *ngIf="activateInterval && counterValue > 0" [style.width.%]="counterValue">
					</div>
					<app-close-button (click)="closeAlert()">
					</app-close-button>
				</div>
			</div>

			<div class="alert-sm-container-center" *ngIf="position ==='center'">
				<div class="alert-sm-box">
					<div class="alert-sm-img">
						<img [src]="urlSuccess" *ngIf="imgType === 'success'" alt="Success">
						<img [src]="urlError" *ngIf="imgType === 'error'" alt="error">
						<img [src]="urlInfo" *ngIf="imgType === 'info'" alt="info">
						<img [src]="urlZap" *ngIf="imgType === 'zap'" alt="Zap">
						<div class="text">
							{{textAlert  | translate}}
						</div>
					</div>
					<div class="action" [routerLink]="primaryActionUrl">
						{{primaryActionTxt  | translate}}
					</div>
					<div class="counter" *ngIf="activateInterval && counterValue > 0" [style.width.%]="counterValue">
					</div>
					<app-close-button (click)="closeAlert()">
					</app-close-button>
				</div>
			</div>

			<div class="alert-sm-container-left-center" *ngIf="position ==='left-center'">
				<div class="alert-sm-box">
					<div class="alert-sm-img">
						<img [src]="urlSuccess" *ngIf="imgType === 'success'" alt="Success">
						<img [src]="urlError" *ngIf="imgType === 'error'" alt="error">
						<img [src]="urlInfo" *ngIf="imgType === 'info'" alt="info">
						<img [src]="urlZap" *ngIf="imgType === 'zap'" alt="Zap">
						<div class="text">
							{{textAlert  | translate}}
						</div>
					</div>
					<div class="action" [routerLink]="primaryActionUrl">
						{{primaryActionTxt  | translate}}
					</div>
					<div class="counter" *ngIf="activateInterval && counterValue > 0" [style.width.%]="counterValue">
					</div>
					<app-close-button (click)="closeAlert()">
					</app-close-button>
				</div>
			</div>

			<div class="alert-sm-container-top-right" *ngIf="position ==='top-right'">
				<div class="alert-sm-box">
					<div class="alert-sm-img">
						<img [src]="urlSuccess" *ngIf="imgType === 'success'" alt="Success">
						<img [src]="urlError" *ngIf="imgType === 'error'" alt="error">
						<img [src]="urlInfo" *ngIf="imgType === 'info'" alt="info">
						<img [src]="urlZap" *ngIf="imgType === 'zap'" alt="Zap">
						<div class="text">
							{{textAlert  | translate}}
						</div>
					</div>
					<div class="action" [routerLink]="primaryActionUrl">
						{{primaryActionTxt  | translate}}
					</div>
					<div class="counter" *ngIf="activateInterval && counterValue > 0" [style.width.%]="counterValue">
					</div>
					<app-close-button (click)="closeAlert()">
					</app-close-button>
				</div>
			</div>

			<div class="alert-sm-container-top-center" *ngIf="position ==='top-center'">
				<div class="alert-sm-box">
					<div class="alert-sm-img">
						<img [src]="urlSuccess" *ngIf="imgType === 'success'" alt="Success">
						<img [src]="urlError" *ngIf="imgType === 'error'" alt="error">
						<img [src]="urlInfo" *ngIf="imgType === 'info'" alt="info">
						<img [src]="urlZap" *ngIf="imgType === 'zap'" alt="Zap">
						<div class="text">
							{{textAlert  | translate}}
						</div>
					</div>
					<div class="action" [routerLink]="primaryActionUrl">
						{{primaryActionTxt  | translate}}
					</div>
					<div class="counter" *ngIf="activateInterval && counterValue > 0" [style.width.%]="counterValue">
					</div>
					<app-close-button (click)="closeAlert()">
					</app-close-button>
				</div>
			</div>

			<div class="alert-sm-container-top-left" *ngIf="position ==='top-left'">
				<div class="alert-sm-box">
					<div class="alert-sm-img">
						<img [src]="urlSuccess" *ngIf="imgType === 'success'" alt="Success">
						<img [src]="urlError" *ngIf="imgType === 'error'" alt="error">
						<img [src]="urlInfo" *ngIf="imgType === 'info'" alt="info">
						<img [src]="urlZap" *ngIf="imgType === 'zap'" alt="Zap">
						<div class="text">
							{{textAlert  | translate}}
						</div>
					</div>
					<div class="action" [routerLink]="primaryActionUrl">
						{{primaryActionTxt  | translate}}
					</div>
					<div class="counter" *ngIf="activateInterval && counterValue > 0" [style.width.%]="counterValue">
					</div>
					<app-close-button (click)="closeAlert()">
					</app-close-button>
				</div>
			</div>
	</div>

	<div *ngIf="alertType === 'medium'">
			<div class="alert-md-container-right-bottom" *ngIf="position ==='bottom-right'">
				<div class="alert-md-box">
					<div class="alert-md-img">
						<img [src]="urlSuccess" *ngIf="imgType === 'success'" alt="Success">
						<img [src]="urlError" *ngIf="imgType === 'error'" alt="error">
						<img [src]="urlInfo" *ngIf="imgType === 'info'" alt="info">
						<img [src]="urlZap" *ngIf="imgType === 'zap'" alt="Zap">
						<div class="textBox">
							<div class="text">
								{{textAlert  | translate}}
							</div>
							<div class="subtitle">
								{{subtitleAlert  | translate}}
							</div>
						</div>
					</div>
					<div class="counter" *ngIf="activateInterval && counterValue > 0" [style.width.%]="counterValue">
					</div>
					<app-close-button (click)="closeAlert()">
					</app-close-button>
				</div>
			</div>

			<div class="alert-md-container-center-bottom" *ngIf="position ==='bottom-center'">
				<div class="alert-md-box">
					<div class="alert-md-img">
						<img [src]="urlSuccess" *ngIf="imgType === 'success'" alt="Success">
						<img [src]="urlError" *ngIf="imgType === 'error'" alt="error">
						<img [src]="urlInfo" *ngIf="imgType === 'info'" alt="info">
						<img [src]="urlZap" *ngIf="imgType === 'zap'" alt="Zap">
						<div class="textBox">
							<div class="text">
								{{textAlert  | translate}}
							</div>
							<div class="subtitle">
								{{subtitleAlert  | translate}}
							</div>
						</div>
					</div>
					<div class="counter" *ngIf="activateInterval && counterValue > 0" [style.width.%]="counterValue">
					</div>
					<app-close-button (click)="closeAlert()">
					</app-close-button>
				</div>
			</div>


			<div class="alert-md-container-left-bottom" *ngIf="position ==='bottom-left'">
				<div class="alert-md-box">
					<div class="alert-md-img">
						<img [src]="urlSuccess" *ngIf="imgType === 'success'" alt="Success">
						<img [src]="urlError" *ngIf="imgType === 'error'" alt="error">
						<img [src]="urlInfo" *ngIf="imgType === 'info'" alt="info">
						<img [src]="urlZap" *ngIf="imgType === 'zap'" alt="Zap">
						<div class="textBox">
							<div class="text">
								{{textAlert  | translate}}
							</div>
							<div class="subtitle">
								{{subtitleAlert  | translate}}
							</div>
						</div>
					</div>
					<div class="counter" *ngIf="activateInterval && counterValue > 0" [style.width.%]="counterValue">
					</div>
					<app-close-button (click)="closeAlert()">
					</app-close-button>
				</div>
			</div>


			<div class="alert-md-container-right-center" *ngIf="position ==='right-center'">
				<div class="alert-md-box">
					<div class="alert-md-img">
						<img [src]="urlSuccess" *ngIf="imgType === 'success'" alt="Success">
						<img [src]="urlError" *ngIf="imgType === 'error'" alt="error">
						<img [src]="urlInfo" *ngIf="imgType === 'info'" alt="info">
						<img [src]="urlZap" *ngIf="imgType === 'zap'" alt="Zap">
						<div class="textBox">
							<div class="text">
								{{textAlert  | translate}}
							</div>
							<div class="subtitle">
								{{subtitleAlert  | translate}}
							</div>
						</div>
					</div>
					<div class="counter" *ngIf="activateInterval && counterValue > 0" [style.width.%]="counterValue">
					</div>
					<app-close-button (click)="closeAlert()">
					</app-close-button>
				</div>
			</div>


			<div class="alert-md-container-center" *ngIf="position ==='center'">
				<div class="alert-md-box">
					<div class="alert-md-img">
						<img [src]="urlSuccess" *ngIf="imgType === 'success'" alt="Success">
						<img [src]="urlError" *ngIf="imgType === 'error'" alt="error">
						<img [src]="urlInfo" *ngIf="imgType === 'info'" alt="info">
						<img [src]="urlZap" *ngIf="imgType === 'zap'" alt="Zap">
						<div class="textBox">
							<div class="text">
								{{textAlert  | translate}}
							</div>
							<div class="subtitle">
								{{subtitleAlert  | translate}}
							</div>
						</div>
					</div>
					<div class="counter" *ngIf="activateInterval && counterValue > 0" [style.width.%]="counterValue">
					</div>
					<app-close-button (click)="closeAlert()">
					</app-close-button>
				</div>
			</div>


			<div class="alert-md-container-left-center" *ngIf="position ==='left-center'">
				<div class="alert-md-box">
					<div class="alert-md-img">
						<img [src]="urlSuccess" *ngIf="imgType === 'success'" alt="Success">
						<img [src]="urlError" *ngIf="imgType === 'error'" alt="error">
						<img [src]="urlInfo" *ngIf="imgType === 'info'" alt="info">
						<img [src]="urlZap" *ngIf="imgType === 'zap'" alt="Zap">
						<div class="textBox">
							<div class="text">
								{{textAlert  | translate}}
							</div>
							<div class="subtitle">
								{{subtitleAlert  | translate}}
							</div>
						</div>
					</div>
					<div class="counter" *ngIf="activateInterval && counterValue > 0" [style.width.%]="counterValue">
					</div>
					<app-close-button (click)="closeAlert()">
					</app-close-button>
				</div>
			</div>


			<div class="alert-md-container-top-right" *ngIf="position ==='top-right'">
				<div class="alert-md-box">
					<div class="alert-md-img">
						<img [src]="urlSuccess" *ngIf="imgType === 'success'" alt="Success">
						<img [src]="urlError" *ngIf="imgType === 'error'" alt="error">
						<img [src]="urlInfo" *ngIf="imgType === 'info'" alt="info">
						<img [src]="urlZap" *ngIf="imgType === 'zap'" alt="Zap">
						<div class="textBox">
							<div class="text">
								{{textAlert  | translate}}
							</div>
							<div class="subtitle">
								{{subtitleAlert  | translate}}
							</div>
						</div>
					</div>
					<div class="counter" *ngIf="activateInterval && counterValue > 0" [style.width.%]="counterValue">
					</div>
					<app-close-button (click)="closeAlert()">
					</app-close-button>
				</div>
			</div>


			<div class="alert-md-container-top-center" *ngIf="position ==='top-center'">
				<div class="alert-md-box">
					<div class="alert-md-img">
						<img [src]="urlSuccess" *ngIf="imgType === 'success'" alt="Success">
						<img [src]="urlError" *ngIf="imgType === 'error'" alt="error">
						<img [src]="urlInfo" *ngIf="imgType === 'info'" alt="info">
						<img [src]="urlZap" *ngIf="imgType === 'zap'" alt="Zap">
						<div class="textBox">
							<div class="text">
								{{textAlert  | translate}}
							</div>
							<div class="subtitle">
								{{subtitleAlert  | translate}}
							</div>
						</div>
					</div>
					<div class="counter" *ngIf="activateInterval && counterValue > 0" [style.width.%]="counterValue">
					</div>
					<app-close-button (click)="closeAlert()">
					</app-close-button>
				</div>
			</div>


			<div class="alert-md-container-top-left" *ngIf="position ==='top-left'">
				<div class="alert-md-box">
					<div class="alert-md-img">
						<img [src]="urlSuccess" *ngIf="imgType === 'success'" alt="Success">
						<img [src]="urlError" *ngIf="imgType === 'error'" alt="error">
						<img [src]="urlInfo" *ngIf="imgType === 'info'" alt="info">
						<img [src]="urlZap" *ngIf="imgType === 'zap'" alt="Zap">
						<div class="textBox">
							<div class="text">
								{{textAlert  | translate}}
							</div>
							<div class="subtitle">
								{{subtitleAlert  | translate}}
							</div>
						</div>
					</div>
					<div class="counter" *ngIf="activateInterval && counterValue > 0" [style.width.%]="counterValue">
					</div>
					<app-close-button (click)="closeAlert()">

					</app-close-button>
				</div>
			</div>

	</div>

	<div *ngIf="alertType === 'large'">

			<div class="alert-lg-container-right-bottom" *ngIf="position ==='bottom-right'">
				<div class="alert-lg-box">
					<div class="alert-md-img">
						<img [src]="urlSuccess" *ngIf="imgType === 'success'" alt="Success">
						<img [src]="urlError" *ngIf="imgType === 'error'" alt="error">
						<img [src]="urlInfo" *ngIf="imgType === 'info'" alt="info">
						<img [src]="urlZap" *ngIf="imgType === 'zap'" alt="Zap">
						<div class="textBox">
							<div class="text">
								{{textAlert  | translate}}
							</div>
							<div class="subtitle">
								{{subtitleAlert  | translate}}
							</div>
							<div class="actionBox">
								<div class="secondary-action" [routerLink]="secondaryActionUrl">
									{{secondaryActionTxt  | translate}}
								</div>
								<div class="primary-action" [routerLink]="primaryActionUrl">
									{{primaryActionTxt  | translate}}
								</div>
							</div>
						</div>
					</div>
					<div class="counter" *ngIf="activateInterval && counterValue > 0" [style.width.%]="counterValue">
					</div>
					<app-close-button (click)="closeAlert()">
					</app-close-button>
				</div>

			</div>


			<div class="alert-lg-container-center-bottom" *ngIf="position ==='bottom-center'">
				<div class="alert-lg-box">
					<div class="alert-md-img">
						<img [src]="urlSuccess" *ngIf="imgType === 'success'" alt="Success">
						<img [src]="urlError" *ngIf="imgType === 'error'" alt="error">
						<img [src]="urlInfo" *ngIf="imgType === 'info'" alt="info">
						<img [src]="urlZap" *ngIf="imgType === 'zap'" alt="Zap">
						<div class="textBox">
							<div class="text">
								{{textAlert  | translate}}
							</div>
							<div class="subtitle">
								{{subtitleAlert  | translate}}
							</div>
							<div class="actionBox">
								<div class="secondary-action" [routerLink]="secondaryActionUrl">
									{{secondaryActionTxt  | translate}}
								</div>
								<div class="primary-action" [routerLink]="primaryActionUrl">
									{{primaryActionTxt  | translate}}
								</div>
							</div>
						</div>
					</div>
					<div class="counter" *ngIf="activateInterval && counterValue > 0" [style.width.%]="counterValue">
					</div>
					<app-close-button (click)="closeAlert()">
					</app-close-button>
				</div>
			</div>


			<div class="alert-lg-container-left-bottom" *ngIf="position ==='bottom-left'">
				<div class="alert-lg-box">
					<div class="alert-md-img">
						<img [src]="urlSuccess" *ngIf="imgType === 'success'" alt="Success">
						<img [src]="urlError" *ngIf="imgType === 'error'" alt="error">
						<img [src]="urlInfo" *ngIf="imgType === 'info'" alt="info">
						<img [src]="urlZap" *ngIf="imgType === 'zap'" alt="Zap">
						<div class="textBox">
							<div class="text">
								{{textAlert  | translate}}
							</div>
							<div class="subtitle">
								{{subtitleAlert  | translate}}
							</div>
							<div class="actionBox">
								<div class="secondary-action" [routerLink]="secondaryActionUrl">
									{{secondaryActionTxt  | translate}}
								</div>
								<div class="primary-action" [routerLink]="primaryActionUrl">
									{{primaryActionTxt  | translate}}
								</div>
							</div>
						</div>
					</div>
					<div class="counter" *ngIf="activateInterval && counterValue > 0" [style.width.%]="counterValue">
					</div>
					<app-close-button (click)="closeAlert()">
					</app-close-button>
				</div>
			</div>

			<div class="alert-lg-container-right-center" *ngIf="position ==='right-center'">
				<div class="alert-lg-box">
					<div class="alert-md-img">
						<img [src]="urlSuccess" *ngIf="imgType === 'success'" alt="Success">
						<img [src]="urlError" *ngIf="imgType === 'error'" alt="error">
						<img [src]="urlInfo" *ngIf="imgType === 'info'" alt="info">
						<img [src]="urlZap" *ngIf="imgType === 'zap'" alt="Zap">
						<div class="textBox">
							<div class="text">
								{{textAlert  | translate}}
							</div>
							<div class="subtitle">
								{{subtitleAlert  | translate}}
							</div>
							<div class="actionBox">
								<div class="secondary-action" [routerLink]="secondaryActionUrl">
									{{secondaryActionTxt  | translate}}
								</div>
								<div class="primary-action" [routerLink]="primaryActionUrl">
									{{primaryActionTxt  | translate}}
								</div>
							</div>
						</div>
					</div>
					<div class="counter" *ngIf="activateInterval && counterValue > 0" [style.width.%]="counterValue">
					</div>
					<app-close-button (click)="closeAlert()">
					</app-close-button>
				</div>
			</div>

			<div class="alert-lg-container-center" *ngIf="position ==='center'">
				<div class="alert-lg-box">
					<div class="alert-md-img">
						<img [src]="urlSuccess" *ngIf="imgType === 'success'" alt="Success">
						<img [src]="urlError" *ngIf="imgType === 'error'" alt="error">
						<img [src]="urlInfo" *ngIf="imgType === 'info'" alt="info">
						<img [src]="urlZap" *ngIf="imgType === 'zap'" alt="Zap">
						<div class="textBox">
							<div class="text">
								{{textAlert  | translate}}
							</div>
							<div class="subtitle">
								{{subtitleAlert  | translate}}
							</div>
							<div class="actionBox">
								<div class="secondary-action" [routerLink]="secondaryActionUrl">
									{{secondaryActionTxt  | translate}}
								</div>
								<div class="primary-action" [routerLink]="primaryActionUrl">
									{{primaryActionTxt  | translate}}
								</div>
							</div>
						</div>
					</div>
					<div class="counter" *ngIf="activateInterval && counterValue > 0" [style.width.%]="counterValue">
					</div>
					<app-close-button (click)="closeAlert()">
					</app-close-button>
				</div>
			</div>


			<div class="alert-lg-container-left-center" *ngIf="position ==='left-center'">
				<div class="alert-lg-box">
					<div class="alert-md-img">
						<img [src]="urlSuccess" *ngIf="imgType === 'success'" alt="Success">
						<img [src]="urlError" *ngIf="imgType === 'error'" alt="error">
						<img [src]="urlInfo" *ngIf="imgType === 'info'" alt="info">
						<img [src]="urlZap" *ngIf="imgType === 'zap'" alt="Zap">
						<div class="textBox">
							<div class="text">
								{{textAlert  | translate}}
							</div>
							<div class="subtitle">
								{{subtitleAlert  | translate}}
							</div>
							<div class="actionBox">
								<div class="secondary-action" [routerLink]="secondaryActionUrl">
									{{secondaryActionTxt  | translate}}
								</div>
								<div class="primary-action" [routerLink]="primaryActionUrl">
									{{primaryActionTxt  | translate}}
								</div>
							</div>
						</div>
					</div>
					<div class="counter" *ngIf="activateInterval && counterValue > 0" [style.width.%]="counterValue">
					</div>
					<app-close-button (click)="closeAlert()">

					</app-close-button>
				</div>
			</div>


			<div class="alert-lg-container-top-right" *ngIf="position ==='top-right'">
				<div class="alert-lg-box">
					<div class="alert-md-img">
						<img [src]="urlSuccess" *ngIf="imgType === 'success'" alt="Success">
						<img [src]="urlError" *ngIf="imgType === 'error'" alt="error">
						<img [src]="urlInfo" *ngIf="imgType === 'info'" alt="info">
						<img [src]="urlZap" *ngIf="imgType === 'zap'" alt="Zap">
						<div class="textBox">
							<div class="text">
								{{textAlert  | translate}}
							</div>
							<div class="subtitle">
								{{subtitleAlert  | translate}}
							</div>
							<div class="actionBox">
								<div class="secondary-action" [routerLink]="secondaryActionUrl">
									{{secondaryActionTxt  | translate}}
								</div>
								<div class="primary-action" [routerLink]="primaryActionUrl">
									{{primaryActionTxt  | translate}}
								</div>
							</div>
						</div>
					</div>
					<div class="counter" *ngIf="activateInterval && counterValue > 0" [style.width.%]="counterValue">
					</div>
					<app-close-button (click)="closeAlert()">
					</app-close-button>
				</div>
			</div>


			<div class="alert-lg-container-top-center" *ngIf="position ==='top-center'">
				<div class="alert-lg-box">
					<div class="alert-md-img">
						<img [src]="urlSuccess" *ngIf="imgType === 'success'" alt="Success">
						<img [src]="urlError" *ngIf="imgType === 'error'" alt="error">
						<img [src]="urlInfo" *ngIf="imgType === 'info'" alt="info">
						<img [src]="urlZap" *ngIf="imgType === 'zap'" alt="Zap">
						<div class="textBox">
							<div class="text">
								{{textAlert  | translate}}
							</div>
							<div class="subtitle">
								{{subtitleAlert  | translate}}
							</div>
							<div class="actionBox">
								<div class="secondary-action" [routerLink]="secondaryActionUrl">
									{{secondaryActionTxt  | translate}}
								</div>
								<div class="primary-action" [routerLink]="primaryActionUrl">
									{{primaryActionTxt  | translate}}
								</div>
							</div>
						</div>
					</div>
					<div class="counter" *ngIf="activateInterval && counterValue > 0" [style.width.%]="counterValue">
					</div>
					<app-close-button (click)="closeAlert()">
					</app-close-button>
				</div>
			</div>


			<div class="alert-lg-container-top-left" *ngIf="position ==='top-left'">
				<div class="alert-lg-box">
					<div class="alert-md-img">
						<img [src]="urlSuccess" *ngIf="imgType === 'success'" alt="Success">
						<img [src]="urlError" *ngIf="imgType === 'error'" alt="error">
						<img [src]="urlInfo" *ngIf="imgType === 'info'" alt="info">
						<img [src]="urlZap" *ngIf="imgType === 'zap'" alt="Zap">
						<div class="textBox">
							<div class="text">
								{{textAlert  | translate}}
							</div>
							<div class="subtitle">
								{{subtitleAlert  | translate}}
							</div>
							<div class="actionBox">
								<div class="secondary-action" [routerLink]="secondaryActionUrl">
									{{secondaryActionTxt  | translate}}
								</div>
								<div class="primary-action" [routerLink]="primaryActionUrl">
									{{primaryActionTxt  | translate}}
								</div>
							</div>
						</div>
					</div>
					<div class="counter" *ngIf="activateInterval && counterValue > 0" [style.width.%]="counterValue">
					</div>
					<app-close-button (click)="closeAlert()">
					</app-close-button>
				</div>
			</div>

	</div>
</div>


