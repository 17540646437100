import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-time-picker',
	templateUrl: './time-picker.component.html',
	styleUrls: ['./time-picker.component.scss']
})
export class TimePickerComponent implements OnInit {
	@Output() timeSelected = new EventEmitter<string>();
	@Input() label: string;
	@Input() showError: boolean = false;  // Propiedad para mostrar el error
	@Input() errorMessage: string | null = null;  // Mensaje de error
	showBoxContainer = false;
	selectedHour: number = 24;
	selectedMinute: number = 0;
	selectedPeriod: string = 'AM';
	selectedTime: string | null = null;
	ngOnInit() {}

	openContainerTime() {
		this.showBoxContainer = true;
	}

	closeContainerTime() {
		this.showBoxContainer = false;
	}

	onTimeChange() {
		// Validación de la hora y minuto
		if (this.selectedHour < 1 || this.selectedHour > 24) {
			this.errorMessage = 'La hora debe ser entre 1 y 24';
			return;
		}
		if (this.selectedMinute < 0 || this.selectedMinute > 59) {
			this.errorMessage = 'Los minutos deben estar entre 0 y 59';
			return;
		}
		// Si todo es válido, limpias el error
		this.errorMessage = null;
	}
	setPeriod(period: string) {
		this.selectedPeriod = period;
	}

	clearTime() {
		this.selectedTime = null;
		this.timeSelected.emit('');
	}

	cancel() {
		this.closeContainerTime();
	}

	accept() {
		// Validar antes de aceptar el tiempo
		if (this.selectedHour < 1 || this.selectedHour > 23) {
			this.errorMessage = 'La hora debe ser entre 1 y 12';
			return;
		}
		if (this.selectedMinute < 0 || this.selectedMinute > 59) {
			this.errorMessage = 'Los minutos deben estar entre 0 y 59';
			return;
		}

		this.selectedTime = `${this.padZero(this.selectedHour)}:${this.padZero(this.selectedMinute)}:00`;
		this.timeSelected.emit(this.selectedTime);
		this.closeContainerTime();
		this.errorMessage = null; // Limpiar el mensaje de error al aceptar
	}

	private padZero(value: number): string {
		return value < 10 ? `0${value}` : `${value}`;
	}
}


