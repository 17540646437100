import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { UserAuthService } from 'src/app/modules/auth/services/user-auth.service';

@Component({
	selector: 'footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
	@Input() version: string = 'v2.0.0';
	@Input() client: string = 'Vivipost';
	systemVersion:string;
	urlData:string;

	constructor(private userService: UserAuthService,private router: Router){

	}
	ngOnInit(){
		var lastSpaceIndex = this.router.url.lastIndexOf(' ');
		var url = this.router.url.substring(lastSpaceIndex + 1);
		var urlParts = url.split('/');
		this.urlData = urlParts[1];
		this.userService.getSystemVersion().then((res:any)=>{
			this.systemVersion = res.version;
			if(res.mensaje_return.CODE === 200){
				this.systemVersion = res.version;
			}
		})
	}
}
