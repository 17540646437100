<div class="breadcrumb-container">
	<ng-container *ngFor="let page of displayedPages; let last = last">
		<ng-container *ngIf="page.label !== '...'; else ellipsis">
			<div class="breadcrumb-item" (mouseenter)="showTooltip(page)" (mouseleave)="hideTooltip()">
				<button
					class="breadcrumb-page"
					[class.active]="last"
					(click)="navigateTo(page.url)">
					{{ page.label | translate}}
				</button>
				<!-- <div class="tooltip-container" *ngIf="tooltipVisible && activeTooltip === page.label">
					<div class="label">
						{{ page.description || 'No description available.' | translate}}
					</div>
					<div class="tooltip-poligon">

					</div>
				</div> -->
			</div>
			<ng-container *ngIf="!last">
			<app-icon name="chevron-right" color="color-fg-tertiary" size="md"></app-icon>
			</ng-container>
		</ng-container>
		<ng-template #ellipsis>
			<div class="breadcrumb-item" (mouseenter)="showHiddenPages()" (mouseleave)="hideHiddenPages()">
				<div class="breadcrumb-ellipsis">...</div>
				<!-- <div class="tooltip-container-hidden" *ngIf="tooltipHiddenVisible">
					<ng-container *ngFor="let hiddenPage of hiddenPages">
						<button class="label" (click)="navigateTo(hiddenPage.url)">
							{{ hiddenPage.label | translate}}
						</button>
					</ng-container>
					<div class="tooltip-poligon">

					</div>
				</div> -->
			</div>
			<app-icon name="chevron-right" color="color-fg-tertiary" size="md"></app-icon>
		</ng-template>
	</ng-container>
</div>
