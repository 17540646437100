// ANGULAR
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { AgGridEvent, ColDef, GridApi, GridOptions } from 'ag-grid-community';
import { AgGridAngular } from 'ag-grid-angular';
import { EstatusTableOrderAdminComponent } from 'src/app/modules/admin-client/components/estatus-table-order-admin/estatus-table-order-admin.component';
import * as XLSX from 'xlsx';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Component, Input, OnInit, Output, SimpleChanges, ViewChild, EventEmitter, OnChanges } from '@angular/core';
import { AdminClientService } from 'src/app/core/http/admin-client.service';
import { TooltipComponent } from '@angular/material/tooltip';
import { DataService } from 'src/app/core/services/data.service';
import { EncryptDataService } from 'src/app/core/security/encypt-data.service';

@Component({
	selector: 'table-lockers',
	templateUrl: './table-lockers.component.html',
	styleUrls: ['./table-lockers.component.scss']
})

export class TableLockersComponent  implements OnInit, OnChanges {
	@Input() calendar: string = 'none';
	@Input() filterFunnel: string = 'none';
	@Input() btnExport: string = 'none';
	// @Input() tableClass: string = '';
	@Input() tableHeader: any[] = [];
	@Input() tableData: any[] = [];
	@Input() rowData: any[];
	@Input() gridOptions: GridOptions;
	@Output() emiter_select = new EventEmitter<string>();
	@Output() emiter_select_comparment = new EventEmitter<string>();
	@Input() data: string;
	@Input() header;
	@Input() register_package: boolean = false;
	@Input() list_compartment;
	@Input() columns: string;
	@Input() hover;
	@Input() link = 'true';
	@Input() selectedData: any;
	@Output() rowDataSizeChange = new EventEmitter<number>();
	@Output() rowDataEmiter = new EventEmitter();
	delivery = [];
	unclaimed = [];
	coords;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	tempData = [];
	dataSource;
	displayedColumns: string[];
	local;
	locks: [];
	lockers: [];
	grid;
	count_door = 1;
	status = [];
	currentURL: any = '';
	compartment = [];
	public page: number = 0;
	ready_select: boolean = false;
	filterText: string = '';
	dataTemporal = [];
	public filteredData: any[] = [];
	idLocker: string;
	completedDataCompleted = [];
	frameworkComponents: any;
	public columnDefs: ColDef[];
	arrayMarker: any = [];
	dataLocal = JSON.parse(this.securityLocalStorage.decryptData(localStorage.getItem('data')));
	id_user_type = this.dataLocal.result.id_user_type;
	overlayLoadingTemplate =`
	<div>
		<div class="spinner-grow text-dark" role="status">
			<span class="sr-only">Loading...</span>
		</div>
		<div class="spinner-grow text-dark" role="status">
			<span class="sr-only">Loading...</span>
		</div>
		<div class="spinner-grow text-dark" role="status">
			<span class="sr-only">Loading...</span>
		</div>
	</div>
	`;
	buttonExport:boolean =true;

	//AG GRID ANGULAR
	@ViewChild('agGrid') agGrid: AgGridAngular;


	//======================================
	gridApi!: GridApi;
	gridColumnApi: any;

	constructor(
		private router        : Router,
		private dialog        : MatDialog,
		private adminService  : AdminClientService,
		public dataService 	: DataService,
		private translate     : TranslateService,
		private securityLocalStorage: EncryptDataService
	) {}

	ngOnInit() {
		if(this.selectedData !== null){
			this.filterText = this.selectedData;
		}
		this.currentURL = document.location.href.split('/');
		this.currentURL = this.currentURL[this.currentURL.length - 1];
		this.local =  JSON.parse(this.securityLocalStorage.decryptData(localStorage.getItem('data')));
		this.updateTable();
		let idUserType = this.local.result.id_user_type;
		this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
			localStorage.setItem('id_locker', this.idLocker);
			this.translate.use(event.lang);
			this.translate.get([
					'table-locker.lockerID',
					'table-locker.totalDeliveries',
					'table-locker.totalMonth',
					'table-locker.doors',
					'table-locker.available',
					'table-locker.serial',
					'table-locker.status',
					'table-locker.address'
			]).subscribe((t: any) => {
				this.columnDefs = [
					{
						headerName		: t['table-locker.lockerID'],
						field			: 'lockerID',
						width			: 230,
						sortable		: true,
						sort			: 'asc',
						tooltipField	: 'lockerID',
						headerClass		: 'borderHeaderTableLockerID',
						cellClass		: this.determineCellClass.bind(this),
						onCellClicked	: this.onLockerIDCellClicked.bind(this),
					},
					{
						headerName		: this.translate.instant('table-locker.address'),
						field			: 'address_complete',
						sortable		: true,
						// sort			: 'asc',
						tooltipField	: 'address_complete',
						headerClass		:'borderHeaderTableLockerID',
						// cellClass		: this.determineCellClass.bind(this),
						// onCellClicked	: this.onLockerIDCellClicked.bind(this),
						minWidth		: 230,
					},
					{
						field			: 'lockername',
						headerName		: 'Locker name',
						hide			: true,
					},
					{
						headerName		: t['table-locker.location'],
						field			: 'address',
						sortable		: true,
						autoHeight		: true,
						tooltipField	: 'address',
						cellRenderer	: this.renderLocationCell.bind(this),
						headerClass		: 'borderHeaderTableDirection',
						cellClass		: 'tableClassDirection',
						minWidth		: 150,
						maxWidth		: 200,
					},
					{
						headerName		: t['table-locker.totalDeliveries'],
						field			: 'totalDeliveries',
						sortable		: true,
						autoHeight		: true,
						cellRenderer	: this.centeredCellRenderer,
						headerClass		: 'borderHeaderTable',
						cellClass		: 'tableClass',
						minWidth		: 150,
						maxWidth		: 150,
					},
					{
						headerName		: t['table-locker.doors'],
						field			: 'doors',
						sortable		: true,
						autoHeight		: true,
						cellRenderer	: this.centeredCellRenderer,
						headerClass		: 'borderHeaderTable',
						cellClass		: 'tableClass',
						minWidth		: 150,
						maxWidth		: 150,
					},
					{
						headerName		: t['table-locker.available'],
						field			: 'available',
						sortable		: true,
						autoHeight 		: true,
						cellRenderer	: this.centeredCellRenderer,
						headerClass		: 'borderHeaderTable',
						cellClass		: 'tableClass',
						minWidth		: 150,
						maxWidth		: 150,
					},
					{
						headerName		: t['table-locker.status'],
						field			: 'status',
						sortable		: true,
						autoHeight		: true,
						cellRenderer	: EstatusTableOrderAdminComponent,
						headerClass		:'borderHeaderTable',
						cellClass		:'tableClass',
						minWidth		: 110,
						maxWidth		: 150,
					}
				];
			});
		});
	}

	ngOnChanges(changes: SimpleChanges) {
		this.rowData = [];
		this.getDataOfTable(this.tableData);
		if(this.selectedData !== null && this.agGrid){
			let temporal = [];
			this.filterText = this.selectedData;
			this.agGrid.api.setQuickFilter(this.filterText);
			this.filteredData = temporal;
		}
	}


	verLocker(params: any) {
		let grid = [];
		params.data.MODULE.forEach((e, i) => {
			grid.push({
				name_locker_module: e.name_locker_module,
				template_module: e.template_module,
			});
		});
		localStorage.setItem('grid', JSON.stringify(grid));
		localStorage.setItem('id_locker', params.data.id);
		localStorage.setItem('lat', params.data.lat);
		localStorage.setItem('lng', params.data.lng);
		localStorage.setItem('address', params.data.address);
		localStorage.setItem('locker_name', params.data.lockerID);
		localStorage.setItem('img_screen', params.data.imgScreen);
		if (this.router !== null && this.router !== undefined) {
			if(this.id_user_type === 9 ){
				this.router.navigateByUrl(`superadmin/locker/${params.data.lockerID}`, {
					skipLocationChange: true,
				})
			}
		}
		return params.value;
	}

	onLockerIDCellClicked(params: any) {
		if (this.id_user_type <= 9) {
			this.verLocker(params);
		}
		else {
			return null;
		}
	}
	determineCellClass(params: any) {
		return this.id_user_type >= 7 && this.id_user_type <= 9 ? 'tableClassLink' : 'tableClass';
	}
	renderLocationCell(params: any) {
		const maxLength = 100;
		const value = params.value;
		if (value.length > maxLength) {
			return value.slice(0, maxLength) + '...';
		}
		return value;
	}


	updateTable() {
		let data =  JSON.parse(this.securityLocalStorage.decryptData(localStorage.getItem('data')));
		let idUserType = data.result.id_user_type;
		this.gridOptions = {
			defaultColDef: {
				tooltipComponent: TooltipComponent,
				resizable: false,
				flex: 1,
			},
			columnDefs: [
				{
					headerName		: this.translate.instant('table-locker.lockerID'),
					field			: 'lockerID',
					sortable		: true,
					sort			: 'asc',
					tooltipField	: 'lockerID',
					headerClass		:'borderHeaderTableLockerID',
					cellClass		: this.determineCellClass.bind(this),
					onCellClicked	: this.onLockerIDCellClicked.bind(this),
					minWidth		: 230,
				},
				{
					headerName		: this.translate.instant('table-locker.address'),
					field			: 'address_complete',
					sortable		: true,
					// sort			: 'asc',
					tooltipField	: 'address_complete',
					headerClass		:'borderHeaderTableLockerID',
					// cellClass		: this.determineCellClass.bind(this),
					// onCellClicked	: this.onLockerIDCellClicked.bind(this),
					minWidth		: 230,
				},
				{
					field			: 'lockername',
					headerName		: 'Locker name',
					hide			: true,
				},
				{
					field			: 'address_complete',
					headerName		: 'Address complete',
					hide			: true,
				},
				{
					headerName		: this.translate.instant('table-locker.location'),
					field			: 'address',
					sortable		: true,
					autoHeight		: true,
					tooltipField	: 'address',
					cellRenderer	: this.renderLocationCell.bind(this),
					onCellClicked	: this.onLockerAddressClicked.bind(this),
					headerClass		: 'borderHeaderTableDirection',
					cellClass		: 'tableClassDirection',
					minWidth		: 150,
					maxWidth		: 200,
				},
				{
					headerName		: this.translate.instant('table-locker.totalDeliveries'),
					field			: 'totalDeliveries',
					sortable		: true,
					autoHeight		: true,
					cellRenderer	: this.centeredCellRenderer,
					headerClass		: 'borderHeaderTable',
					cellClass		: 'tableClass',
					minWidth		: 150,
					maxWidth		: 150,
				},
				{
					headerName		: this.translate.instant('table-locker.doors'),
					field			: 'doors',
					sortable		: true,
					autoHeight		: true,
					cellRenderer	: this.centeredCellRenderer,
					headerClass		: 'borderHeaderTable',
					cellClass		: 'tableClass',
					minWidth		: 150,
					maxWidth		: 150,
				},
				{
					headerName		: this.translate.instant('table-locker.available'),
					field			: 'available',
					sortable		: true,
					autoHeight		: true,
					cellRenderer	: this.centeredCellRenderer,
					headerClass		: 'borderHeaderTable',
					cellClass		: 'tableClass',
					minWidth		: 150,
					maxWidth		: 150,
				},
				{
					headerName		: this.translate.instant('table-locker.status'),
					field			: 'status',
					sortable		: true,
					autoHeight		: true,
					cellRenderer	: EstatusTableOrderAdminComponent,
					headerClass		: 'borderHeaderTable',
					cellClass		: 'tableClass',
					minWidth		: 110,
					maxWidth		: 150,
				}
			],
			autoSizeStrategy: {
				type: 'fitGridWidth',
			},
			rowClass:'rowTableClass',
			animateRows: true,
			pagination: true,
			paginationPageSize: 10,
			paginationAutoPageSize: true,
			headerHeight:62,
			rowHeight:62,
			alwaysShowVerticalScroll:false,
			alwaysShowHorizontalScroll:false,
			overlayNoRowsTemplate:
			`<div>
				<div>
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="bi bi-info-circle icon-no-info" viewBox="0 0 16 16">
						<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
						<path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
					</svg>
				</div>
				<div class="labelNoRegistros">
					Aun no hay registros, <br>
					intentalo mas tarde
				</div>
			</div>`,
		} as MyGridOptions;
	}

	onDisplayedColumnsChanged(event: AgGridEvent) {
		const grid = document.getElementById('ag-grid');
		if (grid) {
			const gridWidth = grid.offsetWidth;

			const widthVisibleColumns = event.api.getColumns()
				.filter(c => c.isVisible())
				.map(c => c.getActualWidth())
				.reduce((a, b) => a + b);

			if (gridWidth > widthVisibleColumns) {
				event.api.sizeColumnsToFit();
			} else {
				event.api.autoSizeAllColumns();
			}
		}
	}

	onLockerAddressClicked(params: any) {
		const url = `https://www.google.com/maps?q=${params.data.address},${params.data.address_complete}`;
		window.open(url, '_blank');
	}

	selectedSize(event: any) {
		this.emiter_select_comparment.emit(event);
	}
	onGridReady(params: any) {

		this.gridApi = params.api;
    	this.gridColumnApi = params.columnApi;

		const { api } = params;
		api.sizeColumnsToFit();
	}
	resultado:string;
	getDataOfTable(data) {
		this.rowData = [];
		this.completedDataCompleted =[];
		data.forEach((item:any)=>{
			let direccion = item.locker_address;
			let partes = direccion.split(',');
			if (partes.length >= 3) {
				this.resultado = partes.slice(0, 2).join(',\n');
			}
			this.completedDataCompleted.push({
				lockerID			: item.description ? item.description.replace(' de', '') : 'N/A',
				lockername			: item.locker_name,
				imgScreen			: item.img_screen,
				totalDeliveries		: item.PACKAGE[0].DELIVERY[0].package_month_actuality_delivery,
				total				: item.PACKAGE[0].BY_COLLECT[0].result_delivery_to_collect,
				doors				: item.door_total,
				available			: item.compartments_available,
				status				: item.name_status,
				serial				: item.serial_locker,
				status_access_locker: item.ping_nombre_status == 'INACTIVE' ? 'Down' : 'Up',
				MODULE				: item.MODULE,
				id					: item.id_locker,
				lat					: item.latitud,
				lng					: item.longitud,
				address				: 'OXXO ' + item.company_route,
				ip_app_locker		: item.ip_ap_locker,
				ip_pc_locker		: item.ip_pc_locker,
				address_complete	: item.locker_address
			});
			this.rowData = this.completedDataCompleted;
			if(this.rowData.length === 0){
				this.buttonExport =false;
			}
			else{
				this.buttonExport =  true;
			}
			this.rowDataSizeChange.emit(this.rowData.length);
		})
	}
	refreshGrid() {
		const api = this.agGrid.api;
		api.refreshCells();
	}
	async seeLocker(locker, i) {
		let grid = [];
		locker.MODULE.forEach((e, i) => {
			grid.push({
				name_locker_module: e.name_locker_module,
				template_module: e.template_module,
			});
		});
		localStorage.setItem('grid', JSON.stringify(grid));
		localStorage.setItem('id_locker', locker.id);
		localStorage.setItem('lat', locker.lat);
		localStorage.setItem('lng', locker.lng);
		localStorage.setItem('address', locker.address);
		localStorage.setItem('status_access_locker',locker.status_access_locker);
		localStorage.setItem('locker_name', locker.lockerID);
		this.router.navigateByUrl(`l-retail/locker/${locker.id}`, {
			skipLocationChange: true,
		})
	}
	ngOnDestroy() {
		if (this.currentURL != 'order') this.dialog.closeAll();
	}
	radioChange(event: any, locker: any) {
		this.emiter_select.emit(locker);
	}
	centeredCellRenderer(params) {
		const cellValue = params.value;
		const cellDiv = document.createElement('div');
		cellDiv.style.display = 'flex';
		cellDiv.style.alignItems = 'center';
		cellDiv.style.justifyContent = 'center';
		cellDiv.style.height = '100%';
		cellDiv.innerText = cellValue;
		return cellDiv;
	}
	onFilterTextChange(event) {
		let textSearch=this.translateStatus(this.filterText.toLowerCase())
		this.agGrid.api.updateGridOptions({ quickFilterText: textSearch.toLowerCase() });
		let temporal = [];
		this.agGrid.api.forEachNodeAfterFilterAndSort((node) => {
			temporal.push(node.data);
		});
		this.filteredData = temporal;
	}

	translateStatus(text: string): string {
		switch (text) {
			// case 'activo':
			// 	return 'active';
			// case 'inactivo':
			// 	return 'inactive';
			// case 'cobrado':
			// 	return 'collected';
			// default:
			// 	return text;
			// }
			case 'activo':
				return 'ACTIVE';
			case 'inactivo':
				return 'INACTIVE';
			case 'bloqueado':
				return 'BLOCKED';
			case 'abierto':
				return 'ABIERTO';
			case 'cerrado':
				return 'CERRADO';
			// case '':
			// 	return 'FALLO';
			// case '':
			// 	return 'REPARADO';
			case 'pendiente':
				return 'PENDIENTE';
			// case '':
			// 	return 'PROCESADO';
			case 'anulado':
				return 'ANULADO';
			// case '':
			// 	return 'PENDIENTE';
			// case '':
			// 	return 'PENDIENTE POR PAGO';
			// case '':
			// 	return 'PAGADO';
			// case '':
			// 	return 'DISPONIBLE';
			// case '':
			// 	return 'EN PROCESO';
			// case '':
			// 	return 'ENTREGADO';
			// case '':
			// 	return 'ASIGNADO';
			// case '':
			// 	return 'UTILIZADO';
			// case '':
			// 	return 'BLOQUEADO';
			// case '':
			// 	return 'ELIMINADO';
			// case '':
			// 	return 'ENVIADO';
			// case '':
			// 	return 'RECORDATORIO';
			case 'fallo al enviar':
				return 'FALLO_AL_ENVIAR';
			// case '':
			// 	return 'OCUPADO';
			case 'pendiente por pago':
				return 'PENDIENTE_POR_PAGO';
			// case '':
			// 	return 'PAGADO';
			// case '':
			// 	return 'INSTALADO';
			// case '':
			// 	return 'EN STOCK';
			// case '':
			// 	return 'ASIGNADO';
			// case '':
			// 	return 'ANULADO';
			// case '':
			// 	return 'DEVUELTO';
			// case '':
			// 	return 'VENCIDO';
			case 'Recolectado':
				return 'RECOLECTADO';
			case 'password expirado':
				return 'PASSWORD_EXPIRADO';
			case 'Agendado':
				return 'SCHEDULED';
			// case '':
			// 	return 'SCHEDULE';
			case 'en proceso':
				return 'IN_PROCESS';
			case 'no aprovado':
				return 'UNAPPROVED';
			case 'pendiente de pago':
				return 'PENDING_PAYMENT';
			case 'terminado':
				return 'FINISH';
			case 'cancelado':
				return 'CANCELLED';
			case 'pendiente de aprobacion':
				return 'TO_BE_APPROVED';
			case 'aprobado':
				return 'APPROVED';
			case 'para actualizar':
				return 'TO UPDATE';
			case 'cancelado por pago':
				return 'CANCELED_BY_PAYMENT';
			case 'cancelado sin utilizar':
				return 'CANCELED_UNUSED';
			case 'En Locker':
				return 'IN_LOCKER';
			case 'asignado':
				return 'ASSIGNED';
			case 'en transito a cedis origen':
				return 'IN_TRANSIT_CEDIS_ORIGIN';
			case 'en cedis origen':
				return 'IN_CEDIS_ORIGIN';
			case 'en transito a cedis destino':
				return 'IN_TRANSIT_CEDIS_DESTINATION';
			case 'en cedis destino':
				return 'IN_DESTINATION_CEDIS';
			case 'en transito a locker destino':
				return 'IN_TRANSIT_LOCKER_DESTINATION';
			case 'en revision':
				return 'IN_REVIEW';
			case 'requiere debolucion':
				return 'RETURN_REQUIRED';
			case 'confiscado':
				return 'CONFISCATED';
			case 'en locker destino':
				return 'IN_DESTINATION_LOCKER';
			case 'entregado':
				return 'DELIVERED';
			case 'expirado':
				return 'EXPIRED';
			case 'recolectado expirado':
				return 'COLLECTED_EXPIRED';
			case 'recolectado en cedis destino':
				return 'COLLECTED_CEDIS_DESTINATION';
			case 'regresado a origen':
				return 'RETURNING_TO_ORIGIN';
			case 'recolectado en cedis origen':
				return 'COLLECTED_CEDIS_ORIGIN';
			// case '':
			// 	return 'LEGAL_PROCESS';
			case 'bloqueado':
				return 'BLOCKED';
			case 'asignado expirado':
				return 'ASSIGNED_EXPIRED';
			case 'en espera en cedis para recoleccion':
				return 'IN_CEDIS_WAITING_COLLECTION';
			case 'devuelto':
				return 'RETURNED';
			default:
				return text;
		}
	}

	onSortChanged(event:any){
		const sortedData: any[] = [];
		this.gridApi.forEachNodeAfterFilterAndSort(node => {
			sortedData.push(node.data);
		});
		this.filteredData=sortedData
	}
	onExport() {
		const fileName = 'LockerSuperAdmin.xlsx';
		const excelParams = {
			fileName: fileName,
			sheetName: 'LockerSuperAdmin',
			columnWidths: [30, 20, 20, 20], // Ancho de las columnas en caracteres
		};
		let Data = [];
		if (this.filteredData.length > 0) Data = this.filteredData || [];
		else if (this.rowData.length > 0 && this.filteredData.length === 0) Data = this.rowData || [];

		// const excelData = Data.map((row) => {
		// 	const { id_company,id_status,id_courier, ...rowDataWithoutFields} = row;
		// 	return rowDataWithoutFields;
		// });

		const exportData = Data.map(row => {
			return {
				lockerID: row.lockerID,
				addressComplete: row.address_complete,
				address: row.address,
				totalDeliveries: row.totalDeliveries,
				doors: row.doors,
				available: row.available,
				status: row.status,
			};
		});

		const wb = XLSX.utils.book_new();
		const ws = XLSX.utils.json_to_sheet(exportData);
		XLSX.utils.book_append_sheet(wb, ws, excelParams.sheetName);
		XLSX.writeFile(wb, fileName);
	}
	getTooltipContent(params) {
		// Aquí puedes personalizar el contenido del tooltip según tus necesidades
		return params.value;
	}
}

interface MyGridOptions extends GridOptions {
	pagination:boolean;
}
