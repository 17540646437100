import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnInit {

	@Input() codeLength 	: 	number;
	currentCodeIndex :number;
	codeArray: any[] | undefined;

	constructor() { }

	ngOnInit(): void {
		this.currentCodeIndex = 0;
		this.codeLength = 6;
		this.codeArray = []
	}

	validateCodeInput(event: any, index: number) {
		this.currentCodeIndex = index;
		const inputs = document.querySelectorAll('input');
		let nextInput = undefined;

		const target = event.target;
		const val = target.value;

		if (isNaN(val)) {
			target.value = "";
			return;
		}

		if (this.codeLength! > index + 1) {
			nextInput = inputs[index + 1];
		}

		if (event.target.value.length === 1 && nextInput) {
			nextInput.focus();
		}

		if (event.target.value.length > 1) {
			event.target.value = event.target.value[1];
			if (nextInput) {
			nextInput.focus();
			}
		}

		if (this.codeLength! === index + 1) {
			event.target.blur();
		}
	}
}
