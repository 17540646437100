<div class="container-main">
	<div class="grid-header">
		<div class="span-txt">
			<span class="txt-title">
				{{'overview.cardsOverview.headquarters' | translate}}
			</span>
			<div class="icon-info">
				<app-tooltip-center-sm
					class="tooltip-span"
					[tooltip]="tooltipHeadquarters">
				</app-tooltip-center-sm>
			</div>
		</div>
	</div>
    <div class="chart" id="chartDonut">
		<!-- <apx-chart style="width:99%"
			[series]="chartOptions.series"
			[chart]="chartOptions.chart"
			[plotOptions]="chartOptions.plotOptions"
			[labels]="chartOptions.labels"
			[legend]="chartOptions.legend"
			[colors]="chartOptions.colors"
			[responsive]="chartOptions.responsive"
			[stroke]="chartOptions.stroke">
		</apx-chart> -->
		<div class="dates-default">
		<div class="grid-header">
			<span class="span-base">{{periodBase}}</span>
		</div>
		<div class="grid-header">
			<span class="span-comparacion">{{periodComp}}</span>
		</div>
		</div>
	</div>
	<div class="container-legend">
		<div class="align-text" >
			<div class="row"  >
				<div class="centerLegends" *ngFor="let item of dataLegends; let i = index" [ngClass]="i <= 2 ? 'col-4' : 'col-6'">
					<span>
						<div class="circle-{{i+1}}">
						</div>
						{{item}}
					</span>
				</div>
			</div>
		</div>
	</div>

</div>


<!-- <div class="container-legend">
	<div class="align-text" *ngFor="let item of dataLabels; let i = index">
		<div class="row-1-legend" >
			<span><div class="circle-{{i}}"></div>{{item}}</span>
		</div>
	</div>
</div> -->
