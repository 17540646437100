import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { SelectItem } from 'primeng/api';
import { AdminClientService } from 'src/app/core/http/admin-client.service';

@Component({
	selector: 'app-add-new-user',
	templateUrl: './add-new-user.component.html',
	styleUrls: ['./add-new-user.component.scss'],
})
export class AddNewUserComponent implements OnInit {
	createCarrierUserForm: FormGroup;
		types: SelectItem[];
		isCompanyEmployeeIdDisabled: boolean = false; // Inicialmente habilitado

		public respError: boolean;

		constructor(
			private formBuilder: FormBuilder,
			private adminService: AdminClientService,
			private dialog: MatDialog,
			private toastr: ToastrService
		) {}

		ngOnInit() {
		this.crearFormulario();
		this.getUserTypes();
		}

		ngOnDestroy(): void {
			this.closeAll();
		}

		modelChangedInformationCarrier(event) {
			if(this.idTypeEmployee !== 9){
				this.createCarrierUserForm.get('company_employee_id').disable();
				this.createCarrierUserForm.get('company_employee_id').clearValidators();
			}
			else{
				this.createCarrierUserForm.get('company_employee_id').enable();
				this.createCarrierUserForm.get('company_employee_id').setValidators([Validators.required]);
			}
		}

		isValidField(field:string):boolean {
			return this.createCarrierUserForm.controls[field].errors && this.createCarrierUserForm.controls[field].touched
		}

		async getUserTypes() {
		this.types = [];
		let res: any = await this.adminService.getUserTypes(
			localStorage.getItem('language').toLowerCase()
		);
		res.result_list_user.forEach((element) => {
			// if (
			// element.id_user_type === 7 ||
			// element.id_user_type === 8 ||
			// element.id_user_type === 9 ||
			// element.id_user_type === 10 ||
			// element.id_user_type === 11 ||
			// element.id_user_type === 13
			// ) {
			this.types.push({
				label: element.user_type,
				value: element.id_user_type,
			});
			// }
		});

		// Actualizar el estado de isCompanyEmployeeIdDisabled aquí según el valor de id_type_employee
		if (this.idTypeEmployee === 9 || this.idTypeEmployee === 10) {
			this.isCompanyEmployeeIdDisabled = true;
		} else {
			this.isCompanyEmployeeIdDisabled = false;
		}

		// Habilitar o deshabilitar el control company_employee_id en función de isCompanyEmployeeIdDisabled
		if (this.isCompanyEmployeeIdDisabled) {
			this.createCarrierUserForm.get('company_employee_id').disable();
		} else {
			this.createCarrierUserForm.get('company_employee_id').enable();
		}
		}

		private crearFormulario() {
		this.createCarrierUserForm = this.formBuilder.group({
			nombre_empresa: [''],
			company_employee_id: [''],
			id_type_employee: ['', [Validators.required]],
			full_name: ['', Validators.required],
			email: ['', [Validators.required]],
		});
		}

		closeAll() {
		this.dialog.closeAll();
		}

		onSubmit() {
			this.createUser();

		}

		get idTypeEmployee() {
			return this.createCarrierUserForm.get('id_type_employee').value;
		}

	async createUser() {
		let body = {
			language: localStorage.getItem('language').toLowerCase(),
			full_name: this.createCarrierUserForm.get('full_name').value,
			email: this.createCarrierUserForm.get('email').value,
			user_type: this.createCarrierUserForm.get('id_type_employee').value,
			company_employee_id: this.createCarrierUserForm.get('company_employee_id').value,
		};
		const filteredUserType = this.types.filter(
			(userType) => userType.value === this.idTypeEmployee
		);
		await this.adminService.createUser(body).then((a: any) => {
			if (a.mensaje_return.CODE === 200) {
			this.toastr.success(
				'Usuario Agregado de tipo ' + filteredUserType[0].label,
				'Usuario Agregado',
				{
				toastClass: 'toast-extendida',
				timeOut: 5000,
				positionClass: 'toast-bottom-center',
				}
			);
			this.dialog.closeAll();
			}
			else if (a.mensaje_return.CODE !== 1204) {
				this.toastr.success(
					'Usuario No Agregado de tipo ' + filteredUserType[0].label,
					'Usuario Existente',
					{
					toastClass: 'toast-warn',
					timeOut: 5000,
					positionClass: 'toast-bottom-center',
					}
				);
			}
		})
	}
}
