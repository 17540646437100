
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ConfirmChangeStatusUserVivipostComponent } from 'src/app/modules/vivipost/components/dialogs/confirm-change-status-user-vivipost/confirm-change-status-user-vivipost.component';
import { DialogChangeStatusCarrierVivipostComponent } from 'src/app/modules/vivipost/components/dialogs/dialog-change-status-carrier-vivipost/dialog-change-status-carrier-vivipost.component';

@Component({
	selector: 'app-toggle',
	templateUrl: './toggle.component.html',
	styleUrls: ['./toggle.component.scss'],
})
export class ToggleComponent implements ICellRendererAngularComp {
	status: boolean = false;
	statusCarrier: boolean = false;
	data: any;
	typeField: string = '';

	constructor(private matdialog : MatDialog) {}

	agInit(params: ICellRendererParams): void {
		this.typeField = params.colDef.field;
		this.data = params.data;
		if(this.typeField === "statusCarrier"){
			this.statusCarrier = this.data.statusCarrier;
		}
		if (this.typeField === 'status') {
			this.status =
				params.data.nombre_status_employee === 'ACTIVE' ? true : false;
		}
		if (this.typeField === 'accessqr') {
			this.status = params.data.slide_status;
		}
		if(this.typeField === 'enable_token'){
			this.status === params.data.enable_token;
		}
		if(this.typeField === 'id_status'){
			this.status = params.data.id_status
		}
		if(this.typeField === 'api_enabled'){
			if(params.data.api_enabled === 1){
				this.status = true;

			}
			else if(params.data.api_enabled === 0){
				this.status = false;
			}
		}
	}

	refresh(params: ICellRendererParams) {
		return false;
	}

	openConfirmChangeStatus(event) {
		const dialogRef = this.matdialog.open(ConfirmChangeStatusUserVivipostComponent, {
			width: '361px',
			height: '231px',
			data: this.data,
		});

		dialogRef.afterClosed().subscribe((result) => {
			// if(this.users !== undefined){
			// 	const newData = this.users.slice();
			// 	newData[result].status = 'INACTIVE';
			// 	this.users = newData;
			// }

		});
	}

	changeStatusCarrier(){
		console.log(this.data)
		const dialogRef = this.matdialog.open(DialogChangeStatusCarrierVivipostComponent, {
			width: '365px',
			height: '422px',
			data : {
				data:this.data,
				isFromDialog:false
			},
			disableClose:true
		});
		dialogRef.afterClosed().subscribe((result) => {
		});
	}

	openEraseCarrier(){
		const dialogRef = this.matdialog.open(DialogChangeStatusCarrierVivipostComponent, {
			width: '365px',
			height: '422px',
			data : {
				data:this.data,
				isFromDialog:false
			}
		});
		dialogRef.afterClosed().subscribe((result) => {
		});
	}

	ngOnInit(): void {}
}
