// * Angular
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { AgGridAngular } from 'ag-grid-angular';
import { CellClickedEvent, ColDef, GridOptions } from 'ag-grid-community';
import { utils } from 'xlsx';
import { AdminClientService } from 'src/app/core/http/admin-client.service';
import { StarRatingComponent } from 'src/app/shared/components/star-rating/star-rating.component';
import { Calendar } from 'primeng/calendar';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import * as XLSX from 'xlsx';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FilterService } from 'src/app/core/http/FilterDataServiceOxxoToOxxo.service';
import { DateTextTablesComponent } from 'src/app/shared/components/date-text-tables/date-text-tables.component';
import { EstatusTableOrderAdminComponent } from '../../estatus-table-order-admin/estatus-table-order-admin.component';
import { DialogOrderDetailComponent } from '../../dialog-order-detail/dialog-order-detail.component';
import { DataService } from 'src/app/core/services/data.service';
import { EstatusOxxoToOxxoComponent } from '../../tables-oxxo-to-oxxo/estatus-oxxo-to-oxxo/estatus-oxxo-to-oxxo.component';
import { DialogOxxoToOxxoDetailComponent } from '../../dialog-oxxo-to-oxxo-detail/dialog-oxxo-to-oxxo-detail.component';
import { EncryptDataService } from 'src/app/core/security/encypt-data.service';
import { EstatusOrdersWarehouseComponent } from 'src/app/modules/warehouse/components/tables-orders/estatus-orders-warehouse/estatus-orders-warehouse.component';

interface Pagination {
    name: string;
    value: number;
}

@Component({
	selector: 'general-table',
	templateUrl: './general-table.component.html',
	styleUrls: ['./general-table.component.scss']
})
export class GeneralTableComponent implements OnInit, OnChanges  {
	@Input() rowData;
	@Input() filteredData;
	@Input() rangeDates: Date[];
	public columnDefs: ColDef[];
	public defaultColDefGeneral: ColDef = {
		sortable: true,
		filter: true,
	};
	rowDataGeneralAdmin;
	public rowDataGeneral: any[];
	private gridApi: AgGridAngular;
	supportLanguages = ['es', 'en'];
	InitialDate = new Date();
	showDate: Date;
	showDateFinal: Date;
	fechasByMonth: string;
	FinalDate = new Date();
	res;
	completedDataCompleted = [];
	countCompleted = 0;
	valueInput;
	display = false;
	minDate: Date;
	maxDate: Date;
	id_user_type = parseInt(localStorage.getItem('user_type'));
	orderSelectToExtendDate: any;
	displayDialogOrder = false;
	filterText: string = '';
	gridOptions: GridOptions;
	dataTemporal = [];
	idLocker: string;
	trackingNumber = localStorage.getItem('searchOrderNotification');
	@ViewChild('calendar') calendar: Calendar;
	@ViewChild('input') textToFilter: ElementRef;
	@ViewChild('agGrid') agGrid: AgGridAngular;
	agGridApi:AgGridAngular;
	currentPage:number =1;
	totalP:number;
	sizeData:number;
	filters:boolean=false;
	cities: Pagination[] | undefined;
    selectedCity = 10;
	mode:string;
	url:string;

	@Output() rangeDatesChange = new EventEmitter<Date[]>();

	constructor(
		private translate		: TranslateService,
		private router			: Router,
		private adminService	: AdminClientService,
		private toastr			: ToastrService,
		private matdialog		: MatDialog,
		private route			: ActivatedRoute,
		private filterService	: FilterService,
		public dataService 		: DataService,
		private securityLocalStorage: EncryptDataService,
		private elementRef		: ElementRef) {

		this.idLocker = localStorage.getItem('id_locker');
	}

	determineCellClass(params: any) {
		const id_user_type = this.obtenerIdUserType();
		return (id_user_type !== 10 && id_user_type !== 11) ? 'generalClassLink' : 'generalClass';
	}
	onLockerIDCellClicked(params: any) {
		const id_user_type = this.obtenerIdUserType();
		if(this.id_user_type !== 10 && this.id_user_type !== 11){
			this.openModalUserDetail(params);
		}
		else {
		  // Otra lógica para otros tipos de usuarios, si es necesario
			return null;
		}
	}
	renderEmailCell(params: any) {
		const maxLength = 100;
		const value = params.value;
		if (value.length > maxLength) {
			return value.slice(0, maxLength) + '...';
		}
		return value;
	}
	ngOnInit() {
		if(this.filteredData === undefined){
			this.filteredData = [];
		}
		this.url = this.route.snapshot.url.join('/');
		this.mode = localStorage.getItem('mode');
		if( localStorage.getItem('searchOrderNotification') !== null){
			this.trackingNumber = localStorage.getItem('searchOrderNotification');
			this.filterText = this.trackingNumber;
		}
		this.updateDataLocker();
		this.route.params.subscribe(params => {
			const id = params['id'];
			if(this.trackingNumber !== id){
				this.filterText = id;
			}
		})
		this.translate.use(localStorage.getItem('language').toLowerCase());
		this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
			localStorage.setItem('id_locker', this.idLocker);
			this.translate.use(event.lang);
			this.translate.get([
					'table-general.order',
					'table-general.model',
					'table-general.tracking',
					'table-general.lockerID',
					'table-general.email',
					'table-general.employeeName',
					'table-general.employeeID',
					'table-general.doorNumber',
					'table-general.status',
					'table-general.inLocker',
					'table-general.dateCollected',
					'table-general.rateStart',
					'table-general.creationDate',
					'table-general.updateDate',
			]).subscribe((t: any) => {
				const id_user_type = this.obtenerIdUserType();
				this.columnDefs = [
					{
						headerName: t['table-general.tracking'],
						field: 'tracking',
						width: 150,
						sortable: true,
						autoHeight: true,
						headerClass:'borderHeaderGeneral',
						cellClass:this.determineCellClass.bind(this),
						onCellClicked: this.onLockerIDCellClicked.bind(this)
					},
					{
						headerName: t['table-general.model'],
						field: 'model',
						width: 150,
						sortable: true,
						autoHeight: true,
						headerClass:'borderHeaderGeneral',
						cellClass: 'generalClass',
						suppressMovable	: true,
					},
					{
						headerName: t['table-general.email'],
						field: 'email',
						width: this.url === 'orders' ? 425 : 375,
						sortable: true,
						autoHeight: true,
						cellClass:'generalClass',
						headerClass:'borderHeaderGeneral',
						cellRenderer:  this.renderEmailCell.bind(this)
					},
					{
						headerName: t['table-general.doorNumber'],
						field: 'doorNumber',
						width: 150,
						sortable: true,
						autoHeight: true,
						headerClass:'borderHeaderGeneral',
						cellClass:'generalClass',
					},
					{
						headerName: t['table-general.status'],
						field: 'status',
						width: 350,
						sortable: true,
						autoHeight: true,
						cellRenderer: EstatusOrdersWarehouseComponent,
						cellClass:'generalClass',
						headerClass:'borderHeaderGeneral',
						onCellClicked: ($event) => this.openCalendar($event)
					},
					{
						headerName: t['table-general.creationDate'],
						field: 'registration_date',
						width: 150,
						sortable: true,
						autoHeight: true,
						sort: 'asc',
						cellRenderer: DateTextTablesComponent,
						headerClass:'borderHeaderGeneral',
						cellClass:'generalClass',
					},
					{
						headerName: t['table-general.updateDate'],
						field: 'update_date',
						width: 150,
						sortable: true,
						autoHeight: true,
						cellRenderer: DateTextTablesComponent,
						headerClass:'borderHeaderGeneral',
						cellClass:'generalClass',
					},
					{
						headerName: t['table-general.rateStart'],
						field: 'rate_start',
						width: 150,
						sortable: true,
						autoHeight: true,
						headerClass:'borderHeaderGeneral',
						cellClass:'generalClass',
						cellRenderer: StarRatingComponent,
					}
				];
			});
		});
		this.minDate = new Date();
		this.minDate.setDate(this.minDate.getDate() + 1); // día mínimo es mañana
		this.maxDate = new Date();
		this.maxDate.setDate(this.maxDate.getDate() + 2);
		let today = new Date();

		if(today.getDate() < 31){
			this.FinalDate = new Date(today.getFullYear() + '-' + (today.getMonth()+1) +'-'+(today.getDate()+1));
		}
		else{
			this.FinalDate = new Date(today.getFullYear() + '-' + (today.getMonth()+1) +'-'+(today.getDate()));
		}

		this.InitialDate = new Date(this.FinalDate);

		if((today.getDate()) === 31){
			this.InitialDate.setDate(0);
		}
		else{
			this.InitialDate.setMonth(this.InitialDate.getMonth() -1);
		}

		this.rangeDates = [this.InitialDate, this.FinalDate];

		this.addEventRangeDates();
	}
	buttonExport:boolean =true;

	ngOnChanges(changes: SimpleChanges){
		if ('rowData' in changes) {
			this.rowDataGeneralAdmin = [...this.rowData];
			if (this.rowData.length === 0) {
				this.buttonExport = false;
			} else {
				this.buttonExport = true;
			}
		}
	}
	obtenerIdUserType(){
		let dataLocal = JSON.parse(this.securityLocalStorage.decryptData(localStorage.getItem('data')))
		return parseInt('id_user_type ->',dataLocal.result.id_user_type);
	}
	updateDataLocker(): void {
		const id_user_type = this.obtenerIdUserType();
		this.gridOptions = {
			columnDefs: [
				{
					headerName: this.translate.instant('table-general.tracking'),
					field: 'tracking',
					width: 150,
					sortable: true,
					autoHeight: true,
					headerClass:'borderHeaderGeneral',
					cellClass:this.determineCellClass.bind(this),
					onCellClicked: this.onLockerIDCellClicked.bind(this),
					suppressMovable	: true,
				},
				{
					headerName: this.translate.instant('table-general.model'),
					field: 'model',
					width: 150,
					sortable: true,
					autoHeight: true,
					headerClass:'borderHeaderGeneral',
					cellClass: 'generalClass',
					suppressMovable	: true,
				},
				{
					headerName: this.translate.instant('table-general.email'),
					field: 'email',
					width: this.url === 'orders' ? 425 : 375,
					sortable: true,
					autoHeight: true,
					cellRenderer:  this.renderEmailCell.bind(this),
					headerClass:'borderHeaderGeneral',
					cellClass:'generalClass',
					suppressMovable	: true,
				},
				{
					headerName: this.translate.instant('table-general.doorNumber'),
					field: 'doorNumber',
					width: 150,
					sortable: true,
					autoHeight: true,
					headerClass:'borderHeaderGeneral',
					cellClass:'generalClass',
					suppressMovable	: true,
				},
				{
					headerName: this.translate.instant('table-general.status'),
					field: 'status',
					width: 350,
					sortable: true,
					autoHeight: true,
					cellRenderer: EstatusOrdersWarehouseComponent,
					headerClass:'borderHeaderGeneral',
					cellClass:'generalClass',
					onCellClicked: ($event) => this.openCalendar($event),
					suppressMovable	: true,
				},
				{
					headerName: this.translate.instant('table-general.creationDate'),
					field: 'registration_date',
					width: 200,
					sortable: true,
					autoHeight: true,
					sort: 'asc',
					cellRenderer: DateTextTablesComponent,
					headerClass:'borderHeaderGeneral',
					cellClass:'generalClass',
					suppressMovable	: true,
				},
				{
					headerName: this.translate.instant('table-general.updateDate'),
					field: 'update_date',
					width: 200,
					sortable: true,
					autoHeight: true,
					cellRenderer: DateTextTablesComponent,
					headerClass:'borderHeaderGeneral',
					cellClass:'generalClass',
					suppressMovable	: true,
				},
				{
					headerName: this.translate.instant('table-general.rateStart'),
					field: 'rate_start',
					width: 220,
					sortable: true,
					autoHeight: true,
					cellRenderer: StarRatingComponent,
					headerClass:'borderHeaderGeneral',
					cellClass:'generalClass',
					suppressMovable	: true,
				}
			],
			animateRows: true,
			pagination: true,
			paginationPageSize: 10,//this.selectedCity,
			paginationAutoPageSize: true,
			headerHeight:59,
			rowHeight:59,
			rowClass:'rowGeneralClass',
			overlayNoRowsTemplate:
			`<div>
				<div>
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="bi bi-info-circle icon-no-info" viewBox="0 0 16 16">
						<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
						<path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
					</svg>
				</div>
				<div class="labelNoRegistros">
					Aun no hay registros, <br>
					intentalo mas tarde
				</div>
			</div>`
		}
	}
	onFilterTextFromNotification(item) {
		this.filteredData = this.rowData.filter(
			(data) => data.tracking === item
		);
		this.rowData = [...this.filteredData];

		this.agGrid.api.setRowData(this.rowData);
	}
	onGridReady(params: any) {
		const { api } = params;
		this.agGridApi = this.agGrid;
		this.gridApi = params;
	}
	onCellClicked(e: CellClickedEvent): void {
		if (e.colDef.field === 'status') {
			if (
				e.data.status_package === 14 &&
				e.data.id_status_code_package === 32
			) {
				this.display = true;
				this.orderSelectToExtendDate = e.data;
			} else {
				this.display = false;
			}
		}
	}
	openCalendar(e: CellClickedEvent) {
		if (e.data.status_package === 14 &&	e.data.id_status_code_package === 32) {
			this.display = true;
			this.orderSelectToExtendDate = e.data;
		} else {
			this.display = false;
		}
	}
	addEventRangeDates() {
		this.rangeDatesChange.emit(this.rangeDates);
	}
	getTableCompleted() {

	}
	DownloadExcel() {
		let headerExcel = [
			'tracking',
			'lockerID',
			'email',
			'employeeID',
			'nameEmploye',
			'inLockerDate',
			'inLockerTime',
			'dateCollectedDate',
			'dateCollectedTime',
			'rate_start',
		];
		let dataExcel = [];

		this.completedDataCompleted.forEach((e: any) => {
			let info = {
				tracking: e.tracking,
				lockerID: e.lockerID,
				email: e.email,
				employeeID: e.employeeId,
				nameEmploye: e.nameEmploye,
				inLockerDate: e.inLocker.slice(0, 10),
				inLockerTime: e.inLocker.slice(11, 19),
				dateCollectedDate: e.dateCollected.slice(0, 10),
				dateCollectedTime: e.dateCollected.slice(11, 19),
				rate_start: e.rate_start,
			};
			dataExcel.push(info);
		});
		const worksheet = utils.json_to_sheet(dataExcel, {
			header: headerExcel,
		});
		const workbook = utils.book_new();
		utils.book_append_sheet(workbook, worksheet, 'Data');
	}
	centeredCellRenderer(params) {
		// const cellValue = params.value;
		// const cellDiv = document.createElement('div');
		// cellDiv.style.display = 'flex';
		// cellDiv.style.alignItems = 'center';
		// cellDiv.style.justifyContent = 'center';
		// cellDiv.style.height = '100%';
		// cellDiv.innerText = cellValue;
		// return cellDiv;
	}
	async extenderFechas(calendar) {
		let dateSelect = new Date(calendar.value);
		let month = dateSelect.getMonth().toString().length == 1 ? '0' + (dateSelect.getMonth() + 1) : dateSelect.getMonth() + 1;
		let day = dateSelect.getDate().toString().length == 1 ? '0' + dateSelect.getDate() : dateSelect.getDate();
		let d = dateSelect.getFullYear() + '-' + month + '-' + day;
		let dataSendUpdate = {
			language: localStorage.getItem('language').toString().toLowerCase(),
			id_company: localStorage.getItem('id_company'),
			id_package_code: this.orderSelectToExtendDate.id_package_code,
			date_update: d,
		};
		let res: any = await this.adminService.updateExpirationDate(dataSendUpdate);
		if (res.mensaje_return) {
			this.display = false;
			this.toastr.success('la entrega santifactoriamente','Se ha extendindo',
				{
					toastClass: 'toast-extendida',
					timeOut: 5000,
					positionClass: 'toast-bottom-center',
				}
			);
		}
		// this.getTableCompleted();
	}
	openModalUserDetail(event) {
		let model = event.data.model
		if(model === "Carrier"){
			const dialogRef = this.matdialog.open(DialogOrderDetailComponent, {
				width: '1304px',
				height: 'auto',
				data: event.data,
			});
			dialogRef.afterClosed().subscribe((result) => {});
		}
		else if (model === "OXXO TO OXXO"){
			const dialogRef = this.matdialog.open(DialogOxxoToOxxoDetailComponent, {
				width: '1304px',
				height: 'auto',
				data: event.data,

			});
			dialogRef.afterClosed().subscribe((result) => {});
		}
	}
	cerrarExtender() {
		this.display = false;
		this.orderSelectToExtendDate = [];
	}
	onFilterTextChange(event) {
		this.filterText = event.target.value;
		event.api.setQuickFilter(this.filterText);
		let api = event.api;
		let temporal = [];
		event.api.forEachNodeAfterFilter((node) => {
			temporal.push(node.data);
		});
		this.filteredData = temporal;
	}
	onExport() {
		const dayInitial = this.InitialDate.getDate() < 10 ? '0' + this.InitialDate.getDate() : this.InitialDate.getDate();
		const monthInitial = this.InitialDate.getMonth() + 1 < 10 ? '0' + (this.InitialDate.getMonth() + 1)	: this.InitialDate.getMonth() + 1;
		const yearInitial = this.InitialDate.getFullYear();
		const dayFinal = this.FinalDate.getDate() < 10 ? '0' + this.FinalDate.getDate() : this.FinalDate.getDate();
		const monthFinal = this.FinalDate.getMonth() + 1 < 10 ? '0' + (this.FinalDate.getMonth() + 1) : this.FinalDate.getMonth() + 1;
		const yearFinal = this.FinalDate.getFullYear();
		const fileName = 'General'+dayInitial+monthInitial+yearInitial+'_'+dayFinal+monthFinal+yearFinal+'.xlsx';
		// Configuración para exportar en formato Excel
		const excelParams = {
			fileName: fileName,
			sheetName: 'General',
			columnWidths: [30, 20, 20, 20], // Ancho de las columnas en caracteres
		};

		const Data = this.filteredData;
		const excelData = Data.map((row) => {
			// Crear un nuevo objeto clonando 'row' sin incluir el campo 'lockername'
			const { id_package,id_package_code,id_status_code_package,status_package,qr_order,resultNotification,IMAGE,  ...rowDataWithoutFields} = row;
			return rowDataWithoutFields;
		});
		const wb = XLSX.utils.book_new();
		const ws = XLSX.utils.json_to_sheet(excelData);
		XLSX.utils.book_append_sheet(wb, ws, excelParams.sheetName);
		XLSX.writeFile(wb, fileName);


		// if(this.filterText !== null){
		// 	if(this.filterText !== ''){
		// 		const Data = this.filteredData.filter(item=> item===this.filterText) || [];
		// 		const excelData = Data.map((row) => {
		// 			// Crear un nuevo objeto clonando 'row' sin incluir el campo 'lockername'
		// 			const { id_package,id_package_code,id_status_code_package,status_package,qr_order,resultNotification,IMAGE,  ...rowDataWithoutFields} = row;
		// 			return rowDataWithoutFields;
		// 		});
		// 		const wb = XLSX.utils.book_new();
		// 		const ws = XLSX.utils.json_to_sheet(excelData);
		// 		XLSX.utils.book_append_sheet(wb, ws, excelParams.sheetName);
		// 		XLSX.writeFile(wb, fileName);
		// 	}

		// }
		// if (this.filteredData.length > 0) {
		// 	const Data = this.filteredData || [];
		// 	const excelData = Data.map((row) => {
		// 		const { lockerID, id_package,id_package_code,id_status_code_package,status_package,qr_order,resultNotification,IMAGE,  ...rowDataWithoutFields} = row;
		// 		return rowDataWithoutFields;
		// 	});
		// 	const wb = XLSX.utils.book_new();
		// 	const ws = XLSX.utils.json_to_sheet(excelData);
		// 	XLSX.utils.book_append_sheet(wb, ws, excelParams.sheetName);
		// 	XLSX.writeFile(wb, fileName);
		// }
		// else if (this.rowData.length > 0 && this.filteredData.length === 0 && (this.trackingNumber === null)) {
		// 	if(this.trackingNumber !== ''){
		// 		const Data = this.rowData || [];
		// 		const excelData = Data.map((row) => {
		// 			// Crear un nuevo objeto clonando 'row' sin incluir el campo 'lockername'
		// 			const { lockerID, id_package,id_package_code,id_status_code_package,status_package,qr_order,resultNotification,IMAGE,  ...rowDataWithoutFields} = row;
		// 			return rowDataWithoutFields;
		// 		});
		// 		const wb = XLSX.utils.book_new();
		// 		const ws = XLSX.utils.json_to_sheet(excelData);
		// 		XLSX.utils.book_append_sheet(wb, ws, excelParams.sheetName);
		// 		XLSX.writeFile(wb, fileName);
		// 	}
		// }
	}
	goToFirstPage() {
		this.agGridApi.api.paginationGoToFirstPage();
		this.currentPage = this.agGridApi.api.paginationGetCurrentPage() + 1;
	}
	goToLastPage() {
		this.agGridApi.api.paginationGoToLastPage();
		this.currentPage = this.agGridApi.api.paginationGetCurrentPage() + 1;
	}
	gotoNextPage(){
		this.agGridApi.api.paginationGoToNextPage();
		this.currentPage = this.agGridApi.api.paginationGetCurrentPage() + 1;
	}
	gotoPreviousPage(){
		this.agGridApi.api.paginationGoToPreviousPage();
		this.currentPage = this.agGridApi.api.paginationGetCurrentPage() + 1;
	}

	changeSize(){
		// this.getTableCompleted();
		this.currentPage = 1
		this.updateDataLocker();
	}

	showFilters(){
		this.filters = !this.filters;
	}

	isMobileView(): boolean {
		const width = this.elementRef.nativeElement.offsetWidth; // Obtiene el ancho del elemento actual
		return width <= 600; // Puedes ajustar este valor según tus necesidades
	}
}
