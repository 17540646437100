<div class="container-webhook">
	<div class="header-container">
		<div class="title">
			{{'configWebhook.title' | translate}}
		</div>
	</div>
	<div class="button-container">
		<app-button-dashboard
			(click)="navigateToActivity()"
			buttonClass="btn-md-primary"
			label="buttons.activity">
		</app-button-dashboard>
	</div>
	<div class="card-container">
		<app-input-dashboard style="width: 100%;"
			[inputValue]="urlDocumentation"
			[inputDisabled]="false"
			[showEraseIcon]="false"
			[showIcon]="false"
			[header]="'configWebhook.config.URL'"
			[showError]="false"
			[inputType]="'email'"
			[error]="false"
		></app-input-dashboard>
		<app-input-dashboard style="width: 100%;"
			[inputValue]="updateDate"
			[inputDisabled]="false"
			[showEraseIcon]="false"
			[showIcon]="false"
			[header]="'configWebhook.config.editionDate'"
			[showError]="false"
			[inputType]="'email'"
			[error]="false"
		></app-input-dashboard>

		<app-input-dashboard style="width: 100%;"
			inputValue="{{'configWebhook.config.subscribe' | translate}}"
			[inputDisabled]="false"
			[showEraseIcon]="false"
			[showIcon]="false"
			[header]="'configWebhook.config.statusSubcription'"
			[showError]="false"
			[inputType]="'email'"
			[error]="false"
		></app-input-dashboard>

		<app-button-dashboard
			buttonClass="btn-md-secondary"
			label="configWebhook.config.updateButton">
		</app-button-dashboard>
	</div>
	<div class="event-container">
		<div class="title">
			{{'configWebhook.eventWebhookTitle' | translate}}
		</div>
		<app-button-dashboard
			(click)="saveChanges()"
			buttonClass="btn-md-primary"
			icon="../../../../../assets/theme/light/icons/icon-download.svg"
			label="configWebhook.saveEventWebhook">
		</app-button-dashboard>
	</div>
</div>

<div class="container-main">
	<div class="row" style="gap: 24px 48px;	margin-bottom: 32px;">
		<div class="col-12 col-md-12 col-lg-12 col-xl-12">
			<div class="cardEventWebhook">
				<ag-grid-angular style="width: 100%; height: auto;"
					class="ag-theme-alpine"
					[gridOptions]="this.gridOptions"
					[columnDefs]="this.columnDefs"
					[rowData]="this.rowData"
					(gridReady)="onGridReady($event)">
				</ag-grid-angular>
				<div *ngIf="isLoading" style="margin-left: 50%;">
					<app-custom-loader-template></app-custom-loader-template>
				</div>
			</div>
		</div>
	</div>
</div>
