<div>
	<div class="sidebar-container" *ngIf="sideBarClosed === false">
		<div class="sidebar-group-1">
			<div class="sidebar-header">
				<div class="image-logo">
					<app-icon
						name="vivipost-logo"
						color="color-fg-secondary"
						fillColor="color-fg-secondary"
						size="lg"
						strokeWidth="semibold">
					</app-icon>
					<!-- <svg xmlns="http://www.w3.org/2000/svg" width="36" height="37" viewBox="0 0 36 37" fill="none">
						<path d="M0 8.5C0 4.08172 3.58172 0.5 8 0.5H28C32.4183 0.5 36 4.08172 36 8.5V28.5C36 32.9183 32.4183 36.5 28 36.5H8C3.58172 36.5 0 32.9183 0 28.5V8.5Z" fill="#F5F5F5"/>
						<path d="M26.2785 14.792L20.25 8.72581C20.044 8.51824 19.7317 8.44609 19.4537 8.54155L10.015 11.7894C9.76139 11.8771 9.57531 12.0847 9.51772 12.3444C9.51218 12.3688 9.50886 12.3944 9.50665 12.4188H9.5V21.8793C9.5 22.1624 9.61297 22.4343 9.81344 22.6352L15.7134 28.5227C16.0014 28.8102 16.4278 28.9079 16.8121 28.7747L25.7845 25.6633C26.2131 25.5146 26.5 25.1106 26.5 24.6566V15.3503H26.4978C26.5044 15.145 26.4291 14.944 26.2796 14.7942L26.2785 14.792ZM19.6198 9.63712L25.1277 15.1783L21.2413 16.4803L15.8906 10.9203L19.6198 9.63712ZM14.7554 11.311L20.0983 16.8643L16.5098 18.0665L10.8811 12.6441L14.7554 11.311ZM10.5876 21.8693V13.8751L15.8231 18.919V27.0941L10.5876 21.8693ZM16.9118 27.5859V19.0821L25.4113 16.2339V24.6377L16.9118 27.5859Z" fill="#475467"/>
						<path d="M19.4129 21.0582C19.8636 20.6065 19.9432 19.9539 19.5907 19.6007C19.2382 19.2474 18.5871 19.3271 18.1364 19.7788C17.6857 20.2305 17.6061 20.8831 17.9586 21.2364C18.3111 21.5897 18.9622 21.5099 19.4129 21.0582Z" fill="#475467"/>
						<path d="M22.7572 18.2796C22.3064 18.7313 22.2267 19.384 22.58 19.737C22.9322 20.09 23.5834 20.0112 24.0342 19.5594C24.485 19.1076 24.5647 18.4549 24.2114 18.102C23.8592 17.749 23.208 17.8278 22.7572 18.2796Z" fill="#475467"/>
						<path d="M20.5805 24.9576C23.8445 24.4126 23.9808 21.0049 23.9808 21.0049L18.1328 22.913C18.1328 22.913 18.4175 25.3184 20.5805 24.9576Z" fill="#475467"/>
					</svg> -->
				</div>
				<div class="header-information">
					<div class="company-container">
						<div class="title">
							{{this.data.data_company.company_name}}
						</div>
						<div class="subtitle">
							{{this.data.data_company.company_type}}
						</div>
					</div>
					<div class="close-sidebar" (click)="closeSidebar()">
						<app-icon
							name="chevron-left"
							color="color-fg-quaternary"
							size="sm"
							strokeWidth="semibold">
						</app-icon>
					</div>
				</div>
			</div>
			<div class="divider">
				<div class="line">
				</div>
			</div>
			<div class="sidebar-menu">
				<div [ngClass]="selectedPage === item.route ? 'item-selected' : 'item'" *ngFor="let item of data_menu" (click)="goToPage(item)">
					<app-icon
						[name]="item.icon"
						color="color-fg-quaternary"
						size="sm"
						strokeWidth="semibold">
					</app-icon>
					<div class="text">
						{{item.txt | translate}}
					</div>
				</div>
			</div>
		</div>
		<div class="sidebar-group-2">
			<div class="divider">
				<div class="line">
				</div>
			</div>
			<div class="appearance-container">
				<div class="item" (click)="openLanguageItem()">
					<app-icon
						name="translate-01"
						color="color-fg-quaternary"
						size="sm"
						strokeWidth="semibold">
					</app-icon>
					<div class="text">
						{{'navbar.lang' | translate }}
					</div>
					<app-icon [ngClass]="{'rotate-chevron': openLanguage}"
						name="chevron-down"
						color="color-fg-quaternary"
						size="sm"
						strokeWidth="semibold">
					</app-icon>
				</div>
				<div [ngClass]="this.language  === 'es' ? 'item-selected' : 'item'" *ngIf="openLanguage" (click)="translateToEs()">
					<div>
					</div>
					<div class="text">
						{{ 'navbar.es' | translate}}
					</div>
				</div>
				<div [ngClass]="this.language  === 'en' ? 'item-selected' : 'item'" *ngIf="openLanguage" (click)="translateToEn()">
					<div>
					</div>
					<div class="text">
						{{ 'navbar.en' | translate}}
					</div>
				</div>
				<div class="item" (click)="openAppareanceItem()">
					<app-icon
						name="layout-alt-01"
						color="color-fg-quaternary"
						size="sm"
						strokeWidth="semibold">
					</app-icon>
					<div class="text">
						{{'navbar.appearance' | translate }}
					</div>
					<app-icon [ngClass]="{'rotate-chevron': openAppareance}"
						name="chevron-down"
						color="color-fg-quaternary"
						size="sm"
						strokeWidth="semibold">
					</app-icon>
				</div>
				<div [ngClass]="this.mode  === 'dark' ? 'item-selected' : 'item'"  *ngIf="openAppareance" (click)="changeModeDark('dark')">
					<div>
					</div>
					<div class="text">
						{{ 'navbar.dark' | translate}}
					</div>
				</div>
				<div  [ngClass]="this.mode  === 'light' ? 'item-selected' : 'item'" *ngIf="openAppareance" (click)="changeModeLight('light')">
					<div>
					</div>
					<div class="text">
						{{ 'navbar.light' | translate}}
					</div>
				</div>
			</div>
			<div class="divider">
				<div class="line">
				</div>
			</div>
			<div class="footer-sidebar">
				<div class="account">
					<div class="user-initials-container">
						<div class="text">
							{{profile | uppercase}}
						</div>
					</div>
					<div class="user">
						<div class="account-container">
							<div class="title">
								{{this.data.data_company.alias_username | titlecase}}
							</div>
							<div class="subtitle">
								{{this.data.data_company.user_type}}
							</div>
						</div>
						<div class="settings" (click)="showSettingsContainer()">
							<app-icon
								name="dots-vertical"
								color="color-fg-quaternary"
								size="sm"
								strokeWidth="semibold">
							</app-icon>
						</div>
					</div>
				</div>
				<div class="version">
					<div class="text">
						Powered by <strong>Vivipost</strong> {{systemVersion}}
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="sidebar-container-close" *ngIf="sideBarClosed === true">
		<div class="sidebar-group-1-close">
			<div class="sidebar-header-close">
				<div class="open-sidebar" (click)="openSidebar()">
					<app-icon
						name="chevron-right"
						color="color-fg-quaternary"
						size="sm"
						strokeWidth="semibold">
					</app-icon>
				</div>
			</div>
			<div class="divider">
				<div class="line">
				</div>
			</div>
			<div class="sidebar-menu-close">
				<div [ngClass]="selectedPage === item.route ? 'item-close-selected' : 'item-close'" *ngFor="let item of data_menu" (click)="goToPage(item)">
					<app-icon
						[name]="item.icon"
						color="color-fg-quaternary"
						size="sm"
						strokeWidth="semibold">
					</app-icon>
				</div>
			</div>
		</div>
		<div class="sidebar-group-2-close">
			<div class="appearance-container-close">
				<div class="item-close" (click)="openLanguageItem()">
					<app-icon
						name="translate-01"
						color="color-fg-quaternary"
						size="sm"
						strokeWidth="semibold">
					</app-icon>
				</div>
				<div class="item-close" (click)="openAppareanceItem()">
					<app-icon
						name="layout-alt-01"
						color="color-fg-quaternary"
						size="sm"
						strokeWidth="semibold">
					</app-icon>
				</div>
			</div>
			<div class="divider">
				<div class="line"></div>
			</div>
			<div class="footer-sidebar-close">
				<div class="account-close" (click)="showSettingsContainerClosed()">
					<div class="user-initials-container-close">
						<div class="text">
							AC
						</div>
					</div>
				</div>
				<div class="version-close">
					<div class="text">
						{{systemVersion}}
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="sidebar-mobile">
		<div class="menu" (click)="showMenuMobile()">
			<app-icon
				name="menu-01"
				color="color-fg-quaternary"
				size="sm"
				strokeWidth="semibold">
			</app-icon>
		</div>
		<div class="image-logo">
			<img alt="icon" class="icon-logo" src="../../../../../assets/icons/sidebar_icons/icon-sidebar-logo.svg"/>
		</div>
		<div class="menu" (click)="showPanelNotifications()">
			<app-icon
				name="bell-03"
				color="color-fg-quaternary"
				size="sm"
				strokeWidth="semibold">
			</app-icon>
		</div>
	</div>
	<div class="sidebar-container-mobile" *ngIf="showPanelMenuMobile === true">
		<div class="sidebar-group-1">
			<div class="sidebar-header">
				<div class="image-logo">
					<svg xmlns="http://www.w3.org/2000/svg" width="36" height="37" viewBox="0 0 36 37" fill="none">
						<path d="M26.2785 14.792L20.25 8.72581C20.044 8.51824 19.7317 8.44609 19.4537 8.54155L10.015 11.7894C9.76139 11.8771 9.57531 12.0847 9.51772 12.3444C9.51218 12.3688 9.50886 12.3944 9.50665 12.4188H9.5V21.8793C9.5 22.1624 9.61297 22.4343 9.81344 22.6352L15.7134 28.5227C16.0014 28.8102 16.4278 28.9079 16.8121 28.7747L25.7845 25.6633C26.2131 25.5146 26.5 25.1106 26.5 24.6566V15.3503H26.4978C26.5044 15.145 26.4291 14.944 26.2796 14.7942L26.2785 14.792ZM19.6198 9.63712L25.1277 15.1783L21.2413 16.4803L15.8906 10.9203L19.6198 9.63712ZM14.7554 11.311L20.0983 16.8643L16.5098 18.0665L10.8811 12.6441L14.7554 11.311ZM10.5876 21.8693V13.8751L15.8231 18.919V27.0941L10.5876 21.8693ZM16.9118 27.5859V19.0821L25.4113 16.2339V24.6377L16.9118 27.5859Z" fill="#475467"/>
						<path d="M19.4129 21.0582C19.8636 20.6065 19.9432 19.9539 19.5907 19.6007C19.2382 19.2474 18.5871 19.3271 18.1364 19.7788C17.6857 20.2305 17.6061 20.8831 17.9586 21.2364C18.3111 21.5897 18.9622 21.5099 19.4129 21.0582Z" fill="#475467"/>
						<path d="M22.7572 18.2796C22.3064 18.7313 22.2267 19.384 22.58 19.737C22.9322 20.09 23.5834 20.0112 24.0342 19.5594C24.485 19.1076 24.5647 18.4549 24.2114 18.102C23.8592 17.749 23.208 17.8278 22.7572 18.2796Z" fill="#475467"/>
						<path d="M20.5805 24.9576C23.8445 24.4126 23.9808 21.0049 23.9808 21.0049L18.1328 22.913C18.1328 22.913 18.4175 25.3184 20.5805 24.9576Z" fill="#475467"/>
					</svg>
				</div>
				<div class="header-information">
					<div class="company-container">
						<div class="title">
							{{this.data.data_company.company_name}}
						</div>
						<div class="subtitle">
							{{this.data.data_company.company_type}}
						</div>
					</div>
					<div class="close-sidebar" (click)="closePanelMobile()">
						<app-icon
							name="menu-01"
							color="color-fg-quaternary"
							size="sm"
							strokeWidth="semibold">
						</app-icon>
					</div>
				</div>
			</div>
			<div class="divider">
				<div class="line">
				</div>
			</div>
			<div class="sidebar-menu">
				<div class="item" *ngFor="let item of data_menu" (click)="goToPage(item)">
					<app-icon
						[name]="item.icon"
						color="color-fg-quaternary"
						size="sm"
						strokeWidth="semibold">
					</app-icon>
					<div class="text">
						{{item.menu_name}}
					</div>
				</div>
			</div>
		</div>
		<div class="sidebar-group-2">
			<div class="divider">
				<div class="line">
				</div>
			</div>
			<div class="appearance-container">
				<div class="item" (click)="openLanguageItem()">
					<app-icon
						name="translate-01"
						color="color-fg-quaternary"
						size="sm"
						strokeWidth="semibold">
					</app-icon>
					<div class="text">
						{{'navbar.lang' | translate }}
					</div>
					<app-icon
						[ngClass]="{'rotate-chevron': openLanguage}"
						name="chevron-down"
						color="color-fg-quaternary"
						size="sm"
						strokeWidth="semibold">
					</app-icon>
				</div>
				<div class="item" *ngIf="openLanguage" (click)="translateToEs()">
					<div>
					</div>
					<div class="text">
						{{ 'navbar.es' | translate}}
					</div>
				</div>
				<div class="item" *ngIf="openLanguage" (click)="translateToEn()">
					<div>
					</div>
					<div class="text">
						{{ 'navbar.en' | translate}}
					</div>
				</div>
				<div class="item" (click)="openAppareanceItem()">
					<app-icon
						name="layout-alt-01"
						color="color-fg-quaternary"
						size="sm"
						strokeWidth="semibold">
					</app-icon>
					<div class="text">
						{{'navbar.appearance' | translate }}
					</div>
					<app-icon
						[ngClass]="{'rotate-chevron': openAppareance}"
						name="chevron-down"
						color="color-fg-quaternary"
						size="sm"
						strokeWidth="semibold">
					</app-icon>
				</div>
				<div class="item" *ngIf="openAppareance" (click)="changeModeDark('dark')">
					<div>
						<app-icon
							name="moon-01"
							color="color-fg-quaternary"
							size="sm"
							strokeWidth="semibold">
						</app-icon>
					</div>
					<div class="text">
						{{ 'navbar.dark' | translate}}
					</div>
				</div>
				<div class="item" *ngIf="openAppareance" (click)="changeModeLight('light')">
					<div>
						<app-icon
							name="sun"
							color="color-fg-quaternary"
							size="sm"
							strokeWidth="semibold">
						</app-icon>
					</div>
					<div class="text">
						{{ 'navbar.light' | translate}}
					</div>
				</div>
			</div>
			<div class="divider">
				<div class="line">
				</div>
			</div>
			<div class="footer-sidebar">
				<div class="account">
					<div class="user-initials-container">
						<div class="text">
							{{profile | uppercase}}
						</div>
					</div>
					<div class="user">
						<div class="account-container">
							<div class="title">
								{{this.data.data_company.alias_username | titlecase}}
							</div>
							<div class="subtitle">
								{{this.data.data_company.user_type}}
							</div>
						</div>
						<div class="settings" (click)="showSettingsContainer()">
							<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
								<path d="M8.49998 9.16663C8.86817 9.16663 9.16665 8.86815 9.16665 8.49996C9.16665 8.13177 8.86817 7.83329 8.49998 7.83329C8.13179 7.83329 7.83331 8.13177 7.83331 8.49996C7.83331 8.86815 8.13179 9.16663 8.49998 9.16663Z" stroke="#667085" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
								<path d="M8.49998 4.49996C8.86817 4.49996 9.16665 4.20148 9.16665 3.83329C9.16665 3.4651 8.86817 3.16663 8.49998 3.16663C8.13179 3.16663 7.83331 3.4651 7.83331 3.83329C7.83331 4.20148 8.13179 4.49996 8.49998 4.49996Z" stroke="#667085" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
								<path d="M8.49998 13.8333C8.86817 13.8333 9.16665 13.5348 9.16665 13.1666C9.16665 12.7984 8.86817 12.5 8.49998 12.5C8.13179 12.5 7.83331 12.7984 7.83331 13.1666C7.83331 13.5348 8.13179 13.8333 8.49998 13.8333Z" stroke="#667085" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
							</svg>
						</div>
					</div>
				</div>
				<div class="version">
					<div class="text">
						Powered by <strong>Vivipost</strong> {{systemVersion}}
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="show-notifications">
	<notifications *ngIf="showNotification === true"
				[orders]="this.notificationOrders"
				[sizeOrders]="sizeOrders"
				[users]="this.notificationUser"
				[sizeUser]="sizeUsers"
				[all]="this.lstNotifications"
				[sizeAll]="sizeAll"
				(clean)="this.cleanNotifications()"
				(cleanItem)="this.cleanItemNotifications()">
	</notifications>
</div>

<div class="settings-container" *ngIf="this.showSettings">
	<div class="item-container" (click)="goToAccount()">
		<div class="item">
			<app-icon
				name="user-circle"
				color="color-fg-quaternary"
				size="sm"
				strokeWidth="semibold">
			</app-icon>
			<div class="text">
				{{'settings.profile' | translate }}
			</div>
		</div>
	</div>
	<div class="item-container" *ngFor="let item of data_profile" (click)="goToPage(item)">
		<div class="item">
			<app-icon (hover)="iconName(item.icon)"
				[name]="item.icon"
				color="color-fg-quaternary"
				size="sm"
				strokeWidth="semibold">
			</app-icon>
			<div class="text">
				{{'settings.'+item.txt | translate }}
			</div>

		</div>
	</div>
	<div class="item-container" (click)="goToVersion()">
		<div class="item">
			<app-icon
				name="git-merge"
				color="color-fg-quaternary"
				size="sm"
				strokeWidth="semibold">
			</app-icon>
			<div class="text">
				{{'settings.version' | translate }}
			</div>
		</div>
	</div>
	<div class="item-container" (click)="logout()">
		<div class="item">
			<app-icon
				name="align-right-01"
				color="color-fg-quaternary"
				size="sm"
				strokeWidth="semibold">
			</app-icon>
			<div class="text">
				{{'settings.logout' | translate }}
			</div>
		</div>
	</div>
</div>

<div class="settings-container-closed" *ngIf="this.showSettingsClosed">
	<div class="item-container" (click)="goToAccount()">
		<div class="item">
			<app-icon
				name="user-circle"
				color="color-fg-quaternary"
				size="sm"
				strokeWidth="semibold">
			</app-icon>
			<div class="text">
				{{'settings.profile' | translate }}
			</div>
		</div>

	</div>
	<div class="item-container" *ngFor="let item of data_profile">
		<div class="item">
			<app-icon (hover)="iconName(item.icon)"
				[name]="item.icon"
				color="color-fg-quaternary"
				size="sm"
				strokeWidth="semibold">
			</app-icon>
			<div class="text">
				{{'settings.'+item.txt | translate }}
			</div>
		</div>
	</div>
	<div class="item-container" (click)="goToVersion()">
		<div class="item">
			<app-icon
				name="git-merge"
				color="color-fg-quaternary"
				size="sm"
				strokeWidth="semibold">
			</app-icon>
			<div class="text">
				{{'settings.version' | translate }}
			</div>
		</div>
	</div>
	<div class="item-container" (click)="logout()">
		<div class="item">
			<app-icon
				name="align-right-01"
				color="color-fg-quaternary"
				size="sm"
				strokeWidth="semibold">
			</app-icon>
			<div class="text">
				{{'settings.logout' | translate }}
			</div>
		</div>
	</div>
</div>
