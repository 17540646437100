 <!-- ? KPIS SM -->
 <div class="kpis">
    <!-- ? CARD SM -->
    <div class ="grid_card"  *ngFor="let kpi of dataCardSm">
        <div [ngClass]="{ }">
        <!-- <div [ngClass]="{'fadeAnimationIn'	: dataService.overviewChecked,
                         'fadeAnimationOut'	: !dataService.overviewChecked }"> -->
            <div class="card_display">
                <div class="header_">
                    <!-- TXT HEADER -->
                    <p class="body_b1 color_primary font_medium white-space"> {{ kpi.header | translate }} </p>
                    <!-- ? TOOLTIP CONTAINER -->
                    <div class="tooltip_cont_">
                        <img src="../../../../../../assets/icons/icon-info.svg" class="icon_tooltip">
                
                        <!-- TOOLTIP -->
                        <div class="tooltip_">
                            <p class="caption_c2 color_primary_400 font_medium">
                                {{ kpi.toolTip | translate }}
                            </p>
                            <!-- PIKE -->
                            <div class="pike_2"></div>
                        </div>
                        <!-- PIKE -->
                        <div class="pike"></div>
                    </div>
                </div>
                <!-- ? KPI DATA -->
                <p  class="color_primary headlines_bh3 font_semibold" >{{kpi.data}}</p>

            </div>        

        </div>

    </div>

</div>