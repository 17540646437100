<!-- ? CONTAINER MAIN -->
<div class="cont_main">
    <!-- ? BLOCK 1 | INPUT | CALENDAR | FILTER | EXPORT  -->
    <div class="block_header">
        <!-- ? INPUT CONTAINER-->
        <div class="input_main_cont">
            <input
                [(ngModel)]="filterText"
                type="text"
                class="input_main"
                (keyup)="onFilterTextChange($event)"
                placeholder="{{'search.oxxoToOxxo' | translate}}">
            <!-- ICON SEARCH -->
            <img class="icon_sm" src="../../../../../../assets/icons/icon-search.svg">

        </div>

        <div class="calendar_filter_export">
            <div class="input_cont_calendar">
                <p-calendar
                    styleClass="input_custome"
                    [showIcon]="true"
                    [(ngModel)]="rangeDates"
                    (ngModelChange)="addEventRangeDates()"
                    selectionMode="range"
                    [readonlyInput]="true"
                    inputId="range"
                    [placeholder]="formatDatePlaceholder(rangeDatesPlaceholder[0],rangeDatesPlaceholder[1])">
                </p-calendar>
            </div>
            <!-- {{filteredData | json}} -->
			<button-export (click)="onExport()" [isDisabled]="filteredData && filteredData.length>0?false:true"></button-export>
        </div>

    </div>
    <!-- {{this.rowData | json}} -->
    <!-- ? BLOCK 2 | TABLE -->
    <div class="block_table">
        <ag-grid-angular
            #agGrid
            class="ag-theme-alpine"
            [gridOptions]="this.gridOptions"
            [rowData]="this.rowData"
            suppressScrollOnNewData = true
            [columnDefs]="this.columnDefsGeneralOTO"
            (gridReady)="onGridReady($event)"
            (displayedColumnsChanged)="onDisplayedColumnsChanged($event)"
            (sortChanged)="onSortChanged($event)">
        </ag-grid-angular>

    </div>
</div>
