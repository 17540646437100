import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { AdminClientService } from 'src/app/core/http/admin-client.service';
import { Data } from '@angular/router';
import { AuthService } from 'src/app/core/http/auth/auth.service';
import * as shajs from 'sha.js';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-password-superadmin',
  templateUrl: './password-superadmin.component.html',
  styleUrls: ['./password-superadmin.component.scss']
})
export class PasswordSuperadminComponent implements OnInit {
	formValid:boolean=false;
	formPassword:FormGroup;
	fname:string;
	sname:string;
	email:string;
	//? COMPANY INFORMATION
	company:string;
	userType:string;
	id_user:number;
	lang:string;
	address:string;
	id_company:string;
	constructor(
		private formBuilder: FormBuilder,
		private adminService: AdminClientService,
		private authService: AuthService,
		private toastr: ToastrService,
		private translate: TranslateService
	){}
	get passwordValue() {
		return this.formPassword.get('password').value;
	}
	get newPasswordValue() {
		return this.formPassword.get('new_password').value;
	}
	get confirmPasswordValue(){
		return this.formPassword.get('confirm_password').value;
	}
	ngOnInit(): void {
		const data 			= localStorage.getItem('data');
		const userData:Data = JSON.parse(data);
		this.lang 			= userData.result.acronym_language;
		this.email 			= userData.data_company.email;
		this.company 		= userData.data_company.company_name;
		this.id_user 		= userData.data_company.id_user;
		this.id_company 	= userData.data_company.id_company;
		this.fname 			= userData.data_company.first_name;
		this.sname 			= userData.data_company.surname;
		this.userType 		= userData.data_company.user_type;
		this.address 		= userData.data_company.address;
		this.formPassword = this.formBuilder.group({
			password		: ['',	[Validators.required]],
			new_password	: ['',	[Validators.required,Validators.minLength(8)]],
			confirm_password: ['',	[Validators.required,Validators.minLength(8)]]
		},
		{
			validators: this.passwordMatchValidator('new_password', 'confirm_password')
		});
	}
	updatePassword(){
		let passValue = shajs('sha512').update(this.passwordValue).digest('hex');
		let data = {
			id_languaje: this.lang,
			first_name: this.fname,
			surname: this.sname,
			email: this.email,
			password: this.newPasswordValue,
			company: this.company,
			id_user: this.id_user,
			userType: this.userType
		}
		let objectValidateUser={
			language:localStorage.getItem('language').toLowerCase(),
			user:this.email,
			pass:passValue,
			mobile:false,
			tecnico:false,
			is_only_validate:true
		}
		this.authService.validateUserPassword(objectValidateUser).then((res:any)=>{
			if(res.body.mensaje_return.CODE === 200){
				this.adminService.update_update_data_user(data).subscribe((res) => {
					if(res.mensaje_return.CODE === 200){
						this.toastr.success(
							res.mensaje_return.ERROR_MENSAGGE,
							this.translate.instant('message.errorGenerateToken'),
							{
							toastClass: 'toast-extendida',
							timeOut: 5000,
							positionClass: 'toast-bottom-center',
							}
						);
					}
				})
			}
			else{
				this.toastr.error(
					res.body.mensaje_return.ERROR_MENSAGGE,
					this.translate.instant('message.errorGenerateToken'),
					{
					toastClass: 'toast-error',
					timeOut: 5000,
					positionClass: 'toast-bottom-center',
					}
				);
			}
		})
	}
	onLoadForm(){
		this.formValid = this.formPassword.valid;
	}

	passwordMatchValidator(controlName: string, matchingControlName: string): ValidatorFn {
		return (formGroup: FormGroup): ValidationErrors | null => {
			const control = formGroup.controls[controlName];
			const matchingControl = formGroup.controls[matchingControlName];

			if (control.value !== matchingControl.value) {
				return { passwordMismatch: true };
			} else {
				return null;
			}
		};
	}
}
