//ANGULAR
import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ChartComponent, ApexAxisChartSeries, ApexResponsive ,ApexChart, ApexXAxis, ApexDataLabels, ApexStroke, ApexMarkers, ApexYAxis, ApexGrid, ApexTitleSubtitle, ApexLegend, ApexFill, ApexTooltip } from 'ng-apexcharts';
import { ClientService } from 'src/app/core/http/client.service';
import { clientsAdminResponse } from 'src/app/core/models/clients/clients.interface';

@Component({
  selector: 'app-carrier-metric-admin',
  templateUrl: './carrier-metric-admin.component.html',
  styleUrls: ['./carrier-metric-admin.component.scss']
})
export class CarrierMetricAdminComponent implements OnInit, OnChanges {
	@ViewChild('chart') chart: ChartComponent;
	@Input() metricData;
	@Input() dataDeliver: number[] = [];
	@Input() dataRecolected: number[] = [];
	@Input() dataExpired: number[] = [];
	public chartOptions: Partial<ChartOptions>;

	arrayDeliver=[];
	arrayRecolected=[];

	constructor(private translate: TranslateService,private clientService	: ClientService) {
		translate.use(localStorage.getItem('language'));
		this.loadDataChart();
	}

	ngOnInit(): void {
		this.translate.use(localStorage.getItem('language'));
		this.translate.onLangChange.subscribe((event:LangChangeEvent)=>{
			this.translate.use(event.lang);
			this.loadDataChart();
		})
		if(this.metricData !== undefined){
			this.processChartData(this.metricData.data_month);
		}
	}
	ngOnChanges(changes: SimpleChanges): void {
		if (changes.metricData && this.metricData) {
			// Call the method to process and update chart data
			if(this.metricData !== undefined){
				this.processChartData(this.metricData.data_month);
			}
		}
	}
	processChartData(event) {
		const series = [];
		if(event !== undefined){
			const courierNames = event['Enero'].map(data => data.courier_name);
			courierNames.forEach(courierName => {
				const courierData = [];
				for (const month in event) {
					if (event.hasOwnProperty(month)) {
					const courierMonthData = event[month].find(data => data.courier_name === courierName);
					courierData.push(courierMonthData ? courierMonthData.cantidad : 0);
					}
				}
				series.push({
					name: courierName,
					data: courierData,
				});
			});
			this.chartOptions.series = series;
			if (this.chart && this.chart.updateOptions) {
				this.chart.updateOptions(this.chartOptions);
			}
		}
	}
	private loadDataChart() {
		this.chartOptions = {
			series: [

			],
			chart: {
				selection: {
					enabled: true,
				},
				dropShadow: {
					enabled: false,
					enabledOnSeries: undefined,
					top: 0,
					left: 0,
					blur: 3,
					color: '#000',
					opacity: 0.35,
				},
				height: 400,
				width: '100%',
				type: 'line',
				toolbar: {
					show: false,
				},
			},
			dataLabels: {
				enabled: false,
			},
			stroke: {
				width: 2,
				colors: ['#0082cb', '#18345d'],
				curve: 'straight',
				dashArray: [0, 0, 0],
				show:true
			},
			markers: {
				size: 0,
				hover: {
					sizeOffset: 6,
				},
				colors: ['#0082cb', '#18345d'],
			},
			yaxis:{
				axisBorder:{
					color:"#efefef",
					show:true
				},
				labels: {
					style:{
						colors:'#a6a6a6',
						fontSize:'12px',
						fontFamily:'var(--primary-font)',
						fontWeight:500
					}
				},
			},
			xaxis: {
				labels: {
					trim: false,
					style:{
						colors:'#a6a6a6',
						fontSize:'12px',
						fontFamily:'var(--primary-font)',
						fontWeight:500
					}
				},
				categories: [
					this.translate.instant('clients.metricsClients.month.January'),
					this.translate.instant('clients.metricsClients.month.February'),
					this.translate.instant('clients.metricsClients.month.March'),
					this.translate.instant('clients.metricsClients.month.April'),
					this.translate.instant('clients.metricsClients.month.May'),
					this.translate.instant('clients.metricsClients.month.June'),
					this.translate.instant('clients.metricsClients.month.July'),
					this.translate.instant('clients.metricsClients.month.August'),
					this.translate.instant('clients.metricsClients.month.September'),
					this.translate.instant('clients.metricsClients.month.October'),
					this.translate.instant('clients.metricsClients.month.November'),
					this.translate.instant('clients.metricsClients.month.December')
				],
			},
			tooltip: {
				y: [
					{
						title: {
							formatter: function (val) {
								return val + ' (mins)';
							},
						},
					},
				],
				marker: {
					fillColors: ['#0082cb', '#18345d'],
				},
			},
			grid: {
				borderColor: '#e5e5e5',
				show:true
			},
			fill: {
				opacity: 1,
				colors: ['#0082cb', '#18345d'],
			},
			legend: {
				position: 'bottom',
				fontFamily: 'Inter',
				offsetY: 0,
				labels:{
					colors:'#212121'
				},
				markers: {
					radius:2,
					fillColors: ['#0082cb', '#18345d'], // Colores personalizados para los marcadores de la leyenda
				},
			}
		};
	}

}

export type ChartOptions = {
	series: ApexAxisChartSeries;
	chart: ApexChart;
	xaxis: ApexXAxis;
	stroke: ApexStroke;
	dataLabels: ApexDataLabels;
	markers: ApexMarkers;
	yaxis: ApexYAxis;
	grid: ApexGrid;
	legend: ApexLegend;
	title: ApexTitleSubtitle;
	fill: ApexFill;
	tooltip: ApexTooltip;
	responsive: ApexResponsive
};
