<div class="filtersBox" *ngIf="this.filters === true">
	<div class="row">
		<div class="col-12 col-md-12 col-lg-12 col-xl-12" style="display: flex; justify-content: space-between;">
			<span class="filtersBoxTitle">
				{{'ordersPage.filters.title' | translate}}
			</span>
			<div class="icon-close" (click)="closeModal()">
			</div>
		</div>
		<div class="col-12 col-md-12 col-lg-12 col-xl-12">
			<div class="boxValues">
				<div class="row">
					<div class="col-12 col-md-12 col-lg-12 col-xl-12">


								<div class="statusInLocker">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="en_locker_destino"
										[(ngModel)]="checkboxCollectionAssigned"
										name="enLockerDestino"
										(change)="validarCasillasSeleccionadas()"/>
									<span class="textDropDown">
										{{'ordersPage.filters.all.inProcess.collectAssigned' | translate}}
									</span>
								</div>
								<div class="statusInLocker">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="en_locker_destino"
										[(ngModel)]="checkboxRouteToCedis"
										name="enLockerDestino"
										(change)="validarCasillasSeleccionadas()"/>
									<span class="textDropDown">
										{{'ordersPage.filters.all.inProcess.routeToCedisOrigin' | translate}}
									</span>
								</div>
								<div class="statusInLocker">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="en_locker_destino"
										[(ngModel)]="checkboxRouteToCedisDestino"
										name="enLockerDestino"
										(change)="validarCasillasSeleccionadas()"/>
									<span class="textDropDown">
										{{'ordersPage.filters.all.inProcess.routeToCedisDestiny' | translate}}
									</span>
								</div>
								<div class="statusInLocker">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="en_locker_destino"
										[(ngModel)]="checkboxRouteToCedisExpired"
										name="enLockerDestino"
										(change)="validarCasillasSeleccionadas()"/>
									<span class="textDropDown">
										{{'ordersPage.filters.all.inProcess.routeToCedisExpired' | translate}}
									</span>
								</div>
								<div class="statusInLocker">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="en_locker_origen"
										[(ngModel)]="chechboxRouteToLocker"
										name="enLockerOrigen"
										(change)="validarCasillasSeleccionadas()"/>
									<span class="textDropDown">
										{{'ordersPage.filters.all.inProcess.deliverAssigned' | translate}}
									</span>
								</div>
								<div class="statusInLocker">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="en_locker_origen"
										[(ngModel)]="checkboxCedis"
										name="enLockerOrigen"
										(change)="validarCasillasSeleccionadas()"/>
									<span class="textDropDown">
										{{'ordersPage.filters.all.pending.CEDIS' | translate}}
									</span>
								</div>
								<div class="statusInLocker">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="en_locker_origen"
										[(ngModel)]="checkboxReview"
										name="enLockerOrigen"
										(change)="validarCasillasSeleccionadas()"/>
									<span class="textDropDown">
										{{'ordersPage.filters.all.warehouseManagement.reviewed' | translate}}
									</span>
								</div>
								<div class="statusInLocker">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="en_locker_origen"
										[(ngModel)]="checkboxCounter"
										name="enLockerOrigen"
										(change)="validarCasillasSeleccionadas()"/>
									<span class="textDropDown">
										{{'ordersPage.filters.all.warehouseManagement.counter' | translate}}
									</span>
								</div>



					</div>
				</div>
			</div>
		</div>
	</div>
</div>
