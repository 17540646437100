<!-- ? CONTAINER MAIN -->
<div class="cont_main">
	<!-- ? HEADER -->
	<div class="header headlines_h2 font_semibold color_primary">
		<!-- TITLE -->
		<p>{{'overview.cardsOverview.LockerUsageByComparmentSize' | translate}}</p>

		 <!-- ? TOOLTIP CONTAINER -->
		 <div class="tooltip_cont_">
			<img src="../../../../../../assets/icons/icon-info.svg" class="icon_tooltip">
			   
			<!-- TOOLTIP -->
			<div class="tooltip_">
				<p class="caption_c2 color_primary_400 font_medium">
					{{ 'tooltip.useLockerBySize' | translate }}
				</p>
				<!-- PIKE -->
				<div class="pike_2"></div>
			</div>
			<!-- PIKE -->
			<div class="pike"></div>

		</div>

	</div>

	<!-- ? CHART  -->	
	<div id="chart" class="chart">
		<apx-chart
			[series]="dataChartUseLocker.series"
			[chart]="dataChartUseLocker.chart"
			[dataLabels]="dataChartUseLocker.dataLabels"
			[plotOptions]="dataChartUseLocker.plotOptions"
			[responsive]="dataChartUseLocker.responsive"
			[xaxis]="dataChartUseLocker.xaxis"
			[legend]="dataChartUseLocker.legend"
			[stroke]="dataChartUseLocker.stroke"
			[fill]="dataChartUseLocker.fill">
		</apx-chart>
	</div>
	
</div>