import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';


@Component({
  selector: 'app-date-text-warehouse',
  templateUrl: './date-text-warehouse.component.html',
  styleUrls: ['./date-text-warehouse.component.scss']
})
export class DateTextWarehouseComponent implements ICellRendererAngularComp {
	fecha: string;
	isBadDate:boolean = false;

	constructor() {}
	agInit(params: ICellRendererParams<any, any>): void {
		if(params.value !== null) {
			let date = new Date(params.value);
			this.fecha = date.toString();
			this.isBadDate = false;
		}
		else{
			this.fecha=null;
		}
	}
	refresh(params: ICellRendererParams<any, any>): boolean {
		return false;
	}

	ngOnInit(): void {}

}
