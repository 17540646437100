import { Component,OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Data } from '@angular/router';
import { AdminClientService } from 'src/app/core/http/admin-client.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-account-superadmin',
  templateUrl: './account-superadmin.component.html',
  styleUrls: ['./account-superadmin.component.scss']
})
export class AccountSuperadminComponent implements OnInit {
	formPersonal:FormGroup;
	//? TOOLTIP SETTINGS
	toolTip:string = 'hello world';
	position:string = 'center';
	//? ACCOUNT INFORMATION
	fname:string;
	sname:string;
	email:string;
	//? COMPANY INFORMATION
	company:string;
	userType:string;
	id_user:number;
	lang:string;
	address:string;
	id_company:string;

	get FnameValue() {
		return this.formPersonal.get('fname').value;
	}
	get emailValue() {
		return this.formPersonal.get('email').value;
	}
	get languajeValue(){
		return this.formPersonal.get('id_language').value;
	}
	constructor(
		private formBuilder: FormBuilder,
		private adminService: AdminClientService,
		private userService: UserService
	) {}
	ngOnInit(): void {
		const data 			= localStorage.getItem('data');
		const userData:Data = JSON.parse(data);
		this.lang 			= userData.result.acronym_language;
		this.email 			= userData.data_company.email;
		this.company 		= userData.data_company.company_name;
		this.id_user 		= userData.data_company.id_user;
		this.id_company 	= userData.data_company.id_company;
		this.fname 			= userData.data_company.first_name;
		this.sname 			= userData.data_company.surname;
		this.userType 		= userData.data_company.user_type;
		this.address 		= userData.data_company.address;
		this.formPersonal = this.formBuilder.group({
			id_language		: [this.lang,	[Validators.required]],
			fname			: [this.fname,	[Validators.required]],
			email			: [this.email,	[Validators.required]],
			company			: [this.company,[Validators.required]],
			id_user			: [this.id_user,[Validators.required]],
			user_type		: [this.userType,[Validators.required]]
		});
		this.formPersonal.get('company').disable();
		this.formPersonal.get('user_type').disable();
		this.formPersonal.get('id_user').disable();
	}

	saveInformation(){
		let data = {
			id_languaje: this.languajeValue,
			first_name: this.FnameValue,
			surname: this.sname,
			email: this.emailValue,
			company: this.company,
			id_user: this.id_user,
			userType: this.userType
		}
		this.adminService.update_update_data_user(data).toPromise();
	}
}
