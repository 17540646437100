
// Angular
import { Component,ComponentRef,Input,OnInit,SimpleChanges,EventEmitter, Output} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import {ApexChart,ApexDataLabels,ApexFill,ApexNonAxisChartSeries,ApexPlotOptions,ApexStroke} from 'ng-apexcharts';
import { AdminClientService } from '../../../../core/http/admin-client.service';
import { StarRatingColor } from 'src/app/shared/components/star-rating/star-rating.component';
import { GoogleMapsComponent } from '../../../../shared/libraries/google-maps/google-maps.component';
import { CardData } from 'src/app/core/models';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { EncryptDataService } from 'src/app/core/security/encypt-data.service';
export type ChartOptions = {
	series: ApexNonAxisChartSeries[];
	chart: ApexChart;
	labels: string[];
	plotOptions: ApexPlotOptions;
	dataLabels: ApexDataLabels;
	fill: ApexFill;
	stroke: ApexStroke;
};
@Component({
	selector: 'app-kpi-locker',
	templateUrl: './kpi-locker.component.html',
	styleUrls: ['./kpi-locker.component.scss'],
})
export class KpiLockerComponent implements OnInit {
	@Input() tableData: any[] = [];
	@Input() total: number = 0;
	@Input() deliverToday;
	@Input() recolectToday;
	@Input() refundToday;
	@Input() deliverPercentage;
	@Input() recolectPercentage;
	@Input() refundPercentage;
	@Input() deliverYesterday;
	@Input() recolectYesterday;
	@Input() refundYesterday;
	dataKpis: CardData[];
	gridOverview: string = 'grid-card-3';
	tooltip: string = 'sm-end';
	public chartOptions: Partial<ChartOptions>;
	rating;
	starCount: number = 5;
	starColor: StarRatingColor = StarRatingColor.accent;
	starColorP: StarRatingColor = StarRatingColor.primary;
	starColorW: StarRatingColor = StarRatingColor.warn;
	dataChart: any;
	months: any = [];
	local;
	consumerCount;
	mostActiveLockers = [];
	lockers: any = [];
	locks: [] | any;
	locker_id;
	data;
	columns;
	ubication: any;
	coords =[];
	hoverCoords = '';
	tempLockers: [] | any;
	datos;
	percent;
	package_rating_total = 0;
	consumerMetrics = [
		{
			package_average_collection: '0%',
			package_rating_total: 0,
			package_avg_rating: 0,
			package_delivery: 0,
			package_pickup: 0,
			package_recollected: 0,
			package_today: 0,
			package_today_pickup: 0,
		},
	];
	porcen;
	average_collection;
	tables: boolean;
	latitud: number = 25.698865179069394;
	longitud: number = -100.31614070928171;
	zoom: number = 5;
	arrayMarker: any = [];
	componentRef!: ComponentRef<GoogleMapsComponent>;
	dataSource: MatTableDataSource<[]>;
	dataSourceFilterDate: MatTableDataSource<[]>;
	@Output() emiter_select_comparment: EventEmitter<any> = new EventEmitter<any>();
	constructor(
		private adminService: AdminClientService,
		private router: Router,
		private translate: TranslateService,
		private securityLocalStorage: EncryptDataService
	) {}
	ngOnInit() {
		let data = JSON.parse(this.securityLocalStorage.decryptData(localStorage.getItem('data')));
		this.local = data;
		this.loadDataChart();
		this.getLockersTable();
		this.translate.use(localStorage.getItem('language').toLowerCase());
		this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
			this.translate.use(event.lang);
			this.local = data;
			this.getLockersTable();
			this.getMetricsDash();
			this.columns = [
				'lockerID',
				'location',
				'totalDeliveries',
				'total',
				'doors',
				'available',
				'serial',
				'status',
				'status_access_locker',
			];
			this.datos = [
				{
					percent_deliveries_all: '0',
					percent_return_all: '0',
					percent_deliveries_today: '0',
					percent_collections_today: '0',
				},
				{
					reviews: 0,
				},
			];
			this.getMetricsDash();
		});
	}
	ngOnChanges(changes: SimpleChanges) {
		this.loadDataChart();
		this.getLockersTable();
		this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
			this.translate.use(event.lang);
			this.loadDataChart();
		});
	}
	private loadDataChart() {
		this.dataKpis = [
			// CARD 1
			{
				header: this.translate.instant('kpi-locker.kpi-footer-1'),
				data: this.deliverToday,
				percent: this.deliverPercentage,
				footer: '',
				footerData: null,
				disable: 'display:none;',
				toolTip: this.translate.instant('tooltip.totalDeliver'),
			},
			// CARD 2
			{
				header: this.translate.instant('kpi-locker.kpi-footer-2'),
				data: this.recolectToday,
				percent: this.recolectPercentage,
				footer: '',
				footerData: null,
				disable: 'display:none;',
				toolTip: this.translate.instant('tooltip.totalRecolect'),
			},
			// CARD 3
			{
				header: this.translate.instant('kpi-locker.kpi-footer-3'),
				data: this.refundToday,
				percent: this.refundPercentage,
				footer: '',
				footerData: null,
				disable: 'display:none;',
				toolTip: this.translate.instant('tooltip.totalRefund'),
			},
		];
	}
	loadchartpercent(porcen) {
		this.chartOptions = {
			series: [porcen],
			chart: {
				height: 190,
				type: 'radialBar',
			},
			plotOptions: {
				radialBar: {
					startAngle: 0,
					endAngle: 360,
					hollow: {
						margin: 0,
						size: '65%',
						background: '#fff',
					},
					track: {
						background: '#EFEFFF',
						strokeWidth: '100%',
						margin: 0,
						dropShadow: {
							enabled: true,
							top: 0,
							left: 0,
							blur: 3,
							opacity: 0.2,
						},
					},
					dataLabels: {
						show: true,
						name: {
							offsetY: -20,
							show: false,
							color: '#888',
							fontSize: '17px',
						},
						value: {
							color: '#111',
							fontSize: '16px',
							fontFamily: 'Noto-Ikea-Regular',
							show: true,
							offsetY: 5,
						},
					},
				},
			},
			fill: {
				colors: ['#0F5595'],
			},
			stroke: {
				lineCap: 'round',
			},
		};
		this.tables = true;
	}
	async getChartLine() {
		let data = {
			language: localStorage.getItem('language'),
			year: new Date().getFullYear(),
			id_company: this.local.data_company.id_company,
		};
		this.dataChart = await this.adminService.getQuantityMonth(data);
		this.dataChart.forEach((m) => {
			this.months.push(m.mes_package);
		});
		this.months = this.months.join(',');
		this.dataChart = JSON.stringify(this.dataChart);
	}
	async getConsumerCount() {
		let data = {
			language: localStorage.getItem('language'),
			id_company: this.local.data_company.id_company,
		};
		this.consumerCount = await this.adminService.getConsumerCompanyCount(
			data
		);
	}
	navigate(l) {
		let gridLocker = [];
		localStorage.setItem('id_locker', l.id_locker);
		localStorage.setItem('locker_name', l.locker_name);
		this.locks.forEach((e: any) => {
			if (e.id_locker == l.id_locker) {
				localStorage.setItem('lat', e.latitud);
				localStorage.setItem('lng', e.longitud);
				localStorage.setItem('address', e.locker_address);
				e.MODULE.forEach((m) => {
					m.template_module.forEach((t) => {});
				});
			}
		});
	}
	getLockersTable() {
		this.total = this.tableData.length;
		this.locks = this.tableData;
		this.locks.forEach((e) => {
			this.arrayMarker.push({
				lat: Number(e.latitud),
				lng: Number(e.longitud),
				lockerID: e.id_locker,
			});
			this.coords.push({
				lat: Number(e.latitud),
				lng: Number(e.longitud),
				locker_name: e.locker_name,
				address: e.locker_address,
				lockerID: e.id_locker,
				MODULE: e.MODULE,
			});
		});
		let dataActiveLocker = {
			language: localStorage.getItem('language'),
			year: new Date().getFullYear(),
			id_company: localStorage.getItem('id_company'),
		};
		// this.adminService.getMostActiveLocker(dataActiveLocker).then((res)=>{
		// 	this.mostActiveLockers = res;
		// }).catch(err=>{
		// });
		let deliveries;
		if(this.locks !== undefined){
			this.locks.forEach((element: any, i) => {
				deliveries = Number.parseFloat(
					element.PACKAGE[0].DELIVERY[0].difference_percentage_delivery
				).toFixed(2);
				this.lockers.push({
					id: element.id_locker,
					lockerID: element.locker_name,
					location: 'view',
					totalDeliveries:element.PACKAGE[0].DELIVERY[0].package_month_actuality_delivery,
					total: element.PACKAGE[0].BY_COLLECT[0].result_delivery_to_collect,
					doors: element.door_total,
					available: element.compartments_available,
					status: element.name_status,
					serial: element.serial_locker,
					status_access_locker: element.ping_nombre_status == 'INACTIVE' ? 'DOWN' : 'UP',
					deliveries: deliveries.toString(),
					lat: element.latitud,
					lng: element.longitud,
					address: element.locker_address,
					MODULE: element.MODULE,
				});
			});
			this.data = JSON.stringify(this.lockers);
		}
	}
	hoverMarker(e) {
		this.hoverCoords = JSON.stringify(e.coords);
	}
	outMarker() {
		this.hoverCoords = JSON.stringify({ lat: '', lng: '' });
	}
	async seeLocker(locker: any) {
		let grid = [];
		locker.MODULE.forEach((e, i) => {
			grid.push({
				name_locker_module: e.name_locker_module,
				template_module: e.template_module,
			});
		});
		localStorage.setItem('grid', JSON.stringify(grid));
		localStorage.setItem('id_locker', locker.lockerID);
		localStorage.setItem('lat', locker.lat);
		localStorage.setItem('lng', locker.lng);
		localStorage.setItem('address', locker.address);
		localStorage.setItem('locker_name', locker.locker_name);
		this.router.navigateByUrl(`l-retail/locker/${locker.lockerID}`, {
				skipLocationChange: true,
			})
	}
	onRatingChanged(rating) {
		this.rating = rating;
	}
	getMetricsDash() {
		let data = {
			language: localStorage.getItem('language'),
			id_company: this.local.data_company.id_company,
		};
		this.adminService.getMetricsDash(data).then((res) => {
			this.consumerMetrics = res;
		}).catch((error) => {
			if (error.error === 'Forbidden') {
				this.router.navigateByUrl('/auth/login');
			}
		});
		this.average_collection = parseFloat(this.consumerMetrics[0].package_average_collection).toFixed(1);
		this.loadchartpercent(this.average_collection != 'NaN' ? this.average_collection : 0);
		this.rating = this.consumerMetrics[0].package_avg_rating;
		this.package_rating_total =	this.consumerMetrics[0].package_rating_total;
	}
	addEvent(dateRangeStart: HTMLInputElement, dateRangeEnd: HTMLInputElement) {
		this.dataSource;
		let dateEvent;
		let dateStart;
		let dateEnd;

		this.dataSource.data = this.dataSourceFilterDate.data;
		this.dataSource.data = this.dataSource.data.filter((e: any) => {
			dateEvent = new Date(e.inLocker);
			dateStart = new Date(dateRangeStart.value + ' 00:00:00');
			dateEnd = new Date(dateRangeEnd.value + ' 23:59:59');
			return dateEvent >= dateStart && dateEvent <= dateEnd;
		});
		this.dataSource.data;
	}
	addEventMonth(dateValue) {
		let dateEvent;
		let dateStart;
		let dateEnd;
		let dateYear = dateValue.value.substring(0, 4);
		let dateMes = dateValue.value.substring(5, 7);
		const date = new Date(dateYear, dateMes);
		const firstDayOfMonth = new Date(date.getFullYear(),date.getMonth(),1).getDate();
		const lastDayOfMonth = new Date(date.getFullYear(),date.getMonth(),	0).getDate();
		const dateInicio = dateMes + '/' + firstDayOfMonth + '/' + dateYear + ' 00:00:00';
		const dateFin =	dateMes + '/' + lastDayOfMonth + '/' + dateYear + ' 23:59:59';
		this.dataSource.data = this.dataSourceFilterDate.data;
		this.dataSource.data = this.dataSource.data.filter((e: any) => {
			dateEvent = new Date(e.inLocker);
			dateStart = new Date(dateInicio);
			dateEnd = new Date(dateFin);
			return dateEvent >= dateStart && dateEvent <= dateEnd;
		});
		this.dataSource.data;
	}
	handleMarkerClick(lockerName: string) {
		this.emiter_select_comparment.emit(lockerName);
		// Aquí puedes realizar las acciones que necesites con el nombre del locker
	}
}
// como mandar el lockerName desde el metodo handleMarkerClick
