import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import {
	ApexAxisChartSeries,
	ApexStroke,
	ApexTitleSubtitle,
	ApexDataLabels,
	ApexChart,
	ChartComponent,
	ApexLegend,
	ApexGrid,
	ApexXAxis,
	ApexTheme,
	ApexPlotOptions,
	ApexTooltip,
	ApexYAxis,
	ApexResponsive,
} from 'ng-apexcharts';
@Component({
	selector: 'app-frequency-use',
	templateUrl: './frequency-use.component.html',
	styleUrls: ['./frequency-use.component.scss'],
})
export class FrequencyUseComponent implements OnInit, OnChanges {
	@ViewChild('chart') chart: ChartComponent;
	public chartHeatmap: Partial<ChartOptions>;

	tooltipFrequency: string = 'Tooltip here';

	maxNumber: number = 0;

	// DATES
	@Input() periodBase: string = '';
	@Input() periodComp: string = '';
	@Input() changeStyles: string = 'dates-default';

	@Input() data = [];
	@Input() addComp: boolean;

	constructor(private translate: TranslateService) {

	}
	ngOnInit(): void {
		this.translate.use(localStorage.getItem('language').toLowerCase());
		this.tooltipFrequency = this.translate.instant('tooltip.frequencyOfUseByHours');
		this.loadDataChart();
		this.translate.onLangChange.subscribe((event:LangChangeEvent)=>{
			this.tooltipFrequency = this.translate.instant('tooltip.frequencyOfUseByHours');
			this.loadDataChart();
		});

	}

	ngOnChanges(changes: SimpleChanges) {
		this.loadDataChart();
	}

	private loadDataChart() {
		// Inicializar con un valor muy bajo
		this.maxNumber = 0;
		this.data.forEach((item) => {
			const maxInItem = Math.max(...item.data); // Encontrar el número más alto en item.data
			if (maxInItem > this.maxNumber) {
				this.maxNumber = maxInItem;// Actualizar el número más alto si es mayor que maxNumber
			}
		});

		if (this.data.length > 0) {
			this.data.forEach((res) => {
				this.chartHeatmap = {
					series: this.data.map((item) => ({
						name: item.hour,
						data: this.addComp ? item.data : item.data.slice(0, item.data.length - 8),
					})),
					chart: {
						height: 410,
						type: 'heatmap',
						background: 'var(--color-container)',
						foreColor: 'var(--color-container)',
						stacked: true,
						toolbar: {
							show: true,
							tools:{
								download:`
									<div class="downloadClassFrequencyUse">
										<label class="textExport">
											${this.translate.instant('buttons.export')}
										</label>
										<div class="icon">
										</div>
									</div>
								`,
								pan:false,
								reset:false,
								zoom:false,
								zoomin:false,
								zoomout:false,
								selection:true
							},
						},
						sparkline: {
							enabled: false,
						},
					},
					dataLabels: {
						enabled: true,
					},
					title: {
						text: '',
					},
					legend: {
						show: false,
						position: 'bottom',
					},
					xaxis: {
						categories: this.addComp ? res.day.map((day) => day.substring(0, 3)) : res.day.slice(0, res.day.length - 8).map((day) => day.substring(0, 3)),
						axisBorder: {
							show: false,
						},
					},
					yaxis: {
						axisBorder: {
							show: false, // Oculta las líneas del eje Y
						},
					},
					grid: {
						show: false,
					},
					plotOptions: {
						heatmap: {
							enableShades: false,
							radius: 5,
							distributed: false,
							colorScale: {
								ranges: [
									{
										from: -30,
										to: -1,
										color: 'var(--color-container)',
										name: ' ',
										foreColor: 'var(--color-container)',
									},
									{
										from: 0,
										to: this.maxNumber / 5,
										color: '#8AC6E7',
										name: ' ',
										foreColor: '#FFFFFF',
									},
									{
										from: this.maxNumber / 5,
										to: this.maxNumber / 4,
										color: '#54ABDC',
										name: ' ',
										foreColor: '#FFFFFF',
									},
									{
										from: this.maxNumber / 4,
										to: this.maxNumber / 3,
										color: '#0082CB',
										name: ' ',
										foreColor: '#FFFFFF',
									},
									{
										from: this.maxNumber / 3,
										to: this.maxNumber / 2,
										color: '#016299',
										name: ' ',
										foreColor: '#FFFFFF',
									},
									{
										from: this.maxNumber / 2,
										to: this.maxNumber / 1,
										color: '#003755',
										name: ' ',
										foreColor: '#FFFFFF',
									},
								],
							},
						},
					},
					responsive: [
						{
							breakpoint: 620,
							options:{
								chart: {
									series: this.data.map((item) => ({
										name: item.hour,
										data: this.addComp ? item.data : item.data.slice(0, item.data.length - 8),
									})),
									chart: {
										height: 350,
										type: 'heatmap',
										background: 'var(--color-container)',
										foreColor: 'var(--color-container)',
										stacked: true,
										toolbar: {
											show: true,
											tools:{
												download:`
													<div class="downloadClassFrequencyUse">
														<label class="textExport">
															Exportar
														</label>
														<div class="icon">
														</div>
													</div>
												`,
												pan:false,
												reset:false,
												zoom:false,
												zoomin:false,
												zoomout:false,
												selection:true
											},
										},
										sparkline: {
											enabled: false,
										},
									},
								},

							}
						},
					],
					tooltip: {
						enabled: false,
					},
					stroke: {
						show: true,
						width: 6,

						colors: ['var(--color-container)'],
					},

				};
			});
		} else if (this.data.length === 0) {
			this.chartHeatmap = {
				series: [
					{
						name: '22:00',
						data: [1, 1, 1, 1, 1, 1, 1],
					},
					{
						name: '21:00',
						data: [1, 1, 1, 1, 1, 1, 1],
					},
					{
						name: '20:00',
						data: [1, 1, 1, 1, 1, 1, 1],
					},
					{
						name: '19:00',
						data: [1, 1, 1, 1, 1, 1, 1],
					},
					{
						name: '18:00',
						data: [1, 1, 1, 1, 1, 1, 1],
					},
					{
						name: '17:00',
						data: [1, 1, 1, 1, 1, 1, 1],
					},
					{
						name: '16:00',
						data: [1, 1, 1, 1, 1, 1, 1],
					},
					{
						name: '15:00',
						data: [1, 1, 1, 1, 1, 1, 1],
					},
					{
						name: '14:00',
						data: [1, 1, 1, 1, 1, 1, 1],
					},
					{
						name: '13:00',
						data: [1, 1, 1, 1, 1, 1, 1],
					},
					{
						name: '12:00',
						data: [1, 1, 1, 1, 1, 1, 1],
					},
					{
						name: '11:00',
						data: [1, 1, 1, 1, 1, 1, 1],
					},
					{
						name: '10:00',
						data: [1, 1, 1, 1, 1, 1, 1],
					},
					{
						name: '09:00',
						data: [1, 1, 1, 1, 1, 1, 1],
					},
					{
						name: '08:00',
						data: [1, 1, 1, 1, 1, 1, 1],
					},
				],
				chart: {
					height: 450,
					type: 'heatmap',
				},
				dataLabels: {
					enabled: false,
				},
				title: {
					text: '',
				},
				legend: {
					show: false,
					position: 'bottom',
				},
				xaxis: {
					categories: ['L', 'Ma', 'Mi', 'J', 'V', 'S', 'D'],
				},
				plotOptions: {
					heatmap: {
						shadeIntensity: 0,
						radius: 5,
						distributed: true,
						colorScale: {
							ranges: [
								{
									from: 0,
									to: 2,
									color: '#a6a6a6',
									name: ' ',
								},
							],
						},
					},
				},

				tooltip: {
					enabled: false,
				},
			};
		}
		this.data = [];
	}
}

export type ChartOptions = {
	series: ApexAxisChartSeries;
	chart: ApexChart;
	dataLabels: ApexDataLabels;
	title: ApexTitleSubtitle;
	colors: any;
	legend: ApexLegend;
	xaxis: ApexXAxis;
	yaxis: ApexYAxis;
	theme: ApexTheme;
	plotOptions: ApexPlotOptions;
	tooltip: ApexTooltip;
	stroke: ApexStroke;
	grid: ApexGrid;
	responsive: ApexResponsive[];
};
