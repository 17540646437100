import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
	selector: 'app-estatus-activity-package',
	templateUrl: './estatus-activity-package.component.html',
	styleUrls: ['./estatus-activity-package.component.scss']
})
export class EstatusActivityPackageComponent implements ICellRendererAngularComp, OnInit {

	idTopicAudit:number;
	constructor() { }

	agInit(params: ICellRendererParams) {
		this.idTopicAudit = params.data.id_topic_audit;

	}
	refresh(params: ICellRendererParams): boolean {
		return false;
	}

	ngOnInit(): void {
	}

}
