import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, GridOptions } from 'ag-grid-community';
import { FileDataEstatusComponent } from '../file-data-estatus/file-data-estatus.component';
import { UserService } from 'src/app/core/services/user.service';
import { TableUserService } from 'src/app/core/http/user.service';
import { StoresService } from 'src/app/core/services/stores.service';

@Component({
  selector: 'app-file-data-modal',
  templateUrl: './file-data-modal.component.html',
  styleUrls: ['./file-data-modal.component.scss']
})
export class FileDataModalComponent implements OnInit {
	data=[];
	dataBoolean:boolean=false;
	isDataCreated: boolean = false;
	dataCreated:boolean = false;
	columnDefs: ColDef[];
	gridOptions: GridOptions;
	stores: any[] = [];
	cities=[];
	loading: boolean = false;
	errorMessage: string = '';
	public overlayNoRowsTemplate = `
		<div class="no-rows-content">
			<div class="img-no-content">
				<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
					<path d="M34.1667 26.6667H13.3333M13.3333 26.6667V5.83333M13.3333 26.6667L5.83333 34.1667M5.83333 13.3333H26.6667M26.6667 13.3333V34.1667M26.6667 13.3333L34.1667 5.83333M35 25.5621V6.33333C35 5.86662 35 5.63327 34.9092 5.45501C34.8293 5.29821 34.7018 5.17072 34.545 5.09083C34.3667 5 34.1334 5 33.6667 5H14.4379C14.0303 5 13.8264 5 13.6346 5.04605C13.4646 5.08688 13.302 5.15422 13.1529 5.2456C12.9847 5.34867 12.8405 5.4928 12.5523 5.78105L5.78105 12.5523C5.4928 12.8405 5.34867 12.9847 5.2456 13.1529C5.15422 13.302 5.08688 13.4646 5.04605 13.6346C5 13.8264 5 14.0303 5 14.4379V33.6667C5 34.1334 5 34.3667 5.09083 34.545C5.17072 34.7018 5.29821 34.8293 5.45501 34.9092C5.63327 35 5.86662 35 6.33333 35H25.5621C25.9697 35 26.1736 35 26.3654 34.9539C26.5354 34.9131 26.698 34.8458 26.8471 34.7544C27.0153 34.6513 27.1595 34.5072 27.4477 34.219L34.2189 27.4477C34.5072 27.1595 34.6513 27.0153 34.7544 26.8471C34.8458 26.698 34.9131 26.5354 34.9539 26.3654C35 26.1736 35 25.9697 35 25.5621Z" stroke="#98A2B3" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
				</svg>
			</div>
			<div class="txt-no-content">
				<div class="title-no-content">
					No hay nada por aquí...
				</div>
				<div class="subtitle-no-content">
					No encontramos usuarios para agregar
				</div>
			</div>
		</div>
	`;
	public overlayLoadingTemplate =  `
		<div class="loader-container">
			<div class="loader-template">
			</div>
			<div class="text-loader">
				<div class="title">
					Cargando contenido...
				</div>
			</div>
		</div>
	`;
	states = [
		{ id: 1, name: 'Aguascalientes', abbreviation: 'AG' },
		{ id: 2, name: 'Baja California', abbreviation: 'BC' },
		{ id: 3, name: 'Baja California Sur', abbreviation: 'BS' },
		{ id: 4, name: 'Campeche', abbreviation: 'CM' },
		{ id: 5, name: 'Chiapas', abbreviation: 'CS' },
		{ id: 6, name: 'Chihuahua', abbreviation: 'CH' },
		{ id: 7, name: 'Ciudad de México (DF)', abbreviation: 'DF' },
		{ id: 8, name: 'Coahuila', abbreviation: 'CO' },
		{ id: 9, name: 'Colima', abbreviation: 'CL' },
		{ id: 10, name: 'Durango', abbreviation: 'DG' },
		{ id: 11, name: 'Estado de México', abbreviation: 'MX' },
		{ id: 12, name: 'Guanajuato', abbreviation: 'GT' },
		{ id: 13, name: 'Guerrero', abbreviation: 'GR' },
		{ id: 14, name: 'Hidalgo', abbreviation: 'HG' },
		{ id: 15, name: 'Jalisco', abbreviation: 'JA' },
		{ id: 16, name: 'Michoacán', abbreviation: 'MI' },
		{ id: 17, name: 'Morelos', abbreviation: 'MO' },
		{ id: 18, name: 'Nayarit', abbreviation: 'NA' },
		{ id: 19, name: 'Nuevo León', abbreviation: 'NL' },
		{ id: 20, name: 'Oaxaca', abbreviation: 'OA' },
		{ id: 21, name: 'Puebla', abbreviation: 'PU' },
		{ id: 22, name: 'Querétaro', abbreviation: 'QT' },
		{ id: 23, name: 'Quintana Roo', abbreviation: 'QR' },
		{ id: 24, name: 'San Luis Potosí', abbreviation: 'SL' },
		{ id: 25, name: 'Sinaloa', abbreviation: 'SI' },
		{ id: 26, name: 'Sonora', abbreviation: 'SO' },
		{ id: 27, name: 'Tabasco', abbreviation: 'TB' },
		{ id: 28, name: 'Tamaulipas', abbreviation: 'TM' },
		{ id: 29, name: 'Tlaxcala', abbreviation: 'TL' },
		{ id: 30, name: 'Veracruz', abbreviation: 'VE' },
		{ id: 31, name: 'Yucatán', abbreviation: 'YU' },
		{ id: 32, name: 'Zacatecas', abbreviation: 'ZA' },
	];
	@ViewChild('agGrid') agGrid: AgGridAngular;
	constructor(
		private translate: TranslateService,
		public dialogRef: MatDialogRef<FileDataModalComponent>,
		@Inject(MAT_DIALOG_DATA) public inputData: any,
		private storeService: StoresService
	){
		this.data = inputData;
		console.log(this.data);
	}
	ngOnInit(): void {
		this.dataBoolean = this.data.length > 0;
		console.log(this.dataBoolean);
		this.gridOptions={
			pagination: true,
			paginationPageSize: 10,
			headerHeight:49,
			rowHeight:49,
			defaultColDef:{
				resizable: false,
				sortable: true,
				flex:1
			}
		}
		this.getColumnsDefs(localStorage.getItem('language'));
		this.translate.onLangChange.subscribe(lang=>{
			this.getColumnsDefs(lang.lang);
		});

	}


	getColumnsDefs(lang:string){
		// Función para verificar si existe un error para un campo específico
		function hasFieldError(errors: string[] | undefined, fieldName: string): boolean {
			if (!errors) {
				// Si errors es undefined, retorna false ya que no hay errores
				return false;
			}
		}
		const translations = require(`../../../../assets/languaje/${lang}.json`);
		this.columnDefs = [
			{
				headerName: '#',
				minWidth: 20,
				maxWidth: 20,
				cellRenderer: (params) => {
					return `${params.node.rowIndex + 1}`;
				}
			},
			{
				colId				: 'store_name',
				headerValueGetter	: () => translations.storesPage.tables.general.storeName,
				field				: 'store_name',
				resizable			: false,
				suppressMovable		: true,
				headerClass			: 'header-text-table',
				cellClass			: 'text-all-cells',
				minWidth			: 120,
				maxWidth			: 150,
				cellRenderer		: (params) => {
					return params.value ? params.value.substring(0, 30) : '...';
				}
			},
			{
				colId				: 'store_alias',
				headerValueGetter	: () => translations.storesPage.tables.general.storeAlias,
				field				: 'store_alias',
				resizable			: false,
				suppressMovable		: true,
				headerClass			: 'header-text-table',
				cellClass			: 'text-all-cells',
				minWidth			: 120,
				maxWidth			: 150,
			},
			{
				colId				: 'state',
				headerValueGetter	: () => translations.storesPage.tables.general.state,
				field				: 'state',
				resizable			: false,
				suppressMovable		: true,
				headerClass			: 'header-text-table',
				cellClass			: 'text-all-cells',
				minWidth			: 120,
				maxWidth			: 150,
			},
			{
				colId				: 'city',
				headerValueGetter	: () => translations.storesPage.tables.general.city,
				field				: 'city',
				resizable			: false,
				suppressMovable		: true,
				headerClass			: 'header-text-table',
				cellClass			: 'text-all-cells',
				minWidth			: 200,
				maxWidth			: 250,
			},
			{
				colId				: 'address',
				headerValueGetter	: () => translations.storesPage.tables.general.address,
				field				: 'address',
				resizable			: false,
				suppressMovable		: true,
				headerClass			: 'header-text-table',
				cellClass			: 'text-all-cells',
				minWidth			: 550,
				maxWidth			: 600,
			},
			{
				colId				: 'latitude',
				headerValueGetter	: () => translations.storesPage.tables.general.latitude,
				field				: 'latitude',
				resizable			: false,
				suppressMovable		: true,
				headerClass			: 'header-text-table',
				cellClass			: 'text-all-cells',
				minWidth			: 120,
				maxWidth			: 150,
			},
			{
				colId				: 'longitude',
				headerValueGetter	: () => translations.storesPage.tables.general.longitude,
				field				: 'longitude',
				resizable			: false,
				suppressMovable		: true,
				headerClass			: 'header-text-table',
				cellClass			: 'text-all-cells',
				minWidth			: 150,
				maxWidth			: 200,
			},
			{
				colId				: 'codigo_referencia',
				headerValueGetter	: () => translations.storesPage.tables.general.referenceCode,
				field				: 'codigo_referencia',
				resizable			: false,
				suppressMovable		: true,
				headerClass			: 'header-text-table',
				cellClass			: 'text-all-cells',
				minWidth			: 150,
				maxWidth			: 200,
			},
			{
				colId				: 'opening_time',
				headerValueGetter	: () => translations.storesPage.tables.general.openening_time,
				field				: 'opening_time',
				resizable			: false,
				suppressMovable		: true,
				headerClass			: 'header-text-table',
				cellClass			: 'text-all-cells',
				minWidth			: 120,
				maxWidth			: 150,
			},
			{
				colId				: 'closing_time',
				headerValueGetter	: () => translations.storesPage.tables.general.closing_time,
				field				: 'closing_time',
				resizable			: false,
				suppressMovable		: true,
				headerClass			: 'header-text-table',
				cellClass			: 'text-all-cells',
				minWidth			: 120,
				maxWidth			: 150,
			},
		]
	}
	onGridReady(params: any) {
		this.agGrid.gridOptions={
			rowHeight:48,
			animateRows:true,
			pagination:true,
			paginationPageSize:10
		}
		this.agGrid.api.sizeColumnsToFit();
	}
	closeDialog(){
		this.dialogRef.close({ fileData: this.data, isAdded:false });
	}
	async createStores(){
		console.log(this.data);
		const cityPromises: Promise<void>[] = [];
		this.data.forEach((res: any) => {
			// Busca el estado correspondiente en this.states
			const state = this.states.find(item => item.name === res.state);

			console.log(state);
			if (state) {
				// Si el estado existe, asigna el id_state al objeto
				res.id_state = state.id;
				// Añadir la promesa para cargar las ciudades del estado
				cityPromises.push(this.getAllCities(res.id_state));
			} else {
				// Si no se encuentra el estado, asigna null o maneja el error según tu lógica
				res.id_state = null;
				console.warn(`Estado no encontrado: ${res.state}`);
			}
		});
		// Esperar a que todas las ciudades sean cargadas
		await Promise.all(cityPromises);


	}


	async assignCityIdsToStores(cities): Promise<void> {
		this.data.forEach((store: any) => {
			const cityName = store.city.trim(); // Asegúrate de que no haya espacios extra
			const city = cities.find(item => item.name === cityName); // Buscar la ciudad por nombre
			console.log(city);
			if (city) {
				store.id_city = city.id; // Asignar el id_city correspondiente
				store.photo_name= "imagen_fail.jpg";
				store.id_status= 1;

			} else {
				store.id_city = null; // Si no encontramos la ciudad, asignar null
				console.warn(`Ciudad no encontrada: ${cityName}`);
			}
		});

		console.log(this.data);

		let obj = {
			store_data: this.data,
		};
		console.log(obj);
		this.dialogRef.close({
			storeCreated:true,
		});
		this.storeService.addStore(obj).then((res: any) => {
			console.log('Response from addStore:', res);
			if(res.status===200){
				this.dialogRef.close({
					storeCreated:true,
				});
			}
		}).catch((error: any) => {
			console.error('Error saving store:', error);
		});
	}
	// Cargar las ciudades desde el servicio y almacenarlas en el mapa
	async getAllCities(stateId: number) {
		try {
			this.storeService.AllCities(stateId).then((res:any)=>{
				console.log(res);
				res.cities.map((element:any)=>{
					this.cities.push({
						id:element.id_city,
						name:element.city
					});

				});
				this.assignCityIdsToStores(this.cities);
			});
			console.log(this.cities);
		} catch (error) {
			console.error('Error al obtener ciudades:', error);
		}
	}
}
