import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-pagination-size-selector',
	templateUrl: './pagination-size-selector.component.html',
	styleUrls: ['./pagination-size-selector.component.scss']
})
export class PaginationSizeSelectorComponent {
	@Input() pageSize: number;
	@Input() pageSizeOptions: number[] = [];

	@Input() firstRow:number;
	@Input() lastRow:number;
	@Input() rowCount:number;

	@Output() pageSizeChanged: EventEmitter<number> = new EventEmitter<number>();

	showOptions: boolean = false;
	constructor() { }
	toggleOptions() {
		this.showOptions = !this.showOptions;
	}
	selectOption(option: number) {
		// Aquí puedes hacer lo que necesites con la opción seleccionada
		this.pageSizeChanged.emit(option);
		this.showOptions = false; // Oculta las opciones después de seleccionar una
	}
	onPageSizeChange(size: number) {
		this.pageSizeChanged.emit(size);
	}
}

