<!-- ? CONTAINER MAIN -->
<div class="cont_main">
    <!-- ? MENU CONTAINER -->
    <div class="menu_container">
        <!-- ? SECTION 1 | HEADER MENU 1 --------------------------------------------------->
        <div class="section_block_">
            <!-- ? BLOCK 1 | USERNAME | COMPANY ------------------------------------------------>
            <div class="header_block_1">
                <!-- COMPANY NAME-->
                <p class="font_medium caption_c1 color-txt-primary"> {{companyName}} </p>

                <!-- USERNAME-->
                <p class="font_regular caption_c3 color_primary_300"> {{companyType | titlecase}} </p>

            </div>

            <!-- ? BLOCK 2 | MENU 1 ------------------------------------------------>
            <div class="block_menu">
                <!-- ? LIST -->
                <ul class="ul_">
                    <li *ngFor="let nav of menuBlock1">
                        <!-- ? LINK MENU-->
                        <a  class="link_menu"
                            [routerLink]="nav.route"
                            (click)="closeHeader()"
                            routerLinkActive="active_link">
                            <!-- ? ICON TEXT CONTAINER -->
                            <div class="icon_txt">
                                <!-- ? ICON LINK CONTAINER-->
                                <a  class="icon_link_cont"
                                    routerLinkActive="active_link_icon">
                                    <!-- ICON -->
                                    <img [src]="'../../../../assets/icons/sidebar_icons/icon-sidebar-'+nav.icon+'.svg'" class="icon_link">
                                </a>
                                <!-- TXT -->
                                <p class="font_regular caption_c1 color-txt-primary opacity_"> {{nav.txt | translate }} </p>
                                <!-- INPUT -->
                            </div>
                        </a>
                    </li>
                </ul>
            </div>

        </div>

        <!-- ? SECTION 2 | MENU 2 MENU 3 USER --------------------------------------------------->
        <div class="section_block_">
            <!-- HR -->
            <div class="hr_cont">
                <div class="hr_"></div>
            </div>

            <!-- ? BLOCK 3 | MENU 2 ------------------------------------------------>
            <div class="block_menu">
                <!-- ? LIST -->
                <ul >
                    <li *ngFor="let nav of menuBlock2" >
                        <!-- ? LINK MENU-->
                        <a  class="link_menu" *ngIf="this.userType === 9"
                            [routerLink]="nav.route"
                            routerLinkActive="active_link">
                            <!-- ? ICON TEXT CONTAINER -->
                            <div class="icon_txt">
                                <!-- ? ICON LINK CONTAINER-->
                                <a  class="icon_link_cont"
                                    routerLinkActive="active_link_icon">
                                    <!-- ICON -->
                                    <img [src]="'../../../../assets/icons/sidebar_icons/icon-sidebar-'+nav.icon+'.svg'" class="icon_link">
                                </a>
                                <!-- TXT -->
                                <p class="caption_c1 color-txt-primary opacity_"> {{nav.txt | translate }} </p>
                            </div>
                        </a>

                    </li>
                    <!-- ? LANG CONTAINER -->
                    <li class="li_lang">
                        <!-- ? LINK MENU-->
                        <a  class="link_menu"
                            routerLinkActive="active_link">
                            <!-- ? ICON TEXT CONTAINER -->
                            <div class="icon_txt">
                                <!-- ? ICON LINK CONTAINER-->
                                <a  class="icon_link_cont"
                                    routerLinkActive="active_link_icon">
                                    <!-- ICON -->
                                    <img src="../../../../assets/icons/sidebar_icons/icon-sidebar-lang.svg" class="icon_link">
                                </a>
                                <!-- TXT -->
                                <p class="caption_c1 color-txt-primary opacity_"> {{'navbar.lang' | translate }} </p>
                                <!-- INPUT -->
                            </div>
                            <!-- ? CHEVRON -->
                            <div class="icon_chevron_menu">
                            </div>
                            <!-- CHECKBOX -->
                            <input  type="checkbox" (click)="checkLang()" [(ngModel)]="this.langCheck" class="checkbox_menu">
                        </a>

                        <div class="dropdown_content">
                            <ul class="dropdown_list">
                                <!-- ? SPANISH -->
                                <li [ngClass]="{ 'lang_selected' : getLang() == 'es' }">
                                    <p  class="caption_c1 color-txt-primary font_regular"
                                        (click)="changeLanguage('es')">
                                        {{ 'navbar.es' | translate}}
                                    </p>
                                </li>
                                <!-- ? ENGLISH -->
                                <li [ngClass]="{ 'lang_selected' : getLang() == 'en' }">
                                    <p  class="caption_c1 color-txt-primary font_regular"
                                        (click)="changeLanguage('en')">
                                        {{ 'navbar.en' | translate}}
                                    </p>
                                </li>
                            </ul>

                        </div>
                    </li>
                    <!-- ? MODE CONTAINER -->
                    <li class="li_mode">
                        <!-- ? LINK MENU-->
                        <a  class="link_menu"
                            routerLinkActive="active_link">
                            <!-- ? ICON TEXT CONTAINER -->
                            <div class="icon_txt">
                                <!-- ? ICON LINK CONTAINER-->
                                <a  class="icon_link_cont"
                                    routerLinkActive="active_link_icon">
                                    <!-- ICON -->
                                    <img src="../../../../assets/icons/sidebar_icons/icon-sidebar-appearance.svg" class="icon_link">
                                </a>
                                <!-- TXT -->
                                <p class="caption_c1 color-txt-primary opacity_"> {{'navbar.appearance' | translate }} </p>
                                <!-- INPUT -->
                            </div>
                            <!-- ? CHEVRON -->
                            <div class="icon_chevron_menu">
                            </div>
                            <!-- CHECKBOX -->
                            <input  type="checkbox" (click)="checkMode()" [(ngModel)]="this.modeCheck" class="checkbox_menu">
                        </a>

                        <div class="dropdown_content">
                            <ul class="dropdown_list">
                                <li [ngClass]="{ 'lang_selected' : this.mode == 'dark' }">
                                    <p  class="caption_c1 color-txt-primary font_regular"
                                        (click)="changeMode('dark')">
                                        {{ 'navbar.dark' | translate}}
                                    </p>
                                </li>
                                <li [ngClass]="{ 'lang_selected' : this.mode == 'light' }">
                                    <p  class="caption_c1 color-txt-primary font_regular"
                                        (click)="changeMode('light')">
                                        {{ 'navbar.light' | translate}}
                                    </p>
                                </li>
                            </ul>

                        </div>
                    </li>
                </ul>
            </div>

            <!-- HR -->
            <div class="hr_cont">
                <div class="hr_"></div>
            </div>

            <!-- ? BLOCK 4 | MENU 3 ------------------------------------------------>
            <div class="block_menu">
                <!-- ? LIST -->
                <ul class="ul_" *ngIf="this.userType === 9">
                    <li *ngFor="let nav of menuBlock3">
                        <!-- ? LINK MENU-->
                        <a  class="link_menu"
                            [routerLink]="nav.route"
                            routerLinkActive="active_link">
                            <!-- ? ICON TEXT CONTAINER -->
                            <div class="icon_txt">
                                <!-- ? ICON LINK CONTAINER-->
                                <a  class="icon_link_cont"
                                    routerLinkActive="active_link_icon">
                                    <!-- ICON -->
                                    <img [src]="'../../../../assets/icons/sidebar_icons/icon-sidebar-'+nav.icon+'.svg'" class="icon_link">
                                </a>
                                <!-- TXT -->
                                <p class="caption_c1 color-txt-primary opacity_"> {{nav.txt | translate }} </p>
                                <!-- INPUT -->
                            </div>
                        </a>
                    </li>
                </ul>
            </div>

            <!-- ? BLOCK USER -->
            <div class="block_user">
                <!-- ? CIRCLE TXT -->
                <div class="profile_cont" (click)="goToAccount()">
                    <div class="profile_">
                        <p class="color_primary_400 font_medium caption_c2">{{profile}} </p>
                    </div>
                    <div class="profile_txt">
                        <p class="opacity_ font_medium caption_c1 color-txt-primary">{{userName}}</p>
                        <p class="opacity_ font_regular caption_c3 color_primary_300">{{'navbar.myaccount' | translate}}</p>
                    </div>
                </div>

                <!-- ? LOGOUT CONTAINER -->
                <div class="logout_cont">
                    <!-- LOGOUT -->
                    <img (click)="logout()" src="../../../../assets/icons/sidebar_icons/icon-sidebar-logout.svg">

                </div>

            </div>

        </div>



    </div>
    <!-- ? HAMBURGER MENU -->
    <div class="hamburger_menu">
        <input type="checkbox" class="hamburger_checkbox" [(ngModel)]="this.hamburgerCheck" (click)="closeAllDrops()" >
        <!-- CROSS -->
        <div class="cross_">
            <div class="line_1"></div>
            <div class="line_2"></div>
            <div class="line_3"></div>
        </div>
    </div>

    <!-- ? LOGO -->
    <div class="logo_container" (click)="goToUp()">
        <img src="../../../../assets/icons/header_menu/icon-logo.svg">
    </div>

    <!-- EMPTY DIV -->
    <div class="empty_div">

    </div>

</div>
