import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { AgGridAngular } from 'ag-grid-angular';
import { CellClickedEvent, ColDef, GridOptions } from 'ag-grid-community';
import { WebhookService } from 'src/app/core/http/webhook.service';
import { AccessToApiResult, EventWebhook, QueryWebhook, ReturnResultWebHook } from 'src/app/core/models/webhook/webhook.interface';
import { DialogSeeJsonWebhookComponent } from '../dialogs/dialog-see-json-webhook/dialog-see-json-webhook.component';
import { Router } from '@angular/router';
import { EncryptDataService } from 'src/app/core/security/encypt-data.service';

@Component({
	selector: 'app-activity',
	templateUrl: './activity.component.html',
	styleUrls: ['./activity.component.scss']
})
export class ActivityComponent {
	apiResultWebhook:AccessToApiResult;
	eventsWebHook =[];
	idWebhook:number;


	gridOptions: GridOptions;
	rowData: any[] = [];
	tableData =[];
	@ViewChild('agGrid') agGrid: AgGridAngular;
	columnDefs: ColDef[];
	selectedMarkerData: any;
	public overlayNoRowsTemplate = `
		<div class="no-rows-content">
			<div class="img-no-content">
				<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
					<path d="M34.1667 26.6667H13.3333M13.3333 26.6667V5.83333M13.3333 26.6667L5.83333 34.1667M5.83333 13.3333H26.6667M26.6667 13.3333V34.1667M26.6667 13.3333L34.1667 5.83333M35 25.5621V6.33333C35 5.86662 35 5.63327 34.9092 5.45501C34.8293 5.29821 34.7018 5.17072 34.545 5.09083C34.3667 5 34.1334 5 33.6667 5H14.4379C14.0303 5 13.8264 5 13.6346 5.04605C13.4646 5.08688 13.302 5.15422 13.1529 5.2456C12.9847 5.34867 12.8405 5.4928 12.5523 5.78105L5.78105 12.5523C5.4928 12.8405 5.34867 12.9847 5.2456 13.1529C5.15422 13.302 5.08688 13.4646 5.04605 13.6346C5 13.8264 5 14.0303 5 14.4379V33.6667C5 34.1334 5 34.3667 5.09083 34.545C5.17072 34.7018 5.29821 34.8293 5.45501 34.9092C5.63327 35 5.86662 35 6.33333 35H25.5621C25.9697 35 26.1736 35 26.3654 34.9539C26.5354 34.9131 26.698 34.8458 26.8471 34.7544C27.0153 34.6513 27.1595 34.5072 27.4477 34.219L34.2189 27.4477C34.5072 27.1595 34.6513 27.0153 34.7544 26.8471C34.8458 26.698 34.9131 26.5354 34.9539 26.3654C35 26.1736 35 25.9697 35 25.5621Z" stroke="#98A2B3" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
				</svg>
			</div>
			<div class="txt-no-content">
				<div class="title-no-content">
					No hay nada por aquí...
				</div>
				<div class="subtitle-no-content">
					En esta tabla las actividades del webhook
				</div>
			</div>
		</div>
	`;
	public overlayLoadingTemplate =  `
		<div class="loader-container">
			<div class="loader-template">
			</div>
			<div class="text-loader">
				<div class="title">
					Cargando contenido...
				</div>
			</div>
		</div>
	`

	constructor(
		private translate: TranslateService,
		private dialog: MatDialog,
		private webhook: WebhookService,
		private router: Router,
		private securityLocalStorage: EncryptDataService
	){
	}

	ngOnInit(){
		this.gridOptions={
			pagination: true,
			paginationPageSize: 10,
			headerHeight:49,
			rowHeight:49,
			enableCellTextSelection: true,
			defaultColDef:{
				resizable: false,
				sortable: true,
				flex:1
			}
		}
		this.translate.use(localStorage.getItem('language').toLowerCase());
		this.updateColDef(localStorage.getItem('language'));
		this.getApiAccessToWebHook();
		this.translate.onLangChange.subscribe((event:LangChangeEvent)=>{
			this.translate.use(event.lang);
			this.updateColDef(event.lang);
		});
	}
	updateColDef(lang:string){
		const translations = require(`../../../../../assets/languaje/${lang}.json`);
		this.columnDefs = this.columnDefs = [
			{
				headerValueGetter	: () => translations.WebHook.table.url,
				field				: 'url',
				autoHeight			: false,
				sortable			: true,
				resizable			: false,
				suppressMovable		: true,
				headerClass			: 'header-text-table',
				cellClass			: 'link-box-primary',
				minWidth			: 500,
				maxWidth 			: 600
			},
			{
				headerValueGetter	: () => translations.WebHook.table.eventType,
				field				: 'service',
				autoHeight			: false,
				sortable			: true,
				resizable			: false,
				suppressMovable		: true,
				headerClass			: 'header-text-table',
				cellClass			: 'text-all-cells',
				minWidth			: 350,
				maxWidth			: 450,
			},
			{
				headerValueGetter	: () => translations.WebHook.table.dateEvent,
				field				: 'registration_date',
				autoHeight			:false,
				sortable			: true,
				resizable			: false,
				suppressMovable		: true,
				headerClass			:'header-text-table',
				cellClass			:'text-all-cells',
				minWidth			: 450,
				maxWidth			: 550,
			},
			{
				headerValueGetter: () => translations.WebHook.table.json,
				field: '',
				autoHeight:false,
				sortable: true,
				resizable: false,
				suppressMovable: true,
				headerClass:'header-text-table',
				cellClass:'text-all-cells',
				minWidth:150,
				maxWidth:150,
				onCellClicked: ($event) => this.openToSeeJson($event),
			},
		]
	}
	getApiAccessToWebHook(){
		let objectAccessToApi = {
			language:localStorage.getItem('language').toLowerCase()
		}
		this.webhook.getWebHook(objectAccessToApi).then((res:QueryWebhook)=>{
			res.return_data_webhooks.forEach(item=>{
				this.idWebhook = item.id_webhock
				this.apiResultWebhook = item
			})
			this.idWebhook = this.apiResultWebhook.id_webhock
			this.getDataWebHook(this.apiResultWebhook.id_webhock);
		})
	}
	getDataWebHook(id_webhock:number){
		let objectToGetWebHookCode={
			language: localStorage.getItem('language').toLowerCase(),
			id_webhock:id_webhock,
			order_by:'DESC'
		}
		this.webhook.getQueryEventWebHook(objectToGetWebHookCode).then((res:EventWebhook)=>{
			if(res.mensaje_return.CODE === 200){
				this.rowData = res.return_data_webhooks;
			}
			else{
				this.rowData = [];
			}
		});


	}
	onCellClicked(e: CellClickedEvent): void {
	}
	async getTableCompleted() {
		this.agGrid.api.setRowData(this.eventsWebHook);
	}
	backPage(){
		let local = JSON.parse(this.securityLocalStorage.decryptData(localStorage.getItem('data')));
		let userTypeID = parseInt(local.data_company.id_user_type);
		if(userTypeID === 6){
			this.router.navigateByUrl("warehouse/config-webhook");
		}
		else if(userTypeID === 7){
			this.router.navigateByUrl("vivipost/config-webhook");
		}
		else if(userTypeID === 9){
			this.router.navigateByUrl("superadmin/config-webhook");
		}
		else if(userTypeID === 10){
			this.router.navigateByUrl("carriers/config-webhook");
		}


	}
	openToSeeJson(event){
		const dialogRef = this.dialog.open(DialogSeeJsonWebhookComponent,{
			data:{
				data:event
			}
		});
	}
}
