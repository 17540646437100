// * Angular
import { Component, Input, OnInit } from '@angular/core';
import { CardData, KpiSummary } from 'src/app/core/models';
// * Services
import { DataService } from 'src/app/core/services/data.service';

@Component({
  selector: 'kpi-clients',
  templateUrl: './kpi-clients.component.html',
  styleUrls: ['./kpi-clients.component.scss']
})
export class KpiClientsComponent  {
	@Input() dataCardSm: CardData;
	@Input() dataCardSummary: KpiSummary[] = [];

	constructor( public dataService : DataService ) {}
}

