<div class="row">
    <div class="col-11">
        <label class="header-user-detail">
            {{'orderDetail.title-orderDetail' |translate}}
        </label>
    </div>
    <div class="col-1" style="text-align: end; cursor: pointer;" (click)="closeModal()">
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="#212121" class="bi bi-x-lg" viewBox="0 0 16 16">
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
        </svg>
    </div>
    <div class="col-12">
        <hr style="color: #e5e5e5;opacity: 100;margin: 12px 0px;">
    </div>
    <div class="col-6" style="height: 800px;">
        <div class="header-informacion-general">
            <div class="header-informacion-general-text">
                {{'orderDetail.subtitle-informationGeneral' |translate}}
            </div>
        </div>
        <div class="table-informacion-general">
            <div class="row">
                <div class="col-12" style="border-bottom: 1px solid #efefef;">
                    <div class="field">
                        <div class="field-txt">
                            {{'orderDetail.informationGeneral.trackingNumber' |translate}}
                        </div>
                        <div class="field-value">
                            {{this.orderData.tracking}}
                        </div>
                    </div>
                </div>
				<div class="col-12" style="border-bottom: 1px solid #efefef;">
                    <div class="field">
                        <div class="field-txt">
                            {{'orderDetail.informationGeneral.lockerID'|translate}}
                        </div>
                        <div class="field-value">
                            {{this.orderData.lockerID}}
                        </div>
                    </div>
                </div>
                <div class="col-12" style="border-bottom: 1px solid #efefef;">
                    <div class="field">
                        <div class="field-txt">
                            {{'orderDetail.informationGeneral.emailClient' |translate}}
                        </div>
                        <div class="field-value">
                            {{this.orderData.email}}
                        </div>
                    </div>
                </div>
                <div class="col-12" style="border-bottom: 1px solid #efefef;">
                    <div class="field">
                        <div class="field-txt">
                            {{'orderDetail.informationGeneral.phoneClient' |translate}}
                        </div>
                        <div class="field-value">
                            {{this.orderData.phone}}
                        </div>
                    </div>
                </div>
                <div class="col-12" style="border-bottom: 1px solid #efefef;">
                    <div class="field">
                        <div class="field-txt">
                            {{'orderDetail.informationGeneral.numberOfDoor' |translate}}
                        </div>
                        <div class="field-value">
                            {{this.orderData.compartment}}
                        </div>
                    </div>
                </div>
				<div class="col-12" style="border-bottom: 1px solid #efefef;">
                    <div class="field">
                        <div class="field-txt">
                            {{'orderDetail.informationGeneral.deliverDate' |translate}}
                        </div>
                        <div class="field-value">
                            ---
                        </div>
                    </div>
                </div>
				<div class="col-12" style="border-bottom: 1px solid #efefef;">
                    <div class="field">
                        <div class="field-txt">
                            {{'orderDetail.informationGeneral.orderStatus' |translate}}
                        </div>
                        <div class="field-value">
                            <app-estatus-order  [orderData]="this.orderData">
                            </app-estatus-order>
                        </div>
                    </div>
                </div>
				<div class="col-12" style="border-bottom: 1px solid #efefef;">
                    <div class="field">
                        <div class="field-txt">
                            {{'orderDetail.informationGeneral.nameEmployee' |translate}}
                        </div>
                        <div class="field-value">
                            {{this.orderData.nameEmploye}}
                        </div>
                    </div>
                </div>
                <div class="col-12" style="border-bottom: 1px solid #efefef;">
                    <div class="field">
                        <div class="field-txt">
                            {{'orderDetail.informationGeneral.typeUser' |translate}}
                        </div>
                        <div class="field-value">
                            ---
                        </div>
                    </div>
                </div>
                <div class="col-12" style="border-bottom: 1px solid #efefef;">
                    <div class="field">
                        <div class="field-txt">
                            {{'orderDetail.informationGeneral.expirationDate' |translate}}
                        </div>
                        <div class="field-value">
                            ---
                        </div>
                    </div>
                </div>
                <div class="col-12" style="border-bottom: 1px solid #efefef;">
                    <div class="field">
                        <div class="field-txt">
                            {{'orderDetail.informationGeneral.removedLocker' |translate}}
                        </div>
                        <div class="field-value">
                            ---
                        </div>
                    </div>
                </div>
                <div class="col-12" style="border-bottom: 1px solid #efefef;">
                    <div class="field">
                        <div class="field-txt">
                            {{'orderDetail.informationGeneral.expirationDate' |translate}}
                        </div>
                        <div class="field-value">
                            ---
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="field">
                        <div class="field-txt">

							{{'orderDetail.informationGeneral.qualificationClient' |translate}}
                        </div>
                        <div class="field-value">
                            <app-star-rating [rating]="this.orderData.rate_start">

                            </app-star-rating>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-6" style="height: 800px;" *ngIf="this.id_status_code_package !== 18 && this.status_package !== 16">
        <div class="informacion-qr">
            <div class="header-informacion-qr">
                <div class="header-informacion-qr-text">
                    {{'orderDetail.subtitle-accessQR' |translate}}
                </div>
                <a href="{{this.orderData.qr}}" target="_blank" style="color: white; text-decoration: none;">
					<app-button-icon icon="{{iconBtnQR}}" btnType="primaryMedium" btnNombre="orderDetail.qrAccess.btnDownload" [activo]="!url_view_image">
					</app-button-icon>
                </a>
            </div>
			<div class="row" style="width: 100%; height: 283px;">
                <div class="col-12" style="text-align: center;">
                    <img [src]="this.orderData.qr" (error)="url_view_image=false" *ngIf="url_view_image" [width]="283" height="auto" alt="imagen-order-detail">
					<ng-container *ngIf="!url_view_image">
						<div class="row">
							<div class="col-12" style="display: flex;justify-content: center;">
								<div class="container-image">
									<div class="container-image-panding">
										<img src="../../../../assets/icons/icon-qr-dark.svg" style="margin-bottom:1rem" width="20%" alt="">
										<h2>{{'message.title_err_img_404' | translate }}</h2>
										<p>{{'message.err_img_404' | translate }}</p>
									</div>
								</div>
							</div>
						</div>
					</ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row" *ngIf="this.idUserType === 9">
    <div class="col-12" style="text-align: -webkit-right;">
		<app-button-icon (click)="openModalEliminarOrder()" [icon]="'icon-erase'" btnType="secondaryDangerMedium" btnNombre="orderDetail.eraseOrder" [activo]="false">
		</app-button-icon>
    </div>
</div>
