import { Component, Input } from '@angular/core';
import { CardData, KpiSummary } from 'src/app/core/models/index';

@Component({
	selector: 'app-card',
	templateUrl: './card.component.html',
	styleUrls: ['./card.component.scss'],
})
export class CardComponent {
	@Input() gridStyle: string = '';
	@Input() dataCardSm: CardData;
	@Input() dataCardSummary: KpiSummary[] = [];
	@Input() addComp: boolean;
	@Input() tooltip: string;

	showTooltip:boolean=false;
	idCard:number;
	ngOnInit() {
	}
	activateTooltip(i){
		this.idCard = i;
		this.showTooltip = true;
	}
	desactivateToolTip(i){
		this.idCard = null;
		this.showTooltip = false;
	}
}
