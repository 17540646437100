import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Calendar } from 'primeng/calendar';

@Component({
	selector: 'app-calendar-dashboard',
	templateUrl: './calendar.component.html',
	styleUrls: ['./calendar.component.scss']
})
export class CalendarDashboardComponent implements OnInit {
	selectedDates: Date[] = [];
	displayCalendar: boolean = true;
	@ViewChild('calendar', { static: true }) calendar: Calendar;
	@Output() datesSelected: EventEmitter<Date[]> = new EventEmitter<Date[]>();
	@Output() calendarOpened: EventEmitter<void> = new EventEmitter<void>();


	onSelect(event): void {
		this.displayCalendar = false;
		this.datesSelected.emit(this.selectedDates);
	}
	acceptSelection(): void {
		// Aquí puedes implementar la lógica para aceptar la selección, por ejemplo, cerrar el calendario
		this.calendar.overlayVisible = false;
    }
	cancelSelection(): void {
		this.calendar.overlayVisible = false;
	}
    clearSelection(): void {

			const currentDate = new Date(); // Obtén la fecha actual
			const lastMonthDate = new Date();
			const twoMonthsAgoDate = new Date();
			const threeMonthsAgoDate = new Date();
			lastMonthDate.setMonth(lastMonthDate.getMonth() - 1);
			twoMonthsAgoDate.setMonth(twoMonthsAgoDate.getMonth() - 2);
			threeMonthsAgoDate.setMonth(threeMonthsAgoDate.getMonth() - 3);
			this.selectedDates = [threeMonthsAgoDate, currentDate];
			this.datesSelected.emit(this.selectedDates);

    }
	ngOnInit(): void {

			const currentDate = new Date(); // Obtén la fecha actual
			const lastMonthDate = new Date();
			const twoMonthsAgoDate = new Date();
			const threeMonthsAgoDate = new Date();
			lastMonthDate.setMonth(lastMonthDate.getMonth() - 1);
			twoMonthsAgoDate.setMonth(twoMonthsAgoDate.getMonth() - 2);
			threeMonthsAgoDate.setMonth(threeMonthsAgoDate.getMonth() - 3);
			this.selectedDates = [threeMonthsAgoDate, currentDate];
			this.datesSelected.emit(this.selectedDates);

	}
	showCalendar() {
		this.calendarOpened.emit();
		this.calendar.overlayVisible = true;
	}
}
