<div class="input-container">
    <input #inputText
        [disabled]="disabled"
        [(ngModel)]="filterText"
		maxlength="30"
        (input)="onInput($event)"
        type="text"
        class="search"
        [placeholder]="'lockerPage.input-search' | translate"
		appAutofocus>
    <div class="icon-search"></div>
</div>
