<button class="btn-export" [disabled]="isDisabled" type="button">
    <div class="icon-export">
        <span class="margin-start-icon">
            {{ 'buttons.export' | translate }}.xls
        </span>
    </div>
</button>
<!-- {{data1 | json}} -->
<button type="button" class="btn-export-sm">
    <div class="icon-export-sm">
    </div>
</button>
