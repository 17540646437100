import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import {
	MAT_DIALOG_DATA,
	MatDialog,
	MatDialogRef,
} from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AdminClientService } from 'src/app/core/http/admin-client.service';
import { DialogRecordatoriosComponent } from '../dialog-recordatorios/dialog-recordatorios.component';

@Component({
	selector: 'app-dialog-deliver-personally',
	templateUrl: './dialog-deliver-personally.component.html',
	styleUrls: ['./dialog-deliver-personally.component.scss'],
})
export class DialogDeliverPersonallyComponent {
	orderData: any;
	viewUserInformation = true;
	viewQrInformation = false;
	editOrderInformation = false;

	dateNotify: string = '';
	timeNotify: string = '';

	constructor(
		private dialogRef: MatDialogRef<DialogDeliverPersonallyComponent>,
		@Inject(MAT_DIALOG_DATA) private data: any,
		private dialog: MatDialog,
		private datePipe: DatePipe,
		private adminService: AdminClientService,
		private toastr: ToastrService
	) {
		this.orderData = this.data;
	}

	ngOnInit(): void {}

	closeModal() {
		this.dialogRef.close();
	}

	ViewUserInformation() {
		this.viewUserInformation = true;
		this.viewQrInformation = false;
	}
	ViewQrInformation() {
		this.viewUserInformation = false;
		this.viewQrInformation = true;
	}

	EditOrderInformation() {
		this.editOrderInformation = true;
	}
	cancelarEditOrder() {
		this.editOrderInformation = false;
	}

	changeTimeNotify(e: any) {
		this.timeNotify = e.target.value;
	}
	changeDateNotify(e: any) {
		this.dateNotify = this.datePipe.transform(
			e.target.value,
			'yyyy-dd-MM HH:mm'
		);
	}

	async savePersonalDelivery() {
		const body = {
			leng: localStorage.getItem('language').toLowerCase(),
			id_package: this.data.id_package,
			id_company: Number(localStorage.getItem('id_company')),
			date: this.dateNotify,
		};
		const res = await this.adminService.savePersonalDelivery(body);
		if (res['mensaje_return'].ERROR) {
			// this.notifySendError = true;
			// this.notifySend = false;
		} else {
			// this.notifySend = true;
			// this.notifySendError = false;
			this.toastr.success('Se a guardado correctamente', 'Guardado', {
				toastClass: 'toast-extendida',
				timeOut: 5000,
				positionClass: 'toast-bottom-center',
			});
		}
	}
	// openModalRecordatorios(){
	// 	let dialogRef = this.dialog.open(DialogRecordatoriosComponent,{width: '700px', height: '475px',
	// 		data : this.orderData
	// 	})
	// }
}
