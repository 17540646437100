<!-- ? GRID CARD -->
<div class="grid_card">
    <!-- ? CARD -->
    <div class="card_cont" *ngFor="let card of dataCard; let i = index">
        <div [ngClass]="{'fadeAnimationIn'	: dataService.overviewChecked,
                        'fadeAnimationOut'	: !dataService.overviewChecked }">
            <div class="card_display">
                <!-- ? HEADER -->
                <div class="header_">
                    <!-- TXT HEADER -->
                    <p class="body_b1 color_primary font_medium white-space"> {{card.header | translate }} </p>
                    <!-- ? TOOLTIP CONTAINER -->
                    <div class="tooltip_cont_">
                        <img src="../../../../../../assets/icons/icon-info.svg" class="icon_tooltip">
                
                        <!-- TOOLTIP -->
                        <div class="tooltip_">
                            <p class="caption_c2 color_primary_400 font_medium">
                                {{ card.toolTip | translate }}
                            </p>
                            <!-- PIKE -->
                            <div class="pike_2"></div>
                        </div>
                        <!-- PIKE -->
                        <div class="pike"></div>
                    </div>
                
                </div>
                <!-- ? BODY FOOTER CONTAINER -->
                <div class="footer_">
                    <!-- ? BODY -->
                    <p class="headlines_bh3 font_semibold color_primary">{{card.data }}</p>

                </div>
            </div>

        </div>

    </div>

</div>