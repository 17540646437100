import { Component, Input, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-link-component',
  templateUrl: './link-component.component.html',
  styleUrls: ['./link-component.component.scss']
})
export class LinkComponentComponent {
	public ts = inject( TranslateService );
	public router = inject( Router );
	@Input() text:string="";
	@Input() url:string="";
	@Input() linkType:string="";

	redirectToPage(){
		if(this.linkType !== 'disabled'){
			this.router.navigateByUrl(''+this.url)
		}
	}
}
