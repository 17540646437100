<div class="container-switch">
	<div *ngFor="let item of content" (click)="selectContent(item)">
		<div [ngClass]="{'content-selected': item.selected === true, 'content': item.selected === false}">
			<div class="text">
				{{ item.text | translate }}
			</div>
			<div class="number-box" *ngIf="item.showquantity === true && item.quantity > 0">
				<div class="text">{{ item.quantity }}</div>
			</div>
		</div>
	</div>
</div>
