import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
	selector: 'app-date-text',
	templateUrl: './date-text.component.html',
	styleUrls: ['./date-text.component.scss'],
})
export class DateTextComponent implements ICellRendererAngularComp {
	fecha: string;
	isBadDate: boolean = false;

	constructor() {}
	agInit(params: ICellRendererParams<any, any>): void {

		if (params.value !== null) {
			// let date = new Date(params.value);
			// this.fecha = date.toString();
			this.fecha=params.value;
			this.isBadDate = false;
		} else {
			this.fecha = null;
		}
	}
	refresh(params: ICellRendererParams<any, any>): boolean {
		return false;
	}

	ngOnInit(): void {}
}
