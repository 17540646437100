import { Component, Inject, OnInit } from '@angular/core';
import {
	MAT_DIALOG_DATA,
	MatDialog,
	MatDialogRef,
} from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AdminClientService } from 'src/app/core/http/admin-client.service';
import { ScheduledTableComponent } from '../orders-components/scheduled-table/scheduled-table.component';
import { DataSharingService } from 'src/app/core/services/data-sharing.service';

@Component({
	selector: 'app-dialog-erase-order',
	templateUrl: './dialog-erase-order.component.html',
	styleUrls: ['./dialog-erase-order.component.scss'],
})
export class DialogEraseOrderComponent implements OnInit {
	executeGetTableCompleted = false;
	constructor(
		private toastr: ToastrService,
		private adminService: AdminClientService,
		private dialogRef: MatDialogRef<DialogEraseOrderComponent>,
		@Inject(MAT_DIALOG_DATA) private data: any,
		private dialog: MatDialog,
		private dataSharingService: DataSharingService
	) {}
	ngOnInit(): void {}
	async deletePackageSchedule() {
		let deleteOrder = {
			language: localStorage.getItem('language').toLowerCase(),
			id_service: this.data.id_service,
		};
		this.adminService.deleteSchedulePackage(deleteOrder).then((res:any)=>{
			if (res.mensaje_return.CODE === 200) {
				this.toastr.success(
					'La operación ha sido realizada con éxito.',
					'Orden Agendada Eliminada',
					{
						toastClass: 'toast-extendida',
						timeOut: 5000,
						positionClass: 'toast-bottom-center',
					}
				);
				this.dataSharingService.triggerUpdateTable();
				this.dialog.closeAll();
			}
		}).catch(err=>{
			this.toastr.error(
				'La operación no ha sido realizada con éxito.',
				'Título de la notificación',
				{
					toastClass: 'toast-error',
					timeOut: 5000,
					positionClass: 'toast-bottom-center',
				}
			);
		});
	}
	closeModal() {
		this.dialogRef.close();
	}
}
