<div class="container-main">
    <div class="header-container">
        <span>
            {{'navbar.account' | translate | titlecase }}
        </span>
    </div>
	<!--
    <div class="block-2-container">
        <sidebar-settings [sideBarSettings]="sidebarSettingsProfileAdmin"> </sidebar-settings>
        <router-outlet></router-outlet>
    </div>
	-->
	<div class="accountBox">
		<div (click)="personalOnClick()" [ngClass]="{
			'boxSelected'	: this.personal === true,
			'box' 			: this.personal === false
		}">
			<div class="text">
				Inf. personal
			</div>
		</div>
		<div (click)="passwordOnClick()" [ngClass]="{
				'boxSelected'	: this.password === true,
				'box' 			: this.password === false
		}">
			<div class="text" >
				Contraseña
			</div>
		</div>
		<div (click)="preferencesOnClick()" [ngClass]="{
			'boxSelected'	: this.preferences === true,
			'box' 			: this.preferences === false
		}">
			<div class="text">
				Preferencias
			</div>
		</div>
	</div>

	<div class="row" *ngIf="this.personal === true">
		<div class="col-12" style="margin-top:24px; margin-bottom:70px">
			<div class="tabs_content animate__animated animate__fadeIn">
				<app-account-superadmin></app-account-superadmin>
			</div>
		</div>
	</div>
	<div class="row" *ngIf="this.password === true">
		<div class="col-12" style="margin-top:24px; margin-bottom:70px">
			<div class="tabs_content animate__animated animate__fadeIn">
				<app-password-superadmin></app-password-superadmin>
			</div>
		</div>
	</div>
	<div class="row" *ngIf="this.preferences === true">
		<div class="col-12" style="margin-top:24px; margin-bottom:70px">
			<div class="tabs_content animate__animated animate__fadeIn">
				<app-preferences-superadmin></app-preferences-superadmin>
			</div>
		</div>
	</div>



</div>
