import { Component, Input, OnInit, Output,EventEmitter } from '@angular/core';
import { Route, Router, RouterLink } from '@angular/router';
import { url } from 'inspector';
import { FilterService } from 'src/app/core/http/FilterDataServiceOxxoToOxxo.service';

@Component({
	selector: 'app-filter-orders',
	templateUrl: './filter-orders.component.html',
	styleUrls: ['./filter-orders.component.scss']
})
export class FilterOrdersComponent implements OnInit {
	@Input() filters:boolean=false;
	checkboxOne						: boolean = false;
	checkboxTwo						: boolean = false;
	checkboxThree 					: boolean = false;
	checkboxFour					: boolean = false;
	checkboxFive					: boolean = false;
	arrayFilterStars: Set<number> = new Set<number>();
	urlString;
	partsURL = [];
	urlActual: string;

	constructor(private filterService: FilterService,private router: Router) {
		this.urlString = router.url;
		this.partsURL = this.urlString.split('/');
		this.urlActual = this.partsURL[2];
	}
	ngOnInit(): void {
	}
	actualizarStarsEnArreglo(valor: number, isChecked: boolean) {
		if (isChecked) {
			this.arrayFilterStars.add(valor);
		} else {
			this.arrayFilterStars.delete(valor);
		}
		this.filterService.updateStarsFilter(this.arrayFilterStars);
	}
	validarStars(){
		this.actualizarStarsEnArreglo(1, this.checkboxOne);
		this.actualizarStarsEnArreglo(2, this.checkboxTwo);
		this.actualizarStarsEnArreglo(3, this.checkboxThree);
		this.actualizarStarsEnArreglo(4, this.checkboxFour);
		this.actualizarStarsEnArreglo(5, this.checkboxFive);
	}
	openRate:boolean=false;
	openRateDiv(){
		this.openRate = !this.openRate;
	}
	closeModal(){
		this.filters = false
	}
}
