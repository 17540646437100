import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'input-search',
	templateUrl: './input-search.component.html',
	styleUrls: ['./input-search.component.scss']
})
export class InputSearchComponent implements OnInit, AfterViewInit {

	@Input() filterText: string;
	@Input() disabled:boolean = false;
	@Output() filterTextChanged = new EventEmitter<string>();
	@ViewChild('inputText') inputText: ElementRef<HTMLInputElement>;
	onFilterTextChange(event: Event) {
		const value = (event.target as HTMLInputElement).value;

		this.filterTextChanged.emit(value);
	}
	onInput(event: Event) {
		const value = (event.target as HTMLInputElement).value;
		this.filterTextChanged.emit(value);
	}
	constructor(public translate: TranslateService,private renderer: Renderer2){

	}
	ngAfterViewInit() {
		this.inputText.nativeElement.focus();
		// Focus the input element again on any click event (to ensure continuous focus)
		this.renderer.listen(this.inputText.nativeElement, 'click', () => {
			this.inputText.nativeElement.focus();
		});
	}
	ngOnInit(): void {

	}
}
