import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LockerGridServiceService {
	private updateLockerGridSubject = new Subject<void>();

	updateLockerGrid$ = this.updateLockerGridSubject.asObservable();

	updateLockerGrid() {
		this.updateLockerGridSubject.next();
	}

	updateTableGeneralOxxoToOxxo(assignedResult:any){
		this.updateLockerGridSubject.next(assignedResult);
	}
}
