import { Component, OnInit } from '@angular/core';
import {
	ApexChart,
	ApexDataLabels,
	ApexFill,
	ApexNonAxisChartSeries,
	ApexPlotOptions,
	ApexStroke,
} from 'ng-apexcharts';
import { StarRatingColor } from 'src/app/shared/components/star-rating/star-rating.component';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { ClientSign } from '../dialogs-admin/client-dialog';
import { AdminClientService } from '../../../../core/http/admin-client.service';
import { OrderCreateComponent } from '../dialogs-admin/order-create/order-create.component';

export type ChartOptions = {
	series: ApexNonAxisChartSeries;
	chart: ApexChart;
	labels: string[];
	plotOptions: ApexPlotOptions;
	dataLabels: ApexDataLabels;
	fill: ApexFill;
	stroke: ApexStroke;
};

@Component({
	selector: 'app-order-admin',
	templateUrl: './order-admin.component.html',
	styleUrls: ['./order-admin.component.scss'],
})
export class OrderAdminComponent implements OnInit {
	public chartOptions: Partial<ChartOptions>;

	rating;
	starCount: number = 5;
	starColor: StarRatingColor = StarRatingColor.accent;
	starColorP: StarRatingColor = StarRatingColor.primary;
	starColorW: StarRatingColor = StarRatingColor.warn;

	consultorTabCompleted = {};

	months: any = [];
	dataChart: any;
	dataSeries = '';
	responseQuantityCarrierName = [];
	responseQuantityCarrierY = [];
	lang;
	responseQuantityLockerName = [];
	responseQuantityLockerY = [];

	scheduled = 0;

	idUserCompany;
	data;
	scheduledDataTable = [];
	scheduledData;

	pendingData;
	generalData;
	refundData;
	expiredData;
	refundDataTable = [];
	generalDataTable = [];
	dataTable = [];
	pendingTable = [];
	expiredTable = [];
	columns;
	columnsGeneral;
	columns1;
	columns2;
	columns3;
	scheduledColumn;
	res;
	total;
	today = 0;
	totalYesterday = 0;
	rate: any = 0;
	pending = 0;
	pendingYesterday = 0;
	pendingRate: number | string = 0;
	mostUsed: any[] = [];
	day: Date;
	local;
	consumerCount;
	mostActiveLockers;
	orderMonths = [];
	chart;
	month;
	list = [];
	pend = 0;
	refund = 0;
	all = 0;
	exp = 0;
	comple = 0;
	deliveries: any = {};
	general: any = {};
	adate = new Date(); // Fecha Actual
	fdate = new Date(this.adate.setMonth(this.adate.getMonth() - 12));
	sdate = new Date(); // Fecha actual menos un mes

	maxDate = new Date();
	sizes = '';
	consumerOrders;
	total_unique_costumers;
	total_locker_usage;
	returning;
	package_average_collection;
	datos;
	selectedTabIndex;
	package_rating_total = 0;
	consumerMetrics = [
		{
			package_average_collection: '0%',
			package_rating_total: 0,
			package_avg_rating: 0,
			package_delivery: 0,
			package_pickup: 0,
			package_recollected: 0,
			package_today: 0,
			package_today_pickup: 0,
		},
	];

	minutesBefore = 5;
	minutesAfter = 5;
	date_record;
	date_record_init;
	date_record_end;

	//Nuevo Componente
	rangeDates: Date[];
	addEventRangeDates() {
		if (this.rangeDates[1] !== null) {
			let fechas = this.rangeDates[0] + '*' + this.rangeDates[1];
			this.getMensaje(fechas);
		}
	}

	selectMonth;
	selectMonthEvent() {
		let fecha = new Date(this.selectMonth);
		let dateInicio;
		let dateFin;
		if (fecha.getMonth() < 10) {
			const firstDayOfMonth = new Date(
				fecha.getFullYear(),
				fecha.getMonth(),
				1
			).getDate();
			const lastDayOfMonth = new Date(
				fecha.getFullYear(),
				fecha.getMonth(),
				0
			).getDate();
			dateInicio =
				'0' +
				(fecha.getMonth() + 1) +
				'/0' +
				firstDayOfMonth +
				'/' +
				fecha.getFullYear() +
				' 00:00:00';
			dateFin =
				'0' +
				(fecha.getMonth() + 1) +
				'/' +
				lastDayOfMonth +
				'/' +
				fecha.getFullYear() +
				' 23:59:59';
		} else {
			const firstDayOfMonth = new Date(
				fecha.getFullYear(),
				fecha.getMonth(),
				1
			).getDate();
			const lastDayOfMonth = new Date(
				fecha.getFullYear(),
				fecha.getMonth(),
				0
			).getDate();
			dateInicio =
				fecha.getMonth() +
				1 +
				'/0' +
				firstDayOfMonth +
				'/' +
				fecha.getFullYear() +
				' 00:00:00';
			dateFin =
				fecha.getMonth() +
				1 +
				'/' +
				lastDayOfMonth +
				'/' +
				fecha.getFullYear() +
				' 23:59:59';
		}

		let fechas = dateInicio + '*' + dateFin;
		this.getMensaje(fechas);
	}

	createPackageSchedule() {
		this.translate
			.get(['orden.title', 'buttons.create_orden'])
			.subscribe((t: any) => {
				let dialogRef = this.dialog.open(OrderCreateComponent, {
					width: '80%',
					height: '90%',
					data: {
						title: t['orden.title'],
						text_button: t['buttons.create_orden'],
						edit_package: false,
					},
				});
				dialogRef.componentInstance.update.subscribe((data: any) => {
					this.updateEvent('true');
				});
			});
	}

	constructor(
		private adminService: AdminClientService,
		private translate: TranslateService,
		private dialog: MatDialog
	) {}

	gOnDestroy() {
		this.dialog.closeAll();
	}

	getMensaje(e) {
		this.fdate = e.split('*')[0];
		this.sdate = e.split('*')[1];
		this.getData();
	}

	onRatingChanged(rating) {
		this.rating = rating;
	}

	ngOnInit() {
		this.getText();
		const languageList =
			localStorage.getItem('language') === 'EN'
				? [
						{ id: 1, month: 'January' },
						{ id: 2, month: 'February' },
						{ id: 3, month: 'March' },
						{ id: 4, month: 'April' },
						{ id: 5, month: 'May' },
						{ id: 6, month: 'June' },
						{ id: 7, month: 'July' },
						{ id: 8, month: 'August' },
						{ id: 9, month: 'September' },
						{ id: 10, month: 'October' },
						{ id: 11, month: 'November' },
						{ id: 12, month: 'December' },
				  ]
				: [
						{ id: 1, month: 'Enero' },
						{ id: 2, month: 'Febrero' },
						{ id: 3, month: 'Marzo' },
						{ id: 4, month: 'Abril' },
						{ id: 5, month: 'Mayo' },
						{ id: 6, month: 'Junio' },
						{ id: 7, month: 'Julio' },
						{ id: 8, month: 'Agosto' },
						{ id: 9, month: 'Septiembre' },
						{ id: 10, month: 'Octubre' },
						{ id: 11, month: 'Noviembre' },
						{ id: 12, month: 'Diciembre' },
				  ];
		this.list = languageList;

		this.local = JSON.parse(localStorage.getItem('data'));
		this.getConsumerCount();
		this.getData();
		this.getSchedulePackage();
		this.day = new Date();
		this.day.setMonth(this.day.getMonth() - 12);
		this.columns = [
			'tracking',
			'lockerID',
			'email',
			'employeId',
			'nameEmploye',
			'inLocker',
			'packagePickupDate',
			'rate_start',
			'actions_order',
		];
		this.columnsGeneral = [
			'order',
			'tracking',
			'lockerID',
			'email',
			'employeId',
			'nameEmploye',
			'doors',
			'compartment',
			'status',
			'inLocker',
			'packagePickupDate',
			'rate_start',
			'evidence',
		];
		this.columns1 = [
			'order',
			'tracking',
			'lockerID',
			'email',
			'employeId',
			'nameEmploye',
			'doors',
			'compartment',
			'inLocker',
			'actions',
		];
		this.columns2 = [
			'order',
			'tracking',
			'lockerID',
			'email',
			'employeId',
			'nameEmploye',
			'doors',
			'compartment',
			'status',
			'inLocker',
			'registrationDateExpired',
		];
		this.columns3 = [
			'order',
			'tracking',
			'lockerID',
			'email',
			'employeId',
			'nameEmploye',
			'doors',
			'compartment',
			'inLocker',
			'registrationDateExpired',
			'registrationDateRefund',
		];
		this.scheduledColumn = [
			'tracking',
			'lockerID',
			'email',
			'phone',
			'nameEmploye',
			'compartment',
			'status',
			'registrationDate',
			'actions',
		];

		this.getOrdersMetrics();
		this.loadchartspercent();
		/*Mockcup data cards Orders */
		this.datos = [
			{
				average_time_taken: '50:02',
				average_collection_time: '55:01',
			},
			{
				percentupunique: 3.45,
				percentlockerusage: 3.54,
				percentorderreturn: 2.34,
				percentorderdeliver: 2.49,
			},
		];
		/* FIn Mockcup data cards Orders */
	}

	openHelp() {
		this.translate
			.get(['video_tutorial.title_schedule', 'buttons.cancel'])
			.subscribe((t: any) => {
				let dialogRef = this.dialog.open(ClientSign, {
					width: '80%',
					height: '80%',
					data: {
						title: t['video_tutorial.title_schedule'],
						text_button: t['buttons.cancel'],
						type: 'video',
					},
				});

			});
	}

	onTabChanged(event) {
		//Desde aqui se debe hacer para activar los tabuladores y dejarlos activados
	}

	async getText() {
		this.deliveries = await this.translate.get('deliveries').toPromise();
		this.general = await this.translate.get('general').toPromise();
	}

	updateEvent(event: string) {
		if (event == 'true') {
			this.getSchedulePackage();
		}
	}

	async getSchedulePackage() {
		let data = {
			language: localStorage.getItem('language'),
		};
		this.scheduledDataTable = [];
		this.scheduled = 0;

		let res: any = await this.adminService.getSchedulePackage(data);
		if (res.mensaje_return && !res.mensaje_return.ERROR) {
			if (res.return_result.length > 0) {
				res.return_result.forEach((p: any) => {
					this.scheduled += p.id_status == 35 ? 1 : 0;
					this.scheduledDataTable.push(this.createScheduledData(p));
				});
				this.scheduledDataTable.sort((a, b) => a.order - b.order);
				this.scheduledData = JSON.stringify(this.scheduledDataTable);
			}
		}
	}

	createScheduledData(p: any) {
		const compartment =
			p.door_number != 'not_select'
				? `${p.door_number} - ${p.compartment_name}`
				: p.door_number;
		const actions = p.id_status == 35 ? ['far fa-trash-alt'] : [];
		const qr = p.id_status == 35 ? p.qr : null;

		return {
			id_service: p.id_service,
			id_compartment_dimension: p.id_compartment_dimension,
			isScheduleData: true,
			id_status_service: p.id_status,
			order: p.code_qr_service.split('.')[0],
			tracking: p.code_service,
			lockerID: p.locker_name,
			email: p.email,
			phone: p.main_tlf,
			nameEmploye: p.nameEmploye,
			compartment,
			status: p.nombre_status,
			registrationDate: p.registration_date,
			id_locker: p.id_locker,
			actions,
			qr,
		};
	}
	async getData() {
		this.months = [];
		this.lang = localStorage.getItem('language');
		let dataChartEN = [
			{ id_mes: 1, mes_package: 'January', orders: 0 },
			{ id_mes: 2, mes_package: 'February', orders: 0 },
			{ id_mes: 3, mes_package: 'Marzo', orders: 0 },
			{ id_mes: 4, mes_package: 'April', orders: 0 },
			{ id_mes: 5, mes_package: 'May', orders: 0 },
			{ id_mes: 6, mes_package: 'June', orders: 0 },
			{ id_mes: 7, mes_package: 'July', orders: 0 },
			{ id_mes: 8, mes_package: 'August', orders: 0 },
			{ id_mes: 9, mes_package: 'September', orders: 0 },
			{ id_mes: 10, mes_package: 'October', orders: 0 },
			{ id_mes: 11, mes_package: 'November', orders: 0 },
			{ id_mes: 12, mes_package: 'Diciembre', orders: 0 },
		];
		let dataChartES = [
			{ id_mes: 1, mes_package: 'Enero', orders: 0 },
			{ id_mes: 2, mes_package: 'Febrero', orders: 0 },
			{ id_mes: 3, mes_package: 'Marzo', orders: 0 },
			{ id_mes: 4, mes_package: 'Abril', orders: 0 },
			{ id_mes: 5, mes_package: 'Mayo', orders: 0 },
			{ id_mes: 6, mes_package: 'Junio', orders: 0 },
			{ id_mes: 7, mes_package: 'Julio', orders: 0 },
			{ id_mes: 8, mes_package: 'Agosto', orders: 0 },
			{ id_mes: 9, mes_package: 'Septiembre', orders: 0 },
			{ id_mes: 10, mes_package: 'Octubre', orders: 0 },
			{ id_mes: 11, mes_package: 'Noviembre', orders: 0 },
			{ id_mes: 12, mes_package: 'Diciembre', orders: 0 },
		];
		this.dataChart = this.lang === 'EN' ? dataChartEN : dataChartES;

		this.dataChart.forEach((element) => {
			this.months.push(element.mes_package);
		});

		this.months = this.months.join(',');
		let temp: any[] = [];

		let data = {
			language: localStorage.getItem('language').toLowerCase(),
			id_company: localStorage.getItem('id_company'),
			date_start: Date.parse(this.fdate.toString()),
			date_end: Date.parse(this.sdate.toString()),
		};

		this.consultorTabCompleted = data;

		this.res = await this.adminService.getAllOrders(data);
		this.adminService.getAllOrders(data).then(res=>{
			this.res = res;
		});

		this.orderMonths = [];
		let d: Date;
		this.orderMonths = [];
		let s = 0;
		let m = 0;
		let ml = 0;
		let l = 0;
		let g = 0;
		let eg = 0;
		let xl = 0;
		let xxl = 0;
		let xxxl = 0;
		this.refundDataTable = [];
		this.generalDataTable = [];
		this.dataTable = [];
		this.pendingTable = [];
		this.expiredTable = [];
		this.res.forEach((o: any) => {
			if (o.compartment_name == 'M') m++;
			else if (o.compartment_name == 'S') s++;
			else if (o.compartment_name == 'G') g++;
			else if (o.compartment_name == 'EG') eg++;
			else if (o.compartment_name == 'M/L') ml++;
			else if (o.compartment_name == 'L') l++;
			else if (o.compartment_name == 'XL') xl++;
			else if (o.compartment_name == 'XXL') xxl++;
			else if (o.compartment_name == 'XXXL') xxxl++;
			d = new Date(o.package_delivery_date);
			this.dataChart.forEach((e) => {
				if (e.id_mes == d.getMonth() + 1) {
					e.orders++;
				}
			});

			this.date_record = new Date(o.package_delivery_date);
			this.date_record_init = this.date_record;
			this.date_record_end = this.date_record;
			let startdate = new Date(this.date_record);
			this.date_record_init = new Date(
				startdate.setMinutes(
					this.date_record.getMinutes() - this.minutesBefore
				)
			).toLocaleTimeString();
			this.date_record_end = new Date(
				startdate.setMinutes(
					this.date_record.getMinutes() + this.minutesAfter
				)
			).toLocaleTimeString();

			let disp = o.name_status_package == 'ENTREGADO' ? true : false;
			this.generalDataTable.push({
				// Todos los paquetes
				isScheduleData: false,
				order: o.order_number,
				lockerID: o.locker_name,
				email: o.email,
				tracking: o.tracking_number,
				doors: o.door_number,
				compartment: o.compartment_name,
				status: o.name_status_package,
				inLocker: o.package_delivery_date,
				pickedUp: disp,
				signature: disp,
				//signImg                    : o.IMAGE.url_view_imagen_signature,
				//pickImg                    : o.IMAGE.url_view_image_pickup,
				employeId: o.company_employee_id,
				nameEmploye: o.delivery_employee_name,
				id_package_code: o.id_package_code,
				id_status_code_package: o.id_status_code_package,
				status_package: o.status_package,
				delivery_employee_name: o.delivery_employee_name,
				packagePickupDate:
					o.status_package == 16 ? o.package_pickup_date : '',
				rate_start: o.rate_start ? o.rate_start : 0,
				evidence: [
					{
						url_view_imagen_signature:
							o.IMAGE.url_view_imagen_signature,
						url_view_image_pickup: o.IMAGE.url_view_image_pickup,
					},
				],
			});
			if (o.id_status_code_package == 18 && o.status_package == 16) {
				this.dataTable.push({
					isScheduleData: false,
					order: o.order_number,
					lockerID: o.locker_name,
					email: o.email,
					tracking: o.tracking_number,
					doors: o.door_number,
					compartment: o.compartment_name,
					status: o.name_status_package,
					inLocker: o.package_delivery_date,
					pickedUp: disp,
					//signature                  : disp,
					//signImg                    : o.IMAGE.url_view_imagen_signature,
					//pickImg                    : o.IMAGE.url_view_image_pickup,
					employeId: o.company_employee_id,
					nameEmploye: o.delivery_employee_name,
					id_package_code: o.id_package_code,
					id_status_code_package: o.id_status_code_package,
					status_package: o.status_package,
					delivery_employee_name: o.delivery_employee_name,
					packagePickupDate: o.package_pickup_date,
					rate_start: o.rate_start ? o.rate_start : 0,
					evidence: [
						{
							url_view_imagen_signature:
								o.IMAGE.url_view_imagen_signature,
							url_view_image_pickup:
								o.IMAGE.url_view_image_pickup,
						},
					],
					actions_order: '',
					dataPackage: [
						{
							item: 'name_status_package',
							value: o.name_status_package,
						},
						{
							item: 'rate_start',
							value: o.rate_start,
						},
						{
							item: 'tracking_number',
							value: o.tracking_number,
						},
						{
							item: 'order_number',
							value: o.order_number,
						},
						{
							item: 'email',
							value: o.email,
						},
						{
							item: 'phone_used',
							value: o.phone_used,
						},
						{
							item: 'locker_name',
							value: o.locker_name,
						},
						{
							item: 'compartment_name',
							value: o.compartment_name,
						},
						{
							item: 'door_number',
							value: o.door_number,
						},
						{
							item: 'compartment_size',
							value: o.compartment_size,
						},
						{
							item: 'delivery_company_employee_id',
							value: o.delivery_company_employee_id,
						},
						{
							item: 'delivery_employee_name',
							value: o.delivery_employee_name,
						},
						{
							item: 'package_delivery_date',
							value: o.package_delivery_date,
						},
						{
							item: 'package_pickup_date',
							value: o.package_pickup_date,
						},
						{
							item: 'expiration_date',
							value: o.expiration_date,
						},
					],
				});
			} else if (
				o.id_status_code_package == 17 &&
				o.status_package == 14
			) {
				this.pendingTable.push({
					isScheduleData: false,
					order: o.order_number,
					lockerID: o.locker_name,
					email: o.email,
					tracking: o.tracking_number,
					doors: o.door_number,
					compartment: o.compartment_name,
					status: o.name_status_package,
					inLocker: o.package_delivery_date,
					//pickedUp: disp,
					//signature: disp,
					signImg: o.IMAGE.url_view_imagen_signature,
					pickImg: o.IMAGE.url_view_image_pickup,
					employeId: o.company_employee_id,
					nameEmploye: o.delivery_employee_name,
					id_package_code: o.id_package_code,
					id_status_code_package: o.id_status_code_package,
					status_package: o.status_package,
					actions: ['far fa-edit'],
					delivery_employee_name: o.delivery_employee_name,
					qr_order: o.qr_order,
				});
			} else if (
				o.id_status_code_package == 32 &&
				o.status_package == 33
			) {
				this.refundDataTable.push({
					isScheduleData: false,
					order: o.order_number,
					lockerID: o.locker_name,
					email: o.email,
					tracking: o.tracking_number,
					doors: o.door_number,
					compartment: o.compartment_name,
					status: o.name_status_package,
					inLocker: o.package_delivery_date,
					pickedUp: disp,
					signature: disp,
					//signImg                  : o.IMAGE.url_view_imagen_signature,
					//pickImg                  : o.IMAGE.url_view_image_pickup,
					registrationDateExpired: o.registration_date_expired,
					registrationDateRefund: o.registration_date_refund,
					employeId: o.company_employee_id,
					nameEmploye: o.delivery_employee_name,
					id_package_code: o.id_package_code,
					id_status_code_package: o.id_status_code_package,
					status_package: o.status_package,
					//pickupCode               : Math.random()*100,
					//actions                   : ['far fa-edit'],
					delivery_employee_name: o.delivery_employee_name,
				});
			} else if (
				o.id_status_code_package == 32 &&
				o.status_package == 14
			) {
				this.expiredTable.push({
					isScheduleData: false,
					order: o.order_number,
					lockerID: o.locker_name,
					email: o.email,
					tracking: o.tracking_number,
					doors: o.door_number,
					compartment: o.compartment_name,
					status: o.name_status_package,
					inLocker: o.package_delivery_date,
					pickedUp: disp,
					signature: disp,
					registrationDateExpired: o.registration_date_expired,
					//signImg                  : o.IMAGE.url_view_imagen_signature,
					//pickImg                  : o.IMAGE.url_view_image_pickup,
					employeId: o.company_employee_id,
					nameEmploye: o.delivery_employee_name,
					id_package_code: o.id_package_code,
					id_status_code_package: o.id_status_code_package,
					status_package: o.status_package,
					//pickupCode               : Math.random()*100,
					//actions                  : ['far fa-edit'],
					delivery_employee_name: o.delivery_employee_name,
				});
			}
			temp.push(o);
		});

		this.all = this.generalDataTable.length;

		this.refund = this.refundDataTable.length;
		this.pend = this.pendingTable.length;
		this.exp = this.expiredTable.length;
		this.comple = this.dataTable.length;
		this.chart = JSON.stringify(this.dataChart);
		this.getDeliveredAndPending(temp);
		this.getMostUsedSize(temp);

		let sizing = [
			//{name: 'S', y: s},
			{ name: 'M', y: m },
			//{name: 'G', y: g},
			//{name: 'EG', y: eg},
			{ name: 'M/L', y: ml },
			{ name: 'L', y: l },
			{ name: 'XL', y: xl },
			{ name: 'XXL', y: xxl },
			{ name: 'XXXL', y: xxxl },
		];

		this.generalData = JSON.stringify(this.generalDataTable);
		this.refundData = JSON.stringify(this.refundDataTable);
		this.sizes = JSON.stringify(sizing);
	}

	async getConsumerCount() {
		let data = {
			language: localStorage.getItem('language'),
			id_company: this.local.data_company.id_company,
		};
		this.consumerCount = await this.adminService.getConsumerCompanyCount(
			data
		);
	}

	getDeliveredAndPending(order) {
		order.forEach((o) => {
			let day = new Date(o.package_delivery_date);
			let dMonth =
				day.getMonth().toString().split('').length == 1
					? '0' + (day.getMonth() + 1).toString()
					: (day.getMonth() + 1).toString();
			let d = `${day.getFullYear()}-${dMonth}-${(
				'0' + day.getDate()
			).slice(-2)}`; //* Día de orden parseado
			let date = new Date(); //*HOY
			let todayMonth =
				date.getMonth().toString().split('').length == 1
					? '0' + (date.getMonth() + 1).toString()
					: (date.getMonth() + 1).toString();

			let t = `${date.getFullYear()}-${todayMonth}-${(
				'0' + date.getDate()
			).slice(-2)}`; //* HOY Parseado
			let y = `${date.getFullYear()}-${todayMonth}-${(
				'0' +
				(date.getDate() - 1)
			).slice(-2)}`; //* AYER Parseado

			if (d == y) {
				this.totalYesterday++;
				if (o.status_package != 14) {
					this.pending++;
				}
			}
			if (d == t) {
				this.today++;
				if (o.status_package != 14) {
					this.pendingYesterday++;
				}
			}
		});
		if (this.pendingYesterday != 0) {
			this.pendingRate =
				((this.pending - this.pendingYesterday) /
					this.pendingYesterday) *
				100;
		} else if (this.pendingYesterday == 0 && this.pending > 0) {
			this.pendingRate = 100;
		}
		this.total = this.res.length;
		this.data = JSON.stringify(this.dataTable);
		this.pendingData = JSON.stringify(this.pendingTable);
		this.expiredData = JSON.stringify(this.expiredTable);
		if (this.totalYesterday != 0) {
			this.rate =
				((this.today - this.totalYesterday) / this.totalYesterday) *
				100;
			this.rate = parseFloat(this.rate).toFixed(2);
		} else if (this.totalYesterday == 0 && this.pending > 0) {
			this.rate = 100;
		}
		if (typeof this.pendingRate === 'number') {
			this.pendingRate =
				this.pendingRate >= 0
					? '+' + this.pendingRate.toString()
					: this.pendingRate;
		}
		this.rate = this.rate >= 0 ? '+' + this.rate.toString() : this.rate;
	}

	navigate() {}

	getMostUsedSize(order) {
		let sizes: any[] = [];
		let s = 0;
		let m = 0;
		let ml = 0;
		let l = 0;
		let g = 0;
		let eg = 0;
		let xl = 0;
		let xxl = 0;
		let xxxl = 0;
		order.forEach((o) => {
			switch (o.compartment_name) {
				case 'Chico':
				case 'S':
					s++;
					sizes[0] = { size: 'S', total: s };
					break;
				case 'M':
					m++;
					sizes[1] = { size: o.compartment_name, total: m };
					break;
				case 'M/L':
					ml++;
					sizes[1] = { size: o.compartment_name, total: ml };
					break;
				case 'L':
					l++;
					sizes[2] = { size: o.compartment_name, total: l };
					break;
				case 'G':
					g++;
					sizes[3] = { size: o.compartment_name, total: g };
					break;
				case 'EG':
					eg++;
					sizes[3] = { size: o.compartment_name, total: eg };
					break;
				case 'XL':
					xl++;
					sizes[3] = { size: o.compartment_name, total: xl };
					break;
				case 'XXL':
					xxl++;
					sizes[4] = { size: o.compartment_name, total: xxl };
					break;
				case 'XXXL':
					xxxl++;
					sizes[5] = { size: o.compartment_name, total: xxxl };
					break;
				default:
					break;
			}
		});
		sizes.sort((a, b) => {
			return b.total - a.total;
		});
		this.mostUsed = sizes;
	}

	async generalChart() {
		let data = {
			language: localStorage.getItem('language'),
			year: new Date().getFullYear(),
			id_company: this.local.data_company.id_company,
		};
		// this.mostActiveLockers = await this.adminService.getMostActiveLocker(
		// 	data
		// );
		am4core.useTheme(am4themes_animated);
		let chart = am4core.create('chartdiv', am4charts.XYChart);
		let length = 0;
		this.mostActiveLockers.forEach((e, l) => {
			length = l + 1;
			let cantidad = 0;
			e.MONTH.forEach((j, i) => {
				Object.defineProperty(this.list[i], 'name' + (l + 1), {
					value: e.locker_name,
					writable: true,
					configurable: true,
					enumerable: true,
				});
				cantidad = e.cant;
				if (this.list[i].id == j.IdMes) {
					Object.defineProperty(this.list[i], `cant${l + 1}`, {
						value: j.cant,
						writable: true,
						configurable: true,
						enumerable: true,
					});
					Object.defineProperty(this.list[i], `total${l + 1}`, {
						value: cantidad - j.cant,
						writable: true,
						configurable: true,
						enumerable: true,
					});
				}
			});
		});
		chart.data = this.list;
		let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
		categoryAxis.dataFields.category = 'month';
		categoryAxis.renderer.grid.template.location = 0;
		categoryAxis.renderer.labels.template.rotation = 10;
		categoryAxis.renderer.minGridDistance = 8;
		categoryAxis.renderer.cellStartLocation = 0.1;
		categoryAxis.renderer.cellEndLocation = 0.9;

		let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
		valueAxis.min = 0;
		for (let i = 1; i < length + 1; i++) {
			this.createSeries(
				`cant${i}`,
				this.list[i]['name' + i],
				false,
				chart,
				length
			);
			this.createSeries(`total${i}`, 'Total', true, chart, length);
		}
		this.list.forEach((e, i) => {});
		chart.legend = new am4charts.Legend();
		chart.responsive.rules.push({
			relevant: function (target) {
				if (target.pixelWidth <= 1000) {
					categoryAxis.renderer.labels.template.rotation = 77;
					categoryAxis.renderer.labels.template.dx = 20;
					categoryAxis.renderer.labels.template.dy = -5;
					return true;
				} else {
					categoryAxis.renderer.labels.template.rotation = 10;
					return false;
				}
			},
			state: function (target, stateId) {
				if (target instanceof am4charts.Chart) {
					let state = target.states.create(stateId);
					state.properties.paddingTop = 0;
					state.properties.paddingRight = 15;
					state.properties.paddingBottom = 5;
					state.properties.paddingLeft = 15;
					return state;
				}
				return null;
			},
		});
	}

	createSeries(field, name, stacked, chart: am4charts.XYChart, length) {
		let series = chart.series.push(new am4charts.ColumnSeries());
		series.dataFields.valueY = field;
		series.dataFields.categoryX = 'month';
		series.name = name;
		series.columns.template.tooltipText = '{name}: [bold]{valueY}[/]';
		series.stacked = stacked;
		series.columns.template.width = am4core.percent(100 / length - 5);
		series.columns.template.height = 10000;
		series.legendSettings.createMarker = true;
		if (stacked === true) {
			series.hiddenInLegend = true;
			series.fill = am4core.color('#dddddd');
		}
	}

	async getOrdersMetrics() {
		let data = {
			language: localStorage.getItem('language'),
			id_company: this.local.data_company.id_company,
		};
		this.consumerOrders = await this.adminService.getMetricsDash(data);
		this.consumerMetrics = this.consumerOrders;
		this.total_unique_costumers = this.consumerOrders[0].package_delivery;
		this.total_locker_usage = this.consumerOrders[0].package_recollected;
		this.returning = this.consumerOrders[0].package_today;
		this.package_average_collection = parseFloat(
			this.consumerOrders[0].package_average_collection
		).toFixed(1);
		this.rating = this.consumerOrders[0].package_avg_rating;
		this.package_rating_total = this.consumerOrders[0].package_rating_total;
	}

	loadchartspercent() {
		this.getOrdersMetrics();
		this.chartOptions = {
			series: [68.9],
			chart: {
				height: 190,
				width: 190,
				type: 'radialBar',
			},
			plotOptions: {
				radialBar: {
					startAngle: 0,
					endAngle: 360,
					hollow: {
						margin: 0,
						size: '65%',
						background: '#fff',
					},
					track: {
						background: '#EFEFFF',
						strokeWidth: '100%',
						margin: 0,
						dropShadow: {
							enabled: true,
							top: 0,
							left: 0,
							blur: 3,
							opacity: 0.2,
						},
					},
					dataLabels: {
						show: true,
						name: {
							offsetY: -20,
							show: false,
							color: '#888',
							fontSize: '17px',
						},
						value: {
							color: '#111',
							fontSize: '16px',
							fontFamily: 'Noto-Ikea-Regular',
							show: true,
							offsetY: 5,
						},
						//enabled:true,
					},
				},
			},
			fill: {
				colors: ['#0F5595'],
			},
			stroke: {
				lineCap: 'round',
			},
		};
	}
}
