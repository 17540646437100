import { Component, Input, OnInit } from '@angular/core';
import { FilterService } from 'src/app/core/http/FilterDataServiceOxxoToOxxo.service';

@Component({
  selector: 'app-filter-admin-all',
  templateUrl: './filter-admin-all.component.html',
  styleUrls: ['./filter-admin-all.component.scss']
})
export class FilterAdminAllComponent implements OnInit {
	@Input() filters:boolean=false;
	//IN PROGRESS
	checkboxCollectionAssigned 		:boolean = false;
	checkboxRouteToCedis 			:boolean = false;
	chechboxRouteToLocker 			:boolean = false;
	//PENDING
	checkboxLockerOrigen			:boolean = false;
	checkboxLockerDestino			:boolean = false;
	checkboxCedis 					:boolean = false;
	checkboxExpired 				:boolean = false;
	checkboxScheduled				:boolean = false;
	//COMPLETED
	checkboxCollected 				:boolean = false;
	checkboxConfiscated				:boolean = false;
	checkboxCollectedCedis 			:boolean = false;
	checkboxReturned				:boolean = false;
	//Warehouse Management
	checkboxReview 					:boolean = false;
	checkboxCollectedPending 		:boolean = false;
	checkboxCounter					:boolean = false;

	arrayFilterEstatus: Set<number> = new Set<number>();
	constructor(private filterService: FilterService) { }
	ngOnInit(): void {
	}
	actualizarValorEnArreglo(valor: number, isChecked: boolean) {
		if (isChecked) {
			this.arrayFilterEstatus.add(valor);
		} else {
			this.arrayFilterEstatus.delete(valor);
		}
		this.filterService.updateEstatusWarehouseGeneralFilter(this.arrayFilterEstatus);
	}
	validarCasillasSeleccionadas() {
		//EN PROGRESO
		this.actualizarValorEnArreglo((48),this.checkboxCollectionAssigned)
		this.actualizarValorEnArreglo((66),this.checkboxCollectionAssigned)
		this.actualizarValorEnArreglo((49),this.checkboxRouteToCedis)
		this.actualizarValorEnArreglo((60),this.checkboxRouteToCedis)
		this.actualizarValorEnArreglo((51),this.checkboxRouteToCedis)
		this.actualizarValorEnArreglo((53),this.chechboxRouteToLocker)
		//PENDIENTEs
		this.actualizarValorEnArreglo((47),this.checkboxLockerOrigen)
		this.actualizarValorEnArreglo((57),this.checkboxLockerDestino)
		this.actualizarValorEnArreglo((50),this.checkboxCedis)
		this.actualizarValorEnArreglo((59),this.checkboxExpired);
		this.actualizarValorEnArreglo((35),this.checkboxScheduled)
		//COMPLETADAS
		this.actualizarValorEnArreglo((56),this.checkboxConfiscated)
		this.actualizarValorEnArreglo((58),this.checkboxCollected)
		this.actualizarValorEnArreglo((61),this.checkboxCollected)
		this.actualizarValorEnArreglo((63),this.checkboxCollectedCedis)
		this.actualizarValorEnArreglo((68),this.checkboxReturned)
		//GESTION DE ALMACENES
		this.actualizarValorEnArreglo((54),this.checkboxReview);
		this.actualizarValorEnArreglo((67),this.checkboxCollectedPending);
		this.actualizarValorEnArreglo((55),this.checkboxCounter);
	}
	openStatus:boolean=false;
	openStatusDiv(){
		this.openStatus = !this.openStatus;
	}
	openInProgress:boolean=false;
	openPending:boolean=false;
	openCompleted:boolean=false;
	openWarehouseManagement:boolean=false;
	openStatusInProgress(){
		this.openInProgress = !this.openInProgress;
		this.openPending = false;
		this.openCompleted = false;
		this.openWarehouseManagement = false;
	}
	openStatusPending(){
		this.openInProgress = false;
		this.openPending = !this.openPending;
		this.openCompleted = false;
		this.openWarehouseManagement = false;
	}
	openStatusCompleted(){
		this.openInProgress = false;
		this.openPending = false;
		this.openCompleted = !this.openCompleted;
		this.openWarehouseManagement = false;
	}
	openStatusWarehouseManagement(){
		this.openInProgress = false;
		this.openPending = false;
		this.openCompleted = false;
		this.openWarehouseManagement = !this.openWarehouseManagement;
	}
	closeModal(){
		this.filters = false;
	}
}
