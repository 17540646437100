<div class="row">
    <div class="text-start" style="width: 70%;">
		<div class="input-container">
			<input type="text" class="input-gbl" (keyup)="onFilterTextChange($event)" placeholder="{{'search.orders' | translate}}"/>
			<button type="submit" class="btn-search">
				<div class="icon-search">
				</div>
			</button>
		</div>
    </div>
    <div class="text-end" style="width:30%; padding-left:0px">
        <!-- ? BTN EXPORT -->
		<button (click)="onExport()" [ngClass]="{
			'btn_primary' : buttonExport === true,
			'btn_primary_disabled' : buttonExport === false
		}">
			{{ 'buttons.export' | translate }}.xls
			<img [src]="'../../../../../../assets/icons/'+this.dataService.getIconBtnExport(this.buttonExport)+'.svg'">
		</button>
    </div>
</div>
<div class="row">
	<div class="col-12">
        <ag-grid-angular 	class="ag-theme-alpine"
							[columnDefs]="this.columnDefs"
							[gridOptions]="gridOptions"
							[rowData]="rowData"
							style="width: 100%; height: 750px;  margin-top:24px"
							(orderDeleted)="onOrderDeleted($event)"
							(gridReady)="onGridReady($event)">
        </ag-grid-angular>
	</div>
</div>
