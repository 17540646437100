import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, OnChanges, OnInit, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GoogleMapsService } from 'src/app/core/services/google-maps/google-maps.service';
import { StoresService } from 'src/app/core/services/stores.service';

@Component({
	selector: 'app-dialog-store-detail',
	templateUrl: './dialog-store-detail.component.html',
	styleUrls: ['./dialog-store-detail.component.scss'],
	encapsulation: ViewEncapsulation.Emulated,
})
export class DialogStoreDetailComponent implements OnInit, AfterViewInit, OnChanges {
	store:any;
	map: google.maps.Map;
	marker: google.maps.Marker;
	@ViewChild('mapContainer') mapContainer: ElementRef;
	center: google.maps.LatLngLiteral = { lat: 19.432608, lng: -99.133209 }; // Ciudad de México
	zoom = 12; // Nivel de zoom inicial
	editFields:boolean = false;
	cities = [];
	storesInformation: FormGroup;
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private dialogRef: MatDialogRef<DialogStoreDetailComponent>,
		private maps: GoogleMapsService,
		private cdr: ChangeDetectorRef,
		private formBuilder: FormBuilder,
		private storeService: StoresService
	){
		this.store = data;
		console.log(this.store);
		this.storesInformation = this.formBuilder.group({
			id_status	: [1, Validators.required],
			id_city		: [this.store.id_city, Validators.required], // Debe seleccionarse una ciudad
			id_company	: [this.store.id_company, Validators.required], // Asociado con la compañía
			store_name	: [this.store.store_name, [Validators.required, Validators.minLength(3), Validators.maxLength(100)]], // Entre 3 y 100 caracteres
			store_alias	: [this.store.store_alias, [Validators.required, Validators.minLength(3), Validators.maxLength(50)]], // Entre 3 y 50 caracteres
			address		: [this.store.address, [Validators.required, Validators.minLength(5), Validators.maxLength(255)]], // Entre 5 y 255 caracteres
			latitude	: [this.store.latitude, [Validators.required, Validators.pattern(/^(-?\d+(\.\d+)?),?\s*(-?\d+(\.\d+)?)$/)]], // Validación de formato de coordenadas
			longitude	: [this.store.longitude, [Validators.required, Validators.pattern(/^(-?\d+(\.\d+)?),?\s*(-?\d+(\.\d+)?)$/)]], // Validación de formato de coordenadas
			photo_name	: "imagen_fail.jpg",
			// photo_name: [null, [Validators.required]],
			codigo_referencia: [this.store.codigo_referencia, [Validators.required, Validators.pattern(/^[A-Z0-9]{6}$/)]], // Solo 6 caracteres alfanuméricos en mayúsculas
			opening_time: [this.store.opening_time, [Validators.required]],
			closing_time: [this.store.closing_time, [Validators.required]],
			city:  [this.store.city],

		});
	}

	ngOnInit() {
		this.getAllCities();
		this.store = this.data;
		this.store.city = this.store.city || 0; // Asignar un valor predeterminado si es undefined
		console.log('this.store', this.store);
		this.cdr.detectChanges(); // Forzar la actualización de la vista después de recibir los datos
		this.loadMap();
	}

	ngOnChanges(changes: SimpleChanges): void {
		console.log(changes);
	}

	async getAllCities(){
		// const cities = await this.storeService.AllCities();
		// cities.forEach((res:any)=>{
		// 	res.cities.map((element:any)=>{
		// 		this.cities.push({
		// 			id:element.id_city,
		// 			name:element.city
		// 		});

		// 	})
		// });
	}

	showStoreData() {
		console.log("Información de la tienda:", this.store);
	}

	onCitySelected(selectedCity: any) {
		if (selectedCity) {
			this.store.city = selectedCity.id; // Save the city ID instead of the name
		}
	}
	loadMap() {
		this.maps.loadGoogleMaps().then(googleMaps => {
			this.center.lat = parseFloat(this.store.latitude);
			this.center.lng = parseFloat(this.store.longitude);
			this.initMap(googleMaps);
		}).catch(error => {
			console.error('Error al cargar Google Maps:', error);
		});
	}
	ngAfterViewInit() {
		// Carga el mapa después de renderizar la vista
		if (this.store && this.store.latitude && this.store.longitude) {
			this.center = {
				lat: parseFloat(this.store.latitude),
				lng: parseFloat(this.store.longitude)
			};
			this.maps.loadGoogleMaps()
				.then((googleMaps) => this.initMap(googleMaps))
				.catch((error) => console.error('Error al cargar Google Maps:', error));
		}
	}
	initMap(googleMaps: any) {
		if (!this.center.lat || !this.center.lng || isNaN(this.center.lat) || isNaN(this.center.lng)) {
			console.error('No se puede inicializar el mapa sin coordenadas válidas.');
			return;
		}

		// Configuración del mapa
		const mapOptions = {
			center: this.center,
			zoom: this.zoom,
			streetViewControl: true,
		};

		this.map = new googleMaps.Map(this.mapContainer.nativeElement, mapOptions);

		// Crear marcador inicial
		this.marker = new googleMaps.Marker({
			position: this.center,
			map: this.map,
			title: this.store.store_name || 'Ubicación de la tienda',
		});

		// Crear InfoWindow
		const infoWindow = new googleMaps.InfoWindow({
			content: `<div>
						<h4>${this.store.store_name || 'Tienda'}</h4>
						<p><strong>Dirección:</strong> ${this.store.address || 'No disponible'}</p>
						<p><strong>Ciudad:</strong> ${this.store.city || 'No disponible'}</p>
					</div>`,
		});

		// Mostrar InfoWindow al hacer clic en el marcador
		this.marker.addListener('click', () => {
			infoWindow.open(this.map, this.marker);
		});

		// Evento click para mover el marcador
		// googleMaps.event.addListener(this.map, 'click', (event: any) => {
		// 	this.placeMarker(event.latLng, googleMaps);
		// });
	}
	getErrorMessage(controlName: string): string {
		const control = this.storesInformation.get(controlName);
		if (control?.hasError('required')) {
			return 'Este campo es obligatorio';
		}
		if (control?.hasError('minlength')) {
			return `El mínimo permitido es ${control.errors?.minlength.requiredLength} caracteres.`;
		}
		if (control?.hasError('maxlength')) {
			return `El máximo permitido es ${control.errors?.maxlength.requiredLength} caracteres.`;
		}
		if (control?.hasError('pattern')) {
			return 'El formato no es válido';
		}
		return '';
	}

	openingTimeSelected(event){
		console.log('open Time',event)
		this.storesInformation.get('opening_time').setValue(event);
		this.storesInformation.setValidators(openingClosingTimeValidator);
	}
	closingTimeSelected(event){
		console.log('close Time',event);
		this.storesInformation.get('closing_time').setValue(event);
		this.storesInformation.setValidators(openingClosingTimeValidator);
	}
	placeMarker(location: google.maps.LatLng, googleMaps: any) {
		if (this.marker) {
			this.marker.setMap(null); // Elimina el marcador previo
		}

		// Crear nuevo marcador
		this.marker = new googleMaps.Marker({
			position: location,
			map: this.map,
			title: 'Nueva ubicación',
		});

		// Crear nueva InfoWindow
		const infoWindow = new googleMaps.InfoWindow({
			content: `<div>
						<h4>Nueva ubicación</h4>
						<p>Latitud: ${location.lat()}</p>
						<p>Longitud: ${location.lng()}</p>
					</div>`,
		});

		// Mostrar InfoWindow al hacer clic en el nuevo marcador
		this.marker.addListener('click', () => {
			infoWindow.open(this.map, this.marker);
		});

		// Actualizar las coordenadas en el store
		this.store.latitude = location.lat();
		this.store.longitude = location.lng();
		console.log('Nueva posición del marcador:', this.store.latitude, this.store.longitude);
	}

	closeModal() {
		this.cdr.detectChanges();
		this.dialogRef.close();
	}


}
export function openingClosingTimeValidator(group: AbstractControl): ValidationErrors | null {
	const openingTime = group.get('opening_time')?.value;
	const closingTime = group.get('closing_time')?.value;
	if (openingTime && closingTime && openingTime >= closingTime) {
	  return { invalidTimeRange: true }; // Error si la apertura es después del cierre
	}
	return null;
}
