import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ZoneSelectionService {
	private _hasDifferentZones = new BehaviorSubject<boolean>(false);

	constructor() { }

	get hasDifferentZones$() {
		return this._hasDifferentZones.asObservable();
	}

	setDifferentZonesStatus(status: boolean) {
		this._hasDifferentZones.next(status);
	}
}
