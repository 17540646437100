<div>
	<app-button-dashboard
		buttonClass="btn-md-secondary"
		label="uploadDocument.buttonLabel"
		(click)="showPopUpToUpload()"
		(keydown)="handleKeydown($event, 'showPopUp')"
		colorIcon="color-fg-tertiary">
	</app-button-dashboard>
</div>

<div class="container-popup" *ngIf="showPopUp">
	<div class="header-container">
		<div class="title">
		{{ 'uploadDocument.popupTitle' | translate }}
		<app-close-button (click)="showPopUpToUpload()"  (keydown)="handleKeydown($event, 'closePopup')"></app-close-button>
		</div>
		<div class="subtitle">
		{{ 'uploadDocument.popupSubtitle' | translate }}
		<span class="link-title" (click)="downloadTemplate()"  (keydown)="handleKeydown($event, 'downloadTemplate')">
			{{ 'uploadDocument.popupLink' | translate }}
		</span>
		</div>
	</div>

	<div class="file-uploader" (click)="fileInput.click()" (keydown)="handleKeydown($event, 'fileUpload')">
		<input type="file" #fileInput (change)="onFileSelected($event)" style="display: none;" />
		<div class="icon-container">
			<!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
				<path d="M14 10.5V11.3C14 12.4201 14 12.9802 13.782 13.408C13.5903 13.7843 13.2843 14.0903 12.908 14.282C12.4802 14.5 11.9201 14.5 10.8 14.5H5.2C4.07989 14.5 3.51984 14.5 3.09202 14.282C2.71569 14.0903 2.40973 13.7843 2.21799 13.408C2 12.9802 2 12.4201 2 11.3V10.5M11.3333 5.83333L8 2.5M8 2.5L4.66667 5.83333M8 2.5V10.5" stroke="#4B4B4B" stroke-linecap="round" stroke-linejoin="round"/>
			</svg> -->
			<app-icon name="upload-01" color="color-fg-quaternary" size="sm" strokeWidth="semibold">
			</app-icon>
		</div>
		<div class="text-container">
		<div class="title">
			<span class="link-title">
			{{ 'uploadDocument.fileUploader.link' | translate }}
			</span>
			{{ 'uploadDocument.fileUploader.clickHere' | translate }}
		</div>
		<div class="subtitle">
			{{ 'uploadDocument.fileUploader.fileSizeLimit' | translate }}
		</div>
		</div>
	</div>

	<div class="file-upload" *ngIf="this.file !== null">
		<div class="file-content">
		<div class="container">
			<div class="icon">
				<app-icon name="upload-01" color="color-fg-quaternary" size="xs" strokeWidth="semibold">
				</app-icon>
			</div>
			<div class="file-information">
			<div class="title">{{this.file.name}}</div>
			<div class="subtitle">{{ (this.file.size / 1024 / 1024).toFixed(2) }} MB</div>
			</div>
		</div>
			<app-close-button (click)="clearFile()"></app-close-button>
		</div>
		<div class="percentage-container">
		<div class="bar" [style.width]="this.progress+'%'"></div>
		<div class="percentage">{{this.progress}}%</div>
		</div>
	</div>

	<div class="oversize-container" *ngIf="fileSizeIsOver">
		<app-icon name="alert-triangle" color="color-chip-fg-orange" size="xs" strokeWidth="semibold">
		</app-icon>
		<div class="text">
		{{ 'uploadDocument.errors.fileSizeOver' | translate }}
		</div>
	</div>

	<div *ngIf="invalidFileType" class="oversize-container">
		<app-icon name="alert-triangle" color="color-chip-fg-orange" size="xs" strokeWidth="semibold">
		</app-icon>
		<div class="text">
		{{ 'uploadDocument.errors.invalidFileType' | translate }}
		</div>
	</div>

	<div *ngIf="invalidHeaders" class="oversize-container">
		<app-icon name="alert-triangle" color="color-chip-fg-orange" size="xs" strokeWidth="semibold">
		</app-icon>
		<div class="text">
		{{ 'uploadDocument.errors.invalidHeaders' | translate }}
		</div>
	</div>
</div>

<app-alert
	[showAlert]="this.showAlert"
	alertType="medium"
	position="bottom-right"
	imgType="success"
	textAlert="uploadDocument.alert.text"
	[subtitleAlert]="'uploadDocument.alert.subtitle'">
</app-alert>
