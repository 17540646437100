<!-- ? CONTAINER MAIN -->
<div class="cont_main">
	<!-- ? BLOCK 1 | HEADER -->
	<div class="header headlines_h2 font_semibold color_primary">
		<!-- TITLE -->
		<p> {{'overview.cardsOverview.qualifyingAverages' | translate}} </p>

		 <!-- ? TOOLTIP CONTAINER -->
		 <div class="tooltip_cont_">
			<img src="../../../../../../assets/icons/icon-info.svg" class="icon_tooltip">
			   
			<!-- TOOLTIP -->
			<div class="tooltip_">
				<p class="caption_c2 color_primary_400 font_medium">
					{{ 'tooltip.qualification' | translate }}
				</p>
				<!-- PIKE -->
				<div class="pike_2"></div>
			</div>
			<!-- PIKE -->
			<div class="pike"></div>

		</div>

	</div>

	<!-- ? BLOCK 2 | CHART -->
	<div class="" [ngClass]="simplified ? '' : 'section_'">
		<!-- ? BLOCK 1 | QUALIFICATION & STARS -->
		<div class="block_1">
			<div>
				<!-- QUALIFICATION TXT -->
				<p class="color_primary font_semibold" >{{qualification}}</p>
				<!-- STARS -->
				<div class="stars">
					<app-star-rating 
						[rating]="rating">
					</app-star-rating>
				</div>
			</div>
			<!-- FOOTER -->
			<p class="caption_c1 color_primary_300 font_medium">
				{{'overview.averageReviews' | translate}} {{quantityQualification}} {{'overview.reviews'|translate}}
			</p>

		</div>

		<div class="block_2" *ngIf="!simplified">
			<!-- ? STARS TXT-->
			<div class="stars_txt">
				<div>
					<!-- NUMBER -->
					<p class="captions_c1 color_primary_300 font_medium" >1</p>
					<!-- STAR -->
					<img src="../../../../../../assets/icons/icon-star-chart.svg">
					<!-- NUMBER -->
				</div>
				<div>
					<!-- NUMBER -->
					<p class="captions_c1 color_primary_300 font_medium" >2</p>
					<!-- STAR -->
					<img src="../../../../../../assets/icons/icon-star-chart.svg">
					<!-- NUMBER -->
				</div>
				<div>
					<!-- NUMBER -->
					<p class="captions_c1 color_primary_300 font_medium" >3</p>
					<!-- STAR -->
					<img src="../../../../../../assets/icons/icon-star-chart.svg">
					<!-- NUMBER -->
				</div>
				<div>
					<!-- NUMBER -->
					<p class="captions_c1 color_primary_300 font_medium" >4</p>
					<!-- STAR -->
					<img src="../../../../../../assets/icons/icon-star-chart.svg">
					<!-- NUMBER -->
				</div>
				<div>
					<!-- NUMBER -->
					<p class="captions_c1 color_primary_300 font_medium" >5</p>
					<!-- STAR -->
					<img src="../../../../../../assets/icons/icon-star-chart.svg">
					<!-- NUMBER -->
				</div>
			</div>

			<!-- ? CHART -->
			<div class="chart" id="chart">
				<apx-chart
					[series]="chartOptions.series"
					[chart]="chartOptions.chart"
					[dataLabels]="chartOptions.dataLabels"
					[plotOptions]="chartOptions.plotOptions"
					[xaxis]="chartOptions.xaxis"
					[responsive]="chartOptions.responsive"
					[legend]="chartOptions.legend"
					[fill]="chartOptions.fill"
					[stroke]="chartOptions.stroke">
				</apx-chart>

			</div>
			<!-- ? CHART VALUES -->
			<div class="chart_values captions_c1 color_primary_300 font_medium">
				<div *ngFor="let item of interpolationData" >
					<p>  {{item.data || 0 }} ({{item.percentage || 0 }}%)</p>
				</div>
			</div>

		</div>

	</div>
</div>
