import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { EncryptDataService } from 'src/app/core/security/encypt-data.service';

@Component({
  selector: 'btn-activity',
  templateUrl: './btn-activity.component.html',
  styleUrls: ['./btn-activity.component.scss']
})
export class BtnActivityComponent {
	idUserType:number;
	local;

	constructor(private router      :Router,private securityLocalStorage: EncryptDataService){}
	ngOnInit(){
		this.local = JSON.parse(this.securityLocalStorage.decryptData(localStorage.getItem('data')));

		this.idUserType = parseInt(this.local.data_company.id_user_type);
	}

	navigateToActivity(){
		if(this.idUserType === 6){
			this.router.navigateByUrl('warehouse/activity')
		}
		if(this.idUserType === 7){
			this.router.navigateByUrl('vivipost/activity')
		}
		else if(this.idUserType ===9){
			this.router.navigateByUrl('superadmin/activity')
		}
		else if(this.idUserType === 10){
			this.router.navigateByUrl('carriers/activity')
		}
		else if(this.idUserType === 11){
			this.router.navigateByUrl('local-admin/activity')
		}
	}
}
