import { Component, OnInit } from '@angular/core';
import { SideBarSettings } from 'src/app/shared/components/sidebar-settings/sidebar-settings.component';

@Component({
	selector: 'app-client-profile-admin',
	templateUrl: './client-profile-admin.component.html',
	styleUrls: ['./client-profile-admin.component.scss'],
})
export class ClientProfileAdminComponent implements OnInit {
	ngOnInit(): void {
		throw new Error('Method not implemented.');
	}

	personal	:boolean = true;
	password	:boolean = false;
	preferences	:boolean = false;


	sidebarSettingsProfileAdmin:SideBarSettings[] =[
		{
		txt:"side-bar-panel-control.account",
		route:"account"
		},
		{
		txt:"side-bar-panel-control.password",
		route:"password"
		},
		{
		txt:"side-bar-panel-control.pref",
		route:"preferences"
		},
	];

	personalOnClick(){
		this.personal 		= true;
		this.password 		= false;
		this.preferences 	= false;
	}

	passwordOnClick(){
		this.personal 		= false;
		this.password 		= true;
		this.preferences 	= false;
	}

	preferencesOnClick(){
		this.personal 		= false;
		this.password 		= false;
		this.preferences 	= true;
	}

}
