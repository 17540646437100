//* Angular
import { Component, Input, SimpleChanges, ViewChild } from '@angular/core';
//* Services
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
//* Apex Charts
import {
	ApexNonAxisChartSeries,
	ApexPlotOptions,
	ApexChart,
	ApexLegend,
	ApexResponsive,
	ChartComponent,
	ApexStroke
} from 'ng-apexcharts';

@Component({
	selector: 'headquarters',
	templateUrl: './headquarters.component.html',
	styleUrls: ['./headquarters.component.scss']
})
export class HeadquartersComponent {
	@ViewChild('chartDonut') chart: ChartComponent;
	public chartOptions: Partial<ChartOptionsDonut>;
	totalSeries: number;
	tooltipHeadquarters: string;
	@Input() periodBase: string = '';
	@Input() periodComp: string = '';
	@Input() changeStyles: string = 'dates-default';
	@Input() dataBase = [];
	@Input() dataLabels = [];
	@Input() dataLegends = [];

	seriesData = [76, 67, 61, 100, 23];
	total = this.seriesData.reduce((acc, value) => acc + value, 0);
	seriesWithTotal = [...this.seriesData, this.total];

	constructor(private translate: TranslateService) {
	}

	ngOnInit() {
		this.loadDataChart();
		this.translate.setDefaultLang(localStorage.getItem('language').toLowerCase());
		this.tooltipHeadquarters = this.translate.instant('tooltip.sedes');
		this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
			this.translate.use(event.lang);
			this.tooltipHeadquarters = this.translate.instant('tooltip.sedes');
		})
	}

	ngOnChanges(changes: SimpleChanges) {
		if (this.dataLegends.length > 0) {
			this.dataLegends = this.dataLegends.map((item) => {
				let newItem = item.replace('Locker ', '');
				return newItem;
			});
		}
		this.loadDataChart();
	}

	private loadDataChart() {
		this.chartOptions = {
			chart: {
				height: 220,
				width: '100%',
				type: 'donut',
				toolbar: {
					show: true,
					tools: {
						download: `
						<div class="downloadClassHeadquarters">
							<label class="textExport">
								${this.translate.instant('buttons.export')}
							</label>
							<div class="icon">
							</div>
						</div>
						`,
						pan: false,
						reset: false,
						zoom: false,
						zoomin: false,
						zoomout: false,
						selection: true
					},
					// export: {
					// 	csv: {
					// 		filename: undefined,
					// 		columnDelimiter: ',',
					// 		headerCategory: 'category',
					// 		headerValue: 'value',
					// 		dateFormatter(timestamp) {
					// 			return new Date(timestamp).toDateString()
					// 		}
					// 	},
					// 	svg: {
					// 		filename: undefined,
					// 	},
					// 	png: {
					// 		filename: undefined,
					// 	}
					// }
				},
			},
			series: this.dataBase,
			plotOptions: {
				pie: {
					startAngle: -90,
					endAngle: 90,
					offsetY: 10,
					dataLabels: {
						offset: 9999999999,
					},
					donut: {
						labels: {
							show: true,
							total: {
								show: true,
								color: 'var(--color-primary)',
								fontSize: '14px',
								fontWeight: '500',
								fontFamily: 'Inter',
								showAlways: true,
								formatter: (w: any) =>
									w.globals.seriesTotals.reduce(
										(a: number, b: number) => a + b,
										0
									),
							},
							value: {
								show: true,
								color: 'var(--color-primary)',
								fontSize: '14px',
								fontWeight: '500',
								fontFamily: 'Inter',
								offsetY: -5,
							},
						},
					},
				},
			},
			colors: ['#8AC6E7', '#54ABDC', '#0082CB', '#005C90', '#003755'],
			labels: this.dataLabels,
			legend: {
				show: false,
				floating: false,
				fontSize: '10px',
				// width: 200,
				position: 'bottom',
				fontFamily: 'var(--primary-font)',
				fontWeight: 500,
				// offsetX: 0,
				offsetY: -50,
				horizontalAlign: 'left',
				// labels: {
				// 	useSeriesColors: true,
				// },
				itemMargin: {
					horizontal: 0,
				},
				markers: {
					width: 10,
					height: 10,
					strokeWidth: 0,
					strokeColor: '#fff',
					fillColors: undefined,
					radius: 2,
					onClick: undefined,
					offsetX: 0,
					offsetY: 0,
					customHTML: function () {
						return `<div class="color_ circle_1"></div>`
					}
				},
			},
			responsive: [
				{
					breakpoint: 480,
					options: {
						height: 150,
						width: '100%',
						type: 'donut',
						toolbar: {
							show: true,
							tools: {
								download: `
									<div class="downloadClassHeadquarters">
										<label class="textExport">
											Exportar
										</label>
										<div class="icon">
										</div>
									</div>
								`,
								pan: false,
								reset: false,
								zoom: false,
								zoomin: false,
								zoomout: false,
								selection: true
							},
						},
					},
				},
			],
			stroke: {
				show: true,
				width: 0,
				colors: ['var(--color-container)'],
			},
		};
		this.totalSeries = this.chartOptions.series.reduce((a, b) => a + b, 0);
	}
}

export type ChartOptionsDonut = {
	series: ApexNonAxisChartSeries;
	chart: ApexChart;
	labels: string[];
	colors: string[];
	legend: ApexLegend;
	plotOptions: ApexPlotOptions;
	responsive: ApexResponsive | ApexResponsive[];
	stroke: ApexStroke;
};
