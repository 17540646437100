import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { AgGridAngular } from 'ag-grid-angular';
import { CellClickedEvent, ColDef, GridApi, GridOptions} from 'ag-grid-community';
import { utils } from 'xlsx';
import { AdminClientService } from 'src/app/core/http/admin-client.service';
import { StarRatingComponent } from 'src/app/shared/components/star-rating/star-rating.component';
import { Calendar } from 'primeng/calendar';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import * as XLSX from 'xlsx';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { EstatusTableOrderAdminComponent } from '../../estatus-table-order-admin/estatus-table-order-admin.component';
import { DateTextComponent } from '../../date-text/date-text.component';
import { DialogOrderDetailComponent } from '../../dialog-order-detail/dialog-order-detail.component';
import { DialogDeliverPersonallyComponent } from '../../dialog-deliver-personally/dialog-deliver-personally.component';
import { FilterService } from 'src/app/core/http/FilterDataServiceOxxoToOxxo.service';

@Component({
	selector: 'app-table-general-carrier',
	templateUrl: './table-general-carrier.component.html',
	styleUrls: ['./table-general-carrier.component.scss']
})
export class TableGeneralCarrierComponent implements OnInit {
	public columnDefs: ColDef[];
	public defaultColDefGeneral: ColDef = {
		sortable: true,
		filter: true,
	};
	public rowDataGeneral: any[];
	private gridApi: AgGridAngular;
	supportLanguages = ['es', 'en'];
	InitialDate = new Date();
	showDate: Date;
	showDateFinal: Date;
	fechasByMonth: string;
	FinalDate = new Date();
	res;
	completedDataCompleted = [];
	countCompleted = 0;
	rangeDates: Date[];
	valueInput;
	display = false;
	minDate: Date;
	maxDate: Date;
	orderSelectToExtendDate: any;
	displayDialogOrder = false;
	filterText: string = '';
	gridOptions: GridOptions;
	rowData: any[];
	dataTemporal = [];
	public filteredData: any[] = [];
	idLocker: string;
	trackingNumber = localStorage.getItem('searchOrderNotification');
	carrierID:number;
	filters:boolean=false;
	mode:string;
	@ViewChild('calendar') calendar: Calendar;
	@ViewChild('input') textToFilter: ElementRef;
	@ViewChild('agGrid') agGrid: AgGridAngular;

	constructor(
		private translate		:TranslateService,
		private router			:Router,
		private adminService	:AdminClientService,
		private toastr			:ToastrService,
		private matdialog		:MatDialog,
		private route			:ActivatedRoute,
		private filterService	: FilterService) {

		this.minDate = new Date();
		this.minDate.setDate(this.minDate.getDate() + 1); // día mínimo es mañana
		this.maxDate = new Date();
		this.maxDate.setDate(this.maxDate.getDate() + 2); // día máximo es pasado mañana
		let today = new Date();
		let month = today.getMonth();
		let year = today.getFullYear();
		let day = today.getDate();
		let lastMonth;
		let endDate;
		let startDate;
		if (month < 0) {
			month = 11;
			year -= 1;
		}
		lastMonth = new Date(year, month - 1, day);
		endDate = new Date(year, month, day);
		startDate = new Date(year, lastMonth.getMonth(), day);
		this.rangeDates = [startDate, endDate];
		this.InitialDate = this.rangeDates[0];
		this.FinalDate = this.rangeDates[1];
		this.idLocker = localStorage.getItem('id_locker');
		localStorage.setItem('id_locker', this.idLocker);
	}
	ngOnInit() {
		this.mode = localStorage.getItem('mode');

		if( localStorage.getItem('searchOrderNotification') !== null){
			this.trackingNumber = localStorage.getItem('searchOrderNotification');
			this.filterText = this.trackingNumber;
		}
		this.carrierID = parseInt(localStorage.getItem('courier_id'));
		this.route.params.subscribe(params => {
			const id = params['id'];
			if(this.trackingNumber !== id){
				this.filterText = id;
			}
		})
		this.translate.use(localStorage.getItem('language').toLowerCase());
		this.updateDataLocker();
		this.addEventRangeDates();
		this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
			localStorage.setItem('id_locker', this.idLocker);
			this.translate.use(event.lang);
			this.translate.get([
					'table-general.tracking',
					'table-general.lockerID',
					'table-general.email',
					'table-general.employeeName',
					'table-general.employeeID',
					'table-general.doorNumber',
					'table-general.status',
					'table-general.inLocker',
					'table-general.dateCollected',
					'table-general.rateStart',
				])
				.subscribe((t: any) => {
					this.columnDefs = [
						{
							headerName: t['table-general.tracking'],
							field: 'tracking',
							sortable: true,
							autoHeight: true,
							cellClass:'generalClassLink',
							cellRenderer: this.centeredCellRenderer,
							headerClass:'borderHeaderGeneral',
							onCellClicked: ($event) =>
								this.openModalUserDetail($event),
						},
						{
							headerName: t['table-general.lockerID'],
							field: 'lockerID',
							sortable: true,
							autoHeight: true,
							cellClass:'generalClass',
							cellRenderer: this.centeredCellRenderer,
							headerClass:'borderHeaderGeneral'
						},
						{
							headerName: t['table-general.email'],
							field: 'email',
							sortable: true,
							autoHeight: true,
							width: 270,
							cellClass:'generalClass',
							cellRenderer: this.centeredCellRenderer,
							headerClass:'borderHeaderGeneral'
						},
						{
							headerName: t['table-general.employeeID'],
							field: 'employeeId',
							sortable: true,
							autoHeight: true,
							cellClass:'generalClass',
							cellRenderer: this.centeredCellRenderer,
							headerClass:'borderHeaderGeneral'
						},
						{
							headerName: t['table-general.doorNumber'],
							field: 'doorNumber',
							sortable: true,
							autoHeight: true,
							cellClass:'generalClass',
							cellRenderer: this.centeredCellRenderer,
							headerClass:'borderHeaderGeneral'
						},
						{
							headerName: t['table-general.status'],
							field: 'status',
							sortable: true,
							autoHeight: true,
							cellRenderer: EstatusTableOrderAdminComponent,
							headerClass:'borderHeaderGeneral',
							cellClass:'generalClass',
							onCellClicked: ($event) =>
								this.openCalendar($event),
						},
						{
							headerName: t['table-general.inLocker'],
							field: 'inLocker',
							sortable: true,
							autoHeight: true,
							cellClass:'generalClass',
							cellRenderer: DateTextComponent,
							headerClass:'borderHeaderGeneral'
						},
						{
							headerName: t['table-general.dateCollected'],
							field: 'dateCollected',
							sortable: true,
							autoHeight: true,
							cellClass:'generalClass',
							cellRenderer: DateTextComponent,
							headerClass:'borderHeaderGeneral'
						},
						{
							headerName: t['table-general.rateStart'],
							field: 'rate_start',
							sortable: true,
							autoHeight: true,
							cellClass:'generalClass',
							cellRenderer: StarRatingComponent,
							headerClass:'borderHeaderGeneral'
						},
					];
				});
		});
	}
	updateDataLocker(): void {
		this.gridOptions = {
			columnDefs: [
				{
					headerName: this.translate.instant('table-general.tracking'),
					field: 'tracking',
					sortable: true,
					autoHeight: true,
					cellClass:'generalClassLink',
					headerClass:'borderHeaderGeneral',
					cellRenderer: this.centeredCellRenderer,
					onCellClicked: ($event) => this.openModalUserDetail($event),
				},
				{
					headerName: this.translate.instant(
						'table-general.lockerID'
					),
					field: 'lockerID',
					sortable: true,
					autoHeight: true,
					cellClass:'generalClass',
					headerClass:'borderHeaderGeneral',
					cellRenderer: this.centeredCellRenderer,
				},
				{
					headerName: this.translate.instant('table-general.email'),
					field: 'email',
					sortable: true,
					autoHeight: true,
					cellClass:'generalClass',
					headerClass:'borderHeaderGeneral',
					cellRenderer: this.centeredCellRenderer,
				},
				{
					headerName: this.translate.instant(
						'table-general.employeeID'
					),
					field: 'employeeId',
					sortable: true,
					autoHeight: true,
					cellClass:'generalClass',
					headerClass:'borderHeaderGeneral',
					cellRenderer: this.centeredCellRenderer,
				},
				{
					headerName: this.translate.instant(
						'table-general.doorNumber'
					),
					field: 'doorNumber',
					sortable: true,
					autoHeight: true,
					cellClass:'generalClass',
					headerClass:'borderHeaderGeneral',
					cellRenderer: this.centeredCellRenderer,
				},
				{
					headerName: this.translate.instant('table-general.status'),
					field: 'status',
					sortable: true,
					autoHeight: true,
					cellClass:'generalClass',
					cellRenderer: EstatusTableOrderAdminComponent,
					headerClass:'borderHeaderGeneral',
					onCellClicked: ($event) => this.openCalendar($event),
				},
				{
					headerName: this.translate.instant(
						'table-general.inLocker'
					),
					field: 'inLocker',
					sortable: true,
					autoHeight: true,
					cellClass:'generalClass',
					headerClass:'borderHeaderGeneral',
					cellRenderer: DateTextComponent,
				},
				{
					headerName: this.translate.instant(
						'table-general.dateCollected'
					),
					field: 'dateCollected',
					sortable: true,
					autoHeight: true,
					cellClass:'generalClass',
					headerClass:'borderHeaderGeneral',
					cellRenderer: DateTextComponent,
				},
				{
					headerName: this.translate.instant(
						'table-general.rateStart'
					),
					field: 'rate_start',
					sortable: true,
					autoHeight: true,
					headerClass:'borderHeaderGeneral',
					cellRenderer: StarRatingComponent,
					cellClass:'generalClass',
				}
			],
			animateRows: true,
			pagination: true,
			paginationPageSize: 10,
			paginationAutoPageSize: true,
			headerHeight:59,
			rowHeight:59,
			rowClass:'rowGeneralClass',
			scrollbarWidth:0,
			alwaysShowVerticalScroll:true,
			alwaysShowHorizontalScroll:true,
			overlayNoRowsTemplate:
			`<div>
				<div>
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="bi bi-info-circle icon-no-info" viewBox="0 0 16 16">
						<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
						<path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
					</svg>
				</div>
				<div class="labelNoRegistros">
					Aun no hay registros, <br>
					intentalo mas tarde
				</div>
			</div>`,
		}
	}
	onFilterTextFromNotification(item) {
		this.filteredData = this.rowData.filter(
			(data) => data.tracking === item
		);
		this.rowData = [...this.filteredData];
		this.agGrid.api.setRowData(this.rowData);
	}
	onGridReady(params: any) {
		this.gridApi = params;
	}
	onCellClicked(e: CellClickedEvent): void {
		if (e.colDef.field === 'status') {
			if (
				e.data.status_package === 14 &&
				e.data.id_status_code_package === 32
			) {
				this.display = true;
				this.orderSelectToExtendDate = e.data;
			} else {
				this.display = false;
			}
		}
	}
	openCalendar(e: CellClickedEvent) {
		if (
			e.data.status_package === 14 &&
			e.data.id_status_code_package === 32
		) {
			this.display = true;
			this.orderSelectToExtendDate = e.data;
		} else {
			this.display = false;
		}
	}
	addEventRangeDates() {
		this.InitialDate = this.rangeDates[0];
		if (this.rangeDates[1] !== null) {
			this.FinalDate = this.rangeDates[1];
			this.updateDataLocker();
			this.getTableCompleted();
		}
	}
	getTableCompleted() {
		this.rowData = [];
		this.idLocker = localStorage.getItem('id_locker');
			this.completedDataCompleted = [];
			let lang = localStorage.getItem('language').toLowerCase();
			let company = localStorage.getItem('id_company');
			let data = {
				language: lang,
				id_company: company,
				date_start: this.InitialDate.getTime(),
				date_end: this.FinalDate.getTime(),
				id_courier : this.carrierID,
				is_module_courier: true
			};
			this.adminService.getAllOrders(data).then((res:any) => {
				res.return_result.map((f) => {
					this.rowData.push({
						order_number: f.order_number,
						tracking: f.tracking_number,
						email: f.email,
						lockerID: f.locker_name,
						phone_used: f.phone_used,
						nameEmploye: f.delivery_employee_name,
						door: f.door_number,
						compartmentSize: f.compartment_size,
						status: f.name_status_package,
						inLocker: f.package_delivery_date,
						dateCollected: f.status_package == 16 ? f.package_pickup_date : null,
						rate_start: f.rate_start,
						id_package: f.id_package,
						id_package_code: f.id_package_code,
						employeeId: f.delivery_company_employee_id,
						carrier: 'N/A',
						doorNumber:	f.door_number + ', ' + f.compartment_name,
						doorSize: f.compartment_name,
						id_status_code_package: f.id_status_code_package,
						status_package: f.status_package,
						qr_order: f.qr_order,
						name_status_notification:f.name_status_notification,
						expiration_date: f.expiration_date,
						extended_package: f.extended_package,
						resultNotification: f.result_notification,
						IMAGE: f.IMAGE,
					});
				});
				this.rowData = [...this.rowData];
				this.filterService.getStarsFilter().subscribe(starsFilter => {
					if(	starsFilter.size > 0){
						const filteredData = this.rowData.filter(item => starsFilter.has(item.rate_start));
						this.filteredData = filteredData;
						this.gridApi.api.setRowData(filteredData);
					}
					else{
						this.gridApi.api.setRowData(this.rowData);
					}
				});
			})
			.catch((error) => {
				console.log(error);
			});
	}
	DownloadExcel() {
		let headerExcel = [
			'tracking',
			'lockerID',
			'email',
			'employeeID',
			'nameEmploye',
			'inLockerDate',
			'inLockerTime',
			'dateCollectedDate',
			'dateCollectedTime',
			'rate_start',
		];
		let dataExcel = [];

		this.completedDataCompleted.forEach((e: any) => {
			let info = {
				tracking: e.tracking,
				lockerID: e.lockerID,
				email: e.email,
				employeeID: e.employeeId,
				nameEmploye: e.nameEmploye,
				inLockerDate: e.inLocker.slice(0, 10),
				inLockerTime: e.inLocker.slice(11, 19),
				dateCollectedDate: e.dateCollected.slice(0, 10),
				dateCollectedTime: e.dateCollected.slice(11, 19),
				rate_start: e.rate_start,
			};
			dataExcel.push(info);
		});
		const worksheet = utils.json_to_sheet(dataExcel, {
			header: headerExcel,
		});
		const workbook = utils.book_new();
		utils.book_append_sheet(workbook, worksheet, 'Data');
	}
	centeredCellRenderer(params) {
		const cellValue = params.value;
		const cellDiv = document.createElement('div');
		cellDiv.style.display = 'flex';
		cellDiv.style.alignItems = 'center';
		cellDiv.style.justifyContent = 'center';
		cellDiv.style.height = '100%';
		cellDiv.innerText = cellValue;
		return cellDiv;
	}
	async extenderFechas(calendar) {
		let dateSelect = new Date(calendar.value);
		let month = dateSelect.getMonth().toString().length == 1 ? '0' + (dateSelect.getMonth() + 1) : dateSelect.getMonth() + 1;
		let day = dateSelect.getDate().toString().length == 1 ? '0' + dateSelect.getDate() : dateSelect.getDate();
		let d = dateSelect.getFullYear() + '-' + month + '-' + day;
		let dataSendUpdate = {
			language: localStorage.getItem('language').toString().toLowerCase(),
			id_company: localStorage.getItem('id_company'),
			id_package_code: this.orderSelectToExtendDate.id_package_code,
			date_update: d,
			id_courier : this.carrierID,
			is_module_courier:true
		};
		let res: any = await this.adminService.updateExpirationDate(dataSendUpdate);
		if (res.mensaje_return) {
			this.display = false;
			this.toastr.success('la entrega santifactoriamente','Se ha extendindo',
				{
					toastClass: 'toast-extendida',
					timeOut: 5000,
					positionClass: 'toast-bottom-center',
				}
			);
		}
		this.getTableCompleted();
	}

	openModalUserDetail(event) {
		const dialogRef = this.matdialog.open(DialogOrderDetailComponent, {
			width: '1304px',
			height: 'auto',
			data: event.data,
		});

		dialogRef.afterClosed().subscribe((result) => {});
	}

	openModalDeliverPersonally(event) {
		const dialogRef = this.matdialog.open(
			DialogDeliverPersonallyComponent,
			{
				width: '558px',
				height: '270px',
				data: event.data,
			}
		);

		dialogRef.afterClosed().subscribe((result) => {});
	}

	cerrarExtender() {
		this.display = false;
		this.orderSelectToExtendDate = [];
	}

	onFilterTextChange(event) {
		this.filterText = event.target.value;
		event.api.setQuickFilter(this.filterText);
		let api = event.api;
		let temporal = [];
		event.api.forEachNodeAfterFilter((node) => {
			temporal.push(node.data);
		});
		this.filteredData = temporal;
	}

	onExport() {
		const fileName = 'CarriersOrderGeneral.xlsx';
		// Configuración para exportar en formato Excel
		const excelParams = {
			fileName: fileName,
			sheetName: 'CarriersOrdersGeneral',
			columnWidths: [30, 20, 20, 20], // Ancho de las columnas en caracteres
		};
		if (this.filteredData.length > 0) {
			const Data = this.filteredData || [];
			const excelData = Data.map((row) => {
				const { employeeId,lockerID, id_package,id_package_code,id_status_code_package,status_package,qr_order,resultNotification,IMAGE,  ...rowDataWithoutFields} = row;
					return rowDataWithoutFields;
			});
			const wb = XLSX.utils.book_new();
			const ws = XLSX.utils.json_to_sheet(excelData);
			XLSX.utils.book_append_sheet(wb, ws, excelParams.sheetName);
			XLSX.writeFile(wb, fileName);
		}
		else if (this.rowData.length > 0 && this.filteredData.length === 0 && (this.trackingNumber === null)) {
			if(this.trackingNumber !== ''){
				const Data = this.rowData || [];
				const excelData = Data.map((row) => {
					const { employeeId,lockerID, id_package,id_package_code,id_status_code_package,status_package,qr_order,resultNotification,IMAGE,  ...rowDataWithoutFields} = row;
					return rowDataWithoutFields;
				});
				const wb = XLSX.utils.book_new();
				const ws = XLSX.utils.json_to_sheet(excelData);
				XLSX.utils.book_append_sheet(wb, ws, excelParams.sheetName);
				XLSX.writeFile(wb, fileName);
			}
		}
	}
	showFilters(){
		this.filters = !this.filters
	}
}
