<div class="container-fluid">
	<div class="row">
		<!--* SEARCH LOCKER -->
		<div class="col-6 text-start">
			<div class="input-container">
				<input type="text" class="input-gbl" (keyup)="onFilterTextChange($event)" placeholder="{{'search.orders' | translate}}"/>
				<button type="submit" class="btn-search"> <div class="icon-search"></div></button>
			</div>
		</div>
		<div class="col-6 buttons-and-ranges">
			<p-calendar class="selectYear"
						[showIcon]="true"
						[(ngModel)]="rangeDates"
						(ngModelChange)="addEventRangeDates()"
						selectionMode="range"
						[readonlyInput]="true"
						inputId="range" >
			</p-calendar>
			<!-- ? BTN EXPORT -->
            <button (click)="onExport()" [ngClass]="{
				'btn_primary' : buttonExport,
				'btn_primary_disabled' : !buttonExport
			}">
                {{ 'buttons.export' | translate }}.xls
                <img [src]="'../../../../../../assets/icons/'+this.dataService.getIconBtnExport(this.buttonExport)+'.svg'">
            </button>
		</div>
	</div>

	<div class="row">
		<!-- SEARCH -->
		<div class="col-12">
			<ag-grid-angular
			#agGrid
			class="ag-theme-alpine"
			[gridOptions]="this.gridOptions"
			[rowData]="this.rowDataExpired"
			[columnDefs]="this.columnDefsExpired"
			[quickFilterText]="this.filterText"
			style="width: 100%; height: 740px; border: none; margin-top:24px;"
			(gridReady)="onGridReady($event)">
		</ag-grid-angular>
		</div>
	</div>
</div>



<div *ngIf="display && (idUserType === 9 || idUserType === 7)" class="extendCard">
    <div class="row">
        <div class="col-12">
            <p-calendar #calendar [inline]="true" [minDate]="minDate" [maxDate]="maxDate"></p-calendar>
        </div>
        <div class="col-6" style="text-align: start;">
            <button type="button" class="btn-cancelar font-primary" (click)="cerrarExtender()">
                {{'general.cancel'| translate}}
            </button>
        </div>
        <div class="col-6" style="text-align: end;">
            <button type="button" class="btn-extend font-primary" (click)="extenderFechas(calendar)">
                {{'table.extend' | translate}}
            </button>
        </div>
    </div>
</div>
