<div class="container-graphic">
	<div class="header-graphic">
		<div class="noMargins">
			<div id="chart">
				<apx-chart
					[series]="chartOptions.series"
					[chart]="chartOptions.chart"
					[xaxis]="chartOptions.xaxis"
					[stroke]="chartOptions.stroke"
					[tooltip]="chartOptions.tooltip"
					[dataLabels]="chartOptions.dataLabels"
					[legend]="chartOptions.legend"
					[markers]="chartOptions.markers"
					[grid]="chartOptions.grid"
					[yaxis]="chartOptions.yaxis"
					[title]="chartOptions.title"
					[responsive]="chartOptions.responsive"
				></apx-chart>
			</div>
		</div>
	</div>
</div>
