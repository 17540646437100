import { Component, OnInit, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AdminClientService } from 'src/app/core/http/admin-client.service';
import { EncryptDataService } from 'src/app/core/security/encypt-data.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
	formularioNewPassword: FormGroup;
	showPasswordConfirm: boolean = false;
	showPasswordNewPassword: boolean = false;
	hasErrorInSamePassword = null;
	hasUpperCase = null;
	hasNumber = null;
	hasSpecialCharacter = null;
	hasMinLength = null;
	errorMessage;
	cpSuccess: boolean = null;
	tocken = null;
	local;
	public fb = inject(FormBuilder);
	public ts = inject(TranslateService);
	public adminService = inject(AdminClientService);
	public securityLocalStorage = inject(EncryptDataService);
	ngOnInit(): void {
		this.local = JSON.parse(this.securityLocalStorage.decryptData(localStorage.getItem('data')))
		this.formularioNewPassword = this.fb.group({
			actualPassword:['', Validators.required],
			newPassword: ['', [Validators.required,Validators.minLength(8)]],
			confirmPassword: ['', [Validators.required]]
		}, { validator: this.passwordMatchValidator });
		this.formularioNewPassword.get('newPassword').valueChanges.subscribe(newValue => {
			this.hasUpperCase = /[A-Z]/.test(newValue);
			this.hasNumber = /\d/.test(newValue);
			this.hasSpecialCharacter = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(newValue);
			this.hasMinLength = newValue.length >= 8;
			this.formularioNewPassword.get('confirmPassword').updateValueAndValidity();
		});
	}
	passwordMatchValidator(formGroup: FormGroup) {
		const newPassword = formGroup.get('newPassword').value;
		const confirmPassword = formGroup.get('confirmPassword').value;
		if (newPassword !== confirmPassword) {
			formGroup.get('confirmPassword').setErrors({ mismatch: true });
		} else {
			formGroup.get('confirmPassword').setErrors(null);
		}
	}
	updatePassword() {
		if (this.formularioNewPassword.get('newPassword').value === '' || this.formularioNewPassword.get('confirmPassword').value === '' || this.formularioNewPassword.invalid) {
			this.errorMessage = 'Por favor, completa todos los campos.';
		} else {
			this.errorMessage = null;
			let obj = {
				"language": "es",
				"token": this.local.token,
				"password": this.formularioNewPassword.get('confirmPassword').value
			}
			this.adminService.changePasswordService(obj).then((res: any) => {
				if (res.mensaje_return.CODE === 200) {
				this.cpSuccess = true;
				} else {
				this.cpSuccess = false;
				}
			}).catch((err: any) => {
				this.cpSuccess = false;
			})
		}
	}
}
