//ANGULAR
import { Component, Input, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
//APEXCHARTS
import { ChartComponent, ApexAxisChartSeries, ApexChart, ApexXAxis, ApexDataLabels, ApexStroke, ApexMarkers, ApexYAxis, ApexGrid, ApexTitleSubtitle, ApexLegend, ApexFill } from 'ng-apexcharts';


@Component({
  selector: 'order-metrics',
  templateUrl: './order-metrics.component.html',
  styleUrls: ['./order-metrics.component.scss']
})
export class OrderMetricsComponent {
	@ViewChild('chart') chart: ChartComponent;
	@Input() dataDeliver: number[] = [];
	@Input() dataRecolected: number[] = [];
	@Input() dataExpired: number[] = [];
	public chartOptions: Partial<ChartOptions>;

	constructor(private translate: TranslateService) {
		translate.setDefaultLang(
			localStorage.getItem('language').toLowerCase()
		);
	}

	ngOnInit() {
		this.loadDataChart();
	}

	ngOnChanges() {
		this.loadDataChart();
	}
	private loadDataChart() {
		this.chartOptions = {
			series: [
				{
					name: this.translate.instant('overview.orderDelivered'),
					data: this.dataDeliver,
				},
				{
					name: this.translate.instant('overview.orderRecolected'),
					data: this.dataRecolected,
				},
				{
					name: this.translate.instant('overview.orderExpired'),
					data: this.dataExpired,
				},
			],
			chart: {
				stacked: true,
				type: 'line',
				height: 375,
				width: '100%',
				toolbar: {
					show: true,
					tools:{
						download:`
							<div class="icon_chevron">
								<p class="caption_c2 font_medium color_primary">
									${this.translate.instant('buttons.export')}
								</p>
								<img src="../../../../../../assets/icons/icon-chevron-down.svg">

							</div>
						`,
						pan:false,
						reset:false,
						zoom:false,
						zoomin:false,
						zoomout:false,
						selection:false
					},
				},
				selection: {
					enabled: true,
				},
				dropShadow: {
					enabled: false,
					enabledOnSeries: undefined,
					top: 0,
					left: 0,
					blur: 3,
					color: 'transparent',
					opacity: 0.35,
				},
			},
			dataLabels: {
				enabled: false,

			},
			
			stroke: {
				width: 2,
				colors: ['#41b5f5', '#0082CB', '#18345D'],
				curve: 'straight',
				dashArray: [0, 0, 0],
			},
			markers: {
				size: 0,
				hover: {
					sizeOffset: 6,
				},
				colors: ['#41b5f5', '#0082CB', '#18345D'],
			},
			yaxis:{
				labels: {
					style:{
						colors:'var(--color-primary-300)'
					}
				},
			},
			xaxis: {
				labels: {
					trim: false,
					style:{
						colors:'var(--color-primary-300)'
					}
				},
				categories: [
					this.translate.instant('month.Ene'),
					this.translate.instant('month.Feb'),
					this.translate.instant('month.Mar'),
					this.translate.instant('month.Abr'),
					this.translate.instant('month.May'),
					this.translate.instant('month.Jun'),
					this.translate.instant('month.Jul'),
					this.translate.instant('month.Ago'),
					this.translate.instant('month.Sep'),
					this.translate.instant('month.Oct'),
					this.translate.instant('month.Nov'),
					this.translate.instant('month.Dic'),
				],
			},
			tooltip: {
				y: [
					{
						title: {
							formatter: function (val) {
								return val + ' (mins)';
							},
						},
					},
				],
				marker: {
					fillColors: ['#41b5f5', '#0082CB', '#18345D'],
				},
			},
			grid: {
				borderColor: 'var(--color-primary-100)',
			},
			fill: {
				opacity: 1,
				colors: ['#41b5f5', '#0082CB', '#18345D'],
			},
			legend: {
				position: 'bottom',
				fontFamily: 'Inter',
				offsetY: 0,
				labels:{
					colors:'var(--color-primary-300)'
				},
				markers: {
					fillColors: ['#41b5f5', '#0082CB', '#18345D'], // Colores personalizados para los marcadores de la leyenda
				},
			},
		};
	}
}

export type ChartOptions = {
	series: ApexAxisChartSeries;
	chart: ApexChart;
	xaxis: ApexXAxis;
	stroke: ApexStroke;
	dataLabels: ApexDataLabels;
	markers: ApexMarkers;
	yaxis: ApexYAxis;
	grid: ApexGrid;
	legend: ApexLegend;
	title: ApexTitleSubtitle;
	fill: ApexFill; 
	tooltip: any;
};
