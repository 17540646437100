<div class="status_accepted" *ngIf="this.data.isAccepted">
	<div class="text">
		{{'Aceptado' | translate}}
	</div>
</div>

<div class="status_not_accepted" *ngIf="!this.data.isAccepted">
	<div class="text">
		{{'No aceptado' | translate}}
	</div>
</div>
