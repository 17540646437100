<!-- <div class="cont_main">

	<div class="row">
		<div class="col-12 col-md-12 col-lg-12 col-xl-4">
			<div class="input_main_cont">
				<input
					[(ngModel)]="filterText"
					type="text"
					class="input_main"
					(keyup)="onFilterTextChange($event)"
					placeholder="{{'search.oxxoToOxxo' | translate}}">
            	<img class="icon_sm" src="../../../../../../assets/icons/icon-search.svg">
			</div>
		</div>
		<div class="col-12 col-md-12 col-lg-12 col-xl-8">
			<div class="filters">
				<app-button-icon *ngIf="this.mode === 'light'"
					[activo]="false"
					btnNombre="Filtros"
					btnType="secondaryMedium"
					icon="icon-funnel"
					(click)="showFilters()">
				</app-button-icon>
				<app-button-icon *ngIf="this.mode === 'dark'"
					[activo]="false"
					btnNombre="Filtros"
					btnType="secondaryMedium"
					icon="icon-funnel-dark"
					(click)="showFilters()">
				</app-button-icon>
				<button-export (click)="onExport()"></button-export>
			</div>

		</div>

		<div class="col-12 col-md-12 col-lg-12 col-xl-12">
			<ag-grid-angular
				#agGrid
				class="ag-theme-alpine"
				[gridOptions]="this.gridOptions"
				[rowData]="this.rowData"
				suppressScrollOnNewData = true
				[columnDefs]="this.columnDefsGeneralOTO"
				style="width: 100%; height: 745px; border: none; margin-top:24px"
				(gridReady)="onGridReady($event)"
				(displayedColumnsChanged)="onDisplayedColumnsChanged($event)">
			</ag-grid-angular>
		</div>
	</div>
</div>

<app-filter-oxxo-to-oxxo [filters]="this.filters">
</app-filter-oxxo-to-oxxo> -->

<div class="cont_main">
    <div class="block_header">
        <div class="input_main_cont">
			<input
				[(ngModel)]="filterText"
				type="text"
				class="input_main"
				(keyup)="onFilterTextChange($event)"
				placeholder="{{'search.oxxoToOxxo' | translate}}">
			<img class="icon_sm" src="../../../../../../assets/icons/icon-search.svg">
        </div>
        <div class="calendar_filter_export">
			<div class="input_cont_calendar">
                <p-calendar
                    styleClass="input_custome"
                    [showIcon]="true"
                    [(ngModel)]="rangeDates"
                    (ngModelChange)="addEventRangeDates()"
                    selectionMode="range"
                    [readonlyInput]="true"
                    inputId="rangeGeneral"
					[placeholder]="formatDatePlaceholder(rangeDatesPlaceholder[0],rangeDatesPlaceholder[1])">
                </p-calendar>
            </div>
			<button-export (click)="onExport()" [isDisabled]="filteredData && filteredData.length>0?false:true"></button-export>
        </div>
    </div>
    <div class="block_table">
        <ag-grid-angular
			#agGrid
			class="ag-theme-alpine"
			[gridOptions]="this.gridOptions"
			[rowData]="this.rowData"
			suppressScrollOnNewData = true
			[columnDefs]="this.columnDefsGeneralOTO"
			style="width: 100%; height: 745px; border: none; margin-top:24px"
			(gridReady)="onGridReady($event)"
			(displayedColumnsChanged)="onDisplayedColumnsChanged($event)"
			(sortChanged)="onSortChanged($event)">
		</ag-grid-angular>
    </div>
</div>