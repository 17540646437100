<div class="pagination-container">
    <div class="left-arrow" [class.left-arrow-disabled]="isLeftArrowDisabled()" (click)="goToPreviousPage()">
        <div class="icon-arrow-left"></div>
    </div>
	<div class="pages">
		<div class="item" *ngFor="let page of visiblePages" [class.item-select]="page === currentPage">
			<div class="text" (click)="onPageChange(page)" *ngIf="page !== -1" >{{ page }}</div>
			<div class="text" style="cursor:not-allowed" *ngIf="page < 0">...</div>
		</div>
	</div>
    <div class="right-arrow" [class.right-arrow-disabled]="isRightArrowDisabled()" (click)="goToNextPage()">
        <div class="icon-arrow-right"></div>
    </div>
</div>
