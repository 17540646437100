<div class="row">
    <div class="col-10">
        <label class="header-user-detail">
            {{'orderDetail.title-orderDetail'|translate}}
        </label>
    </div>
    <div class="col-2" style="text-align: end; cursor: pointer;" (click)="closeModal()">
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" class="bi bi-x-lg" viewBox="0 0 16 16">
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
        </svg>
    </div>
    <div class="col-12">
        <hr style="color: #e5e5e5;opacity: 100;margin: 12px 0px;">
    </div>

    <!--!Informacion de la Orden  -->
    <div class="col-6" style="height: 800px;">
        <div class="header-informacion-general">
            <div class="header-informacion-general-text">
                {{'orderDetail.subtitle-informationGeneral'|translate}}
            </div>
        </div>
        <div class="table-informacion-general">
            <div class="row">
                <div class="col-12" style="border-bottom: 1px solid #efefef;">
                    <div class="field">
                        <div class="field-txt">
                            {{'orderDetail.informationGeneral.trackingNumber'|translate}}
                        </div>
                        <div class="field-value">
                            {{this.orderData.tracking}}
                        </div>
                    </div>
                </div>
				<div class="col-12" style="border-bottom: 1px solid #efefef;">
                    <div class="field">
                        <div class="field-txt">
                            {{'orderDetail.informationGeneral.lockerID'|translate}}
                        </div>
                        <div class="field-value">
                            {{this.orderData.lockerID}}
                        </div>
                    </div>
                </div>
                <div class="col-12" style="border-bottom: 1px solid #efefef;">
                    <div class="field">
                        <div class="field-txt">
                            {{'orderDetail.informationGeneral.emailClient'|translate}}
                        </div>
                        <div class="field-value">
                            {{this.orderData.email}}
                        </div>
                    </div>
                </div>
                <div class="col-12" style="border-bottom: 1px solid #efefef;">
                    <div class="field">
                        <div class="field-txt">
                            {{'orderDetail.informationGeneral.phoneClient'|translate}}
                        </div>
                        <div class="field-value">
                            {{this.orderData.phone_used}}
                        </div>
                    </div>
                </div>
                <div class="col-12" style="border-bottom: 1px solid #efefef;">
                    <div class="field">
                        <div class="field-txt">
                            {{'orderDetail.informationGeneral.nameEmployee'|translate}}
                        </div>
                        <div class="field-value">
                            {{this.orderData.nameEmploye}}
                        </div>
                    </div>
                </div>
                <div class="col-12" style="border-bottom: 1px solid #efefef;">
                    <div class="field">
                        <div class="field-txt">
                            {{'orderDetail.informationGeneral.numberOfDoor'|translate}}
                        </div>
                        <div class="field-value">
                            {{this.orderData.doorNumber}} ({{this.orderData.compartmentSize}})
                        </div>
                    </div>
                </div>
                <div class="col-12" style="border-bottom: 1px solid #efefef;">
                    <div class="field">
                        <div class="field-txt">
                            {{'orderDetail.informationGeneral.orderStatus'|translate}}
                        </div>
                        <div class="field-value">
                            <app-estatus-order  [orderData]="this.orderData">
                            </app-estatus-order>
                        </div>
                    </div>
                </div>
                <div class="col-12" style="border-bottom: 1px solid #efefef;">
                    <div class="field">
                        <div class="field-txt">
                            {{'orderDetail.informationGeneral.inLocker'|translate}}
                        </div>
                        <div class="field-value">
                            {{this.formattedinLockerDate}}
                        </div>
                    </div>
                </div>
                <div class="col-12" style="border-bottom: 1px solid #efefef;">
                    <div class="field">
                        <div class="field-txt">
                            {{'orderDetail.informationGeneral.expirationDate'|translate}}
                        </div>
                        <div class="field-value">
                            {{this.formattedExpirationDate}}
                        </div>
                    </div>
                </div>
                <div class="col-12" style="border-bottom: 1px solid #efefef;">
                    <div class="field">
                        <div class="field-txt">
                            {{'orderDetail.informationGeneral.removedLocker'|translate}}
                        </div>
                        <div class="field-value">
                            {{this.formattedPackagePickupDate}}
                        </div>
                    </div>
                </div>
                <div class="col-12" style="border-bottom: 1px solid #efefef;">
                    <div class="field">
                        <div class="field-txt">
                            {{'orderDetail.informationGeneral.deliverDate'|translate}}
                        </div>
                        <div class="field-value">
                            {{this.formattedPackageDeliveryDate}}
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="field">
                        <div class="field-txt">
                            {{'orderDetail.informationGeneral.qualificationClient'|translate}}
                        </div>
                        <div class="field-value">
                            <app-star-rating [rating]="this.orderData.rate_start">

                            </app-star-rating>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--! SOY EL QR Y LOS RECORDATORIOS  -->
    <div class="col-6" style="height: 800px;" *ngIf="this.orderData.status_package !== 16 && this.orderData.id_status_code_package !== 18">
        <div class="informacion-qr">
            <div class="header-informacion-qr">
                <div class="header-informacion-qr-text">
                    {{'orderDetail.subtitle-accessQR'|translate}}
                </div>
                <app-button-icon
						[activo]="!url_view_image"
						btnNombre="userDetail.btnDownloadQR"
						btnType="primaryMedium"
						icon="icon-qr">

				</app-button-icon>
            </div>
            <div class="row" style="width: 100%; height: 283px;">
                <div class="col-12" style="text-align: center;">
                    <img [src]="this.orderData.qr_order" (error)="url_view_image=false" *ngIf="url_view_image" [width]="283" height="auto" alt="imagen-order-detail">
					<ng-container *ngIf="!url_view_image">
						<div class="row">
							<div class="col-12" style="display: flex;justify-content: center;">
								<div class="container-image">
									<div class="container-image-panding">
										<img src="../../../../assets/icons/icon-qr-dark.svg" style="margin-bottom:1rem" width="20%" alt="">
										<h2>{{'message.title_err_img_404' | translate }}</h2>
										<p>{{'message.err_img_404' | translate }}</p>
									</div>
								</div>
							</div>
						</div>
					</ng-container>
                </div>
            </div>
        </div>
    </div>
    <!--! SOY LA EVIDENCIA  -->
    <div class="col-6" style="height: 800px;" *ngIf="this.orderData.status_package === 16 && this.orderData.id_status_code_package === 18">
        <div class="sign-header-box">
            <div class="sign-header-box-textbox">
                {{'orderDetail.subtitleEvidence' |translate}}
            </div>
        </div>
        <div class="sign-box">
            <div class="sign-text">
                {{'orderDetail.Evidence.signEvidence' |translate}}
            </div>
            <img [src]="this.img_sign" alt="" *ngIf="url_view_image_sign"  (error)="url_view_image_sign=false" width="604px" height="275px" style="border:1px solid #efefef; border-radius: 20px;">
			<ng-container *ngIf="!url_view_image_sign">
				<div class="row">
					<div class="col-12" style="display: flex;justify-content: center;">
						<div class="container-image-sign">
							<div class="container-image-panding">
								<img src="../../../../assets/icons/icon-qr-dark.svg" style="margin-bottom:1rem" width="20%" alt="">
								<h2>{{'message.title_err_img_404' | translate }}</h2>
								<p>{{'message.err_img_404' | translate }}</p>
							</div>
						</div>
					</div>
				</div>
			</ng-container>
        </div>
        <div class="image-box" style="margin-top:24px">
            <div class="image-text">
                {{'orderDetail.Evidence.recollectionEvidence' |translate}}
            </div>
            <img [src]="this.img_pickup" *ngIf="url_view_image_pickup"  (error)="url_view_image_pickup=false" alt="" width="604px" height="381px" style="border-radius: 20px;">
			<ng-container *ngIf="!url_view_image_pickup">
				<div class="row">
					<div class="col-12" style="display: flex;justify-content: center;">
						<div class="container-image-pickup">
							<div class="container-image-panding">
								<img src="../../../../assets/icons/icon-qr-dark.svg" style="margin-bottom:1rem" width="20%" alt="">
								<h2>{{'message.title_err_img_404' | translate }}</h2>
								<p>{{'message.err_img_404' | translate }}</p>
							</div>
						</div>
					</div>
				</div>
			</ng-container>
        </div>
    </div>
</div>
