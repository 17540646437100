<div class="filtersBox" *ngIf="this.filters === true">
	<div class="row">
		<div class="col-12 col-md-12 col-lg-12 col-xl-12" style="display: flex; justify-content: space-between;">
			<span class="filtersBoxTitle">
				{{'ordersPage.filters.title' | translate}}
			</span>
			<div class="icon-close" (click)="closeModal()">
			</div>
		</div>
		<div class="col-12 col-md-12 col-lg-12 col-xl-12">
			<div class="boxValues">
				<div class="row">
					<div class="col-12 col-md-12 col-lg-12 col-xl-12">
						<div class="selectStatus" (click)="openStatusDiv()">
							<div class="icon-right" *ngIf="openStatus === false">
							</div>
							<div class="icon-down" *ngIf="openStatus === true">
							</div>
							<span class="textDropDown">
								{{'ordersPage.filters.all.statusTitle' | translate}}
							</span>
						</div>
							<div class="clasificationStatus" *ngIf="openStatus === true" (click)="openStatusInProgress()">
									<div class="icon-down" *ngIf="openInProgress === false">
									</div>
									<div class="icon-up" *ngIf="openInProgress === true">
									</div>
									<span class="textDropDown">
										{{'ordersPage.filters.all.inProcess.title' | translate}}
									</span>
							</div>
								<div class="statusInLocker" *ngIf="openInProgress === true">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="en_locker_destino"
										[(ngModel)]="checkboxCollectionAssigned"
										name="enLockerDestino"
										(change)="validarCasillasSeleccionadas()"/>
									<span class="textDropDown">
										{{'ordersPage.filters.all.inProcess.collectAssigned' | translate}}
									</span>
								</div>
								<div class="statusInLocker" *ngIf="openInProgress === true">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="en_locker_destino"
										[(ngModel)]="checkboxRouteToCedis"
										name="enLockerDestino"
										(change)="validarCasillasSeleccionadas()"/>
									<span class="textDropDown">
										{{'ordersPage.filters.all.inProcess.routeToCedis' | translate}}
									</span>
								</div>
								<div class="statusInLocker" *ngIf="openInProgress === true">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="en_locker_origen"
										[(ngModel)]="chechboxRouteToLocker"
										name="enLockerOrigen"
										(change)="validarCasillasSeleccionadas()"/>
									<span class="textDropDown">
										{{'ordersPage.filters.all.inProcess.deliverAssigned' | translate}}
									</span>
								</div>



							<div class="clasificationStatus" *ngIf="openStatus === true" (click)="openStatusPending()">
								<div class="icon-down" *ngIf="openInProgress === false">
								</div>
								<div class="icon-up" *ngIf="openInProgress === true">
								</div>
								<span class="textDropDown">
									{{'ordersPage.filters.all.pending.title' | translate}}
								</span>
							</div>
								<div class="statusInLocker" *ngIf="openPending === true">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="en_locker_destino"
										[(ngModel)]="checkboxScheduled"
										name="enLockerDestino"
										(change)="validarCasillasSeleccionadas()"/>
									<span class="textDropDown">
										{{'ordersPage.filters.all.pending.scheduled' | translate}}
									</span>
								</div>
								<div class="statusInLocker" *ngIf="openPending === true">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="en_locker_destino"
										[(ngModel)]="checkboxLockerOrigen"
										name="enLockerDestino"
										(change)="validarCasillasSeleccionadas()"/>
									<span class="textDropDown">
										{{'ordersPage.filters.all.pending.lockerOrigin' | translate}}
									</span>
								</div>
								<div class="statusInLocker" *ngIf="openPending === true">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="en_locker_destino"
										[(ngModel)]="checkboxLockerDestino"
										name="enLockerDestino"
										(change)="validarCasillasSeleccionadas()"/>
									<span class="textDropDown">
										{{'ordersPage.filters.all.pending.lockerDestiny' | translate}}
									</span>
								</div>
								<div class="statusInLocker" *ngIf="openPending === true">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="en_locker_destino"
										[(ngModel)]="checkboxCedis"
										name="enLockerDestino"
										(change)="validarCasillasSeleccionadas()"/>
									<span class="textDropDown">
										{{'ordersPage.filters.all.pending.CEDIS' | translate}}
									</span>
								</div>
								<div class="statusInLocker" *ngIf="openPending === true">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="en_locker_destino"
										[(ngModel)]="checkboxExpired"
										name="enLockerDestino"
										(change)="validarCasillasSeleccionadas()"/>
									<span class="textDropDown">
										{{'ordersPage.filters.all.pending.expired' | translate}}
									</span>
								</div>
							<div class="clasificationStatus" *ngIf="openStatus === true" (click)="openStatusCompleted()">
								<div class="icon-down" *ngIf="openCompleted === false">
								</div>
								<div class="icon-up" *ngIf="openCompleted === true">
								</div>
								<span class="textDropDown">
									{{'ordersPage.filters.all.completed.title' | translate}}
								</span>
							</div>

								<div class="statusInLocker" *ngIf="openCompleted === true">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="en_locker_destino"
										[(ngModel)]="checkboxConfiscated"
										name="enLockerDestino"
										(change)="validarCasillasSeleccionadas()"/>
									<span class="textDropDown">
										{{'ordersPage.filters.all.completed.confiscated' | translate}}
									</span>
								</div>

								<div class="statusInLocker" *ngIf="openCompleted === true">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="en_locker_destino"
										[(ngModel)]="checkboxReturned"
										name="enLockerDestino"
										(change)="validarCasillasSeleccionadas()"/>
									<span class="textDropDown">
										{{'ordersPage.filters.all.completed.returned' | translate}}
									</span>
								</div>

								<div class="statusInLocker" *ngIf="openCompleted === true">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="en_locker_destino"
										[(ngModel)]="checkboxCollected"
										name="enLockerDestino"
										(change)="validarCasillasSeleccionadas()"/>
									<span class="textDropDown">
										{{'ordersPage.filters.all.completed.delivered' | translate}}
									</span>
								</div>

								<div class="statusInLocker" *ngIf="openCompleted === true">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="en_locker_destino"
										[(ngModel)]="checkboxCollectedCedis"
										name="enLockerDestino"
										(change)="validarCasillasSeleccionadas()"/>
									<span class="textDropDown">
										{{'ordersPage.filters.all.completed.collectionExpired' | translate}}
									</span>
								</div>




							<div class="clasificationStatus" *ngIf="openStatus === true" (click)="openStatusWarehouseManagement()">
								<div class="icon-down" *ngIf="openWarehouseManagement === false">
								</div>
								<div class="icon-up" *ngIf="openWarehouseManagement === true">
								</div>
								<span class="textDropDown">
									{{'ordersPage.filters.all.warehouseManagement.title' | translate}}
								</span>
							</div>
								<div class="statusInLocker" *ngIf="openWarehouseManagement === true">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="en_locker_destino"
										[(ngModel)]="checkboxReview"
										name="enLockerDestino"
										(change)="validarCasillasSeleccionadas()"/>
									<span class="textDropDown">
										{{'ordersPage.filters.all.warehouseManagement.reviewed' | translate}}
									</span>
								</div>
								<div class="statusInLocker" *ngIf="openWarehouseManagement === true">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="en_locker_destino"
										[(ngModel)]="checkboxCounter"
										name="enLockerDestino"
										(change)="validarCasillasSeleccionadas()"/>
									<span class="textDropDown">
										{{'ordersPage.filters.all.warehouseManagement.counter' | translate}}
									</span>
								</div>
								<div class="statusInLocker" *ngIf="openWarehouseManagement === true">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="en_locker_destino"
										[(ngModel)]="checkboxCollectedPending"
										name="enLockerDestino"
										(change)="validarCasillasSeleccionadas()"/>
									<span class="textDropDown">
										{{'ordersPage.filters.all.warehouseManagement.counterExpired' | translate}}
									</span>
								</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
