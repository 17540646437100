<div class="input-container">
	<div class="input-header">
		{{header | translate:ts}}
		<span class="required" *ngIf="required === true">
			*
		</span>
	</div>
	<div class="input-body">
		<div [ngClass]="{'input-disabled' : inputDisabled === true,'input-error': showError === true, 'input': showError === false}">
			<input type="{{inputType}}"
					[disabled]="inputDisabled"
					[maxLength]="this.haveMaxLength === true ? this.maxLength : 100"
					[value]="inputValue"
					(input)="onInputChange($event)"
					[autocomplete]="inputType === 'password' ? 'new-password' : 'off'"
					data-lpignore="true">
			<div class="erase-input" *ngIf="inputValue !== '' && showEraseIcon === true" (click)="eraseInput()">
				<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
					<path d="M9.91634 4.08334L4.08301 9.91668M4.08301 4.08334L9.91634 9.91668" stroke="#667085" stroke-linecap="round" stroke-linejoin="round"/>
				</svg>
			</div>
			<div class="units" *ngIf="showTextInside === true">
				{{textInside}}
			</div>
			<div class="info-input" *ngIf="showIcon" (click)="showInfo()">
				<img [src]="icon" alt="icon-input">
			</div>
			<div class="password-input" *ngIf="showPasswordIcon" (click)="changeInputType()">
				<img style="width: 20px; height: 20px;" [src]="this.inputType ==='text' ? '../../../../../assets/icons/icon-eye-off.svg':'../../../../../assets/icons/icon-eye.svg'" alt="icon-eye">
			</div>
		</div>
		<div class="footer-error">
			<div *ngIf="showError">
				{{error}}
			</div>
		</div>
		<div class="footer" *ngIf="showFooter">
			{{footerText}}
		</div>
	</div>
</div>
