import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FilterService {
	private arrayFilterEstatusSubject = new BehaviorSubject<Set<number>>(new Set<number>());
	private arrayFilterStarsSubject = new BehaviorSubject<Set<number>>(new Set<number>());
	private arrayFilterCoverageSubject = new BehaviorSubject<Set<string>>(new Set<string>());

	private arrayFilterEstatusWarehouseGeneralSubject = new BehaviorSubject<Set<number>>(new Set<number>());
	private arrayFilterEstatusWarehouseProgressSubject = new BehaviorSubject<Set<number>>(new Set<number>());
	private arrayFilterEstatusWarehousePendingSubject = new BehaviorSubject<Set<number>>(new Set<number>());
	private arrayFilterEstatusWarehouseCompletedSubject = new BehaviorSubject<Set<number>>(new Set<number>());
	private arrayFilterEstatusWarehouseManagementSubject = new BehaviorSubject<Set<number>>(new Set<number>());
	private arrayFilterEstatusScheduledSubject = new BehaviorSubject<Set<number>>(new Set<number>());
	private arrayFilterEstatuseExpiredSubject = new BehaviorSubject<Set<number>>(new Set<number>());


	private arrayFilterEstatusWarehouseNoAsignadosSubject = new BehaviorSubject<Set<number>>(new Set<number>());
	private arrayFilterEstatusWarehouseReturnsSubject = new BehaviorSubject<Set<number>>(new Set<number>());


	updateEstatusFilter(filter: Set<number>) {
		this.arrayFilterEstatusSubject.next(filter);
	}

	updateEstatusWarehouseGeneralFilter(filter: Set<number>) {
		this.arrayFilterEstatusWarehouseGeneralSubject.next(filter);
	}
	updateEstatusWarehouseProgressFilter(filter: Set<number>) {
		this.arrayFilterEstatusWarehouseProgressSubject.next(filter);
	}
	updateEstatusWarehousePendingFilter(filter: Set<number>) {
		this.arrayFilterEstatusWarehousePendingSubject.next(filter);
	}
	updateEstatusWarehouseCompletedFilter(filter: Set<number>) {
		this.arrayFilterEstatusWarehouseCompletedSubject.next(filter);
	}
	updateEstatusWarehouseManagementFilter(filter: Set<number>) {
		this.arrayFilterEstatusWarehouseManagementSubject.next(filter);
	}

	updateEstatusScheduledFilter(filter: Set<number>) {
		this.arrayFilterEstatusScheduledSubject.next(filter);
	}
	updateEstatusExpiredFilter(filter: Set<number>) {
		this.arrayFilterEstatuseExpiredSubject.next(filter);
	}


	updateEstatusWarehouseNoAsignadosFilter(filter: Set<number>) {
		this.arrayFilterEstatusWarehouseNoAsignadosSubject.next(filter);
	}
	updateEstatusWarehouseReturnsFilter(filter: Set<number>) {
		this.arrayFilterEstatusWarehouseReturnsSubject.next(filter);
	}



	updateStarsFilter(filter: Set<number>) {
		this.arrayFilterStarsSubject.next(filter);
	}

	updateCoverageFilter(filter: Set<string>) {
		this.arrayFilterCoverageSubject.next(filter);
	}

	getEstatusFilter(): Observable<Set<number>> {
		return this.arrayFilterEstatusSubject.asObservable();
	}

	getStarsFilter(): Observable<Set<number>> {
		return this.arrayFilterStarsSubject.asObservable();
	}

	getCoverageFilter(): Observable<Set<string>> {
		return this.arrayFilterCoverageSubject.asObservable();
	}

	getEstatusWarehouseGeneralFilter(): Observable<Set<number>> {
		return this.arrayFilterEstatusWarehouseGeneralSubject.asObservable();
	}
	getEstatusWarehouseProgressFilter(): Observable<Set<number>> {
		return this.arrayFilterEstatusWarehouseProgressSubject.asObservable();
	}
	getEstatusWarehousePendingFilter(): Observable<Set<number>> {
		return this.arrayFilterEstatusWarehousePendingSubject.asObservable();
	}
	getEstatusWarehouseCompletedFilter(): Observable<Set<number>> {
		return this.arrayFilterEstatusWarehouseCompletedSubject.asObservable();
	}
	getEstatusWarehouseManagementFilter(): Observable<Set<number>> {
		return this.arrayFilterEstatusWarehouseManagementSubject.asObservable();
	}

	getEstatusScheduledFilter(): Observable<Set<number>> {
		return this.arrayFilterEstatusScheduledSubject.asObservable();
	}


	getEstatusWarehouseNoAsignadosFilter(): Observable<Set<number>> {
		return this.arrayFilterEstatusWarehouseNoAsignadosSubject.asObservable();
	}
	getEstatusWarehouseReturnsFilter(): Observable<Set<number>> {
		return this.arrayFilterEstatusWarehouseReturnsSubject.asObservable();
	}
	getEstatusWarehouseExpiredFilter(): Observable<Set<number>> {
		return this.arrayFilterEstatuseExpiredSubject.asObservable();
	}

}
