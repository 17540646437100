import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ICellRendererParams } from 'ag-grid-community';
import { UserDetailModalComponent } from 'src/app/modules/carriers/components/overview-components/dialog/user-detail-modal/user-detail-modal.component';

@Component({
  selector: 'app-link-user-detail',
  templateUrl: './link-user-detail.component.html',
  styleUrls: ['./link-user-detail.component.scss']
})
export class LinkUserDetailComponent implements OnInit {
	locker:number;
	locker_name;
	value:string;
	data;
	showTooltip:boolean=false;
	constructor(private dialog: MatDialog,) { }
	agInit(params: ICellRendererParams): void {
		this.value = params.value;
		this.data = params.data
		this.locker_name =  params.data.locker_name;
	}
	refresh(params: ICellRendererParams) {
		return false;
	}
	ngOnInit(): void {
	}
	openModalLockerDetail() {
		const dialogRef = this.dialog.open(UserDetailModalComponent, {
			width: '1456px',
			height: '95%',
			data: this.data,
			disableClose: true,
		});

		dialogRef.afterClosed().subscribe((result) => {
		});
	}
}
