import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-alert',
	templateUrl: './alert.component.html',
	styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit, OnChanges, OnDestroy {
	@Input() showAlert : boolean;
	@Input() alertType :string ="";
	@Input() position : string ="";
	@Input() textAlert: string ="";
	@Input() subtitleAlert: string ="";
	@Input() imgType : string ="";
	@Input() primaryActionTxt: string ="";
	@Input() primaryActionUrl: string ="";
	@Input() secondaryActionTxt: string ="";
	@Input() secondaryActionUrl: string ="";
	@Input() activateInterval:boolean=false;
	public router = inject( Router );
	public ts = inject(TranslateService);
	counterValue: number = 100;
	private intervalId: any;
	// public errorService = inject(ErrorService)

	urlSuccess 	= "../../../../../assets/images/toast-ok-warehouse.svg";
	urlError	= "../../../../../assets/images/toast-error-warehouse.svg";
	urlInfo 	= "../../../../../assets/images/toast-info-warehouse.svg";
	urlZap 		= "../../../../../assets/images/toast-zap-warehouse.svg";

	closeAlert(){
		this.showAlert = !this.showAlert;
	}

	ngOnInit(): void {

	}
	ngOnDestroy(): void {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }
	ngOnChanges(changes: SimpleChanges): void {
		this.counterValue = 100;
		if (this.activateInterval) {
			if (this.intervalId) {
                clearInterval(this.intervalId);
            }
            this.intervalId = setInterval(() => {
                if (this.counterValue > 0 && this.activateInterval) {
                    this.counterValue -= 1; // Disminuye el contador
                } else {
                    this.activateInterval = false;
                    this.showAlert = false;

                    // Limpiar el intervalo cuando no se necesita más
                    clearInterval(this.intervalId);
                }
            }, 300); // Intervalo de actualización en milisegundos
        }

	}
}
