<div class="content">
	<div class="table-header">

		<input-search *ngIf="showInputSearch === true"
			[filterText]="filterText"
			(filterTextChanged)="onFilterTextChange($event)">
		</input-search>


		<div class="title" *ngIf="showInputSearch === false">
			{{title}}
		</div>


		<div class="table-buttons">

			<app-calendar-dashboard (click)="unshowFilter()" style="height: 100%;" *ngIf="showCalendar === true" (datesSelected)="receiveDates($event)">
			</app-calendar-dashboard>

			<app-button-dashboard
				(click)="openCreateStore()"
				(keyup)="handleKeyUp($event)"
				*ngIf="showCreateStore === true"
				buttonClass="btn-md-secondary"
				label="storesPage.tables.createStore">
			</app-button-dashboard>

			<app-file-uploader
				*ngIf="showCreateStore === true">
			</app-file-uploader>


			<app-button-dashboard
				(click)="openFilters()"
				(keyup)="handleKeyUp($event)"
				*ngIf="showFilterButton === true"
				[disabled]="disabledButton"
				buttonClass="btn-md-secondary"
				label="lockerPage.filters"
				icon="../../../../../assets/theme/light/icons/icon-filter.svg" >
			</app-button-dashboard>


			<app-button-dashboard
				*ngIf="showRoutesButton === true"
				onclick="createRoute()"
				(keyup)="handleKeyUp($event)"
				[disabled]="disabledButton"
				buttonClass="btn-md-secondary"
				label="routesPage.create-route"
				icon="../../../../../assets/theme/light/icons/icon-routes.svg" >
			</app-button-dashboard>


			<app-button-dashboard
				*ngIf="showExportButton === true"
				(click)="exportDataToExcel()"
				(keyup)="handleKeyUp($event)"
				[disabled]="disabledButton"
				buttonClass="btn-md-primary"
				label="lockerPage.export"
				icon="../../../../../assets/theme/light/icons/icon-download.svg" >
			</app-button-dashboard>

			<!-- @if(showStartReviewButton === true){
				<app-button
					[disabled]="this.disabledStartReviewButton"
					buttonClass="btn-sm-primary"
					label="requestDetailPage.products.startReview">
				</app-button>
			}
			@if(showCompleteReview === true){
				<app-button
					[disabled]="this.disabledStartReviewButton"
					buttonClass="btn-sm-secondary"
					label="Cancelar" >
				</app-button>
				<app-button
					[disabled]="this.disabledStartReviewButton"
					buttonClass="btn-sm-primary"
					label="Completar Revision"
					(click)="completeReview()">
				</app-button>
			} -->

			<ng-container *ngIf="showCreateRoute === true">
				<!-- Que se deshabilite si no se ha seleccionado ningun checkbox -->
				<app-button-dashboard
					(keyup)="handleKeyUp($event)"
					(click)="clearSelection()"
					[disabled]="!anyCheckboxSelected()"
					buttonClass="btn-md-secondary"
					label="createRoute.cancel" >
				</app-button-dashboard>
				<app-button-dashboard
					(keyup)="handleKeyUp($event)"
					(click)="getSelectedRowsData() "
					[disabled]="!anyCheckboxSelected()"
					buttonClass="btn-md-primary"
					label="createRoute.create-route"
					icon="../../../../../assets/theme/light/icons/icon-routes-white.svg">
				</app-button-dashboard>

			</ng-container>


		</div>
	</div>
	<ag-grid-angular
		style="width: 100%; height: 570px; padding: 10px 10px;"
		#agGrid
		class="ag-theme-alpine"
		[gridOptions]="this.gridOptions"
		[rowData]="this.rowData"
		[columnDefs]="this.colDef"
		[quickFilterText]="this.filterText"
		(gridReady)="onGridReady($event)"
		(rowDataUpdated)="onRowUpdate()"
		[overlayNoRowsTemplate]="overlayNoRowsTemplate"
		[overlayLoadingTemplate]="overlayLoadingTemplate"
		[animateRows]="true">
	</ag-grid-angular>

	<div class="pagination">
		<!-- Selector de tamaño de página -->
		<app-pagination-size-selector
			[firstRow]="1"
			[lastRow]="lastRow"
			[rowCount]="rowCount"
			[pageSize]="10"
			[pageSizeOptions]="[10, 25, 50, 100]"
			(pageSizeChanged)="onPageSizeChanged($event)">
		</app-pagination-size-selector>
		<!-- Paginación -->
		<app-pagination
			[currentPage]="agGrid.api ? agGrid.api.paginationGetCurrentPage() + 1 : 1"
			[totalPages]="agGrid.api ? agGrid.api.paginationGetTotalPages() : 0"
			(pageChanged)="onPageChanged($event)">
		</app-pagination>	</div>

</div>
<app-alert
	[showAlert]="this.showAlert"
	alertType="large"
	position="bottom-right"
	imgType="success"
	textAlert="alerts.routeCreated.text"
	subtitleAlert="alerts.routeCreated.subtitle">
</app-alert>
<app-alert
	[showAlert]="this.showAlertEmpty "
	alertType="small"
	position="bottom-right"
	imgType="info"
	textAlert="Selecciona una o varias ordenes para crear una ruta."
	subtitleAlert="Selecciona una o varias ordenes para crear una ruta.">
</app-alert>


<app-alert
	[showAlert]="this.showAlertCreatedStore"
	alertType="large"
	position="bottom-right"
	imgType="success"
	[textAlert]="'alerts.storeCreated.text'"
	subtitleAlert="alerts.storeCreated.subtitle">
</app-alert>
