import { Component, OnInit } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ColDef, GridOptions } from 'ag-grid-community';
import { TableUserService } from 'src/app/core/http/user.service';
import { UsersCarriersAdmin, UsersCarriersInterface } from 'src/app/core/models/users.interfaces';
import * as XLSX from 'xlsx';
import { ToggleComponent } from 'src/app/shared/components/toggle/toggle.component';
import { MatDialog } from '@angular/material/dialog';
import { DateTextComponent } from '../../date-text/date-text.component';
import { ConfirmChangeStatusUserComponent } from '../../confirm-change-status-user/confirm-change-status-user.component';
import { UserDetailModalComponent } from '../../user-detail-modal/user-detail-modal.component';
import { DialogAuditUserComponent } from '../../dialog-audit-user/dialog-audit-user.component';


@Component({
  selector: 'app-table-user-carrier-admin-carrier',
  templateUrl: './table-user-carrier-admin-carrier.component.html',
  styleUrls: ['./table-user-carrier-admin-carrier.component.scss']
})
export class TableUserCarrierAdminCarrierComponent implements OnInit {
	valueInput;
	public columnDefs: ColDef[];
	filterText: string = '';
	carrierID:number;
	gridOptions: GridOptions;
	public filteredData: any[] = [];
	users: UsersCarriersInterface[];
	carrier =[];
	constructor(private translate: TranslateService,private userService: TableUserService,private dialog: MatDialog) { }
	ngOnInit(): void {
		this.carrierID = parseInt(localStorage.getItem('courier_id'));
		this.translate.use(localStorage.getItem('language').toLowerCase());
		this.updateDataUser();
		this.loadData();
		this.translate.onLangChange.subscribe((event: LangChangeEvent)=> {
			this.translate.use(event.lang);
			this.translate.get([
				'table-carrier-admin.fullName',
				'table-carrier-admin.email',
				'table-carrier-admin.carrier',
				'table-carrier-admin.activeDays',
				'table-carrier-admin.registerDate',
				'table-carrier-admin.expiredDate',
				'table-carrier-admin.statusUser'
			]).subscribe( (t:any)=>{
				this.columnDefs = [
					{
						headerName: t['table-carrier-admin.fullName'],
						field: 'first_name',
						sortable: true,
						autoHeight: true,
						cellClass:'carrierAdminClass',
						headerClass:'borderHeaderCarrierAdmin',
						cellRenderer: this.centeredCellRenderer
					},
					{
						headerName: t['table-carrier-admin.email'],
						field: 'email',
						sortable: true,
						autoHeight: true,
						cellClass:'carrierAdminClass',
						headerClass:'borderHeaderCarrierAdmin',
						cellRenderer: this.centeredCellRenderer
					},
					{
						headerName: t['table-carrier-admin.carrier'],
						field: 'id_user',
						sortable: true,
						autoHeight: true,
						cellClass:'carrierAdminClass',
						headerClass:'borderHeaderCarrierAdmin',
						cellRenderer: this.centeredCellRenderer
					},
					{
						headerName: t['table-carrier-admin.activeDays'],
						field: 'active_days',
						sortable: true,
						autoHeight: true,
						cellClass:'carrierAdminClass',
						headerClass:'borderHeaderCarrierAdmin',
						cellRenderer: this.centeredCellRenderer
					},
					{
						headerName: t['table-carrier-admin.registerDate'],
						field: 'registration_date',
						sortable: true,
						autoHeight: true,
						headerClass:'borderHeaderCarrierAdmin',
						cellClass:'carrierAdminClass',
						cellRenderer: DateTextComponent
					},
					{
						headerName: t['table-carrier-admin.expiredDate'],
						field: 'registration_date',
						sortable: true,
						autoHeight: true,
						headerClass:'borderHeaderCarrierAdmin',
						cellClass:'carrierAdminClass',
						cellRenderer: DateTextComponent
					},
					{
						headerName: t['table-carrier-admin.statusUser'],
						field: 'id_status',
						sortable: true,
						autoHeight: true,
						headerClass:'borderHeaderCarrierAdmin',
						cellClass:'carrierAdminClass',
						cellRenderer: ToggleComponent,
						onCellClicked: ($event) => this.openConfirmChangeStatus($event),
					}
				]
			})
		})
	}
	updateDataUser(): void {
		this.translate.use(localStorage.getItem('language').toLowerCase());
		this.gridOptions = {
			columnDefs: [
				{
					headerName:this.translate.instant('table-carrier-admin.fullName'),
					field: 'first_name',
					sortable: true,
					autoHeight: true,
					headerClass:'borderHeaderCarrierAdmin',
					cellClass:'carrierAdminClass',
					cellRenderer: this.centeredCellRenderer
				},
				{
					headerName: this.translate.instant('table-carrier-admin.email'),
					field: 'email',
					sortable: true,
					autoHeight: true,
					headerClass:'borderHeaderCarrierAdmin',
					cellClass:'carrierAdminClass',
					cellRenderer: this.centeredCellRenderer
				},
				{
					headerName: this.translate.instant('table-carrier-admin.carrier'),
					field: 'id_user',
					sortable: true,
					autoHeight: true,
					headerClass:'borderHeaderCarrierAdmin',
					cellClass:'carrierAdminClass',
					cellRenderer: this.centeredCellRenderer
				},
				{
					headerName: this.translate.instant('table-carrier-admin.activeDays'),
					field: 'active_days',
					sortable: true,
					autoHeight: true,
					headerClass:'borderHeaderCarrierAdmin',
					cellClass:'carrierAdminClass',
					cellRenderer: this.centeredCellRenderer
				},
				{
					headerName: this.translate.instant('table-carrier-admin.registerDate'),
					field: 'registration_date',
					sortable: true,
					autoHeight: true,
					headerClass:'borderHeaderCarrierAdmin',
					cellClass:'carrierAdminClass',
					cellRenderer: DateTextComponent
				},
				{
					headerName: this.translate.instant('table-carrier-admin.expiredDate'),
					field: 'registration_date',
					sortable: true,
					autoHeight: true,
					headerClass:'borderHeaderCarrierAdmin',
					cellClass:'carrierAdminClass',
					cellRenderer: DateTextComponent
				},
				{
					headerName:  this.translate.instant('table-carrier-admin.statusUser'),
					field: 'id_status',
					sortable: true,
					autoHeight: true,
					headerClass:'borderHeaderCarrierAdmin',
					cellClass:'carrierAdminClass',
					cellRenderer: ToggleComponent,
					onCellClicked: ($event) => this.openConfirmChangeStatus($event)
				}
			],
			animateRows: true,
			pagination: true,
			paginationPageSize: 10,
			paginationAutoPageSize: true,
			enableCellChangeFlash: true,
			rowClass:'rowCarrierAdminClass',
			defaultColDef: {
				flex: 1,
			},
			overlayNoRowsTemplate:
			`<div>
				<div>
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="bi bi-info-circle icon-no-info" viewBox="0 0 16 16">
						<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
						<path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
					</svg>
				</div>
				<div class="labelNoRegistros">
					Aun no hay registros, <br>
					intentalo mas tarde
				</div>
			</div>`,
		}
	}
	loadData(){
		this.carrier =[];
		let data ={
			language: localStorage.getItem('language').toLocaleLowerCase(),
			is_module_courier : true,
			id_courier: this.carrierID
		}
		this.userService.getCarrierAdminAsincrono(data).then((res:UsersCarriersAdmin)=>{
			if (res.result_return) {
				res.result_return.forEach(item=>{
					let hourRegistrationDate = item.registration_date.substring(0,2);
					let minutesRegistrationDate = item.registration_date.substring(3,5);
					let secondsRegistrationDate = item.registration_date.substring(6,8);
					let monthRegistrationDate = parseInt(item.registration_date.substring(9,11));
					let dayRegistrationDate = item.registration_date.substring(12,14);
					let yearRegistrationDate = item.registration_date.substring(15,19);
					let registrationDateString = yearRegistrationDate +'-'+monthRegistrationDate+'-'+dayRegistrationDate+' '+hourRegistrationDate+':'+minutesRegistrationDate+':'+secondsRegistrationDate;
					this.carrier.push({
						acronym_language: item.acronym_language,
						active_days: item.active_days,
						alias_username: item.alias_username,
						courier_name: item.courier_name,
						email: item.email,
						first_name: item.first_name,
						id_language: item.id_language,
						id_status: item.id_status,
						id_user: item.id_user,
						language_name: item.language_name,
						name_status: item.name_status,
						registration_date: registrationDateString,
						surname: item.surname
					})
				});
				this.carrier = [...this.carrier];
			}

		})
	}
	onFilterTextChange(event) {
		this.filterText = event.target.value;
		event.api.setQuickFilter(this.filterText);
		let api = event.api;
		let temporal = [];
		event.api.forEachNodeAfterFilter((node) => {
			temporal.push(node.data);
		});
		this.filteredData = temporal;
	}
	onExport() {
		const fileName = 'Users.xlsx';
		const excelParams = {
			fileName: fileName,
			sheetName: 'CarrierAdmin',
			columnWidths: [30, 20, 20, 20],
		};
		if (this.filteredData.length > 0) {
			const Data = this.filteredData || [];
			const excelData = Data.map((row) => {
				const { id_language,id_user,  ...rowDataWithoutFields} = row;
				return rowDataWithoutFields;
			});
			const wb = XLSX.utils.book_new();
			const ws = XLSX.utils.json_to_sheet(excelData);
			XLSX.utils.book_append_sheet(wb, ws, excelParams.sheetName);
			XLSX.writeFile(wb, fileName);
		} else if (this.carrier.length > 0 && this.filteredData.length === 0) {
			const Data = this.carrier || [];
			const excelData = Data.map((row) => {
				const { id_language,id_user,  ...rowDataWithoutFields} = row;
				return rowDataWithoutFields;
			});
			const wb = XLSX.utils.book_new();
			const ws = XLSX.utils.json_to_sheet(excelData);
			XLSX.utils.book_append_sheet(wb, ws, excelParams.sheetName);
			XLSX.writeFile(wb, fileName);
		}
	}
	centeredCellRenderer(params) {
		const cellValue = params.value;
		const cellDiv = document.createElement('div');
		cellDiv.style.display = 'flex';
		cellDiv.style.alignItems = 'center';
		cellDiv.style.justifyContent = 'center';
		cellDiv.style.height = '100%';
		cellDiv.innerText = cellValue;
		return cellDiv;
	}
	openModalUserDetail(event) {
		const dialogRef = this.dialog.open(UserDetailModalComponent, {
			width: '1456px',
			height: '1030px',
			data: event.data,
		});

		dialogRef.afterClosed().subscribe((result) => {
		});
	}
	openConfirmChangeStatus(event) {
		const dialogRef = this.dialog.open(ConfirmChangeStatusUserComponent, {
			width: '500px',
			height: '200px',
			data: event,
		});

		dialogRef.afterClosed().subscribe((result) => {
			const newData = this.users.slice();
			newData[result].status = 'INACTIVE';
			this.users = newData;
		});
	}
	openModalUserAudit(event) {
		const dialogRef = this.dialog.open(DialogAuditUserComponent, {
			width: '959px',
			height: '796px',
			data: event.data,
		});

		dialogRef.afterClosed().subscribe((result) => {
		});
	}
}

