<div class="cont_main">
    <div class="block_header">
        <div class="input_main_cont">
            <input
                [(ngModel)]="filterText"
                type="text"
                class="input_main"
                (keyup)="onFilterTextChange($event)"
                placeholder="{{'search.users' | translate}}">
            <img class="icon_sm" src="../../../../../../assets/icons/icon-search.svg" alt="search">
        </div>
        <div class="calendar_filter_export">
			<button-export (click)="onExport()"></button-export>
        </div>
    </div>
    <div class="block_table">
        <ag-grid-angular #agGrid
			class="ag-theme-alpine"
			[gridOptions]="this.gridOptions"
			[rowData]="this.carrier"
			[columnDefs]="this.columnDefs"
			[quickFilterText]="this.filterText">
		</ag-grid-angular>
    </div>

</div>
