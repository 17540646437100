import { AfterViewInit, Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
	selector: 'app-dropdown',
	templateUrl: './dropdown.component.html',
	styleUrls: ['./dropdown.component.scss'],
	providers: [
		{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => DropdownComponent),
		multi: true,
		},
	],
})
export class DropdownComponent<T = any> implements ControlValueAccessor, OnInit, OnChanges, AfterViewInit {
	@Input() title: string = '';
	@Input() displayKey: keyof T | null = null;
	@Input() valueKey: keyof T | null = null;
	@Input() placeholder: string = 'Seleccione una opción';
	@Input() showTitleContainer: boolean = false;
	@Input() errorMessage: string = 'Selecciona una opción válida';
	@Input() control?: FormControl;
	@Input() disabled: boolean = false;
	@Input() showError: boolean = false;
	@Input() valueItem?: string;
	@Input() valueCityItem?: string;
	@Output() itemSelected: EventEmitter<T> = new EventEmitter<T>();


	private itemsSubject = new BehaviorSubject<T[]>([]);
	@Input() set items(value: T[]) {
		this.itemsSubject.next(value);
	}
	get items(): T[] {
		return this.itemsSubject.getValue();
	}

	isDropdownOpen: boolean = false;
	selectedIndex: number = -1;
	selectedItem: T | null = null;
	searchControl = new FormControl('');
	filteredItems$: Observable<T[]>;

	// ControlValueAccessor functions
	onTouched: () => void = () => {};
	onChange: (value: any) => void = () => {};

	constructor() {
		this.filteredItems$ = this.itemsSubject.asObservable().pipe(
			map((items) => {
				const searchTerm = this.searchControl.value?.toLowerCase() || '';
				console.log('Filtering items with search term:', searchTerm);  // Verifica el valor de búsqueda
				return items.filter((item) =>
					(this.displayKey ? item[this.displayKey] : item)
					.toString()
					.toLowerCase()
					.includes(searchTerm)
				);
			})
		);
	}

	ngOnInit(): void {
		// console.log(1)
		// this.itemsSubject.subscribe(() => {
		// 	this.preselectItem();
		// });
	}

	ngAfterViewInit(): void {
		console.log(2)

		this.itemsSubject.subscribe(() => {
			this.preselectItem();
		});
	}

	ngOnChanges(changes: SimpleChanges): void {
		// console.log(3)

		// if (changes['valueItem'] || changes['valueKey'] || changes['items'] || changes['valueCityItem']) {
		// 	this.preselectItem();
		// }
	}

	private preselectItem(): void {
		console.log()
		if (this.items && this.items.length > 0 && this.valueItem && this.valueKey) {
			this.selectedItem = this.items.find((item) => item[this.valueKey] === this.valueItem);
			if (this.selectedItem) {
				const selectedText = this.displayKey
				? this.selectedItem[this.displayKey]?.toString()
				: this.selectedItem?.toString();
				this.searchControl.setValue(selectedText || this.placeholder);
			} else {
				console.warn('No matching item found for valueItem:', this.valueItem);
			}
		}
	}

	toggleDropdown(): void {
		if (this.disabled) return;
		this.isDropdownOpen = !this.isDropdownOpen;

	}

	selectItem(item: T, index: number): void {
		if (this.disabled) return; // No permite seleccionar un elemento si está deshabilitado
		this.selectedItem = item;
		this.selectedIndex = index;
		this.itemSelected.emit(this.selectedItem);
		this.onChange(this.selectedItem);
		const selectedValue = this.displayKey && item[this.displayKey] !== undefined ? item[this.displayKey]?.toString() : item.toString();
		this.searchControl.setValue(selectedValue);
		this.isDropdownOpen = false;
	}

	getSelectedText(): string {
		if (this.selectedItem && this.displayKey && this.selectedItem[this.displayKey] !== undefined) {
			return this.selectedItem[this.displayKey].toString();
		}
		return this.placeholder;
	}

	writeValue(value: any): void {
		console.log('Writing value:', value);
		if (value) {
			this.valueItem = value;
			this.preselectItem();
		} else {
			this.selectedItem = null;
			this.selectedIndex = -1;
			this.searchControl.setValue(this.placeholder);
		}
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	isError(): boolean {
		return (this.control?.invalid && this.control?.touched) || !this.selectedItem;
	}

	trackByFn(index: number, item: T): any {
		return this.valueKey ? item[this.valueKey] : index;
	}
}
