import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
@Injectable({
	providedIn: 'root',
})
export class GoogleMapsService {
	constructor() {}

	loadGoogleMaps(): Promise<any> {
		const win = window as any;
		const gModule = win.google;
		if (gModule && gModule.maps) {
			return Promise.resolve(gModule.maps);
		}

		return new Promise((resolve, reject) => {
			const script = document.createElement('script');
			script.src =
				'https://maps.googleapis.com/maps/api/js?key=' +
				environment.key_google_maps +
				'&libraries=places';
			script.async = true;
			script.defer = true;
			document.body.appendChild(script);

			script.onload = () => {
				const loadedGoogleModule = win.google;
				if (loadedGoogleModule && loadedGoogleModule.maps) {
					resolve(loadedGoogleModule.maps);
				}
				else {
					reject(new Error('Google Map SDK is not Available'));

				}
			};

			script.onerror = () => {
				reject(new Error('No se pudo cargar el script de Google Maps'));
			};
		});
	}

	searchNearbyPlaces(map: google.maps.Map,location: google.maps.LatLngLiteral,keyword: string): Promise<any[]> {
		return new Promise((resolve, reject) => {
			const service = new google.maps.places.PlacesService(map);
			const request = {
				location,
				radius: 500, // Radio de búsqueda en metros (5 km)
				keyword, // Término de búsqueda
			};
			service.nearbySearch(request, (results, status) => {
				if (status === google.maps.places.PlacesServiceStatus.OK) {
				resolve(results); // Devolver los resultados encontrados
				} else {
				reject(new Error(`Error en la búsqueda de lugares: ${status}`));
				}
			});
		});
	}


}
