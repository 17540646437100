<!-- ? GRID CARD -->
<div class="grid_card">
    <!-- ? CARD -->
    <div class="card_cont" *ngFor="let card of dataCard; let i = index">
        <div [ngClass]="{'fadeAnimationIn'	: dataService.overviewChecked,
                        'fadeAnimationOut'	: !dataService.overviewChecked }">
            <div class="card_display">
                <!-- ? HEADER -->
                <div class="header_">
                    <!-- TXT HEADER -->
                    <p class="body_b1 color_primary font_medium white-space"> {{card.header | translate }} </p>
                    <!-- ? TOOLTIP CONTAINER -->
                    <div class="tooltip_cont_">
                        <img src="../../../../../../assets/icons/icon-info.svg" class="icon_tooltip">
                
                        <!-- TOOLTIP -->
                        <div class="tooltip_">
                            <p class="caption_c2 color_primary_400 font_medium">
                                {{ card.toolTip | translate }}
                            </p>
                            <!-- PIKE -->
                            <div class="pike_2"></div>
                        </div>
                        <!-- PIKE -->
                        <div class="pike"></div>
                    </div>
                
                </div>
                <!-- ? BODY FOOTER CONTAINER -->
                <div class="body_footer_container">
                    <!-- ? BODY -->
                    <p class="headlines_bh3 font_semibold color_primary">{{card.data }}</p>
                    <!-- ? FOOTER -->
                    <div [ngClass]="{
                        'footer_'   : footer === true,
                        'none'      : footer === false }">
                        <!-- ARROW -->
                        <div class="arrow_cont"
                            [ngClass]="{
                            'none' : getType(card.percent) }">
                            <img [src]="'../../../../assets/icons/cards/icon-arrow-'+getArrow(card.percent)+'.svg'">
                        </div>
                        <!-- PERCENT -->
                        <p class="body_b1 font_medium white-space"
                            [ngClass]="{
                                'none'              : card.percent == null,
                                'success_percent'   : card.percent >= 0,
                                'danger_percent'    : card.percent < 0 }">
                            {{card.percent + ' %'}}
                        </p>
                        <!-- TXT FOOTER -->
                        <p class="caption_c1 color_primary_300 font_regular white-space">{{card.footer | translate }}</p>
                        <!-- TXT FOOTER DATA -->
                        <p class="caption_c1 color_primary_300 font_regular white-space"> ({{card.footerData}}) </p>
                    </div>
                </div>
            </div>

        </div>

    </div>

</div>