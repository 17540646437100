import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
	selector: 'app-pagination',
	templateUrl: './pagination.component.html',
	styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit, OnChanges {
	@Input() currentPage: number;
    @Input() totalPages: number;
    @Output() pageChanged: EventEmitter<number> = new EventEmitter<number>();

    visiblePages: number[];

    constructor() { }

    ngOnInit(): void {
    }
	ngOnChanges(changes: SimpleChanges): void {
		this.changeVisiblePages(this.currentPage);
	}

    onPageChange(page: number) {
		if(page !== this.currentPage){
			this.currentPage = page;
			this.changeVisiblePages(this.currentPage);
			this.changePageAndEmitEvent();
		}
    }

    goToPreviousPage() {
		if (this.currentPage > 1) {
			this.currentPage--;
			this.changePageAndEmitEvent();
		}
    }

    goToNextPage() {
		if (this.currentPage < this.totalPages) {
			this.currentPage++;
			this.changePageAndEmitEvent();
		}
    }

	changeVisiblePages(currentPage: number) {
		const pagination = [];
		const edgeDelta = 4; // Number of pages to show before adding '...' at the extremes
		const innerDelta = 1; // Number of pages to show before/after the current page in the middle
		// Helper function to add a range of numbers to the pagination array
		const addRange = (start: number, end: number) => {
			for (let i = start; i <= end; i++) {
				pagination.push(i);
			}
		};
		if (this.totalPages <= 7) {
			// If there are 7 or less pages, show all of them
			addRange(1, this.totalPages);
		} else {
			// Always include the first page
			pagination.push(1);
			if (currentPage <= edgeDelta) {
				// Close to the start
				addRange(2, edgeDelta + 1);
				pagination.push(-1);
			} else if (currentPage >= this.totalPages - edgeDelta + 1) {
				// Close to the end
				pagination.push(-1);
				addRange(this.totalPages - edgeDelta, this.totalPages - 1);
			} else {
				// In the middle
				if (currentPage > edgeDelta) {
					pagination.push(-1);
				}
				const startPage = currentPage - innerDelta;
				const endPage = currentPage + innerDelta;
				addRange(startPage, endPage);
				if (currentPage < this.totalPages - edgeDelta + 1) {
					pagination.push(-1);
				}
			}
			// Always include the last page
			pagination.push(this.totalPages);
		}
		this.visiblePages = pagination;
	}

	isLeftArrowDisabled(): boolean {
        return this.currentPage === 1;
    }

    isRightArrowDisabled(): boolean {
        return this.currentPage === this.totalPages;
    }

	changePageAndEmitEvent() {
		this.pageChanged.emit(this.currentPage);
		this.changeVisiblePages(this.currentPage);
	}

	handlePageClick(event: MouseEvent) {
		const target = event.target as HTMLElement;
		if (target.classList.contains('text')) {
			const page = parseInt(target.innerText);
			this.onPageChange(page);
		}
	}

	handlePointerUp(event: MouseEvent) {
		// Tu lógica para manejar el evento pointerup aquí
	}

	handleKeyDown(event: KeyboardEvent) {
		// Lógica para manejar el evento keydown
	}

	handleKeyPress(event: KeyboardEvent) {
		// Lógica para manejar el evento keypress
	}

	handleKeyUp(event: KeyboardEvent) {
		// Lógica para manejar el evento keyup
	}
}
