<div class="store-detail-container">
	<div class="header-box">
		<div class="text">
			Detalles de la tienda
		</div>
		<app-close-button (click)="closeModal()"></app-close-button>
	</div>
	<div class="body-box">
		<div class="store">
			<div class="header">
				<div class="text">
					Informacion general
				</div>
			</div>

			<div class="content">
				<form [formGroup]="storesInformation" class="form-container">
					<app-input-dashboard
						style="width: 100%;"
						[showEraseIcon]="false"
						[inputValue]="storesInformation.get('store_name').value"
						(inputValueChange)="storesInformation.get('store_name').setValue($event)"
						[header]="'Nombre de la Tienda'"
						[showError]="storesInformation.get('store_name').invalid && storesInformation.get('store_name').touched"
						[error]="getErrorMessage('store_name')"
						[haveMaxLength]="true"
						[maxLength]="100"
						[inputDisabled]="true"
						autocomplete="off">
					</app-input-dashboard>

					<app-input-dashboard
						style="width: 100%;"
						[showEraseIcon]="false"
						[inputValue]="storesInformation.get('store_alias').value"
						(inputValueChange)="storesInformation.get('store_alias').setValue($event)"
						[header]="'Alias de la Tienda'"
						[showError]="storesInformation.get('store_alias').invalid && storesInformation.get('store_alias').touched"
						[error]="getErrorMessage('store_alias')"
						[haveMaxLength]="true"
						[maxLength]="100"
						[inputDisabled]="true"
						autocomplete="off">
					</app-input-dashboard>

					<app-input-dashboard
						style="width: 100%;"
						[showEraseIcon]="false"
						[inputValue]="storesInformation.get('city').value"
						(inputValueChange)="storesInformation.get('city').setValue($event)"
						[header]="'Ciudad'"
						[showError]="storesInformation.get('city').invalid && storesInformation.get('store_alias').touched"
						[error]="getErrorMessage('city')"
						[haveMaxLength]="true"
						[maxLength]="100"
						[inputDisabled]="true"
						autocomplete="off">
					</app-input-dashboard>

					<app-input-dashboard
						style="width: 100%;"
						[showEraseIcon]="false"
						[inputValue]="storesInformation.get('address').value"
						(inputValueChange)="storesInformation.get('address').setValue($event)"
						[header]="'Direccion'"
						[showError]="storesInformation.get('address').invalid && storesInformation.get('address').touched"
						[error]="getErrorMessage('address')"
						[haveMaxLength]="true"
						[maxLength]="100"
						[inputDisabled]="true"
						autocomplete="off">
					</app-input-dashboard>

					<app-input-dashboard
						style="width: 100%;"
						[showEraseIcon]="false"
						[inputValue]="storesInformation.get('latitude').value"
						(inputValueChange)="storesInformation.get('latitude').setValue($event)"
						[header]="'Latitud'"
						[showError]="storesInformation.get('latitude').invalid && storesInformation.get('latitude').touched"
						[error]="getErrorMessage('latitude')"
						[haveMaxLength]="true"
						[maxLength]="100"
						[inputDisabled]="true"
						autocomplete="off">
					</app-input-dashboard>

					<app-input-dashboard
						style="width: 100%;"
						[showEraseIcon]="false"
						[inputValue]="storesInformation.get('longitude').value"
						(inputValueChange)="storesInformation.get('longitude').setValue($event)"
						[header]="'Longitud'"
						[showError]="storesInformation.get('longitude').invalid && storesInformation.get('longitude').touched"
						[error]="getErrorMessage('longitude')"
						[haveMaxLength]="true"
						[maxLength]="100"
						[inputDisabled]="true"
						autocomplete="off">
					</app-input-dashboard>

					<app-input-dashboard
						style="width: 100%;"
						[showEraseIcon]="false"
						[inputValue]="storesInformation.get('codigo_referencia').value"
						(inputValueChange)="storesInformation.get('codigo_referencia').setValue($event)"
						[header]="'Codigo de referencia'"
						[showError]="storesInformation.get('codigo_referencia').invalid && storesInformation.get('codigo_referencia').touched"
						[error]="getErrorMessage('codigo_referencia')"
						[haveMaxLength]="true"
						[maxLength]="100"
						[inputDisabled]="true"
						autocomplete="off">
					</app-input-dashboard>

					<app-input-dashboard
						style="width: 100%;"
						[showEraseIcon]="false"
						[inputValue]="storesInformation.get('opening_time').value"
						(inputValueChange)="storesInformation.get('opening_time').setValue($event)"
						[header]="'Horario de Apertura'"
						[showError]="storesInformation.get('opening_time').invalid && storesInformation.get('opening_time').touched"
						[error]="getErrorMessage('opening_time')"
						[haveMaxLength]="true"
						[maxLength]="100"
						[inputDisabled]="true"
						autocomplete="off">
					</app-input-dashboard>

					<app-input-dashboard
						style="width: 100%;"
						[showEraseIcon]="false"
						[inputValue]="storesInformation.get('closing_time').value"
						(inputValueChange)="storesInformation.get('closing_time').setValue($event)"
						[header]="'Horario de Clausura'"
						[showError]="storesInformation.get('closing_time').invalid && storesInformation.get('closing_time').touched"
						[error]="getErrorMessage('closing_time')"
						[haveMaxLength]="true"
						[maxLength]="100"
						[inputDisabled]="true"
						autocomplete="off">
					</app-input-dashboard>


				</form>
			</div>
		</div>
		<div class="map">
			<div class="title">
				<div class="label">
					Ubicacion de la tienda
				</div>
				<app-icon
					name="info-circle"
					color="color-fg-quaternary"
					size="sm"
					strokeWidth="semibold">
				</app-icon>
			</div>
			<div class="map-container">
				<div *ngIf="!store.latitude || !store.longitude" class="error-message">
					Las coordenadas de la tienda no están disponibles.
				</div>
				<div id="map" class="google_map" #mapContainer *ngIf="store.latitude && store.longitude"></div>

			</div>
		</div>
	</div>
</div>
