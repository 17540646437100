<!-- GRID HEADER -->
<div class="grid-header">

	<!-- TITLE CONTAINER -->
	<div class="span-txt">
		<!-- TITLE -->
		<span class="txt-title">
		{{'overview.cardsOverview.orderHistoryLog' | translate}}
		</span>
			<!-- ICON INFO -->
			<div class="icon-info">
			<!-- TOOLTIP -->
			<app-tooltip-center-sm
				class="tooltip-span"
				[tooltip]="tooltipHistorical">
			</app-tooltip-center-sm>
			</div>
	</div>
</div>

<div class="chart" id="chart">
	<apx-chart style="width:99%"
	[series]="chartOptions.series"
	[chart]="chartOptions.chart"
	[xaxis]="chartOptions.xaxis"
	[stroke]="chartOptions.stroke"
	[tooltip]="chartOptions.tooltip"
	[dataLabels]="chartOptions.dataLabels"
	[legend]="chartOptions.legend"
	[markers]="chartOptions.markers"
	[grid]="chartOptions.grid"
	[yaxis]="chartOptions.yaxis"
	[title]="chartOptions.title"
	[responsive]="chartOptions.responsive"
	>

	</apx-chart>
</div>

<!-- LEGEND -->
<div class="container-legend">
	<div class="align-text">
		<span><div class="circle-1"></div> {{'overview.orderDelivered' | translate}}</span>
		<span><div class="circle-2"></div> {{'overview.orderRecolected' | translate}}</span>
		<span><div class="circle-3"></div> {{'overview.orderExpired' | translate}}</span>
	</div>
</div>
