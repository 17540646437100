// * Angular
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
// * Services
import { AppClientService } from 'src/app/core/http/app-client/app-client.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import * as XLSX from 'xlsx';
// * Libraries
import { AgGridEvent, ColDef, GridOptions, ITooltipParams } from 'ag-grid-community';
// * Components
import { DateTextComponent } from '../../date-text/date-text.component';
import { DialogOxxoToOxxoDetailComponent } from '../../dialog-oxxo-to-oxxo-detail/dialog-oxxo-to-oxxo-detail.component';
import { EstatusOxxoToOxxoComponent } from '../../tables-oxxo-to-oxxo/estatus-oxxo-to-oxxo/estatus-oxxo-to-oxxo.component';
import { DataService } from 'src/app/core/services/data.service';
import { AgGridAngular } from 'ag-grid-angular';
import { StarRatingComponent } from 'src/app/shared/components/star-rating/star-rating.component';
import { TooltipTableComponent } from 'src/app/shared/components/tooltip-table/tooltip-table.component';

@Component({
	selector: 'oxxo-to-oxxo-returns-table',
	templateUrl: './oxxo-to-oxxo-returns-table.component.html',
	styleUrls: ['./oxxo-to-oxxo-returns-table.component.scss'],
})
export class OxxoToOxxoReturnsTableComponent implements OnInit {
	@Input() rowData;
	@Input() filteredData;
	@Output() changeDate = new EventEmitter<{ InitialDate: Date, FinalDate: Date, typeOrders: string }>();	
	@ViewChild('agGrid') agGrid: AgGridAngular;
	rangeDates: Date[];
	rangeDatesPlaceholder:Date[];
	InitialDate = new Date();
	FinalDate = new Date();
	gridOptions: GridOptions;
	filterText: string = '';
	buttonExport: boolean = true;
	overlayLoadingTemplate = `
	<div>
		<div class="spinner-grow text-dark" role="status">
			<span class="sr-only">Loading...</span>
		</div>
		<div class="spinner-grow text-dark" role="status">
			<span class="sr-only">Loading...</span>
		</div>
		<div class="spinner-grow text-dark" role="status">
			<span class="sr-only">Loading...</span>
		</div>
	</div>
	`;

	private gridApi:AgGridAngular;
	public columnDefsGeneralOTO: ColDef[];
	public columnDefs: ColDef[] = [
		{
			headerName: this.translate.instant('oxxoToOxxo.tables.tracking'),
			field: 'data_package_service.shipping_number',
			headerClass: 'borderHeaderOTOGeneral',
			cellClass: 'OTOGeneralClassLink',
			autoHeight: true,
			sortable: true,
			suppressMovable: true,
		},
		{
			headerName: this.translate.instant('oxxoToOxxo.tables.submitter'),
			field: 'data_consumer.consumer_source.name',
			headerClass: 'borderHeaderOTOGeneral',
			cellClass: 'OTOGeneralClass',
			sortable: true,
			autoHeight: true,
			suppressMovable: true,
			tooltipComponent:TooltipTableComponent,
			tooltipValueGetter: (p: ITooltipParams) => p.value,
		},
		{
			headerName: this.translate.instant('oxxoToOxxo.tables.recipient'),
			field: 'data_consumer.consumer_destination.name',
			headerClass: 'borderHeaderOTOGeneral',
			cellClass: 'OTOGeneralClass',
			sortable: true,
			autoHeight: true,
			suppressMovable: true,
			tooltipComponent:TooltipTableComponent,
			tooltipValueGetter: (p: ITooltipParams) => p.value,
		},
		{
			headerName: this.translate.instant('oxxoToOxxo.tables.origin'),
			field: 'data_locker.locker_source.locker_name',
			headerClass: 'borderHeaderOTOGeneral',
			cellClass: 'OTOGeneralClass',
			sortable: true,
			autoHeight: true,
			suppressMovable: true,
			tooltipComponent:TooltipTableComponent,
			tooltipValueGetter: (p: ITooltipParams) => p.value,
		},
		{
			headerName: this.translate.instant('oxxoToOxxo.tables.destiny'),
			field: 'data_locker.locker_destination.locker_name',
			headerClass: 'borderHeaderOTOGeneral',
			cellClass: 'OTOGeneralClass',
			sortable: true,
			autoHeight: true,
			suppressMovable: true,
			tooltipComponent:TooltipTableComponent,
			tooltipValueGetter: (p: ITooltipParams) => p.value,
		},
		{
			headerName: this.translate.instant('oxxoToOxxo.tables.status'),
			field: 'data_package_service.nombre_status',
			colId: 'data_package_service_id_status',
			cellRenderer: EstatusOxxoToOxxoComponent,
			headerClass: 'borderHeaderOTOGeneral',
			cellClass: 'OTOGeneralClass',
			sortable: true,
			autoHeight: true,
			minWidth: 210,
			suppressMovable: true,
		},
		{
			headerName: this.translate.instant('oxxoToOxxo.tables.updateDate'),
			field: 'data_package_service.format_update_date',
			headerClass: 'borderHeaderOTOGeneral',
			cellClass: 'OTOGeneralClass',
			sortable: true,
			autoHeight: true,
			cellRenderer: DateTextComponent,
			suppressMovable: true,
		},
		{
			headerName: this.translate.instant('oxxoToOxxo.tables.rate'),
			field: 'data_package_service.rate',
			cellRenderer: StarRatingComponent,
			headerClass: 'borderHeaderOTOGeneral',
			cellClass: 'OTOGeneralClass',
			sortable: true,
			autoHeight: true,
			suppressMovable: true,
		},
	];


	constructor(
		private translate: TranslateService,
		private matDialog: MatDialog,
		public dataService: DataService
	) { }

	ngOnInit() {
		let today = new Date();
		let month = today.getMonth();
		let year = today.getFullYear();
		let day = today.getDate();
		let lastMonth;
		let endDate;
		let startDate;
		if (month < 0) {
			month = 11;
			year -= 1;
		}
		lastMonth = new Date(year, month - 1, day);
		endDate = new Date(year, month, day, 23, 59, 59);
		startDate = new Date(year, lastMonth.getMonth(), day);
		this.rangeDatesPlaceholder = [startDate, endDate];

		this.translate.use(localStorage.getItem('language').toLowerCase());
		this.updateTableGeneral();
		this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
			this.filterText="";
			this.rangeDates=[];
			this.filteredData=[];
			this.agGrid.api.updateGridOptions({ quickFilterText: "" });

			this.translate
				.get([
					'oxxoToOxxo.tables.tracking',
					'oxxoToOxxo.tables.submitter',
					'oxxoToOxxo.tables.recipient',
					'oxxoToOxxo.tables.origin',
					'oxxoToOxxo.tables.destiny',
					'oxxoToOxxo.tables.status',
					'oxxoToOxxo.tables.updateDate',
					'oxxoToOxxo.tables.rate',
				])
				.subscribe((t: any) => {
					this.columnDefsGeneralOTO = this.columnDefs.map((obj: any,) => {
						switch (obj.field) {
							case 'data_package_service.shipping_number':
								obj.headerName = t['oxxoToOxxo.tables.tracking'];
								obj.sort = null;
								break;
							case 'data_consumer.consumer_source.name':
								obj.headerName = t['oxxoToOxxo.tables.submitter'];
								obj.sort = null;
								break;
							case 'data_consumer.consumer_destination.name':
								obj.headerName = t['oxxoToOxxo.tables.recipient'];
								obj.sort = null;
								break;
							case 'data_locker.locker_source.locker_name':
								obj.headerName = t['oxxoToOxxo.tables.origin'];
								obj.sort = null;
								break;
							case 'data_locker.locker_destination.locker_name':
								obj.headerName = t['oxxoToOxxo.tables.destiny'];
								obj.sort = null;
								break;
							case 'data_package_service.nombre_status':
								obj.headerName = t['oxxoToOxxo.tables.status'];
								obj.sort = null;
								break;
							case 'data_package_service.update_date':
								obj.headerName = t['oxxoToOxxo.tables.updateDate'];
								obj.sort = null;
								break;
							case 'data_package_service.rate':
								obj.headerName = t['oxxoToOxxo.tables.rate'];
								obj.sort = null;
								break;
						}
						return obj
					});
				});
		});
	}

	ngOnChanges(changes: SimpleChanges): void {
		setTimeout(() => {
			let temporal = [];
			this.agGrid.api.forEachNodeAfterFilterAndSort((node) => {
				temporal.push(node.data);
			});
			this.filteredData = temporal;
		}, 10);
	}

	formatDatePlaceholder(date1: Date,date2: Date): string {
		let dia = date1.getDate().toString().padStart(2, '0');
		let mes = (date1.getMonth() + 1).toString().padStart(2, '0');
		let año = date1.getFullYear();
		let fechaFormateada1 = `${dia}/${mes}/${año}`;

		dia = date2.getDate().toString().padStart(2, '0');
		mes = (date2.getMonth() + 1).toString().padStart(2, '0');
		año = date2.getFullYear();
		let fechaFormateada2 = `${dia}/${mes}/${año}`;
	
		return fechaFormateada1+" - "+fechaFormateada2;
	}

	addEventRangeDates() {
		this.InitialDate = this.rangeDates[0];
		if (this.rangeDates[1] !== null) {
			this.FinalDate = this.rangeDates[1];
			this.changeDate.emit({ InitialDate: this.InitialDate, FinalDate: this.FinalDate, typeOrders: "completed" });
		}
	}

	onGridReady(params: any) {
		this.gridApi = params;
	}
	updateTableGeneral() {
		this.gridOptions = {
			enableCellTextSelection: true,
			tooltipShowDelay: 2000,
			tooltipHideDelay: 10000,
			defaultColDef: {
				resizable: false,
				flex: 1,
				minWidth: 150,
			},
			columnDefs: this.columnDefs,
			autoSizeStrategy: {
				type: 'fitGridWidth',
			},
			rowClass: 'rowOTOGeneralClass',
			animateRows: true,
			pagination: true,
			paginationPageSize: 10,
			paginationAutoPageSize: true,
			headerHeight: 59,
			rowHeight: 59,
			overlayLoadingTemplate: this.overlayLoadingTemplate,
			overlayNoRowsTemplate: `<div>
				<div>
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="bi bi-info-circle icon-no-info" viewBox="0 0 16 16">
						<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
						<path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
					</svg>
				</div>
				<div class="labelNoRegistros">
					Aun no hay registros, <br>
					intentalo mas tarde
				</div>
			</div>`,
		};
	}
	onDisplayedColumnsChanged(event: AgGridEvent) {
		const grid = document.getElementById('ag-grid');
		if (grid) {
			const gridWidth = grid.offsetWidth;

			const widthVisibleColumns = event.api.getColumns()
				.filter(c => c.isVisible())
				.map(c => c.getActualWidth())
				.reduce((a, b) => a + b);

			if (gridWidth > widthVisibleColumns) {
				event.api.sizeColumnsToFit();
			} else {
				event.api.autoSizeAllColumns();
			}
		}
	}

	onFilterTextChange(event) {
		let textSearch = this.filterText.toLowerCase();
		this.agGrid.api.updateGridOptions({ quickFilterText: textSearch.toLowerCase() });
		let temporal = [];
		this.agGrid.api.forEachNodeAfterFilter((node) => {
			temporal.push(node.data);
		});
		this.filteredData = temporal;
	}

	openModalOxxoToOxxoDetail(event) {
		const dialogRef = this.matDialog.open(DialogOxxoToOxxoDetailComponent, {
			width: '1306px',
			height: '95%',
			data: {
				rowData: event.data,
				from: 'warehouse',
			},
		});
	}

	dateComparator(param?: string,valueA?: any, valueB?: any, nodeA?: any, nodeB?: any, isInverted?: boolean): number {
		const date1: Date = new Date(nodeA.data.data_package_service[param]);
		const date2: Date = new Date(nodeB.data.data_package_service[param]);
		const idPackage1: number = nodeA.data.data_package_service.id_package;
		const idPackage2: number = nodeB.data.data_package_service.id_package;

		if (date1 === date2) {
			return idPackage2 - idPackage1;
			// return 0;
		}
		
		return date1 < date2 ? -1 : 1;
	}

	onSortChanged(event: any) {
		const sortedData: any[] = [];
		this.gridApi.api.forEachNodeAfterFilterAndSort(node => {
			sortedData.push(node.data);
		});
		this.filteredData = sortedData
	}

	onExport() {
		const fileName = 'OxxoToOxxoCompleted.xlsx';
		// Configuración para exportar en formato Excel
		const excelParams = {
			fileName: fileName,
			sheetName: 'OxxoToOxxo-Completed',
			columnWidths: [30, 20, 20, 20], // Ancho de las columnas en caracteres
		};
		let Data = [];
		if (this.filteredData.length > 0) Data = this.filteredData || [];
		else if (this.rowData.length > 0 && this.filteredData.length === 0) Data = this.rowData || [];
		const excelData = Data.map((row) => {
			return {
				shippingNumber: row.data_package_service.shipping_number,
				submitter: row.data_consumer.consumer_source.name,
				recipient: row.data_consumer.consumer_destination.name,
				origen: row.data_locker.locker_source.locker_name,
				destiny: row.data_locker.locker_destination.locker_name,
				status: row.data_package_service.nombre_status,
				// registrationDate: row.data_package_service.registration_date_schedule,
				updateDate: row.data_package_service.format_update_date,
				rate: row.data_package_service.rate,
			};
		});
		const wb = XLSX.utils.book_new();
		const ws = XLSX.utils.json_to_sheet(excelData);
		XLSX.utils.book_append_sheet(wb, ws, excelParams.sheetName);
		XLSX.writeFile(wb, fileName);
	}
}



// ColumnDefs antiguos para mostrar el modal
// [
// 	{
// 		headerName: t['oxxoToOxxo.tables.tracking'],
// 		field: 'data_package_service.shipping_number',
// 		headerClass: 'borderHeaderOTOGeneral',
// 		cellClass: 'OTOGeneralClassLink',
// 		autoHeight: true,
// 		sortable: true,
// 		onCellClicked: ($event) =>
// 			this.openModalOxxoToOxxoDetail($event),
// 	},
// 	{
// 		headerName: t['oxxoToOxxo.tables.submitter'],
// 		valueGetter: (params) => {
// 			const firstName =
// 				params.data.data_consumer.consumer_source
// 					.name;
// 			// const lockerName =
// 			// 	params.data.data_consumer.consumer_source
// 			// 		.surname;
// 			return `${firstName}`;
// 		},
// 		headerClass: 'borderHeaderOTOGeneral',
// 		cellClass: 'OTOGeneralClass',
// 		sortable: true,
// 		autoHeight: true,
// 	},
// 	{
// 		headerName: t['oxxoToOxxo.tables.recipient'],
// 		valueGetter: (params) => {
// 			const firstName =
// 				params.data.data_consumer.consumer_destination
// 					.name;
// 			// const lockerName =
// 			// 	params.data.data_consumer.consumer_source
// 			// 		.surname;
// 			return `${firstName}`;
// 		},
// 		headerClass: 'borderHeaderOTOGeneral',
// 		cellClass: 'OTOGeneralClass',
// 		sortable: true,
// 		autoHeight: true,
// 	},
// 	{
// 		headerName: t['oxxoToOxxo.tables.origin'],
// 		field: 'data_locker.locker_source.locker_name',
// 		headerClass: 'borderHeaderOTOGeneral',
// 		cellClass: 'OTOGeneralClass',
// 		sortable: true,
// 		autoHeight: true,
// 	},
// 	{
// 		headerName: t['oxxoToOxxo.tables.destiny'],
// 		field: 'data_locker.locker_destination.locker_name',
// 		headerClass: 'borderHeaderOTOGeneral',
// 		cellClass: 'OTOGeneralClass',
// 		sortable: true,
// 		autoHeight: true,
// 	},
// 	// {
// 	// 	headerName: t['oxxoToOxxo.tables.coverage'],
// 	// 	field: 'data_package_service.insurance',
// 	// 	headerClass:'borderHeaderOTOGeneral',
// 	// 	cellClass:'OTOGeneralClass',
// 	// 	sortable: true,
// 	// 	autoHeight: true
// 	// },
// 	{
// 		headerName: t['oxxoToOxxo.tables.status'],
// 		field: 'data_package_service.id_status',
// 		cellRenderer: EstatusOxxoToOxxoComponent,
// 		headerClass: 'borderHeaderOTOGeneral',
// 		cellClass: 'OTOGeneralClass',
// 		sortable: true,
// 		autoHeight: true,
// 	},
// 	{
// 		headerName: 'Fecha de retiro de locker',
// 		field: 'data_package_service.registration_date_schedule',
// 		sort: 'desc',
// 		headerClass: 'borderHeaderOTOGeneral',
// 		cellClass: 'OTOGeneralClass',
// 		sortable: true,
// 		autoHeight: true,
// 		cellRenderer: DateTextComponent,
// 	},
// ];