<div class="file-resume-container">
	<div class="header-container">
		<div class="title-box">
			<div class="title">
				{{ 'dialogToUploadFile.title' | translate }}
			</div>
			<app-close-button (click)="closeDialog()">
			</app-close-button>
		</div>
		<div class="subtitle">
			{{ 'dialogToUploadFile.subtitle' | translate }}
			<!-- {{this.data.length}} {{ 'dialogToUploadFile.subtitle-1' | translate }} -->
		</div>
	</div>
	<app-tables
		[gridOptions]="this.gridOptions"
		[showExportButton]="false"
		[showFilterButton]="false"
		[showCalendar]="false"
		style="width: 100%;"
		[rowData]="this.data"
		[disabledButton]="this.data === undefined"
		[colDef]="this.columnDefs"
		nameFileExport="General"
		[overlayLoadingTemplate]="overlayLoadingTemplate"
		[overlayNoRowsTemplate]="overlayNoRowsTemplate">
	</app-tables>

	<div class="footer-container">
		<div class="button-container">

			<app-button-dashboard
				(click)="closeDialog()"
				[disabled]="false"
				buttonClass="btn-md-secondary"
				label="dialogToUploadFile.buttons.cancel" >
			</app-button-dashboard>
			<app-button-dashboard
				(click)="createStores()"
				[disabled]="!this.dataBoolean"
				buttonClass="btn-md-primary"
				label="dialogToUploadFile.buttons.addUsers" >
			</app-button-dashboard>
		</div>
	</div>
</div>
