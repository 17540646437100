import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GenerateTockenLockerService {
	private token: string;
	public temp: any;
	public data;
	public baseUrl: string = environment.url_server_backend;

	constructor(private http: HttpClient ) { }

	setToken(token: string) {
		this.token = token;
	}

	// Get the token for authentication
	getToken() {
		return this.token ? this.token : localStorage.getItem('token');
	}

	async generateTockenLocker(params) {
		const headers = new HttpHeaders();
		headers.set('token',this.token);
		let url = this.baseUrl +'/server/locker/generate-token-open-door';
		const body = JSON.stringify(params);
		const request = new HttpRequest('POST',url,body,{headers});
		const response = await this.http.request(request).toPromise();
		return response;
	}

	async getTableofTockenGenerated(params){
		let options = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				token: this.getToken(),
			}),
			params: params,
		};
		let url = this.baseUrl + '/server/locker/token-open-door?';
		return this.http.get(url, options).toPromise();
	}
}
