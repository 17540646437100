import { Platform } from '@angular/cdk/platform';
import { DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild }  from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Locker, Module } from 'src/app/core/models/admin-client.interfaces';
import { LockerGridServiceService } from 'src/app/core/http/locker-grid-service.service';
import { ToastrService } from 'ngx-toastr';
import { AdminClientService } from 'src/app/core/http/admin-client.service';
import { GenerateTockenLockerService } from 'src/app/core/http/generate-tocken-locker.service';
import { DialogImageLockerComponent } from 'src/app/modules/admin-client/components/dialog-image-locker/dialog-image-locker.component';

@Component({
  selector: 'app-locker-grid-generate-tocken',
  templateUrl: './locker-grid-generate-tocken.component.html',
  styleUrls: ['./locker-grid-generate-tocken.component.scss']
})
export class LockerGridGenerateTockenComponent implements OnInit {
	@Input() modulo;
	@Input() cStatus;
	@Input() select_compartment = false;
	@Output() select_compartment_available = new EventEmitter<string>();

	@ViewChild('hijoElement', { static: true }) hijoElementRef: ElementRef;

	lockerID = localStorage.getItem('id_locker');
	imgScreen: string = localStorage.getItem('img_screen');
	alturaHijo: number;

	arrayNodeLocker: any = [];
	data;
	res;
	tt_fixed = 0;
	tt_open = 0;
	tt_occupied = 0;
	tt_available = 0;
	tt_damaged = 0;
	count_door = 1;
	locker_info: Locker[] = [];
	id_locker: number;
	cols: number[];
	rows: number[];
	lockers: Locker[];
	module: any = [];
	module2: Module[];
	col: number = 0;
	height: number = 0;
	modulos = 0;
	device = false;
	safari: boolean = false;
	grid;
	temp = [];
	tt_expired = 0;
	tt_schedule: number = 0;
	cols_grid = 6;
	identifi_module: number = 0;
	img = this.imgScreen;

	compartment_select_current: any;

	disabled_ratio: string = 'true';

	//google maps component -------------
	lat: any;
	lng: any;
	arrayMarker: any[] = [];

	constructor(
		private datepipe: DatePipe,
		private platform: Platform,
		private adminService: AdminClientService,
		private dialog: MatDialog,
		private translate: TranslateService,
		private lockerGridService: LockerGridServiceService,
		private generateTockenService: GenerateTockenLockerService,
		private toast: ToastrService
	) {}

	ngOnDestroy() {
		this.dialog.closeAll();
	}

	ngOnInit() {
		this.lockerGridService.updateLockerGrid$.subscribe(() => {
			// Lógica de actualización del componente aquí
			this.getGrid();
		});
		this.count_door = 1;
		this.safari = this.platform.SAFARI;
		this.device = this.platform.ANDROID || this.platform.IOS;
		this.lat = Number(localStorage.getItem('lat'));
		this.lng = Number(localStorage.getItem('lng'));
		this.arrayMarker.push({ lat: this.lat, lng: this.lng });

		let cont_rows = 0;
		let module_add: any = [];
		module_add['template_module'] = [];
		if (localStorage.getItem('grid')) {
			this.grid = JSON.parse(localStorage.getItem('grid'));
		}
		if(this.grid !== undefined ){
			this.grid.forEach((e, i) => {
				if (e.name_locker_module == 'CM-2') {
					module_add.name_locker_module = e.name_locker_module;
					e.template_module.forEach((l) => {
					l.text = this.getTextValue(l.class, this.count_door++);
					module_add.name_locker_module = l.class == 'LOCKER_BLOCK' ? l.class : module_add.name_locker_module;
					cont_rows += l.rows;
					module_add.template_module.push(l);

					if (cont_rows == 14) {
						this.module.push({ id: i + 1, locker: module_add });
						cont_rows = 0;
						module_add = [];
						module_add['template_module'] = [];
						module_add.name_locker_module = e.name_locker_module;
					}
					});
				}
				else {
					e.template_module.forEach((l) => {
						if(l.class !== 'NULL' && l.class !== 'SCREEN'){
							l.text = this.getTextValue(l.class, this.count_door++);
						}
						else{
							l.text ="null"
						}

					});

					this.module.push({ id: i + 1, locker: e });
				}
			});
			this.getGrid();
		}
	}

	getTextValue(classValue: string, countDoor: number): string {
		switch (classValue) {
			case 'NULL':
				return classValue === 'NULL' ? 'null' : '';
			case 'SCREEN':
				return 'SCREEN';
			case 'LOCKER_BLOCK':
				return 'LOCKER_BLOCK';
			default:
				return (countDoor++).toString();
		}
	}
	ngOnChanges() {
		if (this.cStatus && this.modulo) {
			this.cStatus = JSON.parse(this.cStatus);
			this.modulo = JSON.parse(this.modulo);
			this.modulo.map((res:any)=>{
				res.DOOR.forEach((mod)=>{
					if(mod.PACKAGE[0] === undefined){
						if(mod.name_status === 'SCHEDULE'){
							this.tt_schedule++;
						}
						else if(mod.name_status === 'CERRADO'){
							this.tt_available++;
						}
						else if(mod.name_status === 'FALLO'){
							this.tt_damaged++;
						}
						else if(mod.name_status === 'ABIERTO'){
							this.tt_open++;
						}
					}
					else if(mod.PACKAGE[0].id_status_code_package === 17){
						if(mod.PACKAGE[0].status_name ==='ASIGNADO'){
							this.tt_occupied++;
						}
					}
					else if(mod.PACKAGE[0].id_status_code_package === 32){
						if(mod.PACKAGE[0].status_name ==='VENCIDO'){
							this.tt_expired++;
						}
					}
				});
			})

		}
	}
	itemToolInfo(l: any) {
		let info;
		if (l.class != 'LOCKER_BLOCK' && l.class != 'SCREEN') {

			if ( l.order_number == 0 ||	l.order_number == 'undefined' || !l.order_number) {

				info = `Door: ${l.text} \n Status Comparment: ${l.class.split('_')[1]}`;
			}
			else if (	l.class == 'SCREEN' || l.class == 'LOCKER_BLOCK' || l.class == 'NULL') {
				return;
			} else {
				if (l.class){
					info = `Door: ${l.text} \n Order Number: ${l.order_number} \n Order: ${l.tracking_number} \n Status Comparment: ${l.class.split('_')[1]}`;
				}

			}
			return info;
		}
	}

	HOLA(event:any,l:any){
	}

	// TODO:DELETE
	viewScreenShot(img: string) {
		let dialogRef = this.dialog.open(DialogImageLockerComponent, {
			width: '50%',
			height: '90%',

			data: {
				image: img
			},
		});
	}

	lockerGridData;

	async getGrid() {
		let params = {
			language: localStorage.getItem('language').toLowerCase(),
			id_locker: localStorage.getItem('id_locker'),
		};
		let lockerGrid: any = await this.adminService.getLockersGrid(params);
		this.lockerGridData = lockerGrid;
		lockerGrid.modulo_status.forEach( e => {
			e.DOOR.forEach(d=>{
				this.module.forEach((e, i) =>{
					e.locker.template_module.forEach((t:any) => {
						if(t.text == d.door_number){
							t.class = d.GRID.class;
							if(d.PACKAGE[0] != undefined){
								t.order_number     = Number(d.PACKAGE[0].order_number)
								t.tracking_number  = d.PACKAGE[0].tracking_number
								t.status_name      = d.PACKAGE[0].status_name
								if(d.PACKAGE[0].status_name == "VENCIDO"){
									t.class  = "LOCKER_VENCIDO";
									this.tt_expired++;
								}
							}
							else{
								t.order_number    = 0
								t.tracking_number  = "N/A"
							}
						}

					})
				})
			})
		})



		function processDoorData(template: any[], door: any) {
			if (door.PACKAGE[0] !== undefined || door.GRID.class==="LOCKER_SCHEDULE" || door.GRID.class==="LOCKER_FALLO" || door.GRID.class === "LOCKER_ABIERTO") {
				template.forEach((t,i)=>{
					if(t.text === door.door_number){
						if(door.PACKAGE[0]=== undefined){
							template[i] = [];
							template[i] = door.GRID;
						}
						else if(door.PACKAGE[0].id_status_code_package === 17){
							template[i] = [];
							template[i] = door.GRID;
						}
						else if(door.PACKAGE[0].id_status_code_package === 32){
							template[i] = [];
							template[i] = door.GRID;
							template[i].class = 'LOCKER_VENCIDO';
						}

					}
				})
			}
		}
		this.disabled_ratio = 'false';
	}


	onSelectCompartment(event: any, compartment: any) {
		this.lockerGridData.modulo_status.forEach((item:any)=>{
			item.DOOR.map((res:any)=>{
				if(res.GRID.class !== 'LOCKER_FALLO'){
					if(res.door_number === parseInt(compartment.text)){
						this.generateToken(res.id_locker_dimension)
					}
				}

			})
		})
		if (this.compartment_select_current != undefined) {
			this.compartment_select_current.classList.remove('compartment_select');
			this.compartment_select_current.children[0].classList.add('number_select');
		}
		this.compartment_select_current = event.srcElement.classList[0] == 'mat-grid-tile-content' ? event.srcElement : event.path[1];
		this.compartment_select_current.classList.add('compartment_select');
		this.compartment_select_current.children[0].classList.add('number_select');
		this.select_compartment_available.emit(compartment);
	}

	generateToken(id_locker_dimension:number){
		let obj = {
			language: localStorage.getItem('language'),
			locker_code: localStorage.getItem('locker_code'),
			locker_hash: localStorage.getItem('locker_hash'),
			id_locker_dimension: id_locker_dimension
		};
		this.generateTockenService.generateTockenLocker(obj).then((res:any)=>{
			if(res.body.mensaje_return.CODE === 200){
				this.lockerGridService.updateLockerGrid();
				this.toast.success(
					res.body.mensaje_return.ERROR_MENSAGGE,
					this.translate.instant('message.tockenGenerated'),
					{
					toastClass: 'toast-extendida',
					timeOut: 5000,
					positionClass: 'toast-bottom-center',
					}
				);
			}
			else{
				this.toast.error(
					res.body.mensaje_return.ERROR_MESSAGE,
					this.translate.instant('message.errorGenerateToken'),
					{
					toastClass: 'toast-error',
					timeOut: 5000,
					positionClass: 'toast-bottom-center',
					}
				);
			}
		})

	}
}
