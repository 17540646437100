<div class="cont_main">
    <div class="block_1">
        <div class="kpi_lg">
            <div [ngClass]="{'fadeAnimationIn'	: dataService.overviewChecked,
                            'fadeAnimationOut'	: !dataService.overviewChecked }">
                <div>
                    <p class="color-container font_semibold"> {{this.total}} </p>
                    <p class="body_b1 color_primary_300" > {{ 'kpi-locker.kpi-footer-total' | translate }} </p>
                </div>
            </div>
        </div>
        <div class="kpis">
            <div class ="grid_card"  *ngFor="let kpi of dataKpis">
                <div [ngClass]="{'fadeAnimationIn'	: dataService.overviewChecked,
                                'fadeAnimationOut'	: !dataService.overviewChecked }">
                    <div class="card_display">
                        <div class="header_">
                            <p class="body_b1 color_primary font_medium white-space"> {{ kpi.header | translate }} </p>
                            <div class="tooltip_cont_">
                                <img src="../../../../../../assets/icons/icon-info.svg" class="icon_tooltip" alt="info">
                                <div class="tooltip_">
                                    <p class="caption_c2 color_primary_400 font_medium">
                                        {{ kpi.toolTip | translate }}
                                    </p>
                                    <div class="pike_2"></div>
                                </div>
                                <div class="pike"></div>
                            </div>
                        </div>
                        <p  class="color_primary headlines_bh3 font_semibold" >{{kpi.data}}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="map_cont">
            <div [ngClass]="{'fadeAnimationIn'	: dataService.overviewChecked,
                            'fadeAnimationOut'	: !dataService.overviewChecked }">
                <p class="color_primary font_medium headlines_h3" > {{'map.title' | translate}} </p>
            </div>
            <div class="map_component" [ngClass]="{
                'fadeAnimationIn'	: dataService.overviewChecked,
                'fadeAnimationOut'	: !dataService.overviewChecked }">
                <mat-card-content id="map-content">
                    <ng-container *ngIf="arrayMarker.length>0">
                        <app-google-maps
                            latitud="{{latitud}}"
                            longitud="{{longitud}}"
                            [zoom]="zoom"
                            [coords]="arrayMarker"
                            (eventAddress)="handleMarkerClick($event)">
                        </app-google-maps>
                    </ng-container>
                </mat-card-content>

            </div>
			<div class="markerSelectedContainer" *ngIf="showMarkerSelectedContainer">
				<div class="row">
					<div class="col-12" style="display: flex;">
						<div class="namelLockerSelected">
							{{this.lockerSelected[0].company_route}}
						</div>
						<div class="closeContainer" (click)="closeMarkerSelectedContainer()">
							<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
								<path d="M9.91634 4.08398L4.08301 9.91732M4.08301 4.08398L9.91634 9.91732" stroke="#667085" stroke-linecap="round" stroke-linejoin="round"/>
							</svg>
						</div>
					</div>
					<div class="col-12 directionDetailLabel">
						<span class="storeSelectedLabel">{{ 'OXXO ' + this.lockerSelected[0].company_route}},</span>{{this.lockerSelected[0].locker_address}}
						<!-- <strong class="storeSelectedLabel"></strong> -->
					</div>
				</div>
			</div>
        </div>
    </div>
</div>


