import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import axios, { AxiosResponse } from 'axios';
import { ToastrService } from 'ngx-toastr';
import { AdminClientService } from 'src/app/core/http/admin-client.service';
@Component({
	selector: 'app-user-detail-modal',
	templateUrl: './user-detail-modal.component.html',
	styleUrls: ['./user-detail-modal.component.scss'],
})
export class UserDetailModalComponent implements OnInit {
	userData: any;
	viewUserInformation = true;
	viewQrInformation = false;
	expiresDate: Date;
	status;
	imgError:boolean=false;
	url_view_image: boolean = true;

	constructor(
		private dialogRef: MatDialogRef<UserDetailModalComponent>,
		private http: HttpClient,
		private toastr: ToastrService,
		@Inject(MAT_DIALOG_DATA) private data: any,
		private adminService: AdminClientService
	) {
		this.userData = data;
		this.expiresDate = new Date(this.userData.expires);
	}

	ngOnInit(){
	}
	closeModal() {
		this.dialogRef.close();
	}
	ViewUserInformation() {
		this.viewUserInformation = true;
		this.viewQrInformation = false;
	}
	ViewQrInformation() {
		this.viewUserInformation = false;
		this.viewQrInformation = true;
	}

	async updateQR() {
		let data = {
			language: localStorage.getItem('lang').toLowerCase(),
			data_employee: [
				{ id_company_employee: this.data.id_company_employee },
			],
		};

		let res: any = await this.adminService.updateQRAccess(data);

		if (!res.mensaje_return.ERROR) {
			this.status = 'success';
			this.toastr.success(
				'El QR santifactoriamente',
				'Se ha actualizado',
				{
					toastClass: 'toast-extendida',
					timeOut: 5000,
					positionClass: 'toast-bottom-center',
				}
			);
		} else {
			this.status = 'err';
			setTimeout(() => {
				this.status = '';
			}, 1000);
		}
	}
}
