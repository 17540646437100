import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as QRCode from 'qrcode';


@Component({
  selector: 'app-dialog-qr-tocken',
  templateUrl: './dialog-qr-tocken.component.html',
  styleUrls: ['./dialog-qr-tocken.component.scss']
})
export class DialogQrTockenComponent implements OnInit {

	@ViewChild('qrCodeCanvasTockenLocker', { static: true }) qrCodeCanvasTokenLocker: ElementRef;

	constructor(
		private dialogRef: MatDialogRef<DialogQrTockenComponent>,
		@Inject(MAT_DIALOG_DATA) private data: any
	) { }

	ngOnInit(): void {
		const qrCodeDataCEDIS = this.data.token;
		QRCode.toCanvas(this.qrCodeCanvasTokenLocker.nativeElement,  qrCodeDataCEDIS, {
			width: 55, // Specify the desired width
			height: 55 // Specify the desired height
		}, (error) => {
			if (error) {
				console.error('Error generating QR code:', error);
			}
		});
	}

}
